export const accessibility = {
    methods: {
        focusOnFirstInvalidInput() {
            this.$nextTick(() => {
                let firstInvalidInput = [...document.getElementsByClassName('is-invalid')][0];

                if (firstInvalidInput) {
                    firstInvalidInput.focus();
                }
            });
        },

        accessibilityMainContentFocus() {
            this.$nextTick(() => {
                let focusTarget = this.$refs.accessibilityMainFocus;
                if (focusTarget) {
                    focusTarget.setAttribute('tabindex', -1);
                    focusTarget.focus();
                    focusTarget.removeAttribute('tabindex');
                }
            });
        }
    }
}
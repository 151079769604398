var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors && _vm.errors.length > 0
        ? [_c("form-error-alert-component", { attrs: { errors: _vm.errors } })]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasHeaders
        ? _vm._l(this.boundaries.headers, function(header, index) {
            return _c("div", {
              key: index,
              staticClass: "mb-2 rich-text-container",
              domProps: { innerHTML: _vm._s(_vm.$sanitize(header)) }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _c("RequiredInfoLegalEntitylInformation", {
        staticClass: "ml-3",
        attrs: {
          dictionary: _vm.dictionary,
          errors: _vm.errors,
          boundaries: _vm.boundaries,
          "required-info-data": _vm.requiredInfo
        },
        on: {
          skip: function($event) {
            return _vm.$emit("skip")
          },
          submit: function($event) {
            return _vm.$emit("submit")
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
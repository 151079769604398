var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-block" }, [
      _vm.pagesDictionary
        ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.transactions.title))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("Transactions", {
              attrs: {
                dictionary: _vm.dictionary,
                toggles: _vm.toggles,
                "transactions-options": _vm.transactionsOptions,
                "datatables-dictionary": _vm.datatablesDictionary
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "row internal-exchange-orderbook-tabels" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-xl-12 col-xxl-6 orderbook-buy" },
        [
          _c(
            "div",
            { staticClass: "orderbook-topheader table-topheader-bg-color" },
            [
              _c("h4", { staticClass: "text-white mb-1" }, [
                _vm._v(_vm._s(_vm.dictionary.orderBook.orderTypeBuy))
              ])
            ]
          ),
          _vm._v(" "),
          _c("InternalExchangesOrderbookTable", {
            attrs: { type: "buy", dictionary: _vm.dictionary }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-xl-12 col-xxl-6 orderbook-sell" },
        [
          _c(
            "div",
            { staticClass: "orderbook-topheader table-topheader-bg-color" },
            [
              _c("h4", { staticClass: "text-white mb-1" }, [
                _vm._v(_vm._s(_vm.dictionary.orderBook.orderTypeSell))
              ])
            ]
          ),
          _vm._v(" "),
          _c("InternalExchangesOrderbookTable", {
            attrs: { type: "sell", dictionary: _vm.dictionary }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
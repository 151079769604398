<template>
    <div>
        <div v-if="dictionary && canSkipRequiredDocuments">
            
            <div class="required-documents-page-description rich-text-container" v-html="$sanitize(dictionary.labels.requiredDocumentsPageDescription)"></div>

            <RequiredDocumentsTable :dictionary="dictionary" :datatablesDictionary="dictionary.dataTables" getUrl="/RequiredDocuments/IncompleteRequiredDocuments" @action="upload" />
            <div v-if="canSkipRequiredDocuments.canSkip" class="float-right">
                <div class="dt-buttons btn-group pt-1">
                    <button class="btn primaryButton canSkipButton" @click="skip">{{ dictionary.labels.btnSkip }}</button>
                </div>
            </div>
        </div>
        <component-spinner :spin="isLoading || canSkipIsLoading"></component-spinner>
        <loading-spinner :active="isSkipping"></loading-spinner>
    </div>
</template>

<script>
    import RequiredDocumentsTable from "./required-documents-table.component.vue";
    import { get } from 'api';
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            RequiredDocumentsTable
        },
        props: {
            dictionary: Object,
            isLoading: Boolean
        },
        data() {
            return {
                isSkipping: false,
                canSkipRequiredDocuments: null,
                canSkipIsLoading: true
            }
        },
        beforeCreate() {
            get("/RequiredDocuments/CanSkip",
                (responses) => {
                    this.canSkipRequiredDocuments = responses.data;

                    this.canSkipIsLoading = false;
                },
                this,
                () => {
                    this.canSkipIsLoading = false;
                },
                () => { }
            );
        },
        methods: {
            upload(documentId, documentParticipationId) {
                this.$router.push({
                    name: "required-documents-details",
                    query: {
                        documentId: documentId,
                        documentParticipationId: documentParticipationId
                    }
                });
            },
            skip() {
                this.isSkipping = true;
                get("/RequiredDocuments/SkipRequiredDocument",
                    (response) => {
                        if (response.data) {
                            EventBus.$emit("event-reload-layout", '/dashboard');
                        }
                        else {
                            this.isSkipping = false;
                        }
                    },
                    this,
                    () => {
                        this.isSkipping = false;
                    },
                    () => { }
                );
            }
        }
    };
</script>
import LoginDisclaimersContainer from "./login-disclaimers-container.component.vue";

export const loginDisclaimersRoutes = {
    path: '/loginDisclaimers',
    alias: '/loginDisclaimers/index',
    name: 'login-disclaimers',
    component: LoginDisclaimersContainer,
    children: [
        {
            path: '*',
            redirect: '/loginDisclaimers',
            meta: { bodyModal: true }
        }
    ]
};
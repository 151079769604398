<template>
<div>
    <v-data-table
        :id="tableId"
        class="table-striped"
        single-expand
        :no-data-text="noRecordFoundText"
        item-key="participationChoiceId"
        :hide-default-header="isMobile"
        :headers="computedHeaders"
        :items="rows"
        :loading="isLoading"
        :server-items-length="totalRecords"
        :search="search"
        :items-per-page="options.itemsPerPage"
        @page-count="options.pageCount = $event;"
        :options.sync="options"
        hide-default-footer
        >   
        <!-- Search input at top --->
        <template v-slot:top>
            <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
        </template>   

        <template v-slot:[`item.action`]="{ item }">
            <button class="btn primaryButton" @click="$router.push({ path: '/choices/instruction', query: { participationChoiceId: item.participationChoiceId }, hash: getHash });" >
            {{getLinkText(item)}}
            </button>
        </template>

        <!-- Datatable template for mobile view before data rows --->
        <template v-if="isMobile" v-slot:[`body.prepend`]>
            <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                    v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
        </template>
        <!-- Datatable template for mobile view of data rows --->
        <template v-if="isMobile" v-slot:item="{ item }">
            <ChoiceDetailsMobileContent :dictionary="dictionary" :choice="item" :actionLinkText="getLinkText(item)"  />
        </template>

        <template v-slot:footer>
            <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
        </template>
    </v-data-table>


</div>
</template>

<script>
import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
import ChoiceTableExpandedItem from "./choice-table-expanded-item.component.vue";
import ChoiceDetailsMobileContent from "./choice-details-mobile-content.component.vue";

export default {
    mixins: [vueDataTableMixin],
    components: {
        ChoiceTableExpandedItem,
        ChoiceDetailsMobileContent,
    },
    props: {
        dictionary: Object,
        datatablesDictionary: Object,
        noRecordFoundText: String,
        getUrl: {
            type : String,
            require: true
        },
        tableId: {
            type : String,
            require: true
        }
    },
    data() {
        return {
            isTablet: false,
            isMobile: false,            
            nonCriticalHeaders: [],
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["choiceStartDate"],
                sortDesc: [false],
                pageCount: 0,
                itemsPerPageOptions: [
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: this.datatablesDictionary.showAll, value: -1 }
                ]
            },
            headers: [
                { value: 'action', text: '', align: 'center', sortable: false, cellClass: 'wd-xxs' },
                { value: 'title', text: this.dictionary.table.choiceName, align: 'left', sortable: true },
                { value: 'choiceStartDate', text: this.dictionary.table.startDate, align: 'right', sortable: true },
                { value: 'choiceEndDate', text: this.dictionary.table.endDate, align: 'right', sortable: true },
                { value: 'stateDescription', text: this.dictionary.table.state, align: 'left', sortable: true },
            ],
            rows: [],
            totalRecords: 0
        }
    },
    computed: {
        computedHeaders() {            
            return this.headers;           
        },
        sortableColumns() {
            return this.computedHeaders.filter(x => x.sortable);
        },
        getHash() {
            return this.getUrl == "/choices/ActiveChoices" ? "tabActiveChoices" : "tabHistoricalChoices";
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isTablet = window.innerWidth <= this.$windowSizes.TABLET 
                && window.innerWidth > this.$windowSizes.MOBILE;
            this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
        },
        getLinkText(choice) {
            let linkText = this.dictionary.buttons.btnView;
            if (choice.canInstruct) {
                switch (choice.state.value) {
                    case 'csGranted':
                        linkText = this.dictionary.buttons.btnEnter;
                        break;
                    case 'csEntered':
                        linkText = this.dictionary.buttons.btnModify;
                        break;
                    default:
                        linkText = this.dictionary.buttons.btnModify;
                        break;
                }
            }
            
            return linkText;
        }
    }
}
</script>
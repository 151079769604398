import QuestionnaireContainer from "./questionnaire-container.component.vue";
import Questionnaire from "./questionnaire.component.vue";

export const questionnaireRoutes = {
    path: '/questionnaire',
    component: QuestionnaireContainer,
    props: true,
    children: [
        {
            path: '',
            name: 'questionnaire',
            component: Questionnaire,
            props: true,
            meta: { bodyModal: true }
        },
        {
            path: 'detail/:questionnaireId',
            name: 'questionnaire-detail',
            component: Questionnaire,
            props: true
        },
        {
            path: '*',
            redirect: '/questionnaire'
        }
    ],
};
<template>
    <div class="choice-row-flex form-row">
        <p class="col choice-row-flex__label font-weight-bold">{{ label }}</p>
        <p :class="['col choice-row-flex__value', { 'text-right' : textRight }]">{{ value }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        value: String | Number,
        textRight: Boolean
    }
}
</script>
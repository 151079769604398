var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.beneficiaryBoundariesPersonalInfo.mayViewFirstName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.firstName,
              label: _vm.dictionary.firstName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewFirstNames
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.firstNames,
              label: _vm.dictionary.firstNames
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewInitials
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.initials,
              label: _vm.dictionary.initials
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewLastName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.lastName,
              label: _vm.dictionary.lastName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewMaidenName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.maidenName,
              label: _vm.dictionary.maidenName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewGender
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.gender,
              label: _vm.dictionary.gender
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewTitles
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.titles,
              label: _vm.dictionary.titles
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewTitleOfAddress
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.titleOfAddress,
              label: _vm.dictionary.titleOfAddress
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewHonoraryName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.personalDataModel.honoraryName,
              label: _vm.dictionary.honoraryName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesPersonalInfo.mayViewDateOfBirth
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm._f("toFullYearDate")(
                _vm.personalDataModel.dateOfBirth
              ),
              label: _vm.dictionary.dateOfBirth
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
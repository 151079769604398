var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input-element", {
        attrs: {
          type: "password",
          "is-mandatory": true,
          label: _vm.dictionary.labels.currentPassword,
          togglePassword: true,
          name: "currentPassword",
          validationsList: _vm.currentPasswordValidations
        },
        model: {
          value: _vm.model.currentPassword,
          callback: function($$v) {
            _vm.$set(_vm.model, "currentPassword", $$v)
          },
          expression: "model.currentPassword"
        }
      }),
      _vm._v(" "),
      _vm.showPasswordValidationNote
        ? _c(
            "div",
            { staticClass: "pr-2 pl-2 password-expired-validation-note" },
            [
              _c("hr"),
              _vm._v(" "),
              _c("p", {
                staticClass: "rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionary.labels.passwordExpiredDescription
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("PasswordValidationNote", {
                attrs: {
                  dictionary: _vm.dictionary.labels,
                  boundaries: _vm.boundaries
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input-element", {
        attrs: {
          type: "password",
          "is-mandatory": true,
          label: _vm.dictionary.labels.newPassword,
          togglePassword: true,
          name: "newPassword",
          validationsList: _vm.newPasswordValidations
        },
        model: {
          value: _vm.model.newPassword,
          callback: function($$v) {
            _vm.$set(_vm.model, "newPassword", $$v)
          },
          expression: "model.newPassword"
        }
      }),
      _vm._v(" "),
      _c("input-element", {
        attrs: {
          type: "password",
          "is-mandatory": true,
          label: _vm.dictionary.labels.confirmPassword,
          togglePassword: true,
          name: "newPasswordConfirmed",
          validationsList: _vm.newPasswordConfirmedValidations
        },
        model: {
          value: _vm.model.newPasswordConfirmed,
          callback: function($$v) {
            _vm.$set(_vm.model, "newPasswordConfirmed", $$v)
          },
          expression: "model.newPasswordConfirmed"
        }
      }),
      _vm._v(" "),
      _vm._t("buttons", null, { back: _vm.back, submit: _vm.submit })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a class="order-detail" v-on:click="onClick">{{productName}}</a>
</template>

<script>
import { EventBus } from "Shared/event-bus";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        }
    },
    computed: {
        productName() {
            return this.order.hasExitSecondaryPositions ? `${this.order.productName} *` : this.order.productName;
        }
    },
    methods: {
        onClick() {
            EventBus.$emit("event-get-order-detail", {
                instructionType: this.order.instructionType,
                instructionId: this.order.instructionId,
                cancelOrder: false
            });
        },
    },
};
</script>
<template>
    <div class="dashboard row m-0" v-if="!!dictionary && !!ordersDictionary">
        <div class="col-xl-4 col-sm-12 col-md-6 p-0" id="dashboard-main">
            <dashboard-welcome v-if="dashboardData" :dictionary="dictionary" :dashboard-data="dashboardData" :toggles="toggles"></dashboard-welcome>
            <dashboard-announcements v-if="dashboardData" :dictionary="dictionary" :dashboard-data="dashboardData"></dashboard-announcements>
        </div>
        <div class="col-xl-4 col-sm-12 col-md-6 p-0" v-if="showSecurityAvailabilityChart || isInTabletAndDesktopRange">
            <div class="dashboard-chart-column" >
                <security-chart v-if="showSecurityAvailabilityChart"
                                id="securityChart"
                                :dictionary="dictionary.widgets"
                                :title="dictionary.widgets.securityAvailabilityChartTitle"
                                :description="dictionary.widgets.securityAvailabilityChartDescription"
                                :chart-model="securityChartModelData"
                                :colours-override="getDerivativeSharePriceSeriesColor">
                </security-chart>
            </div>
            <div v-if="!toggles.hideOrders && isInTabletAndDesktopRange" class="portlet-container" id="dashboard-side">
                <dashboard-last-orders :dictionary="dictionary" :toggles="toggles" :ordersDictionary="ordersDictionary" />
            </div>
        </div>
        <div class="col-xl-4 col-sm-12 p-0" v-if="!isInTabletAndDesktopRange">
            <div v-if="!toggles.hideOrders" class="portlet-container" id="dashboard-side">
                <dashboard-last-orders :dictionary="dictionary" :toggles="toggles" :ordersDictionary="ordersDictionary" />
            </div>
        </div>
    </div>
</template>

<script>
    import SecurityChart from './security-chart.component.vue';
    import DashboardWelcome from "./dashboard-welcome.component.vue";
    import DashboardAnnouncements from "./dashboard-announcements.component.vue";
    import DashboardLastOrders from "./dashboart-last-orders.component.vue";
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

    export default {
        mixins: [ScreenSizeMixin],
        components: {
            SecurityChart,
            DashboardWelcome,
            DashboardAnnouncements,
            DashboardLastOrders
        },
        computed:{
            securityChartModelData() {
                if(this.securityChartModel && this.securityChartModel.length == 1){
                    return this.securityChartModel[0];
                }
            },
            showSecurityAvailabilityChart() {
                return !this.toggles.hideSecurityAvailabilityChart 
                    && this.securityChartModelData 
                    && this.securityChartModelData.expectedValue > 0 
            },
            getDerivativeSharePriceSeriesColor() {
                return [getComputedStyle(document.documentElement).getPropertyValue('--chart-data-series-1-color').trim(),
                getComputedStyle(document.documentElement).getPropertyValue('--chart-data-series-2-color').trim()];
            }
        },
        props: {
            dictionary: Object,
            toggles: Object,
            ordersDictionary: Object,
            securityChartModel: Array,
            dashboardData: Object,
        }
    }
</script>
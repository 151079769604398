<template>
    <div>
        <div v-if="dictionary">
            <div class="title-block">
                <h1>{{dictionary.page.titleConfirmSubscribe}}</h1>
            </div>
            <div v-if="participationSubscription" class="portlets-wrapper">
                <div class="portlet-container">
                    <div class="row m-0">
                        <portlet-layout column-class="col-lg-8 col-xl-9" :title="dictionary.widget.headerSubscriptionCompleted">
                            <template slot="body">
                            <alert-component variant="warning">
                                <p>{{ dictionary.widget.textSubscriptionCompletedDescription }}</p>
                            </alert-component>
                                <SubscriptionConfirmationDetails :dictionary="dictionary" :participationSubscription="participationSubscription" :isSubscriptions="isSubscriptions" />
                                <div class="buttonContainer mt-3">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn primaryButton" @click="next()">{{ dictionary.widget.btnContinue }}</button>
                                    </div>
                                </div>
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-lg-4 col-xl-3" :title="dictionary.widget.titleSubscriptionInfo" >
                            <template slot="body">
                                <SubscriptionInfo :dictionary="dictionary" :participationSubscription="participationSubscription" />
                            </template>
                        </portlet-layout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import SubscriptionConfirmationDetails from "./subscription-confirmation-details.component.vue";
    import SubscriptionInfo from "./subscription-info.component.vue";

    export default {
        components: {
            SubscriptionConfirmationDetails,
            SubscriptionInfo
        },
        props: {
            dictionary: Object,
            participationSubscription: Object,
            isSubscriptions: Boolean,
        },
        data() {
            return {

            }
        },
        beforeRouteEnter(to, from, next) {
            const previousPathName = from.path ?? "";
            if (!previousPathName.includes("confirmation")) {
                location.replace(`/`);
            } else {
                next();
            }
        },
        created() {
            if (!this.participationSubscription) {
                this.$router.push({ path: '/subscriptions' });
            }
        },
        beforeCreate() {

        },
        methods: {
            next() {
                this.$router.push({ path: '/subscriptions' });
            }
        }
    };
</script>
<template>
    <div v-if="restrictToMoneyOrSecurityOnly" class="dividend-subscription-allocation-radio-button">
        <div class="dividend-subscription-allocation-radio-button-input">
            <input type="radio" v-model="value.sharesDividendAmountPercentage" value="0" name="dividendAllocationPercentage" id="dividendAllocationMoney"/>
            <label for="dividendAllocationMoney">{{dictionary.subscriptionLabels.money}} (100%)</label>
        </div>
        <div class="dividend-subscription-allocation-radio-button-input">  
            <input type="radio" v-model="value.sharesDividendAmountPercentage" value="100" name="dividendAllocationPercentage" id="dividendAllocationShares" />
            <label for="dividendAllocationShares" >{{dictionary.subscriptionLabels.shares}} (100%)</label>
        </div>
    </div>
    <div v-else class="dividend-subscription-allocation-input-element">
        <div class="dividend-allocation-percentage">
            <label class="dividend-allocation-percentage-label" name="moneyDividendAllocationPercentage">{{dictionary.subscriptionTable.money}}</label>
            <input-element class="dividend-allocation-percentage-input" v-model.trim="value.moneyDividendAmountPercentage" name="moneyDividendAllocationPercentage" @input="$emit('allocation-percentage-changed')" @change="moneyDividendAmountPercentageChanged" :validationsList="validation.moneyDividendAmountPercentageValidation"></input-element>
        </div>
        <div class="dividend-allocation-percentage">
            <label class="dividend-allocation-percentage-label" name="sharesDividendAllocationPercentage">{{dictionary.subscriptionTable.shares}}</label>
            <input-element class="dividend-allocation-percentage-input" v-model.trim="value.sharesDividendAmountPercentage" name="sharesDividendAllocationPercentage" @input="$emit('allocation-percentage-changed')" @change="sharesDividendAmountPercentageChanged" :validationsList="validation.sharesDividendAmountPercentageValidation"></input-element>
        </div>    
    </div>
</template>

<script>
    import numeral from 'numeral';
    import { mustBeDecimal, between } from 'Shared/Components/Vue/Validations/custom-validator';

    export default {
        props: {
            dictionary: Object,
            restrictToMoneyOrSecurityOnly: Boolean,
            validation: Object,
            value: Object,
        },
        methods :{
            moneyDividendAmountPercentageChanged(newValue){
                if(this.correctPercentage(newValue) && this.correctPercentage(this.value.sharesDividendAmountPercentage)) {
                    this.value.sharesDividendAmountPercentage = numeral(100 - numeral(newValue).value()).format('0.[000000000]');
                }
            },
            sharesDividendAmountPercentageChanged(newValue){
                if(this.correctPercentage(newValue) && this.correctPercentage(this.value.moneyDividendAmountPercentage)) {
                    this.value.moneyDividendAmountPercentage = numeral(100 - numeral(newValue).value()).format('0.[000000000]');
                }
            },
            correctPercentage(percentage) {
                return mustBeDecimal(percentage)
                    && between(percentage,0,100) && percentage!= null && percentage!=undefined && percentage.toString().trim().length 
                    && percentage.toString().indexOf("%") < 0;
            }
        }
    }
</script>
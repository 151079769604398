<template>
  <div>
    <span v-if="position.isSecurityPosition">{{ position.name }}</span>
    <span v-else >{{ position.name }}&nbsp;<i class="far fa-money-bill-wave primary-color" aria-hidden="true"></i></span>
  </div>
</template>

<script>
export default {
  props: {
    position: Object
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.dictionary.editAccount.heading))]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.submitingData } }),
      _vm._v(" "),
      _vm.bankAccount.hasInvalidSettings && !_vm.isLoading
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _c("div", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(
                    _vm.financialDictionary.bankAccount.security
                      .errorExistingAccountSettingsInvalidDisplay
                  )
                )
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "timeline-container",
        [
          _vm.complianceExternalAccount && _vm.externalAccountBoundaries
            ? _c(
                "timeline-step",
                { attrs: { currentStep: _vm.currentStep, step: 1 } },
                [
                  _c("FinancialAddAccountStepAccountType", {
                    staticClass: "timeline-step",
                    attrs: {
                      "current-step": _vm.currentStep,
                      "account-type": _vm.accountType,
                      dictionary: _vm.dictionary.addAccount,
                      "validation-dictionary":
                        _vm.financialDictionary.validations,
                      currencies: _vm.listCurrencies,
                      countries: _vm.listCountries,
                      "external-account-types": _vm.listExternalAccountTypes,
                      "relationship-types": _vm.listRelationshipTypes,
                      "account-type-data":
                        _vm.complianceExternalAccount.accountType,
                      toggles: _vm.financialToggles,
                      securityAccountDisplaySettings:
                        _vm.securityAccountDisplaySettings,
                      showStep3: _vm.showStep3,
                      externalAccountTypeDefaultsToSecurity:
                        _vm.bankAccount.externalAccountTypeDefaultsToSecurity
                    },
                    on: {
                      "changed-external-account-type": function($event) {
                        return _vm.changedExternalAccountType()
                      },
                      "next-step": _vm.nextStep,
                      "cancel-add-account": function($event) {
                        return _vm.cancelEditAccount()
                      },
                      "update-boundaries": _vm.updateAccountBoundaries,
                      "edit-step": _vm.editStep
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.complianceExternalAccount && _vm.externalAccountBoundaries
            ? _c(
                "timeline-step",
                { attrs: { currentStep: _vm.currentStep, step: 2 } },
                [
                  _c("FinancialAddAccountStepAccountHolder", {
                    staticClass: "timeline-step",
                    attrs: {
                      "account-type": _vm.accountType,
                      "current-step": _vm.currentStep,
                      dictionary: _vm.dictionary.addAccount,
                      "validation-dictionary":
                        _vm.financialDictionary.validations,
                      countries: _vm.listCountries,
                      "account-holder-data":
                        _vm.complianceExternalAccount.accountHolder,
                      "is-account-holder-name-and-city-mandatory":
                        _vm.isAccountHolderNameAndCityMandatory,
                      toggles: _vm.financialToggles,
                      securityAccountDisplaySettings:
                        _vm.securityAccountDisplaySettings,
                      messageInSecurityAccountHolderStep:
                        _vm.messageInSecurityAccountHolderStep,
                      showStep3: _vm.showStep3
                    },
                    on: {
                      "next-step": _vm.nextStep,
                      "previous-step": _vm.previousStep,
                      "cancel-add-account": function($event) {
                        return _vm.cancelEditAccount()
                      },
                      "set-compliance-external-account":
                        _vm.setComplianceExternalAccount,
                      "edit-step": _vm.editStep
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showStep3 &&
          _vm.complianceExternalAccount &&
          _vm.externalAccountBoundaries
            ? _c(
                "timeline-step",
                { attrs: { currentStep: _vm.currentStep, step: 3 } },
                [
                  _c("FinancialAddAccountStepBankAccount", {
                    staticClass: "timeline-step",
                    attrs: {
                      isEdit: true,
                      editingExistingAccount: true,
                      "account-type": _vm.accountType,
                      "current-step": _vm.currentStep,
                      dictionary: _vm.dictionary.addAccount,
                      "validation-dictionary":
                        _vm.financialDictionary.validations,
                      countries: _vm.listCountries,
                      "external-account-boundaries":
                        _vm.externalAccountBoundaries,
                      "compliance-external-account":
                        _vm.complianceExternalAccount,
                      "bank-account-data":
                        _vm.complianceExternalAccount.bankAccount,
                      "central-securities-depository-types":
                        _vm.listCentralSecuritiesDepositoryTypes,
                      errors: _vm.errors,
                      "bank-country-data": _vm.bankCountry,
                      toggles: _vm.financialToggles,
                      securityAccountDisplaySettings:
                        _vm.securityAccountDisplaySettings,
                      showStep3: _vm.showStep3
                    },
                    on: {
                      "previous-step": _vm.previousStep,
                      "next-step": _vm.nextStep,
                      "cancel-add-account": function($event) {
                        return _vm.cancelEditAccount()
                      },
                      "set-compliance-external-account":
                        _vm.setComplianceExternalAccount
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./mfa.component.vue?vue&type=template&id=274b6e46&"
import script from "./mfa.component.vue?vue&type=script&lang=js&"
export * from "./mfa.component.vue?vue&type=script&lang=js&"
import style0 from "./mfa.component.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Monidee-Test\\A3\\_work\\1\\s\\Monidee.tOption\\Monidee.tOption.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('274b6e46')) {
      api.createRecord('274b6e46', component.options)
    } else {
      api.reload('274b6e46', component.options)
    }
    module.hot.accept("./mfa.component.vue?vue&type=template&id=274b6e46&", function () {
      api.rerender('274b6e46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Features/Mfa/Assets/Vue/mfa.component.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "modal-basic",
        {
          attrs: {
            title: _vm.dictionary.widget.labelHeaderTitle,
            "is-on": _vm.isModalVisible,
            "ok-footer": false,
            centered: true
          },
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.moduleName,
              value: _vm.replaceNullOrEmpty(_vm.model.name)
            }
          }),
          _vm._v(" "),
          _vm.model.state
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.state,
                  value: _vm.replaceNullOrEmpty(_vm.model.state.description)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.securityName,
              value: _vm.replaceNullOrEmpty(_vm.model.securityName)
            }
          }),
          _vm._v(" "),
          !_vm.toggles.hideEmissionsGrantedColumn
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.granted,
                  value: _vm.replaceNullOrEmpty(_vm.model.granted)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.grantedDate,
              value: _vm._f("toDate")(_vm.model.grantedDate)
            }
          }),
          _vm._v(" "),
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.startDate,
              value: _vm._f("toDate")(_vm.model.subscriptionDate)
            }
          }),
          _vm._v(" "),
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.securityName,
              value: _vm.replaceNullOrEmpty(_vm.model.securityName)
            }
          }),
          _vm._v(" "),
          _c("SubscriptionMobileRow", {
            attrs: {
              label: _vm.dictionary.table.subscribed,
              value: _vm.replaceNullOrEmpty(_vm.model.subscribed)
            }
          }),
          _vm._v(" "),
          !!_vm.model.feesPrice
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.feesPrice,
                  value: _vm.replaceNullOrEmpty(_vm.model.feesPrice)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.model.expectedContributionAmountFormatted
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.expectedContributionAmount,
                  value: _vm.replaceNullOrEmpty(
                    _vm.model.expectedContributionAmountFormatted
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.model.contributionAmountFormatted
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.contributionAmount,
                  value: _vm.replaceNullOrEmpty(
                    _vm.model.contributionAmountFormatted
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.model.contributionDate
            ? _c("SubscriptionMobileRow", {
                attrs: {
                  label: _vm.dictionary.table.contributionDate,
                  value: _vm._f("toDate")(_vm.model.contributionDate)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary float-right mt-3",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(_vm._s(_vm.dictionary.widget.btnOk))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
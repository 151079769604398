<template>
  <tr>
    <td class="dtr-control p-2">
      <div class="dtr-control-row">
          <div class="d-flex mr-3 w-100">
            <div class="align-self-center">
                <div class="document-icon-container">
                    <i class="far fa-file fa-2x" aria-hidden="true"></i>
                </div>  
            </div> 
            <div class="document-details-container">
                <div class="d-flex justify-content-between">
                  <p><strong>{{item.documentPlanSummary.documentName}}</strong></p>
                  <p class="document-date">{{item.documentDateFormatted}}</p> 
                </div>            
                <p>{{item.documentPlanSummary.planName}}</p>
            </div>
          </div>
          <div class="d-flex my-auto">
                <a class="far fa-download fa-2x align-self-center" :href="$sanitize(`Document/GetDocument?documentid=${item.documentPlanSummary.documentId}`)">
                  <span class="sr-only">{{dictionary.labels.documentsDownloadSRText}} {{item.documentPlanSummary.documentName}}</span>
                </a>
          </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    dictionary: Object,
    item: Object,
  },
};
</script>
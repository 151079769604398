<template>
    <div>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <input-element type="text" name="legalEntityIdentifier" :isMandatory="true"
                               :label="dictionary.labels.lei"
                               v-model="requiredInfoData.legalEntityIdentifier"
                               :readonly="!boundaries.mayEditLegalEntityIdentifier"
                               :validationsList="legalEntityValidations.legalEntityIdentifier"></input-element>
            </div>
        </div>
        <RequiredInfoMifidButtons :dictionary="dictionary" :errors="errors" @skip="$emit('skip')" @submit="submit()" />
    </div>
</template>

<script>
    import { requiredIf } from "vuelidate/lib/validators";
    import RequiredInfoMifidButtons from "./required-info-mifid-buttons.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        props: {
            dictionary: Object,
            requiredInfoData: Object,
            boundaries: Object,
            errors: Array
        },
        components: {
            RequiredInfoMifidButtons
        },
        validations() {
            return {
                requiredInfoModel: {
                    legalEntityIdentifier: {
                        required: requiredIf(function () {
                            return this.boundaries.mayEditLegalEntityIdentifier;
                        })
                    }
                }
            };
        },
        computed: {
            requiredInfoModel() {
                return this.requiredInfoData;
            },
            legalEntityValidations() {
                return {
                    legalEntityIdentifier: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalEntityIdentifier,
                            error: this.$v.requiredInfoModel.legalEntityIdentifier.$error,
                            type: this.$v.requiredInfoModel.legalEntityIdentifier.required,
                            message: this.dictionary.validation.leiInvalid
                        }
                    ]
                };
            }
        },
        methods: {
            submit() {
                EventBus.$emit("v-touch");
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit('submit');
            }
        }
    };
</script>
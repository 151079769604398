export const requiredDocumentsMethods = {
    methods: {
        getActionButtonText(document, dictionary) {
            if (document.isActive) {
                if(document.approvalState.value == 'rdasNew') {
                    return dictionary.labels.actionLinkUpload;
                } else {
                    return dictionary.labels.actionLinkViewUpload
                }
            } else {
                return dictionary.labels.actionLinkView
            }
        }
    }
}
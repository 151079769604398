var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-pac" },
    [
      _c(
        "div",
        { staticClass: "mfa-pac-body" },
        [
          _c("p", { staticClass: "mb-2" }, [
            _c("span", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(
                    _vm.dictionary.beneficiaryModalHeaderUpdateMFASetting
                  )
                )
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(_vm.dictionary.mfaAlertMFAEnterTOTP)
                )
              }
            })
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "auth-code" }, [
            _c("span", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(_vm.dictionary.mfaFieldTOTPCode)
                )
              }
            })
          ]),
          _vm._v(" "),
          _c("v-otp-input", {
            ref: "otpInput",
            attrs: { disabled: _vm.isDisabled, length: "6" },
            on: { finish: _vm.onFinish }
          }),
          _vm._v(" "),
          _vm.error
            ? _c("alert-component", { attrs: { variant: "error" } }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("MfaButtons", {
        attrs: {
          dictionary: _vm.dictionary,
          hasContinueButton: false,
          disableSubmit: _vm.isDisabled,
          hasOnlyCancelButton: true
        },
        on: {
          cancel: function($event) {
            return _vm.cancel()
          },
          submit: function($event) {
            return _vm.submit()
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "lost-phone-wrapper" }, [
        _c("a", {
          attrs: { href: "#" },
          domProps: {
            innerHTML: _vm._s(_vm.$sanitize(_vm.dictionary.mfaFieldLostPhone))
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.navigateToLostMyPhone()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", {
          staticClass: "rich-text-container",
          domProps: {
            innerHTML: _vm._s(
              _vm.$sanitize(_vm.dictionary.beneficiaryAlertMFALostPhone)
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <portlet-layout :id="id"  class="card-header-title w-100" :title="title">
        <template slot="body">
            <p>{{ description }}</p>
            <div class="d-flex justify-content-center">
                <div class="dashboard-security-chart-container">
                    <component-spinner :spin="transformedChartModel === null"></component-spinner>
                    <PieChart :chart-model="transformedChartModel"
                            :colours="coloursOverride"
                            pieChartType="donut"
                            @create-chart-legends="createChartLegends">
                    </PieChart>
                </div>
            </div>
            <div class="dashboard-security-chart-legends">
                <PieChartLegends v-if="chartContexts.length > 0" :chartContexts="chartContexts"/>
            </div>               
        </template>
        <template slot="footerLink">
            <router-link class="portlet-title-see-all-link" to="/portfolio" :title="dictionary.portfolioLinkOpenPortfolio"> {{ dictionary.portfolioLinkOpenPortfolio }}</router-link>
        </template>
    </portlet-layout>
</template>

<script>
    import PieChart from 'Shared/Components/Vue/Charts/pie-chart.component.vue';
    import PieChartLegends from 'Shared/Components/Vue/Charts/pie-chart-legends.component.vue';

    export default {
        props: {
            coloursOverride: Array,
            dictionary: Object,
            id: String,
            chartModel: Object,
            title: String,
            description: String
        },
        components: {
            PieChart,
            PieChartLegends
        },
        data() {
            return {
                chartContexts:[]
            }
        },
        methods:{
            createChartLegends(chartContextsArg) {
                this.chartContexts = chartContextsArg;
            }
        },
        computed: {
            transformedChartModel() {

                let model = null;

                if (!this.$isEmptyObject(this.dictionary) && !this.$isEmptyObject(this.chartModel)) {
                    model = {
                        labelHeading: this.dictionary.chartTableHeadingTypeOfShare,
                        valueHeading: this.dictionary.chartTableHeadingShareAmount,
                        data: [
                            {
                                label: this.dictionary.securityAvailablilityChartUnvestedLabel,
                                value: this.chartModel.unvestedValue,
                                valueFormatted: this.chartModel.unvestedValueFormatted,
                                tooltip: `${this.dictionary.securityAvailablilityChartUnvestedLabel}: ${this.chartModel.unvestedValueFormatted}`
                            },
                            {
                                label: this.dictionary.securityAvailablilityChartVestedLabel,
                                value: this.chartModel.value,
                                valueFormatted: this.chartModel.valueFormatted,
                                tooltip: `${this.dictionary.securityAvailablilityChartVestedLabel}: ${this.chartModel.valueFormatted}`
                            },
                            {
                                label: this.dictionary.securityAvailablilityChartTotalLabel,
                                value: this.chartModel.expectedValue,
                                valueFormatted: this.chartModel.expectedValueFormatted
                            }
                        ]
                    }
                }

                return model;
            }
        }
    }


</script>
<template>
    <div>
        <button class="btn btn-primary multi-position-exit-button" :class="{'mobile' : isMobile}" @click="$emit('clicked')">{{ buttonText }}</button>

        <modal-basic :title="title" :is-on="showModal" :isLoading="isLoading" @close="closeModal()">
            <template>
                <ExitOrderForm :dictionary="exitsDictionary"  :isModal="true" :isMultiExit="true" :positionName="positionName" :exitOrderModel="exitOrderModel"
                  @form-closed="closeModal()" />
            </template>
        </modal-basic>
    </div>
</template>

<script>
import ExitOrderForm from "../../../Exits/Assets/Vue/exit-order-form.component.vue";

export default {
  components: {
    ExitOrderForm,
  },
  props: {
    isModalVisible: Boolean,
    buttonText: String,
    positionName: String,
    exitsDictionary: Object,
    exitOrderModel: Object,
    isLoading: Boolean,
    isMobile: Boolean
  },
  computed: {
    showModal() {
      return this.isModalVisible;
    },
    title() {
      return this.exitsDictionary && this.exitsDictionary.widgets ? this.exitsDictionary.widgets.placeOrder : '';
    }
  },
  methods: {
    closeModal() {
      this.$emit("closed"); 
    },
  },
};
</script>
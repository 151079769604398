var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading-spinner", { attrs: { active: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            _c("div", { staticClass: "portlets-wrapper" }, [
              _c(
                "div",
                { staticClass: "row m-0", attrs: { "data-toggle": "portlet" } },
                [
                  _c("portlet-layout", {
                    attrs: {
                      "column-class": "col-md-6",
                      title: _vm.choiceInstructionModel.title
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function() {
                            return [
                              _c("ChoiceInstructionForm", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  choiceInstructionModel:
                                    _vm.choiceInstructionModel,
                                  isSubmitting: _vm.isSubmitting,
                                  error: _vm.error,
                                  v: _vm.$v
                                },
                                on: {
                                  "accept-instruction": _vm.acceptInstruction,
                                  "decline-instruction": _vm.declineInstruction,
                                  "scenario-selected": _vm.onScenarioSelected,
                                  reset: _vm.resetValidation
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      97443791
                    )
                  }),
                  _vm._v(" "),
                  _vm.showDisclaimersPortlet
                    ? _c("portlet-layout", {
                        attrs: {
                          id: "disclaimers",
                          "column-class": "col-md-6",
                          title: _vm.dictionary.widgets.disclaimers
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c("ChoiceInstructionDisclaimers", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      choiceInstructionModel:
                                        _vm.choiceInstructionModel,
                                      v: _vm.$v
                                    },
                                    on: {
                                      "accept-instruction":
                                        _vm.acceptInstruction,
                                      "decline-instruction":
                                        _vm.declineInstruction
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3163040910
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
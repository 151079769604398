<template>
  <div>
    <beneficiary-data-label :value="addressDataModel.addressProperty" :label="dictionary.address"></beneficiary-data-label>
    <beneficiary-data-label :value="addressDataModel.complementaryAddress" :label="dictionary.complementaryAddress"></beneficiary-data-label>
    <beneficiary-data-label :value="addressDataModel.postalCode" :label="dictionary.postalCode"></beneficiary-data-label>
    <beneficiary-data-label :value="addressDataModel.city" :label="dictionary.city"></beneficiary-data-label>
    <beneficiary-data-label :value="addressDataModel.country" :label="dictionary.country"></beneficiary-data-label>
    <beneficiary-data-label :value="addressDataModel.telephone" :label="dictionary.telephone"></beneficiary-data-label>
  </div>
</template>

<script>

import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
    components: {
        BeneficiaryDataLabel
    },
    props: { 
        addressDataModel : Object, 
        dictionary: Object 
    },
}
</script>
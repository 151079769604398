var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "order-form-div", "aria-live": "polite" } },
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
      _vm._v(" "),
      _c("div", {
        staticClass: "mb-4 rich-text-container",
        attrs: { "aria-hidden": "true" },
        domProps: {
          innerHTML: _vm._s(
            _vm.$sanitize(_vm.formatText(_vm.choiceInstructionModel.text))
          )
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "choice-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
            }
          }
        },
        [
          _c(
            "fieldset",
            [
              _c("legend", {
                staticClass: "sr-only rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.formatText(_vm.choiceInstructionModel.text)
                    )
                  )
                }
              }),
              _vm._v(" "),
              _vm._l(_vm.choiceInstructionModel.scenarios, function(
                scenario,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: scenario.participationChoiceScenarioId,
                    staticClass: "choiceContainer"
                  },
                  [
                    scenario.answerMode.value == "csamSelect"
                      ? [
                          _c("ChoiceScenarioAnswerModeSelect", {
                            attrs: {
                              itemIndex: index,
                              dictionary: _vm.dictionary,
                              choiceInstructionModel:
                                _vm.choiceInstructionModel,
                              validation: _vm.v,
                              scenario: scenario
                            },
                            on: {
                              "scenario-selected": _vm.onScenarioSelected,
                              reset: _vm.onResetValidation
                            }
                          })
                        ]
                      : [
                          _c("ChoiceScenarioAnswerModeValue", {
                            attrs: {
                              itemIndex: index,
                              dictionary: _vm.dictionary,
                              choiceInstructionModel:
                                _vm.choiceInstructionModel,
                              scenario: scenario,
                              validation: _vm.v
                            },
                            on: {
                              "scenario-selected": _vm.onScenarioSelected,
                              reset: _vm.onResetValidation
                            }
                          })
                        ]
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  !_vm.choiceInstructionModel.disclaimers.length
                    ? _c("ChoiceInstructionButtonsComponent", {
                        attrs: {
                          validation: _vm.v,
                          choiceInstructionModel: _vm.choiceInstructionModel
                        },
                        on: {
                          "accept-instruction": _vm.acceptInstruction,
                          "decline-instruction": _vm.declineInstruction
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
        <div class="vesting-column text-right" v-if="position.showVestings">
            <span class="vesting-column__hide-tooltip" v-if="toggles.hideVestingsTooltip">{{ position.grantedFormatted }}</span>

            <span class="vesting-column__show-tooltip" v-else :id="spanId">
                {{ position.grantedFormatted }} <i class="far fa-info-circle popover-info" aria-hidden="true"></i>
            </span>

            <b-popover class="vesting-column__popover" v-if="!toggles.hideVestingsTooltip" :target="spanId" triggers="hover" placement="right">
                <VestingPopover :position="position" :dictionary="dictionary" :toggles="toggles" />
            </b-popover>
        </div>
</template>

<script>
import VestingPopover from './vesting-popover.component.vue';
import { v4 as uuid } from 'uuid';

export default {
    components: {
        VestingPopover
    },
    props: {
        position: Object,
        toggles: Object,
        dictionary: Object
    },
    computed: {
        spanId() {
            return `vesting-popover-target_${uuid()}`;
        }
    }
}
</script>
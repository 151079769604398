import { required, requiredIf } from "vuelidate/lib/validators";
import { mustBeDecimal, mustBeModulo, mustBeMinimum, mustBeLesserThan, mustBeGreatherThan } from './custom-validator'
import numeral from 'numeral'

export const buyOrderFormValidations = {
    validations() {
        return {
            model: {
                executionMoment: { required },
                limitPrice: {
                    required: requiredIf(function () {
                        return !this.hideLimitPrice;
                    }),
                    mustBeDecimal,
                    mustBeModulo: function (value) {
                        return mustBeModulo(value, this.moduleBounderie.limitPriceModulo);
                    }
                },
                nrOrdered: {
                    required: requiredIf(function () {
                        return !this.moduleBounderie.isInstructionInMoney;
                    }),
                    mustBeDecimal,
                    mustBeModuloMinimum: function (value) {
                        return mustBeModulo(value, this.moduleBounderie.transferModulo)
                            && mustBeMinimum(value, this.moduleBounderie.transferMinimum);
                    }
                },
                amountOrdered: {
                    required: requiredIf(function () {
                        return this.moduleBounderie.isInstructionInMoney;
                    }),
                    mustBeDecimal,
                    mustBeModuloMinimum: function (value) {
                        return mustBeModulo(value, this.moduleBounderie.transferModulo)
                            && mustBeMinimum(value, this.moduleBounderie.transferMinimum);
                    }
                }
            }
        }
    }
}

export const moneyTransferFormValidations = {
    validations() {
        return {
            model: {
                executionMoment: { required },
                amount: {
                    required,
                    mustBeDecimal,
                    mustBeLesserThanAvailableQuantity: function (value) {
                        if (value) {
                            value = value.replace(/,/g, '.')
                        }
                        return mustBeLesserThan(value, this.moneyTransferPosition.available);
                    },
                    mustBeGreatherThanZero: function (value) {
                        if (value) {
                            value = value.replace(/,/g, '.')
                        }
                        return mustBeGreatherThan(value, 0);
                    }
                },
            }
        }
    }
}

export const ieOrderFormValidations = {
    validations() {
        return {
            model: {
                internalExchangeBoundaries: {
                    hasMaxConcurrentOpenOrders: {
                        mustBeFalse: function (value) {
                            return !value;
                        }
                    }
                },
                orderType: { required },
                limitPrice: {
                    required,
                    mustBeDecimal,
                    mustBeGreatherThanZero: function (value) {
                        if (value) {
                            value = value.toString().replace(/,/g, '.')
                        }
                        return mustBeGreatherThan(value, 0);
                    },
                    mustBeModulo: function (value) {
                        return mustBeModulo(value, this.model.internalExchangeBoundaries.limitPriceModulo);
                    }
                },
                quantity: {
                    required,
                    mustBeDecimal,
                    mustBeModuloMinimum: function (value) {
                        return mustBeModulo(value, this.model.internalExchangeBoundaries.transferModulo)
                            && mustBeMinimum(this.model.internalExchangeBoundaries.transferMinimum);
                    },
                    mustBeValidMoneyAmount: function (value) {
                        if (!!value && !!this.model && this.model.orderType === 'Buy') {
                            let quantity = numeral(value).value();
                            let moneyAmount = quantity;
                            if (!!this.model.limitPrice) {
                                moneyAmount = quantity * numeral(this.model.limitPrice).value();
                            }
                            return !(moneyAmount > this.model.internalExchangeBoundaries.availableMoneyAmount && !this.model.internalExchangeBoundaries.negativePositionAllowed);
                        }
                        return mustBeDecimal(value);
                    },
                    mustBeValidSecurityAmount: function (value) {
                        if (!!value && !!this.model && this.model.orderType === 'Sell') {
                            var securityAmount = numeral(value).value();
                            return !(securityAmount > this.model.internalExchangeBoundaries.availableSecurityAmount);
                        }
                        return mustBeDecimal(value);
                    }
                }
            }
        }
    }
}

export const exitOrderFormValidations = {
    validations() {
        return {
            model: {
                authorityCheckbox: {
                    required: requiredIf(function () {
                        return this.toggles.useExitsOrderAuthorityToDeal;
                    }),
                },
                exitExecutionMoment: { required },
                limitPrice: {
                    required: requiredIf(function () {
                        return !this.hideLimitPrice;
                    }),
                    mustBeDecimal,
                    mustBeModulo: function (value) {
                        return mustBeModulo(value, this.model.exitInstructionBoundaries.limitPriceModulo);
                    }
                },
                quantity: {
                    required: requiredIf(function () {
                        return this.quantityType !== this.$QuantityInputType.ALL
                            && this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                    }),
                    mustBeDecimal: function (value) {
                        return this.quantityType !== this.$QuantityInputType.SELECT
                            || mustBeDecimal(value);
                    },
                    mustBeModuloMinimum: function (value) {
                        return this.quantityType !== this.$QuantityInputType.SELECT
                            || (mustBeModulo(value, this.model.exitInstructionBoundaries.transferModulo)
                                && mustBeMinimum(this.model.exitInstructionBoundaries.transferMinimum));
                    },
                    mustBeValidSecurityAmount: function (value) {
                        if (!!value && !!this.model && this.quantityType === this.$QuantityInputType.SELECT) {
                            var securityAmount = numeral(value).value();
                            return securityAmount <= (this.isMultiExit ? this.model.availableAmountScenario : this.model.available);
                        }
                        return mustBeDecimal(value);
                    }
                },
                quantityAll: {
                    required: requiredIf(function () {
                        return this.quantityType === this.$QuantityInputType.ALL
                            && this.model.quantityAllAndSelectSelect !== null
                    }),
                    mustBeDecimal: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL
                            || mustBeDecimal(value);
                    },
                    mustBeModuloMinimum: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL
                            || (mustBeModulo(value, this.model.exitInstructionBoundaries.transferModulo)
                                && mustBeMinimum(this.model.exitInstructionBoundaries.transferMinimum));
                    },
                    mustBeValidSecurityAmount: function (value) {
                        if (!!value && !!this.model && this.quantityType === this.$QuantityInputType.ALL) {
                            var securityAmount = numeral(value).value();
                            return securityAmount <= (this.isMultiExit ? this.model.availableAmountScenario : this.model.available);
                        }
                        return mustBeDecimal(value);
                    }
                },
                quantityAllAndSelectSelect: {
                    required: requiredIf(function () {
                        return this.quantityType === this.$QuantityInputType.ALL_AND_SELECT
                            && this.model.quantityAllAndSelectSelection === this.$QuantityInputType.SELECT;
                    }),
                    mustBeDecimal: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                            || mustBeDecimal(value);
                    },
                    mustBeModuloMinimum: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                                || (mustBeModulo(value, this.model.exitInstructionBoundaries.transferModulo)
                                    && mustBeMinimum(this.model.exitInstructionBoundaries.transferMinimum));
                    },
                    mustBeValidSecurityAmount: function (value) {
                        if (!!value && !!this.model && this.quantityType === this.$QuantityInputType.ALL_AND_SELECT) {
                            var securityAmount = numeral(value).value();
                            return securityAmount <= (this.isMultiExit ? this.model.availableAmountScenario : this.model.available);
                        }
                        return mustBeDecimal(value);
                    }
                }
            }
        }
    }
}
<template>
    <section>
        <modal-basic id="modal-cancel-order-mobile" :title="title" :is-on="isModalVisible" @close="$emit('closeCancelDialog')">
            <template v-slot:footer>
                <form id="cancel-order-form">
                    <div class="form-group button-container d-md-flex justify-content-md-end">
                        <button type="button" class="btn primaryButton confirm-cancel-order-mobile-no"
                                @click="$emit('closeCancelDialog')">
                            {{ dictionary.ordersPageBtnLabelCancelNo }}
                        </button>
                        <button type="button" class="btn secondaryButton confirm-cancel-order-mobile-yes"
                                @click="submitCancelOrder()">
                            {{ dictionary.ordersPageBtnLabelCancelYes }}
                        </button>
                    </div>
                </form>
            </template>
        </modal-basic>
    </section>
</template>

<script>

    import { post } from "api";
    import { EventBus } from "Shared/event-bus";

    export default {
        props: {
            instructionData: Object,
            dictionary: Object,
            title: String,
            isModalVisible: Boolean
        },
        methods: {
            submitCancelOrder() {

                post("/InternalExchanges/CancelInstruction",
                    { InstructionId: this.instructionData.InternalExchangeInstructionId },
                    () => {},
                    this,
                    () => {
                        this.$emit('closeCancelDialog');
                        EventBus.$emit("reload-orders-items");
                    }
                );
            }
        }
    };
</script>
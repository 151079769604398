import ChoiceContainer from "./choice-container.component.vue";
import Choice from './choice.component.vue';
import ChoiceInstruction from './choice-instruction.component.vue';


export const choicesRoutes =
{
    path: '/choices',
    component: ChoiceContainer,
    children: [
        {
            path: '',
            component: Choice
        }, {
            path: 'instruction',
            component: ChoiceInstruction
        },
        {
            path: '*',
            redirect: '/choices'
        }
    ]
}

import RequiredInfoCrs from "./required-info-crs.component.vue";
import RequiredInfoMifid from "./required-info-mifid.component.vue";
import RequiredInfoContainer from "./required-info-container.component.vue";

export const requiredInfoRoutes = {
    path: '/requiredInfo',
    name: 'required-info',
    component: RequiredInfoContainer,
    children: [
        {
            path: 'crs',
            name: 'required-info-crs',
            component: RequiredInfoCrs,
            meta: { bodyModal: true }
        },
        {
            path: 'mifid',
            name: 'required-info-mifid',
            component: RequiredInfoMifid,
            meta: { bodyModal: true }
        },
        {
            path: '*',
            redirect: '/requiredInfo'
        }
    ],
};
<template>
<div>
   <v-data-table
        class="table-striped"
        show-expand
        single-expand
        :no-data-text="dictionary.table.noRecordFound"
        item-key="dividendParticipationId"
        :hide-default-header="isMobile"
        :headers="computedHeaders"
        :items="rows"
        :loading="isLoading"
        :server-items-length="totalRecords"
        :search="search"
        :items-per-page="options.itemsPerPage"
        @page-count="options.pageCount = $event;"
        :options.sync="options"
        hide-default-footer
        >       
        <!-- Search input at top --->
        <template v-slot:top>
            <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
        </template>

        <!-- Expanded item template (dividend details) --->
        <template v-slot:expanded-item="{ headers, item }">
            <td class="expanded-item-td" :colspan="headers.length">
                <DividendTableExpandedItem :dictionary="dictionary" :dividend="item" :toggles="toggles" :isTablet="isTablet" />
            </td>
        </template>

        <!-- Datatable template for mobile view before data rows --->
        <template v-if="isMobile" v-slot:[`body.prepend`]>
            <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                    v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
        </template>
        <!-- Datatable template for mobile view of data rows --->
        <template v-if="isMobile" v-slot:item="{ item }">
            <DividendDetailsMobileContent :dictionary="dictionary" :dividend="item" :toggles="toggles" />
        </template>

        <template v-slot:footer>
            <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
        </template>
    </v-data-table>


</div>
</template>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
import DividendTableExpandedItem from "./dividend-table-expanded-item.component.vue";
import DividendDetailsMobileContent from "./dividend-details-mobile-content.component.vue";

export default {
    mixins: [vueDataTableMixin, ScreenSizeMixin],
    components: {
        DividendTableExpandedItem,
        DividendDetailsMobileContent
    },
    props: {
        dictionary: Object,
        datatablesDictionary: Object,
        toggles: Object
    },
    data() {
        return {
            isTablet: false,
            isMobile: false,            
            nonCriticalHeaders: [],
            getUrl: '/Dividend/GetAllDividendParticipations',
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["distributionDate"],
                sortDesc: [true],
                pageCount: 0,
                itemsPerPageOptions: [
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: this.datatablesDictionary.showAll, value: -1 }
                ]
            },
            headers: [
                { value: 'distributionDate', text: this.dictionary.table.distributionDate, align: 'left', sortable: true },
                { value: 'securityName', text: this.dictionary.table.securityName, align: 'left', sortable: true },
                { value: 'nrSharesFormatted', text: this.dictionary.table.nrShares, align: 'right', sortable: true },
                { value: 'grossAmountFormatted', text: this.dictionary.table.grossAmount, align: 'right', sortable: true },
                { value: 'taxAmountFormatted', text: this.dictionary.table.tax, align: 'right', sortable: true },
                { value: 'netAmountFormatted', text: this.dictionary.table.netAmount, align: 'right', sortable: true },
                { value: 'amountToReceiveFormatted', text: this.dictionary.table.amountToReceive, align: 'right', sortable: true },
                { value: 'netQuantityFormatted', text: this.dictionary.table.netQuantity, align: 'right', sortable: true },
                { value: 'data-table-expand', text: this.dictionary.table.details, align: 'center', sortable: false }
            ],
            rows: [],
            totalRecords: 0
        }
    },
    computed: {
        computedHeaders() { 
            let hiddenColumns =[];
            if(this.toggles.hideStockDividendColumn){        
                hiddenColumns.push(this.dictionary.table.netQuantity);
            }
            if(this.toggles.hideCashDividendColumn){        
                hiddenColumns.push(this.dictionary.table.amountToReceive);
            }                
            if(this.isTablet){        
                hiddenColumns.push(this.dictionary.table.grossAmount);
                hiddenColumns.push(this.dictionary.table.netAmount);
                hiddenColumns.push(this.dictionary.table.tax);
            }

            return this.headers.filter(x => !hiddenColumns.includes(x.text));           
        },
        sortableColumns() {
            return this.computedHeaders.filter(x => x.sortable);
        }
    },
}
</script>
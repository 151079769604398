<template>
    <p class="mb-3">
        <strong>
            {{ dictionary.confirmation.quoteCountDownText }}
            <span class="text-countdown" role="timer">{{ timerValue }}</span>
            {{ dictionary.confirmation.quoteCountDownSeconds }}
        </strong>
    </p>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            timerValue: Number,
        }
    };
</script>
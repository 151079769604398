var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    [
      _c("strong", { staticClass: "form-input-label exercises-data_label" }, [
        _vm._v(_vm._s(_vm.label) + ": ")
      ]),
      _c("span", { staticClass: "exercises-data_value" }, [
        _vm._v(_vm._s(_vm.value))
      ]),
      _vm._v(" "),
      _vm.hasTooltip && !_vm.hideSecurityPriceInformationTooltip
        ? [
            _c("i", {
              staticClass: "far fa-info-circle popover-info",
              attrs: { id: _vm.tooltipId, "aria-hidden": "true" }
            }),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                attrs: {
                  target: _vm.tooltipId,
                  triggers: "hover",
                  placement: "top"
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
   <router-link v-if="!isMobile" id="portfolio-overview" to="/Reports/PortfolioOverview" role="button"><span class="title-action-icon" aria-hidden="true"><i class="far fa-calculator"></i></span>{{dictionary.page.portfolioBtnPORCalculatorDesktop}}</router-link>
   <router-link v-else-if="isMobile" id="portfolio-overview" to="/Reports/PortfolioOverview" role="button"><span class="title-action-icon" aria-hidden="true"><i class="far fa-calculator"></i></span>{{dictionary.page.portfolioBtnPORCalculatorMobile}}</router-link>
</template>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

export default {
  mixins:[ScreenSizeMixin],
  props: {
    dictionary:Object
  },
  data() {
    return { 
      isMobile : false,
      isTablet: false
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "no-data-text":
            _vm.dictionary.rows.transactionsTblRecordNotFoundTransactions,
          "hide-default-header": _vm.isMobile || _vm.isTablet,
          headers: _vm.computedHeaders,
          items: _vm.rows,
          "item-key": "reference",
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search-header", {
                    staticClass: "mb-3",
                    attrs: {
                      label: _vm.datatablesDictionary.searchByKeywords,
                      description: _vm.dictionary.page.transactionsWidgetText
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._v(" "),
                  _vm._t("header"),
                  _vm._v(" "),
                  _c("TransactionOverviewHeader", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      transaction: _vm.transaction,
                      isMobile: _vm.isMobile
                    }
                  })
                ]
              },
              proxy: true
            },
            _vm.isMobile || _vm.isTablet
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.sortableColumns,
                          dictionary: _vm.datatablesDictionary
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile || _vm.isTablet
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("TransactionDetailsMobileContent", {
                        attrs: {
                          toggles: _vm.toggles,
                          dictionary: _vm.dictionary,
                          item: item
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="portfolio-row-flex">
        <p class="col portfolio-row__label font-weight-bold">{{ label }}</p>
        <p :class="['col portfolio-row__value', { 'text-right' : textRight }]">{{ value }}</p>
    </div>
</template>

<script>
    export default {
        name:'portfolio-mobile-row',
        props: {
            label: String,
            value: String | Number,
            textRight: Boolean
        }
    }
</script>
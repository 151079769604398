var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "LoginTemplate",
        [
          !_vm.isPostSuccessful
            ? [
                _c("template", { slot: "title" }, [
                  _c("h1", { staticClass: "sr-only welcome-text" }, [
                    _vm._v(_vm._s(_vm.getUserNameTitle))
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "text-center py-2 welcome-text",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v(_vm._s(_vm.getUserNameTitle))]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-left pb-2" }, [
                    _vm._v(_vm._s(_vm.getUserNameText))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "body" },
                  [
                    _vm.error
                      ? _c("alert-component", { attrs: { variant: "error" } }, [
                          _vm._v(_vm._s(_vm.error))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "mb-lg-3",
                        attrs: { id: "userNameRecovery" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.SubmitUserNameRecovery($event)
                          }
                        }
                      },
                      [
                        _vm._l(_vm.bindModel, function(field) {
                          return _c("div", { key: field.index }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                field.type === "fuatDateOfBirth"
                                  ? _c("datepicker-element", {
                                      attrs: {
                                        "is-mandatory": field.isMandatory,
                                        id: _vm.gethtmlId(field.label),
                                        yearRange: "-100:+0",
                                        label: field.label,
                                        validationsList: _vm.requiredFieldValidation(
                                          field.index
                                        )
                                      },
                                      model: {
                                        value:
                                          _vm.forgottenUsernameViewModel
                                            .forgottenUsernameAttributeViewModels[
                                            field.index
                                          ].value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.forgottenUsernameViewModel
                                              .forgottenUsernameAttributeViewModels[
                                              field.index
                                            ],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "forgottenUsernameViewModel.forgottenUsernameAttributeViewModels[field.index].value"
                                      }
                                    })
                                  : _c(
                                      "div",
                                      [
                                        _c("input-element", {
                                          attrs: {
                                            type: "text",
                                            label: field.label,
                                            name: _vm.gethtmlId(field.label),
                                            "is-mandatory": field.isMandatory,
                                            validationsList: _vm.requiredFieldValidation(
                                              field.index
                                            )
                                          },
                                          model: {
                                            value:
                                              _vm.forgottenUsernameViewModel
                                                .forgottenUsernameAttributeViewModels[
                                                field.index
                                              ].value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.forgottenUsernameViewModel
                                                  .forgottenUsernameAttributeViewModels[
                                                  field.index
                                                ],
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "forgottenUsernameViewModel.forgottenUsernameAttributeViewModels[field.index].value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "* " +
                              _vm._s(_vm.dictionary.page.loginLabelMandatory)
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-md-flex justify-content-md-between button-container"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn mt-3 secondaryButton login-button",
                                attrs: { type: "button" },
                                on: { click: _vm.back }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.commonDictionary.btnGoBack) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn mt-3 primaryButton login-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.SubmitUserNameRecovery()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.commonDictionary.btnContinue) +
                                    "\n                    "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "template",
                  { slot: "body" },
                  [
                    _c("UserNameRecoverySuccessful", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        "common-dictionary": _vm.commonDictionary
                      }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.model
    ? _c(
        "section",
        [
          !!_vm.model.payments
            ? _vm._l(_vm.model.payments, function(payment, index) {
                return _c(
                  "div",
                  { key: "payment-" + index, staticClass: "row" },
                  [
                    _c("div", { staticClass: "column-title col" }, [
                      _c("p", { staticClass: "font-italic" }, [
                        _vm._v(_vm._s(payment.name))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column-result col text-right" }, [
                      _c("p", { staticClass: "red-text" }, [
                        _vm._v(_vm._s(payment.netAmountFormatted))
                      ])
                    ])
                  ]
                )
              })
            : !!_vm.model.exerciseDeliveries
            ? _vm._l(_vm.model.exerciseDeliveries, function(
                exerciseDelivery,
                index
              ) {
                return _c(
                  "div",
                  { key: "exerciseDelivery-" + index, staticClass: "row" },
                  [
                    _c("div", { staticClass: "column-title col" }, [
                      _c("p", { staticClass: "font-italic" }, [
                        _vm._v(
                          _vm._s(
                            exerciseDelivery.securityIdentificationDescription
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column-result col text-right" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(exerciseDelivery.nrSharesToDeliverFormatted)
                        )
                      ])
                    ])
                  ]
                )
              })
            : !!_vm.model.value
            ? [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "column-title col" }, [
                    _c("p", { staticClass: "font-italic" }, [
                      _vm._v(_vm._s(_vm.model.label))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column-result col text-right" }, [
                    _c("p", { staticClass: "red-text" }, [
                      _vm._v(_vm._s(_vm.model.value))
                    ])
                  ])
                ])
              ]
            : [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "column-title col" }, [
                    _c("p", { staticClass: "font-italic" }, [
                      _vm._v(_vm._s(_vm.noDataLabel))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-result col text-right" }, [
      _c("p", { staticClass: "red-text" }, [_vm._v("-")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
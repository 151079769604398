var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footer-container", attrs: { id: _vm.footerId } },
    [
      _c("component-spinner", {
        attrs: { "secondary-color": "", spin: _vm.isLoading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          {
            staticClass: "footer-header",
            attrs: { id: "footerImageContainer" }
          },
          [
            _c("div", { staticClass: "footer-sub-header" }, [
              !_vm.toggles.hideFooterLogo
                ? _c("div", { staticClass: "footer-image-container pr-6" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.getAppropriateLogo,
                        alt: _vm.dictionary.footerLogoAltText
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "align-self-center footer-info-container",
                  attrs: { id: "footerInfoContainer" }
                },
                [_c("FooterInfo", { attrs: { model: _vm.model } })],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "align-self-center footer-links" },
              [
                _c("FooterLinks", {
                  attrs: { dictionary: _vm.dictionary, toggles: _vm.toggles }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        !_vm.toggles.hideFooterCustomText
          ? _c(
              "div",
              {
                staticClass: "footer-text-container",
                attrs: { id: "footerTextContainer" }
              },
              [
                _c("p", {
                  staticClass: "rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(_vm.dictionary.footerCustomText)
                    )
                  }
                })
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.dictionary.widget.titleHistory))]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading && _vm.tableHasNoRecords
        ? _c("div", { staticClass: "empty-dataTable" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.dictionary.table.recordNotFoundHistoricEmissions)
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.rows.length > 0 || _vm.isSearchDirty,
            expression: "rows.length > 0 || isSearchDirty"
          }
        ],
        staticClass: "table-striped",
        attrs: {
          "no-data-text": _vm.dictionary.table.recordNotFoundHistoricEmissions,
          "item-key": "participationSubscriptionId",
          "hide-default-header": _vm.isMobile,
          headers: _vm.customHeaders,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("SubscriptionNameLabel", {
                    attrs: {
                      name: item.name,
                      planName: item.planName,
                      isLink: true
                    },
                    on: {
                      action: function($event) {
                        return _vm.openDetailsModal(item)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.granted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.replaceNullOrEmpty(item.granted)))
                  ])
                ]
              }
            },
            {
              key: "item.subscribed",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.replaceNullOrEmpty(item.subscribed)))
                  ])
                ]
              }
            },
            {
              key: "item.feesPrice",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.replaceNullOrEmpty(item.feesPrice)))
                  ])
                ]
              }
            },
            {
              key: "item.contributionAmountFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.replaceNullOrEmpty(item.contributionAmountFormatted)
                      )
                    )
                  ])
                ]
              }
            },
            {
              key: "item.securityName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.replaceNullOrEmpty(item.securityName)))
                  ])
                ]
              }
            },
            {
              key: "item.subscriptionPeriodEndDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("toDate")(item.subscriptionPeriodEndDate))
                    )
                  ])
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.customHeaders,
                          dictionary: _vm.datatablesDictionary
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("SubscriptionMobileContent", {
                        attrs: { dictionary: _vm.dictionary },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("SubscriptionNameLabel", {
                                    attrs: {
                                      name: item.name,
                                      planName: item.planName
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  !_vm.toggles.hideEmissionsGrantedColumn
                                    ? _c("SubscriptionMobileRow", {
                                        attrs: {
                                          label: _vm.dictionary.table.granted,
                                          value: _vm.replaceNullOrEmpty(
                                            item.granted
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("SubscriptionMobileRow", {
                                    attrs: {
                                      label: _vm.dictionary.table.subscribed,
                                      value: _vm.replaceNullOrEmpty(
                                        item.subscribed
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("SubscriptionMobileRow", {
                                    attrs: {
                                      label: _vm.dictionary.table.feesPrice,
                                      value: _vm.replaceNullOrEmpty(
                                        item.feesPrice
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("SubscriptionMobileRow", {
                                    attrs: {
                                      label:
                                        _vm.dictionary.table.contributionAmount,
                                      value: _vm.replaceNullOrEmpty(
                                        item.contributionAmountFormatted
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("SubscriptionMobileRow", {
                                    attrs: {
                                      label: _vm.dictionary.table.securityName,
                                      value: _vm.replaceNullOrEmpty(
                                        item.securityName
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("SubscriptionMobileRow", {
                                    attrs: {
                                      label:
                                        _vm.dictionary.table.subscriptionDate,
                                      value: _vm._f("toDate")(
                                        item.subscriptionPeriodEndDate
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primaryButton",
                                      on: {
                                        click: function($event) {
                                          return _vm.openDetailsModal(item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary.widget.labelHeaderTitle
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("OfferingDetailsModal", {
        attrs: {
          dictionary: _vm.dictionary,
          toggles: _vm.toggles,
          model: _vm.modalModel,
          isModalVisible: _vm.isModalVisible
        },
        on: {
          close: function($event) {
            return _vm.close()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customCollapse-container" }, [
    _c(
      "h4",
      { staticClass: "mb-1" },
      [
        _vm._v(
          _vm._s(_vm.dictionary.orderBook.labelOrderbookProvisionalPrice) +
            "\n        "
        ),
        this.instructionModel.internalExchangeBoundaries.hasCalculatedBestPrice
          ? _c("i", {
              staticClass: "far fa-info-circle popover-info",
              attrs: {
                id: "labelOrderbookProvisionalPrice",
                "aria-hidden": "true"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        this.instructionModel.internalExchangeBoundaries.hasCalculatedBestPrice
          ? _c(
              "b-popover",
              {
                attrs: {
                  target: "labelOrderbookProvisionalPrice",
                  triggers: "hover",
                  placement: "top"
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.dictionary.orderBook.tooltipOrderbookProvisionalPrice
                    ) +
                      " " +
                      _vm._s(
                        _vm._f("toDateTime")(
                          _vm.instructionModel.internalExchangeBoundaries
                            .calculatedBestPriceDateTime
                        )
                      )
                  )
                ])
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "customCollapse-calculated-best-price" }, [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.instructionModel.internalExchangeBoundaries
              .calculatedBestPriceFormatted
          ) +
          "\n    "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="tab-row offerings">
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{pagesDictionary.offerings.title}}</h1>
        </div>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-show="dictionary" >
            <b-tabs content-class="mt-0" class="tab-container tab-bar" ref="tabs">
                <portlet-tab v-if="dictionary" :tabTitle="dictionary.widget.tabOpen" :isActive="true" tabId="tabOpenOfferings">
                    <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.offeringsPageDescriptionOpen)"></div>
                    <OpenContributionTable class="open-contribution-table" :dictionary="dictionary" :datatablesDictionary="dictionary.datatables"/>
                    <OpenTable :dictionary="dictionary" :datatablesDictionary="dictionary.datatables" :toggles="toggles" />
                </portlet-tab>
                <portlet-tab v-if="dictionary" :tabTitle="dictionary.widget.tabHistory" tabId="tabHistoryOfferings">
                    <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.offeringsPageDescriptionHistory)"></div>
                    <HistoricalTable :dictionary="dictionary" :datatablesDictionary="dictionary.datatables" :toggles="toggles" />
                </portlet-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import OpenContributionTable from "./offerings-open-contribution-table.component.vue";
    import OpenTable from "./offerings-open-table.component.vue";
    import HistoricalTable from "./offerings-historical-table.component.vue";

    export default {
        components: {
            OpenContributionTable,
            OpenTable,
            HistoricalTable
        },
        props: {
            dictionary: Object,
            toggles: Object,
            isLoading: Boolean,
            pagesDictionary: Object
        },
        beforeMount() {
           this.$nextTick(() => {
                if (this.$route.hash && this.$refs.tabs) {
                    var params = this.$route.hash.split("&");
                    this.$refs.tabs.currentTab = params.includes("#history") ? 1 : 0;
                    this.$router.replace({hash: ''});
                }
            });
        }
    };
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNotNullable()
    ? _c(
        "div",
        [
          !_vm.isMobile
            ? _c("div", { staticClass: "orders-listitem-container" }, [
                _c("dt", [_vm._v(_vm._s(_vm.label))]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.value))])
              ])
            : _vm.isNotNullabledLabelAndValue()
            ? _c("OrdersDetailsContentRow", {
                attrs: { label: _vm.label, value: _vm.value }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
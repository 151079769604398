<template>
    <div v-if="isVisible" class="beneficiary-details form-group">
        <label :for="name" class="form-input-label">{{ label }}</label>
        <input :type="getInputType()" 
        v-on:input="handleInput" 
        :value="value" class="form-control" 
        :id="name" :name="name"
        :disabled="!isEditable"/>
        <span class="beneficiary-edit-group__error-label" v-show="getErrorKey()">{{ getErrorMessage() }}</span>
    </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    name:String,
    isEditable: Boolean,
    isVisible:Boolean,
    errorKeys: Array,
    validations: Array,
    type: String,
  },
  methods: {
    getInputType: function () {
      return this.type ? this.type : "text";
    },
    getErrorKey: function () {
      return this.errorKeys
        ? this.errorKeys.filter(
            (x) => x.toLowerCase() === this.name.toLowerCase()
          )[0]
        : null;
    },
    getErrorMessage: function () {
      return this.validations
        ? this.validations
            .filter((x) => x.key.toLowerCase() === this.name.toLowerCase())
            .map((x) => x.errorMessage)[0]
        : null;
    },
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
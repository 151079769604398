<template>
    <LoginTemplate v-if="dictionary">
        <template>
            <template slot="title">
                <h1 class="sr-only welcome-text">{{ dictionary.page.changePassword }}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{ dictionary.page.changePassword }}</p>
                <hr>
            </template>
            <template slot="body">
                <div class="text-left py-2 rich-text-container" v-html="$sanitize(dictionary.changePasswordSuccess.successMessage)"></div>
                <button type="button" @click="back" class="btn float-right btn-primary mt-5 secondary-background-color">
                    {{ dictionary.changePasswordSuccess.linkToLogin }}
                </button>
            </template>
        </template>
    </LoginTemplate>
</template>
<script>

    import { get } from "api";
    import LoginTemplate from "../login-template.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: { LoginTemplate },
        data() {
            return {
                dictionary: null
}
        },
        beforeRouteEnter(to, from, next) {
            const referrer = document.referrer ?? '';
            const previousPathName = from.path ?? '';

             if (!["changePassword", "passwordExpired"].some(el => referrer.includes(el) || previousPathName.includes(el))) {
                location.replace(`/`);
            } else {
                 next();
           }
        },
        created() {
                this.loadDictionaries();
        },
        mounted() {
            EventBus.$on("event-language-changed", () => {
                this.loadDictionaries();
            });
        },
        methods: {
            back() {
                location.replace(`/login`);
            },
            loadDictionaries() {
                get(
                    "/Account/Dictionary"
                    , (responses) => {
                        this.dictionary = responses.data;
                    },
                    this,
                    () => { this.isLoading = false; },
                    () => { this.$router.push({ path: '/login' }); });
            }
        }
    }
</script>
<template>
    <portlet-layout v-if="!!dictionary.widgets && !!ordersDictionary" class="w-100" :title="dictionary.widgets.lastOrders">
        <template slot="body">
            <last-orders :toggles="toggles" />
            <order-detail :title="ordersDictionary.modal.headerTitle" :toggles="toggles"></order-detail>
        </template>
        <template slot="footerLink">
            <router-link class="portlet-title-see-all-link" to="/orders" :title="dictionary.widgets.seeAllOrders">{{dictionary.widgets.seeAllOrders}}</router-link>
        </template>
    </portlet-layout>
</template>

<script>
    import OrderDetail from "/Features/Orders/Assets/Vue/order-detail.component.vue";

    export default {
        components: {
            OrderDetail,
        },
        props: {
            dictionary: Object,
            toggles: Object,
            ordersDictionary: Object
        }
    }
</script>
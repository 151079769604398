var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon-details-content" },
    [
      _c("CouponDataLabelRow", {
        attrs: { label: _vm.dictionary.table.state, value: _vm.coupon.state }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.recordDate,
          value: _vm.coupon.recordDate
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.deliveryDescription,
          value: _vm.coupon.deliveryDescription
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.deliveryInstructionReference,
          value: _vm.coupon.deliveryInstructionReference
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.deliveryInstructionDate,
          value: _vm.coupon.deliveryInstructionDate
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.securityName,
          value: _vm.coupon.securityName
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.nrSecurities,
          value: _vm.coupon.nrSecuritiesFormatted
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.grossAmount,
          value: _vm.coupon.grossAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.feesAmount,
          value: _vm.coupon.feesAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("CouponDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.netAmount,
          value: _vm.coupon.netAmountFormatted
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="relationship-details-content">
    <EvaluationDataLabelRow :label="dictionary.relationName" :value="relationship.relatedBeneficiaryName" />
    <EvaluationDataLabelRow :label="dictionary.relationBirthDate" :value="relationship.relatedBeneficiaryBirtDate" />
    <EvaluationDataLabelRow :label="dictionary.relationshipType" :value="relationship.relationshipType" />
    <EvaluationDataLabelRow :label="dictionary.relationIdentifier" :value="relationship.relatedBeneficiaryIdentifier" />
    <EvaluationDataLabelRow :label="dictionary.relationBeginDate" :value="relationship.beginDate" />
    <EvaluationDataLabelRow :label="dictionary.relationEndDate" :value="relationship.endDate" />
    <EvaluationDataLabelRow :label="dictionary.relationRemarks" :value="relationship.remarks" />
</div>
</template>

<script>
import EvaluationDataLabelRow from "./evaluation-data-label-row.component.vue";

export default {
  components: {
    EvaluationDataLabelRow
  },
  props: {
    relationship: Object,
    dictionary: Object,
  }
};
</script>
class SessionRepository {
    
    #store;
    #keyPrefix;

    constructor(store, prefix) {
        this.#store = store;
        this.#keyPrefix = prefix;
    }

    get(key) {
        this._validateKey(key);

        let returnVal = null;

        if(this.#store) {
            returnVal = JSON.parse(this.#store.getItem(this._formatKey(key)));
        }

        return returnVal;
    }

    set(key, value) {
        this._validateKey(key);
        this._validateValue(value, key);

        if(this.#store) {
            this.#store.setItem(this._formatKey(key), JSON.stringify(value));
        }
    }

    clear() {
        if(this.#store) {
            this.#store.clear();
        }
    }

    _formatKey = (key) => `${this.#keyPrefix}.${key}`;

    _validateKey(key) {
        if(typeof key !== "string") {
            throw new Error("The Session Key must be a string")
        }

        if(key.length === 0) {
            throw new Error("The Session Key must not be an empty string")
        }
    }

    _validateValue(value, key) {
        if(value === null) {
            throw new Error(`The Session value must not be null for given key ${key || "Not Provided"}`)
        }
    }
}

export default SessionRepository;
<template>
  <div>
    <template v-if="hasItems">
      <div v-if="!isMobile">
       <div class="orders-listitem-container" v-for="(payment, index) in payments" :key="index" >
            <dt>{{payment.Name}}</dt>
            <dd>{{payment.NetAmountFormatted}}</dd>
        </div>
      </div>
      <div v-else>
          <div v-for="(payment, index) in payments" :key="index"> 
               <OrdersDetailsContentRow :label="payment.Name" :value="payment.NetAmountFormatted" :textRight="true"></OrdersDetailsContentRow>
          </div>
      </div>
    </template>
    <template v-else>        
        <div v-if="!isMobile" class="orders-listitem-container">
            <dt>{{label}}</dt>
            <dd>-</dd>
        </div>
        <div v-else>
          <OrdersDetailsContentRow :label="label" :value="'-'" :textRight="true"></OrdersDetailsContentRow>
        </div>
    </template>
  </div>      
</template>

<script>

import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import OrdersDetailsContentRow from './orders-details-content-row.component.vue';

export default {
  props: {
    payments: Array,
    label:String,
  },
  components:{
    OrdersDetailsContentRow
  },
  mixins:[ScreenSizeMixin],
  methods: {
    hasItems() {
      return this.payments !== null && this.payments.length > 0;
    },
  },
};
</script>
import InternalExchangesContainer from "./internal-exchanges-container.component.vue";
import InternalExchanges from "./internal-exchanges.component.vue";
import InternalExchangesConfirmation from "./internal-exchanges-confirmation.component.vue";


export const internalExchangesRoutes =
{
    path: '/internalExchanges',
    component: InternalExchangesContainer,
    children: [
        {
            path: '',
            component: InternalExchanges
        }, {
            path: 'confirmation',
            component: InternalExchangesConfirmation
        },
        {
            path: '*',
            redirect: '/internalExchanges'
        }
    ]
}

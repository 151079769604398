<template>
    <div class="orders-row-header-wrapper">
        <div class="orders-row-flex">
            <p class="orders-row__label font-weight-bold">{{ label }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        class: [
          "toggle-button",
          "btn-sm",
          "btn-toggle",
          _vm.item.isFromToPosition ? "active" : ""
        ],
        attrs: {
          type: "button",
          "data-toggle": "button",
          "aria-pressed": [_vm.item.isFromToPosition ? true : false]
        },
        on: {
          click: function($event) {
            return _vm.submitProcessTransactionType(_vm.item)
          }
        }
      },
      [
        _c("span", { staticClass: "handle" }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [
          _vm._v(
            _vm._s(_vm.item.positionName) +
              " " +
              _vm._s(_vm.item.transactionDescription)
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div>
            <disclaimers :disclaimers="exercisesInstructionModel.disclaimers" />
        </div>
        <ExercisesConfirmationButtonsComponent :exercisesInstructionModel="exercisesInstructionModel" v-on="$listeners" />
    </div>
</template>

<script>
    import ExercisesConfirmationButtonsComponent from "./exercises-confirmation-buttons.component.vue";

    export default {
        components: {
            ExercisesConfirmationButtonsComponent
        },
        props: {
            exercisesInstructionModel: Object
        }
    };
</script>
<template>
  <div class="data-label-row-details">
      <p>{{ isMandatory ? `${label} *` :  `${label}` }}</p>
      <p>{{ displayEligibleValue() }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    displayValue: String,
    isEdit: Boolean,
    isMandatory: {
        type: Boolean,
        default: false
      }
  },
  methods: {
    isNotNullable: function () {
      return !!this.value;
    },
    displayEligibleValue: function () {
      if (!this.displayValue) {
        return this.replaceNullOrEmpty(this.value);
      }

      return this.displayValue;
    }
  }
};
</script>
<template>
    <div>
        <beneficiary-data-label :value="companyDataModel.name"  :label="dictionary.name"></beneficiary-data-label>
        <beneficiary-data-label :value="companyDataModel.registrationNumber"  :label="dictionary.registrationNumber"></beneficiary-data-label>
        <beneficiary-data-label :value="companyDataModel.rsin"  :label="dictionary.rsin"></beneficiary-data-label>
        <beneficiary-data-label v-if="isLEIVisible" :value="companyDataModel.lei"  :label="dictionary.lei"></beneficiary-data-label>
    </div>
</template>

<script>

import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
    components: {
        BeneficiaryDataLabel,
    },
    props: { 
        companyDataModel : Object, 
        dictionary: Object,
        beneficiaryBoundaries: Object
    },
    computed:{
        isLEIVisible() {
            return this.beneficiaryBoundaries.mayViewLegalEntityIdentifier;
        },
    }
}
</script>
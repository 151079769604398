<template>
    <div role="status" :aria-busy="isLoading">
        <span class="sr-only" aria-live="polite"> {{ loadingSrMessage }} </span>
    </div>
</template>

<script>

import { EventBus } from "Shared/event-bus";

export default {
    props: {
        accessibilityDictionary: Object
    },
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        loadingSrMessage() {
            if (this.accessibilityDictionary) {
                return this.isLoading ? this.accessibilityDictionary?.accessibilityNavigationIsLoading : this.accessibilityDictionary?.accessibilityNavigationIsLoaded;
            }
        }
    },
    mounted() {
        EventBus.$on('sr-loading-state', (loadingState) => {
            this.isLoading = loadingState;
        });
    }
}

</script>
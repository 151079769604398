<template>
    <div>
        <div>
            <div class="title-block" style>
                <h1 v-if="model && dictionary">{{ dictionary.page.confirmation }}</h1>
            </div>
            <div class="portlets-wrapper">
                <component-spinner :spin="isLoading"></component-spinner>
                <div v-if="model && dictionary" class="row m-0" data-toggle="portlet">
                    <portlet-layout column-class="col-xl-6" :title="dictionary.page.confirmation" >
                        <template v-slot:body>
                            <loading-spinner :active="isFormProcessing"></loading-spinner>
                            <ExitConfirmationRtd v-if="model.hasRealTimeDealingQuote && useRealTimeConfirmationWidget" :dictionary="dictionary" :toggles="toggles" :exitInstructionViewModel="model" :error="error"
                                                 :quoteExpires="quoteExpires" @on-quote-expires="updateQuoteExpires" @accept-instruction="acceptInstruction" @requote-instruction="requoteInstruction" @decline-instruction="declineInstruction"
                                                 @accept-new-quantity="newOrderQuantity" @exit-instruction-go-back="exitsInstructionGoBack" />
                            <ExitConfirmationClassic v-else :dictionary="dictionary" :toggles="toggles" :exitInstructionViewModel="model" :error="error"
                                                     @accept-instruction="acceptInstruction" @decline-instruction="declineInstruction" @exit-instruction-go-back="exitsInstructionGoBack" />
                        </template>
                    </portlet-layout>
                    <portlet-layout v-if="showDisclaimersPortlet" column-class="col-xl-6" :title="dictionary.widgets.disclaimers" >
                        <template v-slot:body>
                            <ExitConfirmationDisclaimers :exitInstructionViewModel="model" :isQuoteExpires="quoteExpires"
                                                         @accept-instruction="acceptInstruction" @requote-instruction="requoteInstruction"
                                                         @decline-instruction="declineInstruction" @exit-instruction-go-back="exitsInstructionGoBack" />
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get, post } from 'api';
    import ExitConfirmationClassic from "./exit-confirmation-classic.component.vue";
    import ExitConfirmationRtd from "./exit-confirmation-rtd.component.vue";
    import ExitConfirmationDisclaimers from "./exit-confirmation-disclaimers.component.vue";

    export default {
        components: {
            ExitConfirmationClassic,
            ExitConfirmationRtd,
            ExitConfirmationDisclaimers
        },
        props: {
            toggles: Object,
            dictionary: Object
        },
        data() {
            return {
                exitInstructionViewModel: null,
                error: null,
                quoteExpires: false,
                isLoading: true,
                isDecline: false,
                isFormProcessing: false
            };
        },
        computed: {
            model() {
                return this.exitInstructionViewModel;
            },
            showDisclaimersPortlet() {
                return this.model.disclaimers && this.model.disclaimers.length && this.model.requiresInput;
            },
            useRealTimeConfirmationWidget() {
                return this.toggles.exitsUseRealTimeConfirmationWidget;
            }
        },
        mounted() {
            if (this.$route.query.error) {
                this.error = this.dictionary.confirmation.dealAlertStockMarketClosed;
            }

            if (this.$route.query.stateDescription && this.toggles.showOrderConfirmationStateDescription) {
                this.error = this.$route.query.stateDescription;
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to.name == 'exits' && !this.isDecline) {
                this.beforeApiCall();
                post("/Exits/RejectInstruction",
                    this.prepareModel(),
                    () => { },
                    this,
                    () => {
                        next();
                    },
                    () => { });
            } else {
                next();
            }
        },
        created() {
            this.getInstructionModel();
        },
        methods: {
            getInstructionModel() {
                let query = this.$route.query;
                get(
                    `/Exits/Instruction?exitInstructionId=${query.exitInstructionId}` +
                    `&sharePlanParticipationId=${query.sharePlanParticipationId}` +
                    `&exitScenarioId=${query.exitScenarioId}` +
                    `&positionUsageId=${query.positionUsageId}`,
                    (response) => {
                        this.exitInstructionViewModel = response.data;
                        if (this.exitInstructionViewModel.state.value != 'sppesCalculationOfOrderAmount') {
                            this.exitsInstructionGoBack(this.model.positionUsageId, this.model.sharePlanParticipationId);
                        }
                    },
                    this,
                    () => {
                        this.isLoading = false;
                    },
                    () => {
                        this.exitsInstructionGoBack(query.positionUsageId, query.sharePlanParticipationId);
                    }
                );
            },
            acceptInstruction() {
                this.beforeApiCall();
                post("/Exits/AcceptInstruction",
                    this.prepareModel(),
                    () => {
                        this.$router.push({ path: '/orders' });
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.getInstructionModel();
                    });
            },
            declineInstruction() {
                this.beforeApiCall();
                post("/Exits/RejectInstruction",
                    this.prepareModel(),
                    () => {
                        this.isDecline = true;
                        this.exitsInstructionGoBack(this.model.positionUsageId, this.model.sharePlanParticipationId);
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                     },
                    (err) => {
                        this.error = err.response.data;
                        this.getInstructionModel();
                    });
            },
            requoteInstruction() {
                this.processNewOrder(this.model);
            },
            newOrderQuantity(newQuantity) {
                let newModel = Object.assign({}, this.model);
                newModel.nrOrdered = newQuantity;
                this.processNewOrder(newModel);
            },
            processNewOrder(model) {
                this.beforeApiCall();
                post("/Exits/ReQuote",
                    model,
                    (response) => {
                        this.exitInstructionViewModel = response.data;
                        this.quoteExpires = false;

                        const returnPage = this.$route.query.returnPage;

                        this.$router.replace({
                            query: {
                                exitInstructionId: this.model.exitInstructionId,
                                sharePlanParticipationId: this.model.sharePlanParticipationId,
                                positionUsageId: this.model.positionUsageId,
                                exitScenarioId: this.model.exitScenarioId
                            }
                        });

                        if (returnPage) {
                            this.$router.replace({ query: { ...this.$route.query, returnPage: returnPage } });
                        }

                    }, this, () => {
                        this.isFormProcessing = false;
                    }, (err) => {
                        this.error = err.response.data;
                    });
            },
            beforeApiCall() {
                this.isFormProcessing = true;
                this.quoteExpires = true;
            },
            prepareModel() {
                return {
                    sharePlanParticipationId: this.model.sharePlanParticipationId,
                    positionUsageId: this.model.positionUsageId,
                    instructionId: this.model.exitInstructionId,
                    exitScenarioId: this.model.exitScenarioId,
                    acceptDisclaimers: this.model.disclaimers.filter(d => d.confirmed).map(d => ({ disclaimerId: d.disclaimerId }))
                }
            },
            updateQuoteExpires(quoteExpires) {
                this.quoteExpires = quoteExpires;
            },
            exitsInstructionGoBack(positionUsageId, sharePlanParticipationId) {
                switch (this.$route.query.returnPage) {
                    case "portfolio":
                        this.$router.push({ path: '/portfolio' });
                        break;
                    default:
                        this.$router.push({
                            path: "/exits",
                            query: {
                                positionUsageId: positionUsageId,
                                sharePlanParticipationId: sharePlanParticipationId
                            },
                        });
                }
            }
        }
    };
</script>
<template>
<div>
    <v-expansion-panels :class="['required-documents-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="required-documents-details-expansion-panel">
            <v-expansion-panel-header class="required-documents-details-expansion-panel__header">
                <h3 class="h4">{{ dictionary.mobileRowTitle }} {{ requiredDocument.name }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <RequiredDocumentsDetailsContent :dictionary="dictionary" :requiredDocument="requiredDocument" v-on="$listeners" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</div>
</template>

<script>

import RequiredDocumentsDetailsContent from "./required-documents-details-content.component.vue";

export default {
    components: {
        RequiredDocumentsDetailsContent
    },
    props: {
        requiredDocument: Object,
        dictionary: Object
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        },
    }
}
</script>
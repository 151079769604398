var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-details-content" },
    [
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.reference,
          value: _vm.payment.reference
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.instructionType,
          value: _vm.payment.type
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.processingDate,
          value: _vm._f("toDate")(
            _vm.replaceNullOrEmpty(_vm.payment.processingDateTime)
          )
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.description,
          value: _vm.replaceNullOrEmpty(_vm.payment.description)
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.confirmedAmount,
          value: _vm.replaceNullOrEmpty(_vm.payment.confirmedAmountFormatted)
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.unconfirmedAmount,
          value: _vm.replaceNullOrEmpty(_vm.payment.unconfirmedAmountFormatted)
        }
      }),
      _vm._v(" "),
      !_vm.toggles.hideOrderState
        ? _c("PaymentDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.state,
              value: _vm.payment.state
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.accountNumber,
          value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountNumber)
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.bankName,
          value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountBankName)
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.bankCountry,
          value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountBankCountryName)
        }
      }),
      _vm._v(" "),
      _c("PaymentDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.bic,
          value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountBIC)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
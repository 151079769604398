<template>
    <div class="mfa-pac" v-if="mfaData && mfaData.factor">
        <div class="mfa-pac-body">
            <p class="mb-2">
                <span class="rich-text-container" v-html="$sanitize(mainMessage)"></span>
                <a href="javascript:void(0)" :class="[{ 'isDisabled': disableResendButton }, 'link']" @click="resendCode()">{{ newPacLinkText }}</a>
            </p>
            <div class="form-group">
                <label class="form-input-label" for="pac-input">{{ dictionary.fieldPacLabel }}</label>
                <alert-component v-if="currentError" variant="error">{{ currentError }}</alert-component>
                <alert-component v-else-if="pacSendedOn" variant="success">{{ pacSendedOn | toDateTime }}</alert-component>
                <input type="text" name="pac-input" :disabled="disablePacForm" :value="value" class="form-control" @input="$emit('input', $event.target.value)"
                    @keyup.enter="submit()" />
            </div>
        </div>
        <MfaButtons :dictionary="dictionary" :disableSubmit="disablePacForm" @cancel="cancel()" @submit="submit()" />
    </div>
</template>

<script>
    import { post } from "api";
    import MfaButtons from "../mfa-buttons.component.vue";
    import { MfaMixin } from "../mixins/mfa.mixin.js"

    export default {
        components: {
            MfaButtons
        },
        props: {
            dictionary: Object,
            message: String,
            value: String,
            mfaData: Object,
        },
        mixins:[MfaMixin],
        watch: {
            mfaData(newValue, oldValue) {
                if (newValue) {
                    this.currentError = newValue.errorMessage;
                    this.initializeTimer(this.dictionary.pacLoginErrorServiceDisabled);
                    if (newValue.factor && newValue.factor.delayInSeconds) {
                        this.startDelayErrorMessage(newValue.factor.delayInSeconds)
                    }
                }
            }
        },
        computed: {
            pacSendedOn() {
                return this.$replaceStringWithPlaceholders(this.dictionary.textCodeSent,
                    {
                        SendDate: this.$options.filters.toDateTime(this.mfaData?.factor?.sendDate)
                    });
            },
            newPacLinkText() {
                return this.$replaceStringWithPlaceholders(this.dictionary.linkNewPacRequest,
                    {
                        RemainingResends: this.mfaData?.factor?.remainingResends
                    });
            },
            disableResendButton() {
                return ((this.mfaData && this.mfaData.factor) && !!(!this.mfaData.factor.remainingResends || (this.mfaData.factor.delayInSeconds && this.currentError)))
            },
            disablePacForm() {
                return (this.mfaData && this.mfaData.factor) && !!((!this.mfaData.factor.remainingAttempts && !this.mfaData.isSuccess) || (this.mfaData.factor.delayInSeconds && this.currentError))
            },
            mainMessage() {
                if(this.message && this.mfaData && this.mfaData.factor) {
                    return this.$replaceStringWithPlaceholders(
                        this.message,
                        {
                            Recipient: this.mfaData?.factor?.recipient,
                        }
                    )
                }

                return this.dictionary.alertMfaEnterPac;
            }
        },
        methods: {
            submit() {
                this.confirmCode();
            },
            cancel() {
                this.$emit("cancel");
            },
            confirmCode() {
                this.$emit("in-loading", true);
                post('/Mfa/Mfa',
                    {
                        identifier: this.mfaData.identifier,
                        factorType: this.mfaData.factor.factorType,
                        code: this.value,
                    },
                    (response) => {

                        if (response.data.isSuccess) {
                            this.$emit("confirm-code", {
                                identifier: this.mfaData.identifier,
                                code: this.value,
                                isSuccess: true
                            });
                            
                        }

                        this.$emit("update-mfa-data", response.data);

                        if (response.data.errorMessage) {
                            if (response.data.factor &&  response.data.factor.delayInSeconds) {
                                this.initializeTimer(this.dictionary.pacLoginErrorServiceDisabled);
                                this.startDelayErrorMessage(response.data.factor.delayInSeconds);
                                return;
                            }
                            this.currentError = response.data.errorMessage;
                            return;
                        }
                        this.$emit("mfa-success-login");
                    },
                    this,
                    () => {
                        this.$emit("in-loading", false);
                    },
                    (error) => {
                        this.currentError = error.response.data;
                    });
            },
            resendCode() {
                this.$emit("in-loading", true);
                post('/Mfa/Resend',
                    {
                        identifier: this.mfaData.identifier,
                        factorType: this.mfaData.factor.factorType
                    },
                    (response) => {

                        if (response.data.factor) {
                            this.$emit("update-mfa-data", response.data);
                        }

                        if (response.data.errorMessage) {
                            if (response.data.factor &&  response.data.factor.delayInSeconds) {
                                this.initializeTimer(this.dictionary.pacLoginErrorServiceDisabled);
                                this.startDelayErrorMessage(response.data.factor.delayInSeconds);
                                return;
                            }

                            this.currentError = response.data.errorMessage;
                            return;
                        }
                    },
                    this,
                    () => {
                        this.$emit("in-loading", false);
                    },
                    (error) => {
                        this.currentError = error.response.data;
                    });
            }
        }
    };
</script>
<template>
    <div class="beneficiary-details form-group">
        <p>
            {{ label }}
        </p>
        <p><a :href="$sanitize(urlAction)">{{ value }}</a></p>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        value: String,
        urlAction: String,
        isEdit: Boolean
    }
}
</script>
<template>
    <div class="company-component">
        <h2 class="company-title"> {{ accountDictionary.widgets.employment }}</h2>
        <EmploymentDetail
            :dictionary="accountDictionary.employment"
            :current-employment="currentEmployment">
        </EmploymentDetail>
        <h2 class="employment-history-title"> {{ accountDictionary.widgets.employmentHistory }}</h2>
        <div class="employment-history-subtitle-container d-flex">
            <p class="employment-history-subtitle d-flex"> {{ accountDictionary.widgets.employmentHistorySubtitle }}</p>
        </div>
        <EmploymentHistory
            :dictionary="accountDictionary.employment"
            :employment-history="employmentHistory">
        </EmploymentHistory>
    </div>
</template>

<script>

import EmploymentDetail from './employment-detail.component.vue';
import EmploymentHistory from './employment-history.component.vue';

export default {
    components: {
        EmploymentDetail,
        EmploymentHistory,
    },
    props: {
        accountDictionary: Object,
        employmentHistory: Array,
        currentEmployment: Object
    }
}
</script>

<template>
    <div v-if="dictionary" class="disclaimer-box">
        <div v-html="$sanitize(dictionary.labels.disclaimerText)" class="rich-text-container"></div>      
        <div class="button-section">
            <button @click="goBack" class="btn primaryButton" >{{dictionary.labels.btnGoBack}}</button>
        </div> 
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
        },
        methods: {
            goBack() { 
               this.$router.go(-1) 
            }
        },
        computed: {
            documentTitle() {
                if(this.dictionary){
                    return this.dictionary?.page?.title;
                }
            },
        },
        watch: {
            documentTitle: {
            immediate: true,
                handler(value) {
                    if (value) document.title =  value;
                },
            },
        }
    }
</script>
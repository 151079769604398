<template>
    <div id="container-confirm-subscription">
        <div class="confirm-subscription">

            <div class="confirm-subscription-plan">
                <span class="font-weight-bold">{{ participationSubscription.name}}</span>
            </div>

            <div v-for="offer in participationSubscription.chosenScenario.offers" :key="offer.participationSubscriptionOfferId" class="confirm-subscription-offer" :isMobile="isMobile">
                <SubscriptionDetailsRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(offer.underlyingUsageName)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="isSubscriptions" :label="dictionary.table.granted" :value="getGrantAmount(offer)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="!!offer.acceptedAmountFormatted" :label="dictionary.table.subscribed" :value="replaceNullOrEmpty(offer.acceptedAmountFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="!!offer.exercisePriceFormatted" :label="dictionary.table.exercisePrice" :value="replaceNullOrEmpty(offer.exercisePriceFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="!!offer.expectedValueFormatted" :label="dictionary.table.expectedValue" :value="replaceNullOrEmpty(offer.expectedValueFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="displayEmissionPrice(offer)" :label="dictionary.table.emissionPrice" :value="replaceNullOrEmpty(offer.emissionPriceFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="displayPurchasePrice(offer)" :label="dictionary.table.purchasePrice" :value="replaceNullOrEmpty(offer.purchasePriceFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="displayFeesPrice(offer)" :label="dictionary.table.feesPrice" :value="replaceNullOrEmpty(offer.feesPriceFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="displayTotalPrice(offer)" :label="dictionary.table.contributionAmount" :value="replaceNullOrEmpty(offer.totalPriceFormatted)" :isMobile="isMobile"/>
                
                <SubscriptionConfirmationTaxData v-if="isSubscriptions" :dictionary="dictionary" :offer="offer" :isMobile="isMobile"/>
            </div>
        </div>

    </div>
</template>

<script>

    import SubscriptionDetailsRow from "./subscription-details-row.component.vue";
    import SubscriptionConfirmationTaxData from "./subscription-confirmation-tax-data.component.vue";

    export default {
        components: {
            SubscriptionDetailsRow,
            SubscriptionConfirmationTaxData,
        },
        props: {
            dictionary: Object,
            participationSubscription: Object,
            isSubscriptions: Boolean,
        },
        data() {
            return {
                isMobile:false
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            displayTaxData(offer) {
                return !!offer.taxPayableAmountFormatted || !!offer.taxMonthlyDeductionAmountFormatted || !!offer.nrOfTaxDeductionPeriods
            },
            getGrantAmount(offer) {
                const minimumAndMaximumAmountDiffer = offer.MinimumAwardedAmount != offer.MaximumAwardedAmount;

                return minimumAndMaximumAmountDiffer ? `${offer.minimumAwardedAmountFormatted} - ${offer.maximumAwardedAmountFormatted}` : offer.maximumAwardedAmountFormatted;
            },
            displayEmissionPrice(offer) {
                return !this.isSubscriptions && !!offer.emissionPriceFormatted;
            },
            displayPurchasePrice(offer) {
                return !this.isSubscriptions && !!offer.purchasePriceFormatted;
            },
            displayFeesPrice(offer) {
                return !this.isSubscriptions && !!offer.feesPriceFormatted;
            },
            displayTotalPrice(offer) {
                return !this.isSubscriptions && !!offer.totalPriceFormatted;
            },
            handleResize() {
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            }
        }
    }
</script>
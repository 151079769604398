import DashboardContainer from "./dashboard-container.component.vue";

export const dashboardRoutes =
{
    path: '/',
    alias: '/dashboard',
    component: DashboardContainer,
    children: [
        {
            path: '/dashboard/*',
            redirect: '/dashboard'
        }
    ]
}

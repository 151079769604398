var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-row offerings" },
    [
      _c("div", { staticClass: "title-block" }, [
        _vm.pagesDictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.offerings.title))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dictionary,
              expression: "dictionary"
            }
          ]
        },
        [
          _c(
            "b-tabs",
            {
              ref: "tabs",
              staticClass: "tab-container tab-bar",
              attrs: { "content-class": "mt-0" }
            },
            [
              _vm.dictionary
                ? _c(
                    "portlet-tab",
                    {
                      attrs: {
                        tabTitle: _vm.dictionary.widget.tabOpen,
                        isActive: true,
                        tabId: "tabOpenOfferings"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "page-description rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.page.offeringsPageDescriptionOpen
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("OpenContributionTable", {
                        staticClass: "open-contribution-table",
                        attrs: {
                          dictionary: _vm.dictionary,
                          datatablesDictionary: _vm.dictionary.datatables
                        }
                      }),
                      _vm._v(" "),
                      _c("OpenTable", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          datatablesDictionary: _vm.dictionary.datatables,
                          toggles: _vm.toggles
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dictionary
                ? _c(
                    "portlet-tab",
                    {
                      attrs: {
                        tabTitle: _vm.dictionary.widget.tabHistory,
                        tabId: "tabHistoryOfferings"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "page-description rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.page
                                .offeringsPageDescriptionHistory
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("HistoricalTable", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          datatablesDictionary: _vm.dictionary.datatables,
                          toggles: _vm.toggles
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
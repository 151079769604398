<template>
    <div>
        <template v-if="errors && errors.length > 0">
            <form-error-alert-component :errors="errors"></form-error-alert-component>
        </template>
        <template v-if="hasHeaders">
            <div class="mb-2 rich-text-container" v-for="(header, index) in this.boundaries.headers" :key="index" v-html="$sanitize(header)"></div>
        </template>
        <timeline-container v-if="requiredInfo">
            <timeline-step :current-step="currentStep" :step="1">
                <RequiredInfoMifidPersonalInformation :current-step="currentStep"
                                                      :step="1"
                                                      :steps="steps"
                                                      :summaryType="summaryType"
                                                      :dictionary="dictionary"
                                                      :boundaries="boundaries"
                                                      :required-info-data="requiredInfo"
                                                      @next-step="nextStep"
                                                      @previous-step="previousStep"
                                                      @edit-step="editStep"
                                                      class="timeline-step" />
            </timeline-step>
            <timeline-step :current-step="currentStep" :step="2">
                <RequiredInfoMifidCitizenshipInformation :current-step="currentStep"
                                                         :step="2"
                                                         :steps="steps"
                                                         :summaryType="summaryType"
                                                         :dictionary="dictionary"
                                                         :boundaries="boundaries"
                                                         :countries="knownCountries"
                                                         :required-info-data="requiredInfo"
                                                         @next-step="nextStep"
                                                         @previous-step="previousStep"
                                                         @edit-step="editStep"
                                                         class="timeline-step" />
            </timeline-step>
            <timeline-step v-if="boundaries.canViewFiscalNumber" :current-step="currentStep" :step="3">
                <RequiredInfoMifidFiscalInformation :current-step="currentStep"
                                                    :step="3"
                                                    :steps="steps"
                                                    :summaryType="summaryType"
                                                    :dictionary="dictionary"
                                                    :boundaries="boundaries"
                                                    :required-info-data="requiredInfo"
                                                    @next-step="nextStep"
                                                    @previous-step="previousStep"
                                                    @edit-step="editStep"
                                                    class="timeline-step" />
            </timeline-step>
        </timeline-container>
        <RequiredInfoMifidButtons :dictionary="dictionary" :currentStep="currentStep" :steps="steps" :errors="errors"
          @skip="$emit('skip')" @submit="$emit('submit')" @edit="editSummary()" />
    </div>
</template>

<script>
    import { timelineStepsLogic } from "./mixins/required-info-timeline-steps-logic.mixin.js";
    import RequiredInfoMifidPersonalInformation from "./required-info-mifid-personal-information.component.vue";
    import RequiredInfoMifidCitizenshipInformation from "./required-info-mifid-citizenship-information.component.vue";
    import RequiredInfoMifidFiscalInformation from "./required-info-mifid-fiscal-information.component.vue";
    import FormErrorAlertComponent from "Shared/Components/Vue/Alert/form-error-alert.component.vue";
    import RequiredInfoMifidButtons from "./required-info-mifid-buttons.component.vue";

    export const createDropDownOption = (
        disabled,
        group,
        selected,
        text,
        value
    ) => ({
        disabled: disabled,
        group: group,
        selected: selected,
        text: text,
        value: value
    });

    export default {
        mixins: [timelineStepsLogic],
        components: {
            RequiredInfoMifidPersonalInformation,
            RequiredInfoMifidCitizenshipInformation,
            RequiredInfoMifidFiscalInformation,
            FormErrorAlertComponent,
            RequiredInfoMifidButtons
        },
        props: {
            dictionary: Object,
            boundaries: Object,
            countries: Array,
            requiredInfo: Object,
            errors: Array
        },
        created() {
            this.summaryType = 'afterLastStep';
        },
        computed: {
            steps() {
                return this.boundaries.canViewFiscalNumber ? 3 : 2;
            },
            knownCountries() {
                return this.countries;
            },
            hasHeaders() {
                return !!this.boundaries && !!this.boundaries.headers;
            }
        },
        methods: {
            submitStep(payLoad) {
                this.requiredInfo = payLoad.data;
                if (payLoad.finished === true) {
                    
                    this.$emit("submit");
                }
            },
            editStep(payLoad) {
                this.currentStep = payLoad.step;
            },
            editSummary() {
                this.currentStep = this.currentStep - 1;
            }
        }
    };
</script>
<template>
  <required-info-step
    v-if="requiredInfoModel"
    id="tax-residency-container"
    :currentStep="currentStep"
    :step="step"
    :heading="dictionary.labels.step3"
    :dictionary="dictionary"
    @edit-step="(val) => $emit('edit-step', val)"
  >
    <template v-slot:current>
      <div class="row" v-if="hasEmptyTins">
        <div class="col-12">
          <alert-component variant="warning" iconClass="fa-info-circle">{{dictionary.validation.missingTIN}}</alert-component>
        </div>
      </div>
      <div class="row declaration-text-row mb-2">
        <div class="col-12 rich-text-container" v-html="$sanitize(dictionary.labels.declarationText)">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <alert-component variant="error" v-if="declarationError">{{ declarationError.message }}</alert-component>
          <div class="form-group">
            <div class="input-group mb-2">
              <label class="mb-0 form-check-inline form-input-label">
                <input v-model="requiredInfoData.declarationAccepted" class="form-check-input" type="checkbox" />
                <span class="pl-2">{{ dictionary.labels.declarationCheckbox }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-flex justify-content-md-between button-container">
        <div>
          <button
            class="btn secondaryButton"
            type="button"
            @click="previousStep()"
            :aria-describedby="previousStepAccessibilityLabel(step)">
              {{ dictionary.buttons.previous }}
          </button>
          <button
            class="btn primaryButton"
            type="button"
            @click="nextStep()"
            :aria-describedby="nextStepAccessibilityLabel(step, steps)">
              {{ dictionary.buttons.submit }}
          </button>
        </div>
      </div>
    </template>
  </required-info-step>
</template>

<script>
import AlertComponent from "Shared/Components/Vue/Alert/alert.component.vue";
import RequiredInfoStep from "./required-info-step.component.vue";
import { declarationModelValidations } from "./mixins/required-info-form-validation.mixin";
import { createDropDownOption } from './required-info-crs-timeline.component.vue';
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  mixins: [declarationModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  components: {
    RequiredInfoStep,
    AlertComponent
  },
  props: {
    dictionary: Object,
    currentStep: Number,
    step: Number,
    requiredInfoData: Object,
    countries: Array,
  },
  computed: {
    requiredInfoModel() {
      return this.requiredInfoData;
    },
    listCountries() {
      return this.countries.map((c) =>
        createDropDownOption(false, false, false, c.name, c.id)
      );
    },
    declarationError(){
      return this.declarationValidities.find(x=>x.error && !x.type)
    },
    declarationValidities() {
      return [
          {
            field: this.$v.requiredInfoModel.declarationAccepted,
            error: this.$v.requiredInfoModel.declarationAccepted.$error,
            type: this.$v.requiredInfoModel.declarationAccepted.isTrue,
            message: this.dictionary.validation.noDeclaration,
          },
        ]
    },
    hasEmptyTins(){
      let fiscalNationalities = [ ...this.requiredInfoModel.fiscalNationalities ];

      if(this.requiredInfoModel.isUSCitizen === true){
        fiscalNationalities.push({ ...this.requiredInfoModel.usCitizenNationality });
      }

      return fiscalNationalities.some(x=> !x.fiscalNumber || x.fiscalNumber.length === 0);
    },
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.focusOnFirstInvalidInput();
      }
      this.$emit("next-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    },
    previousStep() {
      this.$emit("previous-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    }
  }
};
</script>
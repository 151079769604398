<template>
    <div class="login-form">
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <loading-spinner :active="isSubmitting"></loading-spinner>
        <form class="mb-lg-3" id="loginForm" @submit.prevent="login">
            <fieldset>
                <legend class="sr-only">Login Form</legend>
                    <input-element type="text" :label="dictionary.page.userName" :is-mandatory="true" v-model="model.userName" name="userName" :validationsList="userNameValidations">
                        <template slot="link"> 
                            <router-link to="/login/forgottenUsername" class="float-right"><span class="underline">{{dictionary.page.forgotUserName}}</span></router-link>
                        </template>
                    </input-element>                

                    <input-element type="password" :label="dictionary.page.password" :is-mandatory="true" v-model="model.password" name="password" :validationsList="passwordValidations" :togglePassword="true">
                        <template slot="link">
                            <router-link to="/login/forgottenPassword" class="float-right"><span class="underline">{{dictionary.page.forgotPassword}}</span></router-link>
                        </template>
                    </input-element>
                    <p>* {{dictionary.page.loginLabelMandatory}}</p>
                    <LoginGoogleRecaptcha v-if="toggles.loginUseCaptcha" ></LoginGoogleRecaptcha>

                    <button class="btn btn-block mt-3 primaryButton login-button" type="submit" @submit.prevent="login" >
                        {{dictionary.page.btnLogin}}
                    </button>

                    <div v-if="isMobile" class="d-flex justify-content-around">
                        <div class="help-link-login">
                            <HeaderLoginHelp :label="dictionary.page.loginHelp"></HeaderLoginHelp>
                        </div>
                    </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
    import { post } from "api";
    import { required } from "vuelidate/lib/validators";
    import LoginGoogleRecaptcha from "./login-google-recaptcha.component.vue";
    import HeaderLoginHelp from "Shared/Layout/Header/Vue/header-login-help.component.vue";
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            LoginGoogleRecaptcha,
            HeaderLoginHelp
        },
        mixins:[ScreenSizeMixin, accessibility],
        props: {
            dictionary: Object,
            toggles: Object
        },
        data() {
            return {
                model: {
                    userName: "",
                    password: "",
                    returnUrl: this.$route.query.ReturnUrl,
                    recaptchaResponse:''
                },
                isSubmitting: false,
                error: null,
                isLoading: true
            };
        },
        validations() {
            return {
                model: {
                    userName: { 
                        required
                    },
                    password: { 
                        required 
                    }                    
                }
            }
        },
        methods:{
            login(){
                this.error = "";
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    return this.focusOnFirstInvalidInput();
                }

                this.isSubmitting = true;
                EventBus.$emit('sr-loading-state', this.isSubmitting);
                if (this.toggles.loginUseCaptcha) 
                    this.model.recaptchaResponse = grecaptcha.getResponse();

                post(
                    '/login',
                    this.model,
                    (res) => {
                        this.$emit("login-success", res.data);
                    },
                    this,
                    () => {},
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    }
                );
            }
        },
        computed: {
            userNameValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.userName.$error,
                        type: this.$v.model.userName.required,
                        message: this.dictionary.errors.loginErrorUserNameRequired
                    }
                ];
            },
            passwordValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.password.$error,
                        type: this.$v.model.password.required,
                        message: this.dictionary.errors.loginErrorPasswordRequired
                    }
                ];
            }
        }
    };
</script>
<template>
  <tr class="hideInMobile">
      <td class="font-weight-bold portfolio-plan-security-totals-row">{{ dictionary.portfolioLabelRowTotals }}</td>
      <td v-if="showPlanActionButtons" class="all position-column portfolio-plan-security-totals-row">&nbsp;</td>                  
      <td v-if="showVestingDateColumnForControlTags" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showLastDayExercisePeriodColumnForControlTags" class="portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="portfolioPlan.hasAnyVestings && showVestingsColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showBalanceColumn" class="text-right portfolio-plan-security-totals-row">{{ security.balanceFormatted }} </td>
      <td v-if="showAvailableColumn" class="text-right portfolio-plan-security-totals-row">{{ security.availableFormatted }} </td>
      <td v-if="showUnvestedColumn" class="text-right portfolio-plan-security-totals-row">{{ security.unvestedFormatted }} </td>
      <td v-if="showLastPriceColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showDerivativeSecurityPriceColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showReferencePriceColumn && hasReferencePrice" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showExercisePriceColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showUnvestedValueColumn" class="text-right portfolio-plan-security-totals-row">{{ security.unvestedFormatted }} </td>
      <td v-if="showVestingDateColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td v-if="showLastDayExercisePeriodColumn" class="text-right portfolio-plan-security-totals-row">&nbsp;</td>
      <td class="text-right portfolio-plan-security-totals-row">{{ securityTotalValue(security) }} </td>
      <td v-if="showExpectedValueColumn" class="text-right portfolio-plan-security-totals-row" >{{ security.valueFormatted }} </td>
    </tr>    
</template>

<script>
import { controlTagsMethods } from "./mixins/portfolio-plan-control-tags.mixin";
import { portfolioPlanMethods } from "./mixins/portfolio-plan.mixin";

export default {
  mixins: [controlTagsMethods, portfolioPlanMethods],
  props: {
    isMultiPositionExit: Boolean,
    dictionary: Object,
    toggles: Object,
    security: Object,
    controlTags: String,
    portfolioPlan:Object
  },
  methods: {
    securityTotalValue(security) {
      return this.replaceNullOrEmpty(!this.toggles.showCurrencyConversionDropdown ? security.valueFormatted : security.localCurrencyValueFormatted, '-')
    }
  }
};
</script>
<template>
    <div>
        <div>
            <disclaimers :disclaimers="buyInstructionModel.disclaimers" />
        </div>
        <BuyConfirmationButtonsComponent :buyInstructionModel="buyInstructionModel" v-on="$listeners" />
    </div>
</template>

<script>
    import BuyConfirmationButtonsComponent from "./buy-confirmation-buttons.component.vue";

    export default {
        components: {
            BuyConfirmationButtonsComponent
        },
        props: {
            buyInstructionModel: Object
        }
    };
</script>
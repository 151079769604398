<template>
  <div>
    <template v-if="isMobile">
        <v-expansion-panels accordion class="portfolio-expansion-panels">
          <v-expansion-panel v-if="!toggles.hideTotalValueBlock || !toggles.hideBalanceBlock || !toggles.hideCashAvailableBlock || !toggles.hideTotalExpectedValueBlock">
            <v-expansion-panel-header>
            <div @click.prevent.stop>
              <PortfolioSummaryMobileBlock v-if="headerColumn == 'totalValue'" :portfolio-summary="portfolioSummary.totalValue" />
              <PortfolioSummaryMobileBlock v-if="headerColumn == 'cashBalance'" :portfolio-summary="portfolioSummary.cashBalance"/>
              <PortfolioSummaryMobileBlock v-if="headerColumn == 'cashAvailable'" :portfolio-summary="portfolioSummary.cashAvailable"/>
              <PortfolioSummaryMobileBlock v-if="headerColumn == 'totalExpectedValue'"  :portfolio-summary="portfolioSummary.totalExpectedValue"/>
            </div>            
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                  <PortfolioSummaryMobileBlock v-if="!toggles.hideBalanceBlock && portfolioSummary.cashBalance && headerColumn != 'cashBalance'" :portfolio-summary="portfolioSummary.cashBalance"/>
                  <PortfolioSummaryMobileBlock v-if="!toggles.hideCashAvailableBlock && portfolioSummary.cashAvailable && headerColumn != 'cashAvailable'" :portfolio-summary="portfolioSummary.cashAvailable"/>
                  <PortfolioSummaryMobileBlock v-if="!toggles.hideTotalExpectedValueBlock && portfolioSummary.totalExpectedValue && headerColumn != 'totalExpectedValue'" :portfolio-summary="portfolioSummary.totalExpectedValue"/>
              </div>              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </template>
    <template v-else>
      <PortfolioSummaryBlock v-if="!toggles.hideTotalValueBlock && portfolioSummary.totalValue" :portfolio-summary="portfolioSummary.totalValue" />
      <PortfolioSummaryBlock v-if="!toggles.hideBalanceBlock && portfolioSummary.cashBalance" :portfolio-summary="portfolioSummary.cashBalance"/>
      <PortfolioSummaryBlock v-if="!toggles.hideCashAvailableBlock && portfolioSummary.cashAvailable" :portfolio-summary="portfolioSummary.cashAvailable"/>
      <PortfolioSummaryBlock v-if="!toggles.hideTotalExpectedValueBlock && portfolioSummary.totalExpectedValue" :portfolio-summary="portfolioSummary.totalExpectedValue"/>    
    </template>
  </div>
</template>

<script>
import PortfolioSummaryBlock from "./portfolio-summary-block.component.vue";
import PortfolioSummaryMobileBlock from "./portfolio-summary-mobile-block.component.vue";

export default {
  components: {
    PortfolioSummaryBlock,
    PortfolioSummaryMobileBlock
  },
  data(){
    return { 
      isMobile : false 
    }
  },
  props: {
    toggles: Object,
    portfolioSummary: Object
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
       window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
        this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
    }
  }, 
  computed: {
    headerColumn() {
        if(!this.toggles.hideTotalValueBlock && this.portfolioSummary.totalValue) {
            return 'totalValue'
        }      
        else if(!this.toggles.hideBalanceBlock && this.portfolioSummary.cashBalance) {
            return 'cashBalance'
        }
        else if(!this.toggles.hideCashAvailableBlock && this.portfolioSummary.cashAvailable) {
            return 'cashAvailable'
        }
        else if(!this.toggles.hideTotalExpectedValueBlock && this.portfolioSummary.totalExpectedValue) {
            return 'totalExpectedValue'
        } 
    }
  }
};
</script>
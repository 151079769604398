var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", { staticClass: "evaluation-container" }, [
            _vm.questionnairesDictionary &&
            _vm.financialDictionary &&
            !_vm.beneficiaryToggles.hideQuestionnaireWidget
              ? _c(
                  "div",
                  { staticClass: "evaluation-body" },
                  [
                    _c("h2", [
                      _vm._v(
                        _vm._s(_vm.financialDictionary.widget.questionnaires)
                      )
                    ]),
                    _vm._v(" "),
                    _vm.questionnairesDictionary && _vm.datatablesDictionary
                      ? _c("Questionnaires", {
                          attrs: {
                            dictionary: _vm.questionnairesDictionary,
                            "datatables-dictionary": _vm.datatablesDictionary,
                            toggles: _vm.beneficiaryToggles
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.requiredDocumentsDictionary &&
            _vm.financialDictionary &&
            !_vm.beneficiaryToggles.hideRequiredDocumentsWidget
              ? _c(
                  "div",
                  { staticClass: "evaluation-body" },
                  [
                    _c("h2", [
                      _vm._v(
                        _vm._s(_vm.financialDictionary.widget.requiredDocuments)
                      )
                    ]),
                    _vm._v(" "),
                    _c("RequiredDocuments", {
                      attrs: {
                        dictionary: _vm.requiredDocumentsDictionary,
                        "datatables-dictionary": _vm.datatablesDictionary
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.accountDictionary && !_vm.beneficiaryToggles.hideRelationships
              ? _c(
                  "div",
                  [
                    _c("BeneficiaryRelationships", {
                      attrs: {
                        dictionary: _vm.accountDictionary,
                        toggles: _vm.beneficiaryToggles,
                        "datatables-dictionary": _vm.datatablesDictionary
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <loading-spinner v-if="isLoading" :active="isLoading"></loading-spinner>
    <LoginTemplate v-else>
        <template slot="title">
            <div>
                <h1 class="sr-only welcome-text">{{header}}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{header}}</p>
                <hr>
            </div>
        </template>
        <template slot="body">
            <LoginForm v-if="!mfaRequired" :dictionary="dictionary" :toggles="toggles" @login-success="onLoginSuccess"/>
            <LoginMfa v-else :dictionary="dictionary" :isVisible="mfaRequired" @mfa-success="redirect" @in-loading="inLoading" />
        </template>
    </LoginTemplate>
</template>

<script>
    import { get, getAll } from "api";
    import LoginForm from "./login-form.component.vue";
    import LoginMfa from "./login-mfa.component.vue";
    import LoginTemplate from "./login-template.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            LoginMfa,
            LoginForm,
            LoginTemplate
        },
        data() {
            return {
                dictionary: null,
                toggles: null,
                isLoading: true,
                mfaRequired: false,
                loginResult: null
            };
        },
        beforeCreate() {
            getAll([
                "/login/Dictionary",
                '/login/Toggles'
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.isLoading = false;
            }, this);
        },
        mounted() {
            EventBus.$on("event-language-changed", () => {
                this.loadDictionaries();
            });
        },
        computed: {
            header() {
                return !this.mfaRequired ? this.dictionary.page.welcomeText : this.dictionary.pacPage.welcomeTextHeader;
            }
        },
        methods: {
            loadDictionaries() {
                this.isLoading = true;

                get("/login/Dictionary",
                    (response) => {
                        this.dictionary = response.data;
                        this.isLoading = false;
                    }, this);
            },
            onLoginSuccess(loginResult) {
                this.loginResult = loginResult;
                if (loginResult.mfaRequired) {
                    this.mfaRequired = loginResult.mfaRequired;
                } else {
                    this.redirect();
                }
            },
            redirect() {
                let path = '/dashboard';

                if(this.loginResult.returnUrl) {
                    path = this.loginResult.returnUrl
                }

                EventBus.$emit('event-reload-layout', path);
            },
            inLoading(isLoading) {
                this.isLoading = isLoading;
            }
        }
    }

</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relationship-details-content" },
    [
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationshipType,
          value: _vm.relationship.relationshipType
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationIdentifier,
          value: _vm.relationship.relatedBeneficiaryIdentifier
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationBirthDate,
          value: _vm.relationship.relatedBeneficiaryBirtDate
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationName,
          value: _vm.relationship.relatedBeneficiaryName
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationBeginDate,
          value: _vm.relationship.beginDate
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationEndDate,
          value: _vm.relationship.endDate
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.relationRemarks,
          value: _vm.relationship.remarks
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="vesting-modification-content vesting-expanded-content">
    <div class="row">
        <div class="col-md-6">
            <VestingDetailsContentRow :label="dictionary.modifications.date" :value="details.effectiveDate" />
            <VestingDetailsContentRow :label="dictionary.modifications.type" :value="details.vestingType" />
            <VestingDetailsContentRow :label="dictionary.modifications.amount" :value="details.baseAmount" />
        </div>
        <div class="col-md-6">
            <VestingDetailsContentRow :label="dictionary.modifications.percentage" :value="`${details.modificationPercentage}%`" />
            <VestingDetailsContentRow :label="dictionary.modifications.modification" :value="details.modificationAmount" />
            <VestingDetailsContentRow :label="dictionary.modifications.total" :value="details.total" />
        </div>
    </div>
</div>
</template>

<script>
import VestingDetailsContentRow from './vesting-details-content-row.component.vue';

export default {
    components: {
        VestingDetailsContentRow
    },
    props: {
        details: Object,
        dictionary: Object
    }
}
</script>
<template>
    <div>
        <div v-if="dictionary">
            <div class="title-block">
                <h1>{{ dictionary.page.titleConfirmSubscribe }}</h1>
            </div>
            <div v-if="confimationModel.participationSubscription" class="portlets-wrapper">
                <div class="portlet-container">
                    <div class="row m-0">
                        <portlet-layout column-class="col-lg-6 col-xl-6" :title="confimationModel.participationSubscription.name" >
                            <template slot="body">
                                <alert-component v-if="error" variant="error">{{ error }}</alert-component>
                                <loading-spinner :active="isSubmitting"></loading-spinner>
                                <SubscriptionConfirmationDetails :dictionary="dictionary" :participationSubscription="confimationModel.participationSubscription" :isSubscriptions="isSubscriptions"/>
                                <SubscriptionConfirmationButtons class="mt-3" v-if="!confimationModel.disclaimers.length" :dictionary="dictionary" :disclaimers="confimationModel.disclaimers" :presentationMode="confirmationData.participationSubscription.presentationMode" @accept="accept()" @decline="decline()" />
                            </template>
                        </portlet-layout>
                        <portlet-layout v-if="confimationModel.disclaimers.length" column-class="col-lg-6 col-xl-6" :title="dictionary.widget.titleDisclaimers" >
                            <template slot="body">
                                <loading-spinner :active="isSubmitting"></loading-spinner>
                                <SubscriptionConfirmationDisclaimers :dictionary="dictionary" :confimationModel="confimationModel" @accept="accept()" @decline="decline()" />
                            </template>
                        </portlet-layout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { post } from "api";
    import SubscriptionConfirmationDetails from "./subscription-confirmation-details.component.vue";
    import SubscriptionConfirmationDisclaimers from "./subscription-confirmation-disclaimers.component.vue";
    import SubscriptionConfirmationButtons from "./subscription-confirmation-buttons.component.vue";

    export default {
        components: {
            SubscriptionConfirmationDetails,
            SubscriptionConfirmationDisclaimers,
            SubscriptionConfirmationButtons
        },
        props: {
            dictionary: Object,
            confirmationData: Object,
            isSubscriptions: Boolean,
            isFormStepSkipped: Boolean
        },
        data() {
            return {
                confimationModel: null,
                error: null,
                isSubmitting: null
            };
        },
        beforeRouteEnter(to, from, next) {
            const previousPathName = from.path ?? "";
            if (!previousPathName.includes("subscribe")) {
                location.replace(`/`);
            } else {
                next();
            }
        },
        created() {
            if (!this.confirmationData) {
                this.$router.push({ path: "/" + this.actionTarget });
            } else {
                this.confimationModel = Object.assign({}, this.confirmationData);
            }
        },
        computed: {
            actionTarget() {
                return this.isSubscriptions ? "subscriptions" : "offerings";
            }
        },
        methods: {
            accept() {

                this.isSubmitting = true;
                this.error = null;

                var confirmSubscribeModel = {
                    participationSubscriptionScenarioId: this.confimationModel.participationSubscription.participationSubscriptionScenarioId,
                    participationSubscriptionOffers: this.confimationModel.participationSubscription.chosenScenario.offers,
                    acceptDisclaimers: this.confimationModel.disclaimers.filter(d => d.confirmed).map(d => ({ disclaimerId: d.disclaimerId }))
                }

                post('/Subscriptions/ConfirmSubscribe',
                    confirmSubscribeModel,
                    (response) => {

                        this.$router.push({
                            path: this.actionTarget + "-confirmation-success",
                            params: {
                                participationSubscription: response.data
                            }
                        });

                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    });

            },
            decline() {
                if (this.isFormStepSkipped) {
                    this.$router.push({
                        path: "/" + this.actionTarget
                    });
                } else {
                    this.$router.push({
                        path: "/" + this.actionTarget + "/subscribe",
                        query: {
                            participationSubscriptionId: this.confimationModel.participationSubscription.participationSubscriptionId
                        }
                    });
                }
            }
        }
    };
</script>
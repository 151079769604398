var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "accessibilityMainFocus",
      staticClass: "questionnaire-card-container"
    },
    [
      _c("div", {
        staticClass: "questionnaire-description-block rich-text-container",
        domProps: {
          innerHTML: _vm._s(_vm.$sanitize(_vm.dictionary.page.description))
        }
      }),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentQuestionnaire
        ? _c("div", { staticClass: "questionnaire-card-header" }, [
            _c("div", [
              _c("h3", [
                _vm._v(_vm._s(_vm.currentQuestionnaire.questionnaireName))
              ]),
              _vm._v(" "),
              _vm.currentQuestionnaire.headingText
                ? _c("p", {
                    staticClass: "rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(_vm.currentQuestionnaire.headingText)
                      )
                    }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "questionnaire-card-body" },
        [
          _vm.currentQuestionnaire.singleQuestionMode
            ? [
                _c("QuestionSingle", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    v: _vm.$v,
                    "current-questionnaire": _vm.currentQuestionnaire,
                    "question-index": _vm.questionIndex,
                    "show-correct-answer": _vm.showCorrectAnswer
                  },
                  on: { "change-answer": _vm.handleChangeAnswer }
                })
              ]
            : [
                _c("QuestionMultiple", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    v: _vm.$v,
                    "current-questionnaire": _vm.currentQuestionnaire,
                    "show-correct-answer": _vm.showCorrectAnswer
                  },
                  on: { "change-answer": _vm.handleChangeAnswer }
                })
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.currentQuestionnaire
        ? _c(
            "div",
            {
              class: [
                "questionnaire-card-footer d-md-flex",
                _vm.currentQuestionnaire.singleQuestionMode
                  ? "justify-content-md-between"
                  : "justify-content-md-end"
              ]
            },
            [
              _vm.currentQuestionnaire.singleQuestionMode
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn secondaryButton",
                        attrs: { disabled: !_vm.hasPrevQuestion },
                        on: { click: _vm.prevQuestion }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.dictionary.labels.btnPrevious) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn primaryButton",
                        attrs: { disabled: !_vm.hasNextQuestion },
                        on: { click: _vm.nextQuestion }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.dictionary.labels.btnNext) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn primaryButton",
                    attrs: { disabled: !_vm.canSubmitQuestionnaire },
                    on: { click: _vm.handleSubmitQuestionnaire }
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.dictionary.labels.btnSubmit) +
                        "\n          "
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "formCheckContainer mb-3" }, [
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideRadioOrCheckBox,
            expression: "!hideRadioOrCheckBox"
          }
        ],
        staticClass: "form-check-input",
        attrs: {
          type: _vm.inputType,
          name: "Answer_Mode_Value_IsSelected",
          id: "Answer_Mode_Value_" + _vm.itemIndex,
          disabled: !_vm.choiceInstructionModel.canInstruct
        },
        domProps: {
          checked: _vm.scenario.isSelected,
          value: _vm.scenario.isSelected
        },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-input-label",
          attrs: { for: "Answer_Mode_Value_" + _vm.itemIndex }
        },
        [_vm._v(_vm._s(_vm.scenario.title))]
      )
    ]),
    _vm._v(" "),
    !_vm.hideInput
      ? _c(
          "div",
          [
            _c(
              "input-element",
              {
                attrs: {
                  type: "text",
                  value: _vm.$setDecimalSeparatorBySettings(_vm.scenario.value),
                  disabled: _vm.isInputDisabled,
                  validationsList: _vm.valueValidations,
                  name: "Scenarios_" + _vm.itemIndex + "_Value",
                  label: _vm.scenario.text
                },
                model: {
                  value: _vm.scenario.value,
                  callback: function($$v) {
                    _vm.$set(_vm.scenario, "value", $$v)
                  },
                  expression: "scenario.value"
                }
              },
              [
                _c("template", { slot: "group" }, [
                  _vm.hasPercentage
                    ? _c(
                        "div",
                        {
                          staticClass: "input-group-prepend",
                          attrs: { "aria-hidden": "true" }
                        },
                        [
                          _c("span", { staticClass: "input-group-text" }, [
                            _vm._v("%")
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.isVisible
    ? _c(
        "div",
        { staticClass: "login-language" },
        [
          _c("custom-dropdown", {
            attrs: {
              "id-name": "LocaleId",
              items: _vm.items,
              "initial-item": _vm.currentLanguage,
              "show-short-name": true,
              highlight: true,
              eventName: "event-select-change-language",
              "aria-label": "choose a language"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const controlTagsMethods = {
    methods: {
        hideIfControlTagsContains(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTagArray.filter(tag => controlTags.includes(tag)).length;
            }
            return false;
        },
        showIfControlTagsContains(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTagArray.filter(tag => controlTags.includes(tag)).length;
            }
            return false;
        },
        hideIfControlTagsContainsOnlyOneOf(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTagArray.filter(tag => controlTags.includes(tag)).length == 1;
            }
            return false;
        },
        showIfControlTagsContainsOnlyOneOf(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTagArray.filter(tag => controlTags.includes(tag)).length == 1;
            }
            return false;
        },
        hideIfControlTagsContainsAll(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTagArray.filter(tag => controlTags.includes(tag)).length == controlTagArray.length;
            }
            return false;
        },
        showIfControlTagsContainsOnlyFirst(controlTags, ...controlTagArray) {
            if (controlTags && controlTagArray) {
                return controlTags.includes(controlTagArray[0])
                    && !controlTagArray.slice(1).filter(tag => controlTags.includes(tag)).length
            }
            return false;
        }
    },
    computed: {
        showPlanActionButtons() {
            return !this.isMultiPositionExit
                && !this.hideIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showVestingDateColumnForControlTags() {
            return this.showIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
                && !this.hideIfControlTagsContainsAll(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showLastDayExercisePeriodColumnForControlTags() {
            return this.showIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_OPTION)
                && !this.hideIfControlTagsContainsAll(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showVestingsColumn() {
            return !this.toggles.hideVestingsColumn || this.showIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showBalanceColumn() {
            return !this.toggles.hideBalanceColumn
        },
        showAvailableColumn() {
            return !this.toggles.hideAvailableColumn || this.showIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showUnvestedColumn() {
            return this.toggles.showUnvestedColumn
                && !this.hideIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showLastPriceColumn() {
            return !this.toggles.hideLastPriceColumn
                && !this.hideIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showReferencePriceColumn() {
            return (this.toggles.showReferencePriceColumn && 
                (
                    (!this.hideIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_OPTION)  
                    || this.showIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD))
                    && !this.hideIfControlTagsContainsAll(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
                ))
        },
        showExercisePriceColumn() {
            return this.showIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
                && this.showIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_OPTION)
        },
        showVestingDateColumn() {
            return this.toggles.showVestingDateColumn && !this.hideIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showLastDayExercisePeriodColumn() {
            return this.toggles.showLastDayExercisePeriodColumn && !this.hideIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showUnvestedValueColumn() {
            return this.showIfControlTagsContains(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showExpectedValueColumn() {
            return !this.toggles.hideExpectedValueColumn || this.showIfControlTagsContainsOnlyOneOf(this.controlTags, this.$PlanControlTags.STOCK_AWARD, this.$PlanControlTags.STOCK_OPTION)
        },
        showDerivativeSecurityPriceColumn() {
            return this.toggles.showDerivativeSecurityPriceColumn
        },
    }
}
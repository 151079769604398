var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "transaction-header" }, [
    !_vm.isMobile
      ? _c("div", [
          _c(
            "span",
            {
              staticClass: "transaction-header-overview font-weight-bold mr-8"
            },
            [
              _vm._v(
                _vm._s(_vm.dictionary.page.transactionsSubTitlePositionOverview)
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.dictionary.labels.transactionsLabelOpeningBalance)
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "font-weight-bold ml-2" }, [
            _vm._v(
              _vm._s(
                _vm.replaceNullOrEmpty(_vm.transaction.startPositionFormatted)
              )
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-8 mr-8" }, [_vm._v("|")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.dictionary.labels.transactionsLabelClosingBalance)
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "font-weight-bold ml-2" }, [
            _vm._v(
              _vm._s(
                _vm.replaceNullOrEmpty(_vm.transaction.endPositionFormatted)
              )
            )
          ])
        ])
      : _c("div", [
          _c(
            "span",
            { staticClass: "transaction-header-overview font-weight-bold" },
            [
              _vm._v(
                _vm._s(_vm.dictionary.page.transactionsSubTitlePositionOverview)
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.dictionary.labels.transactionsLabelOpeningBalance)
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.replaceNullOrEmpty(
                      _vm.transaction.startPositionFormatted
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.dictionary.labels.transactionsLabelClosingBalance)
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.replaceNullOrEmpty(_vm.transaction.endPositionFormatted)
                  )
                )
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
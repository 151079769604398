var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("required-info-step", {
    attrs: {
      id: "citizenship-info-container",
      currentStep: _vm.currentStep,
      step: _vm.step,
      steps: _vm.steps,
      summaryType: _vm.summaryType,
      heading: _vm.dictionary.labels.step2,
      description: _vm.dictionary.labels.step2Description,
      dictionary: _vm.dictionary
    },
    on: {
      "edit-step": function(val) {
        return _vm.$emit("edit-step", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "previous",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("RequiredInfoDataLabelRow", {
                  staticClass: "col-md-6",
                  attrs: {
                    value: _vm.getCountryName(
                      _vm.requiredInfoModel.legalDocumentCountryId
                    ),
                    label: _vm.dictionary.labels.legalDocumentCountry,
                    isMandatory: true
                  }
                }),
                _vm._v(" "),
                _c("RequiredInfoDataLabelRow", {
                  staticClass: "col-md-6",
                  attrs: {
                    value: _vm.getDocumentTypeName(
                      _vm.requiredInfoModel.legalDocumentType.value
                    ),
                    label: _vm.dictionary.labels.legalDocumentType,
                    isMandatory: true
                  }
                }),
                _vm._v(" "),
                _vm.boundaries.canViewLegalDocumentIssueDate
                  ? _c("RequiredInfoDataLabelRow", {
                      staticClass: "col-md-6",
                      attrs: {
                        value: _vm._f("toDate")(
                          _vm.requiredInfoModel.legalDocumentIssueDate
                        ),
                        label: _vm.dictionary.labels.issueDate,
                        isMandatory: true
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.boundaries.canViewLegalDocumentExpiryDate
                  ? _c("RequiredInfoDataLabelRow", {
                      staticClass: "col-md-6",
                      attrs: {
                        value: _vm._f("toDate")(
                          _vm.requiredInfoModel.legalDocumentExpiryDate
                        ),
                        label: _vm.dictionary.labels.expiryDate,
                        isMandatory: true
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("RequiredInfoDataLabelRow", {
                  staticClass: "col-md-6",
                  attrs: {
                    value: _vm.requiredInfoModel.legalDocumentNumber,
                    label: _vm.dictionary.labels.legalDocumentNumber,
                    isMandatory: true
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "current",
        fn: function() {
          return [
            _c("div", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("select-element", {
                      attrs: {
                        label: _vm.dictionary.labels.legalDocumentCountry,
                        validationsList:
                          _vm.citizenshipValidations.legalDocumentCountryId,
                        options: _vm.listCountries,
                        name: "legalDocumentCountryId",
                        readonly: !_vm.boundaries.mayEditLegalDocumentCountryId,
                        "is-mandatory": true
                      },
                      model: {
                        value: _vm.requiredInfoModel.legalDocumentCountryId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.requiredInfoModel,
                            "legalDocumentCountryId",
                            $$v
                          )
                        },
                        expression: "requiredInfoModel.legalDocumentCountryId"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.boundaries.legalDocumentTypes
                  ? _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.labels.legalDocumentType,
                            options: _vm.legalDocumentTypes,
                            name: "legalDocumentType",
                            readonly: !_vm.boundaries.mayEditLegalDocumentType,
                            validationsList:
                              _vm.citizenshipValidations.legalDocumentType,
                            "is-mandatory": true
                          },
                          model: {
                            value:
                              _vm.requiredInfoModel.legalDocumentType.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel.legalDocumentType,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "requiredInfoModel.legalDocumentType.value"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _vm.boundaries.canViewLegalDocumentIssueDate
                      ? _c("datepicker-element", {
                          attrs: {
                            id: "document-issue-date",
                            label: _vm.dictionary.labels.issueDate,
                            isMandatory: true,
                            readonly: !_vm.boundaries
                              .mayEditLegalDocumentIssueDate,
                            validationsList:
                              _vm.citizenshipValidations.legalDocumentIssueDate,
                            readonlyLabel:
                              _vm.dictionary.accessibility.readonlyLabel
                          },
                          model: {
                            value: _vm.requiredInfoModel.legalDocumentIssueDate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "legalDocumentIssueDate",
                                $$v
                              )
                            },
                            expression:
                              "requiredInfoModel.legalDocumentIssueDate"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _vm.boundaries.canViewLegalDocumentExpiryDate
                      ? _c("datepicker-element", {
                          attrs: {
                            id: "document-expiration-date",
                            label: _vm.dictionary.labels.expiryDate,
                            yearRange: "-25:+25",
                            isMandatory: true,
                            readonly: !_vm.boundaries
                              .mayEditLegalDocumentExpiryDate,
                            validationsList:
                              _vm.citizenshipValidations
                                .legalDocumentExpiryDate,
                            readonlyLabel:
                              _vm.dictionary.accessibility.readonlyLabel
                          },
                          model: {
                            value:
                              _vm.requiredInfoModel.legalDocumentExpiryDate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "legalDocumentExpiryDate",
                                $$v
                              )
                            },
                            expression:
                              "requiredInfoModel.legalDocumentExpiryDate"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-2" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c("input-element", {
                      attrs: {
                        id: "document-number",
                        name: "DocumentNumber",
                        type: "text",
                        isMandatory: true,
                        readonly: !_vm.boundaries.mayEditLegalDocumentNumber,
                        label: _vm.dictionary.labels.legalDocumentNumber,
                        validationsList:
                          _vm.citizenshipValidations.legalDocumentNumber
                      },
                      model: {
                        value: _vm.requiredInfoModel.legalDocumentNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.requiredInfoModel,
                            "legalDocumentNumber",
                            $$v
                          )
                        },
                        expression: "requiredInfoModel.legalDocumentNumber"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-md-flex justify-content-md-between button-container"
                },
                [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn secondaryButton",
                        attrs: {
                          type: "button",
                          "aria-describedby": _vm.previousStepAccessibilityLabel(
                            _vm.step
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.previousStep()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dictionary.buttons.previous) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn primaryButton",
                        attrs: {
                          type: "button",
                          "aria-describedby": _vm.nextStepAccessibilityLabel(
                            _vm.step,
                            _vm.steps
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dictionary.buttons.next) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
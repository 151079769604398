var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dataTables_wrapper dt-bootstrap4 no-footer",
      attrs: { id: "tableInbox_wrapper" }
    },
    [
      _c("v-data-table", {
        staticClass: "dataTable no-footer",
        attrs: {
          role: "grid",
          "no-data-text": _vm.dictionary.table.noRecordFound,
          headers: _vm.headers,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          pagination: _vm.setCurrentPage,
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "tr",
                  {
                    attrs: { id: item.messageId },
                    on: { click: _vm.rowClick }
                  },
                  [
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "email-list-item form-row",
                          attrs: {
                            href: "javascript:;",
                            "data-messageid": item.messageId
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-9 email-list-item__subject" },
                            [_c("h4", [_vm._v(_vm._s(item.subject))])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-3 text-right" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "email-list-item__date force-nowrap"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toShortDateOrTime")(
                                      item.creationDate
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "top",
            fn: function() {
              return [
                _c("datatable-search", {
                  staticClass: "tableSerachBox-align",
                  attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("datatable-pagination", {
                  attrs: {
                    datatablesDictionary: _vm.datatablesDictionary,
                    totalVisible: 8
                  },
                  model: {
                    value: _vm.options,
                    callback: function($$v) {
                      _vm.options = $$v
                    },
                    expression: "options"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictToMoneyOrSecurityOnly
    ? _c(
        "div",
        { staticClass: "dividend-subscription-allocation-radio-button" },
        [
          _c(
            "div",
            {
              staticClass: "dividend-subscription-allocation-radio-button-input"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.sharesDividendAmountPercentage,
                    expression: "value.sharesDividendAmountPercentage"
                  }
                ],
                attrs: {
                  type: "radio",
                  value: "0",
                  name: "dividendAllocationPercentage",
                  id: "dividendAllocationMoney"
                },
                domProps: {
                  checked: _vm._q(_vm.value.sharesDividendAmountPercentage, "0")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.value,
                      "sharesDividendAmountPercentage",
                      "0"
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "dividendAllocationMoney" } }, [
                _vm._v(
                  _vm._s(_vm.dictionary.subscriptionLabels.money) + " (100%)"
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dividend-subscription-allocation-radio-button-input"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.sharesDividendAmountPercentage,
                    expression: "value.sharesDividendAmountPercentage"
                  }
                ],
                attrs: {
                  type: "radio",
                  value: "100",
                  name: "dividendAllocationPercentage",
                  id: "dividendAllocationShares"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.value.sharesDividendAmountPercentage,
                    "100"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.value,
                      "sharesDividendAmountPercentage",
                      "100"
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "dividendAllocationShares" } }, [
                _vm._v(
                  _vm._s(_vm.dictionary.subscriptionLabels.shares) + " (100%)"
                )
              ])
            ]
          )
        ]
      )
    : _c(
        "div",
        { staticClass: "dividend-subscription-allocation-input-element" },
        [
          _c(
            "div",
            { staticClass: "dividend-allocation-percentage" },
            [
              _c(
                "label",
                {
                  staticClass: "dividend-allocation-percentage-label",
                  attrs: { name: "moneyDividendAllocationPercentage" }
                },
                [_vm._v(_vm._s(_vm.dictionary.subscriptionTable.money))]
              ),
              _vm._v(" "),
              _c("input-element", {
                staticClass: "dividend-allocation-percentage-input",
                attrs: {
                  name: "moneyDividendAllocationPercentage",
                  validationsList:
                    _vm.validation.moneyDividendAmountPercentageValidation
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("allocation-percentage-changed")
                  },
                  change: _vm.moneyDividendAmountPercentageChanged
                },
                model: {
                  value: _vm.value.moneyDividendAmountPercentage,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "moneyDividendAmountPercentage",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.moneyDividendAmountPercentage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dividend-allocation-percentage" },
            [
              _c(
                "label",
                {
                  staticClass: "dividend-allocation-percentage-label",
                  attrs: { name: "sharesDividendAllocationPercentage" }
                },
                [_vm._v(_vm._s(_vm.dictionary.subscriptionTable.shares))]
              ),
              _vm._v(" "),
              _c("input-element", {
                staticClass: "dividend-allocation-percentage-input",
                attrs: {
                  name: "sharesDividendAllocationPercentage",
                  validationsList:
                    _vm.validation.sharesDividendAmountPercentageValidation
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("allocation-percentage-changed")
                  },
                  change: _vm.sharesDividendAmountPercentageChanged
                },
                model: {
                  value: _vm.value.sharesDividendAmountPercentage,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value,
                      "sharesDividendAmountPercentage",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "value.sharesDividendAmountPercentage"
                }
              })
            ],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
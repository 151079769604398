var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isHidden
    ? _c(
        "li",
        { class: _vm.hiddenClass },
        [
          _c(
            "router-link",
            {
              class: [_vm.activeClass, _vm.linkClasses],
              attrs: { to: _vm.navUrlAction, title: _vm.label }
            },
            [
              _c("i", { class: [_vm.iconStyle, _vm.icon, "fa"] }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "navigation-text",
                  attrs: { "data-localize": _vm.concatenateLocalization() }
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
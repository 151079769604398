<template>
    <div v-if="isQuantityGreaterThanAvailable" class="form-group alert warning-message">
        {{ feitureWarningText }}
    </div>
</template>

<script>

import numeral from 'numeral';
export default {
    props: {
        warningText: String,
        available: Number,
        availableFormatted: String,
        quantity: String,
        scenarioQuantity: Number,
        isAllSelected: Boolean
    },
    computed: {
        isQuantityGreaterThanAvailable() {
            return (this.isAllSelected ? this.scenarioQuantity : numeral(this.quantity).value()) > this.available;
        },
        feitureWarningText() {
            return this.$replaceStringWithPlaceholders(this.warningText, { "NumberOfShares": this.availableFormatted });
        }
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.dictionary.confirmTitle))]),
      _vm._v(" "),
      _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          _c("MoneyTransferConfirmationFormListItem", {
            attrs: {
              label: _vm.dictionary.orderType,
              value: _vm.dictionary.orderTypePayment
            }
          }),
          _vm._v(" "),
          _c("MoneyTransferConfirmationFormListItem", {
            attrs: {
              label: _vm.dictionary.availableAmount,
              value: _vm.moneyTransferInstruction.availableAmountFormatted
            }
          }),
          _vm._v(" "),
          _c("MoneyTransferConfirmationFormListItem", {
            attrs: {
              label: _vm.dictionary.amount,
              value: _vm.moneyTransferInstruction.amountFormatted
            }
          }),
          _vm._v(" "),
          _c("MoneyTransferConfirmationFormListItem", {
            attrs: {
              label: _vm.dictionary.newAvailableAmount,
              value: _vm.moneyTransferInstruction.newAvailableAmountFormatted
            }
          }),
          _vm._v(" "),
          _c("MoneyTransferConfirmationFormListItem", {
            attrs: {
              label: _vm.dictionary.externalAccountId,
              value: _vm.moneyTransferInstruction.externalAccount.description
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-md-flex justify-content-md-between button-container"
        },
        [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn secondaryButton",
                on: {
                  click: function($event) {
                    return _vm.$emit("reject-instruction")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.commonDictionary.btnDecline))]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn primaryButton ml-0",
                on: {
                  click: function($event) {
                    return _vm.$emit("accept-instruction")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.commonDictionary.btnConfirm))]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <LoginTemplate  v-if="!isLoading" >
        <template v-if="!isPostSuccessful">
            <template slot="title">
                <h1 class="sr-only welcome-text">{{getUserNameTitle}}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{getUserNameTitle}}</p>
                <hr>
                <p class="text-left pb-2">{{getUserNameText}}</p>
            </template>
            <template slot="body">
                <alert-component v-if="error" variant="error">{{ error }}</alert-component>
                <form class="mb-lg-3" id="userNameRecovery" @submit.prevent="SubmitUserNameRecovery">
                    <div v-for="field in bindModel" :key="field.index">
                        <div class="form-group">
                            <datepicker-element v-if="field.type === 'fuatDateOfBirth'" :is-mandatory="field.isMandatory" :id="gethtmlId(field.label)" yearRange="-100:+0" :label="field.label" v-model="forgottenUsernameViewModel.forgottenUsernameAttributeViewModels[field.index].value" :validationsList="requiredFieldValidation(field.index)" />
                            <div v-else>
                                <input-element type="text" :label="field.label" :name="gethtmlId(field.label)"  :is-mandatory="field.isMandatory"
                                v-model="forgottenUsernameViewModel.forgottenUsernameAttributeViewModels[field.index].value" :validationsList="requiredFieldValidation(field.index)"></input-element>
                            </div>
                        </div>
                    </div>
                    <p>* {{dictionary.page.loginLabelMandatory}}</p>
                    <div class="d-md-flex justify-content-md-between button-container">
                        <button type="button" @click="back" class="btn mt-3 secondaryButton login-button">
                            {{commonDictionary.btnGoBack}}
                        </button>
                         <button type="button" class="btn mt-3 primaryButton login-button"  @click="SubmitUserNameRecovery()" >
                            {{commonDictionary.btnContinue}}
                        </button>
                    </div>
                </form>
            </template>
        </template>
        <template v-else>
            <template slot="body">
                <UserNameRecoverySuccessful :dictionary="dictionary" :common-dictionary="commonDictionary"/>
            </template>
        </template>
    </LoginTemplate>
</template>

<script>
  import { getAll,post } from 'api';
  import { required , requiredIf } from "vuelidate/lib/validators";
  import UserNameRecoverySuccessful from "../UserNameRecovery/user-name-recovery-successful.component.vue";
  import LoginTemplate from "../login-template.component.vue";
  import { EventBus } from "Shared/event-bus";

  export default {
      components:{
          UserNameRecoverySuccessful,
          LoginTemplate
      },
      data() {
          return {
              dictionary: Object,
              commonDictionary: Object,
              forgottenUsernameViewModel: Object,
              error: null,
              isSubmitting: false,
              isLoading:true,
              isPostSuccessful:false,
          }
      },
      validations() {
          return {
              forgottenUsernameViewModel:{
                  forgottenUsernameAttributeViewModels: {
                      $each: {
                          value: {
                              required: requiredIf((field) => field.isMandatory === true ) 
                          }
                      }
                  }
              }
          }
      },
      computed: {
          bindModel() {
              return this.forgottenUsernameViewModel.forgottenUsernameAttributeViewModels
                  .map((att) => {  
                      return {
                          index: att.index,
                          value: att.value,
                          isMandatory:  att.isMandatory,
                          label: this.labels.get(att.type),
                          type: att.type
                      };
                  });
          },
          getUserNameTitle() {
              return this.dictionary.recovery.forgotUserNameTitle;
          },
          getUserNameText() {
              return this.dictionary.recovery.forgotUserNameText;
          },
          labels() {
              return new Map([
                  ['fuatAddress',this.dictionary.labels.address],
                  ['fuatCity',this.dictionary.labels.city],
                  ['fuatCompany',this.dictionary.labels.company],
                  ['fuatCountry',this.dictionary.labels.country],
                  ['fuatDateOfBirth',this.dictionary.labels.dateOfBirth],
                  ['fuatEmailAddress',this.dictionary.labels.emailAddress],
                  ['fuatFirstName',this.dictionary.labels.firstName],
                  ['fuatLastName',this.dictionary.labels.lastName],
                  ['fuatMaidenName',this.dictionary.labels.maidenName],
                  ['fuatPostalCode',this.dictionary.labels.postalCode],
                  ['fuatRegistrationIdentifier',this.dictionary.labels.registrationNumber],
                  ['fuatSubsidiary',this.dictionary.labels.subsidiary],
                  ['fuatTelephone',this.dictionary.labels.telephone]
              ]);
          }
      },
      methods: {
          back() {
              this.$router.push({ path: '/login' });
          },
          gethtmlId(label) {
              return label.replace(/ /g, '-');
          },
          SubmitUserNameRecovery() {
              this.error = "";
              this.$v.$touch();
              EventBus.$emit("v-touch");

              if (this.$v.$invalid) 
                  return;

              this.isSubmitting= true;

              post("/login/ForgottenUsernameData",this.forgottenUsernameViewModel,
                  (response) => {
                      this.isPostSuccessful = true;
                  },
                  this,
                  () => { this.isSubmitting = false },
                  (err) => { 
                      this.error = err.response.data;
                      this.isSubmitting = false;
                  }
              );
          },
          requiredFieldValidation(index) {
             return [
                  {
                      id: 1,
                      error: this.$v.forgottenUsernameViewModel.forgottenUsernameAttributeViewModels.$each[index].value.$error,
                      type: this.$v.forgottenUsernameViewModel.forgottenUsernameAttributeViewModels.$each[index].value.required,
                      message: this.$replaceStringWithPlaceholders( this.dictionary.errors.loginErrorUserNameAttributeRequired, { FieldName : this.bindModel[index].label})
                  }
              ];
          },
          loadDictionaries() {
              this.isLoading = true;

              getAll([
                  "/login/Dictionary",
                  "/login/CommonDictionary",
              ], (responses) => {
                  this.dictionary = responses[0].data;
                  this.commonDictionary = responses[1].data;
                  this.isLoading = false;
              }, this);
          }
      },
      beforeCreate() {
          getAll([
              "/login/Dictionary",
              "/login/CommonDictionary",
              "/login/ForgottenUsernameData"
          ], (responses) => {
              this.dictionary = responses[0].data;
              this.commonDictionary = responses[1].data;
              this.forgottenUsernameViewModel = responses[2].data;
              this.isLoading = false;
          }, this);
      },
      mounted() {
          EventBus.$on("event-language-changed", () =>  {
              this.loadDictionaries();
          });
      },
  }
</script>
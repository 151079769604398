var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("modal-basic", {
        attrs: {
          id: "modal-cancel-order-mobile",
          title: _vm.title,
          "is-on": _vm.isModalVisible
        },
        on: {
          close: function($event) {
            return _vm.$emit("closeCancelDialog")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function() {
              return [
                _c("form", { attrs: { id: "cancel-order-form" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group button-container d-md-flex justify-content-md-end"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn primaryButton confirm-cancel-order-mobile-no",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeCancelDialog")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.dictionary.ordersPageBtnLabelCancelNo
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn secondaryButton confirm-cancel-order-mobile-yes",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.submitCancelOrder()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.dictionary.ordersPageBtnLabelCancelYes
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
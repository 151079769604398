var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "dividend-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "dividend-details-expansion-panel" },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "dividend-details-expansion-panel__header" },
                [
                  _c("h3", { staticClass: "h4" }, [
                    _vm._v(_vm._s(_vm.dividendSubscription.securityName))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile" },
                [
                  _c("DividendDataLabelRow", {
                    attrs: {
                      label: _vm.dictionary.subscriptionTable.securityName,
                      value: _vm.dividendSubscription.securityName
                    }
                  }),
                  _vm._v(" "),
                  _c("DividendDataLabelRow", {
                    attrs: {
                      label: _vm.dictionary.subscriptionTable.startDate,
                      value: _vm.dividendSubscription.startDate
                    }
                  }),
                  _vm._v(" "),
                  _c("DividendDataLabelRow", {
                    attrs: {
                      label: _vm.dictionary.subscriptionTable.endDate,
                      value: _vm.dividendSubscription.endDate
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex justify-content-end" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "subscribe",
                              _vm.dividendSubscription.dividendParticipationId,
                              _vm.dividendSubscription.dividendAllocationId
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.dictionary.subscriptionLabels.btnSubscribe)
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <dl class="order-details-list">
        <OrderDataLabel v-if="!toggles.hideOrderState" :label="dictionary.modal.state" :value="instructionData.State"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.reference" :value="instructionData.Reference"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.planName" :value="instructionData.PlanName"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.positionName" :value="instructionData.SecurityName"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.instructionDate" :value="instructionData.SubmissionDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.exerciseDate" :value="instructionData.ExerciseDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.scenarioName" :value="instructionData.ExerciseScenarioName"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.executionMoment" :value="instructionData.ExecutionMoment"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.ordered" :value="instructionData.NrOrderedDerivativesFormatted"></OrderDataLabel>
        <OrderDataLabel :label="labelNr" :value="dataNr"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.exercisePrice" :value="instructionData.ExercisePriceFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.securityPrice" :value="instructionData.SecurityPriceFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.grossAmount" :value="instructionData.GrossAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.netExerciseAmount" :value="instructionData.NetExerciseAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.grossProceeds" :value="instructionData.GrossProceedsFormatted"></OrderDataLabel>
        <OrderPayments  :label="dictionary.modal.taxPayment"  :payments="instructionData.TaxPayments" ></OrderPayments>
        <OrderPayments  :label="dictionary.modal.paymentFees" :payments="instructionData.FeesPayments" ></OrderPayments>
        <OrderBrokerFees :brokerFees="instructionData.BrokerFees" :brokerFeesFormated="instructionData.BrokerFeesFormatted" :label="dictionary.modal.brokerFees" ></OrderBrokerFees>
        <OrderPayments :label="dictionary.modal.usageFeesPayments" :payments="instructionData.UsageFeesPayments" ></OrderPayments>
        <OrderDeliveries  :label="dictionary.modal.nrSharesToDeliver"  :exerciseDeliveries="instructionData.ExerciseDeliveries"></OrderDeliveries>
        <OrderExerciseScenarioType :instructionData="instructionData" :dictionary="dictionary"></OrderExerciseScenarioType>
    </dl>
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
import OrderBrokerFees from "./order-broker-fees.component.vue";
import OrderPayments from "./order-payments.component.vue";
import OrderDeliveries from "./order-deliveries.component.vue";
import OrderExerciseScenarioType from "./order-exercise-scenario-type.component.vue";

export default {
  components: {
    OrderDataLabel,
    OrderPayments,
    OrderBrokerFees,
    OrderDeliveries,
    OrderExerciseScenarioType,
  },
  props: {
    instructionData: Object,
    dictionary: Object,
    toggles: Object
  },
  computed: {
    labelNr() {
      return `${this.dictionary.modal.nrExercisedDerivatives} / ${this.dictionary.modal.nrInExerciseDerivatives} / ${this.dictionary.modal.nrCancelledDerivatives}`;
    },
    dataNr() {
      return `${this.instructionData.NrExercisedDerivativesFormatted} / ${this.instructionData.NrInExerciseDerivativesFormatted} / ${this.instructionData.NrCancelledDerivativesFormatted}`;
    },
  },
};
</script>
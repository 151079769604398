<template>
<div class="coupon-details-content">
      <CouponDataLabelRow  :label="dictionary.table.state" :value="coupon.state" />  
      <CouponDataLabelRow  :label="dictionary.table.recordDate" :value="coupon.recordDate" />  
      <CouponDataLabelRow  :label="dictionary.table.deliveryDescription" :value="coupon.deliveryDescription" />  
      <CouponDataLabelRow  :label="dictionary.table.deliveryInstructionReference" :value="coupon.deliveryInstructionReference" />  
      <CouponDataLabelRow  :label="dictionary.table.deliveryInstructionDate" :value="coupon.deliveryInstructionDate" />
      <CouponDataLabelRow  :label="dictionary.table.securityName" :value="coupon.securityName" />
      <CouponDataLabelRow  :label="dictionary.table.nrSecurities" :value="coupon.nrSecuritiesFormatted" />
      <CouponDataLabelRow  :label="dictionary.table.grossAmount" :value="coupon.grossAmountFormatted" />
      <CouponDataLabelRow  :label="dictionary.table.feesAmount" :value="coupon.feesAmountFormatted" />
      <CouponDataLabelRow  :label="dictionary.table.netAmount" :value="coupon.netAmountFormatted" />   
</div>
</template>

<script>
import CouponDataLabelRow from "./coupon-data-label-row.component.vue";

export default {

  components: {
    CouponDataLabelRow,
  },
  props: {
    coupon: Object,
    dictionary: Object,
  },
};
</script>
<template>
    <div>
        <div class="title-block single-line">
            <h1 v-if="buyPositionModel" >{{ buyPositionModel.name }}</h1>
        </div>
        <div class="portlets-wrapper">
        <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="buyPositionModel && dictionary" class="row m-0">
                <div class="col-md-8 col-xl-9 p-0">
                    <div class="row m-0">
                     <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalAvailable" :text="buyPositionModel.availableFormatted" />
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalValue" :text="getValueFormatted(buyPositionModel, toggles)"></total-block>
                            </template>
                        </portlet-layout>
                       <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                                 <security-price-block :title="dictionary.blocks.lastPrice" :endpoint="lastPriceEndpoint"/>
                            </template>
                        </portlet-layout>
                    </div>
                    <div class="row m-0">
                        <portlet-layout column-class="col-xl-5" :title="dictionary.widgets.placeOrder" >
                            <template v-slot:body>
                                <BuyOrderForm :dictionary="dictionary" :buyPositionModel="buyPositionModel" :buyInstructionModel="buyInstructionModel" :error="error" @submit="submitBuyOrder" />
                                <loading-spinner :active="isSubmitting"></loading-spinner>
                            </template>
                        </portlet-layout>
                        <portlet-layout v-if="!toggles.hideSecurityPricesChartBlock" column-class="col-xl-7" :title="dictionary.widgets.securityPricesChart">
                            <template slot="body">
                                <prices-chart :getSecurityPrices="true" :securityId="buyPositionModel.securityId" />
                            </template>
                        </portlet-layout>
                    </div>
                </div>
                <div class="col-md-4 col-xl-3 p-0">
                    <portlet-layout v-if="!toggles.hideLastOrdersLinkBlock" column-class="block" :title="dictionary.widgets.lastOrders" >
                        <template slot="body">
                            <last-orders :toggles="toggles" :positionUsageId="buyPositionModel.positionUsageId"/>
                            <order-detail v-if="ordersDictionary" :title="ordersDictionary.modal.headerTitle" :toggles="toggles"/>
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/orders" :title="dictionary.widgets.seeAllOrders">{{dictionary.widgets.seeAllOrders}}</router-link>
                        </template>
                    </portlet-layout>
                    <portlet-layout v-if="!toggles.hideLastTransactionsLinkBlock" column-class="block" :title="dictionary.widgets.lastTransactions" >
                        <template slot="body">
                            <last-transactions :positionUsageId="buyPositionModel.positionUsageId" />
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/transactions" :title="dictionary.widgets.seeAllTransactions">{{dictionary.widgets.seeAllTransactions}}</router-link>
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get, post } from "api";
    import BuyOrderForm from "./buy-order-form.component.vue";
    import OrderDetail from "/Features/Orders/Assets/Vue/order-detail.component.vue";
    import numeral from 'numeral'
    import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';

    export default {
        mixins: [positionMethods],
        components: {
            BuyOrderForm,
            OrderDetail
        },
        props: {
            toggles: Object,
            dictionary: Object,
            ordersDictionary:Object
        },
        data() {
            return {
                buyPositionModel: null,
                buyInstructionModel: null,
                error: null,
                isLoading: true,
                isSubmitting: false
            };
        },
        computed: {
            lastPriceEndpoint() {
                let query = this.$route.query;
                return `/InternalExchanges/GetLastPriceBlock?positionUsageId=${query.positionUsageId}`;
            }
        },
        beforeCreate() {
            let query = this.$route.query;
            get(
                `/Buy/InstructionOrder?sharePlanParticipationId=${query.sharePlanParticipationId}` +
                `&positionUsageId=${query.positionUsageId}`,
                (response) => {
                    this.buyPositionModel = response.data;
                    this.buyInstructionModel = response.data.instructBuyInstruction;
                },
                this,
                () => (this.isLoading = false)
            );
        },
        methods: {
            submitBuyOrder(buyInstructionModel) {
                this.isSubmitting = true;
                let newInstructionModel = Object.assign({}, this.buyInstructionModel);
                newInstructionModel.limitPrice = numeral(buyInstructionModel.limitPrice).value();
                newInstructionModel.nrOrdered = numeral(buyInstructionModel.nrOrdered).value();
                newInstructionModel.amountOrdered = numeral(buyInstructionModel.amountOrdered).value();

                post(
                    "/Buy/InstructionConfirmation",
                    newInstructionModel,
                    (res) => {
                        const response = res.data;
                        this.$router.push({
                            path: "/buy/confirmation",
                            query: {
                                buyInstructionId: response.buyInstructionId,
                                positionUsageId: response.positionUsageId,
                                sharePlanParticipationId: response.sharePlanParticipationId,
                            },
                        });
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            }
        }
    };
</script>
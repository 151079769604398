<template>
  <div v-if="accountHolderModel.holderAddress" id="account-holder-container" class="add-account-step">
      <h3 v-if="currentStep <= 2" class="timeline-step-header" :id="'step-' + currentStep">{{dictionary.step}} 2: {{dictionary.subHeadingAccountHolder}}</h3> 
      <div class="account-holder-body">
      <template v-if="currentStep > 2">
        <FinancialAddAccountStepAccordion :heading="dictionary.subHeadingAccountHolder" :current-step="currentStep" :dictionary="dictionary" :showStep3="showStep3">
          <dl class="details-list account-holder-details">
            <div class="details-list-left">
              <BeneficiaryDataLabelRow :value="accountHolderModel.holderName" :label="dictionary.accountHolder.name" />
              <BeneficiaryDataLabelRow :value="accountHolderModel.holderAddress.addressProperty" :label="dictionary.accountHolder.addressLine1" />
              <BeneficiaryDataLabelRow :value="accountHolderModel.holderAddress.postalCode" :label="dictionary.accountHolder.postalCode" />
            </div>
            <div class="details-list-right">
              <BeneficiaryDataLabelRow :value="accountHolderModel.holderAddress.city" :label="dictionary.accountHolder.city" />
              <BeneficiaryDataLabelRow :value="accountHolderModel.holderAddress.state" :label="dictionary.accountHolder.state" />
              <BeneficiaryDataLabelRow :value="countryName" :label="dictionary.accountHolder.country" />
            </div>
          </dl>
        </FinancialAddAccountStepAccordion>
      </template>
      <div class="timeline-step-details" v-else-if="currentStep === 2">
        <div class="row">
          <alert-component v-if="messageInSecurityAccountHolderStep" variant="info"><div id="messageInSecurityAccountHolderStep" v-html="$sanitize(messageInSecurityAccountHolderStep)" class="rich-text-container"></div></alert-component>
        </div>
        <div class="row">
          <alert-component v-if="missingAccountHolderMessage" variant="error"><div id="missingAccountHolderMessage" v-html="$sanitize(missingAccountHolderMessage)" class="rich-text-container"></div></alert-component>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <input-element name="holderName" :disabled="securityBankAccountsInOwnNameOnly" v-model.trim="accountHolderModel.holderName" :label="dictionary.accountHolder.name"
                           :validationsList="accountHolderValidations.holderName" :is-mandatory="isNameAndCityMandatory">
            </input-element>
          </div>        
          <div class="col-md-6 col-lg-4">
            <input-element name="addressProperty" :disabled="securityBankAccountsInOwnNameOnly" v-model="accountHolderModel.holderAddress.addressProperty" :label="dictionary.accountHolder.addressLine1"
                :validationsList="accountHolderValidations.addressProperty" >
            </input-element>
          </div>
          <div class="col-md-6 col-lg-4">
            <input-element name="postalCode" :disabled="securityBankAccountsInOwnNameOnly"  v-model="accountHolderModel.holderAddress.postalCode" 
                :validationsList="accountHolderValidations.postalCode" :label="dictionary.accountHolder.postalCode" >
            </input-element>
          </div>
          <div class="col-md-6 col-lg-4">
            <input-element name="city" :disabled="securityBankAccountsInOwnNameOnly" v-model.trim="accountHolderModel.holderAddress.city" :label="dictionary.accountHolder.city" 
                :validationsList="accountHolderValidations.city" :is-mandatory="isNameAndCityMandatory">
            </input-element>
          </div>
          <div class="col-md-6 col-lg-4">
            <input-element name="state" :disabled="securityBankAccountsInOwnNameOnly" v-model="accountHolderModel.holderAddress.state" :label="dictionary.accountHolder.state"
                :validationsList="accountHolderValidations.state"> 
            </input-element>
          </div>
          <div class="col-md-6 col-lg-4">
            <select-element v-model="accountHolderModel.holderAddress.countryId"  :disabled="securityBankAccountsInOwnNameOnly" 
              :label="dictionary.accountHolder.country" :options="countries" name="accountHolderCountry" :is-mandatory="true">
            </select-element>
          </div>

        </div>

        <FinancialAddAccountMandatoryTooltip :tooltipText="dictionary.mandatory" />

        <div class="d-md-flex justify-content-md-between button-container">

          <FinancialCancelAddAccount :dictionary="dictionary" @cancel-add-account="cancelAddAccount()"/>
          
          <div>
            <button class="btn secondaryButton" type="button"  @click="previousStep()" >
                {{dictionary.btnPrevious}}
            </button>
            <button class="btn primaryButton" type="button" :disabled="disableNextButton" @click="nextStep()"  >
              {{dictionary.btnNext}}
            </button>
          </div>
        </div>
      </div>

      <div class="timeline-step-details mt-0">
            <div v-if="currentStep >= 3 && !showStep3" class="d-md-flex justify-content-md-between">
            
              <FinancialCancelAddAccount :dictionary="dictionary" @cancel-add-account="cancelAddAccount()"/>
                    
              <div>
                <button
                    class="btn secondaryButton"
                    type="button"
                    @click="previousStep()"
                    :aria-describedby="previousStepAccessibilityLabel(currentStep)">
                      {{dictionary.btnPrevious}}
                </button>
                <button
                  class="btn primaryButton"
                  type="button"
                  @click="submitData()"
                  :aria-describedby="nextStepAccessibilityLabel(currentStep, maxNumberOfSteps)">
                    {{ dictionary.btnSubmit }}
                </button>
              </div>
            </div>
      </div>

    </div>
  </div>
</template>

<script>
import { accountHolderModelValidations } from "./financial-add-account-form-validation.mixin";
import FinancialAddAccountMandatoryTooltip from "./financial-add-account-mandatory-tool-tip.component.vue";
import BeneficiaryDataLabelRow from "./../data-label-row.component.vue";
import FinancialAddAccountStepAccordion from "./finanacial-add-account-step-accordion.component.vue";
import FinancialCancelAddAccount from "./financial-cancel-add-account.component.vue";
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  mixins: [accountHolderModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  components: { 
    FinancialAddAccountMandatoryTooltip, 
    BeneficiaryDataLabelRow, 
    FinancialAddAccountStepAccordion, 
    FinancialCancelAddAccount
  },
  props: {
    dictionary: Object,
    validationDictionary: Object,
    accountType: String,
    currentStep: Number,
    maxNumberOfSteps: Number,
    accountHolderData: Object,
    countries: Array,
    isAccountHolderNameAndCityMandatory: Boolean,
    toggles: Object,
    securityAccountDisplaySettings: Object,
    externalAccountType: String,
    messageInSecurityAccountHolderStep: String,
    showStep3: Boolean
  },
  computed: {
    securityBankAccountsInOwnNameOnly() {
      return this.securityAccountDisplaySettings.securityBankAccountsInOwnNameOnly && this.accountType === this.$BankAccountType.SECURITY;
    },
    accountHolderModel() {
      return this.accountHolderData;
    },
    isNameAndCityMandatory(){
      return this.isAccountHolderNameAndCityMandatory;
    },
    countryName() {
        return this.countries.filter(c => c.value === this.accountHolderModel.holderAddress.countryId)[0]?.text;
    },
    accountHolderValidations() {
        return { 
          holderName : [
            {
                id: 1,
                error: this.$v.accountHolderModel.holderName.$error,
                type: this.$v.accountHolderModel.holderName.required,
                message: this.validationDictionary.required.holderName
            },
            {
                id: 2,
                error: this.$v.accountHolderModel.holderName.$error,
                type: this.$v.accountHolderModel.holderName.validName,
                message: this.validationDictionary.invalid.name
            }                 
          ],
          addressProperty: [
            {
                id: 1,
                error: this.$v.accountHolderModel.holderAddress.addressProperty.$error,
                type: this.$v.accountHolderModel.holderAddress.addressProperty.validAddress,
                message: this.validationDictionary.invalid.address
            } 
          ],
          postalCode: [
            {
                id: 1,
                error: this.$v.accountHolderModel.holderAddress.postalCode.$error,
                type: this.$v.accountHolderModel.holderAddress.postalCode.maxLength,
                message: this.$replaceStringWithPlaceholders(this.validationDictionary.invalid.postalCodeMaxLength, { "MaxLength": 20 })
            },
            {
                id: 2,
                error: this.$v.accountHolderModel.holderAddress.postalCode.$error,
                type: this.$v.accountHolderModel.holderAddress.postalCode.validPostalCode,
                message: this.validationDictionary.invalid.postalCode
            }
          ],
          city: [
              {
                id: 1,
                error: this.$v.accountHolderModel.holderAddress.city.$error,
                type: this.$v.accountHolderModel.holderAddress.city.required,
                message: this.validationDictionary.required.city
            },
            {
                id: 2,
                error: this.$v.accountHolderModel.holderAddress.city.$error,
                type: this.$v.accountHolderModel.holderAddress.city.validCity,
                message: this.validationDictionary.invalid.alphaNumeric
            }
          ],
          state: [
            {
                id: 1,
                error: this.$v.accountHolderModel.holderAddress.state.$error,
                type: this.$v.accountHolderModel.holderAddress.state.validState,
                message: this.validationDictionary.invalid.alphaNumeric
            }
          ]
        } 
    },
  },
  data() {
    return {
      disableNextButton: false
    };
  },
  created() {
    if (this.$v.$invalid && this.securityBankAccountsInOwnNameOnly) {
        this.missingAccountHolderMessage = this.dictionary.securityAccountDisplaySettings.securityAccountErrorMissingAccountHolderData;
        this.disableNextButton = true;
    }
    else {
        this.missingAccountHolderMessage = null;
        this.disableNextButton = false;
    }
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.focusOnFirstInvalidInput();
      }
      
      this.$emit("next-step", { step: this.currentStep, data: this.accountHolderModel } );
    },
    previousStep() {
      this.$emit("previous-step", { step: this.currentStep, data: this.accountHolderModel } );
    },
    cancelAddAccount() {
      this.$emit("cancel-add-account");
    },
    submitData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      
      this.$emit("set-compliance-external-account", { step: this.currentStep, data: this.accountHolderModel, submit:true } );
    }
  }
};
</script> 
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vesting" }, [
    _vm.position.showVestings
      ? _c("div", { staticClass: "vesting__show-vestings" }, [
          _c("div", { staticClass: "vesting__show-vestings__granted" }, [
            _vm._v(
              "\r\n            " + _vm._s(_vm.dictionary.table.granted) + " "
            ),
            _c("span", { staticClass: "float-right" }, [
              _vm._v(_vm._s(_vm.position.grantedFormatted))
            ])
          ]),
          _vm._v(" "),
          _vm.toggles.showAcceptedAmount
            ? _c("div", { staticClass: "vesting__show-vestings__accepted" }, [
                _vm._v(
                  "\r\n            " +
                    _vm._s(_vm.dictionary.table.accepted) +
                    " "
                ),
                _c("span", { staticClass: "float-right" }, [
                  _vm._v(_vm._s(_vm.position.acceptedFormatted))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "vesting__show-vestings__vested" }, [
            _vm._v(
              "\r\n            " + _vm._s(_vm.dictionary.table.vested) + " "
            ),
            _c("span", { staticClass: "float-right" }, [
              _vm._v(_vm._s(_vm.position.vestedFormatted))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vesting__show-vestings__unvested" }, [
            _vm._v(
              "\r\n            " + _vm._s(_vm.dictionary.table.unvested) + " "
            ),
            _c("span", { staticClass: "float-right" }, [
              _vm._v(_vm._s(_vm.position.unvestedFormatted))
            ])
          ])
        ])
      : _c("div", { staticClass: "vesting__hide-vestings" }, [
          _vm._v(_vm._s(_vm.position.grantedFormatted))
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { role: "status", "aria-busy": _vm.isLoading } }, [
    _c("span", { staticClass: "sr-only", attrs: { "aria-live": "polite" } }, [
      _vm._v(" " + _vm._s(_vm.loadingSrMessage) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
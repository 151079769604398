<template>
    <div>
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.vestings.title }}</h1>
        </div>
        <div class="portlets-wrapper">
            <component-spinner :spin="isLoading"></component-spinner>
            <Vestings v-if="!isLoading" :dictionary="dictionary" :toggles="toggles"
                                :datatables-dictionary="datatablesDictionary" />
        </div>
    </div>
</template>

<script>
import { getAll } from "api";
import Vestings from './vestings.component.vue';

export default {
    components: {
        Vestings
    },
    props: {
        pagesDictionary: Object
    },
    data() {
        return {
            dictionary: null,
            datatablesDictionary: {},
            toggles: {},
            isLoading: true
        }
    },
    beforeCreate() {
        getAll([
            '/Vestings/Dictionary', 
            '/Vestings/DatatablesDictionary',
            '/Vestings/Toggles'],
            (responses) => {
                this.dictionary = responses[0].data;
                this.datatablesDictionary = responses[1].data;
                this.toggles = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
    }
}
</script>
<template>
    <div id="transaction-overview" v-if="transactionsOptions && transactionsOptions.positions">
        <portlet-row>         
             <TransactionsTable :toggles="toggles" :dictionary="dictionary" :transactionsOptions="transactionsOptions" :datatablesDictionary="datatablesDictionary" 
                                ref="transactionsTable">
             <template v-slot:header>
                <TransactionsForm :dictionary="dictionary" :transactionsOptions="transactionsOptions" :positions="positions" :isShowBtn="isShowBtn"
                             @onPositionChange="onPositionChange" @onShowBtn="updateTable" @onSendButton="sendTransactionReport" />
                <alert-component v-if="sendtoInboxMessage" :variant="sendtoInboxMessage.variant" class="mt-2">{{ sendtoInboxMessage.message }}</alert-component>
            </template>
            </TransactionsTable>
        </portlet-row>
    </div>
</template>

<script>
    import { post } from 'api';
    import TransactionsTable from "./transactions-table.component.vue";
    import TransactionsForm from "./transactions-form.component.vue";

    const createDropDownOption = (text, value) => ({
        text: text,
        value: value
    });

    export default {
        components: {
            TransactionsTable,
            TransactionsForm
        },
        props: {
            dictionary: Object,
            transactionsOptions: Object,
            toggles: Object,
            datatablesDictionary: Object
        },
        watch: {
            transactionsOptions: {
                handler() {
                    this.sendtoInboxMessage = null;
                },
                deep: true
            }
        },
        data() {
            return {
                sendtoInboxMessage: null,
                isShowBtn: true
            };
        },
        beforeMount() {
            this.transactionsOptions.positionUsageId = this.transactionsOptions.positions[0]?.positionUsageId;
        },
        computed: {
            positions() {
                if (this.toggles.showAllTransactionsReport) {
                    this.transactionsOptions.positions.unshift({
                        positionName: this.dictionary.reports.transactionsAllPositions,
                        positionUsageId: null
                    });
                    this.isShowBtn = false;
                }

                return this.transactionsOptions.positions.map((p) =>
                    createDropDownOption(p.positionName, p.positionUsageId)
                );
            }
        },
        methods: {
            onPositionChange() {
                if (this.transactionsOptions.positionUsageId) {
                    this.isShowBtn = true;
                    this.updateTable();
                } else {
                    this.$refs.transactionsTable.prepareForAllPositions();
                    this.isShowBtn = false;
                }
            },
            async sendTransactionReport() {
                let url = '/Transactions/Overview';
                if (this.transactionsOptions.positionUsageId) {
                    url = '/Transactions/Report';
                }
                await post(url, this.transactionsOptions, () => {
                    this.sendtoInboxMessage = { variant: 'success', message: this.dictionary.reports.transactionsReportMessageSuccess }
                }, this, () => { }, () => {
                    this.sendtoInboxMessage = { variant: 'error', message: this.dictionary.reports.transactionsReportMessageFailure }
                });
            },
            updateTable() {
                this.$refs.transactionsTable.updateTable();
                this.sendtoInboxMessage = null;
            }
        }
    }
</script>
import CouponContainer from "./coupon-container.component.vue";

export const couponRoutes =
{
    path: '/coupon',
    component: CouponContainer,
    children: [
        {
            path: '*',
            redirect: '/coupon'
        }
    ]
}
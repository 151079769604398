var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-plan table-responsive mt-0" },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          "item-key": "positionName",
          headers: _vm.customHeaders,
          items: _vm.records,
          "disable-pagination": true,
          "footer-props": {
            disablePagination: true,
            disableItemsPerPage: true
          },
          "disable-sort": true,
          "items-per-page": -1,
          "hide-default-footer": true,
          "mobile-breakpoint": 0,
          "show-expand": _vm.isMobile
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.custom-name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(item.positionName))
                  ])
                ]
              }
            },
            {
              key: "item.custom-value",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    {
                      staticClass: "text-nowrap",
                      class: { "font-weight-bold": _vm.isMobile }
                    },
                    [_vm._v(_vm._s(item.valueFormatted))]
                  )
                ]
              }
            },
            {
              key: "item.data-table-expand",
              fn: function(ref) {
                var item = ref.item
                var isExpanded = ref.isExpanded
                var expand = ref.expand
                return [
                  _c(
                    "v-icon",
                    {
                      class: [
                        "v-data-table__expand-icon",
                        { "v-data-table__expand-icon--active": isExpanded }
                      ],
                      attrs: {
                        "aria-label":
                          item.positionName +
                          " " +
                          (isExpanded ? "collapse" : "expand") +
                          " more details"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return expand(!isExpanded)
                        }
                      }
                    },
                    [_vm._v("$expand")]
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _vm.isMobile
                    ? _c(
                        "td",
                        {
                          staticClass: "expanded-item-td",
                          attrs: { colspan: headers.length }
                        },
                        [
                          _c("PortfolioMobileDetailsContent", {
                            attrs: {
                              position: item,
                              dictionary: _vm.dictionary,
                              isMobile: _vm.isMobile
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="dictionary" class="cookie-policy-details">
        <div class="cookie-policy-details__content">
            <CookiePolicyModalDetailRow :title="dictionary.modal.titleStrictlyNecessary" :text="dictionary.modal.textStrictlyNecessary">
                <template v-slot:switch>
                    <div class="cookie-policy-detail__always-on">
                        <span>{{ dictionary.modal.alertStrictlyNecessaryAlwaysOn }}</span>
                        <i aria-hidden="true" class="far fa-check-circle fa ml-1"></i>
                    </div>
                </template>
            </CookiePolicyModalDetailRow>
            <hr />
            <CookiePolicyModalDetailRow :title="dictionary.modal.titleFunctional" :text="dictionary.modal.textFunctional">
                <template v-slot:switch>
                    <button type="button" :class="['toggle-button', 'btn-sm', 'btn-toggle', functionalToggleValue ? 'active' : '']"
                            data-toggle="button" @click="$emit('onFunctionalToggle')" :aria-pressed="functionalToggleValue"
                            :aria-label="dictionary.modal.titleFunctional">
                        <span class="handle"></span>
                    </button>
                </template>
            </CookiePolicyModalDetailRow>
            <hr />
            <CookiePolicyModalDetailRow :title="dictionary.modal.titlePerformanceAnalytics" :text="dictionary.modal.textPerformanceAnalytics">
                <template v-slot:switch>
                    <button type="button" :class="['toggle-button', 'btn-sm', 'btn-toggle', performanceToggleValue ? 'active' : '']"
                            data-toggle="button" @click="$emit('onPerformanceToggle')" :aria-pressed="performanceToggleValue"
                            :aria-label="dictionary.modal.titlePerformanceAnalytics">
                        <span class="handle"></span>
                    </button>
                </template>
            </CookiePolicyModalDetailRow>
        </div>
        <div class="row cookie-policy__buttons">
            <div class="col-12 col-md-6 mt-2">
                <button class="btn btn-primary btn-primary-white-background w-100" type="button" @click="$emit('cookie-allow-selection')">{{ dictionary.modal.btnAllowSelection }}</button>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <button class="btn btn-primary btn-primary-white-background w-100" type="button" @click="$emit('cookie-allow-all')">{{ dictionary.modal.btnAllowAll }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import CookiePolicyModalDetailRow from "./cookie-policy-modal-detail-row.component.vue";

    export default {
        components: {
            CookiePolicyModalDetailRow
        },
        props: {
            dictionary: Object,
            functionalToggleValue: Boolean,
            performanceToggleValue: Boolean
        }
    }
</script>
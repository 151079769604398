<template>
    <ReportsDetail id="PortfolioReportDetails" v-if="dictionary"  :dictionary="dictionary" :toggles="toggles" :reportName="dictionary.portfolio.name" :reportDescription="dictionary.portfolio.description" :is-mobile="isMobile">
        <template v-slot:body>
              <div class="custom-report-form" v-if="!isMobile">             
                  <div class="custom-report-form-empty-height">
                    <!--Placeholder for date range fields-->
                  </div>
                  <div class="custom-report-form-btn">   
                        <router-link to="/reports/portfolioOverview" class="btn primaryButton float-right">{{dictionary.portfolio.extractButton}}</router-link>
                  </div>
              </div>
              <div class="d-flex justify-content-start" v-else>             
                  <div class="custom-report-form-btn col">   
                        <router-link to="/reports/portfolioOverview" class="btn primaryButton float-right">{{dictionary.portfolio.extractButton}}</router-link>
                  </div>
              </div>
        </template>
    </ReportsDetail> 
</template>

<script>
import ReportsDetail from "./reports-detail.component.vue";

export default {
  components: {
    ReportsDetail,
  },
  props: {
    dictionary: Object,
    toggles: Object,
    isMobile: Boolean
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  },
};
</script>

<template>
    <router-view :dictionary="dictionary" :toggles="toggles" :is-loading="isLoading" :pagesDictionary="pagesDictionary" />
</template>

<script>
    import { getAll } from 'api';
    
    export default {
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                toggles: null,
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                "/Offerings/Dictionary",
                "/Offerings/Toggles"
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    }
</script>
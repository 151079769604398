<template>
  <div class="personal-data-menu" v-if="isVisible && isLoaded && showAvatarIcon">
    <ul class="sidebar-nav personal-data-menu__list">
          <li v-if="userFullName" class="personal-data-menu__user">
            <div class="nav-item">
              <i class="far fa-user fa-2x"></i>
              <span class="navigation-text">{{ userFullName }}</span>
            </div>
          </li>
          <li v-for="(item, index) in filteredItems" :key="`${item.name}-${index}`">
            <router-link :to="item.relativeUrl" class="nav-item personal-data-link">
              <i :class="['far', 'fa-2x', item.icon]"></i>
              <span class="navigation-text">{{ item.text }}</span>
            </router-link >
          </li>
    </ul>
  </div>
</template>

<style>
@import "../personal-data-dropdown.scss";
</style>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import { personalDataDropdownMixin } from "./mixins/personal-data-dropdown.mixins";

export default {
  mixins: [ScreenSizeMixin, personalDataDropdownMixin],
  props: {
    dictionary: Object,
    toggles: Object,
    isHidden: {
      default: false,
      type: Boolean
    },
    accountDataModel: Object,
    beneficiaryBoundariesDataModel: Object,
    userFullName: String
  },
  computed: {
    isVisible() {
      if (!this.isDesktop && !this.isHidden) {
        return true;
      }

      return false;
    }
  }
};
</script>
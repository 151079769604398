<template>
<portlet-layout :title="dictionary.widgets.participations" >
    <template v-slot:body>
        <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.couponsPageDescription)"></div>
        <CouponTable :dictionary="dictionary"  :datatablesDictionary="datatablesDictionary" />
    </template>
</portlet-layout>
</template>

<script>
import CouponTable from './coupon-table.component.vue';

export default {
    components: {
        CouponTable
    },
    props: {
        dictionary: Object,
        datatablesDictionary: Object
    }
}
</script>
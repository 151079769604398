var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.model.requiresInput
        ? _c("p", [_vm._v(_vm._s(_vm.dictionary.widgets.textConfirmation))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          _vm.model.state && !_vm.toggles.hideOrderState
            ? _c("ExitConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.confirmation.stateName,
                  value: _vm.model.state.description
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.planName,
              value: _vm.model.planName
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationPositionsRow", {
            attrs: {
              label: _vm.dictionary.confirmation.positionName,
              positions: _vm.model.positions
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.form.orderType,
              value: _vm.dictionary.form.orderTypeExitName
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.scenarioName,
              value: _vm.model.exitScenarioName
            }
          }),
          _vm._v(" "),
          _vm.model.executionMoment
            ? _c("ExitConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.confirmation.executionMoment,
                  value: _vm.model.executionMoment.description
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.reference,
              value: _vm.model.reference
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.instructionDate,
              value: _vm._f("toDateTime")(_vm.model.submissionDate)
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.ordered,
              value: _vm.model.nrOrderedFormatted
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.securityPrice,
              value: _vm.model.securityPriceFormatted
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.limitPrice,
              value: _vm.model.limitPriceFormatted
            }
          }),
          _vm._v(" "),
          _c("payments-result", {
            attrs: {
              payments: _vm.model.taxPayments,
              noPaymentsLabel: _vm.dictionary.confirmation.taxPayment
            }
          }),
          _vm._v(" "),
          _c("payments-result", {
            attrs: {
              payments: _vm.model.feesPayments,
              noPaymentsLabel: _vm.dictionary.confirmation.paymentFees
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            staticClass: "confirmation-total-row",
            attrs: {
              label: _vm.dictionary.confirmation.proceeds,
              value: _vm.model.proceedsFormatted
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.model.requiresInput
        ? _c("div", { staticClass: "float-md-right clearfix" }, [
            _c(
              "button",
              {
                staticClass: "btn primaryButton",
                attrs: { disabled: false },
                on: {
                  click: function($event) {
                    return _vm.$emit("exit-instruction-go-back")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.form.goBack))]
            )
          ])
        : !_vm.model.disclaimers.length
        ? _c(
            "ExitConfirmationButtonsComponent",
            _vm._g(
              { attrs: { exitInstructionViewModel: _vm.model } },
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        !_vm.isModal
          ? _c("div", { staticClass: "title-block single-line" }, [
              _vm.dictionary
                ? _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.title))])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        _vm.requiredUploadDocument
          ? _c("div", [
              _vm.requiredUploadDocument &&
              _vm.requiredDocumentDetails.isActive &&
              !_vm.inEdit
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row required-document-adhoc-upload m-0 no-gutters",
                      attrs: { "data-toggle": "portlet" }
                    },
                    [
                      _c("conditional-portlet-layout", {
                        attrs: {
                          condition: !_vm.isModal,
                          "portlet-class": "col col-md-10 col-lg-8 col-xxl-6",
                          "div-class": "col",
                          hideTitle: true,
                          allowCollapse: false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "required-document-header" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row no-gutters" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "required-document-header__text rich-text-container col-md-9",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$sanitize(
                                                  _vm.dictionary.labels
                                                    .textHeaderRequiredDocument
                                                )
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "required-document-header__button col-md-3 button-container"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary text-nowrap float-md-right",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showUploadForm()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.dictionary.labels
                                                        .actionLinkUploadNew
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          874661832
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row m-0 no-gutters",
                  attrs: { "data-toggle": "portlet" }
                },
                [
                  _c("conditional-portlet-layout", {
                    attrs: {
                      condition: !_vm.isModal,
                      "portlet-class": "col col-md-10 col-lg-8 col-xxl-6",
                      "div-class": "col",
                      hideTitle: true,
                      allowCollapse: false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "container-required-documents  mb-4"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "documents-container",
                                      attrs: { id: "requiredDocuments" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "required-document-block"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "document-header" },
                                            [
                                              _c("h2", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.requiredDocumentDetails
                                                      .name
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "document-upload"
                                                },
                                                [
                                                  _vm.requiredDocumentDetails
                                                    .requiredDocumentParticipation
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .requiredDocumentDetails
                                                              .requiredDocumentParticipation
                                                              .state
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass:
                                              "document-body rich-text-container",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$sanitize(
                                                  _vm.requiredDocumentDetails
                                                    .text
                                                )
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("RequiredDocumentsDetailsForm", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  requiredUploadDocument:
                                    _vm.requiredUploadDocument,
                                  inEdit: _vm.inEdit
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2288605556
                    )
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
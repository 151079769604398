<template>
    <portlet-layout column-class="col-lg-8 col-xl-9" :title="dictionary.subscriptionWidgets.titleEnterSubscription" >
        <template slot="body">             
            <div class="dividend-subscription-form" v-if="dictionary && dividendSubscription">
                <div class="page-description rich-text-container" v-html="$sanitize(pageDescription)"></div>

                <div class="dividend-subscription-headers">
                    <div class="dividend-security-name dividend-subscription-header">
                        <p>{{dictionary.subscriptionTable.securityName}}</p>
                    </div>
                    <div class="dividend-subscription dividend-subscription-header">
                        <p>{{dictionary.subscriptionTable.subscription}}</p>
                    </div>
                </div>

                <div :class="gridClass">
                    <div class="dividend-security-name">
                        <p class="dividend-subscription-mobile-header">{{dictionary.subscriptionTable.securityName}}</p>
                        <p>{{dividendSubscription.securityName}}</p>
                    </div>
                    <div class="dividend-subscription">
                        <DividendAllocationPercentageInput :dictionary="dictionary" :restrictToMoneyOrSecurityOnly="dividendSubscription.restrictToMoneyOrSecurityOnly" @allocation-percentage-changed="validate" :validation="validationsList.dividendAllocationPercentagesValidation" v-model="dividendAllocationPercentages"/>
                    </div>
                </div>

                <DividendSubscriptionButtons :cancelButtonLabel="dictionary.subscriptionLabels.btnCancel" :subscribeButtonLabel="dictionary.subscriptionLabels.btnSubscribe" @cancel="cancel" @subscribe="subscribe" />
            </div>
        </template>
    </portlet-layout>
</template>

<script>
    
    import { EventBus } from "Shared/event-bus";
    import DividendAllocationPercentageInput from "./dividend-allocation-percentage-input.component.vue";
    import DividendSubscriptionButtons from "./dividend-subscription-buttons.component.vue";
    import { dividendSubscriptionValidation } from "./mixins/dividend-subscription-validation.mixin";
    import numeral from 'numeral';

    export default {
        mixins: [dividendSubscriptionValidation],
        components: {
            DividendAllocationPercentageInput,
            DividendSubscriptionButtons,
        },
        props: {
            dictionary: Object,
            dividendSubscription: Object,
        },
        data() {
            return {
                dividendAllocationPercentages: {
                    moneyDividendAmountPercentage: 0,
                    sharesDividendAmountPercentage: 100 
                }
            }
        },
        computed: { 
            pageDescription() {
                return this.dividendSubscription.restrictToMoneyOrSecurityOnly 
                    ? this.dictionary.subscriptionWidgets.selectOptionDescription : this.dictionary.subscriptionWidgets.enterPercentageDescription;
            },
            gridClass() {
                return this.dividendSubscription.restrictToMoneyOrSecurityOnly ? "dividend-subscription-grid-center" : "dividend-subscription-grid-top";
            }
        },
        methods: {
            cancel() {
                this.$router.push({
                    path: '/dividend'
                });
            },
            validate() {
                this.$v.$touch();
                EventBus.$emit("v-touch");
            },
            subscribe() {
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    return;
                }

                this.$emit("subscribe", {
                    dividendParticipationId: this.dividendSubscription.dividendParticipationId,
                    dividendAllocationId: this.dividendSubscription.dividendAllocationId,
                    dividendAmountPercentage: numeral(this.dividendAllocationPercentages.sharesDividendAmountPercentage).value()
                });
            },
        },
    };
</script>
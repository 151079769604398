import InboxContainer from "./inbox-container.component.vue";

export const inboxRoutes =
{
    path: '/inbox',
    component: InboxContainer,
    children: [
        {
            path: '*',
            redirect: '/inbox'
        }
    ]
}

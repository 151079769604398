<template>
  <portlet-tab :tabTitle="financialDictionary.transferSettingsTabName" tabId="TransferSettings" :isActive="isActive">
    <h2>{{financialDictionary.widget.transferSettings}}</h2>
    
    <div class="page-description rich-text-container" v-html="$sanitize(financialDictionary.transferSettings.beneficiaryPageDescriptionTransferSettings)"></div>
    
      <FinancialTransferSettingsTable :dictionary="financialDictionary.transferSettings"
            :datatables-dictionary="datatablesDictionary">
      </FinancialTransferSettingsTable>

  </portlet-tab>
</template>

<script>

import FinancialCard from "./financial-card.component.vue";
import FinancialTransferSettingsTable from "./financial-transfer-settings-table.component.vue";
import AlertComponent from "Shared/Components/Vue/Alert/alert.component.vue";

export default {
  props: {
    financialDictionary: Object,
    transferSettingsData: Array,
    datatablesDictionary: Object,
    isActive: Boolean
  },
  components: {
    FinancialCard,
    FinancialTransferSettingsTable,
    AlertComponent
  },  
};
</script>
<template>
<portlet-layout :title="dictionary.page.subHeading">
    <template v-slot:body>
        <Reports :dictionary="dictionary" :toggles="toggles" :boundaries="boundaries" />
    </template>
</portlet-layout>
</template>

<script>
import Reports from "./reports.component.vue";

export default {
  components: {
    Reports,
  },
  props: {
		dictionary: Object,
		toggles: Object,
		boundaries: Object
  },
};
</script>
<template>
  <div>
    <component-spinner :spin="isLoading"></component-spinner>
    <div v-if="!isLoading" id="login-disclaimers-box">
      <div class="row">
        <div class="col"> 
          <LoginDisclaimers  :disclaimers="disclaimers" :dictionary="dictionary" :boundaries="boundaries" @reloadDisclaimers="reload"></LoginDisclaimers>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAll } from "api";
import LoginDisclaimers from './login-disclaimers.component.vue';

export default {
  components: {
    LoginDisclaimers
  },
  data() {
    return {
        dictionary:null,
        disclaimers:null,
        boundaries:null,
        isLoading: true,
    }
  },
   methods: {
    reload() {
      this.isLoading = true;

      getAll([
        "/LoginDisclaimers/SharePlanDisclaimers", 
        "/LoginDisclaimers/Boundaries"],
        (response) => {
            this.disclaimers = response[0].data;
            this.boundaries = response[1].data;
            this.isLoading = false;
        }, 
        this);
    },
  },
  beforeCreate() {
        this.$emit('set-header', {header: null, subHeader: null});

        getAll([
            "/LoginDisclaimers/Dictionary", 
            "/LoginDisclaimers/SharePlanDisclaimers", 
            "/LoginDisclaimers/Boundaries"],
            (response) => {
                this.dictionary = response[0].data;
                this.disclaimers = response[1].data;
                this.boundaries = response[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.$emit('set-header', {header: this.dictionary.page.header});
                this.isLoading = false;
            }, 
            this);
    }
};
</script>
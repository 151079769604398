var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.lostPhoneScreen
      ? _c(
          "div",
          { staticClass: "mfa-confirmation" },
          [
            _c("ILostMyPhone", {
              attrs: { dictionary: _vm.dictionary },
              on: {
                cancel: function($event) {
                  return _vm.cancel()
                }
              }
            })
          ],
          1
        )
      : _vm.showFirstScreen && _vm.isOnFirstScreen
      ? _c(
          "div",
          { staticClass: "mfa-confirmation" },
          [
            _c("MfaMsauthenticatorConfiguration", {
              attrs: {
                currentFactorType: _vm.currentFactorType,
                mfaBoundariesDataModel: _vm.mfaBoundariesDataModel,
                mfaEnableDisableDataModel: _vm.mfaEnableDisableDataModel,
                error: _vm.currentError,
                showError: _vm.showErrorInConfiguration,
                isMobile: _vm.isMobile,
                dictionary: _vm.dictionary
              },
              on: {
                "disable-close-button": function($event) {
                  return _vm.$emit("disable-close-button")
                },
                cancel: function($event) {
                  return _vm.cancel()
                },
                "continue-mfa": function($event) {
                  return _vm.continueMfa()
                }
              }
            })
          ],
          1
        )
      : !_vm.showFirstScreen || !_vm.isOnFirstScreen
      ? _c(
          "div",
          { staticClass: "mfa-confirmation" },
          [
            _vm.getIdentifier
              ? _c("MfaMsauthenticatorCode", {
                  attrs: {
                    isDisabled: _vm.isDisabled,
                    currentFactorType: _vm.currentFactorType,
                    isMobile: _vm.isMobile,
                    mfaEnableDisableDataModel: _vm.mfaEnableDisableDataModel,
                    isVisible: _vm.isVisible,
                    identifier: _vm.getIdentifier,
                    error: _vm.currentError,
                    dictionary: _vm.dictionary
                  },
                  on: {
                    "disable-close-button": function($event) {
                      return _vm.$emit("disable-close-button")
                    },
                    navigateToLostMyPhone: function($event) {
                      return _vm.navigateToLostMyPhone()
                    },
                    "post-mfa": _vm.postMfa,
                    cancel: function($event) {
                      return _vm.cancel()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
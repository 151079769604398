<template>
    <div class="sidebar-wrapper" @click="removeBodyClass('aside-toggled', 'navigation-menu', 'personal-menu')">
        <aside id="sidebar" class="aside-container sidebar-container navigation-menu">
            <nav v-if="!isLoading" class="sidebar aside-inner" data-sidebar-anyclick-close="" aria-label="Site navigation">
                <SidebarMenu :dictionary="dictionary" :toggles="toggles" :features="features" :logoutEndpoint="logoutEndpoint" />
            </nav>
        </aside>
        <aside class="aside-container sidebar-container personal-menu">
            <div class="aside-inner">
                <nav v-if="!isLoading" class="sidebar" data-sidebar-anyclick-close="" aria-label="Site navigation">
                    <PersonalDataMenu :accountDataModel="accountDataModel" :beneficiaryBoundariesDataModel="beneficiaryBoundariesDataModel" :dictionary="personalDataDictionary" :toggles="personalDataToggles" :userFullName="userFullName"></PersonalDataMenu>
                </nav>
            </div>
        </aside>
    </div>
</template>

<script>
    import SidebarMenu from './sidebar-menu.component.vue';
    import PersonalDataMenu from 'Features/PersonalDataDropdown/Assets/Vue/personal-data-menu.component.vue';
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            SidebarMenu,
            PersonalDataMenu,
        },
        props: {
            toggles: Object,
            dictionary: Object,
            logoutEndpoint: String,
            isLoading: Boolean,
            features: Array
        },
        data() {
            return {
                personalDataDictionary: {},
                personalDataToggles: {},
                beneficiaryBoundariesDataModel: {},
                accountDataModel: {},
                userFullName: null
            }
        },
        mounted() {
            EventBus.$on("personal-data-dropdown", (dictionary, toggles, accountDataModel, beneficiaryBoundariesDataModel) => {
                this.personalDataDictionary = dictionary;
                this.personalDataToggles = toggles;
                this.beneficiaryBoundariesDataModel = beneficiaryBoundariesDataModel;
                this.accountDataModel = accountDataModel;
            });

            EventBus.$on("personal-data-user-full-name", (userFullName) => {
                this.userFullName = userFullName;
            });
        },
        methods: {
            removeBodyClass(...args) {
                args.map(e => document.body.classList.remove(e))
            }
        }
    };
</script>
<template>
<div>
    <v-expansion-panels :class="['dividend-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion>
        <v-expansion-panel class="dividend-details-expansion-panel">
            <v-expansion-panel-header class="dividend-details-expansion-panel__header">
                <h3 class="h4">{{ dividendSubscription.securityName }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <DividendDataLabelRow :label="dictionary.subscriptionTable.securityName" :value="dividendSubscription.securityName" />
                <DividendDataLabelRow :label="dictionary.subscriptionTable.startDate" :value="dividendSubscription.startDate" />
                <DividendDataLabelRow :label="dictionary.subscriptionTable.endDate" :value="dividendSubscription.endDate" />
                <div class="flex justify-content-end">
                    <button class="btn btn-primary" @click="$emit('subscribe',dividendSubscription.dividendParticipationId, dividendSubscription.dividendAllocationId)">{{ dictionary.subscriptionLabels.btnSubscribe }}</button>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</div>
</template>

<script>
import DividendSubscriptionDetailsRow from "./dividend-subscription-details-row.component.vue";
import DividendDataLabelRow from "./dividend-data-label-row.component.vue";

export default {
    components: {
        DividendSubscriptionDetailsRow,
        DividendDataLabelRow
    },
    props: {
        dividendSubscription: Object,
        dictionary: Object,
        isMobile: Boolean
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        },
    }
}
</script>
<template>
<div>
    <component-spinner v-if="isLoading && !isModalVisible" :spin="isLoading"></component-spinner>
    <div v-if="!isLoading && !isModalVisible" >
        <button v-if="canBeCancelledByBeneficiary" class="btn secondaryButton" type="button" @click="openCancelDialog()">
                                {{dictionary.ordersPageBtnLabelCancelOrder}}
        </button>
        <v-divider></v-divider>

        <OrdersDetailsContentRowHeader :label="dictionary.exitsModalHeadingBasicData"></OrdersDetailsContentRowHeader>
        <OrdersDetailsContentRow :label="dictionary.instructionDate" :value="item.submissionDate" />
        <OrdersDetailsContentRow :label="dictionary.reference" :value="item.reference" />
        <OrdersDetailsContentRow :label="dictionary.instruction" :value="item.orderTypeDescription" />
        <OrdersDetailsContentRow :label="dictionary.state" :value="item.state" />
        <OrdersDetailsContentRow :label="dictionary.positionName" :value="item.productName" />

        <OrdersDetailsContentRowHeader :label="dictionary.exitsModalHeadingOrderDetails"></OrdersDetailsContentRowHeader>
        <component v-if="!isLoading" :is="transactionTypeModalElement" v-bind="{ 'instruction-data': instructionData, 'dictionary': detailsDictionary, 'toggles':toggles }"></component>
    </div>
    <ConfirmCancelOrderMobile :isModalVisible="isModalVisible" :instructionData="instructionData" :dictionary="dictionary" :title="dictionary.ordersPageCancelConfirmation" @closeCancelDialog="closeCancelDialog"></ConfirmCancelOrderMobile>
</div>
</template>

<script>

import OrdersDetailsContentRow from './orders-details-content-row.component.vue';
import OrdersDetailsContentRowHeader from './orders-details-content-row-header.component.vue';
import { getAll } from "api";
import BuyDetail from "./buy-detail.component.vue";
import ExitDetail from "./exit-detail.component.vue";
import ExerciseDetail from "./exercise-detail.component.vue";
import InternalExchangeDetail from "./internal-exchange-detail.component.vue";
import InternalExchangeCancelOrder from "./internal-exchange-cancel-order.component.vue";
import InternalExchangeCancelOrderModalWrapper from './internal-exchange-cancel-order-modal-wrapper.component.vue';
import ConfirmCancelOrderMobile from './confirm-cancel-order-mobile.component.vue';

export default {
    components: {
        OrdersDetailsContentRow,
        OrdersDetailsContentRowHeader,
        BuyDetail,
        ExitDetail,
        ExerciseDetail,
        InternalExchangeDetail,
        InternalExchangeCancelOrder,
        InternalExchangeCancelOrderModalWrapper,
        ConfirmCancelOrderMobile
    },
    props: {
        dictionary: Object,
        toggles: Object,
        item: Object
    },
    data() {
        return {
            instructionData: {},
            detailsDictionary: null,
            isLoading:true,
            transactionType: "",
            transactionTypeRequestDictionary: {
                    [this.$TransactionType.EXIT]: "Exits",
                    [this.$TransactionType.INTERNAL_EXCHANGE]: "InternalExchanges",
                    [this.$TransactionType.EXERCISE]: "Exercises",
                    [this.$TransactionType.BUY]: "Buy"
            },
            isModalVisible:false
        };
    },
    created() {
        this.loadOrderDetails(this.item.instructionId, this.item.instructionType);
    },
    methods:{
        loadOrderDetails(instructionId, instructionType) {
            this.transactionType = instructionType;
            this.instructionData = {};
            this.loading = true;
            
            const requestUrl = `/Orders/GetInstructionDetails?instructionId=${instructionId}&transactionType=${instructionType}`;
            const dictionaryUrl = `/${this.transactionTypeRequestDictionary[instructionType]}/Dictionary`

            getAll([requestUrl, dictionaryUrl], (response) => {

                this.transactionType = instructionType;
                this.instructionData = response[0].data;
                this.detailsDictionary = response[1].data;
                this.isLoading = false;
                }, this);
        },
        openCancelDialog() {
            this.isModalVisible = true;
        },
        closeCancelDialog() {
            this.isModalVisible = false;
        }
    },
    computed: {
        canBeCancelledByBeneficiary() {
            return this.item.canBeCancelledByBeneficiary;
        },
        transactionTypeModalElement() {
            return this.transactionTypeModalDictionary[this.transactionType];
        },
        transactionTypeModalDictionary(){
            return {
                [this.$TransactionType.EXIT]: "exit-detail",
                [this.$TransactionType.INTERNAL_EXCHANGE]: "internal-exchange-detail",
                [this.$TransactionType.BUY]: "buy-detail",
                [this.$TransactionType.EXERCISE]: "exercise-detail"
            }
        }
  }
}
</script>
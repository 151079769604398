var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vestings-state-info" }, [
    _c("p", { staticClass: "vestings-row" }, [
      _c("i", {
        staticClass: "far fa-clock",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" " + _vm._s(_vm.dictionary.labels.futureVesting))
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "vestings-row" }, [
      _c("i", {
        staticClass: "far fa-check-circle",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" " + _vm._s(_vm.dictionary.labels.alreadyVested))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-if="bankAccountModel" id="bank-account-container" class="add-account-step">
      <h3 v-if="currentStep <= 3" class="timeline-step-header" :id="'step-' + currentStep">{{dictionary.step}} 3: {{dictionary.subHeadingBankAccount}}</h3> 
      <div>
        <FormErrorAlert :errors="errors"></FormErrorAlert>
      </div> 
      <div class="bank-account-body">
        <template v-if="currentStep > 3">
          <FinancialAddAccountStepAccordion :heading="dictionary.subHeadingAccountHolder" :current-step="currentStep" :dictionary="dictionary" :showStep3="showStep3">
            <dl class="details-list bank-account-details">
              <div class="details-list-left">
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyAccount && externalAccountBoundaries.requiresIBAN" :value="bankAccountModel.iban" :label="ibanLabel" />
                <BeneficiaryDataLabelRow v-else-if="showBankFieldsForSecurityAccounts && (isSecurityAccount && isExternalAccountTypeSecurityShareCertificate)
                                                  ||(isSecurityAndEATypeDRS)" :value="bankAccountModel.iban" :label="getLabelForIBanInput" />
                <BeneficiaryDataLabelRow v-else-if="isMoneyAccount || (isSecurityAccount && shouldShowAccountNumberInput)" :value="bankAccountModel.accountNumber" :label="(isSecurityAccount && isExternalAccountTypeSecurityShareCertificate) ? dictionary.bankAccount.ivcNumber : dictionary.bankAccount.accountNumber" />
                <BeneficiaryDataLabelRow v-if="isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.bankName" :label="dictionary.bankAccount.bankName" />
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.bic" :label="dictionary.bankAccount.bankIdentificationCode" />
                <BeneficiaryDataLabelRow v-else-if="showBankFieldsForSecurityAccounts && isSecurityAndEATypeDRS" :value="bankAccountModel.bic" :label="dictionary.bankAccount.socialSecurityNumber" />
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.accountAddress.addressProperty" :label="dictionary.bankAccount.addressLine1" />
              </div>
              <div class="details-list-right">
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.accountAddress.postalCode" :label="dictionary.bankAccount.postalCode" />
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.accountAddress.city" :label="dictionary.bankAccount.city" />
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.accountAddress.state" :label="dictionary.bankAccount.state" />
                <BeneficiaryDataLabelRow v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService" :value="bankAccountModel.contactInformation" :label="dictionary.bankAccount.contactInfo" />
              </div>
            </dl>
          </FinancialAddAccountStepAccordion>
          <FinancialAddAccountStepCustodian v-if="!toggles.hideCustodianFieldsForSecurityAccounts && isSecurityAccount && isExternalAccountTypeSecurityService && bankCountryData"
                                            :toggles="toggles"
                                            :dictionary="dictionary.custodian" 
                                            :validation-dictionary="validationDictionary" 
                                            :uses-domestic-instructions-only="usesDomesticInstructionsOnly"
                                            :bank-name="bankAccountModel.bankName"
                                            :external-identifier="bankCountryData.externalIdentifier"
                                            :custodian-data="custodianData" 
                                            :central-securities-depository-types="centralSecuritiesDepositoryTypes" 
                                            :current-step="currentStep" ref="financialAddAccountStepCustodian"/>
        </template>
      <div class="timeline-step-details" v-else-if="currentStep == 3">
        <p v-if="isSecurityAccount && isExternalAccountTypeSecurityService">{{dictionary.custodian.bankAccountCustodianHeaderDescription}}</p>
        <div class="row" >
          <template>
            <div class="col-md-6 col-lg-4" v-if="(isMoneyAccount && externalAccountBoundaries.requiresIBAN)">
              <input-element name="bank-iban" :disabled="notToEdit && !externalAccountBoundaries.canEditIBAN" v-model.trim="bankAccountModel.iban" 
                            :label="ibanLabel" :validationsList="bankAccountValidations.iban" 
                            :is-mandatory="isMoneyAccount && externalAccountBoundaries.requiresIBAN" />
            </div>
            <div class="col-md-6 col-lg-4" v-else-if="(isSecurityAccount && isExternalAccountTypeSecurityShareCertificate)
                                                  ||(isSecurityAndEATypeDRS)">
              <input-element name="bank-iban" v-model="bankAccountModel.iban" 
                            :label="getLabelForIBanInput"/>
            </div>
            <div class="col-md-6 col-lg-4" v-else-if="isMoneyAccount || (isSecurityAccount && shouldShowAccountNumberInput)">
              <input-element  name="account-number" :disabled="notToEdit && !externalAccountBoundaries.mayEditAccountNumber && !externalAccountBoundaries.canEditAccountNumber && !externalAccountBoundaries.mayEditAccountNumber"
                      v-model.trim="bankAccountModel.accountNumber" :label="dictionary.bankAccount.accountNumber" 
                      :validationsList="bankAccountValidations.accountNumber" 
                      :is-mandatory="(isMoneyAccount && !externalAccountBoundaries.requiresIBAN) || (isSecurityAccount && !isExternalAccountTypeSecurityShareCertificate)" />         
            </div>          
          </template>

          <div class="col-md-6 col-lg-4" v-if="isMoneyOrSecurityAndEATypeSecurityService">
            <input-element  name="bank-name" :disabled="notToEdit && !externalAccountBoundaries.canEditBankName" v-model.trim="bankAccountModel.bankName" 
                      :label="dictionary.bankAccount.bankName"  :validationsList="bankAccountValidations.bankName" :is-mandatory="isMoneyAccount || isBankNameRequiredSecurity" />         
          </div>

          <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
            <input-element name="contact-info" v-model="bankAccountModel.contactInformation" :label="dictionary.bankAccount.contactInfo" />         
          </div>

          <template>
            <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
              <input-element name="bank-bic" :disabled="notToEdit && !externalAccountBoundaries.canEditBIC" v-model.trim="bankAccountModel.bic"  
                              :label="dictionary.bankAccount.bankIdentificationCode" 
                    :validationsList="bankAccountValidations.bic" :is-mandatory="isMoneyAccount && $v.bankAccountModel.bic.$params.validBIC.mandatory" />
            </div>
            <div class="col-md-6 col-lg-4" v-else-if="showBankFieldsForSecurityAccounts &&  isSecurityAndEATypeDRS">
              <input-element name="bank-bic" v-model="bankAccountModel.bic"
                              :disabled="editingExistingAccount"
                              :label="dictionary.bankAccount.socialSecurityNumber"/>
            </div>
          </template>

          <template>
            <div class="col-md-6 col-lg-4"  v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService && (externalAccountBoundaries.requiresRoutingCode || externalAccountBoundaries.requiresBICOrRoutingCode)">
              <input-element name="bank-sort-code" :disabled="notToEdit && !externalAccountBoundaries.canEditRoutingCode" v-model="bankAccountModel.sortCode" :is-mandatory="$v.bankAccountModel.sortCode.$params.validRoutingCode.mandatory"
                      :label="routingLabel" :validationsList="bankAccountValidations.sortCode" :toolTipText="dictionary.bankAccount.routingDescription" />     
            </div>
          </template>

          <template>
            <div class="col-md-6 col-lg-4" v-if="isMoneyAccount && externalAccountBoundaries.requiresIntermediaryBIC">
              <input-element name="bank-intermediary-bic" :disabled="notToEdit && !externalAccountBoundaries.canEditIntermediaryBIC" v-model="bankAccountModel.intermediaryBIC"
                    :label="dictionary.bankAccount.intermediaryBIC" :validationsList="bankAccountValidations.intermediaryBIC" />    
            </div>       
          </template>

        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
            <input-element name="bank-addressLine1" v-model.trim="bankAccountModel.accountAddress.addressProperty" 
                  :label="dictionary.bankAccount.addressLine1" :validationsList="bankAccountValidations.addressProperty" :is-mandatory="$v.bankAccountModel.accountAddress.addressProperty.$params.validAddressProperty.mandatory" />
          </div>

          <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
            <input-element name="bank-postalCode" v-model="bankAccountModel.accountAddress.postalCode"  :label="dictionary.bankAccount.postalCode" />         
          </div>

          <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
            <input-element name="bank-city" v-model.trim="bankAccountModel.accountAddress.city" 
                :label="dictionary.bankAccount.city"  :validationsList="bankAccountValidations.city" :is-mandatory="$v.bankAccountModel.accountAddress.city.$params.validCity.mandatory"  />
          </div>

          <div class="col-md-6 col-lg-4" v-if="showBankFieldsForSecurityAccounts && isMoneyOrSecurityAndEATypeSecurityService">
            <input-element name="bank-state" v-model="bankAccountModel.accountAddress.state"  :label="dictionary.bankAccount.state" />
          </div>
        </div>

        <FinancialAddAccountMandatoryTooltip :tooltipText="dictionary.mandatory" />

        <FinancialAddAccountStepCustodian v-if="!toggles.hideCustodianFieldsForSecurityAccounts && isSecurityAccount && isExternalAccountTypeSecurityService && bankCountryData" 
                                                :dictionary="dictionary.custodian" 
                                                :validation-dictionary="validationDictionary" 
                                                :uses-domestic-instructions-only="usesDomesticInstructionsOnly"
                                                :bank-name="bankAccountModel.bankName"
                                                :external-identifier="bankCountryData.externalIdentifier"
                                                :custodian-data="custodianData" 
                                                :central-securities-depository-types="centralSecuritiesDepositoryTypes" 
                                                :toggles="toggles"
                                                :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                                :current-step="currentStep" ref="financialAddAccountStepCustodian"/>
      </div>
      <div class="timeline-step-details mt-0">
        <div v-if="currentStep >= 3" class="d-md-flex justify-content-md-between">
          
            <FinancialCancelAddAccount :dictionary="dictionary" @cancel-add-account="cancelAddAccount()"/>
                  
            <div>
              <button
                  class="btn secondaryButton"
                  type="button"
                  @click="previousStep()"
                  :aria-describedBy="previousStepAccessibilityLabel(currentStep)">
                    {{dictionary.btnPrevious}}
              </button>
              <button
                  v-if="isEdit && currentStep != 4"
                  class="btn primaryButton"
                  type="button"
                  @click="nextStep()"
                  :aria-describedBy="nextStepAccessibilityLabel(currentStep, maxNumberOfSteps)">
                {{ dictionary.btnNext }}
              </button>
              <button
                  v-if="!isEdit || currentStep == 4"
                  class="btn primaryButton"
                  type="button" 
                  @click="submitData()">
                {{ dictionary.btnSubmit }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { bankAccountModelValidations } from "./financial-add-account-form-validation.mixin";
import FormErrorAlert from "Shared/Components/Vue/Alert/form-error-alert.component.vue";
import FinancialAddAccountMandatoryTooltip from "./financial-add-account-mandatory-tool-tip.component.vue";
import FinancialCancelAddAccount from "./financial-cancel-add-account.component.vue";
import FinancialAddAccountStepAccordion from "./finanacial-add-account-step-accordion.component.vue";
import BeneficiaryDataLabelRow from "./../data-label-row.component.vue";
import FinancialAddAccountStepCustodian from "./financial-add-account-step-custodian.component.vue";
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  mixins: [bankAccountModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  components: {
    FormErrorAlert,
    FinancialAddAccountMandatoryTooltip,
    FinancialCancelAddAccount,
    FinancialAddAccountStepAccordion,
    BeneficiaryDataLabelRow,
    FinancialAddAccountStepCustodian,
  },
  props: {
    dictionary: Object,
    validationDictionary: Object,
    accountType: String,
    countries: Array,
    complianceExternalAccount: Object,
    externalAccountBoundaries: Object,
    bankAccountData: Object,
    bankCountryData: Object,
    centralSecuritiesDepositoryTypes: Array,
    currentStep: Number,
    maxNumberOfSteps: Number,
    errors: Array,
    editingExistingAccount:{
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    toggles:Object,
    securityAccountDisplaySettings:Object,
    showStep3: Boolean,
    action: String
  },
  data() {
    return {
         securityService: "beatSecurity",
         securityMaintained: "beatSecurityMaintained",
         securityCustodian: "beatSecurityCustodian",
         securityDRS:"beatSecurityDRS",
         securityShareCertificate:"beatSecurityCertificate",
    };
  },
  methods: {
    nextStep() {
      let isCustodianInValid = false;
      if (!this.toggles.hideCustodianFieldsForSecurityAccounts)
        isCustodianInValid = this.validateCustodianComponent();
        
      this.$v.$touch();
      if (this.$v.$invalid || isCustodianInValid) {
        return this.focusOnFirstInvalidInput();
      }
      this.$emit("next-step", { step: this.currentStep, data: this.bankAccountModel } );
    },
    previousStep() {
        this.$emit("previous-step", { step: this.currentStep, data: this.bankAccountModel } );
    },
    cancelAddAccount() {
      this.$emit("cancel-add-account");
    },
    submitData() {
      let isCustodianInValid = false;
      if (!this.toggles.hideCustodianFieldsForSecurityAccounts)
        isCustodianInValid = this.validateCustodianComponent();

      this.$v.$touch();
      if (this.$v.$invalid || isCustodianInValid) {
        return;
      }
       this.$emit("set-compliance-external-account", { step: this.currentStep, data: this.bankAccountModel } );
    },
    validateCustodianComponent()
    {
      const custodianComponent = this.$refs.financialAddAccountStepCustodian;
      if(custodianComponent){
      custodianComponent.$v.$touch();
      return custodianComponent.$v.$invalid;
      }
      return false;
    }
  },
  mounted() {
      if(this.securityAccountDisplaySettings && this.securityAccountDisplaySettings.allowUKShareTransferSettingsOnly) {
        this.complianceExternalAccount.bankAccount.custodian.csdType = "csdtCREST";
      }
  },
  computed: {
    showBankFieldsForSecurityAccounts(){
      return !(this.toggles.hideBankFieldsForSecurityAccounts && this.isSecurityAccount);
    },
    custodianData(){
      return this.complianceExternalAccount.bankAccount.custodian;
    },
    isSecurityAccount() {
      return this.accountType === this.$BankAccountType.SECURITY;
    },
    isMoneyAccount(){
      return this.accountType === this.$BankAccountType.MONEY;
    },
      isExternalAccountTypeSecurityService() {
      return this.complianceExternalAccount.accountType.externalAccountType === this.securityService;
    },
    isExternalAccountTypeSecurityMaintained(){
      return this.complianceExternalAccount.accountType.externalAccountType === this.securityMaintained;
    },
    isExternalAccountTypeSecurityCustodian(){
      return this.complianceExternalAccount.accountType.externalAccountType === this.securityCustodian;
    },
    isExternalAccountTypeSecurityDRS(){
      return this.complianceExternalAccount.accountType.externalAccountType === this.securityDRS;
    },
    isExternalAccountTypeSecurityShareCertificate(){
      return this.complianceExternalAccount.accountType.externalAccountType === this.securityShareCertificate;
    },
    shouldShowAccountNumberInput(){
      return this.isExternalAccountTypeSecurityService 
              || this.isExternalAccountTypeSecurityShareCertificate 
              || this.isExternalAccountTypeSecurityCustodian
              || this.isExternalAccountTypeSecurityMaintained;
    },
    usesDomesticInstructionsOnly(){
      return  this.externalAccountBoundaries.usesDomesticInstructionsOnly;
    },
    isMoneyOrSecurityAndEATypeSecurityService(){
      return this.isMoneyAccount || (this.isSecurityAccount && this.isExternalAccountTypeSecurityService);
    },
    isSecurityAndEATypeDRS(){
      return (this.isSecurityAccount && this.isExternalAccountTypeSecurityDRS);
    },
    isBankCountyrUK(){
        return this.bankCountryData.externalIdentifier === "GB";
    },
    isBankCountyrUS(){
        return this.bankCountryData.externalIdentifier === "US";
    },
    isBankNameRequiredSecurity(){
      if(this.isSecurityAccount && this.usesDomesticInstructionsOnly)
      {
        return false;
      }
        return this.isCustodianNameEmpty;
    },
    getLabelForIBanInput(){
      if(this.isSecurityAccount && this.isExternalAccountTypeSecurityShareCertificate){
        return this.dictionary.bankAccount.ivcNumber
      }
      else if(this.isSecurityAndEATypeDRS){
        return this.dictionary.bankAccount.taxIdNumber
      }
    },
    centralSecuritiesDepositoryTypesList(){
      return this.centralSecuritiesDepositoryTypes;
    },
    bankAccountModel() {
      return this.bankAccountData;
    },
    notToEdit(){
      return this.isEdit 
            && (!this.externalAccountBoundaries.mayEditBankAccount || !this.externalAccountBoundaries.mayEditBank)
    },
    bankAccountValidations() {
        return { 
          bankName : [
            {
                id: 1,
                error: this.$v.bankAccountModel.bankName.$error,
                type: this.$v.bankAccountModel.bankName.required,
                message: this.validationDictionary.required.bankName
            }                
          ],
          iban : [
            {
                id: 2,
                error: this.$v.bankAccountModel.iban.$error,
                type: this.$v.bankAccountModel.iban.validIBAN,
                message: this.validationDictionary.invalid.iban
            },
            {
                id: 1,
                error: this.$v.bankAccountModel.iban.$error,
                type: this.$v.bankAccountModel.iban.required,
                message: this.validationDictionary.required.iban
            }                
          ],
          accountNumber : [
            {
                id: 1,
                error: this.$v.bankAccountModel.accountNumber.$error,
                type: this.$v.bankAccountModel.accountNumber.required,
                message: this.validationDictionary.required.accountNumber
            }                
          ],
          bic : [
            {
                id: 1,
                error: this.$v.bankAccountModel.bic.$error,
                type: this.$v.bankAccountModel.bic.validBIC,
                message: this.validationDictionary.invalid.bic
            }, 
            {
                id: 2,
                error: this.$v.bankAccountModel.bic.$error,
                type: this.$v.bankAccountModel.bic.required,
                message: this.validationDictionary.required.bic
            },
            {
                id: 3,
                error: this.$v.bankAccountModel.bic.$error,
                type: this.$v.bankAccountModel.bic.maxBicLength,
                message: this.validationDictionary.invalid.bic 
            }                       
          ],
          intermediaryBIC : [
            {
                id: 1,
                error: this.$v.bankAccountModel.intermediaryBIC.$error,
                type: this.$v.bankAccountModel.intermediaryBIC.validBIC,
                message: this.validationDictionary.invalid.intermediaryBIC
            }            
          ],
          sortCode : [
            {
                id: 2,
                error: this.$v.bankAccountModel.sortCode.$error,
                type: this.$v.bankAccountModel.sortCode.validRoutingCode,
                message: this.validationDictionary.invalid.sortCode
            },
            {
                id: 1,
                error: this.$v.bankAccountModel.sortCode.$error,
                type: this.$v.bankAccountModel.sortCode.required,
                message: this.validationDictionary.required.sortCode,
            }                
          ],
          addressProperty: [
              {
                id: 1,
                error: this.$v.bankAccountModel.accountAddress.addressProperty.$error,
                type: this.$v.bankAccountModel.accountAddress.addressProperty.required,
                message: this.validationDictionary.required.addressLine1
            } 
          ],          
          city: [
              {
                id: 1,
                error: this.$v.bankAccountModel.accountAddress.city.$error,
                type: this.$v.bankAccountModel.accountAddress.city.required,
                message: this.validationDictionary.required.city
            } 
          ]
        } 
    },
    ibanLabel(){
      return this.bankCountryData?.externalAccountArea === this.$ExternalAccountArea.SEPA ? `${this.dictionary.bankAccount.iban} (SEPA)` : this.dictionary.bankAccount.iban;
    },
    routingLabel() {  
      switch(this.bankCountryData.externalAccountRouting) {
        case this.$ExternalAccountRouting.OptionalBICandBranchSortCode:
            return this.dictionary.bankAccount.sortCode 
        case this.$ExternalAccountRouting.BICorABA:
            return this.dictionary.bankAccount.aba;
        case this.$ExternalAccountRouting.BICorBSB:
            return this.dictionary.bankAccount.bsb;
        case this.$ExternalAccountRouting.BICorBranchCode:
            return this.dictionary.bankAccount.branchCode;
        case this.$ExternalAccountRouting.BICorTransitNumber:
            return this.dictionary.bankAccount.transitNo;
        default:
            return this.dictionary.bankAccount.sortCode 
      }      
    },
    isCustodianNameEmpty() {
        return !(!!this.bankAccountData.custodian.name);
    },
  }
};
</script>
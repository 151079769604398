var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("div", { staticClass: "cookie-policy-consent" }, [
        _c("div", { staticClass: "cookie-policy-consent__content" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", { staticClass: "cookie-policy-consent__title" }, [
                _vm._v(_vm._s(_vm.dictionary.modal.policyModalTitle))
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "rich-text-container",
                domProps: {
                  innerHTML: _vm._s(_vm.$sanitize(_vm.dictionary.modal.consent))
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row cookie-policy__buttons" }, [
          _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary btn-primary-white-background w-100",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cookie-manage-cookies")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.modal.btnManageCookies))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary btn-primary-white-background w-100",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cookie-allow-all")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.modal.btnAllowAll))]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
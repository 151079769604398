var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-component" },
    [
      _c("h2", { staticClass: "company-title" }, [
        _vm._v(" " + _vm._s(_vm.accountDictionary.widgets.employment))
      ]),
      _vm._v(" "),
      _c("EmploymentDetail", {
        attrs: {
          dictionary: _vm.accountDictionary.employment,
          "current-employment": _vm.currentEmployment
        }
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "employment-history-title" }, [
        _vm._v(" " + _vm._s(_vm.accountDictionary.widgets.employmentHistory))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "employment-history-subtitle-container d-flex" },
        [
          _c("p", { staticClass: "employment-history-subtitle d-flex" }, [
            _vm._v(
              " " +
                _vm._s(_vm.accountDictionary.widgets.employmentHistorySubtitle)
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("EmploymentHistory", {
        attrs: {
          dictionary: _vm.accountDictionary.employment,
          "employment-history": _vm.employmentHistory
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
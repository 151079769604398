<template>
    <div v-if="isVisible && securities" :class="['security-price-dropdown', position]">
        <template v-if="displaySecurities.length">
            <v-menu v-model="isActive" rounded="0" offset-y>
                <template v-slot:activator="{ attrs, on }">
                    <div class="security-price-header" v-bind="attrs" v-on="on">
                        <i class="far fa-chart-area" aria-hidden="true"></i>
                        <div class="security-price-header-description">
                            <div class="security-price-header-description__label">{{ getLastUpdatePrice.name }}</div>
                            <div class="security-price-header-description__value">{{ getLastUpdatePrice.lastSecurityPriceFormatted }}</div>
                            <div class="security-price-header-description__lastupdate">
                                <span>{{ dictionary.securityPriceUpdated }}</span>
                                <span>{{ getLastUpdatePrice.lastSecurityPriceDate | toDateTimeWithoutSeconds() }}</span>
                            </div>
                        </div>
                        <i v-if="showDropdown" :class="['far', 'fa-chevron-down', 'v-icon', {'isActive': isActive}]" aria-hidden="true"></i>
                    </div>
                </template>
                <v-list v-if="showDropdown" :class="['security-price-content', position]">
                    <div v-for="(secuirity, index) in displaySecurities.slice(1)" :key="`${index}-${secuirity.name}`" class="security-price-content__item">
                        <span class="security-price-content__label">{{ secuirity.name }}</span>
                        <span class="security-price-content__value">{{ secuirity.lastSecurityPriceFormatted }}</span>
                    </div>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>

<style>
    @import '../security-price-dropdown.scss';
</style>

<script>
    export default {
        data() {
            return {
                isDesktop: false,
                isActive: false
            }
        },
        props: {
            dictionary: Object,
            securities: Array,
            isLoading: {
                default: false,
                type: Boolean
            },
            position: String,
            isHidden: {
                default: false,
                type: Boolean
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.isDesktop = window.innerWidth >= this.$windowSizes.DESKTOP;
            }
        },
        computed: {
            displaySecurities() {
                return this.securities.filter(x => x.isDisplaySecurityPrice);
            },
            getLastUpdatePrice() {
                return this.displaySecurities[0];
            },
            showDropdown() {
                return this.displaySecurities.length > 1;
            },
            isVisible() {
                if (((this.position == 'top' && this.isDesktop) ||
                    (this.position == 'side' && !this.isDesktop)) && !this.isHidden) {
                    return true;
                }

                return false
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toggle" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "button",
          {
            class: [
              "toggle-button",
              "btn-sm",
              "btn-toggle",
              _vm.toggleValue ? "active" : ""
            ],
            attrs: {
              type: "button",
              name: "mfa-switch",
              "data-toggle": "button",
              disabled: _vm.isDisabled,
              "aria-pressed": _vm.toggleValue
            },
            on: { click: _vm.switchToggle }
          },
          [_c("span", { staticClass: "handle" })]
        ),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "form-input-label", attrs: { for: "mfa-switch" } },
          [_vm._v(_vm._s(_vm.label))]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.errors, function(error, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("alert-component", { attrs: { variant: "info" } }, [
              _vm._v(_vm._s(error))
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
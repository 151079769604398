var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accountDictionary
    ? _c("portlet-layout", {
        attrs: {
          title: _vm.accountDictionary.widgets.contact,
          hideShadow: true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._v(
                    "\r\n        " +
                      _vm._s(_vm.accountDictionary.widgets.contact) +
                      " "
                  ),
                  _vm.beneficiaryBoundariesDataModel.mayEditCurrentAddress
                    ? [
                        _c("i", {
                          staticClass: "far fa-edit beneficiary-edit-button",
                          attrs: { "aria-hidden": "true" },
                          on: { click: _vm.editAddress }
                        })
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "body",
              fn: function() {
                return [
                  !_vm.isAddressEdited
                    ? _c(
                        "div",
                        [
                          _c("AddressView", {
                            attrs: {
                              addressDataModel: _vm.addressDataModel,
                              dictionary: _vm.accountDictionary.address,
                              isAddressEdited: _vm.isAddressEdited
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("BeneficiaryAddressEditForm", {
                            attrs: {
                              addressDataEditModel: _vm.addressDataEditModel,
                              accountDictionary: _vm.accountDictionary,
                              commonDictionary: _vm.commonDictionary,
                              isAddressEdited: _vm.isAddressEdited
                            },
                            on: {
                              "address-edit-form-cancelled":
                                _vm.addressEditFormCancelled,
                              "reload-page": _vm.reloadPage
                            }
                          })
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          478634300
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
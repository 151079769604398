var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "mb-3" }, [
    _c("strong", [
      _vm._v(
        "\n        " +
          _vm._s(_vm.dictionary.confirmation.quoteCountDownText) +
          "\n        "
      ),
      _c("span", { staticClass: "text-countdown", attrs: { role: "timer" } }, [
        _vm._v(_vm._s(_vm.timerValue))
      ]),
      _vm._v(
        "\n        " +
          _vm._s(_vm.dictionary.confirmation.quoteCountDownSeconds) +
          "\n    "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
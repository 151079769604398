<template>
  <section >    
    <component-spinner :spin="loadingAccountData"></component-spinner>
    <template v-if="!loadingAccountData">
        <div class="row"> 
            <div class="col-md-6 col-lg-4">
                <div class="portlet-next-row">
                <BeneficiaryAccountPortlet :account-dictionary="accountDictionary" :common-dictionary="commonDictionary"
                        :account-data-model="accountDataModel" :is-account-edited="isAccountEdited" 
                        :account-data-edit-model="accountDataEditModel"
                        v-on:edit-account="editAccount"
                        v-on:account-edit-form-cancelled="accountEditFormCancelled" 
                        v-on:reload-page="reloadPage">
                </BeneficiaryAccountPortlet>
                </div>
                <div class="portlet-next-row">
                <BeneficiaryMfaPortlet :account-dictionary="accountDictionary" 
                :account-data-model="accountDataModel"
                :mfa-boundaries-data-model="mfaBoundariesDataModel"
                v-on:reload-page="reloadPage"
                @edit-mfa="(value) => $emit('edit-mfa', value)" /> 
                </div>

                <div class="portlet-next-row">
                  <BeneficiaryAddressPortlet :account-dictionary="accountDictionary" :common-dictionary="commonDictionary"
                        :address-data-model="addressDataModel" :is-address-edited="isAddressEdited" :address-data-edit-model="addressDataEditModel" 
                        :beneficiary-boundaries-data-model="beneficiaryBoundariesDataModel"
                        v-on:edit-address="editAddress"
                        v-on:address-edit-form-cancelled="addressEditFormCancelled" 
                        v-on:reload-page="reloadPage">
                  </BeneficiaryAddressPortlet> 
                </div>
            </div>
            <div v-if="isBeneficiaryCompanyNull || !isAllViewPropertiesOfPersonalInfoFalse" class="col-md-6 col-lg-4">
                <div class="portlet-next-row">
                  <BeneficiaryCompanyPortlet :account-dictionary="accountDictionary" 
                        :account-data-model="accountDataModel" :beneficiary-boundaries="beneficiaryBoundaries">
                  </BeneficiaryCompanyPortlet>
                </div>
                <div class="portlet-next-row">
                  <BeneficiaryPersonalPortlet v-if="!isAllViewPropertiesOfPersonalInfoFalse" :account-dictionary="accountDictionary" 
                      :account-data-model="accountDataModel"
                      :beneficiary-boundaries-personal-info="beneficiaryBoundariesPersonalInfo">
                  </BeneficiaryPersonalPortlet> 
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="portlet-next-row">
                  <BeneficiarySpousalPortlet v-if="!toggles.hideSpousalBlock && !!accountDataModel.spouseData && !isAllViewPropertiesOfSpouseInfoFalse" :account-dictionary="accountDictionary" 
                      :account-data-model="accountDataModel" :beneficiary-boundaries-spouse-info="beneficiaryBoundariesSpouseInfo">
                  </BeneficiarySpousalPortlet>
                </div>
                <div v-if="!!accountDataModel.legalNationality" class="portlet-next-row">
                  <BeneficiaryNationalityPortlet :account-dictionary="accountDictionary" 
                      :account-data-model="accountDataModel">
                  </BeneficiaryNationalityPortlet>
                </div>

                <div v-if="!!accountDataModel.fiscalData" class="portlet-next-row">
                  <BeneficiaryFiscalPortlet :account-dictionary="accountDictionary" 
                      :account-data-model="accountDataModel">
                  </BeneficiaryFiscalPortlet>
                </div>
            </div>
        </div>
    </template>
  </section>
</template>

<script>

import BeneficiaryAddressPortlet from "./beneficiary-address-portlet.component.vue";
import BeneficiaryAccountPortlet from "./beneficiary-account-portlet.component.vue";
import BeneficiaryCompanyPortlet from "./beneficiary-company-portlet.component.vue";
import BeneficiaryPersonalPortlet from "./beneficiary-personal-portlet.component.vue";
import BeneficiaryFiscalPortlet from "./beneficiary-fiscal-portlet.component.vue";
import BeneficiarySpousalPortlet from "./beneficiary-spousal-portlet.component.vue";
import BeneficiaryNationalityPortlet from "./beneficiary-nationality-portlet.component.vue";
import BeneficiaryMfaPortlet from "./beneficiary-mfa-portlet.component.vue";

export default {
  components: {
        BeneficiaryAccountPortlet,
        BeneficiaryAddressPortlet,
        BeneficiaryPersonalPortlet,
        BeneficiaryFiscalPortlet,
        BeneficiarySpousalPortlet,
        BeneficiaryNationalityPortlet,
        BeneficiaryCompanyPortlet,
        BeneficiaryMfaPortlet
    },
  props: {
    accountDictionary: Object,
    commonDictionary: Object,
    accountDataModel: Object,
    accountDataEditModel: Object,
    isAccountEdited: Boolean,
    addressDataModel: Object,
    addressDataEditModel: Object,
    beneficiaryBoundariesDataModel: Object,
    mfaBoundariesDataModel:Object,
    isAddressEdited: Boolean,
    toggles: Object,
    loadingAccountData: Boolean
  },
  methods: {
    editAccount: function () {
      this.$emit("edit-account");
    },
    accountEditFormCancelled() {
      this.$emit("account-edit-form-cancelled");
    },
    editAddress: function () {
      this.$emit("edit-address")
    },
    addressEditFormCancelled() {
      this.$emit("address-edit-form-cancelled");
    },
    reloadPage() {
      this.$emit("reload-page");
    },
  },
  computed:{
    beneficiaryBoundaries(){
      return this.beneficiaryBoundariesDataModel;
    },
    beneficiaryBoundariesPersonalInfo(){
      return this.beneficiaryBoundariesDataModel.beneficiaryBoundariesPersonalInfo;
    },
    beneficiaryBoundariesSpouseInfo(){
      return this.beneficiaryBoundariesDataModel.beneficiaryBoundariesSpouseInfo;
    },
    isAllViewPropertiesOfPersonalInfoFalse(){
        return Object.values(this.beneficiaryBoundariesDataModel.beneficiaryBoundariesPersonalInfo).every(item => item === false);
    },
    isAllViewPropertiesOfSpouseInfoFalse(){
      return Object.values(this.beneficiaryBoundariesDataModel.beneficiaryBoundariesSpouseInfo).every(item => item === false);
    },
    isBeneficiaryCompanyNull()
    {
      if(this.accountDataModel.beneficiaryCompany){
        return true;
      }
      return false;
    }
  }
};
</script>
<template>
    <div  v-if="header || subHeader || loginButton" class="card-header-title w-100 flex flex-row">
        <div class="flex flex-1">
            <h1 v-if="header" class="card-title">{{header}}</h1>
            <p v-if="subHeader" class="card-subtitle">{{subHeader}}</p>
        </div>
        <div v-if="!isAuthenticated && loginButton" class="align-self-center pb-2">
            <a :href="$sanitize(layout.loginEndpoint)" type="submit" class="btn btn-primary btn-xl" :title="layout.dictionary.login">
                <span class="nav-icon"><i class="fas fa-sign-in-alt" aria-hidden="true"></i></span>
                <span>{{layout.dictionary.login}}</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            layout: Object,
            isAuthenticated: Boolean,
            header: String,
            subHeader: String,
            loginButton: {
                type: Boolean,
                default: true
            }
        }
    };
</script>

import RequiredDocumentsContainer from "./required-documents-container.component.vue";
import RequiredDocuments from "./required-documents.component.vue";
import RequiredDocumentsDetails from "./required-documents-details.component.vue";

export const requiredDocumentsRoutes = {
    path: '/requiredDocuments',
    component: RequiredDocumentsContainer,
    children: [
        {
            path: '',
            name: 'required-documents',
            component: RequiredDocuments,
            meta: { bodyModal: true },
        },
        {
            path: 'details',
            name: 'required-documents-details',
            component: RequiredDocumentsDetails
        },
        {
            path: '*',
            redirect: '/requiredDocuments'
        }
    ],
};
<template>
    <div id="panelRelationsOfBeneficiary">
        <h2>{{ widgetTitle }}</h2>
        <v-data-table class="table-striped" 
                      :no-data-text="dictionary.noRecordFound"
                      :hide-default-header="isMobile"
                      :headers="computedHeaders"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer
                    >

            <!-- Search input at top --->
            <template v-slot:top>
                <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

            <!-- Datatable template for mobile view of data rows  --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <RelationsOfBeneficiaryMobileContent :dictionary="dictionary" :relationship="item"  />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import RelationsOfBeneficiaryMobileContent from "./relations-of-beneficiary-mobile-content.component.vue"

    export default {
        mixins: [vueDataTableMixin],
        components: {
            RelationsOfBeneficiaryMobileContent
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            widgetTitle: String,
            toggles:Object
        },
        data() {
            return {
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                getUrl: '/Beneficiaries/RelationsOfBeneficiary',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["relationshipType"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'relationshipType', text: this.dictionary.relationshipType, align: 'left', sortable: false, visible: true},
                    { value: 'relatedBeneficiaryIdentifier', text: this.dictionary.relationIdentifier, align: 'left', sortable: true, visible: true },
                    { value: 'relatedBeneficiaryBirtDate', text: this.dictionary.relationBirthDate, align: 'center', sortable: true , visible: true},
                    { value: 'relatedBeneficiaryName', text: this.dictionary.relationName, align: 'left', sortable: true, visible: true },
                    { value: 'beginDate', text: this.dictionary.relationBeginDate, align: 'right', sortable: true , visible: true},
                    { value: 'endDate', text: this.dictionary.relationEndDate, align: 'right', sortable: true , visible: true},
                    { value: 'remarks', text: this.dictionary.relationRemarks, align: 'left', sortable: true , visible: !this.toggles.hideRelationshipRemarksColumn},
                ],
                rows: [],
                totalRecords: 0
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
                    && window.innerWidth > this.$windowSizes.MOBILE;
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            }
        },
        computed: {
            computedHeaders() { 
                return this.headers.filter(h => h.visible);           
            },
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "timeline-step-header" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "accordion-" + _vm.thisStep,
                expression: "'accordion-' + thisStep"
              }
            ]
          },
          [
            !_vm.isCustodian
              ? _c("span", { staticClass: "timeline-accordion-header" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dictionary.step) +
                      " " +
                      _vm._s(_vm.thisStep) +
                      ": " +
                      _vm._s(_vm.heading) +
                      "\n      "
                  )
                ])
              : _c("span", { staticClass: "timeline-accordion-header" }, [
                  _vm._v("\n        " + _vm._s(_vm.heading) + "\n      ")
                ]),
            _vm._v(" "),
            !_vm.isSummary
              ? _c("span", { staticClass: "timeline-accordion-icon" }, [
                  _c("i", {
                    class: [
                      "far",
                      _vm.isVisible ? "fa-chevron-up" : "fa-chevron-down"
                    ],
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: "accordion-" + _vm.thisStep, role: "tabpanel" },
          model: {
            value: _vm.isVisible,
            callback: function($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "timeline-step-details" },
            [
              _vm._t("default"),
              _vm._v(" "),
              !_vm.isSummary && !_vm.isCustodian
                ? _c(
                    "button",
                    {
                      staticClass: "primaryButton btn edit-step-button",
                      on: {
                        click: function($event) {
                          return _vm.editStep()
                        }
                      }
                    },
                    [
                      _vm._v(_vm._s(_vm.dictionary.btnEdit) + " "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.thisStep) + " " + _vm._s(_vm.heading))
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
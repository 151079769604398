var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible && _vm.securities
    ? _c(
        "div",
        { class: ["security-price-dropdown", _vm.position] },
        [
          _vm.displaySecurities.length
            ? [
                _c(
                  "v-menu",
                  {
                    attrs: { rounded: "0", "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var attrs = ref.attrs
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    { staticClass: "security-price-header" },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("i", {
                                    staticClass: "far fa-chart-area",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "security-price-header-description"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "security-price-header-description__label"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getLastUpdatePrice.name)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "security-price-header-description__value"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getLastUpdatePrice
                                                .lastSecurityPriceFormatted
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "security-price-header-description__lastupdate"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dictionary
                                                  .securityPriceUpdated
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "toDateTimeWithoutSeconds"
                                                )(
                                                  _vm.getLastUpdatePrice
                                                    .lastSecurityPriceDate
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.showDropdown
                                    ? _c("i", {
                                        class: [
                                          "far",
                                          "fa-chevron-down",
                                          "v-icon",
                                          { isActive: _vm.isActive }
                                        ],
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      728007461
                    ),
                    model: {
                      value: _vm.isActive,
                      callback: function($$v) {
                        _vm.isActive = $$v
                      },
                      expression: "isActive"
                    }
                  },
                  [
                    _vm._v(" "),
                    _vm.showDropdown
                      ? _c(
                          "v-list",
                          { class: ["security-price-content", _vm.position] },
                          _vm._l(_vm.displaySecurities.slice(1), function(
                            secuirity,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index + "-" + secuirity.name,
                                staticClass: "security-price-content__item"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "security-price-content__label"
                                  },
                                  [_vm._v(_vm._s(secuirity.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "security-price-content__value"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        secuirity.lastSecurityPriceFormatted
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
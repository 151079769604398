<template>
    <modal-basic v-if="dictionary" :title="dictionary.removeAccountModal.header" :is-on="isModalVisible" :isLoading="submitingData" :ok-footer="false" @close="close()">
      <div  v-if="bankAccount"  id="remove-account-container" class="default-account-container" >
        <BeneficiaryFinancialRemoveAccountBody :dictionary="dictionary" :account-data="bankAccount" :errors="errors"/>
        <BeneficiaryFinancialRemoveAccountButtons :dictionary="dictionary" @cancel-submit="cancelSubmit()" 
            @submit-remove-account="submitRemoveAccount()"  />    
      </div> 
    </modal-basic>
</template>

<script>
import { post, errorMessage } from "api";
import BeneficiaryDataLabel from "./../data-label.component.vue";
import BeneficiaryFinancialRemoveAccountButtons from "./financial-remove-account-buttons.component.vue";
import BeneficiaryFinancialRemoveAccountBody from "./financial-remove-account-body.component.vue";

export default {
  components: {
    BeneficiaryDataLabel,
    BeneficiaryFinancialRemoveAccountButtons,
    BeneficiaryFinancialRemoveAccountBody,
  },
  props: {
    financialDictionary: Object,
    bankAccount: Object,
    beneficiaryBoundariesData: Object,  
    accountType: String,
    dictionary: Object,
    isModalVisible: Boolean,
    financialToggles: Object
  },
  data() {
    return {
      submitingData: false,
      errors: [],
    };
  },
  methods: {

    submitRemoveAccount() {
      const context = this;
      context.submitingData = true;
      const requestUrl = `Beneficiaries/RemoveAccount?accountId=${context.bankAccount.id}`;
      post(
        requestUrl,
        {},
        (response) => {
          context.$emit("submit-completed", {
            accountType: context.accountType,
          });
        },
        context,
        () => {
          context.submitingData = false;
        },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            this.errors = err.response.data;
          } else {
            this.errors = [errorMessage];
          }
        }
      );
    },
    cancelSubmit(){
      this.close();
    },
    close() {
      this.$emit("close-modal");
    },
  },
  watch: {
    bankAccount: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
            this.$emit("open-modal");
            this.errors = [];
        }
      }
    } 
  }  
};
</script>
<template>
  <div id="process-transaction-types-container">

    <v-data-table id="dt-process-transaction-types"
                  class="table-striped"
                  group-by="planName"
                  :no-data-text="dictionary.noRecordFound"
                  :hide-default-header="isMobile"
                  :headers="headers"
                  :items="rows"
                  :loading="isLoading"
                  :server-items-length="totalRecords"
                  :search="search"
                  :items-per-page="options.itemsPerPage"
                  @page-count="options.pageCount = $event;"
                  :options.sync="options"
                  hide-default-footer                  
                  >

      <!-- Search input at top --->
      <template v-slot:top>
        <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
      </template>

      <template v-slot:[`header.action`]>
        <span id="auto-payment-settings-popover">
          {{dictionary.automaticPaymentSettings}} <i class="far fa-info-circle popover-info" aria-hidden="true"></i>
        </span>
      </template>

      <template v-slot:[`group.header`]="{ group, headers }">
        <th :colspan="headers.length" scope="colgroup">
          <span class="primary-color">{{ group }}</span>
        </th>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <TransferSettingsDetailActionButton :item="item" @load-items="loadItems()"></TransferSettingsDetailActionButton>
      </template>

      <template v-if="isMobile" v-slot:item="{ item }">
        <TransferSettingsDetailMobileContent :dictionary="dictionary" :item="item" @load-items="loadItems()"></TransferSettingsDetailMobileContent>
      </template>

      <template v-slot:footer>
        <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
      </template>
    </v-data-table>

    <b-popover v-if="!isMobile" target="auto-payment-settings-popover" placement="left" triggers="hover">
      <template slot:content>
        <div class="rich-text-container" v-html="$sanitize(dictionary.automaticPaymentSettingsPopover)"></div>
      </template>
    </b-popover>

  </div>
</template>

<script>
  import { vueDataTableMixin } from "Shared/Mixins/vue-data-table.mixin";
  import AlertComponent from "Shared/Components/Vue/Alert/alert.component.vue";
  import TransferSettingsDetailMobileContent from "./transfer-settings-detail-mobile-content.component.vue";
  import TransferSettingsDetailActionButton from "./transfer-settings-detail-action-button.component.vue";
  import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

  export default {
    mixins: [vueDataTableMixin, ScreenSizeMixin],
    components: {
      AlertComponent,
      TransferSettingsDetailMobileContent,
      TransferSettingsDetailActionButton
    },
    props: {
      datatablesDictionary: Object,
      dictionary: Object
    },
    data() {
      return {
        getUrl: "/Beneficiaries/ProcessTransactionTypes",
        options: {
          itemsPerPage: 10,
          page: 1,
          sortBy: ["planName"],
          sortDesc: [false],
          pageCount: 0,
          itemsPerPageOptions: [
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: this.datatablesDictionary.showAll, value: -1 }
          ]
        },
        rows: [],
        totalRecords: 0,
        headers: [
          { value: 'planName', text: this.dictionary.planName, align: 'left', sortable: true, groupable: true },
          { value: 'positionName', text: this.dictionary.positionName, align: 'left', sortable: true },
          { value: 'processDescription', text: this.dictionary.planProcess, align: 'left', sortable: true },
          { value: 'transactionDescription', text: this.dictionary.transactionType, align: 'left', sortable: true },
          { value: 'action', align: 'left', sortable: false }
        ],
        timer: null
      };
    },
    beforeDestroy() {
      this.timer = setInterval(this.loadItems, 60 * 1000);
    },
    destroyed() {
      clearInterval(this.timer);
    }
  };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-row" },
    [
      _c(
        "b-tabs",
        {
          ref: "tabs",
          staticClass: "tab-bar tab-container",
          attrs: { "content-class": "mt-0" }
        },
        [
          _c(
            "portlet-tab",
            {
              attrs: {
                tabTitle: _vm.dictionary.tabs.active,
                isActive: true,
                tabId: "tabActiveChoices"
              }
            },
            [
              _c("div", {
                staticClass: "page-description rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionary.page.choicesPageDescriptionActive
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("ChoiceTable", {
                attrs: {
                  tableId: "TableActiveChoices",
                  dictionary: _vm.dictionary,
                  noRecordFoundText: _vm.dictionary.table.noActiveChoices,
                  getUrl: "/choices/ActiveChoices",
                  datatablesDictionary: _vm.datatablesDictionary
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "portlet-tab",
            {
              attrs: {
                tabTitle: _vm.dictionary.tabs.historical,
                tabId: "tabHistoricalChoices"
              }
            },
            [
              _c("div", {
                staticClass: "page-description rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionary.page.choicesPageDescriptionHistorical
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("ChoiceTable", {
                attrs: {
                  tableId: "TableHistoricalChoices",
                  dictionary: _vm.dictionary,
                  noRecordFoundText: _vm.dictionary.table.noHistoricalChoices,
                  getUrl: "/choices/HistoricalChoices",
                  datatablesDictionary: _vm.datatablesDictionary
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
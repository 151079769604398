<template>
    <div class="position-action-link">
        <a class="position-action-links__link" @click="conditionalPath()">
            <span>{{position.name}}</span>
        </a>
    </div>
</template>

<script>
    import { actionMethods } from '/Features/Portfolio/Assets/Vue/Shared/mixins/position-action-methods.mixin';

    export default {
        mixins: [actionMethods],
        props: {
            position: Object
        }
    }
</script>
export const choiceLogic = {
    props: {
      choiceInstructionModel: Object,
      itemIndex: Number,
      dictionary: Object,
      scenario: Object,
      validation:  Object
    },
    methods: {
      onChange() {
        if (this.hasOneScenario && this.scenario.answerMode.value != 'csamSelect' && this.validation.choiceInstructionModel.$invalid){
          //Since there is validate data need to reset to old state before making any change.
          this.$emit('reset');
        }
        else {
          this.$emit('scenario-selected', this.scenario);
        }
      }
    },
    computed: {
      v(){
        return this.validation.choiceInstructionModel.scenarios.$each[this.itemIndex];
      },
      hasOneScenario() {
        return (
          this.choiceInstructionModel.scenarios.length  == 1 || 
          (this.choiceInstructionModel.maximumNrEnteredScenarios == 1 && 
            this.choiceInstructionModel.minimalNrEnteredScenarios == 1)
        );
      },
      hasPercentage() {
        return (
          this.choiceInstructionModel.valueMode.value == "cvmFreePercentage" ||
          this.choiceInstructionModel.valueMode.value == "cvmStrictPercentage"
        );
      },
      hideInput() {
        return this.scenario.answerMode.value == 'csamSelect';
      },
      valueValidations() {
        return [
          {
            id: 1,
            field: this.v.value,
            error: this.v.value.$error,
            type: this.v.value.mustBeDecimal,
            message: this.dictionary.validation.invalidCharacter,
          },
          {
            id: 2,
            field: this.v.value,
            error: this.v.value.$error,
            type: this.v.value.mustBeMinimumValue,
            message: this.$replaceStringWithPlaceholders( this.dictionary.validation.minimumValue, { MinimumValue: this.scenario.minimumValue } )
          },
          {
            id: 3,
            field: this.v.value,
            error: this.v.value.$error,
            type: this.v.value.mustBeMaximumValue,
            message: this.$replaceStringWithPlaceholders(this.dictionary.validation.maximumValue, { MaximumValue: this.scenario.maximumValue })
          },
        ];
      },
      inputType() {
        return this.hasOneScenario && 
        (this.scenario.answerMode.value == 'csamSelectWithValue' || 
        this.scenario.answerMode.value == 'csamValue') ? "radio" : "checkbox";
      },
      hideRadioOrCheckBox() {

        if (this.scenario.answerMode.value == 'csamValue')
          return true;

        return this.hasOneScenario && this.scenario.answerMode.value == 'csamSelectWithValue' && this.choiceInstructionModel.scenarios.length  == 1 ;
      },
      isInputDisabled() {
        
        if (this.scenario.answerMode.value == 'csamValue')
          return false;

        return !this.scenario.isSelected;
      }
    }
};
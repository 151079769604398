<template>
    <div class="portfolio-plan-header-values-container">
        <div class="d-flex justify-content-between">
            <div>
                <span class="font-weight-bold">{{title}}</span>
            </div>
            <div>
                <ul class="portfolio-plan-header-values">
                    <li v-for="(value, index) in values" :key="getKey(index)" >
                        <span class="portfolio-plan-header-value font-weight-bold">{{value.valueFormatted}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
    props: {
        title: String,
        values: {
            type : Array,
            default: []
        }
    },
    methods: {
        getKey(index) {
            return `value-key-${index}-${uuid()}`;
        },
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentQuestionnaire
    ? _c(
        "div",
        _vm._l(_vm.currentQuestionnaire.questions, function(
          currentQuestion,
          index
        ) {
          return _c(
            "div",
            { key: index, staticClass: "question-container" },
            [
              _c("QuestionDetail", {
                attrs: {
                  currentQuestion: currentQuestion,
                  questionIndex: index,
                  showCorrectAnswer: _vm.showCorrectAnswer,
                  dictionary: _vm.dictionary,
                  v: _vm.v
                },
                on: { "change-answer": _vm.handleChangeAnswer }
              })
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <modal-basic :title="header" :is-on="isModalVisible" :ok-footer="false" :staticMode="true" :lazy="false" :centered="true" :close-button="closeButton" @close="$emit('cancel')">
        <Mfa :initialCurrentFactorType="currentFactorType" :currentFactorType="currentFactorType" :message="message" :isVisible="isModalVisible" 
            :mfa-boundaries-data-model="mfaBoundariesDataModel"
            :mfa-enable-disable-data-model="mfaEnableDisableDataModel"
            :phone="phone" 
             v-on="$listeners" @disable-close-button="closeButton = false" :showFirstScreen="showFirstScreen" />
    </modal-basic>
</template>

<style>
    @import './mfa.scss';
</style>

<script>
    import Mfa from "./mfa.component.vue";

    export default {
        components: {
            Mfa
        },
        props: {
            header: String,
            message: String,
            isModalVisible: Boolean,
            phone: String,
            currentFactorType:String,
            showFirstScreen:Boolean,
            mfaBoundariesDataModel:Object,
            mfaEnableDisableDataModel:Object
        },
        watch: {
            isModalVisible: function (newVal, oldVal) {
                if (newVal) {
                    this.closeButton = true;
                }
            }
        },
        data() {
            return {
                closeButton: true
            }
        }
    };
</script>
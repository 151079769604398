<template>
<portlet-layout v-if="plan.positions.length" column-class="col-12" :title="plan.name" :is-custom-title="true" :is-custom-body="true">
    <template v-slot:customTitle>
        <PortfolioPlanValueTitle :toggles="toggles" :dictionary="dictionary" :portfolioPlan="plan" :isMobile="isMobile" />
    </template>
    
    <template v-slot:body>
        <PortfolioPlanTable :portfolioPlan="plan" :dictionary="dictionary" :toggles="toggles" :currency="currency" :isMobile="isMobile" />

        <MultiPositionExitButton v-if="plan.isMultiPositionExit" :buttonText="dictionary.block.btnMultiPositionExit" :isModalVisible="isModalVisible" 
            :positionName="plan.multiPositionExitPosition.name" :isLoading="isLoading" :isMobile="isMobile"
            :exitsDictionary="exitsDictionary" :exitOrderModel="exitInstructionOrderModel" @clicked="openExitInstructionOrderModal()" 
            @closed="closeModal()" />
    </template>
</portlet-layout>
</template>

<script>

import PortfolioPlanValueTitle from "./portfolio-plan-value-title.component.vue";
import MultiPositionExitButton from "./multi-position-exit-button.component.vue";
import ExitOrderForm from "../../../Exits/Assets/Vue/exit-order-form.component.vue";
import { get } from 'api';
import PortfolioPlanTable from "./portfolio-plan-table.component.vue";

export default {
  components: {
    PortfolioPlanValueTitle,
    MultiPositionExitButton,
    ExitOrderForm,
    PortfolioPlanTable
  },
  props: {
    plan: Object,
    toggles: Object,
    dictionary: Object,
    currency: String
 },
  data() {
    return {
      isModalVisible: false,
      exitInstructionOrderModel: {},
      exitsDictionary: {},
      isFormProcessing: false,
      isMobile: false
    };
  },
  computed:{
    isLoading(){
      return this.$isEmptyObject(this.exitInstructionOrderModel) || this.$isEmptyObject(this.exitsDictionary);
    }
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  methods: {
  handleResize() {
        this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
    },
    openExitInstructionOrderModal() {
        this.isModalVisible = true;

        const position = this.plan.multiPositionExitPosition;

        if(this.$isEmptyObject(this.exitsDictionary)){
          get("/Exits/Dictionary", response => { this.exitsDictionary = response.data }, this);
        }

        get(`/Exits/InstructionOrder?sharePlanParticipationId=${position.sharePlanParticipationId}&positionUsageId=${position.positionUsageId}`, response => {
            this.exitInstructionOrderModel = response.data.exitInstructionOrderViewModel;
        }, this);
    },
    closeModal(){
      this.isModalVisible = false;
    }
  },
};
</script>
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import { withParams } from "vuelidate/lib";
import { isValidIBAN, isValidBIC, isValidRoutingCode, nameValidator, addressValidator, postalCodeValidator, alphaNumericValidator } from "./account-validations";


const isBICRequired = (vm) => {
    if (vm.accountType === vm.$BankAccountType.MONEY){
        if (vm.externalAccountBoundaries.requiresBIC)
        {
            return  !(!!vm.bankAccountData.iban);
        }
        if (vm.externalAccountBoundaries.requiresBICOrRoutingCode)
        {
            return  !(!!vm.bankAccountData.iban) &&  !(!!vm.bankAccountData.bic) &&  !(!!vm.bankAccountData.sortCode);
        }
    }
    return false;
}

const isSortCodeRequired = (vm) => {
    if (vm.accountType === vm.$BankAccountType.MONEY){
        if (vm.externalAccountBoundaries.requiresRoutingCode)
        {
            return !(!!vm.bankAccountData.iban);
        }
        if (vm.externalAccountBoundaries.requiresBICOrRoutingCode)
        {
            return !(!!vm.bankAccountData.iban) &&  !(!!vm.bankAccountData.bic) &&  !(!!vm.bankAccountData.sortCode);
        }
    }

    return false;
}

const isBICMandatory = (vm) => {
    if (vm.accountType === vm.$BankAccountType.MONEY){
        if (vm.externalAccountBoundaries.requiresBIC)
        {
            return  !(!!vm.bankAccountData.iban);
        }
        if (vm.externalAccountBoundaries.requiresBICOrRoutingCode)
        {
            return  !(!!vm.bankAccountData.iban) && !(!!vm.bankAccountData.sortCode);
        }
    }
    return false;
}

const isSortCodeMandatory = (vm) => {
    if (vm.accountType === vm.$BankAccountType.MONEY){
        if (vm.externalAccountBoundaries.requiresRoutingCode)
        {
            return !(!!vm.bankAccountData.iban);
        }
        if (vm.externalAccountBoundaries.requiresBICOrRoutingCode)
        {
            return !(!!vm.bankAccountData.iban) &&  !(!!vm.bankAccountData.bic);
        }
    }

    return false;
}

const isAddressAndCityRequired = (vm) => {
   return  vm.accountType === vm.$BankAccountType.MONEY &&  !(!!vm.bankAccountData.iban)  &&  !(!!vm.bankAccountData.bic) && !vm.externalAccountBoundaries.requiresBIC;
}

const isBankNameRequired = (vm) => {
    return !(!!vm.bankName);
}

const isCustodianNameRequired = (vm) => {
    return (!!vm.custodianModel.name);
}

const countriesToValidate = [
{
    name:"Ireland",
    externalIdentifier:"IE"
},{
    name:"United Kingdom",
    externalIdentifier:"GB"
},{
    name:"Jersey",
    externalIdentifier:"JE"
},{
    name:"Isle of Man",
    externalIdentifier:"IM"
}];

const shouldBeCsdtCREST = (externalId, csdType) =>{
    if(countriesToValidate.filter(c=>c.externalIdentifier === externalId).length > 0){
        return csdType === "csdtCREST";
    }
    return true;
}
const shouldBeCsdtDTC = (externalId, csdType) =>{
    if(externalId === "US"){
        return csdType === "csdtDTC";
    }
    return true;
}

const isValidCSDType = (input,vm) =>{
    if(input)
    {
        const externalIdentifier = vm.externalIdentifier;
        if(!isCSDTypeEmpty(vm) && (!shouldBeCsdtCREST(externalIdentifier,input) || !shouldBeCsdtDTC(externalIdentifier,input))){
            return false;
        }
    }
    return true;
}

const isAccountHolderNameAndCityRequired = (vm) =>{
    return vm.isNameAndCityMandatory;
}

const isCustodianCityRequired = (vm) => {
    return vm.isCustodianCityRequired;
}

const isCSDTypeEmpty = (vm) =>{ return (vm.custodianModel.csdType === 'csdtNone' || vm.custodianModel.csdType === '' || vm.custodianModel.csdType === null)};

export const accountHolderModelValidations = {
    validations() {
        return {
            accountHolderModel: {
                holderName: { 
                    required: requiredIf(function () {
                        return isAccountHolderNameAndCityRequired(this)
                    }),
                    validName: function(value) {
                        return nameValidator(value);
                    }  
                },
                holderAddress: {
                    addressProperty: {
                        validAddress: function(value) {
                            return addressValidator(value);
                        }
                     },
                    postalCode: { 
                        maxLength: maxLength(20),
                        validPostalCode: function(value) {
                            return postalCodeValidator(value);
                        }                       
                    },
                    city: { 
                        required: requiredIf(function () {
                            return isAccountHolderNameAndCityRequired(this)
                        }),
                        validCity: function(value) {
                            return alphaNumericValidator(value);
                        } 
                    },
                    state : {
                        validState: function(value) {
                            return alphaNumericValidator(value);
                        }
                    },
                    countryId: { required },
                }
            }
        }
    }
}

export const custodianModelValidations ={
    validations(){
    return{
        custodianModel:{
            name:{
                required: requiredIf(function () {
                        return !this.usesDomesticInstructionsOnly && isBankNameRequired(this)
                    })
                },
            csdType:{
                required: requiredIf(function () {
                    return !this.usesDomesticInstructionsOnly && isCustodianNameRequired(this) && isCSDTypeEmpty(this);
                }),
                validCSDType: function(value) {
                    return (!!value ? isValidCSDType(value,this) : true);
                },
            },
            csdIdentifier:{
                required: requiredIf(function () {
                    return !this.usesDomesticInstructionsOnly && isCustodianNameRequired(this) || ((!!this.custodianModel.csdType) && (this.custodianModel.csdType !== 'csdtNone'));
                })
            },
            address: {
                addressProperty: { },
                postalCode: { },
                city: { required: requiredIf(function () {
                    return isCustodianCityRequired(this);
                }) },
                state: { },
            }
        }
    }
}
}

export const accountTypeModelValidations = {
    validations() {
        return {
            accountTypeModel: {
                relationshipType: { required },
                externalAccountType: { required },
                bankCountryId: { required },
                currencyId: { required },
            },
        }
    }
}

export const bankAccountModelValidations = {
    validations() {
        return {
            bankAccountModel: {
                accountNumber: {
                    required: requiredIf(function () {
                        return (this.isMoneyAccount && !this.externalAccountBoundaries.requiresIBAN) || (this.isSecurityAccount 
                            && !this.isExternalAccountTypeSecurityDRS 
                            && !this.isExternalAccountTypeSecurityShareCertificate);
                    })
                },
                bankName: {required: requiredIf(function () {
                        return (this.isMoneyAccount || (this.isSecurityAccount && !this.usesDomesticInstructionsOnly && this.isExternalAccountTypeSecurityService 
                        && !(!!this.bankAccountData.custodian.name)));
                    })
                },
                bic: {
                    required: requiredIf(function () {
                        return (!this.toggles.hideBankFieldsForSecurityAccounts && ((this.isMoneyAccount || (this.isSecurityAccount && !this.usesDomesticInstructionsOnly)) && isBICRequired(this)))
                    }),
                    validBIC: withParams(
                        { mandatory: isBICMandatory(this) },
                        (value) => !!value && this.isMoneyAccount ? isValidBIC(value) : true
                      ),
                    maxLength: maxLength(11)
                },
                iban: {
                    validIBAN: function(value) {
                        return (!!value && this.externalAccountBoundaries.requiresIBAN && this.isMoneyAccount) ? isValidIBAN(value, this.bankCountryData.externalAccountIBANLength) : true;
                    }, 
                    required: requiredIf(function () {
                        return (!this.toggles.hideBankFieldsForSecurityAccounts && this.isMoneyAccount && this.externalAccountBoundaries.requiresIBAN);
                    })
                },
                intermediaryBIC: {
                    validBIC: function(value) {
                        return (!!value && this.externalAccountBoundaries.requiresIntermediaryBIC) ? isValidBIC(value) : true;
                    } 
                },
                sortCode: {
                    required: requiredIf(function () {
                        return !this.toggles.hideBankFieldsForSecurityAccounts && (this.isMoneyAccount || (this.isSecurityAccount && !this.usesDomesticInstructionsOnly)) && isSortCodeRequired(this);
                    }),                    
                    validRoutingCode: withParams(
                        { mandatory: isSortCodeMandatory(this) },
                        (value) => !!value ? isValidRoutingCode(value, this.bankCountryData, this.$ExternalAccountRouting) : true
                      )
                },
                accountAddress: {
                    addressProperty: { 
                        required :requiredIf(function () {
                            return !this.toggles.hideBankFieldsForSecurityAccounts && (this.isMoneyAccount || (this.isSecurityAccount && !this.usesDomesticInstructionsOnly)) && isAddressAndCityRequired(this);
                        }),
                        validAddressProperty: withParams(
                            { mandatory: isAddressAndCityRequired(this) },
                            () => true
                          )
                    },
                    city: { 
                        required :requiredIf(function () {
                            return !this.toggles.hideBankFieldsForSecurityAccounts && (this.isMoneyAccount || (this.isSecurityAccount && !this.usesDomesticInstructionsOnly)) && isAddressAndCityRequired(this);
                        }),
                        validCity: withParams(
                            { mandatory: isAddressAndCityRequired(this) },
                            () => true
                          ) 
                     },
                }
            }
        }
    }
}
<template>
    <div id="vestings-form" v-if="vestingsOptions">
        <div class="form-row align-items-end">
            <div class="col-md-4 col-lg-2">
                <datepicker-element id="vestingsStartDate" v-model="vestingsOptions.startDate"  :label="dictionary.page.vestingsLabelStartDate"  :yearRange="'-150:+5'"/>
            </div>
            <div class="col-md-4 col-lg-2">
                <datepicker-element id="vestingsEndDate" v-model="vestingsOptions.endDate"  :label="dictionary.page.vestingsLabelEndDate" :yearRange="'-150:+5'" />
            </div>
            <div class="col-auto">
                <div class="btn-group form-group">
                    <button class="btn btn-primary" type="button" @click="$emit('onShowButton')"> {{ dictionary.page.vestingsLabelBtnShow }} </button>
                </div>
            </div>
            <div class="col-auto">
                <div class="btn-group form-group">
                    <button class="btn btn-primary" type="button" @click="$emit('onDownloadButton')"> {{ dictionary.page.btnDownload }} </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            vestingsOptions: Object
        }
    };
</script>

import { required, requiredIf } from "vuelidate/lib/validators";
import { mustBeDecimal, mustBeModulo, mustBeMinimum} from 'Shared/Components/Vue/Validations/custom-validator'
import numeral from "numeral";

export const exercisesOrderFormValidations = {
    validations() {
        return {
            model: {
                executionMoment: { required },
                limitPrice: {
                    required: requiredIf(function () {
                        return !this.hideLimitPrice;
                    }),
                    mustBeDecimal,
                },
                nrOrderedDerivatives: {
                    required: requiredIf(function () {
                        return this.quantityType !== this.$QuantityInputType.ALL
                            && this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                    }),
                    mustBeDecimal: function (value) {
                        return (this.quantityType === this.$QuantityInputType.ALL
                            || this.quantityType === this.$QuantityInputType.ALL_AND_SELECT)
                            || mustBeDecimal(value)
                    },
                    mustBeModuloMinimum: function (value) {
                        return (this.quantityType === this.$QuantityInputType.ALL
                            || this.quantityType === this.$QuantityInputType.ALL_AND_SELECT)
                            || (mustBeModulo(value, this.model.exerciseInstructionBoundaries.transferModulo)
                                && mustBeMinimum(this.model.exerciseInstructionBoundaries.transferMinimum));
                    }
                },
                quantityAllAndSelectSelect: {
                    
                    required: requiredIf(function () {
                        return this.quantityType === this.$QuantityInputType.ALL_AND_SELECT
                            && this.model.quantityAllAndSelectSelection === this.$QuantityInputType.SELECT
                    }),
                    mustBeDecimal: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                            || mustBeDecimal(value)
                    },
                    mustBeModuloMinimum: function (value) {
                        return this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT
                            || (mustBeModulo(value, this.model.exerciseInstructionBoundaries.transferModulo)
                            && mustBeMinimum(this.model.exerciseInstructionBoundaries.transferMinimum));
                    },
                    mustBeValidSecurityAmount: function (value) {
                        if (!!value && !!this.model && this.quantityType === this.$QuantityInputType.ALL_AND_SELECT) {
                            const securityAmount = numeral(value).value();
                            return securityAmount <= this.model.available;
                        }
                        return mustBeDecimal(value);
                    }
                }
            }
        }
    }
}
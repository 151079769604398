<template>
    <div class="portlets-wrapper">
        <Disclaimer :dictionary="dictionary"/>
        <component-spinner :spin="isLoading"></component-spinner>
    </div>
</template>

<script>
    import { get } from 'api';
    import Disclaimer from "./disclaimer.component.vue";

    export default {
        components: {
            Disclaimer
        },
        data() {
            return {
                dictionary: null,
                isLoading: true,
            }
        },
        beforeCreate() {
            this.$emit('set-header', {header: null, subHeader: null});
            
            get([
                "/Disclaimer/Dictionary",
            ], response => {
                this.dictionary = response.data;

                this.$setPageTitle(this.dictionary.page.title);
                this.$emit('set-header', {header: this.dictionary.page.title});

                this.isLoading = false;

            }, this);
        }
    }
</script>
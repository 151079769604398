<template>
        <LeaversPromptModal v-if="dictionary" :dictionary="dictionary" :isModalVisible="isModalVisible" @leavers-prompt-close-modal="closeModal()"/>
</template>

<script>
    import { getAll, get, post } from 'api';
    import LeaversPromptModal from "./leavers-prompt-modal.component.vue";

    export default {
        components: {
            LeaversPromptModal
        },
        props: {
            toggles: Object
        },
        data() {
            return {
                dictionary: null,
                isLoading: true,
                accountDataModel: null,
                isCookieSet: false
            }
        },
        created() {
            if (this.toggles.displayPersonalDataPrompt)
                this.getAllData();
        },
        methods: {
            getAllData() {
                get('/LeaversPrompt/GetCookieSession', response => {
                    this.isCookieSet = response.data;
                    if (!this.isCookieSet) {
                        getAll([
                            "/LeaversPrompt/Dictionary",
                            "/Beneficiaries/BeneficiaryAccountData"
                        ], (responses) => {
                            this.dictionary = responses[0].data;
                            this.accountDataModel = responses[1].data;
                            this.isLoading = false;
                        }, this);
                    }
                }, this);
            },
            closeModal() {
                    this.$emit('modal-closed');
                    let url = `/LeaversPrompt/SetCookieSession?value=${true}`
                    post(url,
                    {},
                    (response) => {},
                    this,
                    () => { },
                    (err) => { }
                    );
            }
        },
        computed: {
            isModalVisible() {
                if (this.accountDataModel) {
                    if (this.accountDataModel.beneficiaryBoundaries.mayEditBeneficiaryDetails && this.accountDataModel.beneficiaryBoundaries.mayViewBeneficiaryDetails && this.accountDataModel.beneficiaryBoundaries.mayEditCCEmailAddress && this.accountDataModel.beneficiaryBoundaries.mayViewCCEmailAddress && !this.accountDataModel.ccEmailAddress)
                        return true;
                }

                return false;
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "questionnaire-table-container" } },
    [
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "item-key": "questionnaireId",
          "no-data-text": _vm.dictionary.labels.recordNotFound,
          "hide-default-header": _vm.isMobile,
          headers: _vm.headers,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn primaryButton",
                      attrs: {
                        role: "button",
                        to: {
                          name: "questionnaire-detail",
                          params: {
                            questionnaireId: item.questionnaireId,
                            returnUrl: "/Beneficiaries#evaluation"
                          }
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          item.canEdit
                            ? _vm.dictionary.labels.modify
                            : _vm.dictionary.labels.view
                        )
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.isEligible",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        item.isEligible == null
                          ? "-"
                          : item.isEligible
                          ? _vm.dictionary.labels.eligible
                          : _vm.dictionary.labels.notEligible
                      )
                    )
                  ])
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("QuestionnairesDetailsMobileContent", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          questionnaire: item,
                          toggles: _vm.toggles
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
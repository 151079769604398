<template>
    <ul class="sidebar-nav">
        <SecurityPriceDropdown :isHidden="toggles.hideSecurityPriceDropdown" position="side" :dictionary="securitiesDictionary" :securities="securities"></SecurityPriceDropdown>

        <template v-for="feature in features">
            <SidebarItem :key="feature.pathName" v-if="!feature.isDisabled" :name="feature.name" :urlAction="'/' + feature.pathName" :icon="feature.icon" icon-type="far"
                         :label="feature.label" :isHidden="feature.isDisabled" :controllerMatch="controllerMatch(feature.pathName)" />
        </template>

        <li class="d-lg-none">
            <hr />
        </li>

        <li class="d-lg-none">
            <a :href="$sanitize(logoutEndpoint)" :title="dictionary.navLogout">
                <i class="far fa-2x fa-sign-out-alt"></i>
                <span :data-localize="`sidebar.nav.${this.dictionary.navLogout}`" class="navigation-text">{{ dictionary.navLogout }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
import SidebarItem from './sidebar-item.component.vue';
import SecurityPriceDropdown from "Features/SecurityPricesDropdown/Assets/Vue/security-price.dropdown.component.vue";
import { EventBus } from "Shared/event-bus";

export default {
    components: {
        SidebarItem,
        SecurityPriceDropdown
    },
    props: {
        toggles: Object,
        dictionary: Object,
        logoutEndpoint: String,
        features: Array
    },
    data() {
        return {
            securitiesDictionary: {},
            securities: []
        }
    },
    mounted() {
        EventBus.$on("security-prices-data", (dictionary, securities) => {
            this.securitiesDictionary = dictionary;
            this.securities = securities;
        });
    },
    methods: {
        controllerMatch(name) {
            if(name === 'dashboard') {
               return ['', name]
            }
            return [name]
        }
    }
};
</script>
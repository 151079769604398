import ListedTradingContainer from "./listed-trading-container.component.vue";

export const listedTradingRoutes =
{
    path: '/listed',
    component: ListedTradingContainer,
    children: [
        {
            path: '*',
            redirect: '/listed'
        }
    ]
}
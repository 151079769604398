<template>
    <div>
        <div v-for="(position, index) in positions" :key="`${position}-${index}`" class="orders-listitem-container">
            <dt>{{ !index ? label : '' }}</dt>
            <dd :class="{ 'pb-2' : !!index }">{{ position }}</dd>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            positions: Array
        }
    };
</script>
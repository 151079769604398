<template>
    <div class="listed-trading-table">
        <v-data-table class="table-striped"
                      :no-data-text="dictionary.table.datatables.noRecordFound"
                      item-key="positionUsageId"
                      :hide-default-header="isMobile"
                      :headers="customHeaders"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer
                        >
            <!-- Search input at top --->
            <template v-slot:top>
                <datatable-search :label="dictionary.table.datatables.searchByKeywords" v-model="search" />
            </template>

            <template v-slot:[`item.custom-button`]="{ item }">
                <PositionActionButtons :position="item" :dictionary="dictionary" type="listed-trading"
                                       class="hideInMobile action-buttons-table-cell" />
            </template>

            <template v-slot:[`item.positionName`]="{ item }">
                <ListedTradingTablePositionActionLink :position="item" />
            </template>

            <template v-slot:[`item.buyPriceFormatted`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.buyPriceFormatted) }}</span>
            </template>

            <template v-slot:[`item.sellPriceFormatted`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.sellPriceFormatted) }}</span>
            </template>

            <template v-slot:[`item.securityPriceFormatted`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.securityPriceFormatted) }}</span>
            </template>

            <!-- Datatable template for mobile view before data rows --->
            <template v-if="isMobile" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                         v-model="sortBy" :dictionary="dictionary.table.datatables"></datatable-mobile-header>
            </template>

            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <ListedTradingTableMobile :dictionary="dictionary" :position="item" :isMobile="isMobile" />
            </template>
            
            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="dictionary.table.datatables" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import ListedTradingTableMobile from "./listed-trading-table-mobile.component.vue";
    import PositionActionButtons from "/Features/Portfolio/Assets/Vue/Shared/position-action-buttons.component.vue"
    import ListedTradingTablePositionActionLink from "./listed-trading-table-position-action-link.component.vue"
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

    const interval = 60000;

    export default {
        mixins: [vueDataTableMixin, ScreenSizeMixin],
        components: {
            ListedTradingTableMobile,
            PositionActionButtons,
            ListedTradingTablePositionActionLink
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            toggles: Object
        },
        data() {
            return {
                nonCriticalHeaders: [],
                getUrl: '/ListedTrading/All',
                options: {
                    itemsPerPage: 100,
                    page: 1,
                    sortBy: ["positionName"],
                    sortDesc: [false],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: '100', value: 100 },
                        { label: this.dictionary.table.datatables.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'custom-button', text: '', align: 'center', sortable: false, visible: !this.isMobile, width: '3rem' },
                    { value: 'positionName', text: this.dictionary.table.positionName, align: 'left', sortable: true, visible: !this.isMobile },
                    { value: 'buyPriceFormatted', text: this.dictionary.table.bid, cellClass: 'text-nowrap', align: 'right', sortable: true, visible: !this.isMobile },
                    { value: 'sellPriceFormatted', text: this.dictionary.table.ask, cellClass: 'text-nowrap', align: 'right', sortable: true, visible: !this.isMobile },
                    { value: 'securityPriceFormatted', text: this.dictionary.table.last, cellClass: 'text-nowrap', align: 'right', sortable: true, visible: !this.isMobile },
                ],
                rows: [],
                totalRecords: 0,
                listedTradingsInterval: null
            }
        },
        computed: {
            customHeaders() {
                return this.headers.filter(h => h.visible);
            },
            sortableColumns() {
                return this.customHeaders.filter(x => x.sortable);
            }
        },
        created() {
            this.listedTradingsInterval = setInterval(this.loadItems, interval);
        },
        beforeDestroy() {
            if (!!this.listedTradingsInterval) {
                clearInterval(this.listedTradingsInterval);
                this.listedTradingsInterval = null;
            }
        }
    }
</script>
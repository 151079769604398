<template>
  <div v-if="mayAddOrEditAccount" class="d-md-flex">
      <button class="btn primaryButton ml-auto" type="button" @click="$emit('add-account')" >
          {{ dictionary.btnAddAccount }}
      </button>
  </div>
</template>

<script>
export default {
  props: {
    dictionary: Object,
    beneficiaryBoundariesData: Object,  
    accountType: String,
  },
  computed: {
      mayAddOrEditAccount(){
      return  (this.beneficiaryBoundariesData.mayEditSecuritiesBankAccount && this.accountType === this.$BankAccountType.SECURITY) ||
              (this.beneficiaryBoundariesData.mayEditMoneyBankAccount && this.accountType === this.$BankAccountType.MONEY)
    },
  }  
};
</script>
<template>
  <div :class="['internal-exchange-orderbook-table-' + type]">
    <v-data-table 
      :class="['table-striped', { 'vertical-scroll' : hasScroll }]"
      :no-data-text="dictionary.datatables.noRecordFound"
      :headers="headers"
      :items="items" 
      :loading="isLoading || isUpdating" 
      :hide-default-footer="true"
      :items-per-page="-1"
      :fixed-header="hasScroll"
      :mobile-breakpoint="0">

      <template v-slot:[`item.nrOrdersFormatted`]="{ item }">
        {{ item.nrOrdersFormatted }}
      </template>

      <template v-slot:[`item.totalQuantityFormatted`]="{ item }">
        {{ item.totalQuantityFormatted }}
      </template>

      <template v-slot:[`item.priceFormatted`]="{ item }">
        {{ item.priceFormatted }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { get } from "api";
import { vueDataTableScrollMixin } from 'Shared/Mixins/vue-data-table-scroll.mixin';

export default {
  mixins: [vueDataTableScrollMixin],
  props: {
    type: String,
    dictionary: Object,
  },
  data(){ 
    return {
      timer: null,
      items: [],
      initialRowsCount: 5,
      currentRowsCount: 5,
      pullingRowsCount: 5,
      maxRowsCount: 10,
      isLoading: true,
      isUpdating: false
  }
  },
  mounted() {
    const scrollableElement = document.querySelector('.internal-exchange-orderbook-table-' + this.type + ' .v-data-table__wrapper');
    scrollableElement?.addEventListener('scroll', e => {
        this.handleScrollEvent(scrollableElement);
    });
  },
  methods: {
    getItems(isUpdating) {
      const query = this.$route.query;
      this.isUpdating = isUpdating;
      
      get(
        `/InternalExchanges/GetOrderBook${this.type}?positionUsageId=${query.positionUsageId}` 
          + `&pagesize=${this.currentRowsCount}`,
        (response) => {
          let items = response.data;

          const emptyItem = {
                nrOrdersFormatted:"-",
                priceFormatted:"-",
                totalQuantityFormatted:"-"  
              }

          if(items.length < 4) {
           items = items.concat(Array(4 - items.length).fill(emptyItem));
          }

          this.items = items;

        },
        this,
        () => {
          this.isLoading = false;
          this.isUpdating = false;
        }
      ); 
    }
  },
  computed: {
    headers() {
      return [
        { value: "nrOrdersFormatted", text: this.dictionary.orderBook.nrOrders, align: "right", width: '31%', sortable: false },
        { value: "totalQuantityFormatted", text: this.dictionary.orderBook.quantity, align: "right", width: '38%', sortable: false },
        { value: "priceFormatted", text: this.dictionary.orderBook.price, align: "right", width: '31%', sortable: false }
      ];
    }
  },
  created() {
    this.getItems();
    this.timer = setInterval(this.getItems, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>
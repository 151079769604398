<template>
  <select
    data-img-class="mr-2"
    class="form-control select-menu-images"
    :name="`${name}`"
  >
    <slot />
  </select>
</template>

<script>
export default {
  props: {
    value: String,
    name: String,
  },
  mounted: function () {
    const emit = (value) => this.$emit("input", value);

    $(this.$el).selectmenuimages().selectmenuimages("menuWidget");

    $(this.$el).on("selectmenuimageschange", function (event, data) {
      window.needsToReloadBecauseOfLanguage = true;
      emit(data.item.value);
    });
    
    $(this.$el).val(this.value).selectmenuimages().selectmenuimages("refresh");
  },
  watch: {
    value: function (value) {
      $(this.$el).val(value).selectmenuimages().selectmenuimages("refresh");
    },
  },
};
</script>
<template>
    <div class="tooltip-container">
        <i v-if="!hideTooltip" :id="uniqueId" :class="icon" class="popover-info" aria-hidden="true"></i>
        <b-popover v-if="!hideTooltip" class="vesting-column__popover" :target="uniqueId" triggers="hover" :placement="placement">            
            <p>{{ tooltipText }}</p>
            <p class="sr-only">{{ tooltipText }}</p>
        </b-popover>
    </div>
</template>

<script>
    export default {
        props: {
            placement: {
                type: String,
                default: 'top'
            },
            icon: {
                type: String,
                default: 'far fa-info-circle'
            },
            tooltipText:{
                type: String,
                default: ''
            },
            hideTooltip:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            uniqueId() {
                return `total_currency_value_tooltip__${this._uid}`;
            }
        }
    }
</script>
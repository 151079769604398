<template>
    <div class="tab-bar">
        <component-spinner :spin="loadingDictionariesAndToggles"></component-spinner>
        <b-tabs class="tab-container" v-if="!loadingDictionariesAndToggles" ref="tabs">
            <template v-if="beneficiaryBoundariesData && financialToggles">

                <FinancialAccountTab v-if="showTabMoney" :financialDictionary="financialDictionary" :bankAccountData="moneyAccountData"
                                     :accountType="$BankAccountType.MONEY" :dictionary="financialDictionary.bankAccount.money" :tabTitle="financialDictionary.moneyTabName"
                                     tabId="BankAccount" :widgetTitle="financialDictionary.widget.bankAccount" :isActive="true" :beneficiary-boundaries-data="beneficiaryBoundariesData"
                                     @reload-bank-account-data="reloadBankAccountData" :financial-toggles="financialToggles" />

                <FinancialAccountTab v-if="showTabSecurity" :financialDictionary="financialDictionary" :bankAccountData="securityAccountData"
                                     :accountType="$BankAccountType.SECURITY" :dictionary="financialDictionary.bankAccount.security" :tabTitle="financialDictionary.securityTabName"
                                     tabId="SecurityBankAccount" :widgetTitle="financialDictionary.widget.securityBankAccount" :beneficiary-boundaries-data="beneficiaryBoundariesData"
                                     @reload-bank-account-data="reloadBankAccountData" :financial-toggles="financialToggles" />

            </template>
            <FinancialTransferSettingsTab v-if="showTabTransferSettings" :financialDictionary="financialDictionary" :datatables-dictionary="datatablesDictionary" />
        </b-tabs>
    </div>
</template>

<script>
    import FinancialAccountTab from "./financial-account-tab.component.vue";
    import FinancialTransferSettingsTab from "./financial-transfer-settings-tab.component.vue";
    import FinancialCard from "./financial-card.component.vue";
    import FinancialDefaultAccount from "./financial-default-account.component.vue";

    export default {
        props: {
            financialDictionary: Object,
            datatablesDictionary: Object,
            financialToggles: Object,
            moneyAccountData: Array,
            securityAccountData: Array,
            beneficiaryBoundariesData: Object,
            loadingDictionariesAndToggles: Boolean
        },
        components: {
            FinancialTransferSettingsTab,
            FinancialCard,
            FinancialDefaultAccount,
            FinancialAccountTab
        },
        data() {
            return {
                defaultAccount: {},
                accountType: null
            };
        },
        mounted() {
            this.$watch(
                () => {
                    return this.$refs.tabs.registeredTabs
                },
                () => {
                    this.$refs.tabs.currentTab = 0;
                }
            )
        },
        computed: {
            showTabMoney() {
                return (
                    !this.financialToggles.hideMoneyAccountTab &&
                    this.beneficiaryBoundariesData.mayViewMoneyBankAccount
                );
            },
            showTabSecurity() {
                return (
                    !this.financialToggles.hideSecurityAccountTab &&
                    this.beneficiaryBoundariesData.mayViewSecuritiesBankAccount
                );
            },
            showTabTransferSettings() {
                return !this.financialToggles.hideTransferSettingsTab;
            }
        },
        methods: {
            reloadBankAccountData(payLoad) {
                this.$emit("reload-bank-account-data", payLoad);
            }
        }
    };
</script>
import PositionDetailContainer from "./position-detail-container.component.vue";

export const positionDetailRoutes =
{
    path: '/positionDetail',
    component: PositionDetailContainer,
    children: [
        {
            path: '*',
            redirect: '/positionDetail'
        }
    ]
}
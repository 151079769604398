var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.model
    ? _c("section", [
        _c(
          "div",
          { staticClass: "mb-3" },
          [
            _c("ExercisesConfirmationRowParagraph", {
              attrs: {
                label: _vm.dictionary.securityName,
                value: _vm.model.securityName
              }
            }),
            _vm._v(" "),
            _c("ExercisesConfirmationRowParagraph", {
              attrs: {
                label: _vm.dictionary.quantityShortName,
                value:
                  _vm.model.nrOrderedDerivativesFormatted +
                  " | " +
                  _vm.model.exerciseScenarioName
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-xl" },
                [
                  _c("ExercisesConfirmationRowParagraph", {
                    attrs: {
                      hasIcon: true,
                      label: _vm.dictionary.exercisePrice,
                      value: _vm.model.exercisePriceFormatted
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xl" },
                [
                  _c("ExercisesConfirmationRowParagraph", {
                    attrs: {
                      hasIcon: true,
                      label: _vm.dictionary.securityPrice,
                      value: _vm.model.securityPriceFormatted
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.model.executionMoment.value ===
            _vm.$ExecutionMomentType.AT_PRICE
              ? _c("ExercisesConfirmationRowParagraph", {
                  attrs: {
                    hasIcon: true,
                    label: _vm.dictionary.limitPrice,
                    value: _vm.model.limitPriceFormatted
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-3" },
          [
            _c("h3", { staticClass: "primary-color" }, [
              _vm._v(_vm._s(_vm.dictionary.calculation))
            ]),
            _vm._v(" "),
            _c("ExercisesConfirmationDetailRow", {
              attrs: {
                "stack-content": "1",
                label: _vm.dictionary.securityCalculation,
                expression:
                  _vm.model.nrOrderedDerivatives +
                  " x " +
                  _vm.model.securityPriceFormatted,
                result: _vm.model.grossAmountFormatted
              }
            }),
            _vm._v(" "),
            _c("ExercisesConfirmationDetailRow", {
              attrs: {
                "stack-content": "2",
                label: _vm.dictionary.exerciseCalculation,
                expression:
                  _vm.model.nrOrderedDerivatives +
                  " x " +
                  _vm.model.exercisePriceFormatted,
                result: _vm.model.netExerciseAmountFormatted,
                resultClass: "red-text"
              }
            }),
            _vm._v(" "),
            _c("ExercisesConfirmationDetailRow", {
              attrs: { "stack-content": "3", label: _vm.dictionary.payCost }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "column-spacer" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("ExercisesConfirmationDetailMultipleResult", {
                    attrs: {
                      model: { payments: _vm.model.taxPayments },
                      noDataLabel: _vm.dictionary.taxPayment
                    }
                  }),
                  _vm._v(" "),
                  _c("ExercisesConfirmationDetailMultipleResult", {
                    attrs: {
                      model: { payments: _vm.model.feesPayments },
                      noDataLabel: _vm.dictionary.paymentFees
                    }
                  }),
                  _vm._v(" "),
                  _vm.model.brokerFees > 0
                    ? _c("ExercisesConfirmationDetailMultipleResult", {
                        attrs: {
                          model: {
                            value: _vm.model.brokerFeesFormatted,
                            label: _vm.dictionary.brokerFees
                          },
                          noDataLabel: _vm.dictionary.brokerFees
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ExercisesConfirmationDetailMultipleResult", {
                    attrs: {
                      model: { payments: _vm.model.usageFeesPayments },
                      noDataLabel: _vm.dictionary.usageFeesPayments
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            !!_vm.model.exerciseDeliveries
              ? [
                  _c("ExercisesConfirmationDetailRow", {
                    attrs: {
                      "stack-content": "4",
                      label: _vm.dictionary.exerciseDeliveries
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "column-spacer" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("ExercisesConfirmationDetailMultipleResult", {
                          attrs: {
                            model: {
                              exerciseDeliveries: _vm.model.exerciseDeliveries
                            },
                            noDataLabel: "-"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.model.hasToPayForExercise
              ? _c("h4", { staticClass: "row total-due" }, [
                  _c("span", { staticClass: "column-title col" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.dictionary.amountToPay) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "column-result col text-right" }, [
                    _vm._v(_vm._s(_vm.model.amountToPayFormatted))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.model.hasToPayForExercise
              ? _c("ExercisesConfirmationDetailRow", {
                  attrs: {
                    "stack-content": "5",
                    label: _vm.dictionary.amountToPay,
                    result: _vm.model.amountToPayFormatted
                  }
                })
              : _c("ExercisesConfirmationDetailRow", {
                  attrs: {
                    "stack-content": "5",
                    label: _vm.dictionary.restAmountToDeliver,
                    result: _vm.model.restAmountToDeliverFormatted
                  }
                })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "exercise-type-info" }, [
          _c("h3", { staticClass: "primary-color" }, [
            _vm._v(_vm._s(_vm.dictionary.deliveryInformation))
          ]),
          _vm._v(" "),
          _vm.model.hasToPayForExercise
            ? _c("p", [
                _vm._v(
                  _vm._s(_vm.dictionary.paymentAccount) +
                    " " +
                    _vm._s(_vm.model.paymentAccount)
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.model.moneyProceedsAccountDescription
            ? _c("p", [
                _vm._v(
                  _vm._s(_vm.dictionary.moneyProceedsAccount) +
                    " " +
                    _vm._s(_vm.model.moneyProceedsAccountDescription)
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.model.securityProceedsAccountDescription
            ? _c("p", [
                _vm._v(
                  _vm._s(_vm.dictionary.securityProceedsAccount) +
                    " " +
                    _vm._s(_vm.model.securityProceedsAccountDescription)
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
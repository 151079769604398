import PortfolioContainer from "./portfolio-container.component.vue";

export const portfolioRoutes =
{
    path: '/portfolio',
    component: PortfolioContainer,
    children: [
        {
            path: '*',
            redirect: '/portfolio'
        }
    ]
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    { staticClass: "order-details-list" },
    [
      _vm.instructionData.HasToPayForExercise == true
        ? [
            _c("OrderDataLabel", {
              attrs: {
                value: _vm.instructionData.AmountToPayFormatted,
                label: _vm.dictionary.modal.amountToPay
              }
            }),
            _vm._v(" "),
            _c("OrderDataLabel", {
              attrs: {
                value: _vm.instructionData.PaymentAccount,
                label: _vm.dictionary.modal.paymentAccount
              }
            })
          ]
        : [
            _c("OrderDataLabel", {
              attrs: {
                value: _vm.removeMinusSymbol(
                  _vm.instructionData.RestAmountToDeliverFormatted
                ),
                label: _vm.dictionary.modal.restAmountToDeliver
              }
            })
          ],
      _vm._v(" "),
      _vm.instructionData.SecurityProceedsAccountDescription != null
        ? [
            _c("OrderDataLabel", {
              attrs: {
                value: _vm.instructionData.SecurityProceedsAccountDescription,
                label: _vm.dictionary.modal.securityProceedsAccount
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
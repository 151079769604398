var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    { staticClass: "order-details-list" },
    [
      !_vm.toggles.hideOrderState
        ? _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.state,
              value: _vm.instructionData.State,
              "custom-class-data": "font-weight-bold"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.reference,
          value: _vm.instructionData.Reference
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.positionName,
          value: _vm.instructionData.SecurityName
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: { label: _vm.dictionary.modal.orderType, value: _vm.orderType }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.instructionDate,
          value: _vm.instructionData.SubmissionDate
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.expirationDate,
          value: _vm.instructionData.ExpirationDate
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.quantity,
          value: _vm.instructionData.QuantityFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.limitPrice,
          value: _vm.instructionData.LimitPriceFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.maxFeeReservationAmount,
          value: _vm.instructionData.MaxFeeReservationAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.expectedOrderAmount,
          value: _vm.instructionData.ExpectedOrderAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.processedAmount,
          value: _vm.instructionData.ProcessedAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.cancelledAmount,
          value: _vm.instructionData.CancelledAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.averagePrice,
          value: _vm.instructionData.AveragePriceFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.lastExecutionDate,
          value: _vm.instructionData.LastExecutionDate
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.totalPaidFees,
          value: _vm.instructionData.TotalPaidFeesFormatted
        }
      }),
      _vm._v(" "),
      _vm.toggles.displayOrderTaxDetails &&
      !_vm.isCancelled &&
      _vm.instructionData.TaxRate !== null
        ? _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.taxRate,
              value: _vm.instructionData.TaxRate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.toggles.displayOrderTaxDetails &&
      !_vm.isCancelled &&
      _vm.taxAmount !== null
        ? _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.taxAmount,
              value: _vm.taxAmount
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.totalTransactionValue,
          value: _vm.instructionData.TotalTransactionValueFormatted
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
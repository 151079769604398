var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMobile
        ? [
            _c(
              "v-expansion-panels",
              {
                staticClass: "portfolio-expansion-panels",
                attrs: { accordion: "" }
              },
              [
                !_vm.toggles.hideTotalValueBlock ||
                !_vm.toggles.hideBalanceBlock ||
                !_vm.toggles.hideCashAvailableBlock ||
                !_vm.toggles.hideTotalExpectedValueBlock
                  ? _c(
                      "v-expansion-panel",
                      [
                        _c("v-expansion-panel-header", [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _vm.headerColumn == "totalValue"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.totalValue
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerColumn == "cashBalance"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.cashBalance
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerColumn == "cashAvailable"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.cashAvailable
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.headerColumn == "totalExpectedValue"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.totalExpectedValue
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-expansion-panel-content", [
                          _c(
                            "div",
                            [
                              !_vm.toggles.hideBalanceBlock &&
                              _vm.portfolioSummary.cashBalance &&
                              _vm.headerColumn != "cashBalance"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.cashBalance
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.toggles.hideCashAvailableBlock &&
                              _vm.portfolioSummary.cashAvailable &&
                              _vm.headerColumn != "cashAvailable"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.cashAvailable
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.toggles.hideTotalExpectedValueBlock &&
                              _vm.portfolioSummary.totalExpectedValue &&
                              _vm.headerColumn != "totalExpectedValue"
                                ? _c("PortfolioSummaryMobileBlock", {
                                    attrs: {
                                      "portfolio-summary":
                                        _vm.portfolioSummary.totalExpectedValue
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : [
            !_vm.toggles.hideTotalValueBlock && _vm.portfolioSummary.totalValue
              ? _c("PortfolioSummaryBlock", {
                  attrs: {
                    "portfolio-summary": _vm.portfolioSummary.totalValue
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.toggles.hideBalanceBlock && _vm.portfolioSummary.cashBalance
              ? _c("PortfolioSummaryBlock", {
                  attrs: {
                    "portfolio-summary": _vm.portfolioSummary.cashBalance
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.toggles.hideCashAvailableBlock &&
            _vm.portfolioSummary.cashAvailable
              ? _c("PortfolioSummaryBlock", {
                  attrs: {
                    "portfolio-summary": _vm.portfolioSummary.cashAvailable
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.toggles.hideTotalExpectedValueBlock &&
            _vm.portfolioSummary.totalExpectedValue
              ? _c("PortfolioSummaryBlock", {
                  attrs: {
                    "portfolio-summary": _vm.portfolioSummary.totalExpectedValue
                  }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
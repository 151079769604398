<template>
    <div id="transaction-form">
        <div class="form-row">
            <div class="col-md-4 col-lg-5">
                <div class="form-group">
                    <select-element v-model="transactionsOptions.positionUsageId" :label="dictionary.labels.transactionsLabelPosition" :options="positions" @changed="$emit('onPositionChange')" name="positions"></select-element>
                </div>
            </div>
            <div class="col-md-4 col-lg-2">
                <datepicker-element id="transactionsStartDate" :label="dictionary.labels.transactionsLabelStartDate" v-model="transactionsOptions.startDate" />
            </div>
            <div class="col-md-4 col-lg-2">
                <datepicker-element id="transactionsEndDate" :label="dictionary.labels.transactionsLabelEndDate" v-model="transactionsOptions.endDate" />
            </div>
            <div class="col-12 col-sm-auto" v-if="isShowBtn">
                <div class="btn-group">
                    <button class="btn btn-primary btnSubmit" type="button" @click="$emit('onShowBtn')">{{ dictionary.labels.transactionsLabelBtnShow }}</button>
                </div>
            </div>
            <div class="col-12 col-sm-auto">
                <div class="btn-group">
                    <button class="btn btn-primary btn-primary-white-background" type="button" @click="$emit('onSendButton')">{{ dictionary.labels.transactionsLabelBtnSendToInbox }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            transactionsOptions: Object,
            positions: Array,
            isShowBtn: Boolean
        }
    };
</script>

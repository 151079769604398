<template>
    <div class="tab-row">
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.document.title }}</h1>
        </div>

        <div class="tab-bar" id="documents-vm">
            <component-spinner :spin="isLoading"></component-spinner>
            <Documents v-if="dictionary && dictionaryPersonalDocuments" :dictionary-documents="dictionary" :dictionary-personal-documents="dictionaryPersonalDocuments" :toggles="toggles" :plans="plans" />
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Documents from "./documents.component.vue";
    export default {
        components: {
            Documents
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                dictionaryPersonalDocuments: null,
                toggles: null,
                plans: [],
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                "/Document/Dictionary",
                "/RequiredDocuments/Dictionary",
                "/Document/Toggles",
                "/Document/GetAllPlanNames"
            ], responses => {
                this.dictionary = responses[0].data;
                this.dictionaryPersonalDocuments = responses[1].data;
                this.toggles = responses[2].data;
                this.plans = responses[3].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    };
</script>

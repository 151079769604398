var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.fiscalDataModel.fiscalNumber,
          label: _vm.dictionary.fiscalNumber
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.fiscalDataModel.country,
          label: _vm.dictionary.country
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.fiscalDataModel.startDate,
          label: _vm.dictionary.startDate,
          "display-value": _vm.fiscalDataModel.startDateFormatted
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.fiscalDataModel.endDate,
          label: _vm.dictionary.endDate,
          "display-value": _vm.fiscalDataModel.endDateFormatted
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="orders-listitem-container">
        <dt>{{ label }}</dt>
        <dd>{{ value }}</dd>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            value: Number | String
        }
    };
</script>
<template>
    <div>
      <form id="cancel-order-form">
        <div class="form-group">
            <button type="button" class="btn secondaryButton" @click="$emit('close')">{{dictionary.modal.cancelOrderNo}}</button> 
            <button type="button" class="btn primaryButton"   @click="$emit('submit-completed')">{{dictionary.modal.cancelOrderYes}}</button>             
        </div>
      </form>
    </div>
</template>

<script>

export default {
  props: {
    instructionData: Object,
    dictionary: Object,
  },  
};
</script>

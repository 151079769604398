var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: { title: _vm.dictionary.page.subTitle },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("ListedTradingTable", { attrs: { dictionary: _vm.dictionary } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accountDictionary && _vm.showMfaPortlet
    ? _c("portlet-layout", {
        attrs: {
          title: _vm.accountDictionary.widgets.account,
          hideShadow: true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.accountDictionary.widgets.mfa) +
                      "\n    "
                  )
                ]
              },
              proxy: true
            },
            {
              key: "body",
              fn: function() {
                return [
                  _c("AccountEnableMfaToggle", {
                    attrs: {
                      dictionary: _vm.accountDictionary.mfa,
                      boundaries: _vm.accountDataModel.beneficiaryBoundaries,
                      "mfa-boundaries-data-model": _vm.mfaBoundariesDataModel,
                      telephone: _vm.accountDataModel.telephone,
                      toggleValue: _vm.accountDataModel.mfaEnabled
                    },
                    on: {
                      "on-switch": function($event) {
                        return _vm.onSwitch($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("MfaModal", {
                    attrs: {
                      dictionary: _vm.accountDictionary,
                      header: _vm.modalHeader,
                      message: _vm.modalConfirmationMessage,
                      currentFactorType: _vm.switchFactor,
                      "mfa-boundaries-data-model": _vm.mfaBoundariesDataModel,
                      "mfa-enable-disable-data-model":
                        _vm.mfaEnableDisableDataModel,
                      phone: _vm.modalPacPhone,
                      isModalVisible: _vm.isMfaModalVisable,
                      showFirstScreen: _vm.isMfaEnabling
                    },
                    on: {
                      "confirm-code": _vm.confirmCode,
                      cancel: function($event) {
                        return _vm.closeMfaModal()
                      },
                      "submit-confirmation": _vm.submitConfirmation,
                      "mfa-success-login": function($event) {
                        return _vm.pacCodeConfirmed()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          298087540
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-topprogress", { ref: "topProgress" }),
      _vm._v(" "),
      _c("div", [
        _c(
          "nav",
          {
            staticClass: "navbar topnavbar",
            attrs: { "aria-label": "Top navigation" }
          },
          [
            _c("div", { staticClass: "navbar-header" }, [
              _c(
                "div",
                [
                  _c("logo-image", {
                    attrs: {
                      "logo-desktop": _vm.skin.images.logo,
                      "logo-mobile": _vm.skin.images.logoMobile,
                      "logo-favicon": _vm.skin.images.favicon,
                      "logo-alignment": _vm.skin.layout.logoAlignment,
                      "logo-overlap": _vm.skin.layout.logoOverlap
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "header-additional-info-container" },
              [
                !_vm.isLoading && _vm.headerAuthenticated
                  ? _c("CurrentEnvironment", {
                      attrs: {
                        text: _vm.headerAuthenticated.currentEnvironment
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isImpersonated
                  ? _c("LocalhrContainer", {
                      attrs: { dictionary: _vm.dictionary.header }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isLoading &&
            _vm.headerAuthenticated &&
            !_vm.isDisclaimer &&
            !_vm.toggles.topbarToggles.hideSecurityPriceDropdown
              ? _c("SecurityPricesDropdown", {
                  attrs: {
                    position: "top",
                    dictionary: _vm.dictionary.securityPricesDropdown,
                    securities: _vm.securities,
                    "is-loading": _vm.isLoading
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "navbar-nav" },
              [
                !_vm.isDisclaimer
                  ? [
                      _c(
                        "li",
                        {
                          staticClass:
                            "nav-item d-lg-none justify-content-around nav-bar-no-desktop"
                        },
                        [
                          !_vm.isLoading &&
                          _vm.headerAuthenticated &&
                          _vm.showAvatarIcon
                            ? _c("div", { staticClass: "p-1 personal-menu" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link sidebar-toggle",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleBodyClass(
                                          "aside-toggled",
                                          "personal-menu"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "nav-icon" }, [
                                      _c("i", {
                                        staticClass: "far fa-user fa-2x",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary.sidebar
                                              .navPersonalData
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "personal-menu-btn-close"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-times fa-2x pr-2",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dictionary.sidebar
                                                .navMobileMenuCloseSRText
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "p-1" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    to: "/inbox",
                                    title: _vm.dictionary.sidebar.navInbox
                                  }
                                },
                                [
                                  _c("span", { staticClass: "nav-icon" }, [
                                    _c("i", {
                                      staticClass: "far fa-envelope fa-2x",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.sidebar.navInbox)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "navigation-menu p-1",
                              attrs: { "aria-live": "polite" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link sidebar-toggle",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleBodyClass(
                                        "aside-toggled",
                                        "navigation-menu"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "nav-icon" }, [
                                    _c("i", {
                                      staticClass: "far fa-bars fa-2x",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dictionary.sidebar
                                              .navMobileMenuOpenSRText
                                          )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "navigation-menu-btn-close"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-times fa-2x pr-2",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary.sidebar
                                              .navMobileMenuCloseSRText
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isLoading &&
                      _vm.headerAuthenticated &&
                      _vm.showAvatarIcon
                        ? _c("li", { staticClass: "nav-item personal-data" }, [
                            _c(
                              "ul",
                              [
                                _c("PersonalDataDropdown", {
                                  attrs: {
                                    "beneficiary-boundaries-data-model":
                                      _vm.beneficiaryBoundariesDataModel,
                                    dictionary: _vm.dictionary,
                                    toggles: _vm.toggles,
                                    "account-data-model": _vm.accountDataModel,
                                    "user-full-name":
                                      _vm.headerAuthenticated.fullName,
                                    "nav-personal-data-label":
                                      _vm.dictionary.sidebar.navPersonalData
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item nav-bar-desktop" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: "/inbox",
                                title: _vm.dictionary.sidebar.navInbox
                              }
                            },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("i", {
                                  staticClass: "far fa-envelope",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v(_vm._s(_vm.dictionary.sidebar.navInbox))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "nav-item pr-4 nav-bar-desktop",
                          attrs: { title: _vm.dictionary.sidebar.navLogout }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: _vm.$sanitize(_vm.logoutEndpoint) }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link btn-xl btn-signout",
                                  attrs: { type: "submit", id: "LogOutButton" }
                                },
                                [
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.sidebar.navLogout)
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  : [
                      _c(
                        "li",
                        {
                          staticClass: "nav-item pr-4",
                          attrs: { title: _vm.dictionary.sidebar.navLogout }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: _vm.$sanitize(_vm.logoutEndpoint) }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-link btn-xl btn-signout",
                                  attrs: { type: "submit", id: "LogOutButton" }
                                },
                                [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.sidebar.navLogout)
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "nav-icon" }, [
      _c("i", {
        staticClass: "far fa-sign-out-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "nav-icon-not-scalable" }, [
      _c("i", {
        staticClass: "far fa-sign-out-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
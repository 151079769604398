<template>
  <div id="personal-documents-container"> 
    <v-data-table
        class="table"
        id="dtPersonalDocuments"
        hide-default-header
        :no-data-text="dictionary.labels.noRecordPersonalDocuments"
        :headers="headers"
        :items="rows"
        :loading="isLoading"
        :server-items-length="totalRecords"
        :search="search"
        :items-per-page="options.itemsPerPage"
        @page-count="options.pageCount = $event;"
        :options.sync="options"
        hide-default-footer
        >
      
    <!-- Search input at top --->
    <template v-slot:top>
          <datatable-search :label="dictionary.searchByKeywords" v-model="search" />
    </template>

    <template v-slot:item="{ item }">
          <TablePersonalDocumentsTableBody :dictionary="dictionary" :item="item" />          
    </template>

    <template v-slot:footer>
          <datatable-pagination :datatablesDictionary="dictionary.labels" v-model="options" />
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { vueDataTableMixin } from "Shared/Mixins/vue-data-table.mixin";
import TablePersonalDocumentsTableBody from "./table-personal-documents-body.component.vue";
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

export default {
  mixins: [vueDataTableMixin, ScreenSizeMixin],
  components: {
    TablePersonalDocumentsTableBody,
  },
  props: {
    dictionary: Object,
  },
  data() {
    return {
      getUrl: "/RequiredDocuments/PersonalDocuments",
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["documentDate"],
        sortDesc: [true],
        pageCount: 0,
        itemsPerPageOptions: [
          { label: '5', value: 5 },
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: this.dictionary.labels.showAll, value: -1 }
        ]
      },
      rows: [],
      totalRecords: 0,
      headers: [],
      timer: null
    };
  },
  created() {
   this.timer = setInterval(this.loadItems, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
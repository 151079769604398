<template>
    <div>
      <h2>{{dictionary.addAccount.heading}}</h2>
      <component-spinner :spin="isLoading"></component-spinner>
      <timeline-container>
        <timeline-step  v-if="!!complianceExternalAccount" :currentStep="currentStep" :step="1">
            <FinancialAddAccountStepAccountType :current-step="currentStep"
                                                :maxNumberOfSteps="maxNumberOfSteps"
                                                :account-type="accountType"
                                                :dictionary="dictionary.addAccount"
                                                :validation-dictionary="financialDictionary.validations"
                                                :currencies="listCurrencies"
                                                :countries="listCountries"
                                                :external-account-types="listExternalAccountTypes"
                                                :relationship-types="listRelationshipTypes"
                                                :account-type-data="complianceExternalAccount.accountType"
                                                :toggles="financialToggles"
                                                :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                                :showStep3="showStep3"
                                                @changed-external-account-type="changedExternalAccountType()"
                                                @next-step="nextStep" 
                                                @cancel-add-account="cancelAddAccount()"
                                                @update-boundaries="updateAccountBoundaries"
                                                @edit-step="editStep"
                                                :action="$AccountActionType.ADD"
                                                class="timeline-step" >
            </FinancialAddAccountStepAccountType>
        </timeline-step>

        <timeline-step   v-if="!!complianceExternalAccount" :currentStep="currentStep" :step="2">
            <FinancialAddAccountStepAccountHolder :account-type="accountType"
                                                  :current-step="currentStep"
                                                  :maxNumberOfSteps="maxNumberOfSteps"
                                                  :dictionary="dictionary.addAccount"
                                                  :validation-dictionary="financialDictionary.validations"
                                                  :countries="listCountries"
                                                  :account-holder-data="complianceExternalAccount.accountHolder"
                                                  :is-account-holder-name-and-city-mandatory="isAccountHolderNameAndCityMandatory"
                                                  :externalAccountType="complianceExternalAccount.accountType.externalAccountType"
                                                  :toggles="financialToggles"
                                                  :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                                  :messageInSecurityAccountHolderStep="messageInSecurityAccountHolderStep"
                                                  :showStep3="showStep3"
                                                  @next-step="nextStep" 
                                                  @previous-step="previousStep" 
                                                  @cancel-add-account="cancelAddAccount()"
                                                  @edit-step="editStep"
                                                  @set-compliance-external-account="setComplianceExternalAccount"
                                                  class="timeline-step">
            </FinancialAddAccountStepAccountHolder>
        </timeline-step>

        <timeline-step   v-if="showStep3 && externalAccountBoundaries && complianceExternalAccount" :currentStep="currentStep" :step="3">
            <FinancialAddAccountStepBankAccount :account-type="accountType"
                                                :current-step="currentStep"
                                                :maxNumberOfSteps="maxNumberOfSteps"
                                                :editingExistingAccount="false"
                                                :isEdit="true"
                                                :dictionary="dictionary.addAccount"
                                                :validation-dictionary="financialDictionary.validations"
                                                :countries="listCountries"
                                                :external-account-boundaries="externalAccountBoundaries"
                                                :compliance-external-account="complianceExternalAccount"
                                                :bank-account-data="complianceExternalAccount.bankAccount" :errors="errors"
                                                :central-securities-depository-types="listCentralSecuritiesDepositoryTypes"
                                                :bank-country-data="bankCountry"
                                                :toggles="financialToggles"
                                                :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                                :showStep3="showStep3"
                                                @previous-step="previousStep" 
                                                @next-step="nextStep"
                                                @cancel-add-account="cancelAddAccount()" 
                                                @set-compliance-external-account="setComplianceExternalAccount"
                                                :action="$AccountActionType.ADD"
                                                class="timeline-step">
            </FinancialAddAccountStepBankAccount>
        </timeline-step>
      </timeline-container>
    </div>  
</template>

<script>
import { get, getAll, post } from "api";
import FinancialAddAccountStepAccountType from "./financial-add-account-step-account-type.component.vue";
import FinancialAddAccountStepAccountHolder from "./financial-add-account-step-account-holder.component.vue";
import FinancialAddAccountStepBankAccount from "./financial-add-account-step-bank-account.component.vue";
import { addEditStepsLogic } from "./financial-add-edit-steps-logic.mixin.js";


export default {
  mixins: [addEditStepsLogic],
  components: {
    FinancialAddAccountStepAccountType,
    FinancialAddAccountStepAccountHolder,
    FinancialAddAccountStepBankAccount
  },
  props: {
    dictionary: Object,
    financialDictionary: Object,
    beneficiaryBoundariesData: Object,
    accountType: String,
    financialToggles: Object
  },
  data() {
    return {
      maxNumberOfSteps: 4,
      isAccountHolderNameAndCityMandatory:true,
      securityService: "beatSecurity",
      securityCustodian: "beatSecurityCustodian",
      securityAccountDisplaySettings:null
    };
  },
  created() {
    getAll(
      ["/Common/GetCurrencies", "/Common/GetCountries", '/Beneficiaries/GetSecurityAccountDisplaySettings', `/Beneficiaries/DefaultExternalAccount?accountType=${this.accountType}`],
      (responses) => {
        this.currencies = responses[0].data;
        this.countries = responses[1].data;
        this.securityAccountDisplaySettings = responses[2].data;

        this.complianceExternalAccount = responses[3].data;         
        this.externalAccountTypes = responses[3].data.externalAccountTypes;
        this.relationshipTypes = responses[3].data.relationshipTypes;
        
        this.centralSecuritiesDepositoryTypes = responses[3].data.centralSecuritiesDepositoryTypes;
        this.bankCountry = this.countries.filter(c => c.id == this.complianceExternalAccount.accountType.bankCountryId)[0];

        if(!this.complianceExternalAccount.accountHolder.holderAddress?.countryId) {
            this.complianceExternalAccount.accountHolder.holderAddress ??= {};
            this.complianceExternalAccount.accountHolder.holderAddress.countryId = this.bankCountry.id;
        }

        this.displayBankAccountStep();
        this.displayMessageInSecurityAccountHolderStep(this.dictionary);
        this.isLoading = false;
      },
      this,
      () => { 
      },
      (err) => {
       }
    );

    const postData = { 
          externalAccountType : this.accountType,
          complianceExternalAccountId : this.complianceExternalAccount?.complianceExternalAccountId,
          beneficiaryExternalAccountType : this.complianceExternalAccount?.beneficiaryExternalAccountType,
          currencyId : this.complianceExternalAccount?.currencyId,
          bankCountryId : this.complianceExternalAccount?.bankCountryId,
    };

    this.postAccountBoundaries(postData);
  },
  computed:{
    isSecurityAccount(){
      return this.accountType === this.$BankAccountType.SECURITY;
    },
    isMoneyAccount(){
      return this.accountType === this.$BankAccountType.MONEY;
    }
  },
  methods: {
     cancelAddAccount() {
      this.$emit("cancel-add-account");
    },
    changedExternalAccountType(){
      this.displayBankAccountStep();
      this.displayMessageInSecurityAccountHolderStep(this.dictionary);
      if(this.isSecurityAccount){
        const externalAccountType = this.complianceExternalAccount.accountType.externalAccountType;
          if(externalAccountType === this.securityService || externalAccountType === this.securityCustodian){
              this.isAccountHolderNameAndCityMandatory = false;
            }
            else{
              this.isAccountHolderNameAndCityMandatory = true;
            }
      }
    },
    editStep(payLoad) {
      this.currentStep = payLoad.step;
    },
    setComplianceExternalAccount(payLoad) {
      switch (payLoad.step) {
        case 1:
          this.complianceExternalAccount.accountType = payLoad.data
          break;
        case 2:
          this.complianceExternalAccount.accountHolder = payLoad.data
          break;
        case 3: {
          if (this.showStep3)
            this.complianceExternalAccount.bankAccount = payLoad.data;
          break;
          }
        default:
          break;
      }
      
      if ((this.currentStep === 4 && payLoad.step === 4) || (payLoad.submit && !this.showStep3)) {

          if ((payLoad.submit && !this.showStep3))
            this.complianceExternalAccount.bankAccount = null;

          this.createExternalAccount();
      }
    },
    createExternalAccount() {
      const context = this;
      context.submitingData = true;
      context.errors = [];
      post(
        "/Beneficiaries/CreateExternalAccount",
        this.complianceExternalAccount,
        (response) => {
          this.$emit("add-account-completed", { accountType: context.accountType});
        },
        context,
        () => {
          context.submitingData = false;
        },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            context.errors = err.response.data;
          } else {
            context.errors.push(err.response.data);
          }
        }
      );
    },
    postAccountBoundaries(postData) {
      const context = this;      
      post(
        "/Beneficiaries/ExternalAccountBoundaries",
        postData,
        (response) => {
            context.externalAccountBoundaries = response.data;
        },
        context,
        () => {
          
        },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            context.errors = err.response.data;
          } else {
            context.errors.push(err.response.data);
          }
        }
      );
    },
    updateAccountBoundaries(accountType){
      const postData = { 
          externalAccountType : this.accountType,
          complianceExternalAccountId : this.complianceExternalAccount?.complianceExternalAccountId,
          beneficiaryExternalAccountType : this.complianceExternalAccount?.beneficiaryExternalAccountType,
          currencyId : accountType.currencyId,
          bankCountryId : accountType.bankCountryId,
      };
      this.postAccountBoundaries(postData);   
      this.bankCountry = this.countries.filter(c => c.id == this.complianceExternalAccount.accountType.bankCountryId)[0];
      this.complianceExternalAccount.bankAccount.accountAddress.countryId = this.bankCountry.id;
      this.complianceExternalAccount.bankAccount.accountAddress.country = this.bankCountry.name;
    }

  }
};
</script>
<template>
<div class="vesting">
    <div v-if="position.showVestings" class="vesting__show-vestings">
        <div class="vesting__show-vestings__granted">
            {{ dictionary.table.granted }} <span class="float-right">{{ position.grantedFormatted }}</span>            
        </div> 
        <div class="vesting__show-vestings__accepted" v-if="toggles.showAcceptedAmount">
            {{ dictionary.table.accepted }} <span class="float-right">{{ position.acceptedFormatted }}</span>            
        </div> 
        <div class="vesting__show-vestings__vested">
            {{ dictionary.table.vested }} <span class="float-right">{{ position.vestedFormatted }}</span>
        </div>
        <div class="vesting__show-vestings__unvested">
            {{ dictionary.table.unvested }} <span class="float-right">{{ position.unvestedFormatted }}</span>
        </div>
    </div>
    <div v-else class="vesting__hide-vestings">{{ position.grantedFormatted }}</div>
</div>
</template>

<script>
export default {
    props: {
        position: Object,
        dictionary: Object,
        toggles: Object
    }
}
</script>
<template>
    <modal-basic v-if="dictionary" :title="dictionary.defaultAccountModal.title" :is-on="isModalVisible" :isLoading="submitingData" :ok-footer="false" @close="close()">
      <div v-if="bankAccount"  id="default-account-container" class="default-account-container" >
        <BeneficiaryFinancialDefaultAccountBody :dictionary="dictionary" :financialDictionary="financialDictionary" :account-data="bankAccount" :hasInvalidSettings="bankAccount.hasInvalidSettings" :errors="errors"/>
        <BeneficiaryFinancialDefaultAccountButtons :dictionary="dictionary" :hasInvalidSettings="bankAccount.hasInvalidSettings" @cancel-submit="cancelSubmit()" 
            @submit-default-account="submitDefaultAccount()" :may-set-default="maySetDefault" />    
      </div> 
    </modal-basic>
</template>

<script>
import { post, errorMessage } from "api";
import BeneficiaryDataLabel from "./../data-label.component.vue";
import BeneficiaryFinancialDefaultAccountButtons from "./financial-default-account-buttons.component.vue";
import BeneficiaryFinancialDefaultAccountBody from "./financial-default-account-body.component.vue";

export default {
  components: {
    BeneficiaryDataLabel,
    BeneficiaryFinancialDefaultAccountButtons,
    BeneficiaryFinancialDefaultAccountBody,
  },
  props: {
    financialDictionary: Object,
    bankAccount: Object,
    beneficiaryBoundariesData: Object,  
    accountType: String,
    dictionary: Object,
    isModalVisible: Boolean,
    financialToggles: Object
  },
  data() {
    return {
      submitingData: false,
      errors: [],
    };
  },
  methods: {
    submitDefaultAccount() {
      const context = this;
      context.submitingData = true;
      const requestUrl = `Beneficiaries/AccountAsDefaultAsync?accountId=${context.bankAccount.id}`;
      post(
        requestUrl,
        {},
        (response) => {
          context.$emit("submit-completed", {
            accountType: context.accountType,
          });
        },
        context,
        () => {
          context.submitingData = false;
        },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            this.errors = err.response.data;
          } else {
            this.errors = [errorMessage];
          }
        }
      );
    },
    cancelSubmit(){
      this.close();
    },
    close() {
      this.$emit("close-modal");
    },
  },
  watch: {
    bankAccount: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
            this.$emit("open-modal");
            this.errors = [];
        }
      }
    } 
  },
  computed: {
    maySetDefault(){
      return (this.beneficiaryBoundariesData.mayEditSecuritiesBankAccount && this.accountType === this.$BankAccountType.SECURITY) ||
        (this.beneficiaryBoundariesData.mayEditMoneyBankAccount && this.accountType === this.$BankAccountType.MONEY)
    }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        [
          _c("div", [_c("p", [_vm._v(_vm._s(_vm.headerDescription))])]),
          _vm._v(" "),
          _vm.error
            ? _c("alert-component", { attrs: { variant: "error" } }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("loading-spinner", {
            attrs: { active: _vm.isLoading || _vm.isSkipping }
          }),
          _vm._v(" "),
          _vm.disclaimers && _vm.disclaimers.length
            ? _c(
                "div",
                { staticClass: "disclaimers mb-4" },
                _vm._l(_vm.disclaimers, function(disclaimer, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "disclaimer-block mb-2" },
                    [
                      _c("div", { staticClass: "disclaimer-header mb-1" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: disclaimer.isAccepted,
                              expression: "disclaimer.isAccepted"
                            }
                          ],
                          attrs: {
                            id: "disclaimerCheckbox-" + index,
                            type: "checkbox"
                          },
                          domProps: {
                            checked: Array.isArray(disclaimer.isAccepted)
                              ? _vm._i(disclaimer.isAccepted, null) > -1
                              : disclaimer.isAccepted
                          },
                          on: {
                            change: function($event) {
                              var $$a = disclaimer.isAccepted,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      disclaimer,
                                      "isAccepted",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      disclaimer,
                                      "isAccepted",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(disclaimer, "isAccepted", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass:
                              "font-weight-bold mb-0 form-input-label",
                            attrs: { for: "disclaimerCheckbox-" + index }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(disclaimer.title) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        disclaimer.isMandatory
                          ? _c(
                              "div",
                              { staticClass: "disclaimer-required ml-auto" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.dictionary.labels.required) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "disclaimer-body ml-1 rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(_vm.$sanitize(disclaimer.text))
                        }
                      }),
                      _vm._v(" "),
                      !!disclaimer.documentId
                        ? _c("div", { staticClass: "disclaimer-download" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.$sanitize(
                                    "/LoginDisclaimers/DownloadDisclaimer?documentType=" +
                                      disclaimer.documentType +
                                      "&documentId=" +
                                      disclaimer.documentId
                                  ),
                                  title: _vm.dictionary.labels.btnDownload
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-download p-1",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(disclaimer.documentName) +
                                    "\n        "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-md-flex justify-content-end disclaimer-buttons-container"
            },
            [
              _vm.canSkipDisclaimers
                ? _c(
                    "button",
                    {
                      staticClass: "btn secondaryButton",
                      on: { click: _vm.skip }
                    },
                    [_vm._v(_vm._s(_vm.dictionary.labels.btnSkip))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn primaryButton",
                  attrs: { disabled: _vm.disableSubmitButton },
                  on: { click: _vm.submitDisclaimers }
                },
                [_vm._v(_vm._s(_vm.acceptButtonTitle))]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
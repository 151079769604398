var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mayAddOrEditAccount
    ? _c("div", { staticClass: "d-md-flex" }, [
        _c(
          "button",
          {
            staticClass: "btn primaryButton ml-auto",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("add-account")
              }
            }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.dictionary.btnAddAccount) + "\n    "
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
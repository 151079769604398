<template>
    <div id="passwordValidationNote">
        <article>
            <p><i class="fas fa-check-square" aria-hidden="true"></i> {{ passwordMinLength }} </p>
            <template v-if="boundaries.useStrongPassword">
                <p><i class="fas fa-check-square" aria-hidden="true"></i> {{ dictionary.accountChangePasswordSpecialCharacters }} <strong> {{ specialCharacters }} </strong></p>
                <p><i class="fas fa-check-square" aria-hidden="true"></i> {{ dictionary.accountChangePasswordCapitalLetters }} </p>
                <p><i class="fas fa-check-square" aria-hidden="true"></i> {{ dictionary.accountChangePasswordLowerCaseLetters }} </p>
                <p><i class="fas fa-check-square" aria-hidden="true"></i> {{ dictionary.accountChangePasswordDigits }} </p>
            </template>
        </article>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            boundaries: Object
        },
        computed: {
            specialCharacters() {
                return '!"#$%&\'()*+,-./:;<>?@@[\]^_`{|}~';
            },
            passwordMinLength() {
                return this.$replaceStringWithPlaceholders(
                    this.dictionary.accountChangePasswordLength,
                    {
                        "MinimumLength": this.boundaries.minimumLength
                    }
                )
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-view", {
    attrs: {
      dictionary: _vm.dictionary,
      "is-loading": _vm.isLoading,
      toggles: _vm.toggles,
      "is-subscriptions": _vm.isSubscriptions,
      pagesDictionary: _vm.pagesDictionary
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="!isMobile" class="position-action-links__link-container">
        <a v-if="!disableLink" class="position-action-links__link" @click="$emit('onClick')">
            <PortfolioPlanPositionName :position="position" />
        </a>
        <span v-else class="position-action-links__link" >
            <PortfolioPlanPositionName :position="position" />
        </span>
    </div>
    <div v-else>
    <a class="btn primaryButton mobile" @click="$emit('onClick')" role="button">
        {{ mobileLabel }}
    </a>
    </div>
</template>

<script>
import PortfolioPlanPositionName from './portfolio-plan-position-name.component.vue';

export default {
    components: {
        PortfolioPlanPositionName
    },
    props: {
        disableLink: Boolean,
        href: String,
        position: Object,
        isMobile: Boolean,
        mobileLabel: String
    }
}
</script>
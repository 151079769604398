<template>
  <dl class="order-details-list"> 
      <template v-if="instructionData.HasToPayForExercise == true">
          <OrderDataLabel :value="instructionData.AmountToPayFormatted" :label="dictionary.modal.amountToPay"></OrderDataLabel>
          <OrderDataLabel :value="instructionData.PaymentAccount" :label="dictionary.modal.paymentAccount"></OrderDataLabel>
      </template>
      <template v-else> 
          <OrderDataLabel :value="removeMinusSymbol(instructionData.RestAmountToDeliverFormatted)" :label="dictionary.modal.restAmountToDeliver"></OrderDataLabel>
      </template>
      <template v-if="instructionData.SecurityProceedsAccountDescription != null">
          <OrderDataLabel :value="instructionData.SecurityProceedsAccountDescription" :label="dictionary.modal.securityProceedsAccount"></OrderDataLabel>
      </template>
  </dl>      
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
export default {
  components: {
    OrderDataLabel,
  },
  props: {
    instructionData: Object,
    dictionary: Object,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pt-2 form-group float-right" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-secondary",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("cancel-form")
          }
        }
      },
      [_vm._v("\n        " + _vm._s(_vm.commonDictionary.btnCancel) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("submit-form")
          }
        }
      },
      [_vm._v("\n        " + _vm._s(_vm.commonDictionary.btnSubmit) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "modal-basic",
        {
          staticClass: "cookie-policy-modal",
          attrs: {
            size: "lg",
            "is-on": _vm.isModalVisible,
            "ok-footer": false,
            staticMode: true,
            lazy: false,
            centered: true,
            noCloseOnEsc: true
          },
          on: {
            "hook:mounted": function($event) {
              return _vm.tryShowCookieModal()
            }
          }
        },
        [
          _c("div", { staticClass: "tab-row" }, [
            _c(
              "div",
              { staticClass: "tab-bar" },
              [
                _c(
                  "b-tabs",
                  {
                    ref: "cookie_policy_modal_tabs",
                    staticClass: "tab-container",
                    attrs: { "content-class": "mt-0", fill: "" }
                  },
                  [
                    _c(
                      "portlet-tab",
                      {
                        attrs: {
                          tabTitle: _vm.dictionary.modal.consentTab,
                          isActive: true,
                          tabId: "tabOpenOrders"
                        }
                      },
                      [
                        _c("CookiePolicyModalConsent", {
                          attrs: { dictionary: _vm.dictionary },
                          on: {
                            "cookie-manage-cookies": function($event) {
                              return _vm.switchTabToDetails()
                            },
                            "cookie-allow-all": function($event) {
                              return _vm.updateSettings(true)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "portlet-tab",
                      {
                        attrs: {
                          tabTitle: _vm.dictionary.modal.detailsTab,
                          tabId: "tabHistoryOrders"
                        }
                      },
                      [
                        _c("CookiePolicyModalDetails", {
                          attrs: {
                            dictionary: _vm.dictionary,
                            functionalToggleValue: _vm.functionalToggleValue,
                            performanceToggleValue: _vm.performanceToggleValue
                          },
                          on: {
                            onFunctionalToggle: function($event) {
                              _vm.functionalToggleValue = !_vm.functionalToggleValue
                            },
                            onPerformanceToggle: function($event) {
                              _vm.performanceToggleValue = !_vm.performanceToggleValue
                            },
                            "cookie-allow-selection": function($event) {
                              return _vm.updateSettings(false)
                            },
                            "cookie-allow-all": function($event) {
                              return _vm.updateSettings(true)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
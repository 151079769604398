<template>
  <div class="details-listitem-container">
      <dt>{{ label }}</dt>
      <dd>{{ displayEligibleValue() }}</dd>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    displayValue: String,
    isEdit: Boolean,
  },
  methods: {
    isNotNullable: function () {
      return !!this.value;
    },
    displayEligibleValue: function () {
      if (!this.displayValue) {
        return this.replaceNullOrEmpty(this.value);
      }

      return this.displayValue;
    },
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "evaluation-required-documents" },
    [
      _c("RequiredDocumentsTabContainer", {
        attrs: {
          dictionary: _vm.dictionary,
          "datatables-dictionary": _vm.dictionary.table
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "rich-text-container",
        domProps: {
          innerHTML: _vm._s(
            _vm.$sanitize(_vm.dictionary.labels.textUploadRequiredDocuments)
          )
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "container-confirm-subscription" } }, [
    _c(
      "div",
      { staticClass: "confirm-subscription" },
      [
        _c("div", { staticClass: "confirm-subscription-plan" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.participationSubscription.name))
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.participationSubscription.chosenScenario.offers, function(
          offer
        ) {
          return _c(
            "div",
            {
              key: offer.participationSubscriptionOfferId,
              staticClass: "confirm-subscription-offer",
              attrs: { isMobile: _vm.isMobile }
            },
            [
              _c("SubscriptionDetailsRow", {
                attrs: {
                  label: _vm.dictionary.table.securityName,
                  value: _vm.replaceNullOrEmpty(offer.underlyingUsageName),
                  isMobile: _vm.isMobile
                }
              }),
              _vm._v(" "),
              _vm.isSubscriptions
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.granted,
                      value: _vm.getGrantAmount(offer),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !!offer.acceptedAmountFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.subscribed,
                      value: _vm.replaceNullOrEmpty(
                        offer.acceptedAmountFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !!offer.exercisePriceFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.exercisePrice,
                      value: _vm.replaceNullOrEmpty(
                        offer.exercisePriceFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !!offer.expectedValueFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.expectedValue,
                      value: _vm.replaceNullOrEmpty(
                        offer.expectedValueFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.displayEmissionPrice(offer)
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.emissionPrice,
                      value: _vm.replaceNullOrEmpty(
                        offer.emissionPriceFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.displayPurchasePrice(offer)
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.purchasePrice,
                      value: _vm.replaceNullOrEmpty(
                        offer.purchasePriceFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.displayFeesPrice(offer)
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.feesPrice,
                      value: _vm.replaceNullOrEmpty(offer.feesPriceFormatted),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.displayTotalPrice(offer)
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.contributionAmount,
                      value: _vm.replaceNullOrEmpty(offer.totalPriceFormatted),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSubscriptions
                ? _c("SubscriptionConfirmationTaxData", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      offer: offer,
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e()
            ],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
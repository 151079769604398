var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    {
      ref: "tabs",
      staticClass: "tab-container",
      attrs: { "content-class": "mt-0" }
    },
    [
      _c(
        "portlet-tab",
        {
          attrs: {
            tabTitle: _vm.dictionaryDocuments.tabs.plan,
            tabId: "tabPlanDocuments"
          }
        },
        [
          _c("div", {
            staticClass: "page-description rich-text-container",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(
                  _vm.dictionaryDocuments.page.documentsPageDescriptionPlan
                )
              )
            }
          }),
          _vm._v(" "),
          _c("TablePlanDocuments", {
            attrs: { dictionary: _vm.dictionaryDocuments, plans: _vm.plans }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.toggles.tabHidePersonalDocs
        ? _c(
            "portlet-tab",
            {
              attrs: {
                tabTitle: _vm.dictionaryDocuments.tabs.personal,
                tabId: "tabPersonalDocuments"
              }
            },
            [
              _c("div", {
                staticClass: "page-description rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionaryDocuments.page
                        .documentsPageDescriptionPersonal
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("TablePersonalDocuments", {
                attrs: { dictionary: _vm.dictionaryPersonalDocuments }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
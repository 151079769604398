<template>
    <div id="order-form-div">
      <form v-if="model" id="exercises-instruct-form" @submit.prevent="submit">
        <div v-if="dictionary.form.widgetTextPlaceOrder" class="form-group">
          <span class="primary-color rich-text-container" v-html="$sanitize(dictionary.form.widgetTextPlaceOrder)"></span>
        </div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <order-types-link :type="$OrderTypes.Exercises" :dictionary="dictionary.form.orderTypes" :positionViewModel="exercisesPositionModel" :title="dictionary.form.orderType" name="orderTypes" />
        <div class="form-group">
          <p>{{ dictionary.widgets.exerciseDescription }}</p>
        </div>
        <div class="form-group">
          <exercises-data-label :label="dictionary.widgets.labelOption" :value="exercisesPositionModel.name" />
          <exercises-data-label :label="dictionary.widgets.labelExercisePrice" :value="exercisesPositionModel.strikePriceFormatted" :tooltip="dictionary.tooltips.exercisePriceTooltip" />
        </div>
        <div class="form-group" v-if="hasDerivativeSecurity">
          <exercises-data-label :label="dictionary.widgets.labelUnderlying" :value="derivativeSecurity.name" />
          <exercises-data-label :label="dictionary.widgets.labelSecurityPrice" :value="derivativeSecurity.lastSecurityPriceFormatted" :tooltip="derivativeSecurityPriceTooltip" :hideSecurityPriceInformationTooltip="toggles.hideSecurityPriceInformationTooltip" />
        </div>
        <select-element v-model="model.exerciseScenarioId" :label="dictionary.form.scenarioName" :options="scenarioTypes" @changed="scenarioChanged()" name="scenarioTypes"></select-element>
       
        <alert-component v-if="hasRestrictedSecurityAccountSettings" variant="warning">
            <div class="rich-text-container" v-html="$sanitize(dictionary.form.errorExistingAccountSettingsInvalidDisplay)"></div>
        </alert-component>
       
        <select-element v-model="model.executionMoment" :label="dictionary.form.executionMoment" :options="executionMomentTypes" :validations="$v.model.executionMoment" name="executionMomentTypes"></select-element>
        <ExercisesOrderQuantity v-if="!!selectedExerciseScenario" :dictionary="dictionary" :model="model" :quantityType="selectedExerciseScenario.quantityInputType"
                                :validations="$v.model"
                                @nrOrderedDerivatives="model.nrOrderedDerivatives = $event"
                                @quantityAll="model.quantityAll = $event"
                                @quantityAllAndSelectSelect="model.quantityAllAndSelectSelect = $event"
                                @quantitySelection="model.quantityAllAndSelectSelection = $event" />

        <input-element v-if="!hideLimitPrice" type="text" :label="dictionary.form.limitPrice" v-model="model.limitPrice" :validationsList="limitPriceValidations" name="limitPrice" />

        <ExercisesOrderScenarioTypeDetail :dictionary="dictionary" :model="model" :exerciseScenario="selectedExerciseScenario" name="selectedExerciseScenario" />

        <div class="flex-container-button-right mt-4">
            <button v-if="hasRestrictedSecurityAccountSettings" class="btn btn-secondary mr-2" type="button" @click="$router.back()">
                {{ dictionary.form.btnCancel }}
            </button>
          <button class="btn btn-primary" type="submit" :disabled="hasRestrictedSecurityAccountSettings" >{{ dictionary.form.paceOrder }}</button>
        </div>
      </form>
    </div>
</template>

<script>
    import { exercisesOrderFormValidations } from "./exercises-order-form-validations";
    import { EventBus } from "Shared/event-bus";
    import ExercisesOrderQuantity from "./exercises-order-quantity.component.vue";
    import ExercisesOrderScenarioTypeDetail from "./exercises-order-scenario-type-detail.component.vue";
    import FormErrorAlert from "Shared/Components/Vue/Alert/form-error-alert.component.vue";
    import ExercisesDataLabel from "./exercises-data-label.component.vue";

    const createDropDownOption = (disabled, group, selected, text, value) => ({
        disabled: disabled,
        group: group,
        selected: selected,
        text: text,
        value: value
    });

    const getScenario = (scenariosList, exerciseScenarioId) =>
        scenariosList.filter((x) => x.exerciseScenarioId == exerciseScenarioId)[0];

    export default {
        mixins: [exercisesOrderFormValidations],
        components: {
            ExercisesOrderQuantity,
            ExercisesOrderScenarioTypeDetail,
            FormErrorAlert,
            ExercisesDataLabel
        },
        props: {
            dictionary: Object,
            toggles: Object,
            exercisesPositionModel: Object,
            exercisesInstructionModel: Object,
            error: String
        },
        data(){
            return {
                selectedExerciseScenario: null,
                quantityType: '',
                errors: [],
                isLoading: true
            }
        },
        computed: {
            model() {
                return this.exercisesInstructionModel;
            },
            moduleBounderie() {
                return this.exercisesPositionModel.exerciseInstructionOrderViewModel.exerciseInstructionBoundaries;
            },
            hasDerivativeSecurity() {
                return this.exercisesPositionModel.derivativeSecurities && this.exercisesPositionModel.derivativeSecurities.length > 0;
            },
            derivativeSecurity() {
                return this.hasDerivativeSecurity ? this.exercisesPositionModel.derivativeSecurities[0] : null;
            },
            executionMomentTypes(){
                return this.model.exerciseInstructionBoundaries.executionMomentTypes.map( c=> createDropDownOption(false, false, true, c.description, c.value) );
            },
            scenarioTypes(){
                return this.model.exerciseInstructionBoundaries.scenarios.map( c=> createDropDownOption(false, false, true, c.scenarioDescriptionHeader, c.exerciseScenarioId) );
            },
            derivativeSecurityPriceTooltip() {
              return this.hasDerivativeSecurity ? this.dictionary.tooltips.underlyingSecurityPriceTooltip.replace("{0}",  this.$options.filters.toDateTime(this.derivativeSecurity.lastSecurityPriceDate).replace('00:00:00', '')) : "";
            },
            limitPriceValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.required,
                        message: this.dictionary.validation.limitPriceRequired
                    },
                    {
                        id: 2,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.mustBeDecimal,
                        message: this.dictionary.validation.limitPriceInvalid
                    }
                ];
            },
            hideLimitPrice() {
                if (this.model.executionMoment !== this.$ExecutionMomentType.AT_PRICE) {
                    this.model.limitPrice = '';
                    return true;
                }
                return false;
            },
            hasRestrictedSecurityAccountSettings() {
                return this.model.hasRestrictedSecurityAccountSettings && 
                    getScenario(this.model.exerciseInstructionBoundaries.scenarios, this.model.exerciseScenarioId)?.exerciseScenarioType.value === 'detDelivery';
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit("submit", this.model);
            },
            setExecutionMomentIfEmpty() {
                const executionMoment = this.executionMomentTypes.filter(x => x.value === this.formExecutionMoment)[0];
                this.model.executionMoment = executionMoment ? executionMoment.value : this.executionMomentTypes[0].value;
            },
            setScenarioTypeIfEmpty() {
                const scenarioType = this.model.exerciseInstructionBoundaries.scenarios.filter(x => x.exerciseScenarioId === this.model.exerciseScenarioId)[0];
                this.model.exerciseScenarioId = !!scenarioType ? scenarioType.exerciseScenarioId : this.model.exerciseInstructionBoundaries.scenarios[0].exerciseScenarioId;
                this.scenarioChanged();
            },
            scenarioChanged() {
                this.selectedExerciseScenario =  getScenario(
                    this.model.exerciseInstructionBoundaries.scenarios,
                    this.model.exerciseScenarioId
                );
                
                this.quantityType = this.selectedExerciseScenario.quantityInputType;

                if (!!this.selectedExerciseScenario.moneyExternalAccounts) {
                    let moneyExternalAccount = this.selectedExerciseScenario.moneyExternalAccounts.filter(x => x.value === this.model.moneyExternalAccountId)[0];
                    moneyExternalAccount = !!moneyExternalAccount ? moneyExternalAccount : this.selectedExerciseScenario.moneyExternalAccounts[0];
                    this.model.moneyExternalAccountId = !!moneyExternalAccount ? moneyExternalAccount.value : null;
                }
                if(!!this.selectedExerciseScenario.securityExternalAccounts) {
                    let securityExternalAccount = this.selectedExerciseScenario.securityExternalAccounts.filter(x => x.value === this.model.securityExternalAccountId)[0];
                    securityExternalAccount = !!securityExternalAccount ? securityExternalAccount : this.selectedExerciseScenario.securityExternalAccounts[0];
                    this.model.securityExternalAccountId = !!securityExternalAccount ? securityExternalAccount.value : null;
                }
            }
        },
        mounted() {
            this.formExecutionMoment = this.model.executionMoment;
            this.setExecutionMomentIfEmpty();
            this.setScenarioTypeIfEmpty();
        },
    };
</script>
<template>
    <div>
        <div class="beneficiary-details">
            <mfa-toggle v-if="showToggleSMS"  :label="dictionary.beneficiaryLabelMFASMS" :toggleValue="toggleValueSms" :errors="smsErrors" :isDisabled="isSmsDisabled" @on-switch="switchToggleSms"/>
            <mfa-toggle v-if="showToggleTotp" :label="dictionary.beneficiaryLabelMFATOTP" :toggleValue="toggleValueTotp" :errors="totpErrors" :isDisabled="isTotpDisabled" @on-switch="switchToggleTotp"/>
            <div>
                <alert-component v-if="error" variant="info">{{ error }}</alert-component>
            </div>
        </div>
    </div>
</template>

<script>
    import { phoneValidWithPrefixAndLenght } from 'Shared/Components/Vue/Validations/custom-validator'
    import  MfaToggle  from './mfa-toggle.component.vue';

    export default {
        components:{
            MfaToggle
        },
        props: {
            boundaries: Object,
            telephone: String,
            dictionary: Object,
            toggleValue: Boolean,
            mfaBoundariesDataModel:Object
        },
        data() {
            return {
                isWaiting: false,
                smsErrors: [],
                totpErrors: [],
                toggleValueSms:false,
                toggleValueTotp:false,
                error:''
            }
        },
        mounted() {
            this.checkToggles;
            this.checkSmsErrors;
        },
        computed: {
            checkToggles() {
                    if (this.mfaBoundariesDataModel.currentFactorType == 'mftSMS') 
                        this.toggleValueSms = this.toggleValue; 
                    else 
                        this.toggleValueTotp = this.toggleValue;
            },
            checkSmsErrors() {
                if (this.showNoMobileError)
                    this.smsErrors.push(this.dictionary.errorMfaNoMobile);
                if (this.showInvalidMobileError)
                    this.smsErrors.push(this.dictionary.errorMfaInvalidMobile)
                if (this.showNoMobileEditProhibitedError)
                    this.smsErrors.push(this.dictionary.errorMfaNoMobileEditProhibited)
            },
            showToggleSMS() {
                if(this.mfaBoundariesDataModel.availableFactorTypes.length > 0)
                    return this.mfaBoundariesDataModel.availableFactorTypes.some(x => x == 'mftSMS');
                return false;
            },
            showToggleTotp() {
                if(this.mfaBoundariesDataModel.availableFactorTypes.length > 0)
                    return this.mfaBoundariesDataModel.availableFactorTypes.some(x => x == 'mftTOTP');
                return false;
            },
            showNoMobileError() {
                return !this.telephone && this.boundaries.mayEditTelephone;
            },
            showInvalidMobileError() {
                return (!!this.telephone && !phoneValidWithPrefixAndLenght(this.telephone)) && this.boundaries.mayEditTelephone;
            },
            showNoMobileEditProhibitedError() {
                return (!this.telephone || !phoneValidWithPrefixAndLenght(this.telephone)) && !this.boundaries.mayEditTelephone;
            },
            isSmsDisabled() {
                return this.showNoMobileEditProhibitedError || this.showNoMobileError || this.showInvalidMobileError;
            },
            isTotpDisabled() {
                return false;
            }
        },
        methods: {
            switchToggleSms() {
                if (this.toggleValueTotp) {
                        this.error = this.dictionary.beneficiaryErrorMFAMultipleEnabled;
                        return;
                }
                this.error = '';
                this.$emit("on-switch", {toggleValue: !this.toggleValueSms, toggleFactor: 'mftSMS'});
            },
            switchToggleTotp() {
                if (this.toggleValueSms) {
                        this.error = this.dictionary.beneficiaryErrorMFAMultipleEnabled;
                        return;
                }
                this.error = '';
                this.$emit("on-switch", {toggleValue: !this.toggleValueTotp, toggleFactor: 'mftTOTP'});
            }
        }
    }
</script>
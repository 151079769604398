<template>
    <div>
        <button type="button"
                :class="['toggle-button', 'btn-sm', 'btn-toggle', item.isFromToPosition ? 'active' : '']"
                data-toggle="button"
                @click="submitProcessTransactionType(item)"
                :aria-pressed="[item.isFromToPosition ? true : false]">
            <span class="handle"></span>
            <span class="sr-only">{{item.positionName}} {{item.transactionDescription}}</span>
        </button>
    </div>
</template>

<script>
    import { post } from "api";

    export default {
        props: {
            item: Object,
        },
        methods: {
            submitProcessTransactionType(item) {
                post(
                    "/Beneficiaries/ModifyProcessTransactionType",
                    {
                        PositionUsageId: item.positionUsageId,
                        ProcessTransactionTypeId: item.processTransactionTypeId,
                        IsFromToPosition: !item.isFromToPosition,
                    },
                    () => {
                        this.$emit("load-items");
                    },
                    this,
                    () => { },
                    (err) => { }
                );
            }
        }
    };
</script>
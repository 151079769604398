<template>
    <section>
        <modal-basic :title="dictionary.widget.labelHeaderTitle" :is-on="isModalVisible" :ok-footer="false" :centered="true" @close="$emit('close')">
            <SubscriptionMobileRow :label="dictionary.table.moduleName" :value="replaceNullOrEmpty(model.name)" />
            <SubscriptionMobileRow v-if="model.state" :label="dictionary.table.state" :value="replaceNullOrEmpty(model.state.description)" />
            <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(model.securityName)" />
            <SubscriptionMobileRow :label="dictionary.table.grantedDate" :value="model.grantedDate | toDate" />
            <SubscriptionMobileRow :label="dictionary.table.startDate" :value="model.subscriptionDate | toDate" />
            <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(model.securityName)" />
            <SubscriptionMobileRow :label="dictionary.table.subscribed" :value="replaceNullOrEmpty(model.subscribed)" />
            <button class="btn btn-secondary float-right mt-3" @click="$emit('close')">{{ dictionary.widget.btnOk }}</button>
        </modal-basic>
    </section>
</template>

<script>
    import SubscriptionMobileRow from "./Shared/subscription-mobile-row.component.vue";

    export default {
        components: {
            SubscriptionMobileRow
        },
        props: {
            dictionary: Object,
            model: Object,
            isModalVisible: Boolean
        }
    }
</script>
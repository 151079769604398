var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "page-description rich-text-container",
        domProps: {
          innerHTML: _vm._s(
            _vm.$sanitize(_vm.dictionary.table.paymentsPageDescription)
          )
        }
      }),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "show-expand": "",
          "no-data-text": _vm.dictionary.table.noRecordFound,
          "item-key": "moneyFinancialInstructionId",
          "hide-default-header": _vm.isMobile,
          headers: _vm.computedHeaders,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.processingDateTime",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("toDate")(
                          _vm.replaceNullOrEmpty(item.processingDateTime)
                        )
                      )
                    )
                  ])
                ]
              }
            },
            {
              key: "item.description",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.replaceNullOrEmpty(item.description)))
                  ])
                ]
              }
            },
            {
              key: "item.confirmedAmount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.replaceNullOrEmpty(item.confirmedAmountFormatted)
                      )
                    )
                  ])
                ]
              }
            },
            {
              key: "item.unconfirmedAmount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.replaceNullOrEmpty(item.unconfirmedAmountFormatted)
                      )
                    )
                  ])
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  !_vm.isMobile
                    ? _c(
                        "td",
                        {
                          staticClass: "expanded-item-td",
                          attrs: { colspan: headers.length }
                        },
                        [
                          _c("PaymentsTableExpandedItem", {
                            attrs: { dictionary: _vm.dictionary, payment: item }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.data-table-expand",
              fn: function(ref) {
                var item = ref.item
                var isExpanded = ref.isExpanded
                var expand = ref.expand
                return [
                  _c(
                    "v-icon",
                    {
                      class: [
                        "v-data-table__expand-icon",
                        { "v-data-table__expand-icon--active": isExpanded }
                      ],
                      attrs: {
                        "aria-label":
                          item.type +
                          " " +
                          item.reference +
                          " " +
                          (isExpanded ? "collapse" : "expand") +
                          " more details"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return expand(!isExpanded)
                        }
                      }
                    },
                    [_vm._v("$expand")]
                  )
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.headers,
                          dictionary: _vm.datatablesDictionary
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("PaymentDetailsMobileContent", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          payment: item,
                          toggles: _vm.toggles
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="default-account-buttons button-container">
    <div class="default-account-buttons__cancel-button">
      <button class="btn secondaryButton" type="button" @click="$emit('cancel-submit')">
          {{ dictionary.defaultAccountModal.btnCancel }}
      </button>
    </div>
    <div v-if="maySetDefault" class="default-account-buttons__submit-button">
          <button class="btn primaryButton" type="button" :disabled="hasInvalidSettings"  @click="$emit('submit-default-account')">
              {{ dictionary.defaultAccountModal.btnSetAsDefault }}
          </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dictionary: Object,
    maySetDefault: Boolean,
    hasInvalidSettings: Boolean
  },
};
</script>
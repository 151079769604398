var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mt-2 d-md-flex justify-content-md-between button-container summary-buttons-container"
    },
    [
      _c(
        "button",
        {
          staticClass: "btn secondaryButton",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("skip")
            }
          }
        },
        [_vm._v(_vm._s(_vm.dictionary.buttons.skip))]
      ),
      _vm._v(" "),
      _vm.isSubmitButton
        ? _c(
            "button",
            {
              staticClass: "btn primaryButton",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.dictionary.buttons.submit))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditButton
        ? _c(
            "button",
            {
              staticClass: "btn primaryButton",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.dictionary.buttons.edit))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "reports-container" } },
    [
      _c("p", [_vm._v(_vm._s(_vm.dictionary.page.description))]),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.submitting } }),
      _vm._v(" "),
      _vm.success
        ? _c("alert-component", { attrs: { variant: "success" } }, [
            _vm._v(_vm._s(_vm.success))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.anyCouponReportAvailable &&
      !_vm.anyAnnualReportAvailable &&
      !_vm.anyReportPortfolioAvailable
        ? _c("alert-component", { ref: "no-reports-message" }, [
            _vm._v(_vm._s(_vm.dictionary.page.noReportsMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.boundaries.canRequestBeneficiaryYearResultsReport &&
      _vm.yearsListAvailable
        ? [
            _vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "accordion report-accordion",
                    attrs: { role: "tablist" }
                  },
                  [
                    _c("ReportsAnnual", {
                      attrs: {
                        boundaries: _vm.boundaries,
                        dictionary: _vm.dictionary,
                        toggles: _vm.toggles,
                        "is-mobile": _vm.isMobile
                      },
                      on: {
                        getAnnualStatementReport: _vm.getAnnualStatementReport
                      }
                    })
                  ],
                  1
                )
              : _c("ReportsAnnual", {
                  attrs: {
                    boundaries: _vm.boundaries,
                    dictionary: _vm.dictionary,
                    toggles: _vm.toggles,
                    "is-mobile": _vm.isMobile
                  },
                  on: { getAnnualStatementReport: _vm.getAnnualStatementReport }
                })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.boundaries.canRequestBeneficiaryQuarterlyReport &&
      _vm.yearsListAvailable
        ? [
            _vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "accordion report-accordion",
                    attrs: { role: "tablist" }
                  },
                  [
                    _c("ReportsQuarterly", {
                      attrs: {
                        boundaries: _vm.boundaries,
                        dictionary: _vm.dictionary,
                        toggles: _vm.toggles,
                        "is-mobile": _vm.isMobile
                      },
                      on: {
                        getQuarterlyStatementReport:
                          _vm.getQuarterlyStatementReport
                      }
                    })
                  ],
                  1
                )
              : _c("ReportsQuarterly", {
                  attrs: {
                    boundaries: _vm.boundaries,
                    dictionary: _vm.dictionary,
                    toggles: _vm.toggles,
                    "is-mobile": _vm.isMobile
                  },
                  on: {
                    getQuarterlyStatementReport: _vm.getQuarterlyStatementReport
                  }
                })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.boundaries.canRequestCouponPaymentsReport
        ? [
            _vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "accordion report-accordion",
                    attrs: { role: "tablist" }
                  },
                  [
                    _c("ReportsCoupon", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        toggles: _vm.toggles,
                        "is-mobile": _vm.isMobile
                      },
                      on: { getCoupon: _vm.getCoupon }
                    })
                  ],
                  1
                )
              : _c("ReportsCoupon", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    toggles: _vm.toggles,
                    "is-mobile": _vm.isMobile
                  },
                  on: { getCoupon: _vm.getCoupon }
                })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.boundaries.canRequestPortfolioOverviewReport
        ? [
            _vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "accordion report-accordion",
                    attrs: { role: "tablist" }
                  },
                  [
                    _c("ReportsPortfolio", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        toggles: _vm.toggles,
                        "is-mobile": _vm.isMobile
                      }
                    })
                  ],
                  1
                )
              : _c("ReportsPortfolio", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    toggles: _vm.toggles,
                    "is-mobile": _vm.isMobile
                  }
                })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-pac" },
    [
      !_vm.mfaData && !_vm.skipConfirmation
        ? [
            _c(
              "MfaConfirmation",
              _vm._g(
                {
                  attrs: {
                    dictionary: _vm.dictionary,
                    message: _vm.message,
                    phone: _vm.phone,
                    error: _vm.error
                  },
                  on: { "in-loading": _vm.inLoading }
                },
                _vm.$listeners
              )
            )
          ]
        : [
            _c(
              "MfaPacCode",
              _vm._g(
                {
                  attrs: {
                    dictionary: _vm.dictionary,
                    message: _vm.message,
                    mfaData: _vm.mfaData
                  },
                  on: { "in-loading": _vm.inLoading },
                  model: {
                    value: _vm.pacData,
                    callback: function($$v) {
                      _vm.pacData = $$v
                    },
                    expression: "pacData"
                  }
                },
                _vm.$listeners
              )
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
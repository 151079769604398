var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.dashboardData
    ? _c(
        "portlet-layout",
        { staticClass: "w-100", attrs: { title: _vm.welcomeText } },
        [
          _c("template", { slot: "body" }, [
            _c("div", {
              staticClass: "rich-text-container welcome",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(_vm.dictionary.widgets.welcomeText)
                )
              }
            })
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
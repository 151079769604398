<template>
  <div>
    <h2>{{dictionary.editAccount.heading}}</h2>
    <component-spinner :spin="isLoading"></component-spinner>
    <loading-spinner :active="submitingData"></loading-spinner>
    <alert-component v-if="bankAccount.hasInvalidSettings && !isLoading" variant="error">
      <div v-html="$sanitize(financialDictionary.bankAccount.security.errorExistingAccountSettingsInvalidDisplay)" class="rich-text-container"></div>
    </alert-component>
    <timeline-container>
      <timeline-step v-if="complianceExternalAccount && externalAccountBoundaries" :currentStep="currentStep" :step="1">
        <FinancialAddAccountStepAccountType :current-step="currentStep"
                                            :account-type="accountType"
                                            :dictionary="dictionary.addAccount"
                                            :validation-dictionary="financialDictionary.validations"
                                            :currencies="listCurrencies"
                                            :countries="listCountries" 
                                            :external-account-types="listExternalAccountTypes"
                                            :relationship-types="listRelationshipTypes"
                                            :account-type-data="complianceExternalAccount.accountType"
                                            :toggles="financialToggles"
                                            :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                            :showStep3="showStep3"
                                            :externalAccountTypeDefaultsToSecurity="bankAccount.externalAccountTypeDefaultsToSecurity"
                                            @changed-external-account-type="changedExternalAccountType()"
                                            @next-step="nextStep" 
                                            @cancel-add-account="cancelEditAccount()" 
                                            @update-boundaries="updateAccountBoundaries"
                                            @edit-step="editStep" 
                                            class="timeline-step">
        </FinancialAddAccountStepAccountType>
      </timeline-step>

      <timeline-step v-if="complianceExternalAccount && externalAccountBoundaries" :currentStep="currentStep" :step="2">
        <FinancialAddAccountStepAccountHolder :account-type="accountType" 
                                              :current-step="currentStep"
                                              :dictionary="dictionary.addAccount" 
                                              :validation-dictionary="financialDictionary.validations"
                                              :countries="listCountries"
                                              :account-holder-data="complianceExternalAccount.accountHolder"
                                              :is-account-holder-name-and-city-mandatory="isAccountHolderNameAndCityMandatory" 
                                              :toggles="financialToggles"
                                              :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                              :messageInSecurityAccountHolderStep="messageInSecurityAccountHolderStep"
                                              :showStep3="showStep3"
                                              @next-step="nextStep" 
                                              @previous-step="previousStep" 
                                              @cancel-add-account="cancelEditAccount()"
                                              @set-compliance-external-account="setComplianceExternalAccount"
                                              @edit-step="editStep" 
                                              class="timeline-step">
        </FinancialAddAccountStepAccountHolder>
      </timeline-step>

      <timeline-step v-if="showStep3 && complianceExternalAccount && externalAccountBoundaries" :currentStep="currentStep" :step="3">
        <FinancialAddAccountStepBankAccount :isEdit="true" 
                                            :editingExistingAccount="true" 
                                            :account-type="accountType"
                                            :current-step="currentStep"
                                            :dictionary="dictionary.addAccount"
                                            :validation-dictionary="financialDictionary.validations" 
                                            :countries="listCountries"
                                            :external-account-boundaries="externalAccountBoundaries"
                                            :compliance-external-account="complianceExternalAccount"
                                            :bank-account-data="complianceExternalAccount.bankAccount"
                                            :central-securities-depository-types="listCentralSecuritiesDepositoryTypes"
                                            :errors="errors"
                                            :bank-country-data="bankCountry" 
                                            :toggles="financialToggles"
                                            :securityAccountDisplaySettings="securityAccountDisplaySettings"
                                            :showStep3="showStep3"
                                            @previous-step="previousStep" 
                                            @next-step="nextStep"
                                            @cancel-add-account="cancelEditAccount()" 
                                            @set-compliance-external-account="setComplianceExternalAccount"
                                            class="timeline-step">
        </FinancialAddAccountStepBankAccount>
      </timeline-step>
    </timeline-container>
  </div>
</template>

<script>
import { getAll, post } from "api";
import FinancialAddAccountStepAccountType from "./financial-add-account-step-account-type.component.vue";
import FinancialAddAccountStepAccountHolder from "./financial-add-account-step-account-holder.component.vue";
import FinancialAddAccountStepBankAccount from "./financial-add-account-step-bank-account.component.vue";
import { addEditStepsLogic } from "./financial-add-edit-steps-logic.mixin.js";

export default {
  mixins: [addEditStepsLogic],
  components: {
    FinancialAddAccountStepAccountType,
    FinancialAddAccountStepAccountHolder,
    FinancialAddAccountStepBankAccount,
  },
  props: {
    dictionary: Object,
    financialDictionary: Object,
    beneficiaryBoundariesData: Object,
    accountType: String,
    bankAccount: Object,
    financialToggles: Object 
  },
  data() {
    return {
      steps: 4,
      centralSecuritiesDepositoryTypes: Array,
      isAccountHolderNameAndCityMandatory: true,
      securityAccountDisplaySettings: null
    };
  },
  created() {
    getAll(
      [
        "/Common/GetCurrencies",
        "/Common/GetCountries",
        `/Beneficiaries/GetExternalAccountAsync?accountId=${this.bankAccount.id}`,
        '/Beneficiaries/GetSecurityAccountDisplaySettings'
      ],
      (responses) => {
        this.currencies = responses[0].data;
        this.countries = responses[1].data;
        this.complianceExternalAccount = responses[2].data;
        this.centralSecuritiesDepositoryTypes = this.complianceExternalAccount.centralSecuritiesDepositoryTypes;
        this.bankCountry = this.countries.filter((c) => c.id == this.complianceExternalAccount.accountType.bankCountryId)[0];
        this.securityAccountDisplaySettings = responses[3].data;
        let currencyId = this.complianceExternalAccount?.currencyId;
        
        if (this.securityAccountDisplaySettings.allowUKShareTransferSettingsOnly) {
          currencyId = this.currencies.filter(x => x.externalIdentifier == "GBP")[0]?.id;
        } 

        const postData = {
          externalAccountType: this.complianceExternalAccount?.id,
          complianceExternalAccountId: this.bankAccount.id,
          beneficiaryExternalAccountType: this.complianceExternalAccount?.beneficiaryExternalAccountType.value,
          currencyId: currencyId,
          bankCountryId: this.complianceExternalAccount?.bankCountryId,
        };
        
        this.postAccountBoundaries(postData);
        this.displayBankAccountStep();
        this.displayMessageInSecurityAccountHolderStep(this.dictionary);
      },
      this
    );
  },
  computed: {
    isMoneyAccount() {
      return this.accountType === this.$BankAccountType.MONEY;
    },
    isSecurityAccount() {
      return this.accountType === this.$BankAccountType.SECURITY;
    }
  },
  methods: {
    cancelEditAccount() {
      this.$emit("cancel-edit-account");
    },
    editStep(payLoad) {
      this.currentStep = payLoad.step;
    },
    setComplianceExternalAccount(payLoad) {
      switch (payLoad.step) {
        case 1:
          this.complianceExternalAccount.accountType = payLoad.data;
          break;
        case 2:
          this.complianceExternalAccount.accountHolder = payLoad.data;
          break;
        case 3: {
          if (this.showStep3)
            this.complianceExternalAccount.bankAccount = payLoad.data;
          break;
        }
        default:
          break;
      }
      if ((this.currentStep === 4 && payLoad.step === 4) || (payLoad.submit && !this.showStep3)) {

        if ((payLoad.submit && !this.showStep3))
          this.complianceExternalAccount.bankAccount = null;

        this.updateExternalAccount();
      } 
    },
    updateExternalAccount() {
      this.submitingData = true;
      this.errors = [];

      post(
        `/Beneficiaries/UpdateExternalAccount?accountId=${this.bankAccount.id}`,
        this.complianceExternalAccount,
        (response) => {
          this.$emit("edit-account-completed", {
            accountType: this.accountType,
          });
        },
        this,
        () => {
          this.submitingData = false;
        },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            this.errors = err.response.data;
          } else {
            this.errors.push(err.response.data);
          }
        }
      );
    },
    postAccountBoundaries(postData) {
      post(
        "/Beneficiaries/ExternalAccountBoundaries",
        postData,
        (response) => {
          this.externalAccountBoundaries = response.data;
          this.externalAccountTypes = response.data.beneficiaryExternalAccountTypes;
          this.relationshipTypes = response.data.beneficiaryRelationshipTypes;
          this.bankCountry = this.countries.filter((c) => c.id == this.complianceExternalAccount.accountType.bankCountryId)[0];
          this.complianceExternalAccount.bankAccount.accountAddress ??= {};
          this.complianceExternalAccount.bankAccount.accountAddress.countryId = this.bankCountry.id;
          this.complianceExternalAccount.bankAccount.accountAddress.country = this.bankCountry.name;
          if (this.isMoneyAccount) {
            if (this.externalAccountBoundaries.requiresIBAN) {
              this.complianceExternalAccount.bankAccount.accountNumber = '';
            } else {
              this.complianceExternalAccount.bankAccount.iban = '';
            }

            if (!this.externalAccountBoundaries.requiresRoutingCode && !this.externalAccountBoundaries.requiresBICOrRoutingCode ) {
              this.complianceExternalAccount.bankAccount.sortCode = '';
            }

            if (!this.externalAccountBoundaries.requiresIntermediaryBIC) {
              this.complianceExternalAccount.bankAccount.intermediaryBIC = '';
            }
          }

          this.isLoading = false;
        },
        this,
        () => { },
        (err) => {
          if (
            err.response.status === this.$HttpStatusCodes.BAD_REQUEST &&
            Array.isArray(err.response.data)
          ) {
            this.errors = err.response.data;
          } else {
            this.errors.push(err.response.data);
          }
        }
      );
    },
    updateAccountBoundaries(accountType) {
      const postData = {
        externalAccountType: this.complianceExternalAccount?.id,
        complianceExternalAccountId: this.bankAccount.id,
        beneficiaryExternalAccountType: this.complianceExternalAccount?.beneficiaryExternalAccountType.value,
        currencyId: accountType.currencyId,
        bankCountryId: accountType.bankCountryId,
      };

      this.postAccountBoundaries(postData);
    },
    changedExternalAccountType() {
      this.displayBankAccountStep();
      this.displayMessageInSecurityAccountHolderStep(this.dictionary);
      if (this.isSecurityAccount) {
        const externalAccountType = this.complianceExternalAccount.accountType.externalAccountType;
        if (externalAccountType === this.securityService || externalAccountType === this.securityCustodian) {
          this.isAccountHolderNameAndCityMandatory = false;
        } else {
          this.isAccountHolderNameAndCityMandatory = true;
        }
      }
    }
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isQuantityShown
        ? _c("input-element", {
            attrs: {
              name: "NrOrderedDerivatives",
              label: _vm.dictionary.form.quantity,
              type: "text",
              value: _vm.model.nrOrderedDerivatives,
              validationsList: _vm.nrOrderedDerivativesValidations
            },
            on: {
              input: function($event) {
                return _vm.$emit("nrOrderedDerivatives", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.quantityType === _vm.$QuantityInputType.ALL
        ? _c("exercises-data-label", {
            attrs: {
              label: _vm.dictionary.form.quantity,
              value: _vm.model.quantityAll
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.quantityType === _vm.$QuantityInputType.ALL_AND_SELECT
        ? [
            _c(
              "fieldset",
              [
                _c("legend", { staticClass: "form-input-label label" }, [
                  _vm._v(_vm._s(_vm.dictionary.form.quantity))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c(
                      "div",
                      { staticClass: "form-check form-check-inline pl-2 pr-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.quantitySelectionValue,
                              expression: "quantitySelectionValue"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "radio",
                            id: "quantitySelectionValue"
                          },
                          domProps: {
                            value: _vm.$QuantityInputType.ALL,
                            checked: _vm._q(
                              _vm.quantitySelectionValue,
                              _vm.$QuantityInputType.ALL
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.quantitySelectionValue =
                                _vm.$QuantityInputType.ALL
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "mb-0 form-input-label",
                        attrs: { for: "quantitySelectionValue" }
                      },
                      [_vm._v(_vm._s(_vm.quantityLabel))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "input-element",
                  {
                    staticClass: "rounded",
                    attrs: {
                      type: "text",
                      value: _vm.model.quantityAllAndSelectSelect,
                      disabled: _vm.equalsQuantity(_vm.$QuantityInputType.ALL),
                      validationsList: _vm.quantityAllAndSelectSelect,
                      labelClasses: ["sr-only"],
                      label: _vm.dictionary.form.quantity,
                      name: "quantityAllAndSelectSelect"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("quantityAllAndSelectSelect", $event)
                      }
                    }
                  },
                  [
                    _c("template", { slot: "group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-check form-check-inline pl-2 pr-2"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quantitySelectionValue,
                                expression: "quantitySelectionValue"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "radio",
                              id: "quantitySelectionValue"
                            },
                            domProps: {
                              value: _vm.$QuantityInputType.SELECT,
                              checked: _vm._q(
                                _vm.quantitySelectionValue,
                                _vm.$QuantityInputType.SELECT
                              )
                            },
                            on: {
                              change: function($event) {
                                _vm.quantitySelectionValue =
                                  _vm.$QuantityInputType.SELECT
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "sr-only form-input-label",
                              attrs: { for: "quantitySelectionValue" }
                            },
                            [_vm._v(_vm._s(_vm.dictionary.form.quantity))]
                          )
                        ]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="email-header">
        <div class="email-header__subject row">
            <div v-if="isMobileOrTablet" class="col-1 back-button" @click="$emit('back-click', false)">
                <h2>
                    <i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>
                </h2>
            </div>
            <div class="col-10">
                <h2>{{message.subject}}</h2>
                <div class="email-header__subject__date">
                    <span>{{message.creationDate}}</span>
                </div>
            </div>
            <!-- Not a part of #1015 - leaving for the follow up story -->
            <!-- <div class="col-2">
                <a class="email-header__subject__print">
                    <i class="email-header__subject__print__icon fas fa-print fa-2x"></i>
                </a>                
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: Object,
        isMobileOrTablet:Boolean
    }
}
</script>
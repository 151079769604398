var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.moduleBounderie.isInstructionInMoney
        ? _c("input-element", {
            attrs: {
              name: "AmountOrdered",
              type: "text",
              label: _vm.dictionary.form.amountOrdered,
              value: _vm.model.amountOrdered,
              validationsList: _vm.amountValidations
            },
            on: {
              input: function($event) {
                return _vm.$emit("amountOrdered", $event)
              }
            }
          })
        : _c("input-element", {
            attrs: {
              name: "Quantity",
              label: _vm.dictionary.form.quantity,
              type: "text",
              value: _vm.model.nrOrdered,
              validationsList: _vm.quantityValidations
            },
            on: {
              input: function($event) {
                return _vm.$emit("nrOrdered", $event)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
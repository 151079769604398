<template>
    <p>* {{tooltipText}}</p>
</template>

<script>
export default {
    props: {
        tooltipText: String
    }
}
</script>

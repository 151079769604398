<template>
    <div class="evaluation-required-documents">
      <RequiredDocumentsTabContainer :dictionary="dictionary" :datatables-dictionary="dictionary.table" />
         <div class="rich-text-container" v-html="$sanitize(dictionary.labels.textUploadRequiredDocuments)"></div>
    </div>
</template>

<script>
import RequiredDocumentsTabContainer from "Features/RequiredDocuments/Assets/Vue/required-documents-tab-container.component.vue";

export default {
  components: {
    RequiredDocumentsTabContainer
  },
  props: {
    dictionary: Object,
    datatablesDictionary: Object,
  }
};
</script>
<template>
    <div>
        <h2>{{ dictionary.widget.titleOpen }}</h2>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-if="!isLoading && tableHasNoRecords" class="empty-dataTable">
            <span>{{ dictionary.table.recordNotFoundEmissions }}</span>
        </div>
        <v-data-table v-show="rows.length > 0 || isSearchDirty" class="table-striped"
                      :no-data-text="dictionary.table.recordNotFoundEmissions"
                      item-key="participationSubscriptionId"
                      :hide-default-header="isMobile"
                      :headers="customHeaders"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer                      
                        > 

            <template v-slot:top>
                <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

            <template v-slot:[`item.custom-button`]="{ item }">
                <button class="btn btn-primary" @click="subscribe(item.participationSubscriptionId)">{{ dictionary.widget.buttonSubscribe }}</button>
            </template>

            <template v-slot:[`item.name`]="{ item }">
                <SubscriptionNameLabel :name="item.name" :planName="item.planName" />
            </template>

            <template v-slot:[`item.securityName`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.securityName) }}</span>
            </template>

            <template v-slot:[`item.granted`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.granted) }}</span>
            </template>

            <template v-slot:[`item.subscribed`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.subscribed) }}</span>
            </template>

            <template v-slot:[`item.subscriptionPeriodStartDate`]="{ item }">
                <span>{{ item.subscriptionPeriodStartDate | toDate }}</span>
            </template>

            <template v-slot:[`item.subscriptionPeriodEndDate`]="{ item }">
                <span>{{ item.subscriptionPeriodEndDate | toDate }}</span>
            </template>

            <template v-if="isMobile" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="customHeaders"
                                         v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
            </template>

            <template v-if="isMobile" v-slot:item="{ item }">
                <SubscriptionMobileContent :dictionary="dictionary">
                    <template v-slot:header>
                        <SubscriptionNameLabel :name="item.name" :planName="item.planName" />
                    </template>
                    <template v-slot:content>
                        <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(item.securityName)" />
                        <SubscriptionMobileRow v-if="!toggles.hideEmissionsGrantedColumn" :label="dictionary.table.granted" :value="replaceNullOrEmpty(item.granted)" />
                        <SubscriptionMobileRow :label="dictionary.table.emissionPrice" :value="replaceNullOrEmpty(item.subscribed)" />
                        <SubscriptionMobileRow :label="dictionary.table.startDate" :value="item.subscriptionPeriodStartDate | toDate" />
                        <SubscriptionMobileRow :label="dictionary.table.endDate" :value="item.subscriptionPeriodEndDate | toDate" />
                        <button class="btn primaryButton" @click="subscribe(item.participationSubscriptionId)">{{ dictionary.widget.buttonSubscribe }}</button>
                    </template>
                </SubscriptionMobileContent>
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import SubscriptionMobileContent from "/Features/Subscriptions/Assets/Vue/Shared/subscription-mobile-content.component.vue";
    import SubscriptionNameLabel from "/Features/Subscriptions/Assets/Vue/Shared/subscription-name-label.component.vue";
    import SubscriptionMobileRow from "/Features/Subscriptions/Assets/Vue/Shared/subscription-mobile-row.component.vue";

    export default {
        mixins: [vueDataTableMixin, ScreenSizeMixin],
        components: {
            SubscriptionMobileContent,
            SubscriptionNameLabel,
            SubscriptionMobileRow
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            toggles: Object
        },
        data() {
            return {
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                getUrl: '/offerings/open',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["subscriptionPeriodEndDate"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]   
                },
                headers: [
                    { value: 'custom-button', text: this.dictionary.table.subscribed, align: 'left', sortable: false, visible: true, width: '3rem' },
                    { value: 'name', text: this.dictionary.table.subscriptionName, align: 'left', sortable: true, visible: true },
                    { value: 'securityName', text: this.dictionary.table.securityName, align: 'left', sortable: true, visible: true, className: 'force-nowrap', },
                    { value: 'granted', text: this.dictionary.table.granted, align: 'right', sortable: true, visible: !this.toggles.hideEmissionsGrantedColumn },
                    { value: 'subscribed', text: this.dictionary.table.emissionPrice, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" },
                    { value: 'subscriptionPeriodStartDate', text: this.dictionary.table.startDate, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" },
                    { value: 'subscriptionPeriodEndDate', text: this.dictionary.table.endDate, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" }
                ],
                rows: [],
                totalRecords: 0
            }
        },
        computed: {
            customHeaders() {
                return this.headers.filter(h => h.visible);
            },
            sortableColumns() {
                return this.customHeaders.filter(x => x.sortable);
            },
            tableHasNoRecords() {
                return this.rows.length == 0 && !this.isSearchDirty;
            }
        },
        methods: {
            subscribe(participationSubscriptionId) {
                this.$router.push({
                    path: "/offerings/subscribe",
                    query: {
                        participationSubscriptionId: participationSubscriptionId
                    }
                });
            }
        }
    }
</script>
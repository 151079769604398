var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary multi-position-exit-button",
          class: { mobile: _vm.isMobile },
          on: {
            click: function($event) {
              return _vm.$emit("clicked")
            }
          }
        },
        [_vm._v(_vm._s(_vm.buttonText))]
      ),
      _vm._v(" "),
      _c(
        "modal-basic",
        {
          attrs: {
            title: _vm.title,
            "is-on": _vm.showModal,
            isLoading: _vm.isLoading
          },
          on: {
            close: function($event) {
              return _vm.closeModal()
            }
          }
        },
        [
          [
            _c("ExitOrderForm", {
              attrs: {
                dictionary: _vm.exitsDictionary,
                isModal: true,
                isMultiExit: true,
                positionName: _vm.positionName,
                exitOrderModel: _vm.exitOrderModel
              },
              on: {
                "form-closed": function($event) {
                  return _vm.closeModal()
                }
              }
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
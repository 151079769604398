<template>
    <div class="d-md-flex justify-content-md-between button-container">
        <div>
            <button  class="btn secondaryButton" @click="declineInstruction">{{ dictionary.btnDecline }}</button>
        </div>
        <div v-if="model.hasRealTimeDealingQuote">
            <button class="btn secondaryButton" :disabled="!isQuoteExpires" @click="requoteInstruction">{{ dictionary.btnRequote }}</button>
        </div>
        <div>
            <button  class="btn primaryButton ml-0" :disabled="isQuoteExpires || !isDisclaimersAccepted" @click="acceptInstruction" :title="confirmButtonTitle">{{ dictionary.btnConfirm }}</button>
        </div>
    </div>
</template>

<script>
    import { get } from 'api';

    export default {
        components: {},
        props: {
            exitInstructionViewModel: Object,
            isQuoteExpires: Boolean
        },
        data() {
            return {
                dictionary: {}
            };
        },
        computed: {
            model() {
                return this.exitInstructionViewModel;
            },
            isDisclaimersAccepted() {
                return !this.model.disclaimers.filter(d => !d.confirmed).length;
            },
            confirmButtonTitle() {
                return this.isDisclaimersAccepted ? "" : this.dictionary.btnConfirmTitleAcceptDisclaimer;
            }
        },
        methods: {
            acceptInstruction() {
                this.$emit('accept-instruction');
            },
            requoteInstruction() {
                this.$emit('requote-instruction');
            },
            declineInstruction() {
                this.$emit('decline-instruction');
            }
        },
        beforeCreate() {
            get("/Common/Dictionary", (response) => this.dictionary = response.data, this);
        }
    };
</script>



var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPlanHeader
    ? _c(
        "div",
        {
          staticClass: "portfolio-plan-header-values",
          class: { "mt-2": _vm.isMobile }
        },
        [
          _c("ul", [
            _vm.showSecurityPrice
              ? _c(
                  "li",
                  {
                    staticClass: "plan-value-container",
                    class: { mobile: _vm.isMobile }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.dictionary.block.planHeaderSecurityPriceLabel
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "portfolio-plan-header-value" }, [
                      _vm._v(_vm._s(_vm.portfolioPlan.securityPriceFormatted))
                    ]),
                    _vm._v(" "),
                    _c("PortfolioTooltip", {
                      staticClass: "hideInMobile plan-value-tooltip",
                      attrs: {
                        hideTooltip:
                          _vm.toggles.hideSecurityPriceInformationTooltip,
                        placement: "top",
                        icon: "far fa-info-circle",
                        "tooltip-text": _vm.tooltipSecurityPrice
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPlanValue
              ? _c(
                  "li",
                  {
                    staticClass: "plan-value-container",
                    class: { mobile: _vm.isMobile }
                  },
                  [
                    _vm.portfolioPlan.values.length > 1
                      ? [
                          _c(
                            "label",
                            {
                              staticClass: "form-input-label",
                              attrs: { for: _vm.uniqueId }
                            },
                            [_vm._v(_vm._s(_vm.dictionary.table.value))]
                          ),
                          _vm._v(" "),
                          _c("b-select", {
                            staticClass: "value-block-select",
                            attrs: {
                              id: _vm.uniqueId,
                              options: _vm.portfolioPlan.values,
                              "text-field": "valueFormatted",
                              "value-field": "value"
                            },
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ]
                      : [
                          _c("span", [
                            _vm._v(_vm._s(_vm.dictionary.table.value) + ":")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "portfolio-plan-header-value" },
                            [_vm._v(_vm._s(_vm.planValue))]
                          )
                        ]
                  ],
                  2
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="d-md-flex justify-content-md-between button-container">
        <div>
            <button class="btn secondaryButton" @click="declineInstruction">{{ dictionary.btnGoBack }}</button>
        </div>
        <div>
            <button v-if="model.canInstruct" class="btn primaryButton ml-0" :disabled="!canSubmitInstruction" @click="acceptInstruction" :title="confirmButtonTitle">{{ dictionary.btnConfirm }}</button>
        </div>
    </div>
</template>

<script>
import { get } from "api";

export default {
  props: {
    choiceInstructionModel: Object,
    validation: Object
  },
  data() {
    return {
      dictionary: {},
      enableSubmitButton: false
    };
  },
  computed: {
    model() {
      return this.choiceInstructionModel;
    },
    canSubmitInstruction() {
      return (
        !this.validation.$invalid && this.model.scenarios.filter(s=> s.isSelected).length > 0 &&
        this.model.canInstruct &&
        this.model.disclaimers.every((x) => x.confirmed)
      );
    }, 
    confirmButtonTitle() {
      return this.canSubmitInstruction
        ? ""
        : this.dictionary.btnConfirmTitleAcceptDisclaimer;
    },
    hasOneScenario() {
        return (
          this.choiceInstructionModel.scenarios.length  == 1 || 
          (this.choiceInstructionModel.maximumNrEnteredScenarios == 1 && 
            this.choiceInstructionModel.minimalNrEnteredScenarios == 1)
        );
      },    
  },
  methods: {
    acceptInstruction() {
      this.$emit("accept-instruction", this.model);
    },
    declineInstruction() {
      this.$emit("decline-instruction");
    },
  },
  beforeCreate() {
    get(
      "/Common/Dictionary",
      (response) => (this.dictionary = response.data),
      this
    );
  },
   watch: {
    'validation.$invalid': {
        handler: function (newValue, oldValue) {
          this.enableSubmitButton = newValue ? false : true
        },
        deep: true,
        immediate:true
    },
    'choiceInstructionModel.scenarios': {
        handler: function (scenariosNew, oldValue) {
          this.enableSubmitButton = !this.validation.$invalid && scenariosNew.filter(s=> s.isSelected).length > 0
        },
        deep: true,
        immediate:true
    }
  }
};
</script>
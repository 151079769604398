var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dividend-expanded-content" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-lg-4" },
        [
          _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.state,
              value: _vm.dividend.state.description
            }
          }),
          _vm._v(" "),
          _vm.isTablet
            ? _c("DividendDataLabelRow", {
                attrs: {
                  label: _vm.dictionary.table.grossAmount,
                  value: _vm.dividend.grossAmountFormatted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isTablet
            ? _c("DividendDataLabelRow", {
                attrs: {
                  label: _vm.dictionary.table.tax,
                  value: _vm.dividend.taxAmountFormatted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isTablet
            ? _c("DividendDataLabelRow", {
                attrs: {
                  label: _vm.dictionary.table.netAmount,
                  value: _vm.dividend.netAmountFormatted
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6 col-lg-4" },
        [
          _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.dividendType,
              value: _vm.dividendTypeTitle
            }
          }),
          _vm._v(" "),
          _vm.showQuantityPerShare
            ? _c("DividendDataLabelRow", {
                attrs: {
                  label: _vm.dictionary.table.quantityPerShare,
                  value: _vm.quantityPerShare
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAmountPerShare
            ? _c("DividendDataLabelRow", {
                attrs: {
                  label: _vm.dictionary.table.amountPerShare,
                  value: _vm.amountPerShare
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
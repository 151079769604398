var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("div", { staticClass: "disclaimer-box" }, [
        _c("div", {
          staticClass: "rich-text-container",
          domProps: {
            innerHTML: _vm._s(
              _vm.$sanitize(_vm.dictionary.labels.disclaimerText)
            )
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "button-section" }, [
          _c(
            "button",
            { staticClass: "btn primaryButton", on: { click: _vm.goBack } },
            [_vm._v(_vm._s(_vm.dictionary.labels.btnGoBack))]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const questionnaireMethods = {
    methods: {
        invalidAnswer(question) {
            if (question.questionType == this.$QuestionType.StandardChoices) {
                return this.correctAnswerMode && question.givenAnswer != question.correctAnswer;
            }
            else if (question.questionType == this.$QuestionType.DefinedChoices) {
                return this.correctAnswerMode && !question.choices.find(c => c.questionChoiceId == question.givenChoiceId)?.isCorrectAnswer
            }
        },
        isAnyInvalidAnswer() {
            return !!this.questionnaireOverview.questions.filter(question => this.invalidAnswer(question)).length;
        }
    }
}
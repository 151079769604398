<template>
<portlet-layout :hideTitle="true" >
    <template v-slot:body>
        <div v-if="displayDividendSubscriptions" id="dividend-body-dividend-subscriptions-table" class="dividend-body">
            <h2>{{dictionary.subscriptionWidgets.titleDividendSubscription}}</h2>
            <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.dividendPageSubscriptionDescription)"></div>
            <DividendSubscriptionsTable :dictionary="dictionary" :datatablesDictionary="datatablesDictionary" />
        </div>
        <div id="dividend-body-dividend-table" class="dividend-body">
            <h2>{{dictionary.widgets.particiaptions}}</h2>
            <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.dividendPageDescription)"></div>
            <DividendTable :dictionary="dictionary" :toggles="toggles" :datatablesDictionary="datatablesDictionary" />
        </div>
    </template>
</portlet-layout>
</template>

<script>
import DividendTable from './dividend-table.component.vue';
import DividendSubscriptionsTable from './dividend-subscriptions-table.component.vue';

export default {
    components: {
        DividendTable,
        DividendSubscriptionsTable,
    },
    props: {
        dictionary: Object,
        toggles: Object,
        datatablesDictionary: Object,
        displayDividendSubscriptions: Boolean
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.dictionary.widgets && !!_vm.ordersDictionary
    ? _c(
        "portlet-layout",
        {
          staticClass: "w-100",
          attrs: { title: _vm.dictionary.widgets.lastOrders }
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c("last-orders", { attrs: { toggles: _vm.toggles } }),
              _vm._v(" "),
              _c("order-detail", {
                attrs: {
                  title: _vm.ordersDictionary.modal.headerTitle,
                  toggles: _vm.toggles
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footerLink" },
            [
              _c(
                "router-link",
                {
                  staticClass: "portlet-title-see-all-link",
                  attrs: {
                    to: "/orders",
                    title: _vm.dictionary.widgets.seeAllOrders
                  }
                },
                [_vm._v(_vm._s(_vm.dictionary.widgets.seeAllOrders))]
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "listed-trading-table" },
    [
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "no-data-text": _vm.dictionary.table.datatables.noRecordFound,
          "item-key": "positionUsageId",
          "hide-default-header": _vm.isMobile,
          headers: _vm.customHeaders,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: {
                      label: _vm.dictionary.table.datatables.searchByKeywords
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.custom-button",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("PositionActionButtons", {
                    staticClass: "hideInMobile action-buttons-table-cell",
                    attrs: {
                      position: item,
                      dictionary: _vm.dictionary,
                      type: "listed-trading"
                    }
                  })
                ]
              }
            },
            {
              key: "item.positionName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("ListedTradingTablePositionActionLink", {
                    attrs: { position: item }
                  })
                ]
              }
            },
            {
              key: "item.buyPriceFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.replaceNullOrEmpty(item.buyPriceFormatted))
                    )
                  ])
                ]
              }
            },
            {
              key: "item.sellPriceFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.replaceNullOrEmpty(item.sellPriceFormatted))
                    )
                  ])
                ]
              }
            },
            {
              key: "item.securityPriceFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.replaceNullOrEmpty(item.securityPriceFormatted)
                      )
                    )
                  ])
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.sortableColumns,
                          dictionary: _vm.dictionary.table.datatables
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("ListedTradingTableMobile", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          position: item,
                          isMobile: _vm.isMobile
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: {
                      datatablesDictionary: _vm.dictionary.table.datatables
                    },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
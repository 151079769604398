import ExitsContainer from "./exit-container.component.vue";
import Exits from "./exits.component.vue";
import ExitsConfirmation from "./exit-confirmation.component.vue";


export const exitsRoutes =
{
    path: '/exits',
    component: ExitsContainer,
    children: [
        {
            path: '',
            component: Exits
        }, {
            path: 'confirmation',
            component: ExitsConfirmation
        },
        {
            path: '*',
            redirect: '/exits'
        }
    ]
}

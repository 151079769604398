var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.instructionData.ExerciseScenarioType == "detSale"
        ? [
            _c("ExerciseScenarioTypeSale", {
              attrs: {
                valueAmountToDeliver:
                  _vm.instructionData.AmountToDeliverFormatted,
                labelAmountToDeliver: _vm.dictionary.modal.amountToDeliver,
                valueProceedsAccount:
                  _vm.instructionData.MoneyProceedsAccountDescription,
                labelProceedsAccount: _vm.dictionary.modal.proceedsAccount
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.instructionData.ExerciseScenarioType == "detDelivery"
        ? [
            _c("ExerciseScenarioTypeDelivery", {
              attrs: {
                dictionary: _vm.dictionary,
                instructionData: _vm.instructionData
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.instructionData.ExerciseScenarioType == "detSaleAndDelivery"
        ? [
            _c("ExerciseScenarioTypeSaleAndDelivery", {
              attrs: {
                dictionary: _vm.dictionary,
                instructionData: _vm.instructionData
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
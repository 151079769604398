export const vueDataTableScrollMixin = {
    data() {
        return {
            lastScrollTop: 0,
            tableWrapperOffset: 1
        };
    },
    methods: {
        handleScrollEvent(scrollableElement) {

            if (scrollableElement.scrollTop > this.lastScrollTop) {
                if (scrollableElement.scrollTop + scrollableElement.offsetHeight + this.tableWrapperOffset >= scrollableElement.scrollHeight) {
                    if ((!this.isLoading && !this.isUpdating) &&
                        (!this.maxRowsCount || (this.currentRowsCount < this.maxRowsCount))) {
                        this.currentRowsCount += this.pullingRowsCount;
                        this.getItems(true);
                    }
                }
            }

            this.lastScrollTop = scrollableElement.scrollTop;
        }
    },
    computed: {
        hasScroll() {
            return this.items.length >= this.initialRowsCount;
        }
    }
}
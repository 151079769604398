var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary && _vm.dividendSubscription
    ? _c("div", { staticClass: "portlet-container" }, [
        _c(
          "div",
          { staticClass: "row m-0" },
          [
            !_vm.showConfirmation
              ? _c("DividendSubscriptionFormPortlet", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    dividendSubscription: _vm.dividendSubscription
                  },
                  on: { subscribe: _vm.subscribe }
                })
              : _c("DividendSubscriptionConfirmationPortlet", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    dividendSubscription: _vm.dividendSubscription,
                    subscribedDividend: _vm.subscribedDividend
                  },
                  on: {
                    "confirm-subscription": _vm.confirm,
                    cancel: _vm.cancel
                  }
                }),
            _vm._v(" "),
            _c(
              "portlet-layout",
              {
                attrs: {
                  "column-class": "col-lg-4 col-xl-3",
                  title: _vm.dictionary.subscriptionLabels.calendar
                }
              },
              [
                _c(
                  "template",
                  { slot: "body" },
                  [
                    _c("DividendSubscriptionInfo", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        dividendSubscription: _vm.dividendSubscription
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
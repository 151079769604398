var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("component-spinner", { attrs: { spin: _vm.isLoading } })
    : _vm.dictionary && _vm.boundaries
    ? _c(
        "form",
        {
          staticClass: "required-info-box",
          attrs: { id: "required-documents-form" }
        },
        [
          _vm.boundaries.isCompany
            ? _c("RequiredInfoMifidTimelineCompany", {
                attrs: {
                  dictionary: _vm.dictionary,
                  errors: _vm.errors,
                  requiredInfo: _vm.requiredInfo,
                  boundaries: _vm.boundaries
                },
                on: {
                  submit: function($event) {
                    return _vm.submit()
                  },
                  skip: function($event) {
                    return _vm.skip()
                  }
                }
              })
            : _c("RequiredInfoMifidTimelineStandard", {
                attrs: {
                  dictionary: _vm.dictionary,
                  errors: _vm.errors,
                  requiredInfo: _vm.requiredInfo,
                  boundaries: _vm.boundaries,
                  countries: _vm.countries
                },
                on: {
                  submit: function($event) {
                    return _vm.submit()
                  },
                  skip: function($event) {
                    return _vm.skip()
                  }
                }
              }),
          _vm._v(" "),
          _c("RequiredInfoSkipModal", {
            attrs: {
              dictionary: _vm.dictionary,
              isModalVisible: _vm.isSkipModalVisible
            },
            on: {
              "close-modal": function($event) {
                return _vm.cancelSkipModal()
              },
              "submit-cancel": function($event) {
                return _vm.submitSkipModal()
              }
            }
          }),
          _vm._v(" "),
          _c("loading-spinner", { attrs: { active: _vm.isSubmitting } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
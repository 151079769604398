var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-container" },
    [
      _vm.dictionary && _vm.mfaBoundariesDataModel
        ? _c(
            "div",
            [
              _vm.getCurrentFactorType == "mftTOTP"
                ? [
                    _c(
                      "MfaMsauthenticator",
                      _vm._g(
                        {
                          attrs: {
                            currentFactorType: _vm.getCurrentFactorType,
                            dictionary: _vm.dictionary,
                            "mfa-boundaries-data-model":
                              _vm.mfaBoundariesDataModel,
                            "mfa-enable-disable-data-model":
                              _vm.mfaEnableDisableDataModel,
                            mfaData: _vm.mfaData,
                            showFirstScreen: _vm.showFirstScreen,
                            isVisible: _vm.isVisible,
                            error: _vm.error,
                            skipConfirmation: _vm.skipConfirmation
                          },
                          on: {
                            "disable-close-button": function($event) {
                              return _vm.$emit("disable-close-button")
                            },
                            "mfa-success-login": function($event) {
                              return _vm.$emit("mfa-success-login")
                            },
                            "show-ms-authenticator-code": _vm.getCodeState,
                            "in-loading": _vm.inLoading
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  ]
                : [
                    _c(
                      "MfaPac",
                      _vm._g(
                        {
                          attrs: {
                            pac: _vm.pac,
                            isVisible: _vm.isVisible,
                            mfaData: _vm.mfaData,
                            dictionary: _vm.dictionary,
                            message: _vm.message,
                            phone: _vm.phone,
                            skipConfirmation: _vm.skipConfirmation,
                            error: _vm.error
                          },
                          on: {
                            "in-loading": _vm.inLoading,
                            "update-mfa-data": _vm.updateMfaData,
                            "disable-close-button": function($event) {
                              return _vm.$emit("disable-close-button")
                            }
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.isLoading } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-basic",
    {
      staticClass: "leavers-prompt-modal",
      attrs: {
        size: "md",
        title: _vm.dictionary.page.settingsModalHeaderPersonalDetails,
        "is-on": _vm.showDialog,
        staticMode: true,
        lazy: false,
        centered: true,
        "close-button": true
      },
      on: {
        close: function($event) {
          return _vm.closePromptModal()
        },
        "hook:mounted": function($event) {
          return _vm.openPromptModal()
        }
      }
    },
    [
      _c("div", [
        _c("div", [
          _c("div", { staticClass: "leavers-prompt-content" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "leavers-prompt-modal-alert" },
                [
                  _c(
                    "alert-component",
                    {
                      attrs: {
                        variant: "warning",
                        iconClass: "fa fa-lightbulb-on"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dictionary.page
                              .settingsModalAlertTextPersonalEmail
                          )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "leavers-prompt-modal-content" }, [
                _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dictionary.page
                        .settingsModalTitleDescriptionPersonalEmail
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.dictionary.page
                        .settingsModalTextDescriptionPersonalEmail
                    )
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row leavers-prompt__buttons" }, [
              _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary btn-primary-white-background w-100",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closePromptModal()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.dictionary.page.settingsModalBtnSkip))]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary w-100",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.redirectToBeneficiariesAccount()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dictionary.page.settingsModalBtnAddPersonalEmail
                        )
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="mfa-confirmation">
        <div class="mfa-confirmation-body">
            <p class="mb-2 rich-text-container" v-html="$sanitize(message)" ></p>
            <alert-component v-if="error" variant="error">{{ error }}</alert-component>
            <p>
                <label class="form-input-label">{{ dictionary.labelMfaPromptMobileNumber }}:</label>
                <span>{{ phone }}</span>
            </p>
        </div>
        <MfaButtons :dictionary="dictionary" @cancel="cancel()" @submit="submit()" />
    </div>
</template>

<script>
    import MfaButtons from "../mfa-buttons.component.vue";

    export default {
        components: {
            MfaButtons
        },
        props: {
            dictionary: Object,
            message: String,
            phone: String,
            error: String
        },
        methods: {
            submit() {
                this.$emit("disable-close-button");
                this.$emit("in-loading", true);
                this.$emit("submit-confirmation");
            },
            cancel() {
                this.$emit("cancel");
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "sr-only welcome-text" }, [
      _vm._v(_vm._s(_vm.dictionary.recovery.forgotUserNameTitle))
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass: "text-center py-2 welcome-text",
        attrs: { "aria-hidden": "true" }
      },
      [_vm._v(_vm._s(_vm.dictionary.recovery.forgotUserNameTitle))]
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", {
      staticClass: "text-left py-2 rich-text-container",
      domProps: {
        innerHTML: _vm._s(
          _vm.$sanitize(_vm.dictionary.recovery.usernameRecoverySuccessText)
        )
      }
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "btn float-right btn-primary mt-5 secondary-background-color",
        attrs: { type: "button" },
        on: { click: _vm.back }
      },
      [_vm._v("\n        " + _vm._s(_vm.commonDictionary.btnGoBack) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <portlet-row column-class="col-12" :title="portfolioOverviewDictionary.reportParameters" ref="reportParameters">
        <loading-spinner :active="isSubmitting"></loading-spinner>
        <form>
            <div id="report-date-container">
                <div class="row">
                  <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-4']">
                      <label class="form-input-label">
                        <span><strong>{{portfolioOverviewDictionary.labels.date}}</strong></span>
                        <span class="px-2 noprint">
                            <tooltip :tooltipText="portfolioOverviewDictionary.labels.tooltipDate" :hideTooltip="false"></tooltip>
                        </span>
                      </label>
                  </div>
                  <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-8']">
                    <datepicker-element  id="reportDate" v-model="model.reportDate"
                      :hideLabel="true" >
                        <template slot="customAction">
                            <a href="javascript:void(0)" role="button" class="fas fa-sync noprint" @click="resetDefault" :title="portfolioOverviewDictionary.labels.resetDefault"></a>
                        </template>
                    </datepicker-element>                     
                  </div>
                </div>
            </div>

            <div id="security-price-container">
                <div id="security-price-label-container">
                  <PortfolioFormCustomLabel 
                    :label="portfolioOverviewDictionary.labels.securityPrice" 
                    :tooltipText="portfolioOverviewDictionary.labels.tooltipSecurityPrice">
                      <template slot="reset-action">
                        <a href="javascript:void(0)" role="button" class="fas fa-sync noprint" @click="resetDefaultSecurityPrices" :title="portfolioOverviewDictionary.labels.resetDefault"><span class="p-1">{{portfolioOverviewDictionary.labels.resetDefault}}</span></a>
                      </template>
                  </PortfolioFormCustomLabel>
                </div>
                <div id="security-prices-container">
                  <div  class="row" v-for="(customSecurityPrice, index) in model.customizableSecurityPrices" :key="customSecurityPrice.securityId" v-bind:index="index">
                      <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-4']">
                        <label class="form-input-label">{{customSecurityPrice.name}}</label>
                      </div>
                      <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-8']">
                        <input-element :id="`customSecurityPrice-${customSecurityPrice.securityId}`" 
                          :name="`customSecurityPrice-${customSecurityPrice.securityId}`"  type="text" v-model="customSecurityPrice.price" 
                          :validationsList="reportValidationsSecurityPrices(index)" :autoFocus="false">
                          <template slot="group">
                            <div class="input-group-prepend" aria-hidden="true">
                              <span class="input-group-text">{{customSecurityPrice.symbol}}</span>
                            </div>      
                          </template>
                        </input-element>
                      </div>
                  </div>
                </div>             
            </div>

            <div id="currency-conversion-label-container">
                <PortfolioFormCustomLabel 
                  :label="portfolioOverviewDictionary.labels.currencyConversion" 
                  :tooltipText="portfolioOverviewDictionary.labels.tooltipCurrencyConversion">
                    <template slot="reset-action">
                      <a href="javascript:void(0)" role="button"  class="fas fa-sync noprint" @click="resetDefaultCustomCurrencyRates" :title="portfolioOverviewDictionary.labels.resetDefault"><span class="p-1">{{portfolioOverviewDictionary.labels.resetDefault}}</span></a>
                    </template>
                </PortfolioFormCustomLabel>
            </div>
             
            <div id="currency-dropdown-container" class="row" >
                <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-4']">
                  <label class="form-input-label">{{portfolioOverviewDictionary.labels.currencyDropdown}}</label>
                </div>
                <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-8']">
                <select-element  name="selectableCurrencies" v-model="model.selectedCurrency" 
                  :options="customCurrencies" >
                </select-element> 
              </div>
            </div>

            <div id="custom-conversion-rate-container">
              <div class="row" v-for="(customCurrencyRate, index) in model.customCurrencyRates" :key="customCurrencyRate.currencyId" v-bind:index="index" >
                <div class="primary-color" :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-4']">
                    <label class="form-input-label">{{conversionRateCurrecyTitle(customCurrencyRate) }}</label>
                </div>
                <div :class="[ isMobile ? 'col-md-6 col-lg-4' : 'col-8']">
                  <div class="custom-conversion-rate">
                    <div class="input-conversion-rate">
                      <input-element :id="`customCurrencyRate-${customCurrencyRate.name}`"
                        :validationsList="reportValidationsCurrencyRates(index)"                          
                        v-model="customCurrencyRate.rate" :disabled="!hasSelectedCurrency" :autoFocus="false"> 
                        <template slot="group">
                          <div class="input-group-prepend" aria-hidden="true">
                            <span class="input-group-text">{{customCurrencyRate.symbol}}</span>
                          </div>      
                        </template>                             
                      </input-element>
                    </div>
                    <div class="output-conversion-rate" v-if="!isMobile">
                      <div class="input-group-append"  v-if="hasSelectedCurrency">                               
                        <span class="input-group-text"> {{customConversionForSelectedCurrency}}</span>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
    
            <div class="d-md-flex justify-content-end">
                <div>
                  <button type="button" class="btn secondaryButton mr-2" @click="resetAllToDefault()">{{portfolioOverviewDictionary.labels.resetAllToDefault}}</button>
                  <button type="button" :disabled="$v.$invalid"  class="btn primaryButton" @click="getPortfolio()">{{portfolioOverviewDictionary.labels.btnRecalculate}}</button>
                </div>
            </div>
        </form>

    </portlet-row>
</template>

<script>

import {portfolioReportValidations} from "../mixins/reports-portfolio.validation.mixin";
import { EventBus } from "Shared/event-bus";
import PortfolioFormCustomLabel from "./portfolio-form-custom-label.component.vue";

export default {
  mixins: [portfolioReportValidations],
  components: {PortfolioFormCustomLabel},
  props: {
      portfolioOverviewDictionary: Object,
      model: Object,
      customCurrencies: Array,
      isSubmitting: Boolean,
      isMobile: Boolean,
      isTablet: Boolean,
      getSelectedCurrency: Object
  },
  methods: {
      conversionRateCurrecyTitle(customCurrencyRate){   
        return  this.$replaceStringWithPlaceholders( this.portfolioOverviewDictionary.labels.conversionRate, { "ISOCODE":  customCurrencyRate.isoCode });
      },
      getPortfolio(){              
        this.$v.$touch();
        EventBus.$emit("v-touch");
        if (this.$v.$invalid) {
              return;
        }
        EventBus.$emit("getPortfolio", this.model);        
      },
      resetAllToDefault(){
        EventBus.$emit("resetAllToDefault");
      },
      resetDefault(){
        EventBus.$emit("resetDefault");
      },
      resetDefaultCustomCurrencyRates(){
        EventBus.$emit("resetDefaultCustomCurrencyRates");
      },
      resetDefaultSecurityPrices(){
        EventBus.$emit("resetDefaultSecurityPrices");
      },
      reportValidationsSecurityPrices(index) {
        return [
            {
                  id: 1,
                  error: this.$v.model.customizableSecurityPrices.$each[index].price.$error,
                  type: this.$v.model.customizableSecurityPrices.$each[index].price.mustBeDecimal,
                  message: this.portfolioOverviewDictionary.labels.errorInvalidSecurityPriceFormat
            },
            {
                  id: 2,
                  error: this.$v.model.customizableSecurityPrices.$each[index].price.$error,
                  type: this.$v.model.customizableSecurityPrices.$each[index].price.mustBeGreatherThanZero,
                  message: this.portfolioOverviewDictionary.labels.errorInvalidSecurityPriceFormat
            },
            {
                  id: 3,
                  error: this.$v.model.customizableSecurityPrices.$each[index].price.$error,
                  type: this.$v.model.customizableSecurityPrices.$each[index].price.atLeastOneShouldBeHigherThanZero,
                  message: this.portfolioOverviewDictionary.labels.errorInvalidSecurityPriceFormat
            }
        ];
      },
      reportValidationsCurrencyRates(index) {
        return [
            {
                  id: 1,
                  error: this.$v.model.customCurrencyRates.$each[index].rate.$error,
                  type: this.$v.model.customCurrencyRates.$each[index].rate.required,
                  message: this.portfolioOverviewDictionary.labels.conversionRateRequired
            },
            {
                  id: 2,
                  error: this.$v.model.customCurrencyRates.$each[index].rate.$error,
                  type: this.$v.model.customCurrencyRates.$each[index].rate.mustBeDecimal,
                  message: this.portfolioOverviewDictionary.labels.errorInvalidCurrencyRateFormat
            },
            {
                  id: 3,
                  error: this.$v.model.customCurrencyRates.$each[index].rate.$error,
                  type: this.$v.model.customCurrencyRates.$each[index].rate.mustBeGreatherThanZero,
                  message: this.portfolioOverviewDictionary.labels.errorInvalidCurrencyRateFormat
            }
        ];
      },        
  },
  created(){
    this.$v.$touch();
    EventBus.$emit("v-touch");
    if (this.$v.$invalid) {
          return;
    }
       
    const postData = {
      reportDate : null,
      customizableSecurityPrices: [],
      customCurrencyRates: [],
      selectedCurrency: null,
    };
  
    EventBus.$emit('getPortfolio', postData);
  },
  computed: {
    customConversionForSelectedCurrency(){
      return `= ${this.getSelectedCurrency.externalIdentifier} 1${window.culture.decimalSeparator}00`
    },
    hasSelectedCurrency(){
      return !!this.model.selectedCurrency
    }
  }
}
</script>
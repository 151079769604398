<template>
  <div>
    <h3 class="timeline-step-header">
      <button v-b-toggle="'accordion-' + thisStep">
        <span v-if="!isCustodian" class="timeline-accordion-header">
          {{dictionary.step}} {{thisStep}}: {{heading}}
        </span>
        <span v-else class="timeline-accordion-header">
          {{heading}}
        </span>
        <span v-if="!isSummary" class="timeline-accordion-icon">
          <i :class="['far', isVisible ? 'fa-chevron-up' : 'fa-chevron-down']" aria-hidden="true"></i>
        </span>
      </button>
    </h3>
    <b-collapse :id="'accordion-' + thisStep" role="tabpanel" v-model="isVisible">
      <div class="timeline-step-details">
        <slot></slot>
        <button v-if="!isSummary && !isCustodian" class="primaryButton btn edit-step-button" @click="editStep()">{{dictionary.btnEdit}} <span class="sr-only">{{thisStep}} {{heading}}</span></button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    props: {
      heading: String,
      currentStep: Number,
      dictionary: Object,
      isCustodian: Boolean,
      showStep3:Boolean
    },
    data() {
      return {
        isVisible: false,
        thisStep: this.isCustodian ? this.heading : this.currentStep - 1,
        accordionId: 'accordion-' + this.thisStep
      }
    },
    computed: {
        isSummary() {
          if(this.currentStep == 4 || (this.currentStep == 3 && !this.showStep3)) {
            this.isVisible = true;
            return true;
          }
          this.isVisible = false;
            return false;
        }
    },
    methods: {
      editStep() {
        this.$parent.$emit("edit-step", { step: this.thisStep });
      }
    }
  }
</script>

<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <h3 class="h4 primary-color">{{ dictionary.instructionTitle }}</h3>
        <p role="alert" v-if="shouldShowDeviationWarningMessage"><strong>{{internalExchangesWarningLimitPriceDeviation}}</strong></p>
        <dl class="order-details-list">
            <InternalExcahngesConfirmationRow :label="dictionary.positionName" :value="intExchangeInstructionModel.securityName" />
            <InternalExcahngesConfirmationRow :label="dictionary.orderType" :value="intExchangeInstructionModel.orderType == 'Buy' ? dictionary.orderTypeBuy : dictionary.orderTypeSell" />
            <InternalExcahngesConfirmationRow :label="dictionary.expirationDate" :value="intExchangeInstructionModel.expirationDate | toDateTime" />
            <InternalExcahngesConfirmationRow :label="dictionary.quantity" :value="intExchangeInstructionModel.quantityFormatted" />
            <InternalExcahngesConfirmationRow :label="dictionary.limitPrice" :value="intExchangeInstructionModel.limitPriceFormatted" />
            <InternalExcahngesConfirmationRow :label="dictionary.maxFeeReservationAmount" :value="intExchangeInstructionModel.maxFeeReservationAmountFormatted" />
            <InternalExcahngesConfirmationRow v-if="toggles.displayOrderTaxDetails && (intExchangeInstructionModel.taxRate !== null)" :label="dictionary.taxRate" :value="intExchangeInstructionModel.taxRate" />
            <InternalExcahngesConfirmationRow v-if="toggles.displayOrderTaxDetails && (intExchangeInstructionModel.estimatedTaxesFormatted !== null)" :label="dictionary.taxAmount" :value="intExchangeInstructionModel.estimatedTaxesFormatted" />
            <InternalExcahngesConfirmationRow :label="dictionary.expectedOrderAmount" :value="intExchangeInstructionModel.expectedOrderAmountFormatted" class="confirmation-total-row" />
        </dl>
        <InternalExcahngesConfirmationButtonsComponent v-if="!intExchangeInstructionModel.disclaimers.length" :intExchangeInstructionModel="intExchangeInstructionModel" v-on="$listeners" />
    </div>
</template>

<script>
import InternalExcahngesConfirmationRow from "./internal-exchanges-confirmation-row.component.vue";
import InternalExcahngesConfirmationButtonsComponent from "./internal-exchanges-confirmation-buttons.component.vue";

export default {
  components: {
    InternalExcahngesConfirmationRow,
    InternalExcahngesConfirmationButtonsComponent,
  },
  props: {
    intExchangeInstructionModel: Object,
    toggles: Object,
    dictionary: Object,
    error: String,
  },
  data() {
    return {
      priceDeviationWarningPercentage: 0,
      shouldShowDeviationWarningMessage: false
    };
  },
  computed: {
    internalExchangesWarningLimitPriceDeviation() {
        return this.$replaceStringWithPlaceholders(
            this.dictionary.internalExchangesWarningLimitPriceDeviation,
            {
                "MaxDeviationValue": this.priceDeviationWarningPercentage
            }
        )
    }
  },
  created() {
            let query = this.$route.query;
            this.priceDeviationWarningPercentage = query.priceDeviationWarningPercentage;
            this.shouldShowDeviationWarningMessage = (query?.shouldShowDeviationWarningMessage?.toLowerCase() === 'true');
  } 
};
</script>
const createDropDownOption = (disabled, group, selected, text, value, externalIdentifier) => ({
    disabled: disabled,
    group: group,
    selected: selected,
    text: text,
    value: value,
    externalIdentifier: externalIdentifier
});

export const addEditStepsLogic = {
    data() {
        return {
            currentStep: 1,
            countries: [],
            currencies: [],
            externalAccountTypes: [],
            relationshipTypes: [],
            externalAccountBoundaries: null,
            complianceExternalAccount: null,
            bankCountry: null,
            errors: [],
            submitingData: false,
            isLoading: true,
            securityAccountDisplaySettings:null,
            showStep3:true,
            messageInSecurityAccountHolderStep:''
        };
    },
    methods: {
        nextStep(payLoad) {
            if (payLoad.step === this.steps) {
                this.currentStep = 1;
            } else {
                this.currentStep = payLoad.step + 1;
            }

            if(this.errors.length > 0)
            {
                this.errors = [];
            }
            this.setComplianceExternalAccount(payLoad);
        },
        previousStep(payLoad) {
            if (payLoad.step === 1) {
                this.currentStep = 1;
            } else {
                this.currentStep = payLoad.step - 1;
            }
            if(this.errors.length > 0)
            {
                this.errors = [];
            }
            this.setComplianceExternalAccount(payLoad);
        },
        displayBankAccountStep() {
            if (this.securityAccountDisplaySettings && this.securityAccountDisplaySettings.allowUKShareTransferSettingsOnly && this.complianceExternalAccount.accountType.externalAccountType == "beatSecurityCertificate") 
                this.showStep3 = false;
            else
                this.showStep3 = true;
        },
        displayMessageInSecurityAccountHolderStep(dictionary) {
            
          if (this.securityAccountDisplaySettings.securityBankAccountsInOwnNameOnly && this.complianceExternalAccount.accountType.externalAccountType == "beatSecurity") 
            this.messageInSecurityAccountHolderStep = dictionary.addAccount.securityAccountDisplaySettings.securityAccountDescriptionSecurityAccount;
          else if (this.securityAccountDisplaySettings.securityBankAccountsInOwnNameOnly && this.complianceExternalAccount.accountType.externalAccountType == "beatSecurityCertificate") 
            this.messageInSecurityAccountHolderStep = dictionary.addAccount.securityAccountDisplaySettings.securityAccountDescriptionShareCertificate;
          else 
            this.messageInSecurityAccountHolderStep = null;
        }
    },
    computed: {
        hasPrevStep: function () {
            return this.currentStep > 1;
        },
        hasNextStep: function () {
            return this.currentStep < this.steps;
        },
        listExternalAccountTypes() {

            if (this.financialToggles.hideDRSAccountTypeForSecurityAccounts)
              this.externalAccountTypes = this.externalAccountTypes.filter(item => item.value != 'beatSecurityDRS')
            if (this.financialToggles.hideSecurityCustodianAccountTypeForSecurityAccounts)
              this.externalAccountTypes = this.externalAccountTypes.filter(item => item.value != 'beatSecurityCustodian')
            if (this.financialToggles.hideMaintainedSecurityAccountTypeForSecurityAccounts)
              this.externalAccountTypes = this.externalAccountTypes.filter(item => item.value != 'beatSecurityMaintained')
            if (this.financialToggles.hideShareCertificateAccountTypeForSecurityAccounts)
              this.externalAccountTypes = this.externalAccountTypes.filter(item => item.value != 'beatSecurityCertificate')
            
            return this.externalAccountTypes.filter(c => (c.value != 'beatUndefined' && c.description != '-'))
              .map(c => createDropDownOption(false, false, true, c.description, c.value));
        },
        listRelationshipTypes() {
            return this.relationshipTypes?.map(c => createDropDownOption(false, false, true, c.description, c.value));
        },
        listCentralSecuritiesDepositoryTypes() {
            return this.centralSecuritiesDepositoryTypes?.map(c => createDropDownOption(false, false, true, c.description, c.value));
        },
        listCountries() {
            return this.countries?.map(c => createDropDownOption(false, false, c.id == this.externalAccountBoundaries?.defaultCountryId, c.name, c.id));
        },
        listCurrencies() {
            return this.currencies?.map(c => createDropDownOption(false, false, c.id == this.externalAccountBoundaries?.defaultCurrencyId, c.name, c.id, c.externalIdentifier));
        }
    },
    watch: {
        steps(val) {
            if (this.currentStep > val) {
                this.currentStep = val;
            }
        },
    }
};
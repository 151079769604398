var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.containsAnnouncements
    ? _c(
        "portlet-layout",
        {
          staticClass: "w-100",
          attrs: { title: _vm.dictionary.widgets.announcements }
        },
        [
          _c(
            "template",
            { slot: "body" },
            _vm._l(_vm.dashboardData.announcements, function(
              announcement,
              index
            ) {
              return _c(
                "div",
                {
                  key: announcement.announcementId + "-" + index,
                  staticClass: "announcement"
                },
                [
                  _c("div", { staticClass: "announcement__date" }, [
                    _vm._v(_vm._s(_vm._f("toDate")(announcement.startDate)))
                  ]),
                  _vm._v(" "),
                  _c("h6", [_vm._v(_vm._s(announcement.title))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(announcement.text))]),
                  _vm._v(" "),
                  announcement.document
                    ? _c("div", { staticClass: "announcement__document" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.redirectToDocument(
                                  announcement.document
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getDocumentLinkText(announcement))
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  announcement.url
                    ? _c("div", { staticClass: "announcement__url" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.redirect(announcement.url)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(announcement.urlDescription))]
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    
    <div class="mfa-pac">
        <div class="mfa-pac-body">
            <p class="mb-2">
                <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryModalHeaderUpdateMFASetting)"> </span>
            </p>
            <p>
                <span class="rich-text-container" v-html="$sanitize(dictionary.mfaAlertMFAEnterTOTP)"></span>
            </p>
            <p class="auth-code">
                <span class="rich-text-container" v-html="$sanitize(dictionary.mfaFieldTOTPCode)"></span>
            </p>
            <v-otp-input  ref="otpInput" :disabled="isDisabled" length="6"
            @finish="onFinish"></v-otp-input>
            <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        </div>
        <MfaButtons :dictionary="dictionary" :hasContinueButton="false" :disableSubmit="isDisabled" :hasOnlyCancelButton="true" @cancel="cancel()" @submit="submit()" />
        <div class="lost-phone-wrapper">
            <a v-html="$sanitize(dictionary.mfaFieldLostPhone)" href="#" @click.prevent="navigateToLostMyPhone()"></a>
        </div>
        <p>
            <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryAlertMFALostPhone)"></span>
        </p>
    </div>
    </template>
    
    <script>
        import MfaButtons from "../mfa-buttons.component.vue";
        export default {
            components: {
                MfaButtons
            },
            props: {
                dictionary: Object,
                error: String,
                isMobile:Boolean,
                currentFactorType:String,
                identifier:String,
                isDisabled:Boolean,
                isVisible:Boolean
            },
            watch: {
                isVisible: function (newVal, oldVal) {
                    if (newVal) {
                        this.$emit("disable-close-button");
                        [... document.getElementsByClassName('otp-field-box--0')][0].focus();
                        this.initializeTOTPInputs();
                    }
                },
            },
            mounted() {
                this.$emit("disable-close-button");
                [... document.getElementsByClassName('otp-field-box--0')][0].focus();
            },
            methods: {
                navigateToLostMyPhone(){
                    this.$emit('navigateToLostMyPhone');
                },
                initializeTOTPInputs(){
                    
                    Object.keys(this.$refs["otpInput"].otp).forEach(key => {
                        this.$refs["otpInput"].otp[key] = ''
                    });

                    this.$refs["otpInput"].reset();
                },
                submit() {
                    this.$emit("disable-close-button");
                    this.$emit("in-loading", true);
                    this.$emit("submit-mfa-mic-confirmation");
                },
                onFinish (authCode) {
                    this.$emit("in-loading", true);
                    let mfaPayload = {
                            identifier: this.identifier,
                            factorType: this.currentFactorType,
                            code: authCode
                    };

                    this.$emit('post-mfa', mfaPayload);
                },
                cancel() {
                    this.$emit("cancel");
                }
            }
        };
    </script>
    
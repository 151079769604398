var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.addressDataModel.addressProperty,
          label: _vm.dictionary.address
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.addressDataModel.complementaryAddress,
          label: _vm.dictionary.complementaryAddress
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.addressDataModel.postalCode,
          label: _vm.dictionary.postalCode
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: { value: _vm.addressDataModel.city, label: _vm.dictionary.city }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.addressDataModel.country,
          label: _vm.dictionary.country
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.addressDataModel.telephone,
          label: _vm.dictionary.telephone
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
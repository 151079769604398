import { render, staticRenderFns } from "./internal-exchanges-confirmation-form.component.vue?vue&type=template&id=4b1efa93&"
import script from "./internal-exchanges-confirmation-form.component.vue?vue&type=script&lang=js&"
export * from "./internal-exchanges-confirmation-form.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Monidee-Test\\A3\\_work\\1\\s\\Monidee.tOption\\Monidee.tOption.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b1efa93')) {
      api.createRecord('4b1efa93', component.options)
    } else {
      api.reload('4b1efa93', component.options)
    }
    module.hot.accept("./internal-exchanges-confirmation-form.component.vue?vue&type=template&id=4b1efa93&", function () {
      api.rerender('4b1efa93', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Features/InternalExchanges/Assets/Vue/internal-exchanges-confirmation-form.component.vue"
export default component.exports
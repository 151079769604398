var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _vm.dictionary &&
      _vm.commonDictionary &&
      _vm.toggles &&
      _vm.exitOrderModel
        ? _c("div", { attrs: { id: "order-form-div" } }, [
            _c(
              "form",
              {
                attrs: { id: "exit-instruct-form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _vm.isOutOfHours
                  ? _c("alert-component", { attrs: { variant: "warning" } }, [
                      _c("div", {
                        staticClass: "rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.form.realTimeDealWarningOutOfHours
                            )
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("loading-spinner", {
                  attrs: { active: _vm.isFormProcessing }
                }),
                _vm._v(" "),
                _c("ExitOrderHiddenInputs", { attrs: { model: _vm.model } }),
                _vm._v(" "),
                _vm.isMultiExit
                  ? _c("div", [
                      _vm.positionName
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("h4", [_vm._v(_vm._s(_vm.positionName))])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.dictionary.form.sharesAvailable))
                        ]),
                        _vm._v(
                          ": " +
                            _vm._s(_vm.model.availableAmountScenarioFormatted)
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.error
                  ? _c("alert-component", { attrs: { variant: "error" } }, [
                      _vm._v(_vm._s(_vm.error))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.dictionary.form.widgetTextPlaceOrder
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("span", {
                        staticClass: "primary-color rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.form.widgetTextPlaceOrder
                            )
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMultiExit
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "form-input-label" }, [
                        _vm._v(_vm._s(_vm.dictionary.form.orderType))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group" }, [
                        _vm._v(_vm._s(_vm.dictionary.form.orderTypeExitName))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isModal && _vm.positionViewModel
                  ? _c("order-types-link", {
                      attrs: {
                        type: _vm.$OrderTypes.Exits,
                        dictionary: _vm.dictionary.form.orderTypes,
                        positionViewModel: _vm.positionViewModel,
                        title: _vm.dictionary.form.orderType,
                        name: "orderType"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("select-element", {
                  attrs: {
                    label: _vm.dictionary.form.scenarioName,
                    options: _vm.scenarios,
                    name: "scenarioName",
                    valueTooltipText: _vm.exitScenarioRemarks
                  },
                  on: {
                    changed: function($event) {
                      return _vm.scenarioChanged()
                    }
                  },
                  model: {
                    value: _vm.model.exitScenarioId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "exitScenarioId", $$v)
                    },
                    expression: "model.exitScenarioId"
                  }
                }),
                _vm._v(" "),
                _vm.hasRestrictedSecurityAccountSettings
                  ? _c("alert-component", { attrs: { variant: "warning" } }, [
                      _c("div", {
                        staticClass: "rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.form
                                .errorExistingAccountSettingsInvalidDisplay
                            )
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("select-element", {
                  attrs: {
                    label: _vm.dictionary.form.executionMoment,
                    options: _vm.executionMoments,
                    validations: _vm.$v.model.exitExecutionMoment,
                    name: "executionMoment"
                  },
                  model: {
                    value: _vm.model.exitExecutionMoment,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "exitExecutionMoment", $$v)
                    },
                    expression: "model.exitExecutionMoment"
                  }
                }),
                _vm._v(" "),
                _vm.toggles.useExitsOrderForfeitureWarning
                  ? _c("ExitOrderQuantityWarning", {
                      attrs: {
                        available: _vm.model.availableAmountPrimaryPosition,
                        availableFormatted:
                          _vm.model.availableAmountPrimaryPositionFormatted,
                        warningText:
                          _vm.dictionary.form.orderTextForfeitureWarning,
                        scenarioQuantity: _vm.model.availableAmountScenario,
                        quantity: _vm.model.quantityAllAndSelectSelect,
                        isAllSelected: _vm.isQuantityAllSelected
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("ExitOrderQuantity", {
                  attrs: {
                    isMultiExit: _vm.isMultiExit,
                    dictionary: _vm.dictionary,
                    model: _vm.model,
                    quantityType: _vm.quantityType,
                    validations: _vm.$v.model
                  },
                  on: {
                    quantity: function($event) {
                      _vm.model.quantity = $event
                    },
                    quantityAll: function($event) {
                      _vm.model.quantityAll = $event
                    },
                    quantityAllAndSelectSelect: function($event) {
                      _vm.model.quantityAllAndSelectSelect = $event
                    },
                    quantitySelection: function($event) {
                      _vm.model.quantityAllAndSelectSelection = $event
                    }
                  }
                }),
                _vm._v(" "),
                !_vm.hideLimitPrice
                  ? _c("input-element", {
                      attrs: {
                        type: "text",
                        label: _vm.dictionary.form.limitPrice,
                        validationsList: _vm.limitPriceValidations,
                        name: "limitPrice"
                      },
                      model: {
                        value: _vm.model.limitPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "limitPrice", $$v)
                        },
                        expression: "model.limitPrice"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.toggles.useExitsOrderAuthorityToDeal
                  ? _c("ExitOrderAuhtorityToDeal", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        validations: _vm.$v.model
                      },
                      model: {
                        value: _vm.model.authorityCheckbox,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "authorityCheckbox", $$v)
                        },
                        expression: "model.authorityCheckbox"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("ExitOrderButtons", {
                  attrs: {
                    isModal: _vm.isModal,
                    hasRestrictedSecurityAccountSettings:
                      _vm.hasRestrictedSecurityAccountSettings,
                    hasAuhtorityToDeal:
                      _vm.toggles.useExitsOrderAuthorityToDeal,
                    isEnabled: _vm.model.authorityCheckbox,
                    isRealTimeDealing: _vm.isRealTimeDealing,
                    dictionary: _vm.dictionary.form,
                    commonDictionary: _vm.commonDictionary
                  },
                  on: {
                    "order-submitted": _vm.submit,
                    closed: function($event) {
                      return _vm.$emit("form-closed")
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
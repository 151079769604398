<template>
  <div>
    <div class="title-block single-line">
      <h1 v-if="positionModel">{{ positionModel.name }}</h1>
    </div>
    <div class="portlets-wrapper">
      <component-spinner :spin="isLoading"></component-spinner>
      <div v-if="positionModel && dictionary" class="row m-0">
        <div class="portlet-container col-md-8 col-xl-9 p-0">
          <div class="row m-0">
            <portlet-layout
              column-class="col-xl col-lg-12"
              :hideTitle="true"
            >
              <template slot="body">
                <total-block
                  :title="dictionary.blocks.titleTotalAvailable"
                  :text="positionModel.availableFormatted"
                />
              </template>
            </portlet-layout>
            <portlet-layout
              column-class="col-xl col-lg-12"
              :hideTitle="true"
            >
              <template slot="body">
                <total-block
                  :title="dictionary.blocks.titleTotalValue"
                  :text="getValueFormatted(positionModel, toggles)"
                ></total-block>
              </template>
            </portlet-layout>
            <portlet-layout
              column-class="col-xl col-lg-12"
              :hideTitle="true"
            >
              <template slot="body">
                  <security-price-block :title="dictionary.blocks.titleLastPrice" :endpoint="lastPriceEndpoint"/>
              </template>
            </portlet-layout>
          </div>
        
          <alert-component variant="warning">
              {{ dictionary.page.textNoInstructionAvailable }}
          </alert-component>

          <div class="row m-0">
            <portlet-layout
              v-if="!toggles.hideSecurityPricesChartBlock"
              column-class="col-12"
              :title="dictionary.widgets.titleSecurityPricesChart"
            >
              <template slot="body">
                <prices-chart
                  :getSecurityPrices="true"
                  :securityId="positionModel.securityId"
                />
              </template>
            </portlet-layout>
          </div>
        </div>

        <div class="col-md-4 col-xl-3 p-0" id="portlet-2">
          
          <portlet-layout v-if="!toggles.hideLastOrdersLinkBlock" column-class="block" :title="dictionary.widgets.titleLastOrders">
            <template slot="body">
              <last-orders :toggles="toggles" :positionUsageId="positionModel.positionUsageId" />
              <order-detail v-if="ordersDictionary" :title="ordersDictionary.modal.headerTitle" :toggles="toggles" />
            </template>
            <template slot="footerLink">
              <router-link class="portlet-title-see-all-link" to="/orders" :title="dictionary.widgets.seeAllOrders">{{dictionary.widgets.seeAllOrders}}</router-link>
            </template>
          </portlet-layout>

          <portlet-layout v-if="!toggles.hideLastTransactionsLinkBlock" column-class="block" :title="dictionary.widgets.titleRecentTransactions">
            <template slot="body">
              <last-transactions :positionUsageId="positionModel.positionUsageId" />
            </template>
            <template slot="footerLink">
              <router-link class="portlet-title-see-all-link" to="/transactions" :title="dictionary.widgets.seeAllTransactions">{{dictionary.widgets.seeAllTransactions}}</router-link>
            </template>
          </portlet-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "api";
import OrderDetail from "/Features/Orders/Assets/Vue/order-detail.component.vue";
import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';


export default {
  mixins: [ positionMethods ],
  components: {
    OrderDetail,
  },
  props: {
    dictionary: Object,
    ordersDictionary: Object,
    toggles: Object,
  },
  data() {
    return {
      positionModel: null,
      error: null,
      isLoading: true,
    };
  },
  computed: {
    documentTitle() {
        let pageTitle = this.dictionary?.page?.title;
        let positionName = this.positionModel?.name;

        if(pageTitle && positionName){
            return `${pageTitle} - ${positionName}`;
        }
    },
    lastPriceEndpoint() {
        let query = this.$route.query;
        return `/InternalExchanges/GetLastPriceBlock?positionUsageId=${query.positionUsageId}`;
    }
  },
  watch: {
    documentTitle: {
      immediate: true,
      handler(value) {
        if (value) document.title = value;
      },
    },
  },
  beforeCreate() {
    let query = this.$route.query;
    get(
      `/PositionDetail/Details?positionUsageId=${query.positionUsageId}`,
      (response) => {
        this.positionModel = response.data;
      },
      this,
      () => (this.isLoading = false)
    );
  },
};
</script>
<template>
  <a v-if="order.canBeCancelledByBeneficiary" class="cancel-order" v-on:click="onClick">{{cancelLinkText}}</a>
</template>

<script>
import { EventBus } from "Shared/event-bus";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
        cancelLinkText: {
            type: String,
            required: true,
        }
    },
    methods: {
        onClick() {
            if(this.order.canBeCancelledByBeneficiary)
            {
                EventBus.$emit("event-get-order-detail", {
                    instructionType: this.order.instructionType,
                    instructionId: this.order.instructionId,
                    cancelOrder: true
                }); 
            }
        },
    },
};
</script>
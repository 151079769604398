<template>
    <section v-if="!!model">
        <div class="mb-3">
            <ExercisesConfirmationRowMultipleColumns  :label="dictionary.securityName" :value="model.securityName" />
            <ExercisesConfirmationRowMultipleColumns  :label="dictionary.quantityShortName" :value="`${model.nrOrderedDerivativesFormatted} | ${model.exerciseScenarioName}`" />

            <div class="row">
                <div class="col-xl">
                    <ExercisesConfirmationRowMultipleColumns  :hasIcon="true" :label="dictionary.exercisePrice" :value="model.exercisePriceFormatted" />
                </div>
                <div class="col-xl">
                    <ExercisesConfirmationRowMultipleColumns  :hasIcon="true" :label="dictionary.securityPrice" :value="model.securityPriceFormatted" />
                </div>
            </div>
            <ExercisesConfirmationRowMultipleColumns v-if="model.executionMoment.value === $ExecutionMomentType.AT_PRICE" :hasIcon="true" :label="dictionary.limitPrice" :value="model.limitPriceFormatted" />
        </div>
        <div class="mb-3">
            <h3 class="primary-color">{{dictionary.calculation}}</h3>
            <ExercisesConfirmationDetailRow stack-content="1" :label="dictionary.securityCalculation" :expression="`${model.nrOrderedDerivatives} x ${model.securityPriceFormatted}`"  :result="model.grossAmountFormatted" />
            <ExercisesConfirmationDetailRow stack-content="2" :label="dictionary.exerciseCalculation" :expression="`${model.nrOrderedDerivatives} x ${model.exercisePriceFormatted}`" :result="model.netExerciseAmountFormatted"  resultClass="red-text"/>        
            <ExercisesConfirmationDetailRow stack-content="3" :label="dictionary.payCost"  />
            <div class="row">
                <div class="column-spacer"></div>
                <div class="col">
                    <ExercisesConfirmationDetailMultipleResult :model="{ payments: model.taxPayments }"  :noDataLabel="dictionary.taxPayment" /> 
                    <ExercisesConfirmationDetailMultipleResult :model="{ payments: model.feesPayments }" :noDataLabel="dictionary.paymentFees" />
                    <ExercisesConfirmationDetailMultipleResult v-if="model.brokerFees > 0" :model="{ value: model.brokerFeesFormatted, label:dictionary.brokerFees }" :noDataLabel="dictionary.brokerFees" />
                    <ExercisesConfirmationDetailMultipleResult :model="{ payments: model.usageFeesPayments }" :noDataLabel="dictionary.usageFeesPayments" />
                </div>
            </div>
            <template v-if="!!model.exerciseDeliveries">
                <ExercisesConfirmationDetailRow stack-content="4" :label="dictionary.exerciseDeliveries" />
                <div class="row">
                    <div class="column-spacer"></div>
                    <div class="col">
                        <ExercisesConfirmationDetailMultipleResult :model="{ exerciseDeliveries: model.exerciseDeliveries }"  noDataLabel="-" />
                    </div>            
                </div>
            </template>
            <h4  v-if="model.hasToPayForExercise" class="row total-due">            
                <span class="column-title col">
                    {{dictionary.amountToPay}}
                </span>
                <span class="column-result col text-right">{{model.amountToPayFormatted}}</span>
            </h4>
            <ExercisesConfirmationDetailRow v-else stack-content="5" :label="dictionary.restAmountToDeliver" :expression="model.restAmountToDeliverFormatted"  />      
        </div>
        
        <div class="exercise-type-info">
            <h3 class="primary-color">{{dictionary.deliveryInformation}}</h3>
            <p v-if="model.hasToPayForExercise">{{dictionary.paymentAccount}} {{model.paymentAccount}}</p>
            <p v-if="!!model.securityProceedsAccountDescription">{{dictionary.securityProceedsAccount}} {{model.securityProceedsAccountDescription}}</p>
        </div>
    </section>
</template>

<script>

import ExercisesConfirmationDetailMultipleResult from "./exercises-confirmation-detail-multiple-result.component.vue";
import ExercisesConfirmationRowMultipleColumns from "./exercises-confirmation-row-multiple-columns.component.vue"
import ExercisesConfirmationDetailRow from "./exercises-confirmation-detail-row.component.vue"

export default {
    components: {
        ExercisesConfirmationDetailMultipleResult,
        ExercisesConfirmationRowMultipleColumns,
        ExercisesConfirmationDetailRow,
    },
    props: {
        model:Object,
        dictionary: Object
    },
}
</script>
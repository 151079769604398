<template>
    <div class="inactivity-prompt-buttons button-container">
        <div class="inactivity-prompt-buttons__signout-button">
            <button class="btn secondaryButton" type="button" @click="$emit('signout')">
                {{ dictionary.signOutButton }}
            </button>
        </div>
        <div class="inactivity-prompt-buttons_continue-button">
            <button class="btn primaryButton" type="button" @click="$emit('continue')">
                {{ dictionary.continueButton }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object
        }
    }
</script>
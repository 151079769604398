<template>
  <div class="vestings">
    <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.vestingsPageDescription)"></div>

    <v-data-table class="table-striped"
                  :show-expand="showDetailsColumn"
                  single-expand
                  item-key="id"
                  :no-data-text="dictionary.table.noRecordFound"
                  :hide-default-header="isMobile"
                  :headers="filteredHeaders"
                  :items="rows"
                  :loading="isLoading"
                  :server-items-length="totalRecords"
                  :search="search"
                  :items-per-page="options.itemsPerPage"
                  @page-count="options.pageCount = $event;"
                  :options.sync="options"
                  hide-default-footer
                  >

      <!-- Search input at top --->
      <template v-slot:top>
        <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
        <VestingsQueryForm :dictionary="dictionary" :vestingsOptions="vestingsOptions" @onShowButton="showButton" @onDownloadButton="downloadButton"/>
      </template>

      <template v-slot:[`header.sharePrice`]="{ header }">
        {{ header.text }}
        <i id="sharePriceInfo" class="far fa-info-circle popover-info" aria-hidden="true"></i>
      </template>

      <template v-slot:[`header.marketValue`]="{ header }">
        {{ header.text }}
        <i id="marketValueInfo" class="far fa-info-circle popover-info" aria-hidden="true"></i>
      </template>

      <template v-slot:[`item.sharePrice`]="{ item }">
        {{ replaceNullOrEmpty(item.sharePrice) }}
      </template>

      <template v-slot:[`item.marketValue`]="{ item }">
        {{ replaceNullOrEmpty(item.marketValue) }}
      </template>

      <!-- Expanded item template (purchase details & modifications) --->
      <template v-slot:expanded-item="{ headers, item }">
        <td class="expanded-item-td" :colspan="headers.length">
          <VestingTableExpandedRows :dictionary="dictionary" :vesting="item" :toggles="toggles" />
        </td>
      </template>

      <!-- Logic for expandable rows if conditions met  --->
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon v-if="item.hasPurchase || hasAnyModifications(item) || toggles.showVestingTaxDetails"
                :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                @click.stop="expand(!isExpanded)" :aria-label="`${item.planName} ${isExpanded ? 'collapse' : 'expand'} more details`">$expand</v-icon>
      </template>

      <!-- Datatable template for mobile view before data rows --->
      <template v-if="isMobile" v-slot:[`body.prepend`]>
        <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                 v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
        <VestingsStateInfo :dictionary="dictionary" />
      </template>

      <!-- Datatable template for mobile view of data rows --->
      <template v-if="isMobile" v-slot:item="{ item }">
        <VestingDetailsMobileContent :dictionary="dictionary" :item="item" :toggles="toggles" />
      </template>

      <template v-slot:footer>
        <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
      </template>
    </v-data-table>

    <b-popover target="sharePriceInfo" triggers="hover" placement="bottom">
      <span>{{ dictionary.table.popupSharePrice }}</span>
    </b-popover>

    <b-popover target="marketValueInfo" triggers="hover" placement="bottom">
      <span>{{ dictionary.table.popupMarketValue }}</span>
    </b-popover>
  </div>
</template>

<script>
  import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
  import VestingDetailsMobileContent from './vesting-details-mobile-content.component.vue';
  import VestingsStateInfo from './vestings-state-info.component.vue';
  import VestingsQueryForm from './vestings-query-form.component.vue';
  import { get } from 'api';
  import VestingTableExpandedRows from './vestings-table-expanded-rows.component.vue';

  export default {
    mixins: [vueDataTableMixin],
    components: {
      VestingDetailsMobileContent,
      VestingsStateInfo,
      VestingTableExpandedRows,
      VestingsQueryForm
    },
    props: {
      dictionary: Object,
      datatablesDictionary: Object,
      toggles: Object
    },
    data() {
      return {
        isTablet: false,
        isMobile: false,
        isDownload:false,
        vestingsOptions: {
          startDate: null,
          endDate: null
        },
        nonCriticalHeaders: ['vestingName', 'state'],
        getUrl: '/Vestings/Transactions',
        requestType: "get",
        queryStringExtraParams:  {
          startDate: null,
          endDate: null
        },
        options: {
          itemsPerPage: 10,
          page: 1,
          sortBy: ["vestingDate"],
          sortDesc: [true],
          pageCount: 0,
          itemsPerPageOptions: [
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: this.datatablesDictionary.showAll, value: -1 }
                ]
        },
        headers: [
          { value: 'planName', text: this.dictionary.table.planNameColumn, align: 'left', sortable: false },
          { value: 'vestingName', text: this.dictionary.table.vestingNameColumn, align: 'left', sortable: false },
          { value: 'vestingDate', text: this.dictionary.table.dateColumn, align: 'left', sortable: true },
          { value: 'state', text: this.dictionary.table.stateColumn, align: 'left', sortable: false },
          { value: 'vestingAmount', text: this.dictionary.table.amountColumn, align: 'right', sortable: true },
          { value: 'sharePrice', text: this.dictionary.table.sharePriceColumn, align: 'right', sortable: false },
          { value: 'marketValue', text: this.dictionary.table.marketValueColumn, align: 'right', sortable: false },
          { value: 'data-table-expand', text: this.dictionary.table.detailsColumn, align: 'center', sortable: false }
        ],
        rows: [],
        totalRecords: 0
      }
    },
    computed: {
      filteredHeaders() {
        let headerList = this.showVestingTaxDetailsFilter(this.headers);
        headerList = this.hideDetailsColumnFilter(headerList);
        headerList = this.showColumnsForTabletViewFilter(headerList);
        
        return headerList;
      },
      sortableColumns() {
        return this.filteredHeaders.filter(x => x.sortable);
      },
      showDetailsColumn() {
        return !this.hideDetailsColumn;
      },
      hideDetailsColumn() {
        return (this.rows.every(x => !x.hasPurchase && !x.modifications.length)
          || (this.toggles.hidePurchaseVestings && this.toggles.hideVestingModifications)) && !this.toggles.showVestingTaxDetails;
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      updateTable() {
          this.loadItems();
      },
      downloadFile() {
        this.isLoading = true;
        let url = '/Vestings/Download';

        get(`${url}` +
             `?startDate=${this.vestingsOptions.startDate}` +
             `&endDate=${this.vestingsOptions.endDate}`,
             (response) => {
                  const blob = new Blob([response.data], { type: 'text/csv' });
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'vestings.csv';
                  link.click();
                  URL.revokeObjectURL(link.href);
             }, this, () => {
                 this.isLoading = false;
             },
             (err) => {
                 this.isLoading = false;
                 this.error = err.response;
        });
      },
      async downloadButton() {
        this.downloadFile();
      },
      showButton() {
        this.queryStringExtraParams = this.vestingsOptions;
        this.updateTable();
      },
      hasAnyModifications(item) {
        return item.modifications.length && !this.toggles.hideVestingModifications;
      },
      handleResize() {
        this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
          && window.innerWidth > this.$windowSizes.MOBILE;
        this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
      },
      showVestingTaxDetailsFilter(headerList) {
        if(!this.toggles.showVestingTaxDetails) {
          return headerList.filter(x => x.value !== 'sharePrice' && x.value !== 'marketValue')
        }

        return headerList;
      },
      hideDetailsColumnFilter(headerList) {
        if(this.hideDetailsColumn) {
          return headerList.filter(x => x.value !== 'data-table-expand')
        }

        return headerList;
      },
      showColumnsForTabletViewFilter(headerList) {
        if (this.isTablet && !this.isMobile) {
          return headerList.filter(x => !this.nonCriticalHeaders.includes(x.value));
        }

        return headerList;
      }
    }
  }
</script>
<template>
    <div class="portfolio-row-flex form-row">
        <p class="col portfolio-row__label font-weight-bold">{{ label }} <i :id="spanId" v-if="tooltipShow" class="far fa-info-circle popover-info" aria-hidden="true"></i></p>
        <p :class="['col portfolio-row__value', { 'text-right' : textRight }]">{{ value }} </p>
        <div>
            <b-popover :target="spanId" triggers="hover" placement="top">
                <span class="rich-text-container" v-html="$sanitize(tooltipText)"> </span>
            </b-popover>
        </div>     
    </div>
    
</template>

<script>
import { v4 as uuid } from 'uuid';
export default {
    props: {
        label: String,
        value: String | Number,
        textRight: Boolean, 
        tooltipText: String,
        tooltipShow : Boolean
    },
    computed: {
        spanId() {
            return `portfolio-row-popover-target_${uuid()}`;
        }
    }
}
</script>
<template>
    <div>
        <PositionDetail :dictionary="dictionary" :orders-dictionary="ordersDictionary" :toggles="toggles"></PositionDetail>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import PositionDetail from './position-detail.component.vue';

    export default {
        components: {
            PositionDetail
        },
        data() {
            return {
        dictionary: null,
        ordersDictionary: null,
        toggles: null,
        isLoading: true
            }
        },
        beforeCreate() {
            getAll([
            '/PositionDetail/Dictionary',
            '/PositionDetail/Toggles',
            '/Orders/Dictionary'
            ], responses => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.ordersDictionary = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);     
                this.isLoading = false;
            }, this);
        }
    };
</script>

<template>
<div>
    <v-card>        
        <v-card-title>
            <h3 class="primary-color h4">{{ dictionary.table.headingDetails }} : {{ choice.choiceStartDate }}</h3>
        </v-card-title>
        <ChoiceDetails  :dictionary="dictionary" :choice="choice"  :isTablet="isTablet" />
    </v-card>    
</div>
</template>

<script>
import ChoiceDetails from "./choice-details.component.vue"

export default {
  components: {
    ChoiceDetails,
  },
  props: {
    choice: Object,
    dictionary: Object,
    isTablet:Boolean
  },
};
</script>
<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <p>{{ dictionary.orderConfirmTitle }}</p>
        <template v-if="!!exerciseScenarioType">
            <ExercisesConfirmationSaleOfShares v-if="exerciseScenarioType.value === $ExerciseScenarioType.SALE_OF_SHARES" :model="exercisesInstructionModel" :dictionary="dictionary" /> 
            <ExercisesConfirmationDeliveryOfShares v-if="exerciseScenarioType.value === $ExerciseScenarioType.DELIVERY_OF_SHARES" :model="exercisesInstructionModel" :dictionary="dictionary" />
            <ExercisesConfirmationSaleAndDeliveryOfShares v-if="exerciseScenarioType.value === $ExerciseScenarioType.SALE_AND_DELIVERY_OF_SHARES" :model="exercisesInstructionModel" :dictionary="dictionary" />
            <ExercisesConfirmationButtons v-if="!exercisesInstructionModel.disclaimers.length" :exercisesInstructionModel="exercisesInstructionModel" v-on="$listeners" />        
        </template>
    </div>
</template>

<script>

import ExercisesConfirmationButtons from "./exercises-confirmation-buttons.component.vue";
import ExercisesConfirmationSaleOfShares from "./exercises-confirmation-sale-of-shares.component.vue";
import ExercisesConfirmationDeliveryOfShares from "./exercises-confirmation-delivery-of-shares.component.vue";
import ExercisesConfirmationSaleAndDeliveryOfShares from "./exercises-confirmation-sale-and-delivery-of-shares.component.vue";

export default {
  components: {
    ExercisesConfirmationButtons,
    ExercisesConfirmationSaleOfShares,
    ExercisesConfirmationDeliveryOfShares,
    ExercisesConfirmationSaleAndDeliveryOfShares,
  },
  props: {
    exercisesInstructionModel: Object,
    toggles: Object,
    dictionary: Object,
    error: String,
  },
  computed: {
      exerciseScenarioType(){
          return this.exercisesInstructionModel.exerciseScenarioType
      }
  },
};
</script>
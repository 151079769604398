var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cancel-add-account-container",
      attrs: { id: "cancel-add-account-container" }
    },
    [
      _c(
        "button",
        {
          staticClass: "btn secondaryButton",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.openCancelAddAccountModal()
            }
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.dictionary.btnCancel) + "\n    ")]
      ),
      _vm._v(" "),
      _c("FinancialCancelAddAccountModal", {
        attrs: {
          dictionary: _vm.dictionary.cancelModal,
          isModalVisible: _vm.isModalVisible
        },
        on: {
          "close-modal": function($event) {
            return _vm.closeCancelAddAccountModal()
          },
          "submit-cancel": function($event) {
            return _vm.submitCancelAddAccount()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="PortfolioOverview" v-if="!isLoading">
        <PortfolioOverview  :dictionary="dictionary"  :toggles="toggles" :model="model" 
            :isSubmitting="isSubmitting"  :isMobile="isMobile" :isTablet="isTablet" :success="success" :error="error"
            :reportParameters="reportParameters" :reportOverview="reportOverview"  >
        </PortfolioOverview>
    </div> 
</template>

<script>

import { get , post} from "api";
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import { EventBus } from "Shared/event-bus";
import PortfolioOverview from "./portfolio/portfolio-overview.component.vue";
import numeral from "numeral";

export default {
  name: "reports-portfolio-overview",
  mixins:[ScreenSizeMixin],
  components: {
    PortfolioOverview,
  },
  props: {
    dictionary: Object,
    toggles: Object,
  },
  data() {
    return  {
      isMobile: false,
      isTablet: false,
      reportParameters: null,
      reportOverview: null,
      model: {
        selectedCurrency: "",
        reportDate: "",
        customSecurityPrice: "",
        customizableSecurityPrices: [],
        customCurrencyRates: []
      },
      isLoading: true,
      isSubmitting: false,
      success: null,
      error: null,
    }
  },
  mounted() {
      get('/Reports/PortfolioOverviewBoundaries',
        (response) => {
          this.reportParameters = response.data;
          this.model.reportDate = response.data.defaultDate;
          this.model.customizableSecurityPrices = response.data.customizableSecurityPrices.map(c =>  { 
                const container = {};                
                container.name = c.name;
                container.securityId = c.securityId;
                container.symbol = c.symbol;
                container.securityPrice = c.securityPrice;  
                
                if (c.securityPriceFormatted != null && c.securityPriceFormatted != '') 
                  container.price = this.extractDecimalOnly(c.securityPriceFormatted);
                else 
                  container.price = null;

                return container
             });

          this.model.customCurrencyRates = response.data.customizableCurrencies.map(c =>  { 
                const container = {};
                container.currencyId = c.id;
                container.symbol = c.symbol;
                container.name = c.name;
                container.isoCode = c.externalIdentifier;                
                container.rate = null;
                return container
             });
             this.isLoading = false;

        }, this);
  },

  methods: {
    getPortfolio(payLoad){
      this.isSubmitting = true;
      this.success = null;
      this.error = null
      const postData = this.prepareSubmitData(payLoad);

      post('/Reports/Overview', postData,
        (response) => {
          this.reportOverview = response.data;
        }, this, () => this.isSubmitting = false);
    },
    resetAllToDefault(){
        this.model.selectedCurrency = "";
        this.model.reportDate = this.reportParameters.defaultDate;
        this.model.customizableSecurityPrices = this.reportParameters.customizableSecurityPrices.map(c =>  { 
              const container = {};
              container.name = c.name;
              container.securityId = c.securityId;
              container.symbol = c.symbol;
              container.securityPrice = c.securityPrice;
              if (c.securityPriceFormatted != null && c.securityPriceFormatted != '') 
                container.price = this.extractDecimalOnly(c.securityPriceFormatted);
              else 
                container.price = null;
              return container
            });
        this.model.customCurrencyRates = this.reportParameters.customizableCurrencies.map(c =>  { 
              const container = {};
              container.currencyId = c.id;
              container.symbol = c.symbol;
              container.name = c.name;
              container.isoCode = c.externalIdentifier;
              container.rate = null;
              return container
            });
        EventBus.$emit('reset', this.model.reportDate);
        this.getPortfolio(this.model)
    },
    resetDefault(){
        this.model.reportDate = this.reportParameters.defaultDate;  
        EventBus.$emit('reset', this.model.reportDate);
        this.getPortfolio(this.model)
    },
    resetDefaultCustomCurrencyRates(){
        this.model.selectedCurrency = "";
        this.model.customCurrencyRates = this.reportParameters.customizableCurrencies.map(c =>  { 
              const container = {};
              container.currencyId = c.id;
              container.symbol = c.symbol;
              container.isoCode = c.externalIdentifier;
              container.name = c.name;
              container.rate = null;
              return container
            });
    },
    resetDefaultSecurityPrices(){
        this.model.customizableSecurityPrices = this.reportParameters.customizableSecurityPrices.map(c =>  { 
              const container = {};
              container.name = c.name;
              container.securityId = c.securityId;
              container.symbol = c.symbol;
              container.securityPrice = c.securityPrice;
              if (c.securityPriceFormatted != null && c.securityPriceFormatted != '') 
                container.price = this.extractDecimalOnly(c.securityPriceFormatted);
              else 
                container.price = null;
              return container
            });
    },
    sendToInboxPortfolio(payLoad) {
        const postData = this.prepareSubmitData(payLoad);
        this.isSubmitting = true;
        this.success = null;
        this.error = null;
        post("/Reports/PortfolioOverviewToInbox",
          postData,
            (response) => {
                this.success = this.dictionary.portfolio.messageSuccess;
                this.isSubmitting = false;
          },
          this,
          () => {
            this.isSubmitting = false;
          },
          (err) => {
              this.error = this.dictionary.portfolio.messageFailure;
              this.isSubmitting = false;
          }
        );
    },
    printPage() { 
      window.print() 
    },  
    extractDecimalOnly(value){
        return value.split(' ')[1]
    },
    prepareSubmitData(payLoad){
        const postData = {
          customDate :  payLoad.reportDate,
          customCurrencyId:  payLoad.selectedCurrency,
          customSecurityPrices:  payLoad.customizableSecurityPrices?.filter(c => !!c.price),
          customCurrencyRates:  payLoad.customCurrencyRates?.filter(c => !!c.rate),
        };
        if (postData.customSecurityPrices.length){
            postData.customSecurityPrices = postData.customSecurityPrices.map(c =>  { 
              const container = {};
              container.securityPrice = c.securityPrice;
              container.securityId = c.securityId;
              container.price = numeral(c.price).value();
              return container
            });
        }
        if (postData.customCurrencyRates.length){
            postData.customCurrencyRates = postData.customCurrencyRates.map(c =>  { 
              const container = {};
              container.currencyId = c.currencyId;
              container.rate = numeral(c.rate).value();
              return container
            });
        }

        return postData;
      } 
  },
  created() {
    EventBus.$on("getPortfolio", this.getPortfolio);
    EventBus.$on("resetAllToDefault", this.resetAllToDefault);
    EventBus.$on("resetDefault", this.resetDefault);
    EventBus.$on("resetDefaultSecurityPrices", this.resetDefaultSecurityPrices);
    EventBus.$on("resetDefaultCustomCurrencyRates", this.resetDefaultCustomCurrencyRates);
    EventBus.$on("sendToInboxPortfolio", this.sendToInboxPortfolio);
    EventBus.$on("printPage", this.printPage);
  }
};
</script>

<template>
<portlet-layout v-if="!!accountDataModel.legalNationality" :title="accountDictionary.widgets.legalInformation" :hideShadow="true">
    <template v-slot:body>
        <div>
            <LegalNationalityView :legalNationalityDataModel="accountDataModel.legalNationality" :dictionary="accountDictionary.legalNationality"></LegalNationalityView>
        </div>
    </template>
</portlet-layout>
</template>

<script>
import LegalNationalityView from "./legal-nationality-view.component.vue";

export default {
  components: {
    LegalNationalityView,
  },
  props: {
    accountDictionary: Object,
    accountDataModel: Object,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "title-block" }, [
        _vm.model && _vm.dictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.confirmation))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "portlets-wrapper" },
        [
          _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
          _vm._v(" "),
          _vm.model && _vm.dictionary
            ? _c(
                "div",
                { staticClass: "row m-0", attrs: { "data-toggle": "portlet" } },
                [
                  _c("portlet-layout", {
                    attrs: {
                      "column-class": "col-xl-6",
                      title: _vm.dictionary.page.confirmation
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function() {
                            return [
                              _c("loading-spinner", {
                                attrs: { active: _vm.isFormProcessing }
                              }),
                              _vm._v(" "),
                              _vm.model.hasRealTimeDealingQuote &&
                              _vm.useRealTimeConfirmationWidget
                                ? _c("ExitConfirmationRtd", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      toggles: _vm.toggles,
                                      exitInstructionViewModel: _vm.model,
                                      error: _vm.error,
                                      quoteExpires: _vm.quoteExpires
                                    },
                                    on: {
                                      "on-quote-expires":
                                        _vm.updateQuoteExpires,
                                      "accept-instruction":
                                        _vm.acceptInstruction,
                                      "requote-instruction":
                                        _vm.requoteInstruction,
                                      "decline-instruction":
                                        _vm.declineInstruction,
                                      "accept-new-quantity":
                                        _vm.newOrderQuantity,
                                      "exit-instruction-go-back":
                                        _vm.exitsInstructionGoBack
                                    }
                                  })
                                : _c("ExitConfirmationClassic", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      toggles: _vm.toggles,
                                      exitInstructionViewModel: _vm.model,
                                      error: _vm.error
                                    },
                                    on: {
                                      "accept-instruction":
                                        _vm.acceptInstruction,
                                      "decline-instruction":
                                        _vm.declineInstruction,
                                      "exit-instruction-go-back":
                                        _vm.exitsInstructionGoBack
                                    }
                                  })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      405295581
                    )
                  }),
                  _vm._v(" "),
                  _vm.showDisclaimersPortlet
                    ? _c("portlet-layout", {
                        attrs: {
                          "column-class": "col-xl-6",
                          title: _vm.dictionary.widgets.disclaimers
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c("ExitConfirmationDisclaimers", {
                                    attrs: {
                                      exitInstructionViewModel: _vm.model,
                                      isQuoteExpires: _vm.quoteExpires
                                    },
                                    on: {
                                      "accept-instruction":
                                        _vm.acceptInstruction,
                                      "requote-instruction":
                                        _vm.requoteInstruction,
                                      "decline-instruction":
                                        _vm.declineInstruction,
                                      "exit-instruction-go-back":
                                        _vm.exitsInstructionGoBack
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1129740412
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
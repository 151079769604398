<template>
  <div class="login-language" v-if="!isLoading && isVisible">
    <custom-dropdown
      id-name="LocaleId"
      :items="items"
      :initial-item="currentLanguage"
      :show-short-name="true"
      :highlight="true"
      eventName="event-select-change-language"
      aria-label="choose a language"
    ></custom-dropdown>
  </div>
</template>

<script>
import { get, post } from "api";
import { EventBus } from "Shared/event-bus";

export default {
  props: {
      currentLanguage: String
  },
  data() {
    return {
      availableLanguages: null,
      isLoading: true
    };
  },
  beforeCreate() {
    get(
      ["/login/Locale"],
      (responses) => {
        this.availableLanguages = responses.data;
        
        this.isLoading = false;
      },
      this,
      () => {}
    );
  },
  mounted() {
      EventBus.$on("event-select-change-language", (payLoad) =>  {
          this.changeLanguage(this.availableLanguages.find((x) => {return x.id == payLoad}).isO3);
      });
  },
  methods: {
    changeLanguage(isO3) {
      post(
          '/login/ChangeLanguage',
          {isO3: isO3},
          (res) => {
              EventBus.$emit("event-language-changed", isO3);
          },
          this,() => { }, (err) => { }
      );
    }
  },
  computed:{
    items() {
      return this.availableLanguages && this.availableLanguages
        .map((language) => {  
            return {
                id: language.id,
                name: language.name,
                imageSrc:  `/images/flags/${language.imageSrc}`,
                isO3: language.isO3,
                displayName: language.displayName
            };
        });
    },
    isVisible() {
      return this.availableLanguages && this.availableLanguages.length > 1;
    }
  }
};
</script>

<template>
  <div class="portfolio-mobile-details-content">
      <PortfolioMobileRow :label="dictionary.tables.subscribedAccepted" :value="position.acceptedAmountFormatted" :textRight="true" />
      <PortfolioMobileRow :label="dictionary.tables.balance" :value="position.balanceFormatted" :textRight="true" />
      <PortfolioMobileRow :label="dictionary.tables.credited" :value="position.creditedAmountFormatted" :textRight="true" />
      <PortfolioMobileRow :label="dictionary.tables.debited" :value="position.debitedAmountFormatted"  :textRight="true"/>
      <PortfolioMobileRow v-if="position.securityPrice" :label="dictionary.tables.securityPrice" :value="position.securityPriceFormatted" :textRight="true" />
      <PortfolioMobileRow :label="dictionary.tables.value" :value="position.valueFormatted" :textRight="true"/>
  </div>
</template>

<script>
import PortfolioMobileRow from "./portfolio-mobile-row.component.vue";

export default {
  components: {
    PortfolioMobileRow
  },
  props: {
    position: Object,
    dictionary: Object,
    toggles: Object
  }
};
</script>
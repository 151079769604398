var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: ["orders-listitem-container", { "d-none": _vm.isEdit }] },
      [
        _c("dt", [_vm._v(_vm._s(_vm.dictionary.confirmation.ordered))]),
        _vm._v(" "),
        _c("dd", [
          _c("span", [_vm._v(_vm._s(_vm.model.nrOrderedFormatted))]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: ["btn-link", "ml-1", { "d-none": !_vm.isActive }],
              attrs: { href: "javascript:void(0)", role: "button" },
              on: { click: _vm.editQuantity }
            },
            [
              _c("i", {
                staticClass: "far fa-edit",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.dictionary.confirmation.quantitySRText))
              ])
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mb-4" }, [
      _c("div", { class: [{ "d-none": !_vm.isEdit }] }, [
        _c(
          "label",
          {
            staticClass: "font-weight-bold form-input-label",
            attrs: { for: "newQuantity" }
          },
          [_vm._v(_vm._s(_vm.dictionary.confirmation.ordered))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group form-inline" }, [
          _c("div", { staticClass: "form-group mr-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newQuantity,
                  expression: "newQuantity"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "number", id: "newQuantity" },
              domProps: { value: _vm.newQuantity },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newQuantity = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn secondaryButton",
              on: { click: _vm.cancelQuantityEdit }
            },
            [_vm._v(_vm._s(_vm.dictionary.form.cancel))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn primaryButton",
              on: { click: _vm.submitNewQuantity }
            },
            [_vm._v(_vm._s(_vm.dictionary.form.submit))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
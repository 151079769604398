<template>
    <alert-component v-if="isVisible" variant="success">{{successMessage}}</alert-component>
</template>

<script>
    export default {
        props: {
            showSuccess: Boolean,
            successMessage: String,
        },
        data() {
            return {
                isVisible: false,
                visibleTimeout: null
            }
        },
        watch: {
            showSuccess: {
                handler(newVal, oldVal) {
                    this.isVisible = newVal;
                    if (newVal) {
                        this.visibleTimeout = setTimeout(() => {
                            this.isVisible = false;
                            this.$emit("alert-visible", this.isVisible);
                        }, 3000)
                    }
                },
                immediate: true
            }
        },
        beforeDestroy() {
            clearTimeout(this.visibleTimeout);
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row employment-history-detail-row" }, [
    _c("div", { staticClass: "col-6 employment-history-detail-row-label" }, [
      _c("p", [_vm._v(_vm._s(_vm.label))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 employment-history-detail-row-value" }, [
      _c("p", [_vm._v(_vm._s(_vm.displayEligibleValue()))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
    <v-card>        
        <v-card-title>
            <h3 class="primary-color h4">{{ dictionary.labels.headingDetails }} : {{ coupon.recordDate }}</h3>
        </v-card-title>
        <CouponDetails  :dictionary="dictionary" :coupon="coupon"  :isTablet="isTablet" />
    </v-card>    
</div>
</template>

<script>
import CouponDetails from "./coupon-details.component.vue"

export default {
  components: {
    CouponDetails,
  },
  props: {
    coupon: Object,
    dictionary: Object,
    isTablet:Boolean
  },
};
</script>
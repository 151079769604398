var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title-block" }, [
        _vm.dictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.titleConfirmation))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _vm.model && _vm.dictionary
        ? _c("div", { staticClass: "portlets-wrapper" }, [
            _c(
              "div",
              { staticClass: "row m-0", attrs: { "data-toggle": "portlet" } },
              [
                _c("portlet-layout", {
                  attrs: {
                    "column-class": "col-md-6",
                    title: _vm.dictionary.widgets.confirmation
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function() {
                          return [
                            _c("loading-spinner", {
                              attrs: { active: _vm.isFormProcessing }
                            }),
                            _vm._v(" "),
                            _c("MoneyTransferConfirmationForm", {
                              attrs: {
                                dictionary: _vm.dictionary.confirmation,
                                commonDictionary: _vm.commonDictionary,
                                moneyTransferInstruction: _vm.model,
                                error: _vm.error
                              },
                              on: {
                                "accept-instruction": _vm.acceptInstruction,
                                "reject-instruction": _vm.rejectInstruction
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3148892660
                  )
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="subscription-info">
        <div class="d-sm-flex justify-content-between">
            <p class="subscription-info-title">{{dictionary.table.subscriptionOpenTitle}}</p>
            <p class="text-sm-right">{{participationSubscription.subscriptionPeriodStartDate | toDateTime }}</p>
        </div>
        <div class="d-sm-flex justify-content-between">
            <p class="subscription-info-title">{{dictionary.table.subscriptionClosedTitle}}</p>
            <p class="text-sm-right">{{participationSubscription.subscriptionPeriodEndDate | toDateTime }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            participationSubscription: Object
        },
    };
</script>
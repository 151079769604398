<template>
    <section v-if="!!model">
        <template v-if="!!model.payments">
            <div class="row" v-for="(payment, index) in model.payments" :key="`payment-${index}`">
                <div class="column-title col">
                    <p class="font-italic">{{payment.name}}</p>
                </div>
                <div class="column-result col text-right"><p class="red-text">{{payment.netAmountFormatted}}</p></div>
            </div>        
        </template>
        <template v-else-if="!!model.exerciseDeliveries">
            <div class="row"  v-for="(exerciseDelivery, index) in model.exerciseDeliveries" :key="`exerciseDelivery-${index}`">
                <div class="column-title col" >
                    <p class="font-italic">{{exerciseDelivery.securityIdentificationDescription}}</p>
                </div>
                <div class="column-result col text-right"><p>{{exerciseDelivery.nrSharesToDeliverFormatted}}</p></div>
            </div>
        </template>
        <template v-else-if="!!model.value">
            <div class="row" >
                <div class="column-title col" >
                    <p class="font-italic">{{model.label}}</p>
                </div>
                <div class="column-result col text-right"><p class="red-text">{{model.value}}</p></div>
            </div>
        </template>
        <template v-else>
            <div class="row" >
                <div class="column-title col" >
                    <p class="font-italic">{{noDataLabel}}</p>
                </div>
                <div class="column-result col text-right"><p class="red-text">-</p></div>
            </div>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        model:Object,
        noDataLabel: String
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingData
    ? _c("component-spinner", { attrs: { spin: _vm.isLoadingData } })
    : _c("div", [
        _c("div", { staticClass: "title-block" }, [
          _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.titleSubscribe))])
        ]),
        _vm._v(" "),
        _vm.participationSubscription
          ? _c("div", { staticClass: "portlets-wrapper" }, [
              _c("div", { staticClass: "portlet-container" }, [
                _c(
                  "div",
                  { staticClass: "row m-0" },
                  [
                    _c(
                      "portlet-layout",
                      {
                        attrs: {
                          "column-class": "col-lg-8 col-xl-9",
                          title: _vm.participationSubscription.name
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "body" },
                          _vm._l(
                            _vm.participationSubscription.availableScenarios,
                            function(scenario) {
                              return _c(
                                "div",
                                {
                                  key:
                                    scenario.participationSubscriptionScenarioId
                                },
                                [
                                  _c("SubscriptionForm", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      subscriptionScenario: scenario,
                                      isSubscriptions: _vm.isSubscriptions,
                                      participationSubscription:
                                        _vm.participationSubscription
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "portlet-layout",
                      {
                        attrs: {
                          "column-class": "col-lg-4 col-xl-3",
                          title: _vm.dictionary.widget.titleSubscriptionInfo
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "body" },
                          [
                            _c("SubscriptionInfo", {
                              attrs: {
                                dictionary: _vm.dictionary,
                                participationSubscription:
                                  _vm.participationSubscription
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c("div", { staticClass: "position-action-links__link-container" }, [
        !_vm.disableLink
          ? _c(
              "a",
              {
                staticClass: "position-action-links__link",
                on: {
                  click: function($event) {
                    return _vm.$emit("onClick")
                  }
                }
              },
              [
                _c("PortfolioPlanPositionName", {
                  attrs: { position: _vm.position }
                })
              ],
              1
            )
          : _c(
              "span",
              { staticClass: "position-action-links__link" },
              [
                _c("PortfolioPlanPositionName", {
                  attrs: { position: _vm.position }
                })
              ],
              1
            )
      ])
    : _c("div", [
        _c(
          "a",
          {
            staticClass: "btn primaryButton mobile",
            attrs: { role: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("onClick")
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.mobileLabel) + "\n")]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
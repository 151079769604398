<template>
    <div class="portlets-wrapper cookie-policy-container">
        <CookiePolicy :dictionary="dictionary" />
        <component-spinner :spin="isLoading"></component-spinner>
    </div>
</template>

<script>
    import { get } from 'api';
    import CookiePolicy from "./cookie-policy.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            CookiePolicy
        },
        data() {
            return {
                dictionary: null,
                isLoading: true
            }
        },
        beforeCreate() {
            this.$emit('set-header', { header: null, subHeader: null, loginButton: false });
        },
        created() {
            this.getDictionary();
        },
        mounted() {
            EventBus.$on("event-language-changed", () => {
                this.getDictionary();
            });
        },
        methods: {
            getDictionary() {
                get('/CookiePolicy/Dictionary', response => {
                    this.dictionary = response.data,
                    this.isLoading = false

                    this.$setPageTitle(this.dictionary.page.title);
                    this.$emit('set-header', { loginButton: false });
                }, this);
            }
        }
    }
</script>
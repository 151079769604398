var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "h4 primary-color" }, [
      _vm._v(_vm._s(_vm.dictionary.modal.cancelOrderConfirmText))
    ]),
    _vm._v(" "),
    _c(
      "dl",
      { staticClass: "order-details-list" },
      [
        _c("OrderDataLabel", {
          attrs: {
            label: _vm.dictionary.modal.positionName,
            value: _vm.instructionData.SecurityName
          }
        }),
        _vm._v(" "),
        _vm.instructionData.OrderType === "Sell"
          ? _c("OrderDataLabel", {
              attrs: {
                label: _vm.dictionary.modal.orderType,
                value: _vm.dictionary.modal.orderTypeSell
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.instructionData.OrderType === "Buy"
          ? _c("OrderDataLabel", {
              attrs: {
                label: _vm.dictionary.modal.orderType,
                value: _vm.dictionary.modal.orderTypeBuy
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("OrderDataLabel", {
          attrs: {
            label: _vm.dictionary.modal.instructionDate,
            value: _vm.instructionData.SubmissionDate
          }
        }),
        _vm._v(" "),
        _c("OrderDataLabel", {
          attrs: {
            label: _vm.dictionary.modal.expirationDate,
            value: _vm.instructionData.ExpirationDate
          }
        }),
        _vm._v(" "),
        _c("OrderDataLabel", {
          attrs: {
            label: _vm.dictionary.modal.quantity,
            value: _vm.instructionData.QuantityFormatted
          }
        }),
        _vm._v(" "),
        _c("OrderDataLabel", {
          attrs: {
            label: _vm.dictionary.modal.limitPrice,
            value: _vm.instructionData.LimitPriceFormatted
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
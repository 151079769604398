var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mfaData && _vm.mfaData.factor
    ? _c(
        "div",
        { staticClass: "mfa-pac" },
        [
          _c("div", { staticClass: "mfa-pac-body" }, [
            _c("p", { staticClass: "mb-2" }, [
              _c("span", {
                staticClass: "rich-text-container",
                domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.mainMessage)) }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: [{ isDisabled: _vm.disableResendButton }, "link"],
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.resendCode()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.newPacLinkText))]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-input-label",
                    attrs: { for: "pac-input" }
                  },
                  [_vm._v(_vm._s(_vm.dictionary.fieldPacLabel))]
                ),
                _vm._v(" "),
                _vm.currentError
                  ? _c("alert-component", { attrs: { variant: "error" } }, [
                      _vm._v(_vm._s(_vm.currentError))
                    ])
                  : _vm.pacSendedOn
                  ? _c("alert-component", { attrs: { variant: "success" } }, [
                      _vm._v(_vm._s(_vm._f("toDateTime")(_vm.pacSendedOn)))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "pac-input",
                    disabled: _vm.disablePacForm
                  },
                  domProps: { value: _vm.value },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", $event.target.value)
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submit()
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("MfaButtons", {
            attrs: {
              dictionary: _vm.dictionary,
              disableSubmit: _vm.disablePacForm
            },
            on: {
              cancel: function($event) {
                return _vm.cancel()
              },
              submit: function($event) {
                return _vm.submit()
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
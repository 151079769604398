<template>
    <ReportsDetail id="quarterlyReportDetails" v-if="dictionary" :dictionary="dictionary"  :toggles="toggles" :reportName="dictionary.quarterly.nameQuarterlyStatement" :reportDescription="dictionary.quarterly.descriptionQuarterlyStatement" :is-mobile="isMobile">
        <template v-slot:body>
          <div >
              <div class="custom-report-form justify-content-start" v-if="!isMobile">             
                  <div class="mr-4">
                    <select-element class="mb-0" v-model="selectedYear" :validationsList="reportValidations.selectedYear"
                      :label="dictionary.quarterly.reportsLabelYear" :options="listYears" :is-mandatory="true" name="quarterlyReportYears">
                    </select-element>
                  </div>
                  <div class="mr-4">
                    <select-element class="mb-0" v-model="selectedQuarter" :validationsList="reportValidations.selectedQuarter"
                      :label="dictionary.quarterly.labelQuarter" :options="listQuarters" :is-mandatory="true" name="quarterlyReportYears">
                    </select-element>
                  </div>
                  <div class="custom-report-form-btn ml-auto">
                    <button  type="button" @click="getQuarterlyStatementReport()"  class="btn primaryButton">{{dictionary.quarterly.buttonQuarterlyStatement}}</button>
                  </div>
              </div>
               <div class="custom-report-form-mobile" v-else>             
                  <div  class="col">
                    <select-element v-model="selectedYear" :validationsList="reportValidations.selectedYear"
                      :label="dictionary.quarterly.reportsLabelYear" :options="listYears" :is-mandatory="true" name="quarterlyReportYears">
                    </select-element>
                  </div>
                  <div  class="col">
                    <select-element v-model="selectedQuarter" :validationsList="reportValidations.selectedQuarter"
                      :label="dictionary.quarterly.labelQuarter" :options="listQuarters" :is-mandatory="true" name="quarterlyReportYears">
                    </select-element>
                  </div>
                  <div class="custom-report-form-btn col">
                    <button  type="button" @click="getQuarterlyStatementReport()"  class="btn primaryButton">{{dictionary.quarterly.buttonQuarterlyStatement}}</button>
                  </div>
             </div>
          </div>
        </template>
    </ReportsDetail> 
</template>

<script>
import ReportsDetail from "./reports-detail.component.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    ReportsDetail,
  },
  props: {
    dictionary: Object,
    toggles: Object,
    boundaries: Object,
    isMobile: Boolean
  },
   data() {
    return {
        selectedYear: this.boundaries.beneficiaryYearResultsReportYears.length > 0 ? this.boundaries.beneficiaryYearResultsReportYears[0] : '',
        selectedQuarter: 1
    }
  },
  validations() {
      return {
        selectedYear: { required },
        selectedQuarter: { required }
      };
  },
  computed: {
    listYears() {
        return this.boundaries.beneficiaryYearResultsReportYears.map(c => this.createDropDownOption(false, false, true, c, c));
    },
    listQuarters() {
        let quarters = [
          { id: 1, text: this.dictionary.quarterly.dropdownQuarterFirst },
          { id: 2, text: this.dictionary.quarterly.dropdownQuarterSecond },
          { id: 3, text: this.dictionary.quarterly.dropdownQuarterThird },
          { id: 4, text: this.dictionary.quarterly.dropdownQuarterFourth }
        ];

      return quarters.map(c => this.createDropDownOption(false, false, false, c.text, c.id));
    },
    reportValidations() {
      return {
        selectedYear:  [{
          id: 1,
          field: this.$v.selectedYear,
          error: this.$v.selectedYear.$error,
          type: this.$v.selectedYear.required,
          message: this.dictionary.quarterly.yearsDropdownRequired
        }],
         selectedQuarter:  [{
          id: 1,
          field: this.$v.selectedQuarter,
          error: this.$v.selectedQuarter.$error,
          type: this.$v.selectedQuarter.required,
          message: this.dictionary.quarterly.dropdownQuarterlyStatementRequired
        }],
        }
    }
  },
  methods: {
    getQuarterlyStatementReport() {
                      
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      this.$emit("getQuarterlyStatementReport", this.selectedYear, this.selectedQuarter);
    },
    createDropDownOption(disabled, group, selected, text, value)   { 
      return {
            disabled: disabled,
            group: group,
            selected: selected,
            text: text,
            value: value
      }
    }
  }
};
</script>

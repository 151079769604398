var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portfolio-summary" }, [
    _c(
      "h4",
      { staticClass: "force-nowrap" },
      [
        _c("span", [
          _vm._v(
            "\n          " +
              _vm._s(_vm.portfolioSummary.blockTitle) +
              "\n          "
          ),
          _vm.portfolioSummary.blockTooltip
            ? _c("i", {
                staticClass: "far fa-info-circle popover-info",
                attrs: { id: _vm.tooltipId, "aria-hidden": "true" }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "b-popover",
          {
            attrs: {
              target: _vm.tooltipId,
              triggers: "hover",
              placement: "top"
            }
          },
          [_c("span", [_vm._v(_vm._s(_vm.portfolioSummary.blockTooltip))])]
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.portfolioSummary.walletValues.length > 1
      ? _c("div", [
          _vm.portfolioSummary.walletValues.length > 1
            ? _c(
                "select",
                {
                  staticClass: "form-control",
                  attrs: { "aria-label": _vm.portfolioSummary.blockTitle }
                },
                _vm._l(_vm.portfolioSummary.walletValues, function(item) {
                  return _c(
                    "option",
                    { key: item.currency, domProps: { value: item.currency } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.walletValue) +
                          "\n          "
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ])
      : _vm.portfolioSummary.walletValues.length
      ? _c("div", [
          _c("h3", [
            _vm._v(
              _vm._s(
                _vm.portfolioSummary.walletValues[0]
                  ? _vm.portfolioSummary.walletValues[0].walletValue
                  : ""
              )
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
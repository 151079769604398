<template>
    <div>
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.listedTrading.title }}</h1>
        </div>
    <div class="portlets-wrapper">
            <component-spinner :spin="isLoading"></component-spinner>
            <ListedTrading v-if="!isLoading" :dictionary="dictionary"></ListedTrading>
    </div>
</div>
</template>

<script>
    import { get } from 'api';
    import ListedTrading from "./listed-trading.component.vue";

    export default {
        components: {
            ListedTrading
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                isLoading: true
            }
        },
        beforeCreate() {
            get("/ListedTrading/Dictionary",
                (response) => {
                    this.dictionary = response.data;
                    this.$setPageTitle(this.dictionary.page.title);
                    this.isLoading = false;
                }, this);
        }
    };
</script>

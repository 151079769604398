<template>
  <div class="beneficiary-data-group form-group">
    <label class="beneficiary-data-group__label-readonly form-input-label">
      {{ label }}
    </label>
    <div class="beneficiary-data-group__true-false-icon">
      <i :class="['fa', icon, iconStyle]" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean,
  },
  data() {
    return {
      icon: this.value ? "fa-check" : "fa-times",
      iconStyle: `beneficiary-data-group__true-false-icon__${
        this.value ? "true" : "false"
      }`,
    };
  },
};
</script>
<template>
    <div>
        <div class="page-description rich-text-container" v-html="$sanitize(dictionary.table.paymentsPageDescription)"></div>

        <v-data-table class="table-striped"
                      show-expand
                      :no-data-text="dictionary.table.noRecordFound"
                      item-key="moneyFinancialInstructionId"
                      :hide-default-header="isMobile"
                      :headers="computedHeaders"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer
                      >
            <!-- Search input at top --->
            <template v-slot:top>
            <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>  

            <template v-slot:[`item.processingDateTime`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.processingDateTime) | toDate }}</span>
            </template>

            <template v-slot:[`item.description`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.description) }}</span>
            </template>

            <template v-slot:[`item.confirmedAmount`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.confirmedAmountFormatted) }}</span>
            </template>

            <template v-slot:[`item.unconfirmedAmount`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.unconfirmedAmountFormatted) }}</span>
            </template>

            <!-- Expanded item template (purchase details & modifications) --->
            <template v-slot:expanded-item="{ headers, item }">
                <td v-if="!isMobile" class="expanded-item-td" :colspan="headers.length">
                <PaymentsTableExpandedItem :dictionary="dictionary" :payment="item" />
                </td>
            </template>

            <!-- Logic for expandable rows if conditions met  --->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                        @click.stop="expand(!isExpanded)" :aria-label="`${item.type} ${item.reference} ${isExpanded ? 'collapse' : 'expand'} more details`">$expand</v-icon>
            </template>

            <!-- Datatable template for mobile view before data rows --->
            <template v-if="isMobile" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="headers"
                                        v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
            </template>
            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <PaymentDetailsMobileContent :dictionary="dictionary" :payment="item" :toggles="toggles" />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>

    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import PaymentDetailsMobileContent from "./payment-details-mobile-content.component.vue";
    import PaymentsTableExpandedItem from "./payments-table-expanded-item.component.vue";

    export default {
        mixins: [vueDataTableMixin],
        components: {
            PaymentDetailsMobileContent,
            PaymentsTableExpandedItem
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            toggles: Object
        },
        data() {
            return {
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                getUrl: '/Payments/All',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["processingDateTime"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'reference', text: this.dictionary.table.reference, align: 'left', sortable: true, visible: true },
                    { value: 'type', text: this.dictionary.table.instructionType, align: 'left', sortable: true, visible: true },
                    { value: 'processingDateTime', text: this.dictionary.table.processingDate, align: 'right', sortable: true, visible: true, className: 'force-nowrap' },
                    { value: 'description', text: this.dictionary.table.description, align: 'left', sortable: true, visible: true },
                    { value: 'confirmedAmount', text: this.dictionary.table.confirmedAmount, align: 'right', sortable: true, visible: true },
                    { value: 'state', text: this.dictionary.table.state, align: 'left', sortable: true, visible: !this.toggles.hideOrderState },
                    { value: 'data-table-expand', text: this.dictionary.table.detailsColumn, align: 'center', sortable: false, visible: true }
                ],
                rows: [],
                totalRecords: 0
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
                    && window.innerWidth > this.$windowSizes.MOBILE;
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            }
        },
        computed: {
            computedHeaders() {
                return this.headers.filter(h => h.visible);
            }
        }
    }
</script>
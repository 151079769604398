import { mustBeDecimal, between, correctDecimalSeparator } from 'Shared/Components/Vue/Validations/custom-validator'
import { required } from "vuelidate/lib/validators";

export const dividendSubscriptionValidation = {
    validations() {
        return {
            dividendAllocationPercentages: {
                moneyDividendAmountPercentage: {
                    correctDecimalSeparator: function (value) {
                        return correctDecimalSeparator(value)
                    },
                    mustBeDecimal: function (value) {
                        return mustBeDecimal(value);
                    },
                    between: function (value) {
                        return between(value, 0, 100);
                    },
                    required
                },
                sharesDividendAmountPercentage: {
                    correctDecimalSeparator: function (value) {
                        return correctDecimalSeparator(value)
                    },
                    mustBeDecimal: function (value) {
                        return mustBeDecimal(value);
                    },
                    between: function (value) {
                        return between(value, 0, 100);
                    },
                    required
                },
            }
        }
    },
    computed: {
        validationsList() {
            return {
                dividendAllocationPercentagesValidation: 
                {
                    moneyDividendAmountPercentageValidation: [
                        {
                            id: 1,
                            error: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.correctDecimalSeparator,
                            message: this.dictionary.subscriptionWidgets.errorInvalidDecimal,
                        },
                        {
                            id: 2,
                            error: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.mustBeDecimal,
                            message: this.dictionary.subscriptionWidgets.errorInvalidNumber,
                        },
                        {
                            id: 3,
                            error: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.between,
                            message: this.dictionary.subscriptionWidgets.errorInvalidPercentage,
                        },
                        {
                            id: 4,
                            error: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.moneyDividendAmountPercentage.required,
                            message: this.dictionary.subscriptionWidgets.errorInvalidNumber,
                        },
                    ],
                    sharesDividendAmountPercentageValidation: [
                        {
                            id: 1,
                            error: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.correctDecimalSeparator,
                            message: this.dictionary.subscriptionWidgets.errorInvalidDecimal,
                        },
                        {
                            id: 2,
                            error: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.mustBeDecimal,
                            message: this.dictionary.subscriptionWidgets.errorInvalidNumber,
                        },
                        {
                            id: 3,
                            error: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.between,
                            message: this.dictionary.subscriptionWidgets.errorInvalidPercentage,
                        },
                        {
                            id: 4,
                            error: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.$error,
                            type: this.$v.dividendAllocationPercentages.sharesDividendAmountPercentage.required,
                            message: this.dictionary.subscriptionWidgets.errorInvalidNumber,
                        },
                    ]
                }
            }
        }
    }
}
export const timelineStepsLogic = {
    data() {
        return {
            currentStep: 1,
            summaryType: 'inLastStep',
            submitingData: false,
            isLoading: false
        };
    },
    methods: {
        nextStep(payLoad) {
            if (payLoad.step === this.finishStep) {
                payLoad.finished = true;
            } else {
                this.currentStep = payLoad.step + 1;
            }
            this.submitStep(payLoad);
        },
        previousStep(payLoad) {
            if (payLoad.step === 1) {
                this.currentStep = 1;
            } else {
                this.currentStep = payLoad.step - 1;
            }
            this.submitStep(payLoad);
        }
    },
    computed: {
        finishStep() {
            return this.summaryType == 'afterLastStep' ? this.steps + 1 : this.steps;
        }
    },
    watch: {
        steps(val) {
            if (this.currentStep > val) {
                this.currentStep = val;
            }
        }
    }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "process-transaction-types-container" } },
    [
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          id: "dt-process-transaction-types",
          "group-by": "planName",
          "no-data-text": _vm.dictionary.noRecordFound,
          "hide-default-header": _vm.isMobile,
          headers: _vm.headers,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "header.action",
              fn: function() {
                return [
                  _c(
                    "span",
                    { attrs: { id: "auto-payment-settings-popover" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.dictionary.automaticPaymentSettings) +
                          " "
                      ),
                      _c("i", {
                        staticClass: "far fa-info-circle popover-info",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var headers = ref.headers
                return [
                  _c(
                    "th",
                    { attrs: { colspan: headers.length, scope: "colgroup" } },
                    [
                      _c("span", { staticClass: "primary-color" }, [
                        _vm._v(_vm._s(group))
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("TransferSettingsDetailActionButton", {
                    attrs: { item: item },
                    on: {
                      "load-items": function($event) {
                        return _vm.loadItems()
                      }
                    }
                  })
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("TransferSettingsDetailMobileContent", {
                        attrs: { dictionary: _vm.dictionary, item: item },
                        on: {
                          "load-items": function($event) {
                            return _vm.loadItems()
                          }
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      !_vm.isMobile
        ? _c(
            "b-popover",
            {
              attrs: {
                target: "auto-payment-settings-popover",
                placement: "left",
                triggers: "hover"
              }
            },
            [
              [
                _c("div", {
                  staticClass: "rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.automaticPaymentSettingsPopover
                      )
                    )
                  }
                })
              ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
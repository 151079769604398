<template>
  <v-expansion-panels accordion >
    <v-expansion-panel>
        <v-expansion-panel-header>
            <h3 class="h4">{{ item.positionName }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div>
                <TransferSettingsDetailContentRow :label="dictionary.positionName" :value="item.positionName" />
                <TransferSettingsDetailContentRow :label="dictionary.transactionType" :value="item.transactionType.description" />
                <TransferSettingsDetailContentRow :label="dictionary.planProcess" :value="item.planProcess.description" />
                <TransferSettingsDetailContentActionRow :label="dictionary.automaticPaymentSettings " :item="item" v-on="$listeners" />
                <div class="transfer-settings-row-flex">
                   <p>*&nbsp;</p><span class="rich-text-container" v-html="$sanitize(dictionary.automaticPaymentSettingsPopover)"></span>
                </div>         
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
import TransferSettingsDetailContentRow from "./transfer-settings-detail-content-row.component.vue";
import TransferSettingsDetailContentActionRow from "./transfer-settings-detail-content-action-row.component.vue";
import TransferSettingsDetailActionButton from "./transfer-settings-detail-action-button.component.vue";

export default {
  components: {
    TransferSettingsDetailContentRow,
    TransferSettingsDetailContentActionRow,
    TransferSettingsDetailActionButton,
  },
  props: {
    item: Object,
    dictionary: Object,
  },
};
</script>
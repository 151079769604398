var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSpinner
    ? _c("component-spinner", { attrs: { spin: _vm.showSpinner } })
    : _c(
        "div",
        [
          _vm.errors && _vm.errors.length > 0
            ? [
                _c("form-error-alert-component", {
                  attrs: { errors: _vm.errors }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.hasHeaders
            ? _vm._l(this.boundaries.headers, function(header, index) {
                return _c("div", {
                  key: index,
                  staticClass: "mb-2 rich-text-container",
                  domProps: { innerHTML: _vm._s(_vm.$sanitize(header)) }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.requiredInfo
            ? _c(
                "timeline-container",
                [
                  _c(
                    "timeline-step",
                    { attrs: { "current-step": _vm.currentStep, step: 1 } },
                    [
                      _c("required-info-crs-step-account-holder", {
                        staticClass: "timeline-step",
                        attrs: {
                          "current-step": _vm.currentStep,
                          step: 1,
                          steps: _vm.steps,
                          dictionary: _vm.dictionary,
                          countries: _vm.knownCountries,
                          "required-info-data": _vm.requiredInfo
                        },
                        on: {
                          "next-step": _vm.nextStep,
                          "previous-step": _vm.previousStep,
                          "edit-step": _vm.editStep
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "timeline-step",
                    { attrs: { "current-step": _vm.currentStep, step: 2 } },
                    [
                      _c("required-info-crs-step-tax-residency", {
                        staticClass: "timeline-step",
                        attrs: {
                          "current-step": _vm.currentStep,
                          step: 2,
                          steps: _vm.steps,
                          dictionary: _vm.dictionary,
                          "required-info-data": _vm.requiredInfo,
                          countries: _vm.knownCountries
                        },
                        on: {
                          "next-step": _vm.nextStep,
                          "previous-step": _vm.previousStep,
                          "edit-step": _vm.editStep
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "timeline-step",
                    { attrs: { "current-step": _vm.currentStep, step: 3 } },
                    [
                      _c("required-info-crs-step-declaration", {
                        staticClass: "timeline-step",
                        attrs: {
                          "current-step": _vm.currentStep,
                          step: 3,
                          steps: _vm.steps,
                          dictionary: _vm.dictionary,
                          "required-info-data": _vm.requiredInfo
                        },
                        on: {
                          "next-step": _vm.nextStep,
                          "previous-step": _vm.previousStep
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loading-spinner", { attrs: { active: _vm.submitingData } })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <ReportsDetail id="CouponReportDetails" v-if="dictionary"  :dictionary="dictionary" :toggles="toggles" :reportName="dictionary.coupon.name" :reportDescription="dictionary.coupon.description" :is-mobile="isMobile">
        <template v-slot:body>
            <div class="custom-report-form" v-if="!isMobile">             
                <div class="custom-report-form-empty-height">
                  <!--Placeholder for date range fields-->
                </div>
                 <div class="custom-report-form-btn">   
                      <button  type="button" @click="getCoupon()" class="btn primaryButton">{{dictionary.coupon.extractButton}}</button>
                 </div>
            </div>
             <div class="d-md-flex justify-content-md-between" v-else>             
                <div class="custom-report-form-empty-height">
                  <!--Placeholder for date range fields-->
                  <span>{{dictionary.coupon.description}}</span>
                </div>
                 <div class="custom-report-form-btn col">   
                      <button  type="button" @click="getCoupon()" class="btn primaryButton">{{dictionary.coupon.extractButton}}</button>
                 </div>
            </div>
        </template>
    </ReportsDetail> 
</template>

<script>
import ReportsDetail from "./reports-detail.component.vue";

export default {
  components: {
    ReportsDetail,
  },
  props: {
    dictionary: Object,
    toggles: Object,
    isMobile: Boolean
  },
  methods: {
    getCoupon() {
      this.$emit("getCoupon");
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "expansion-panel-subscriptions" } },
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "subscriptions-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "subscriptions-details-expansion-panel" },
            [
              _c("v-expansion-panel-header", [
                _c(
                  "div",
                  {
                    staticClass: "subscriptions-details-expansion-panel__header"
                  },
                  [_vm._t("header")],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile", attrs: { eager: "" } },
                [_c("div", [_vm._t("content")], 2)]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <required-info-step
    v-if="requiredInfoModel"
    id="tax-residency-container"
    :currentStep="currentStep"
    :step="step"
    :heading="dictionary.labels.step2"
    :description="dictionary.labels.step2Description"
    :dictionary="dictionary"
    @edit-step="(val) => $emit('edit-step', val)"
  >
    <template v-slot:previous>
      <dl class="details-list tax residency-details">
        <div class="details-list-left">
          <DataLabelRow
            :value="requiredInfoModel.isUSCitizen ? dictionary.labels.usCitizenYes : dictionary.labels.usCitizenNo"
            :label="dictionary.labels.usCitizen"
          />
          <template v-if="requiredInfoModel.isUSCitizen">
            <div class="mb-1">
              <DataLabelRow
                :value="requiredInfoModel.usCitizenNationality.fiscalNumber"
                :label="dictionary.labels.usTin"
              />
              <DataLabelRow
                :value="requiredInfoModel.usCitizenNationality.startDate | toDate"
                :label="dictionary.labels.startDate"
              />
              <DataLabelRow
                :value="requiredInfoModel.usCitizenNationality.endDate | toDate"
                :label="dictionary.labels.endDate"
              />
            </div>
          </template>
          <template v-for="(fiscalNationality, index) in requiredInfoModel.fiscalNationalities">
            <div class="mb-1" :key="index">
             <DataLabelRow
                :value="getCountryName(fiscalNationality.countryId)"
                :label="dictionary.labels.countryOfTaxResidency"
              />
              <DataLabelRow
                :value="fiscalNationality.fiscalNumber"
                :label="dictionary.labels.tiNorNI"
              />
              <DataLabelRow
                :value="fiscalNationality.startDate | toDate"
                :label="dictionary.labels.startDate"
              />
              <DataLabelRow
                :value="fiscalNationality.endDate | toDate"
                :label="dictionary.labels.endDate"
              />
            </div>
          </template>
        </div>
      </dl>
    </template>
    <template v-slot:current>
      <div class="row">
        <div class="col-12">
          <div class="mb-2">
            <span>{{ dictionary.labels.usCitizen }}<i :id="`usCitizenToolTip`" class="far fa-info-circle popover-info" aria-hidden="true"></i>
              <b-popover :target="`usCitizenToolTip`" triggers="hover" placement="bottom">
                <div class="us-citizen-tooltip rich-text-container" v-html="$sanitize(dictionary.tooltips.usCitizen)"></div>
              </b-popover>
            </span>
          </div>
          <div class="form-group">
              <div class="input-group mb-2">
                  <div class="form-check form-check-inline pl-2 pr-2">
                      <input class="form-check-input" type="radio" :value="true" v-model="requiredInfoModel.isUSCitizen" id="isUSCitizenYes" name="isUSCitizen"/>
                  </div>
                  <label class="mb-0 form-input-label" for="isUSCitizenYes">{{ dictionary.labels.usCitizenYes }}</label>
              </div>
              <div class="input-group mb-2">
                  <div class="form-check form-check-inline pl-2 pr-2">
                      <input class="form-check-input" type="radio" :value="false" v-model="requiredInfoModel.isUSCitizen" id="isUSCitizenNo" name="isUSCitizen"/>
                  </div>
                  <label class="mb-0 form-input-label" for="isUSCitizenNo">{{ dictionary.labels.usCitizenNo }}</label>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="fiscalNationalitiesError">
        <div class="col-12">
          <alert-component variant="error" >{{fiscalNationalitiesError.message}}</alert-component>
        </div>
      </div>
      <template v-if="requiredInfoModel.isUSCitizen">
        <div class="row">
          <div class="col-md-6">
            <input-element
              name="usCitizenFiscalNumber"
              v-model="requiredInfoData.usCitizenNationality.fiscalNumber"
              :label="dictionary.labels.usTin"
              :validationsList="usCitizenNationalityValidations.usTin"
            >
            </input-element>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-6">
            <datepicker-element
              name="usCitizenStartDate"
              id="usCitizenStartDate"
              v-model="requiredInfoData.usCitizenNationality.startDate"
              :label="dictionary.labels.startDate"
              :validationsList="usCitizenNationalityValidations.startDate"
              :is-mandatory="true"
              :year-range="'-150:+0'"
            >
            </datepicker-element>
          </div>
          <div class="col-md-6">
            <datepicker-element
              name="usCitizenEndDate"
              id="usCitizenEndDate"
              v-model="requiredInfoData.usCitizenNationality.endDate"
              :label="dictionary.labels.endDate"
              :validationsList="usCitizenNationalityValidations.endDate"
              :commonDictionary="dictionary.common"
              :show-button-panel="true"
              :year-range="'-150:+10'"
            >
            </datepicker-element>
          </div>
        </div>
      </template>
      <div class="fiscal-nationalities-container">
        <p class="mb-2">{{dictionary.labels.otherTaxResidencyDescription}}</p>
        <template v-for="(fiscalNationality, index) in requiredInfoModel.fiscalNationalities">
          <tax-residency-fiscal-nationality 
            :key="fiscalNationality._id"
            :countries="listCountries" 
            :dictionary="dictionary" 
            v-model="requiredInfoModel.fiscalNationalities[index]" 
            @delete-item="removeFiscalNationality(fiscalNationality._id)"
            :validations="getFiscalNationalityValidation(index)"
            >
          </tax-residency-fiscal-nationality>
        </template>
        <modal-basic :is-on="isDeleteNotificationModalVisible"  @close="closeDeleteModal()">
          {{ dictionary.validation.unabletoDelete }}
        </modal-basic>
        <div class="mb-4" v-if="canAddNewFiscalNationality">
          <button @click="addFiscalNationality" class="btn text-white"><i class="far fa-plus mr-2" aria-hidden="true"/>{{dictionary.buttons.addTaxResidency}}</button>
        </div>
      </div>
      <div class="d-md-flex justify-content-md-between button-container">
        <div>
          <button
            class="btn secondaryButton"
            type="button"
            @click="previousStep()"
            :aria-describedby="previousStepAccessibilityLabel(step)">
              {{ dictionary.buttons.previous }}
          </button>
          <button
            class="btn primaryButton"
            type="button"
            @click="nextStep()"
            :aria-describedby="nextStepAccessibilityLabel(step, steps)">
              {{ dictionary.buttons.next }}
          </button>
        </div>
      </div>
    </template>
  </required-info-step>
</template>

<script>
import { v4 as uuid } from "uuid";
import { taxResidencyModelValidations } from "./mixins/required-info-form-validation.mixin";
import DataLabelRow from "../../../Beneficiaries/Assets/Vue/data-label-row.component.vue";
import RequiredInfoStep from "./required-info-step.component.vue";
import TaxResidencyFiscalNationality from './tax-residency-fiscal-nationality.component.vue';
import { createDropDownOption } from './required-info-crs-timeline.component.vue';
import AlertComponent from '../../../../Shared/Assets/Components/Vue/Alert/alert.component.vue';
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  mixins: [taxResidencyModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  components: {
    DataLabelRow,
    RequiredInfoStep,
    TaxResidencyFiscalNationality,
    AlertComponent
  },
  props: {
    dictionary: Object,
    currentStep: Number,
    step: Number,
    requiredInfoData: Object,
    countries: Array,
  },
  data(){
    return {
      isDeleteNotificationModalVisible: false
    }
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.focusOnFirstInvalidInput();
      }
      this.$emit("next-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    },
    previousStep() {
      this.$emit("previous-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    },
    addFiscalNationality(){
      this.requiredInfoModel.fiscalNationalities = [...this.requiredInfoModel.fiscalNationalities, { _id: uuid()}];
    },
    closeDeleteModal(){
      this.isDeleteNotificationModalVisible = false;
    },
    removeFiscalNationality(_id){
      let index = this.requiredInfoModel.fiscalNationalities.findIndex(x=>x._id === _id);
      
      if(index === -1 || this.requiredInfoModel.fiscalNationalities[index].id){
        //stored fiscal nationalities cant be deleted
        this.isDeleteNotificationModalVisible = true;
        return;
      }
      
      this.requiredInfoModel.fiscalNationalities.splice(index, 1);
    },
    getCountryName(id){
      return this.countries.find(x=>x.id === id)?.name;
    },
    getFiscalNationalityValidation(index){
      return {
        countryId: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.fiscalNationalities.$each[index].countryId,
            error: this.$v.requiredInfoModel.fiscalNationalities.$each[index].countryId.$error,
            type: this.$v.requiredInfoModel.fiscalNationalities.$each[index].countryId.required,
            message: this.dictionary.validation.countryOfTaxResidenceBlank,
          },
        ],
        startDate: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.fiscalNationalities.$each[index].startDate,
            error: this.$v.requiredInfoModel.fiscalNationalities.$each[index].startDate.$error,
            type: this.$v.requiredInfoModel.fiscalNationalities.$each[index].startDate.emptyOrNotBeforeStartDate,
            message: this.dictionary.validation.startDateBlank,
          },
        ],
        endDate: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.fiscalNationalities.$each[index].endDate,
            error: this.$v.requiredInfoModel.fiscalNationalities.$each[index].endDate.$error,
            type: this.$v.requiredInfoModel.fiscalNationalities.$each[index].endDate.emptyOrNotBeforeStartDate,
            message: this.dictionary.validation.endDateInvalid,
          },
        ]
      };
    },
    resetUSCitizenValidation(){
      //prevent tooltip to be shown immediatly when changing us citizen state to true
      this.$v.requiredInfoModel.usCitizenNationality.startDate.$reset();
      this.$v.requiredInfoModel.usCitizenNationality.endDate.$reset();
      this.$v.requiredInfoModel.usCitizenNationality.fiscalNumber.$reset();
    }
  },
  computed: {
    requiredInfoModel() {
      return this.requiredInfoData;
    },
    isUSCitizen(){
      return this.requiredInfoModel.isUSCitizen;
    },
    listCountries() {
      return this.countries.map((c) =>
        createDropDownOption(false, false, false, c.name, c.id)
      );
    },
    USACountry() {
      return this.countries.find((c) => c.externalIdentifier === "US");
    },
    usCitizenNationalityValidations() {
      return {
        usTin: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.usCitizenNationality.fiscalNumber,
            error: this.$v.requiredInfoModel.usCitizenNationality.fiscalNumber.$error,
            type: this.$v.requiredInfoModel.usCitizenNationality.fiscalNumber.validUSTin,
            message: this.dictionary.validation.invalidUSTIN,
          },
        ],
        startDate: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.usCitizenNationality.startDate,
            error: this.$v.requiredInfoModel.usCitizenNationality.startDate.$error,
            type: this.$v.requiredInfoModel.usCitizenNationality.startDate.required,
            message: this.dictionary.validation.startDateBlank,
          },
        ],
        endDate: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.usCitizenNationality.endDate,
            error: this.$v.requiredInfoModel.usCitizenNationality.endDate.$error,
            type: this.$v.requiredInfoModel.usCitizenNationality.endDate.emptyOrNotBeforeStartDate,
            message: this.dictionary.validation.endDateInvalid,
          },
        ]
      };
    },
    canAddNewFiscalNationality(){
      return this.requiredInfoModel.fiscalNationalities.length <  4;
    },
    fiscalNationalitiesError(){
      return this.nationalityValidations.find(x=>x.error && !x.type)
    },
    nationalityValidations() {
      return [
          {
            id: 1,
            error: this.$v.requiredInfoModel.fiscalNationalities.$error,
            type: this.$v.requiredInfoModel.fiscalNationalities.required,
            message: this.dictionary.validation.noTaxResidencies,
          },
          {
            id: 2,
            error: this.$v.requiredInfoModel.fiscalNationalities.$error,
            type: this.$v.requiredInfoModel.fiscalNationalities.atLeastOneCurrentFiscalNationality,
            message: this.dictionary.validation.noCurrentTaxResidencies,
          },
        ]
    },
  },
  watch: {
    isUSCitizen: function(newValue){
      if(newValue){
        this.resetUSCitizenValidation();

        //load USA into usCitizenNAtionality if its from the database
        let index = this.requiredInfoModel.fiscalNationalities.findIndex(x => x.countryId === this.USACountry.id && !!x.id);
        if (index >= 0) {
          this.requiredInfoModel.usCitizenNationality = this.requiredInfoModel.fiscalNationalities.splice(index, 1)[0]; //split USA nationality from other fiscal nationalities (we join them right before submitting)
        }
      }else{
        //add to list of nationalities if the current is from the database
        if(!!this.requiredInfoModel.usCitizenNationality.id){
          this.requiredInfoModel.fiscalNationalities = [ this.requiredInfoModel.usCitizenNationality, ...this.requiredInfoModel.fiscalNationalities ];
        }
      }
    }
  }
};
</script>
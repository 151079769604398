<template>
  <beneficiary-form :edit-model="addressDataEditModel" form-name="address-edit-form" :common-dictionary="commonDictionary" :account-dictionary="accountDictionary"
                  url-post-action="/Beneficiaries/EditAddress" child-component="beneficiary-address-edit"
                  v-on:form-cancelled="addressEditFormCancelled"
                  v-on:form-submitted="reloadPage"></beneficiary-form>
</template>

<script>
import BeneficiaryForm from "./form.component.vue";
import BeneficiaryAddressEdit from './address-edit.component.vue';

export default {
    components: {
        BeneficiaryForm,
        BeneficiaryAddressEdit
    },
    props: {
        addressDataEditModel : Object,
        commonDictionary: Object,
        accountDictionary: Object
    },
    methods: {
        addressEditFormCancelled() {
            this.$emit('address-edit-form-cancelled')
        },
        reloadPage() {
            this.$emit('reload-page')
        },
    }    
}
</script>
<template>
    <div v-if="dictionary">
        <v-data-table class="table-striped"
                :hide-default-header="isMobile"
                :headers="headers"
                :items="rows"
                :options.sync="options">
            <template v-slot:[`item.startDate`]="{ item }">
                {{  item.startDate | toDate }}
            </template>

            <template v-slot:[`item.endDate`]="{ item }">
                {{  item.endDate | toDate }}
            </template>

            <template v-slot:[`item.custom-buttons`]="{ item }">
                <a @click="openModal(item)" class="employment-details-button"> {{ dictionary.details }} </a>
            </template>
        </v-data-table>
        <employment-history-detail-modal
                :dictionary="dictionary"
                :showModal="isModalOpened"
                :selectedLocation="selectedLocation"
                @close-modal="onModalClosed()">
        </employment-history-detail-modal>
    </div>
</template>

<script>

import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import EmploymentHistoryDetailModal from './employment-history-detail-modal.component.vue';

export default {
    mixins: [ScreenSizeMixin],
    components: {
        EmploymentHistoryDetailModal
    },
    props: {
        dictionary: Object,
        employmentHistory: Array,
    },
    data() {
        return {
            options: {
                itemsPerPage: 10,
                page: 1,
                sortDesc: [false]
            },
            headers: [
                { value: 'location', text: this.dictionary.location, align: 'left', sortable: false },
                { value: 'startDate', text: 'Start Date', align: 'left', sortable: true },
                { value: 'endDate', text: 'End Date', align: 'left', sortable: true },
                { value: 'appointment', text: 'Appointment', align: 'left', sortable: false },
                { value: 'custom-buttons', text: '', align: 'left', sortable: false, visible: !this.isMobile }
            ],
            rows: this.employmentHistory,
            isModalOpened: false,
            selectedLocation: {}
        }
    },
    methods: {
        openModal(employmentListItem) {
            this.selectedLocation = employmentListItem;
            this.isModalOpened = true;
        },
        onModalClosed() {
            this.isModalOpened = false;
            this.selectedLocation = {};
        }
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "money-transfer-vm" } },
    [
      _c("router-view", {
        attrs: { dictionary: _vm.dictionary, toggles: _vm.toggles }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline-step-accordion" },
    [
      _c("h2", { staticClass: "timeline-step-header" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: _vm.accordionId,
                expression: "accordionId"
              }
            ]
          },
          [
            _c("span", { staticClass: "timeline-accordion-header" }, [
              _vm._v("\n        " + _vm._s(_vm.heading) + "\n      ")
            ]),
            _vm._v(" "),
            !_vm.isSummary
              ? _c("span", { staticClass: "timeline-accordion-icon" }, [
                  _c("i", {
                    class: [
                      "accordion-toggle-icon far",
                      _vm.isVisible ? "fa-chevron-up" : "fa-chevron-down"
                    ],
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.accordionId, role: "tabpanel" },
          model: {
            value: _vm.isVisible,
            callback: function($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "timeline-step-static-details" },
            [
              _vm._t("default"),
              _vm._v(" "),
              !_vm.isSummary
                ? _c(
                    "button",
                    {
                      staticClass: "primaryButton btn edit-step-button",
                      on: {
                        click: function($event) {
                          return _vm.editStep()
                        }
                      }
                    },
                    [
                      _vm._v(_vm._s(_vm.dictionary.buttons.edit)),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.heading))
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.beneficiaryAccountData
    ? _c("div", { staticClass: "print-header print-only" }, [
        _c("div", { staticClass: "row" }, [
          _c("img", { attrs: { src: _vm.skin.images.logo, alt: "App Logo" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row print-header-address" }, [
          _vm.beneficiaryAccountData.beneficiaryBoundaries &&
          _vm.beneficiaryAccountData.beneficiaryBoundaries.mayViewPersonalData
            ? _c("div", { staticClass: "col-6 text-left" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._v(_vm._s(_vm.beneficiaryAccountData.fullName))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm._v(
                    _vm._s(
                      _vm.beneficiaryAccountData.currentAddress.addressProperty
                    ) + " "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm._v(
                    _vm._s(
                      _vm.beneficiaryAccountData.currentAddress.postalCode
                    ) +
                      " " +
                      _vm._s(_vm.beneficiaryAccountData.currentAddress.city)
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm._v(
                    _vm._s(_vm.beneficiaryAccountData.currentAddress.country)
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.dictionary
          ? _c("div", { staticClass: "print-header-address" }, [
              _c("div", { staticClass: "row" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.dictionary.block.portfolioOverviewSubHeadingReportDate
                    ) + " "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "col-8" }, [
                  _vm._v(_vm._s(_vm._f("toDateTime")(_vm.getDateTimeNow)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.dictionary.block
                        .portfolioOverviewSubHeadingReportUserId
                    ) + " "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "col-8" }, [
                  _vm._v(_vm._s(_vm.beneficiaryAccountData.userName))
                ])
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<portlet-layout :title="dictionary.page.subtitle" >
    <template v-slot:body>
        <VestingsTable :dictionary="dictionary" :toggles="toggles" :datatablesDictionary="datatablesDictionary" />
    </template>
</portlet-layout>
</template>

<script>
import VestingsTable from './vestings-table.component.vue';

export default {
    components: {
        VestingsTable
    },
    props: {
        dictionary: Object,
        toggles: Object,
        datatablesDictionary: Object
    }
}
</script>
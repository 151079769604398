<template>
    <div class="toggle">
        <div class="row">
            <button type="button" name="mfa-switch"
                :class="['toggle-button', 'btn-sm', 'btn-toggle', toggleValue ? 'active' : '']" data-toggle="button"
                @click="switchToggle" :disabled="isDisabled" :aria-pressed="toggleValue">
                <span class="handle"></span>
            </button>
            <label for="mfa-switch" class="form-input-label">{{ label }}</label>
        </div>
        <div v-for="(error, index) in errors" v-bind:key="index">
            <alert-component variant="info">{{ error }}</alert-component>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            toggleValue: Boolean,
            errors: {
                type: Array,
                default: () => []
            },
            label: String,
            isDisabled:Boolean
        },
        data() {
            return {
                isWaiting: false
            }
        },
        methods: {
            switchToggle() {
                if (!this.isDisabled) {
                    this.$emit("on-switch", !this.toggleValue);
                }
            }
        }
    }
</script>
<template>
    <div class="transfer-settings-row-flex form-row">
        <p class="transfer-settings-row__label col-6 font-weight-bold">{{ label }}</p>
        <TransferSettingsDetailActionButton :item="item" v-on="$listeners" />
    </div>
</template>

<script>
import TransferSettingsDetailActionButton from "./transfer-settings-detail-action-button.component.vue";

export default {
  components: {
    TransferSettingsDetailActionButton,
  },
  props: {
    item: Object,
    label: String,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-account-card-body" }, [
    _c("div", { staticClass: "default-account-description" }, [
      _c("p", {
        staticClass: "rich-text-container",
        domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.description)) }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "py-1" },
      [_c("FormErrorAlert", { attrs: { errors: _vm.errors } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("BeneficiaryDataLabel", {
          staticClass: "col-md-6",
          attrs: {
            value: _vm.accountData.iban,
            "display-value": _vm.accountData.accountNumber,
            label: _vm.dictionary.defaultAccountModal.accountNumber
          }
        }),
        _vm._v(" "),
        _c("BeneficiaryDataLabel", {
          staticClass: "col-md-6",
          attrs: {
            value: _vm.accountData.bankName,
            label: _vm.dictionary.defaultAccountModal.bankName
          }
        }),
        _vm._v(" "),
        _c("BeneficiaryDataLabel", {
          staticClass: "col-md-6",
          attrs: {
            value: _vm.accountData.currency.name,
            label: _vm.dictionary.defaultAccountModal.currency
          }
        }),
        _vm._v(" "),
        _c("BeneficiaryDataLabel", {
          staticClass: "col-md-6",
          attrs: {
            value: _vm.accountData.country.name,
            label: _vm.dictionary.defaultAccountModal.country
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
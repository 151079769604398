<template>
    <div id="cancel-add-account-container" class="cancel-add-account-container">
        <button class="btn secondaryButton" type="button" @click="openCancelAddAccountModal()" >
            {{dictionary.btnCancel}}
        </button>  

        <FinancialCancelAddAccountModal :dictionary="dictionary.cancelModal" :isModalVisible="isModalVisible" @close-modal="closeCancelAddAccountModal()" @submit-cancel="submitCancelAddAccount()" />
    </div>
</template>

<script>
import FinancialCancelAddAccountModal from "./financial-cancel-add-account-modal.component.vue";

export default {
  components: {
    FinancialCancelAddAccountModal
  },
  props: {
    dictionary: Object
  },
  data() {
    return {
      isModalVisible: false
    };
  },
  methods: {
    submitCancelAddAccount(){
      this.closeCancelAddAccountModal();
      this.$emit('cancel-add-account');
    },
    closeCancelAddAccountModal() {
      this.isModalVisible = false;
    },
    openCancelAddAccountModal() {
      this.isModalVisible = true;
    }
  }
};

</script>
<template>
    <div id="PortfolioOverview" v-if="!isLoading" ref="PortfolioOverview">
      <portlet-row v-if="dictionary"  :title="portfolioOverviewDictionary.subHeading" >
        <div class="p-2">
          <div class="rich-text-container" v-html="$sanitize(portfolioOverviewDictionary.description)"></div>
        </div>
      </portlet-row>
      <PortfolioReportParametersForm  ref="PortfolioOverviewForm" :isSubmitting="isSubmitting" 
          :portfolioOverviewDictionary="portfolioOverviewDictionary" 
          :model="model" :customCurrencies="customCurrencies" :isMobile="isMobile" :isTablet="isTablet" :getSelectedCurrency="getSelectedCurrency">
      </PortfolioReportParametersForm>

      <PortfolioReportOverviewDetail ref="PortfolioOverviewDetail" v-if="portfolioOverviewDictionary && reportOverview"  :model="model" 
          :portfolioOverviewDictionary="portfolioOverviewDictionary" :reportCustomTitle="reportCustomTitle" 
          :reportOverview="reportOverview" :success="success" :error="error"  :isSubmitting="isSubmitting" 
          :isMobile="isMobile" :isTablet="isTablet">
      </PortfolioReportOverviewDetail>      
    </div> 
</template>

<script>
import {reportPortfolioOverviewMixin} from "../mixins/reports-portfolio-overview.mixin";
import PortfolioReportParametersForm from "./portfolio-report-parameters-form.component.vue";
import PortfolioReportOverviewDetail from "./portfolio-report-overview-detail.component.vue";

export default {
  name: "portfolio-overview",
  mixins: [ reportPortfolioOverviewMixin],
  components: {
    PortfolioReportParametersForm,
    PortfolioReportOverviewDetail
  }
};
</script>

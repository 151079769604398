var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-links-collection" },
    [
      _c(
        "router-link",
        {
          attrs: { to: "/cookiePolicy", title: _vm.dictionary.linkCookiePolicy }
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.dictionary.linkCookiePolicy) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { attrs: { to: "/disclaimer", title: _vm.dictionary.linkDisclaimer } },
        [_vm._v("\n      " + _vm._s(_vm.dictionary.linkDisclaimer) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.toggles.showTermsAndConditionsLink
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.$sanitize(
                  _vm.dictionary.footerLinkTermsAndConditionsUrl
                ),
                title: _vm.dictionary.footerLinkTermsAndConditions,
                target: "_blank",
                rel: "noopener"
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.dictionary.footerLinkTermsAndConditions) +
                  "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.toggles.showPrivacyPolicyLink
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.$sanitize(_vm.dictionary.footerLinkPrivacyPolicyUrl),
                title: _vm.dictionary.linkPrivacyPolicy,
                target: "_blank",
                rel: "noopener"
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.dictionary.linkPrivacyPolicy) +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="orders-mobile-header">
        <span class="h4">{{ title }}</span>
    </div>
</template>

<script>

export default {
    props: {
        title: String
    }
};
</script>
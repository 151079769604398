var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("ReportsDetail", {
        attrs: {
          id: "quarterlyReportDetails",
          dictionary: _vm.dictionary,
          toggles: _vm.toggles,
          reportName: _vm.dictionary.quarterly.nameQuarterlyStatement,
          reportDescription:
            _vm.dictionary.quarterly.descriptionQuarterlyStatement,
          "is-mobile": _vm.isMobile
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  _c("div", [
                    !_vm.isMobile
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "custom-report-form justify-content-start"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mr-4" },
                              [
                                _c("select-element", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    validationsList:
                                      _vm.reportValidations.selectedYear,
                                    label:
                                      _vm.dictionary.quarterly.reportsLabelYear,
                                    options: _vm.listYears,
                                    "is-mandatory": true,
                                    name: "quarterlyReportYears"
                                  },
                                  model: {
                                    value: _vm.selectedYear,
                                    callback: function($$v) {
                                      _vm.selectedYear = $$v
                                    },
                                    expression: "selectedYear"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mr-4" },
                              [
                                _c("select-element", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    validationsList:
                                      _vm.reportValidations.selectedQuarter,
                                    label:
                                      _vm.dictionary.quarterly.labelQuarter,
                                    options: _vm.listQuarters,
                                    "is-mandatory": true,
                                    name: "quarterlyReportYears"
                                  },
                                  model: {
                                    value: _vm.selectedQuarter,
                                    callback: function($$v) {
                                      _vm.selectedQuarter = $$v
                                    },
                                    expression: "selectedQuarter"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "custom-report-form-btn ml-auto" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primaryButton",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getQuarterlyStatementReport()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.quarterly
                                          .buttonQuarterlyStatement
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "custom-report-form-mobile" },
                          [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("select-element", {
                                  attrs: {
                                    validationsList:
                                      _vm.reportValidations.selectedYear,
                                    label:
                                      _vm.dictionary.quarterly.reportsLabelYear,
                                    options: _vm.listYears,
                                    "is-mandatory": true,
                                    name: "quarterlyReportYears"
                                  },
                                  model: {
                                    value: _vm.selectedYear,
                                    callback: function($$v) {
                                      _vm.selectedYear = $$v
                                    },
                                    expression: "selectedYear"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("select-element", {
                                  attrs: {
                                    validationsList:
                                      _vm.reportValidations.selectedQuarter,
                                    label:
                                      _vm.dictionary.quarterly.labelQuarter,
                                    options: _vm.listQuarters,
                                    "is-mandatory": true,
                                    name: "quarterlyReportYears"
                                  },
                                  model: {
                                    value: _vm.selectedQuarter,
                                    callback: function($$v) {
                                      _vm.selectedQuarter = $$v
                                    },
                                    expression: "selectedQuarter"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "custom-report-form-btn col" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primaryButton",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getQuarterlyStatementReport()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.quarterly
                                          .buttonQuarterlyStatement
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                  ])
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          3233020991
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
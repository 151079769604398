export const beneficiaryTabsViewRightsMixin = {
    computed: {      
        mayViewAccountData(){
            return ((this.mayViewPersonalData && this.mayViewBeneficiaryData)||
                (this.mayViewPersonalData && !this.mayViewBeneficiaryData && this.hasAnyPersonalSettingsRights));
        },
        mayViewFinancialData() {
            return (this.mayViewPersonalData && this.mayViewBeneficiaryData &&
                !this.beneficiaryToggles.hideFinancialTab && 
                (this.beneficiaryBoundariesDataModel.mayViewMoneyBankAccount
                    || this.beneficiaryBoundariesDataModel.mayViewSecuritiesBankAccount
                    || !this.beneficiaryToggles.hideTransferSettings));
        },
        mayViewBeneficiaryData(){
            return this.beneficiaryBoundariesDataModel.mayViewBeneficiaryDetails;
        },
        mayViewPersonalData(){
            return this.beneficiaryBoundariesDataModel.mayViewPersonalData;
        },
        mayViewEvaluationData(){
            return (!this.beneficiaryToggles.hideEvaluationTab) && 
            (!this.beneficiaryToggles.hideRelationships || 
            !this.beneficiaryToggles.hideRequiredDocumentsWidget ||
            !this.beneficiaryToggles.hideQuestionnaireWidget);
        },
        mayViewCompanyData(){
            return (this.beneficiaryBoundariesDataModel.mayViewPersonalData && 
                this.mayViewBeneficiaryData &&
                !this.beneficiaryToggles.hideCompanyTab && 
                this.accountDataModel.currentEmployment != null);
        },
        hasAnyPersonalSettingsRights()
        {
            return this.beneficiaryBoundariesDataModel.mayChangePassword || 
                this.beneficiaryBoundariesDataModel.mayEditLocale ||
                this.beneficiaryBoundariesDataModel.mayEditMfaEnabled;
        },
        showAvatarIcon() {
            return this.mayViewAccountData || this.mayViewFinancialData || this.mayViewEvaluationData || this.mayViewCompanyData;
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-right force-nowrap" }, [
    _c("span", [_vm._v(_vm._s(_vm.header.text) + " ")]),
    !_vm.toggles.hideReferencePriceTooltipColumn
      ? _c("i", {
          staticClass: "far fa-info-circle popover-info",
          attrs: { id: _vm.spanId, "aria-hidden": "true" }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "b-popover",
          {
            attrs: {
              target: _vm.spanId,
              triggers: "hover",
              placement: "top",
              boundary: "window"
            }
          },
          [
            _c("span", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(
                    _vm.dictionary.block.portfolioReferencePriceColumnTooltip
                  )
                )
              }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="dictionary" id="orders-table-container" class="orders-table-container">
        <div v-if="hasExitSecondaryPositions">
            <small>*{{ dictionary.multiplePositions }}</small>
        </div>
        <v-data-table :id="id" class="table-striped"
            item-key="reference"
            :no-data-text="dictionary.noRecordOrders"
            :hide-default-header="isMobile"
            :headers="headers"
            :items="rows"
            :loading="isLoading"
            :server-items-length="totalRecords"
            :search="search"
            :mobile-breakpoint="0"
            :items-per-page="options.itemsPerPage"
            @page-count="options.pageCount = $event;"
            :options.sync="options"
            hide-default-footer
            >

            <template v-slot:top>
                <datatable-search :isMobile="isMobile" :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

            <template v-slot:[`item.submissionDate`]="{ item }">
                {{ item.submissionDate | toDateTime }}
            </template>

            <template v-slot:[`item.expirationDate`]="{ item }">
                {{ item.expirationDate | toDateTime }}
            </template>

            <template v-slot:[`item.limitPrice`]="{ item }">
                {{ item.limitPrice | toMinusIfNull }}
            </template>

            <template v-slot:[`item.averagePrice`]="{ item }">
                {{ item.averagePrice | toMinusIfNull }}
            </template>
            
            <template v-slot:[`item.totalTransactionValue`]="{ item }">
                {{ item.totalTransactionValue | toMinusIfNull }}
            </template>

            <template v-slot:[`item.productName`]="{ item }">
                <div v-if="item.canBeCancelledByBeneficiary" class="mobile-column tablet-column primary-color">{{ item.submissionDate | toDateTime }} | {{item.ordered}} | {{item.state}} | 
                    <OrderCancelLink v-if="item.canBeCancelledByBeneficiary" :order="item" :cancel-link-text="dictionary.btnCancelOrder"></OrderCancelLink>
                </div>
                <div v-if="!item.canBeCancelledByBeneficiary" class="mobile-column tablet-column primary-color">{{ item.submissionDate | toDateTime }} | {{item.ordered}} | {{item.state}}</div>
                <div>
                    <OrderDetailsLink :order="item"></OrderDetailsLink>
                </div>
            </template>           
            
            <template v-if="hasCanBeCancelledByBeneficiarys" v-slot:[`item.canBeCancelledByBeneficiary`]="{ item }">
                <OrderCancelLink v-if="item.canBeCancelledByBeneficiary" :order="item" :cancel-link-text="dictionary.btnCancelOrder"></OrderCancelLink>
            </template>

            <template v-if="isMobile" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="headers"
                                 v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
                <OrdersDetailsMobileHeader :title="dictionary.positionName" />
            </template>

            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <OrdersDetailsMobileRow :dictionary="dictionary" :toggles="toggles" :item="item" />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { vueDataTableMixin } from "Shared/Mixins/vue-data-table.mixin";
import OrderDetailsLink from "./order-details-link.component.vue";
import OrderCancelLink from "./order-cancel-link.component.vue";
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import OrdersDetailsMobileRow from './orders-details-mobile-row.component.vue';
import OrdersDetailsMobileHeader from './orders-details-mobile-header.component.vue';
import { EventBus } from "Shared/event-bus";

export default {
    mixins: [ vueDataTableMixin, ScreenSizeMixin],
    components: {
        OrderDetailsLink,
        OrderCancelLink,
        OrdersDetailsMobileRow,
        OrdersDetailsMobileHeader
    },
    props: {
        getUrl: String,
        id: String,
        dictionary: {
            type: Object,
            required: true,
        },
        datatablesDictionary: {
            type: Object,
            required: true,
        },
        isHistoryOrders: {
            type: Boolean,
            default: false,
        },
        toggles: Object
    },
    data() {
        return {
            nonCriticalHeaders: [],
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["submissionDate"],
                sortDesc: [true],
                pageCount: 0,
                itemsPerPageOptions: [
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: this.datatablesDictionary.showAll, value: -1 }
                ]
            },
            rows: [],
            totalRecords: 0,
            timer: null
        };
    },
    computed: {
        headers() {
            let shouldShowCanBeCancelledByBeneficiarysHeader = this.hasCanBeCancelledByBeneficiarys;
            let headers = this.isHistoryOrders ? this.headersForHistoryOrders : this.headersForOpenOrders(shouldShowCanBeCancelledByBeneficiarysHeader);
            return headers.filter(h => h.visible);
        },
        headersForHistoryOrders() {
            return [
                { value: "submissionDate", text: this.dictionary.instructionDate, align: "left", sortable: true, visible: true, width: '7%', class: "force-nowrap", cellClass: "force-nowrap" },
                { value: "reference", text: this.dictionary.reference, align: "left", sortable: true, visible: true, width: '7%' },
                { value: "productName", text: this.dictionary.positionName, align: "left", sortable: true, visible: true, width: '45%' },
                { value: "orderTypeDescription", text: this.dictionary.instruction, align: "left", sortable: true, visible: true },
                { value: "state", text: this.dictionary.state, align: "left", sortable: true, visible: !this.toggles.hideOrderState },
                { value: "ordered", text: this.dictionary.ordered, align: "left", sortable: true, visible: true},
                { value: "processed", text: this.dictionary.processed, align: "left", sortable: true, visible: true},
                { value: "cancelled", text: this.dictionary.cancelled, align: "left", sortable: true, visible: true},
                { value: "limitPrice", text: this.dictionary.limitPrice, align: "left", sortable: true, visible: true},
                { value: "averagePrice", text: this.dictionary.price, align: "left", sortable: true, visible: true},
                { value: "totalTransactionValue", text: this.dictionary.totalValue, align: "left", sortable: true, visible: true}
            ]
        },
        hasExitSecondaryPositions() {
            return this.rows.some(x => x.hasExitSecondaryPositions);
        },
        hasCanBeCancelledByBeneficiarys() {
            return this.rows.some(x => x.canBeCancelledByBeneficiary);
        }
    },
    created() {
        this.timer = setInterval(this.loadItems, 60 * 1000);
        EventBus.$on('reload-orders-items', this.loadItems)
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        headersForOpenOrders(hasCanBeCancelledByBeneficiaryHeader) {
            let headers = [
                { value: "submissionDate", text: this.dictionary.instructionDate, align: "left", sortable: true, visible: true, width: '7%', class: "force-nowrap", cellClass: "force-nowrap" },
                { value: "reference", text: this.dictionary.reference, align: "left", sortable: true, visible: true, width: '7%' },
                { value: "productName", text: this.dictionary.positionName, align: "left", sortable: true, visible: true, width: '45%' },
                { value: "orderTypeDescription", text: this.dictionary.instruction, align: "left", sortable: true, visible: true },
                { value: "state", text: this.dictionary.state, align: "left", sortable: true, visible: !this.toggles.hideOrderState },
                { value: "expirationDate", text: this.dictionary.expiryDate, align: "left", sortable: true, visible: true, width: '7%' },
                { value: "ordered", text: this.dictionary.ordered, align: "left", sortable: true, visible: true },
                { value: "processed", text: this.dictionary.processed, align: "left", sortable: true, visible: true },
                { value: "limitPrice", text: this.dictionary.limitPrice, align: "left", sortable: true, visible: true }
            ];

            if (hasCanBeCancelledByBeneficiaryHeader)
                headers.push( { value: "canBeCancelledByBeneficiary", text: this.dictionary.cancelOrder, align: "center", sortable: true, visible: true });
            return headers;
        }
    }
};
</script>
<template>
    <router-view :dictionary="dictionary" :is-loading="isLoading" :toggles="toggles" :is-subscriptions="isSubscriptions" :pagesDictionary="pagesDictionary" />
</template>

<script>
    import { get } from 'api';
    
    export default {
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                toggles: null,
                isLoading: true,
                isSubscriptions: true,
            }
        },
        beforeCreate() {
            get([
                "/Subscriptions/Dictionary"
            ], (responses) => {
                this.dictionary = responses.data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    }
</script>
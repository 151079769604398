var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c("section", { staticClass: "report-panel" }, [
        _c("div", { staticClass: "report-panel-inner custom-report-panel" }, [
          _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "h4" }, [_vm._v(_vm._s(_vm.reportName))]),
              _vm._v(" "),
              _c("p", {
                staticClass: "rich-text-container",
                domProps: {
                  innerHTML: _vm._s(_vm.$sanitize(_vm.reportDescription))
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", [_vm._t("body")], 2)
        ])
      ])
    : _c(
        "div",
        [
          _c("div", { staticClass: "report-accordion-header" }, [
            _c("h3", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.id,
                      expression: "id"
                    }
                  ]
                },
                [
                  _c("span", { staticClass: "accordion-header-text" }, [
                    _c("span", { staticClass: "h4" }, [
                      _vm._v(_vm._s(_vm.reportName))
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.reportDescription))])
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _c("i", {
                      class: [
                        "far",
                        _vm.isCollapsed ? "fa-chevron-down" : "fa-chevron-up"
                      ],
                      attrs: { "aria-hidden": "true" }
                    })
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("b-collapse", { attrs: { id: _vm.id, role: "tabpanel" } }, [
            _c(
              "div",
              { staticClass: "report-accordion-body" },
              [_vm._t("body")],
              2
            )
          ])
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file-type-icon" }, [
      _c("img", {
        attrs: { src: "/images/files/excel-icon.png", alt: "export to excel" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
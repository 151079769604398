<template>
<div class="formCheckContainer mb-3">    
    <div>
        <input  :type="inputType"
            class="form-check-input"
            name="Answer_Mode_Select_Value_IsSelected"
            :checked="scenario.isSelected"
            :value="scenario.isSelected"
            :id="`Answer_Mode_Select_Value_${itemIndex}`"
            :disabled="!choiceInstructionModel.canInstruct"
            @change="onChange" />
        <label class="form-input-label" :for="`Answer_Mode_Select_Value_${itemIndex}`">{{scenario.title}}</label> 
    </div>
    <div v-if="scenario.text" class="label-container">
        <label class="form-input-label"><strong>{{scenario.text}}</strong></label>
    </div>
</div>
</template>

<script>
import {choiceLogic} from "./choice-instruction.mixin"

export default {
  mixins:[choiceLogic]
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "passwordValidationNote" } }, [
    _c(
      "article",
      [
        _c("p", [
          _c("i", {
            staticClass: "fas fa-check-square",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(" " + _vm._s(_vm.passwordMinLength) + " ")
        ]),
        _vm._v(" "),
        _vm.boundaries.useStrongPassword
          ? [
              _c("p", [
                _c("i", {
                  staticClass: "fas fa-check-square",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dictionary.accountChangePasswordSpecialCharacters
                    ) +
                    " "
                ),
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.specialCharacters) + " ")
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _c("i", {
                  staticClass: "fas fa-check-square",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.dictionary.accountChangePasswordCapitalLetters) +
                    " "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("i", {
                  staticClass: "fas fa-check-square",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.dictionary.accountChangePasswordLowerCaseLetters
                    ) +
                    " "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("i", {
                  staticClass: "fas fa-check-square",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(
                  " " + _vm._s(_vm.dictionary.accountChangePasswordDigits) + " "
                )
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
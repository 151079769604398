<template>
    <div>
        <div >
            <div class="title-block">
                <h1 v-if="dictionary">{{ dictionary.page.confirmation }}</h1>
            </div>
            <div class="portlets-wrapper exercise-confirmation">
            <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="model && dictionary" class="row m-0" data-toggle="portlet">
                    <portlet-layout column-class="col-md-6" :title="dictionary.page.confirmation" >
                        <template v-slot:body>
                            <loading-spinner :active="isFormProcessing"></loading-spinner>
                            <ExercisesConfirmationForm :dictionary="dictionary.confirmation" :toggles="toggles" :exercisesInstructionModel="model" :error="error" @accept-instruction="acceptInstruction" @decline-instruction="declineInstruction" />
                        </template>
                    </portlet-layout>
                    <portlet-layout v-if="showDisclaimersPortlet" column-class="col-md-6" :title="dictionary.widgets.disclaimers" >
                        <template v-slot:body>
                            <ExercisesConfirmationDisclaimers :exercisesInstructionModel="model" @accept-instruction="acceptInstruction" @decline-instruction="declineInstruction" />
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get, post } from "api";
    import ExercisesConfirmationForm from "./exercises-confirmation-form.component.vue";
    import ExercisesConfirmationDisclaimers from "./exercises-confirmation-disclaimers.component.vue";

    export default {
        components: {
            ExercisesConfirmationForm,
            ExercisesConfirmationDisclaimers
        },
        props: {
            toggles: Object,
            dictionary: Object
        },
        data() {
            return {
                exercisesInstructionModel: null,
                error: null,
                isLoading: true,
                isDecline: false,
                isFormProcessing: false
            };
        },
        computed: {
            model() {
                return this.exercisesInstructionModel;
            },
            showDisclaimersPortlet() {
                return this.model.disclaimers && this.model.disclaimers.length;
            },
        },
        beforeCreate() {
            let query = this.$route.query;
            get(
                `/Exercises/Instruction?instructionId=${query.instructionId}` +
                `&sharePlanParticipationId=${query.sharePlanParticipationId}` +
                `&positionUsageId=${query.positionUsageId}`,
                (response) => { 
                    this.exercisesInstructionModel = response.data;
                     if (this.exercisesInstructionModel.state.value != 'desInternalCalculationOfOrderAmount'){
                        this.exercisesInstructionGoBack(this.model.positionUsageId, this.model.sharePlanParticipationId);
                    }                    
                },
                this,
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.exercisesInstructionGoBack(query.positionUsageId, query.sharePlanParticipationId);
                }
            );
        },
        beforeRouteLeave(to, from, next) {
            if (to.name == 'exercises' && !this.isDecline) {
                this.beforeApiCall();
                post("/exercises/RejectInstruction",
                    this.prepareAcceptDeclineModel(),
                    () => { },
                    this,
                    () => {
                        next();
                    },
                    () => { });
            } else {
                next();
            }
        },
        methods: {
            acceptInstruction() {
                this.isFormProcessing = true;
                post(
                    "/Exercises/AcceptInstruction",
                    this.prepareAcceptDeclineModel(),
                    () => {
                        this.$router.push({ path: '/orders' });
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            declineInstruction() {
                this.isFormProcessing = true;
                post(
                    "/Exercises/RejectInstruction",
                    this.prepareAcceptDeclineModel(),
                    () => {
                        this.exercisesInstructionGoBack(this.model.positionUsageId, this.model.sharePlanParticipationId);
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            prepareAcceptDeclineModel() {
                return {
                    sharePlanParticipationId: this.model.sharePlanParticipationId,
                    positionUsageId: this.model.positionUsageId,
                    instructionId: this.model.exerciseInstructionId,
                    acceptDisclaimers: this.model.disclaimers
                        .filter((d) => d.confirmed)
                        .map((d) => ({ disclaimerId: d.disclaimerId })),
                };
            },
            exercisesInstructionGoBack(positionUsageId, sharePlanParticipationId) {
                switch (this.$route.query.returnPage) {
                    case "portfolio":
                        this.$router.push({ path: '/portfolio' });
                        break;
                    default:
                        this.$router.push({
                            path: "/exercises",
                            query: {
                                positionUsageId: positionUsageId,
                                sharePlanParticipationId: sharePlanParticipationId
                            },
                        });
                }
            },
            beforeApiCall() {
                this.isLoading = true;
            },
        }
    };
</script>
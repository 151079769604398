var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InternalExchangeCancelOrderModalFooter", {
    attrs: { instructionData: _vm.instructionData, dictionary: _vm.dictionary },
    on: {
      close: function($event) {
        return _vm.$emit("close")
      },
      "submit-completed": function($event) {
        return _vm.submitCancelOrder()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="internal-exchange-orderbook-trades">
            <v-data-table :class="['table-striped', { 'vertical-scroll' : hasScroll }]"
                          :no-data-text="dictionary.datatables.noRecordFound"
                          :headers="headers"
                          :items="items"
                          :items-per-page="-1"
                          :loading="isLoading || isUpdating"
                          :hide-default-footer="true"
                          :fixed-header="hasScroll"
                          :mobile-breakpoint="0"
                          >

                <template v-slot:[`item.executionDate`]="{ item }">
                    {{ item.executionDate }}
                </template>

                <template v-slot:[`item.totalQuantityFormatted`]="{ item }">
                    {{ item.totalQuantityFormatted }}
                </template>

                <template v-slot:[`item.averagePriceFormatted`]="{ item }">
                    {{ item.averagePriceFormatted }}
                </template>
            </v-data-table>
    </div>
</template>

<script>
    import { get } from "api";
    import { vueDataTableScrollMixin } from 'Shared/Mixins/vue-data-table-scroll.mixin';

    export default {
        mixins: [vueDataTableScrollMixin],
        props: {
            dictionary: Object
        },
        data() {
            return {
                timer: null,
                items: [],
                initialRowsCount: 5,
                currentRowsCount: 5,
                pullingRowsCount: 5,
                maxRowsCount: null,
                isLoading: true,
                isUpdating: false
            };
        },
        mounted() {
            const scrollableElement = document.querySelector('.internal-exchange-orderbook-trades .v-data-table__wrapper');
            scrollableElement?.addEventListener('scroll', e => {
                this.handleScrollEvent(scrollableElement);
            });
        },
        methods: {
            getItems(isUpdating) {
                const query = this.$route.query;
                this.isUpdating = isUpdating;

                get(
                    `/InternalExchanges/LastTradeRounds?positionUsageId=${query.positionUsageId}` +
                    `&pagesize=${this.currentRowsCount}`,
                    (response) => {
                        this.items = response.data;
                    },
                    this,
                    () => {
                        this.isLoading = false;
                        this.isUpdating = false;
                    }
                );
            }
        },
        computed: {
            headers() {
                return [
                    { value: "executionDate", text: this.dictionary.lastTrades.executionDate, align: "left", width: '40%', sortable: false },
                    { value: "totalQuantityFormatted", text: this.dictionary.lastTrades.volume, align: "right", width: '30%', sortable: false },
                    { value: "averagePriceFormatted", text: this.dictionary.lastTrades.price, align: "right", width: '30%', sortable: false }
                ];
            }
        },
        created() {
            this.getItems();
            this.timer = setInterval(this.getItems, 60000);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    };
</script>
<template>
    <div>
        <div v-for="(chartContext, index) in chartContexts">
            <div class="legend-partial-container">
                <span class="apexcharts-legend-marker legend-color" rel="1" data:collapsed="false"
                    :style="{ 'background': chartContext.colors, 'color': chartContext.colors }">
                </span>
                <span class="text-start">
                    <span tabindex="0" class="legend-partial-title">{{ chartContext.labels[0] }}</span>
                </span>
                <span class="float-end">
                    <span class="legend-partial-currency" tabindex="0">{{ chartContext.labels[1] }}</span>
                    <span class="legend-partial-percentage" tabindex="0"> 
                        <span class="legend-partial-percentage-bullet" >&#8226;</span>
                        {{ getPercentage(chartContext.percents) }}</span>
                </span>
            </div>
            <hr v-if="index != chartContexts.length - 1"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        chartContexts: Array
    },
    methods: {
        getPercentage(val) {
            return `${this.$setDecimalSeparatorBySettings(Math.round((val + Number.EPSILON) * 100) / 100)}%`;
        }
    }
}
</script>
<template>
<portlet-layout class="col-xl col-lg-12" :title="dictionary.widgets.placeOrder" :is-custom-title="true" :is-custom-body="true">
  <template v-slot:body>
    <div id="order-form-div">
     <loading-spinner :active="isFormProcessing"></loading-spinner>
     <alert-component class="subscription-form-alert" v-if="error" variant="error">{{ error }}</alert-component>
      <form  v-if="hasMoneyBankAccounts" id="money-transfer-instruct-form" @submit.prevent="submit">

        <order-types-link :type="$OrderTypes.MoneyTransfer" :dictionary="dictionary.orderTypes" :positionViewModel="model" :title="dictionary.order.orderType" name="orderType"/>

        <input-element type="text" :label="dictionary.order.amount" v-model="model.amount" :value="model.amount" :validationsList="amountValidations" name="Amount"></input-element>

        <select-element :label="dictionary.order.externalAccountId" v-model="model.externalAccountId" :options="selected" name="ExternalAccountId"></select-element>
        
        <div class="mt-4">
          <button class="btn btn-primary" type="submit">
            {{ dictionary.order.moneyTransferBtnPlaceOrder }}
          </button>
        </div>
      </form>
      <template v-else>
          <alert-component variant="info">{{dictionary.validation.placeOrderUnauthorizedRequest}}</alert-component>
      </template>
    </div>
  </template>
</portlet-layout>
</template>

<script>
import { moneyTransferFormValidations } from "Shared/Components/Vue/Validations/order-form-validation.mixin.js";
import { post } from 'api';
import { EventBus } from "Shared/event-bus";
const createDropDownOption = (text, value) => ({
        text: text,
        value: value
    });
export default {
  mixins: [moneyTransferFormValidations],
  props: {
    moneyTransferPosition: Object,
    dictionary: Object,
  },
  data() {
      return {
        isFormProcessing: false,
        error: null
      }
  },
  computed: {
    model(){
      return this.moneyTransferPosition.instructMoneyInstruction;
    },
    hasMoneyBankAccounts(){
      let boundaries = this.moneyTransferPosition.boundaries;
      return (boundaries !== null && boundaries.selectableMoneyExternalAccounts !== null && 
      this.moneyTransferPosition.boundaries.selectableMoneyExternalAccounts.length > 0)
    },
    selected() {
      if (!this.model.externalAccountId) {
                    this.model.externalAccountId = this.moneyTransferPosition.boundaries
        .selectableMoneyExternalAccounts[0]?.externalAccountId;
                }
         return this.moneyTransferPosition.boundaries
        .selectableMoneyExternalAccounts.map((p) =>
                    createDropDownOption( p.description,p.externalAccountId))
    },
    amountValidations() {
      return [
              {
                id: 1,
                error: this.$v.model.amount.$error,
                type: this.$v.model.amount.required,
                message: this.dictionary.validation.amountRequired
              },
              {
                id: 2,
                error: this.$v.model.amount.$error,
                type: this.$v.model.amount.mustBeDecimal,
                message: this.dictionary.validation.amountInvalid
              },
              {
                id: 3,
                error: this.$v.model.amount.$error,
                type: this.$v.model.amount.mustBeLesserThanAvailableQuantity,
                message: this.dictionary.validation.amountInsufficientBalance
              },
              {
                id: 4,
                error: this.$v.model.amount.$error,
                type: this.$v.model.amount.mustBeGreatherThanZero,
                message: this.dictionary.validation.amountInvalid
              }
            ]
    },
    prepareRequestModel(){
      const requestModel = {...this.model};
        if (requestModel) {
            requestModel.amount = requestModel.amount.replace(/,/g, '.');
          }
        return requestModel;
    }     
  },
  methods: {
    submit() {
      this.$v.$touch();
                EventBus.$emit("v-touch");
                if (this.$v.model.amount.$invalid) {
                    return;
                }
                this.isFormProcessing = true;
        post(
              "/MoneyTransfer/InstructInstruction",
                this.prepareRequestModel,
                  (res) => {
                      const response = res.data;
                      this.$router.push({
                          path: "/moneyTransfer/confirmation",
                          query: {
                              positionUsageId: response.positionUsageId,
                              sharePlanParticipationId: response.sharePlanParticipationId,
                              moneyInstructionId: response.moneyInstructionId
                          },
                      });
                  },
                  this,
                  () => { this.isFormProcessing = false; },
                  (err) => {
                      this.error = err.response.data;
                  }
            );  
          },    
      }
};
</script>
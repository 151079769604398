var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { attrs: { id: "PortfolioOverview" } },
        [
          _c("PortfolioOverview", {
            attrs: {
              dictionary: _vm.dictionary,
              toggles: _vm.toggles,
              model: _vm.model,
              isSubmitting: _vm.isSubmitting,
              isMobile: _vm.isMobile,
              isTablet: _vm.isTablet,
              success: _vm.success,
              error: _vm.error,
              reportParameters: _vm.reportParameters,
              reportOverview: _vm.reportOverview
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
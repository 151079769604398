export const isValidIBAN = (input, ibanLength) => {
    // keep only alphanumeric characters
    const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); 

    // match and capture (1) the country code, (2) the check digits, and (3) the rest
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    // check syntax and length
    if (!code || iban.length !== ibanLength) {
        return false;
    }

    // rearrange country code and check digits, and convert chars to ints
    let digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });

    // final check
    return modulo97(digits) === 1;
}

export const isValidBIC = (input) => {
    const bicRegEx = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    const bic = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); 
    return bicRegEx.test(bic);
}


export const isValidRoutingCode = ( routingCode , aCountry, enumExternalAccountRouting) => {
	const externalAccountRouting = aCountry.externalAccountRouting;
    let routingCodeRegExp = /^[0-9]{6}$/;
	if (enumExternalAccountRouting.OptionalBICandBranchSortCode === externalAccountRouting)  { 
        // Branch Sort Code: UK & South Africa, max 6 numeric
        return routingCodeRegExp.test(routingCode);
    }
	else if (enumExternalAccountRouting.BICorABA === externalAccountRouting ) {
        // ABA / Routing Number: US, 9 numeric
        return isValidABARoutingNumber(routingCode);
    }
    else if (enumExternalAccountRouting.BICorBSB === externalAccountRouting ) {
        // BSB (Bank State Branch) Code: Australia, 6 numeric (http://en.wikipedia.org/wiki/Bank_State_Branch : 1,2=fin.institution 3=state 4,5,6=branch)
        return routingCodeRegExp.test(routingCode);
    }
    else if (enumExternalAccountRouting.BICorBranchCode === externalAccountRouting ) {
        return routingCodeRegExp.test(routingCode);
    }
    else if (enumExternalAccountRouting.BICorTransitNumber === externalAccountRouting ) {
        // Canadian transit numbers: XXXXX-YYY, 8 numeric (http://en.wikipedia.org/wiki/Routing_transit_number#Canadian_transit_number)
		// Electronic Fund Transactions (EFT) code would be 0YYYXXXXX, 9 numeric. Also called  Routing number format
        routingCodeRegExp = /^[0-9]{8}|^[0-9]{9}$/;
        return routingCodeRegExp.test(routingCode);
    }
    else {
        //earNone, earBIC, earBICandIBAN, earIBAN
        // no routing code needed, so everything is accepted.
        return true;
    }	
}

const modulo97 = (string) => {
    let checksum = string.slice(0, 2);
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

const isValidABARoutingNumber = (routingNumberToTest) => {
    if (!routingNumberToTest) { //all 0's is technically a valid routing number, but it's inactive
      return false;
    }
  
    var routing = routingNumberToTest.toString();
    while (routing.length < 9) {
      routing = '0' + routing; //I refuse to import left-pad for this
    }
  
    //gotta be 9  digits
    var match = routing.match("^\\d{9}$");
    if (!match) {
      return false;
    }
  
    //The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
    //https://en.wikipedia.org/wiki/Routing_transit_number
    const firstTwo = parseInt(routing.substring(0, 2));
    const firstTwoValid =  (0 <= firstTwo && firstTwo <= 12)
                        || (21 <= firstTwo && firstTwo <= 32)
                        || (61 <= firstTwo && firstTwo <= 72)
                        || firstTwo === 80;
    if (!firstTwoValid) {
      return false;
    }
  
    //this is the checksum
    //http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
    const weights = [3, 7 ,1];
    var sum = 0;
    for (var i=0 ; i<8; i++) {
      sum += parseInt(routing[i]) * weights[i % 3];
    }
  
    return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
}

export const nameValidator = (value) => { return !value || /^[\p{L}\p{M}' -]+$/u.test(value) }
export const addressValidator = (value) => { return !value || /^[\p{L}\p{N}\p{M}# /]+$/u.test(value) }
export const postalCodeValidator = (value) => { return !value || /^[\p{L}\p{N}\p{M} -]+$/u.test(value) }
export const alphaNumericValidator = (value) => { return !value || /^[\p{L}\p{N}\p{M} ]+$/u.test(value) }
<template slot="customCollapse">
    <div class="customCollapse-container">
        <h4 class="mb-1">{{ dictionary.orderBook.labelOrderbookProvisionalPrice }}
            <i v-if="this.instructionModel.internalExchangeBoundaries.hasCalculatedBestPrice"
                id="labelOrderbookProvisionalPrice"
                class="far fa-info-circle popover-info"
                aria-hidden="true"></i>
            <b-popover v-if="this.instructionModel.internalExchangeBoundaries.hasCalculatedBestPrice"
                target="labelOrderbookProvisionalPrice"
                triggers="hover"
                placement="top">
                <span>{{dictionary.orderBook.tooltipOrderbookProvisionalPrice}} {{instructionModel.internalExchangeBoundaries.calculatedBestPriceDateTime | toDateTime}}</span>
            </b-popover>
        </h4>
        <h4 class="customCollapse-calculated-best-price">
            {{instructionModel.internalExchangeBoundaries.calculatedBestPriceFormatted}}
        </h4>
    </div>
</template>

<script>
export default {
  props: {
    dictionary: Object,
    instructionModel: Object
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-layout",
    {
      attrs: {
        "column-class": "col-lg-8 col-xl-9",
        title: _vm.dictionary.subscriptionWidgets.titleConfirmSubscription
      }
    },
    [
      _c("template", { slot: "body" }, [
        _vm.dictionary && _vm.dividendSubscription
          ? _c(
              "div",
              { staticClass: "dividend-subscription-form" },
              [
                _vm.error
                  ? _c(
                      "alert-component",
                      {
                        staticClass: "subscription-form-alert",
                        attrs: { variant: "danger" }
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "page-description rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.subscriptionWidgets
                          .textConfirmSubscription
                      )
                    )
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "confirm-dividend-subscription" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.dividendSubscription.securityName))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("DividendSubscriptionDetailsRow", {
                      attrs: {
                        label: _vm.dictionary.subscriptionLabels.money,
                        value: _vm.moneyDividendAmountPercentage,
                        isMobile: _vm.isMobile
                      }
                    }),
                    _vm._v(" "),
                    _c("DividendSubscriptionDetailsRow", {
                      attrs: {
                        label: _vm.dictionary.subscriptionLabels.shares,
                        value: _vm.sharesDividendAmountPercentage,
                        isMobile: _vm.isMobile
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("DividendSubscriptionButtons", {
                  attrs: {
                    cancelButtonLabel:
                      _vm.dictionary.subscriptionLabels.btnCancel,
                    subscribeButtonLabel:
                      _vm.dictionary.subscriptionLabels.btnSubscribe
                  },
                  on: { cancel: _vm.cancel, subscribe: _vm.subscribe }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
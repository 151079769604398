<template>
<div class="position-action-links">
    <PortfolioPlanActionLink v-if="position.canInstructExit" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails" :disableLink="disableLink" :position="position" @onClick="goToPath('/exits')" />
    <PortfolioPlanActionLink v-else-if="position.canInstructBuy" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails"  :disableLink="disableLink" :position="position" @onClick="goToPath('/buy')" />
    <PortfolioPlanActionLink v-else-if="position.canInstructExercise" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails"  :disableLink="disableLink" :position="position" @onClick="goToPath('/exercises')" />
    <PortfolioPlanActionLink v-else-if="!position.isSecurityPosition" :disableLink="disableLink" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails"  :position="position" @onClick="goToPath('/moneyTransfer')" />

    <PortfolioPlanActionLink v-else-if="(position.canInstructInternalExchange && (position.canBuy || position.canSell)) || position.hasInternalExchange" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails"  :disableLink="disableLink" :position="position" 
        @onClick="goToPath('/internalExchanges', 'orderType', position.orderType)" />
        
    <PortfolioPlanActionLink v-else :disableLink="disableLink" :position="position" :isMobile="isMobile" :mobileLabel="dictionary.table.btnViewPositionDetails" 
        @onClick="goToPath('/positionDetail', 'securityUnderlyingId', position.securityUnderlyingId)" />
</div>
</template>

<script>
import PortfolioPlanActionLink from './portfolio-plan-action-link.component.vue'
import { actionMethods } from './Shared/mixins/position-action-methods.mixin';

export default {
    mixins: [actionMethods],
    components: {
        PortfolioPlanActionLink
    },
    props: {
        isMultiPositionExit: Boolean,
        position: Object,
        isMobile: Boolean,
        dictionary: Object
    },
    computed: {
        disableLink() {
            return this.isMultiPositionExit && this.position.isSecurityPosition;
        }
    }
}
</script>
<template>
  <div>
    <template v-if="hasItems && !isMobile">
        <div class="orders-listitem-container" v-for="(exerciseDelivery, index) in exerciseDeliveries" :key="index" >
            <dt>
                <template v-if="index == 0">
                    {{label}}
                </template>
            </dt>
            <dd>{{exerciseDelivery.NrSharesToDeliverFormatted}} X {{exerciseDelivery.SecurityIdentificationDescription}}</dd>
        </div>
    </template>
    <template v-else-if="hasItems && isMobile">
      <div v-for="(exerciseDelivery, index) in exerciseDeliveries" :key="index" >

        <div class="orders-row-flex" v-if="index == 0">
            <p class="orders-row__label">{{ label }}</p>
        </div>
        <div class="orders-row-flex">
            <p :class="['orders-row__value', { 'text-right' : textRight }]">{{exerciseDelivery.NrSharesToDeliverFormatted}} X {{exerciseDelivery.SecurityIdentificationDescription}}</p>
        </div>
        <v-divider class="orders-row-divider" ></v-divider>
      </div>
    </template>
  </div>      
</template>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

export default {
  props: {
    exerciseDeliveries: Array,
    label:String,
  },
  mixins:[ScreenSizeMixin],
  methods: {
    hasItems() {
      return this.exerciseDeliveries !== null && this.exerciseDeliveries.length > 0;
    },
  },
};
</script>
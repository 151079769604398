export const dividendMixin = {

    computed: {
        quantityPerShare() {
            return this.dividend && this.dividend.dividendAllocations ? this.dividend.dividendAllocations[0].dividendQuantityPerShareFormatted : '';
        },
        amountPerShare() {
            return this.dividend && this.dividend.dividendAllocations ? this.dividend.dividendAllocations[0].dividendAmountPerShareFormatted : '';
        },
        showQuantityPerShare() {
            return (this.dividend && this.dividend.dividendType.value === this.$DividendType.Security  
                || this.dividend.dividendType.value === this.$DividendType.SecurityAndMoney) 
                && !this.toggles.hideStockDividendColumn;
        },
        showAmountPerShare() {
            return (this.dividend && this.dividend.dividendType.value === this.$DividendType.Money 
                ||  this.dividend.dividendType.value === this.$DividendType.SecurityAndMoney)  
                && !this.toggles.hideCashDividendColumn
        },
        dividendTypeTitle() {
            return this.dividend && this.dividend.dividendType.value !== this.$DividendType.SecurityAndMoney
                ? this.dividend.dividendType.description
                : `${this.dividend.dividendType.description}  (${this.dividend.dividendPercentage})`;
        }
    }

}
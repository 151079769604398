<template>
    <div class="mfa-buttons button-container">
        <div class="mfa-buttons__cancel-button">
            <button :class="[{ 'hasOnlyCancelButton':hasOnlyCancelButton},'btn', 'secondaryButton']" type="button" @click="$emit('cancel')">
                {{ dictionary.buttonMfaCancel }}
            </button>
        </div>
        <div v-if="!hasContinueButton && !hasOnlyCancelButton" class="mfa-buttons__submit-button">
            <button :class="[{ 'isDisabled': disableSubmit }, 'btn', 'primaryButton']" type="button" @click="$emit('submit')">
                {{ dictionary.buttonMfaConfirm }}
            </button>
        </div>
        <div v-if="hasContinueButton  && !hasOnlyCancelButton" class="mfa-buttons__submit-button">
            <button :class="['btn', 'primaryButton']" type="button" @click="$emit('continueMfa')">
                {{ dictionary.mfaBtnMFAPromptContinue }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            disableSubmit: {
                type: Boolean,
                default: false
            },
            hasContinueButton: {
                type: Boolean,
                default: false
            },
            hasOnlyCancelButton: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
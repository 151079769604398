var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    { staticClass: "order-details-list" },
    [
      !_vm.toggles.hideOrderState
        ? _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.state,
              value: _vm.instructionData.State
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.reference,
          value: _vm.instructionData.Reference
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.planName,
          value: _vm.instructionData.PlanName
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.positionName,
          value: _vm.instructionData.SecurityName
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.instructionDate,
          value: _vm.instructionData.SubmissionDate
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.exerciseDate,
          value: _vm.instructionData.ExerciseDate
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.scenarioName,
          value: _vm.instructionData.ExerciseScenarioName
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.executionMoment,
          value: _vm.instructionData.ExecutionMoment
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.ordered,
          value: _vm.instructionData.NrOrderedDerivativesFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: { label: _vm.labelNr, value: _vm.dataNr }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.exercisePrice,
          value: _vm.instructionData.ExercisePriceFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.securityPrice,
          value: _vm.instructionData.SecurityPriceFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.grossAmount,
          value: _vm.instructionData.GrossAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.netExerciseAmount,
          value: _vm.instructionData.NetExerciseAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderDataLabel", {
        attrs: {
          label: _vm.dictionary.modal.grossProceeds,
          value: _vm.instructionData.GrossProceedsFormatted
        }
      }),
      _vm._v(" "),
      _c("OrderPayments", {
        attrs: {
          label: _vm.dictionary.modal.taxPayment,
          payments: _vm.instructionData.TaxPayments
        }
      }),
      _vm._v(" "),
      _c("OrderPayments", {
        attrs: {
          label: _vm.dictionary.modal.paymentFees,
          payments: _vm.instructionData.FeesPayments
        }
      }),
      _vm._v(" "),
      _c("OrderBrokerFees", {
        attrs: {
          brokerFees: _vm.instructionData.BrokerFees,
          brokerFeesFormated: _vm.instructionData.BrokerFeesFormatted,
          label: _vm.dictionary.modal.brokerFees
        }
      }),
      _vm._v(" "),
      _c("OrderPayments", {
        attrs: {
          label: _vm.dictionary.modal.usageFeesPayments,
          payments: _vm.instructionData.UsageFeesPayments
        }
      }),
      _vm._v(" "),
      _c("OrderDeliveries", {
        attrs: {
          label: _vm.dictionary.modal.nrSharesToDeliver,
          exerciseDeliveries: _vm.instructionData.ExerciseDeliveries
        }
      }),
      _vm._v(" "),
      _c("OrderExerciseScenarioType", {
        attrs: {
          instructionData: _vm.instructionData,
          dictionary: _vm.dictionary
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
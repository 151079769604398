var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          class: _vm.listStyle
            ? "cookie-policy-row__title_list"
            : "cookie-policy-row__title"
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.richText
        ? _c("div", {
            staticClass: "rich-text-container",
            domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.text)) }
          })
        : _c("p", [_vm._v(_vm._s(_vm.text))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
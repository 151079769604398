<template>
  <div>
    <v-card class="payment" >
      <v-card-title class="payment-title">
        <h3 class="primary-color h4">{{ dictionary.table.expandedRowTitle }}</h3>
      </v-card-title>
      <PaymentExpandedItemDetails class="flex-row" :dictionary="dictionary" :payment="payment" />
    </v-card>
  </div>
</template>

<script>
  import PaymentExpandedItemDetails from './payments-table-expanded-item-details.component.vue';

  export default {
    components: {
      PaymentExpandedItemDetails
    },
    props: {
      payment: Object,
      dictionary: Object
    },
    computed: {
    }
  }
</script>
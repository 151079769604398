<template>
    <div>
        <alert-component v-if="error" variant="error">{{error}}</alert-component>
        <p>{{dictionary.confirmTitle}}</p>
        <dl class="order-details-list">
            <MoneyTransferConfirmationFormListItem :label="dictionary.orderType" :value="dictionary.orderTypePayment" />
            <MoneyTransferConfirmationFormListItem :label="dictionary.availableAmount" :value="moneyTransferInstruction.availableAmountFormatted" />
            <MoneyTransferConfirmationFormListItem :label="dictionary.amount" :value="moneyTransferInstruction.amountFormatted" />
            <MoneyTransferConfirmationFormListItem :label="dictionary.newAvailableAmount" :value="moneyTransferInstruction.newAvailableAmountFormatted" />
            <MoneyTransferConfirmationFormListItem :label="dictionary.externalAccountId" :value="moneyTransferInstruction.externalAccount.description" />
        </dl>
        <div class="d-md-flex justify-content-md-between button-container">
            <div>
                <button class="btn secondaryButton" @click="$emit('reject-instruction')">{{commonDictionary.btnDecline}}</button>
            </div>
            <div>
                <button class="btn primaryButton ml-0" @click="$emit('accept-instruction')">{{commonDictionary.btnConfirm}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import MoneyTransferConfirmationFormListItem from "./money-transfer-confirmation-form-list-item.component.vue";
    export default {
        components: {
            MoneyTransferConfirmationFormListItem
        },
        props: {
            moneyTransferInstruction: Object,
            dictionary: Object,
            commonDictionary: Object,
            error: String
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        [
          _c("div", { staticClass: "documents-container" }, [
            _c("div", { staticClass: "upload-document-block" }, [
              _c("div", { staticClass: "document-header" }, [
                _c("h2", { staticClass: "card-header-title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        !_vm.inEdit
                          ? _vm.dictionary.labels.documentsUpload
                          : _vm.dictionary.labels.documentsUploaded
                      ) +
                      "\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "document-body",
                  attrs: { id: "upload-document-form" }
                },
                [
                  _vm.requiredDocumentDetails.isPersonalIdentificationDocument
                    ? _c(
                        "div",
                        [
                          _c("select-element", {
                            attrs: {
                              label: _vm.dictionary.labels.documentType,
                              options:
                                _vm.requiredDocumentDetails
                                  .availableDocumentIdentificationTypes,
                              isMandatory: _vm.inEdit,
                              readonly: !_vm.inEdit,
                              readonlyLabel:
                                _vm.dictionary.accessibility.readonlyLabel,
                              name: "documentType"
                            },
                            model: {
                              value: _vm.documentUploadDetails.documentType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.documentUploadDetails,
                                  "documentType",
                                  $$v
                                )
                              },
                              expression: "documentUploadDetails.documentType"
                            }
                          }),
                          _vm._v(" "),
                          _c("input-element", {
                            attrs: {
                              id: "document-number",
                              name: "DocumentNumber",
                              type: "text",
                              isMandatory: _vm.inEdit,
                              label: _vm.dictionary.labels.documentNumber,
                              readonly: !_vm.inEdit,
                              validationsList:
                                _vm.validationLists.documentNumberValidation
                            },
                            model: {
                              value: _vm.documentUploadDetails.documentNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.documentUploadDetails,
                                  "documentNumber",
                                  $$v
                                )
                              },
                              expression: "documentUploadDetails.documentNumber"
                            }
                          }),
                          _vm._v(" "),
                          _c("datepicker-element", {
                            attrs: {
                              id: "document-expiration-date",
                              label: _vm.dictionary.labels.documentExpiryDate,
                              yearRange: "-25:+25",
                              isMandatory: _vm.inEdit,
                              readonly: !_vm.inEdit,
                              readonlyLabel:
                                _vm.dictionary.accessibility.readonlyLabel,
                              validationsList:
                                _vm.validationLists.documentExpiryDateValidation
                            },
                            model: {
                              value:
                                _vm.documentUploadDetails.documentExpiryDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.documentUploadDetails,
                                  "documentExpiryDate",
                                  $$v
                                )
                              },
                              expression:
                                "documentUploadDetails.documentExpiryDate"
                            }
                          }),
                          _vm._v(" "),
                          _c("datepicker-element", {
                            attrs: {
                              id: "document-issue-date",
                              label: _vm.dictionary.labels.documentIssueDate,
                              isMandatory: _vm.inEdit,
                              readonly: !_vm.inEdit,
                              readonlyLabel:
                                _vm.dictionary.accessibility.readonlyLabel,
                              validationsList:
                                _vm.validationLists.documentIssueDateValidation
                            },
                            model: {
                              value:
                                _vm.documentUploadDetails.documentIssueDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.documentUploadDetails,
                                  "documentIssueDate",
                                  $$v
                                )
                              },
                              expression:
                                "documentUploadDetails.documentIssueDate"
                            }
                          }),
                          _vm._v(" "),
                          _c("select-element", {
                            attrs: {
                              label: _vm.dictionary.labels.documentCountry,
                              options: _vm.requiredUploadDocument.countries,
                              name: "documentCountry",
                              isMandatory: _vm.inEdit,
                              readonly: !_vm.inEdit,
                              readonlyLabel:
                                _vm.dictionary.accessibility.readonlyLabel
                            },
                            model: {
                              value: _vm.documentUploadDetails.documentCountry,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.documentUploadDetails,
                                  "documentCountry",
                                  $$v
                                )
                              },
                              expression:
                                "documentUploadDetails.documentCountry"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm.inEdit
                        ? [
                            _c("upload-element", {
                              attrs: {
                                id: "document-data",
                                name: "document-data",
                                label: _vm.dictionary.labels.filesUpload,
                                isMandatory: _vm.inEdit,
                                placeholder: _vm.dictionary.labels.chooseFile,
                                browseLabel: _vm.dictionary.labels.btnBrowse,
                                extensionFilter: ".png,.jpg,.jpeg,.pdf",
                                validationsList:
                                  _vm.validationLists.uploadFilesValidation
                              },
                              model: {
                                value: _vm.documentUploadDetails.uploadFiles,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.documentUploadDetails,
                                    "uploadFiles",
                                    $$v
                                  )
                                },
                                expression: "documentUploadDetails.uploadFiles"
                              }
                            })
                          ]
                        : [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "label form-input-label input-container font-weight-bold"
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.dictionary.labels.filesUploaded
                                    ) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group mb-3" }, [
                              _vm.requiredDocumentDetails
                                .requiredDocumentParticipation.documentData
                                .length
                                ? _c(
                                    "div",
                                    { staticClass: "list-group" },
                                    _vm._l(
                                      _vm.requiredDocumentDetails
                                        .requiredDocumentParticipation
                                        .documentData,
                                      function(documentData) {
                                        return _c(
                                          "div",
                                          { key: documentData.documentId },
                                          [
                                            !_vm.toBeApproved
                                              ? [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: _vm.$sanitize(
                                                          _vm.getDocumentUrl(
                                                            documentData.documentId,
                                                            documentData.documentType
                                                          )
                                                        ),
                                                        download: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          documentData.documentName
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        documentData.documentName
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e()
                            ])
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.inEdit
                    ? [
                        _c("p", [
                          _vm._v("* " + _vm._s(_vm.dictionary.labels.mandatory))
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "document-button" },
                    [
                      _vm.inEdit
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary pull-right",
                                attrs: {
                                  "aria-label":
                                    _vm.dictionary.accessibility.backButton
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goBack(false)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.dictionary.labels.btnCancel))]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary pull-right",
                                attrs: {
                                  type: "submit",
                                  "aria-label":
                                    _vm.dictionary.accessibility.submitButton
                                },
                                on: { click: _vm.submit }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.dictionary.labels.btnSubmit) + " "
                                )
                              ]
                            )
                          ]
                        : [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary pull-right",
                                attrs: {
                                  "aria-label":
                                    _vm.dictionary.accessibility.backButton
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goBack(false)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.dictionary.labels.btnGoBack))]
                            )
                          ]
                    ],
                    2
                  )
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("loading-spinner", { attrs: { active: _vm.isLoading } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
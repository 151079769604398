import { mustBeDecimal, mustBeGreatherThan  } from 'Shared/Components/Vue/Validations/custom-validator'
import { requiredIf } from "vuelidate/lib/validators";
import numeral from 'numeral';

const isSelectedCurrencyRequired = (vm) =>{
    return !!vm.model.selectedCurrency;
}

export const portfolioReportValidations = {

    validations() {
        return {
            model: {
                customizableSecurityPrices:
                {
                    $each: {
                         price: {
                            mustBeDecimal: function (value) {
                                return mustBeDecimal(value);
                            },
                            mustBeGreatherThanZero: function (value) {
                                if (value == undefined || value == null || value == '')
                                    return true;

                                if (value) {
                                    value = numeral(value).value();
                                }
                                   
                                return mustBeGreatherThan(value, 0);
                            }
                         }
                    },
                    atLeastOneShouldBeHigherThanZero: function (value) {
                        return value.some(x=> numeral(x.price).value() > 0);
                    }
                },
                customCurrencyRates:
                {
                    $each: {
                        rate: {
                            required: requiredIf(function () {
                                return isSelectedCurrencyRequired(this)
                            }), 
                            mustBeDecimal: function (value) {
                                return mustBeDecimal(value);
                            },
                            mustBeGreatherThanZero: function (value) {
                                if (value == undefined || value == null || value == '')
                                    return true;
                                
                                if (value) {
                                    value = numeral(value).value();
                                }

                                return mustBeGreatherThan(value, 0);
                            }
                         }
                    }
                }
            }
        }
    }
} 

<template>
    <div>
        <!-- <p>Test details mobile component</p> -->
        <v-expansion-panels :class="['transaction-details-expansion-block', 'mobile',
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion>
            <v-expansion-panel class="transaction-details-expansion-panel">
                <v-expansion-panel-header class="transaction-details-expansion-panel__header">
                    <div>
                        <div class="row transaction-details-header-text">
                            <p>{{dictionary.rows.transactionsTblRowMobileHeader}}: {{item.executionDate}}</p>
                        </div>
                        <div v-if="!toggles.hideTransactionOriginColumn" class="row transaction-details-child-text">
                            <p>{{dictionary.columns.transactionsTblColOrigin}}: {{item.origin}}</p>
                        </div>
                        <div class="row transaction-details-child-text">
                            <p>{{dictionary.columns.transactionsTblColAmount}}: {{item.amountFormatted}}</p>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mobile">
                    <TransactionDetailsContent :toggles="toggles" :dictionary="dictionary" :item="item" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import TransactionDetailsContent from './transaction-details-content.component.vue';

    export default {
        components: {
            TransactionDetailsContent,
        },
        props: {
            dictionary: Object,
            item: Object,
            toggles: Object
        },
        data() {
            return {
                panel: null
            }
        },
        computed: {
            isPanelOpened() {
                return this.panel !== undefined && this.panel !== null;
            },
        },
    }
</script>
<template>
    <div v-if="position.lastSecurityPrice" :id="spanId" class="text-right force-nowrap">
        <span>{{ position.lastSecurityPriceFormatted }} </span>
        <i v-if="!hideSecurityPriceInformationTooltip" class="far fa-info-circle popover-info" aria-hidden="true"></i>
        <div>
            <b-popover :target="spanId" triggers="hover" v-if="!hideSecurityPriceInformationTooltip" placement="right">
                {{ tooltipSecurityPrice }}
            </b-popover>
        </div>    
    </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  components: {

  },
  props: {
    position: Object,
    dictionary: Object,
    hideSecurityPriceInformationTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spanId() {
      return `portfolio-last-security-price-popover-target_${uuid()}`;
    },
    tooltipSecurityPrice() {
        const securityPriceDate = this.$options.filters.toDateTime(this.position.lastSecurityPriceDate).replace('00:00:00', '')
        return `${this.dictionary.block.tooltipSharePrice} ${securityPriceDate}`;
    },
  },
};
</script>
<template>

    <div class="formCheckContainer mb-3">
        <div>
            <input  :type="inputType"
                class="form-check-input"
                name="Answer_Mode_Value_IsSelected"
                :checked="scenario.isSelected"
                :value="scenario.isSelected"
                :id="`Answer_Mode_Value_${itemIndex}`"
                :disabled="!choiceInstructionModel.canInstruct"
                @change="onChange" 
                v-show="!hideRadioOrCheckBox"
                 />
            <label class="form-input-label" :for="`Answer_Mode_Value_${itemIndex}`">{{scenario.title}}</label>      
        </div>
        <div v-if="!hideInput">
            <input-element  type="text" 
                v-model="scenario.value" 
                :value="$setDecimalSeparatorBySettings(scenario.value)"
                :disabled="isInputDisabled"
                :validationsList="valueValidations"
                :name="`Scenarios_${itemIndex}_Value`"
                :label="scenario.text"  >
                <template slot="group">
                  <div v-if="hasPercentage" class="input-group-prepend" aria-hidden="true">
                    <span class="input-group-text">%</span>
                  </div>      
                </template>
            </input-element>
        </div> 
    </div>

</template>

<script>
import {choiceLogic} from "./choice-instruction.mixin"

export default {
  mixins:[choiceLogic],
  mounted(){
    if (!this.scenario.isSelected && this.hasOneScenario && this.choiceInstructionModel.scenarios.length == 1){
        if (this.scenario.answerMode.value === 'csamSelectWithValue' || this.scenario.answerMode.value === 'csamValue'){
          this.$nextTick(() => {
            document.getElementById(`Answer_Mode_Value_${this.itemIndex}`).click()
          });
        }        
    }     
  }   
}
</script>
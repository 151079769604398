<template>
    <div class="email-attachment" v-if="message.attachments && message.attachments.length > 0">
        <ul class="list-group">
            <li class="list-group-item" :key="attachement.documentId" v-for="attachement in message.attachments">
                <a class="card-link" target="_blank" rel="noopener" :href="$sanitize(getAttachmentLink(attachement))" download>
                    <span>Attachment: {{attachement.documentName}} </span> &nbsp;&nbsp;
                    <i class="far fa-download" aria-hidden="true"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        message: Object
    },
    methods: {
        getAttachmentLink(attachement) {
            return `/Inbox/Attachment?documentType=${attachement.documentType}&documentId=${attachement.documentId}`;
        }
    }
}
</script>
<template>
    <div class="current-environment-container" v-if="text">
        <p class="current-environment-text">{{ text }}</p>
    </div>
</template>

<script>

    export default {
        props: {
            text: String
        }
    };
</script>

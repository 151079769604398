<template>
    <div class="mfa-pac">
        <template v-if="!mfaData && !skipConfirmation">
                <MfaConfirmation :dictionary="dictionary" :message="message" :phone="phone" :error="error" v-on="$listeners" @in-loading="inLoading" />
        </template>
        <template v-else>
                <MfaPacCode :dictionary="dictionary" v-model="pacData" :message="message" :mfaData="mfaData" @in-loading="inLoading"
                            v-on="$listeners" />
        </template>
    </div>
</template>

<script>
    import MfaConfirmation from "./mfa-pac-confirmation.component.vue";
    import MfaPacCode from "./mfa-pac-code.component.vue";

    export default {
        components: {
            MfaConfirmation,
            MfaPacCode
        },
        props: {
            dictionary: Object,
            message: String,
            error:String,
            phone: String,
            mfaData: Object,
            skipConfirmation: Boolean,
            pac:String,
            isVisible:Boolean
        },
        data() {
            return {
                pacData:''
            }
        },
        watch: {
            isVisible: function (newVal, oldVal) {
                if (newVal) {
                    this.pacData = '';
                }
            },
            pac(newValue, oldValue) {
                if (newValue) {
                    this.pacData = newValue.trim();
                }
            }
        },
        methods:{
            inLoading() {
                this.$emit('in-loading');
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.accountDataModel.legalNationality
    ? _c("portlet-layout", {
        attrs: {
          title: _vm.accountDictionary.widgets.legalInformation,
          hideShadow: true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  _c(
                    "div",
                    [
                      _c("LegalNationalityView", {
                        attrs: {
                          legalNationalityDataModel:
                            _vm.accountDataModel.legalNationality,
                          dictionary: _vm.accountDictionary.legalNationality
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2042236126
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <main id="mainContent">
        <div class="login-image-box"></div>
        <div class="login-box">
            <div>
                <div>
                    <h1 class="text-center py-2">{{dictionary.errorPageTitle}}</h1>
                </div>
                <div class="text-left">
                    <alert-component variant="error">{{ dictionary.internalServerFriendlyError }}</alert-component>
                    <router-link class="btn btn-block btn-primary mt-5"  role="button" to="/login">{{dictionary.btnOk}}</router-link>                
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
        },
    }
</script>
<template>
    <main id="mainContent">
        <div class="login-image-box"></div>
        <div class="login-box">
            <LoginSso></LoginSso>
        </div>
    </main>
</template>

<script>
    import { getSessionRepository } from 'Shared/factory';

    import LoginSso from "./login-sso.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        name: 'login-sso-main',
        components: {
            LoginSso
        },
        data() {
            return {
                isLoading: false,
            };
        },
        mounted() {
            this.hideLoginOnce();
            EventBus.$emit("event-header-login", { showHeaderLoginHelp: false, showHeaderLoginLanguage: false });

            // Can't guarantee the user has logged out correctly so must clear this at login
            // TODO: Move this "clear" method call to the session keep alive functionality when complete (user story #2127 - User session management)
            getSessionRepository().clear();
        },
        methods: {
            hideLoginOnce() {
                if (window.localStorage) {
                    if (!localStorage.getItem('firstLoad')) {
                        this.isLoading = true;
                        localStorage.setItem('firstLoad', "true");
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const returnUrl = urlParams.get('ReturnUrl') ?? "/";
                        window.location.href = returnUrl;
                    }
                    else {
                        localStorage.removeItem('firstLoad');
                        this.isLoading = false;
                    }
                }
            }
        },
    };
</script>
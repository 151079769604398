<template>
<div class="questionnaire-details-content">
    <EvaluationDataLabelRow :label="dictionary.table.questionnaireName" :value="questionnaire.questionnaireName" />
    <EvaluationDataLabelRow :label="dictionary.table.nrOfQuestions" :value="questionnaire.nrQuestions" />
    <EvaluationDataLabelRow :label="dictionary.table.nrOfUnansweredQuestions" :value="questionnaire.unansweredQuestions" />
    <EvaluationDataLabelRow :label="dictionary.table.result" :value="questionnaire.isEligible ? dictionary.labels.eligible : dictionary.labels.notEligible" />
    <router-link class="btn btn-primary w-100" role="button" :to="{ name: 'questionnaire-detail', params: { questionnaireId: questionnaire.questionnaireId, returnUrl: '/Beneficiaries#evaluation' }}">
    {{questionnaire.canEdit ? dictionary.labels.modify : dictionary.labels.view }} asd</router-link>
</div>
</template>

<script>
import EvaluationDataLabelRow from "./evaluation-data-label-row.component.vue";

export default {
  components: {
    EvaluationDataLabelRow
  },
  props: {
    questionnaire: Object,
    dictionary: Object,
    toggles: Object
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "sidebar-nav" },
    [
      _c("SecurityPriceDropdown", {
        attrs: {
          isHidden: _vm.toggles.hideSecurityPriceDropdown,
          position: "side",
          dictionary: _vm.securitiesDictionary,
          securities: _vm.securities
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.features, function(feature) {
        return [
          !feature.isDisabled
            ? _c("SidebarItem", {
                key: feature.pathName,
                attrs: {
                  name: feature.name,
                  urlAction: "/" + feature.pathName,
                  icon: feature.icon,
                  "icon-type": "far",
                  label: feature.label,
                  isHidden: feature.isDisabled,
                  controllerMatch: _vm.controllerMatch(feature.pathName)
                }
              })
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("li", { staticClass: "d-lg-none" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.$sanitize(_vm.logoutEndpoint),
              title: _vm.dictionary.navLogout
            }
          },
          [
            _c("i", { staticClass: "far fa-2x fa-sign-out-alt" }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "navigation-text",
                attrs: {
                  "data-localize": "sidebar.nav." + this.dictionary.navLogout
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.navLogout))]
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "d-lg-none" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasItems
        ? [
            !_vm.isMobile
              ? _c(
                  "div",
                  _vm._l(_vm.payments, function(payment, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "orders-listitem-container" },
                      [
                        _c("dt", [_vm._v(_vm._s(payment.Name))]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(payment.NetAmountFormatted))])
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  _vm._l(_vm.payments, function(payment, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("OrdersDetailsContentRow", {
                          attrs: {
                            label: payment.Name,
                            value: payment.NetAmountFormatted,
                            textRight: true
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
          ]
        : [
            !_vm.isMobile
              ? _c("div", { staticClass: "orders-listitem-container" }, [
                  _c("dt", [_vm._v(_vm._s(_vm.label))]),
                  _vm._v(" "),
                  _c("dd", [_vm._v("-")])
                ])
              : _c(
                  "div",
                  [
                    _c("OrdersDetailsContentRow", {
                      attrs: { label: _vm.label, value: "-", textRight: true }
                    })
                  ],
                  1
                )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
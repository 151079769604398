var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    staticClass: "col-xl col-lg-12",
    attrs: {
      title: _vm.dictionary.widgets.placeOrder,
      "is-custom-title": true,
      "is-custom-body": true
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { attrs: { id: "order-form-div" } },
              [
                _c("loading-spinner", {
                  attrs: { active: _vm.isFormProcessing }
                }),
                _vm._v(" "),
                _vm.error
                  ? _c(
                      "alert-component",
                      {
                        staticClass: "subscription-form-alert",
                        attrs: { variant: "error" }
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasMoneyBankAccounts
                  ? _c(
                      "form",
                      {
                        attrs: { id: "money-transfer-instruct-form" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          }
                        }
                      },
                      [
                        _c("order-types-link", {
                          attrs: {
                            type: _vm.$OrderTypes.MoneyTransfer,
                            dictionary: _vm.dictionary.orderTypes,
                            positionViewModel: _vm.model,
                            title: _vm.dictionary.order.orderType,
                            name: "orderType"
                          }
                        }),
                        _vm._v(" "),
                        _c("input-element", {
                          attrs: {
                            type: "text",
                            label: _vm.dictionary.order.amount,
                            value: _vm.model.amount,
                            validationsList: _vm.amountValidations,
                            name: "Amount"
                          },
                          model: {
                            value: _vm.model.amount,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "amount", $$v)
                            },
                            expression: "model.amount"
                          }
                        }),
                        _vm._v(" "),
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.order.externalAccountId,
                            options: _vm.selected,
                            name: "ExternalAccountId"
                          },
                          model: {
                            value: _vm.model.externalAccountId,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "externalAccountId", $$v)
                            },
                            expression: "model.externalAccountId"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                "\r\n            " +
                                  _vm._s(
                                    _vm.dictionary.order
                                      .moneyTransferBtnPlaceOrder
                                  ) +
                                  "\r\n          "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : [
                      _c("alert-component", { attrs: { variant: "info" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.validation
                              .placeOrderUnauthorizedRequest
                          )
                        )
                      ])
                    ]
              ],
              2
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
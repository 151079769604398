<template>
  <portlet-tab  :tabTitle="tabTitle" :isActive="isActive" :tabId="tabId">
    <template  v-if="action !== $AccountActionType.ADD && action !== $AccountActionType.EDIT"  >      
      <h2>{{widgetTitle}}</h2>
      
      <div v-if="accountType == $BankAccountType.SECURITY" class="page-description rich-text-container" v-html="$sanitize(financialDictionary.beneficiaryPageDescriptionSecurity)"></div>
      <div v-else-if="accountType == $BankAccountType.MONEY" class="page-description rich-text-container" v-html="$sanitize(financialDictionary.beneficiaryPageDescriptionMoney)"></div>
      
      <FinancialSucceessAlert :showSuccess="showSuccess" :successMessage="successMessage" @alert-visible="updateAlert" />    
      <template v-if="bankAccountData.length">
        <FinancialCard v-for="bankAccount in bankAccountData" :key="bankAccount.id" 
            :bank-account="bankAccount"  
            :financial-dictionary="dictionary" :account-type="accountType"
            :beneficiary-boundaries-data="beneficiaryBoundariesData"  
            @setDefaultAccount="setDefaultAccount" 
            @removeAccount="removeAccount"
            @editAccount="editAccount" >
            
          <FinancialBankAccount  
                :financial-dictionary="dictionary" :bank-account="bankAccount" 
                :financial-toggles="financialToggles" :account-type="accountType" />

        </FinancialCard>         
      </template>
      <template v-else-if="!showSuccess">
          <alert-component variant="info">{{dictionary.noRecord}}</alert-component>
      </template>
      <FinancialAddAccountButton
          :dictionary="dictionary" :account-type="accountType" 
          :beneficiary-boundaries-data="beneficiaryBoundariesData" 
          @add-account="addAccount()" >      
      </FinancialAddAccountButton>
    </template>

    <FinancialAddAccount v-if="action === $AccountActionType.ADD" 
          :dictionary="dictionary" :account-type="accountType" 
          :financial-dictionary="financialDictionary" :beneficiary-boundaries-data="beneficiaryBoundariesData" 
          :financial-toggles="financialToggles"
          @cancel-add-account="cancelAddAccount()" @add-account-completed="addAccountSubmitCompleted">
    </FinancialAddAccount>

    <FinancialEditAccount v-if="action === $AccountActionType.EDIT" 
          :dictionary="dictionary" :bankAccount="bankAccount" :account-type="accountType"
          :financial-toggles="financialToggles"
          :financial-dictionary="financialDictionary"  :beneficiary-boundaries-data="beneficiaryBoundariesData" 
          @cancel-edit-account="cancelEditAccount()" @edit-account-completed="editAccountSubmitCompleted">
    </FinancialEditAccount>

    <FinancialDefaultAccount v-if="action === $AccountActionType.DEFAULT"   
          :dictionary="dictionary" :financial-dictionary="financialDictionary" 
          :bankAccount="bankAccount" :accountType="accountType" :isModalVisible="isModalVisible" 
          :beneficiary-boundaries-data="beneficiaryBoundariesData"  :financial-toggles="financialToggles"
          @submit-completed="defaultAccountSubmitCompleted" @close-modal="closeModal" @open-modal="openModal" >
    </FinancialDefaultAccount>

    <FinancialRemoveAccount v-if="action === $AccountActionType.REMOVE"  
          :dictionary="dictionary" :financial-dictionary="financialDictionary" 
          :bankAccount="bankAccount" :accountType="accountType" :isModalVisible="isModalVisible" 
          :beneficiary-boundaries-data="beneficiaryBoundariesData" :financial-toggles="financialToggles"
          @submit-completed="removeAccountSubmitCompleted" @close-modal="closeModal" @open-modal="openModal" >
    </FinancialRemoveAccount>

  </portlet-tab>
</template>

<script>
import FinancialBankAccount from "./financial-bank-account.component.vue";
import FinancialCard from "./financial-card.component.vue";
import FinancialDefaultAccount from "./financial-default-account.component.vue";
import FinancialAddAccountButton from "./financial-add-account-button.component.vue";
import FinancialAddAccount from "./financial-add-account.component.vue";
import FinancialEditAccount from "./financial-edit-account.component.vue";
import FinancialRemoveAccount from "./financial-remove-account.component.vue";
import FinancialSucceessAlert from "./financial-success-alert.component.vue";

export default {
  props: {
    beneficiaryBoundariesData: Object,  
    financialDictionary: Object,
    dictionary: Object,
    bankAccountData: Array,
    tabTitle: String,
    tabId: String,
    widgetTitle: String,
    isActive: Boolean,
    accountType: String,
    financialToggles: Object
  },
  components: {
    FinancialBankAccount,
    FinancialCard,
    FinancialDefaultAccount,
    FinancialAddAccountButton,
    FinancialAddAccount,
    FinancialEditAccount,
    FinancialRemoveAccount,
    FinancialSucceessAlert
  },
  data() {
    return {
      bankAccount: null,
      isModalVisible: false,
      showSuccess: false,
      successMessage: '',
      action: this.$AccountActionType.VIEW
    };
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.action = this.$AccountActionType.VIEW
      this.bankAccount = null;
      this.isModalVisible = false;
    },
    setDefaultAccount(payLoad) {
      this.bankAccount = Object.assign({},  payLoad.bankAccount);
      this.action = payLoad.action;
      this.showSuccess = false;
    },
    removeAccount(payLoad) {
      this.bankAccount = Object.assign({},  payLoad.bankAccount);
      this.action = payLoad.action;
      this.showSuccess = false;
    },
    addAccount() {
      this.action = this.$AccountActionType.ADD
      this.showSuccess = false;
    },
    editAccount(payLoad) {
      this.bankAccount = Object.assign({},  payLoad.bankAccount);
      this.action = this.$AccountActionType.EDIT
      this.showSuccess = false;
    },
    cancelAddAccount() {
      this.action = this.$AccountActionType.VIEW
      this.showSuccess = false;
    },
    cancelEditAccount() {
      this.action = this.$AccountActionType.VIEW
      this.showSuccess = false;
    },
    defaultAccountSubmitCompleted(payLoad) {
      this.$emit("reload-bank-account-data", payLoad);     
      this.closeModal();
    },
    addAccountSubmitCompleted(payLoad) {
      this.action = this.$AccountActionType.VIEW
      this.showSuccess = true;
      this.successMessage = this.dictionary.addExternalAccountSuccessMessage;
      this.$emit("reload-bank-account-data", payLoad);   
    },
    editAccountSubmitCompleted(payLoad) {
      this.action = this.$AccountActionType.VIEW
      this.showSuccess = true;
      this.successMessage = this.dictionary.editExternalAccountSuccessMessage;
      this.$emit("reload-bank-account-data", payLoad);   
    },
    removeAccountSubmitCompleted(payLoad) {
      this.action = this.$AccountActionType.VIEW
      this.showSuccess = true;
      this.successMessage = this.dictionary.removeExternalAccountSuccessMessage;
      this.$emit("reload-bank-account-data", payLoad);     
      this.closeModal();
    },
    updateAlert(visible) {
      this.showSuccess = visible;
    }
  }  
};
</script>
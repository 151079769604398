<template>
<div class="required-documents-details-content">
    <RequiredDocumentsDetailsRow :label="dictionary.table.documentName" :value="requiredDocument.name" />
    <RequiredDocumentsDetailsRow v-if="requiredDocument.expiryDate" :label="dictionary.table.expiryDate" :value="requiredDocument.expiryDate | toDate" />
    <RequiredDocumentsDetailsRow :label="dictionary.table.documentState" :value="requiredDocument.stateDescription" />
    <a class="btn btn-primary w-100" role="button" @click="$emit('action', requiredDocument.requiredDocumentId, requiredDocument.requiredDocumentParticipationId)">{{ getActionButtonText(requiredDocument, dictionary) }}</a>
</div>
</template>

<script>
import RequiredDocumentsDetailsRow from "./required-documents-details-row.component.vue";
import { requiredDocumentsMethods } from "./mixins/required-documents.mixin.js";

export default {
  mixins: [ requiredDocumentsMethods ],
  components: {
    RequiredDocumentsDetailsRow
  },
  props: {
    requiredDocument: Object,
    dictionary: Object
  }
};
</script>
<template>
<section>
    <component-spinner :spin="isLoading"></component-spinner>
    <div v-if="!isLoading" class="evaluation-container">
        <div v-if="questionnairesDictionary && financialDictionary && !beneficiaryToggles.hideQuestionnaireWidget" class="evaluation-body">
          <h2>{{financialDictionary.widget.questionnaires}}</h2>
          <Questionnaires v-if="questionnairesDictionary && datatablesDictionary"  :dictionary="questionnairesDictionary" :datatables-dictionary="datatablesDictionary" :toggles="beneficiaryToggles" />       
        </div>

        <div v-if="requiredDocumentsDictionary && financialDictionary && !beneficiaryToggles.hideRequiredDocumentsWidget" class="evaluation-body">
          <h2>{{financialDictionary.widget.requiredDocuments}}</h2>
          <RequiredDocuments :dictionary="requiredDocumentsDictionary" :datatables-dictionary="datatablesDictionary" />    
        </div>

        <div v-if="accountDictionary && !beneficiaryToggles.hideRelationships">
          <BeneficiaryRelationships  :dictionary="accountDictionary" :toggles="beneficiaryToggles" :datatables-dictionary="datatablesDictionary"  />    
        </div>
      </div>
  </section>
</template>

<script>
import { getAll } from 'api';
import RequiredDocuments from "./evaluation-required-documents.component.vue";
import Questionnaires from "./evaluation-questionnaires.component.vue";
import BeneficiaryRelationships from "./evaluation-relationships.component.vue";

export default {
  props: {
      financialDictionary: Object,
      accountDictionary:  Object,
      datatablesDictionary: Object,
      beneficiaryToggles: Object
  },
  components: {
    RequiredDocuments,
    Questionnaires,
    BeneficiaryRelationships
  },
  beforeCreate(){
    this.isLoading = true;
    getAll([
        '/RequiredDocuments/Dictionary', 
        '/Questionnaire/Dictionary',
        '/Beneficiaries/Relationships',
        ], (response) => {
        this.requiredDocumentsDictionary = response[0].data;
        this.questionnairesDictionary = response[1].data;
        this.beneficiaryRelationships= response[2].data;
        this.isLoading = false;
    }, this);
  },
  data() {
    return {
         questionnairesDictionary: null,
         requiredDocumentsDictionary: null,
         beneficiaryRelationships: null,
         isLoading: false
    };
  }
};
</script>
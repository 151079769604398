<template>
    <div>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-if="dictionary && commonDictionary && toggles && exitOrderModel" id="order-form-div">
            <form id="exit-instruct-form" @submit.prevent="submit">

                <alert-component v-if="isOutOfHours" variant="warning">
                    <div class="rich-text-container" v-html="$sanitize(dictionary.form.realTimeDealWarningOutOfHours)"></div>
                </alert-component>
                
                <loading-spinner :active="isFormProcessing"></loading-spinner>
                <ExitOrderHiddenInputs :model="model" />
                <div v-if="isMultiExit">
                    <div class="form-group" v-if="positionName"><h4>{{ positionName }}</h4></div>
                    <div class="form-group"><strong>{{ dictionary.form.sharesAvailable }}</strong>: {{ model.availableAmountScenarioFormatted }}</div>
                </div>

                <alert-component v-if="error" variant="error">{{ error }}</alert-component>

                <div v-if="dictionary.form.widgetTextPlaceOrder" class="form-group">
                    <span class="primary-color rich-text-container" v-html="$sanitize(dictionary.form.widgetTextPlaceOrder)"></span>
                </div>

                <div v-if="isMultiExit" class="form-group">
                    <label class="form-input-label">{{ dictionary.form.orderType }}</label>
                    <div class="input-group">{{ dictionary.form.orderTypeExitName }}</div>
                </div>
                <order-types-link v-if="!isModal && positionViewModel" :type="$OrderTypes.Exits" :dictionary="dictionary.form.orderTypes" :positionViewModel="positionViewModel" :title="dictionary.form.orderType" name="orderType" />
                <select-element v-model="model.exitScenarioId" :label="dictionary.form.scenarioName" :options="scenarios" @changed="scenarioChanged()" name="scenarioName" :valueTooltipText="exitScenarioRemarks"></select-element>
                
                <alert-component v-if="hasRestrictedSecurityAccountSettings" variant="warning">
                    <div class="rich-text-container" v-html="$sanitize(dictionary.form.errorExistingAccountSettingsInvalidDisplay)"></div>
                </alert-component>

                <select-element v-model="model.exitExecutionMoment" :label="dictionary.form.executionMoment" :options="executionMoments" :validations="$v.model.exitExecutionMoment" name="executionMoment"></select-element>

                <ExitOrderQuantityWarning v-if="toggles.useExitsOrderForfeitureWarning" :available="model.availableAmountPrimaryPosition" :availableFormatted="model.availableAmountPrimaryPositionFormatted" :warningText="dictionary.form.orderTextForfeitureWarning"
                                          :scenarioQuantity="model.availableAmountScenario" :quantity="model.quantityAllAndSelectSelect" :isAllSelected="isQuantityAllSelected" />

                <ExitOrderQuantity :isMultiExit="isMultiExit" :dictionary="dictionary" :model="model" :quantityType="quantityType" :validations="$v.model"
                                   @quantity="model.quantity = $event"
                                   @quantityAll="model.quantityAll = $event"
                                   @quantityAllAndSelectSelect="model.quantityAllAndSelectSelect = $event"
                                   @quantitySelection="model.quantityAllAndSelectSelection = $event" />

                <input-element v-if="!hideLimitPrice" type="text" :label="dictionary.form.limitPrice" v-model="model.limitPrice" :validationsList="limitPriceValidations" name="limitPrice"></input-element>
                <ExitOrderAuhtorityToDeal v-if="toggles.useExitsOrderAuthorityToDeal" :dictionary="dictionary" v-model="model.authorityCheckbox" :validations="$v.model" />

                <ExitOrderButtons :isModal="isModal" :hasRestrictedSecurityAccountSettings="hasRestrictedSecurityAccountSettings" :hasAuhtorityToDeal="toggles.useExitsOrderAuthorityToDeal" :isEnabled="model.authorityCheckbox" :isRealTimeDealing="isRealTimeDealing"
                    :dictionary="dictionary.form" :commonDictionary="commonDictionary" @order-submitted="submit" @closed="$emit('form-closed')" />
            </form>
        </div>
    </div>

</template>

<script>
    import { getAll, post } from "api";
    import { exitOrderFormValidations } from "Shared/Components/Vue/Validations/order-form-validation.mixin.js";
    import ExitOrderHiddenInputs from "./exit-order-hidden-inputs.component.vue";
    import ExitOrderQuantity from "./exit-order-quantity.component.vue";
    import ExitOrderQuantityWarning from "./exit-order-quantity-warning.component.vue";
    import ExitOrderAuhtorityToDeal from "./exit-order-authority-to-deal.component.vue";
    import ExitOrderButtons from "./exit-order-buttons.component.vue";
    import { EventBus } from "Shared/event-bus";
    import numeral from 'numeral'

    const getScenario = (scenariosList, exitScenarioId) =>
        scenariosList.filter((x) => x.exitScenarioId == exitScenarioId)[0];

    const createDropDownOption = (disabled, group, selected, text, value) => ({
        disabled: disabled,
        group: group,
        selected: selected,
        text: text,
        value: value
    });

    export default {
        mixins: [exitOrderFormValidations],
        components: {
            ExitOrderHiddenInputs,
            ExitOrderQuantity,
            ExitOrderQuantityWarning,
            ExitOrderAuhtorityToDeal,
            ExitOrderButtons
            },
        props: {
            exitOrderModel: {
                type: Object,
                default: null
            },
            positionViewModel: {
                type: Object,
                default: null
            },
            dictionary: Object,
            positionName: String,
            isMultiExit: Boolean,
            isModal: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                toggles: null,
                commonDictionary: null,
                quantityType: "",
                isFormProcessing: false,
                formStateExecutionMoment: "",
                error: null,
                isLoading: true
            };
        },
        computed: {
            model() {
                return this.exitOrderModel;
            },
            scenarios() {
              return this.model.exitInstructionBoundaries.scenarios.map(e => createDropDownOption(false, null, false, e.customDescription && e.customDescription.length > 0 ? e.customDescription : e.name, e.exitScenarioId));
            },
            exitScenarioRemarks(){
                return this.toggles.showExitRemarksTooltip && getScenario(this.model.exitInstructionBoundaries.scenarios, this.model.exitScenarioId).customRemarks;
            },
            executionMoments() {
                const exitInstructionBoundariesModule = this.getExitInstructionBoundariesModule();
                if (!exitInstructionBoundariesModule) {
                    return [];
                }

                return exitInstructionBoundariesModule.executionMomentTypes.map(e => createDropDownOption(
                    false, null, exitInstructionBoundariesModule.executionMomentTypes.length === 1, e.description, e.value));
            },
            hideLimitPrice() {
                if (this.model.exitExecutionMoment !== this.$ExecutionMomentType.AT_PRICE) {
                    this.model.limitPrice = '';
                    return true;
                }
                return false;
            },
            isRealTimeDealing() {
                return this.hasRealTimeDealingQuote && !this.isOutOfHours;
            },
            limitPriceValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.required,
                        message: this.dictionary.validation.limitPriceRequired
                    },
                    {
                        id: 2,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.mustBeDecimal,
                        message: this.dictionary.validation.limitPriceInvalid
                    },
                    {
                        id: 3,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.mustBeModulo,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.limitPriceModulo,
                            {
                                LimitPriceModulo: this.$setDecimalSeparatorBySettings(this.model.exitInstructionBoundaries.limitPriceModulo),
                            }
                        )
                    }
                ];
            },
            isQuantityAllSelected() {
                return (
                    this.model.quantityAllAndSelectSelection === this.$QuantityInputType.ALL
                );
            },
            hasRestrictedSecurityAccountSettings() {
                return this.model.hasRestrictedSecurityAccountSettings && 
                    getScenario(this.model.exitInstructionBoundaries.scenarios, this.model.exitScenarioId).exitScenarioType === 'estDelivery';
            },
            isOutOfHours() {
                const exitInstructionBoundariesModule = this.getExitInstructionBoundariesModule();
                return !!exitInstructionBoundariesModule?.isOutOfHours;
            },
            hasRealTimeDealingQuote() {
                const exitInstructionBoundariesModule = this.getExitInstructionBoundariesModule();
                return !!exitInstructionBoundariesModule?.hasRealTimeDealingQuote;
            }
        },
        beforeCreate() {
            getAll([
                "/Common/Dictionary",
                "/Exits/Toggles"
            ], (responses) => {
                this.commonDictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.isLoading = false;
            }, this);
        },
        mounted() {
            this.formStateExecutionMoment = this.model.exitExecutionMoment;

            this.quantityType = getScenario(
                this.model.exitInstructionBoundaries.scenarios,
                this.model.exitScenarioId
            ).quantityInputType;

            this.setExecutionMomentIfEmpty();
        },
        methods: {
            getExitInstructionBoundariesModule() {
                const scenario = getScenario(this.model.exitInstructionBoundaries.scenarios, this.model.exitScenarioId);
                return this.model.exitInstructionBoundaries.modules
                        .filter(e => e.moduleId === scenario.moduleId)[0];
            },
            setExecutionMomentIfEmpty() {
                const executionMoment = this.executionMoments.filter(x => x.value === this.formStateExecutionMoment)[0];
                this.model.exitExecutionMoment = executionMoment ? executionMoment.value : this.executionMoments[0].value;
            },
            scenarioChanged() {
                const scenario = getScenario(
                    this.model.exitInstructionBoundaries.scenarios,
                    this.model.exitScenarioId
                );

                this.quantityType = scenario.quantityInputType;
                this.model.availableAmountScenario = scenario.available;
                this.model.availableAmountScenarioFormatted = scenario.availableFormatted;
                this.setExecutionMomentIfEmpty();
            },
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");
                if (this.$v.$invalid) {
                    return;
                }
                
                this.isFormProcessing = true;

                let newInstructionModel = Object.assign({}, this.model);
                newInstructionModel.limitPrice = numeral(this.model.limitPrice).value();
                newInstructionModel.quantity = numeral(this.model.quantity).value();
                newInstructionModel.quantityAllAndSelectSelect = numeral(this.model.quantityAllAndSelectSelect).value();

                post(
                    "/Exits/InstructionConfirmation",
                    { ...newInstructionModel, isMultiExit: this.isMultiExit },
                    (res) => {

                        const response = res.data;
                        if (this.isModal) {
                            window.location.replace(`/Exits/Confirmation?positionUsageId=${response.positionUsageId}`
                                + `&sharePlanParticipationId=${response.sharePlanParticipationId}`
                                + `&exitInstructionId=${response.exitInstructionId}`
                                + `&exitScenarioId=${response.exitScenarioId}`
                                + `&returnPage=portfolio`);
                        } else {
                            this.$router.push({
                                path: "/exits/confirmation",
                                query: {
                                    exitInstructionId: response.exitInstructionId,
                                    positionUsageId: response.positionUsageId,
                                    sharePlanParticipationId: response.sharePlanParticipationId,
                                    exitScenarioId: response.exitScenarioId
                                },
                            });
                        }
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            }
        }
    };
</script>
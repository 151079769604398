var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "hideInMobile" }, [
    _c(
      "td",
      { staticClass: "font-weight-bold portfolio-plan-security-totals-row" },
      [_vm._v(_vm._s(_vm.dictionary.portfolioLabelRowTotals))]
    ),
    _vm._v(" "),
    _vm.showPlanActionButtons
      ? _c(
          "td",
          {
            staticClass:
              "all position-column portfolio-plan-security-totals-row"
          },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showVestingDateColumnForControlTags
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showLastDayExercisePeriodColumnForControlTags
      ? _c("td", { staticClass: "portfolio-plan-security-totals-row" }, [
          _vm._v(" ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.portfolioPlan.hasAnyVestings && _vm.showVestingsColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showBalanceColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(_vm._s(_vm.security.balanceFormatted) + " ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showAvailableColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(_vm._s(_vm.security.availableFormatted) + " ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showUnvestedColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(_vm._s(_vm.security.unvestedFormatted) + " ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showLastPriceColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showDerivativeSecurityPriceColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showReferencePriceColumn && _vm.hasReferencePrice
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showExercisePriceColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showUnvestedValueColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(_vm._s(_vm.security.unvestedFormatted) + " ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showVestingDateColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showLastDayExercisePeriodColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(" ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("td", { staticClass: "text-right portfolio-plan-security-totals-row" }, [
      _vm._v(_vm._s(_vm.securityTotalValue(_vm.security)) + " ")
    ]),
    _vm._v(" "),
    _vm.showExpectedValueColumn
      ? _c(
          "td",
          { staticClass: "text-right portfolio-plan-security-totals-row" },
          [_vm._v(_vm._s(_vm.security.valueFormatted) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
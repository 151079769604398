import DocumentsContainer from "./documents-container.component.vue";

export const documentRoutes =
{
    path: '/document',
    component: DocumentsContainer,
    children: [
        {
            path: '*',
            redirect: '/document'
        }
    ]
}

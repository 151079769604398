<template>
    <div class="dividend-subscription-info">
        <div class="d-sm-flex justify-content-between">
            <p class="dividend-subscription-info-title">{{dictionary.subscriptionLabels.subscriptionOpen}}</p>
            <p class="text-sm-right">{{dividendSubscription.startDate | toDateTime }}</p>
        </div>
        <div class="d-sm-flex justify-content-between">
            <p class="dividend-subscription-info-title">{{dictionary.subscriptionLabels.subscriptionClosed}}</p>
            <p class="text-sm-right">{{dividendSubscription.endDate | toDateTime }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            dividendSubscription: Object
        },
    };
</script>
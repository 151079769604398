var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "dtr-control p-2" }, [
      _c("div", { staticClass: "dtr-control-row" }, [
        _c("div", { staticClass: "d-flex mr-3 w-100" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "document-details-container" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.item.documentPlanSummary.documentName))
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "document-date" }, [
                _vm._v(_vm._s(_vm.item.documentDateFormatted))
              ])
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.item.documentPlanSummary.planName))])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex my-auto" }, [
          _c(
            "a",
            {
              staticClass: "far fa-download fa-2x align-self-center",
              attrs: {
                href: _vm.$sanitize(
                  "Document/GetDocument?documentid=" +
                    _vm.item.documentPlanSummary.documentId
                )
              }
            },
            [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(
                  _vm._s(_vm.dictionary.labels.documentsDownloadSRText) +
                    " " +
                    _vm._s(_vm.item.documentPlanSummary.documentName)
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("div", { staticClass: "document-icon-container" }, [
        _c("i", {
          staticClass: "far fa-file fa-2x",
          attrs: { "aria-hidden": "true" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
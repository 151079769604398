var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "modal-basic",
        {
          attrs: {
            title: _vm.dictionary.defaultAccountModal.title,
            "is-on": _vm.isModalVisible,
            isLoading: _vm.submitingData,
            "ok-footer": false
          },
          on: {
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _vm.bankAccount
            ? _c(
                "div",
                {
                  staticClass: "default-account-container",
                  attrs: { id: "default-account-container" }
                },
                [
                  _c("BeneficiaryFinancialDefaultAccountBody", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      financialDictionary: _vm.financialDictionary,
                      "account-data": _vm.bankAccount,
                      hasInvalidSettings: _vm.bankAccount.hasInvalidSettings,
                      errors: _vm.errors
                    }
                  }),
                  _vm._v(" "),
                  _c("BeneficiaryFinancialDefaultAccountButtons", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      hasInvalidSettings: _vm.bankAccount.hasInvalidSettings,
                      "may-set-default": _vm.maySetDefault
                    },
                    on: {
                      "cancel-submit": function($event) {
                        return _vm.cancelSubmit()
                      },
                      "submit-default-account": function($event) {
                        return _vm.submitDefaultAccount()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-if="dictionary">
            <div class="title-block single-line">
                <h1>{{ dictionary.page.changePassword }}</h1>
            </div>
            <div class="portlets-wrapper">
                <div class="row m-0">
                    <portlet-layout column-class="col-xl-6" :title="dictionary.widgets.changePassword" >
                        <template slot="body">
                            <alert-component v-if="error" variant="error">{{ error }}</alert-component>
                            <loading-spinner :active="isSubmitting"></loading-spinner>
                            <input-element type="text" :label="dictionary.labels.userName" :is-mandatory="false" v-model="changePasswordBoundaries.userName"
                                           name="userName" :disabled="true" />
                            <ChangePasswordInputs :dictionary="dictionary" :model="model" :boundaries="changePasswordBoundaries"
                                                  @back="backToBeneficiaries" @submit="submit">
                                <template v-slot:buttons="{ back, submit }">
                                    <div class="form-group button-container d-md-flex justify-content-md-end">
                                        <button class="btn secondaryButton mb-0" @click="back"> {{ commonDictionary.btnGoBack }}</button>
                                        <button class="btn primaryButton" @click="submit">{{ commonDictionary.btnSubmit }}</button>
                                    </div>
                                </template>
                            </ChangePasswordInputs>
                        </template>
                    </portlet-layout>
                    <portlet-layout column-class="col-xl-6" :title="dictionary.widgets.passwordValidationNote" >
                        <template slot="body">
                            <PasswordValidationNote :dictionary="dictionary.labels" :boundaries="changePasswordBoundaries" />
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { getAll, post } from "api";
    import ChangePasswordInputs from 'Shared/Components/Vue/ChangePassword/change-password-inputs.component.vue'
    import PasswordValidationNote from 'Shared/Components/Vue/ChangePassword/password-validation-note.component.vue'

    export default {
        components: {
            ChangePasswordInputs,
            PasswordValidationNote
        },
        data() {
            return {
                model: {
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmed: "",
                },
                isSubmitting: false,
                error: null,
                isLoading: true,
                dictionary: null,
                commonDictionary: null,
                changePasswordBoundaries: null
            };
        },
        beforeCreate() {
            getAll([
                "/Common/Dictionary",
                "/Account/Dictionary",
                "/Account/ChangePasswordBoundaries"
            ], (responses) => {
                this.commonDictionary = responses[0].data;
                this.dictionary = responses[1].data;
                this.changePasswordBoundaries = responses[2].data;

                if (!this.changePasswordBoundaries.mayChangePassword) {
                    this.backToBeneficiaries();
                }
            },
                this,
                () => { this.isLoading = false; },
                () => { this.backToBeneficiaries(); });
        },
        methods: {
            submit() {
                this.error = "";
                this.isSubmitting = true;

                post(
                    '/Account/ChangePassword',
                    this.model,
                    (res) => {
                        this.logout();
                    },
                    this,
                    () => { this.isSubmitting = false; },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            backToBeneficiaries() {
                this.$router.push({ path: '/beneficiaries', hash: '#account' });
            },
            logout() {
                post(
                    '/Login/Logout',
                    {},
                    (res) => {
                        location.replace(`/login/PasswordChangedSuccessful`);
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    }
                );
            }
        }
    };
</script>
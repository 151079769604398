<template>
    <div class="subscriptions-row-flex form-row">
        <p class="col subscriptions-row__label font-weight-bold">{{ label }}</p>
        <p :class="['col subscriptions-row__value', { 'text-right' : textRight }]">{{ value }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            value: String | Number,
            textRight: Boolean
        }
    }
</script>
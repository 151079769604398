<template>
    <div class="portfolio-plan table-responsive mt-0">
        <v-data-table class="elevation-1"
                      item-key="name"
                      :headers="customHeaders"
                      :items="items"
                      :disable-pagination="true"
                      :footer-props="{ disablePagination: true, disableItemsPerPage : true }"
                      :disable-sort="true"
                      :items-per-page="-1"
                      :hide-default-footer="true"
                      :mobile-breakpoint="0"
                      :show-expand="isMobile" >

            <template v-slot:[`header.expectedValueFormatted`]="{ header }">
                <ExpectedValueColumn :header="header" :toggles="toggles" :dictionary="dictionary" />
            </template>

            <template v-slot:[`header.expectedAvailableValueFormatted`]="{ header }">
                <ExpectedValueColumn :header="header" :toggles="toggles" :dictionary="dictionary" />
            </template>

            <template v-if="showReferencePriceColumn" v-slot:[`header.lastVestingReferencePriceFormatted`]="{ header }">
                <VestingReferencePriceColumn :header="header" :toggles="toggles" :dictionary="dictionary" />
            </template>

            <template v-slot:[`item.custom-buttons`]="{ item }">
                <PortfolioPlanActionButtons :position="item" :dictionary="dictionary" class="hideInMobile action-buttons-table-cell" />
            </template>

            <template v-slot:[`item.custom-name`]="{ item }">
                <div class="font-weight-bold">
                    <PortfolioPlanActionLinks v-if="!isMobile" :dictionary="dictionary" :isMultiPositionExit="portfolioPlan.isMultiPositionExit" :position="item" />
                    <PortfolioPlanPositionName v-else :position="item" />
                </div>
            </template>

            <template v-slot:[`item.custom-vesting`]="{ item }">
                <VestingColumn :anyVestings="portfolioPlan.hasAnyVestings" :position="item" :toggles="toggles" :dictionary="dictionary" />
            </template>

            <template v-slot:[`item.custom-last-price`]="{ item }">
                <LastPriceColumn :position="item" :dictionary="dictionary" :hideSecurityPriceInformationTooltip="toggles.hideSecurityPriceInformationTooltip" />
            </template>

            <template v-slot:[`item.custom-derivative`]="{ item }">
                <DerivativeSecurityPriceColumn :derivativeSecurity="derivativeSecurity(item)" :dictionary="dictionary" :hideSecurityPriceInformationTooltip="toggles.hideSecurityPriceInformationTooltip" />
            </template>

            <template v-slot:[`item.custom-value`]="{ item }">
                <span class="text-nowrap" :class="{ 'font-weight-bold' : isMobile }">{{ portfolioValue(item) }}</span>
            </template>

            <!-- Expanded item template (purchase details & modifications) --->
            <template v-slot:expanded-item="{ headers, item }">
                <td v-if="isMobile" class="expanded-item-td" :colspan="headers.length">
                    <PortfolioPlanMobileDetails :position="item" :dictionary="dictionary" :toggles="toggles" :isMultiPositionExit="portfolioPlan.isMultiPositionExit"
                                                :anyVestings="portfolioPlan.hasAnyVestings" :derivativeSecurity="derivativeSecurity(item)" :controlTags="portfolioPlan.controlTags"
                                                :isMobile="isMobile" />
                </td>
            </template>

            <!-- Logic for expandable rows if conditions met  --->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                        @click.stop="expand(!isExpanded)" :aria-label="`${item.type} ${item.reference} ${isExpanded ? 'collapse' : 'expand'} more details`">$expand</v-icon>
            </template>

            <template slot="body.append" v-if="toggles.showShareTotalsRow && portfolioPlan.security && !isMobile">
                <PortfolioPlanTotalsRow :dictionary="dictionary"
                                        :security="portfolioPlan.security"
                                        :isMultiPositionExit="portfolioPlan.isMultiPositionExit"
                                        :toggles="toggles" 
                                        :portfolioPlan="portfolioPlan" 
                                        :controlTags="portfolioPlan.controlTags" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { controlTagsMethods } from "./mixins/portfolio-plan-control-tags.mixin";
    import PortfolioPlanTotalsRow from "./portfolio-plan-totals-row.component.vue";
    import PortfolioPlanActionButtons from "./Shared/position-action-buttons.component.vue";
    import PortfolioPlanActionLinks from "./portfolio-plan-action-links.component.vue";
    import LastPriceColumn from "./portfolio-last-price-column.component.vue";
    import DerivativeSecurityPriceColumn from "./derivative-security-price-column.component.vue";
    import VestingColumn from "./vesting-column.component.vue";
    import PortfolioPlanMobileDetails from "./portfolio-plan-mobile-details.component.vue";
    import PortfolioPlanPositionName from './portfolio-plan-position-name.component.vue';
    import VestingReferencePriceColumn from "./reference-price-column.component.vue";
    import ExpectedValueColumn from "./expected-value-column.component.vue";
    import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';
    import { portfolioPlanMethods } from "./mixins/portfolio-plan.mixin";

    export default {
        mixins: [controlTagsMethods, positionMethods, portfolioPlanMethods],
        components: {
            PortfolioPlanTotalsRow,
            PortfolioPlanActionButtons,
            PortfolioPlanActionLinks,
            LastPriceColumn,
            DerivativeSecurityPriceColumn,
            VestingColumn,
            PortfolioPlanMobileDetails,
            PortfolioPlanPositionName,
            VestingReferencePriceColumn,
            ExpectedValueColumn
        },
        props: {
            portfolioPlan: Object,
            dictionary: Object,
            toggles: Object,
            currency: String,
            isMobile: Boolean
        },
        data() {
            return {
                isLoading: false,
            }
        },
        methods: {
            portfolioValue(position) {
                return this.replaceNullOrEmpty(!this.toggles.showCurrencyConversionDropdown ? this.getValueFormatted(position, this.toggles) : position.localCurrencyValueFormatted, "-");
            },
            derivativeSecurity(position) {
                return position.derivativeSecurities && position.derivativeSecurities[0] != null ? position.derivativeSecurities[0] : null;
            }
        },
        computed: {
            headers() {
                return [
                    { value: 'custom-buttons', text: '', align: 'center', sortable: true, visible: this.showPlanActionButtons && !this.isMobile, width: '3rem' },
                    { value: 'custom-name', text: this.dictionary.table.positionName, align: 'left', sortable: false, visible: true },
                    { value: 'lastVestingDate', text: this.dictionary.table.vestingDate, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showVestingDateColumnForControlTags && !this.isMobile },
                    { value: 'expiryDate', text: this.dictionary.table.lastDayExPeriod, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showLastDayExercisePeriodColumnForControlTags && !this.isMobile },
                    { value: 'custom-vesting', text: this.dictionary.table.vesting, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.portfolioPlan.hasAnyVestings && this.showVestingsColumn && !this.isMobile },
                    { value: 'balanceFormatted', text: this.dictionary.table.balance, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showBalanceColumn && !this.isMobile },
                    { value: 'availableFormatted', text: this.dictionary.table.available, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showAvailableColumn && !this.isMobile },
                    { value: 'unvestedFormatted', text: this.dictionary.table.unvested, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showUnvestedColumn && !this.isMobile },
                    { value: 'custom-last-price', text: this.dictionary.table.lastPrice, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showLastPriceColumn && !this.isMobile },
                    { value: 'custom-derivative', text: this.dictionary.table.underlyingSharePrice, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showDerivativeSecurityPriceColumn && !this.isMobile },
                    { value: 'lastVestingReferencePriceFormatted', text: this.dictionary.table.referencePrice, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showReferencePriceColumn && this.hasReferencePrice && !this.isMobile },
                    { value: 'strikePriceFormatted', text: this.dictionary.table.exercisePrice, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showExercisePriceColumn && !this.isMobile },
                    { value: 'lastVestingDate', text: this.dictionary.table.vestingDate, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showVestingDateColumn && !this.isMobile },
                    { value: 'expiryDate', text: this.dictionary.table.lastDayExPeriod, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showLastDayExercisePeriodColumn && !this.isMobile },
                    { value: 'unvestedValueFormatted', text: this.dictionary.table.unvestedValue, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showUnvestedValueColumn && !this.isMobile },
                    { value: 'custom-value', text: this.portfolioValueHeader, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: true },
                    {
                        value: this.toggles.useAvailableValueDisplay ? 'expectedAvailableValueFormatted' : 'expectedValueFormatted',
                        text: this.dictionary.table.expectedValue, cellClass: 'text-nowrap', align: 'right', sortable: false, visible: this.showExpectedValueColumn && !this.isMobile
                    },
                    { value: 'data-table-expand', text: '', align: 'right', sortable: false, visible: this.isMobile }
                ];
            },
            portfolioValueHeader() {
                return !this.toggles.showCurrencyConversionDropdown ? this.dictionary.table.value : `${this.dictionary.table.value} (${this.currency})`;
            },
            customHeaders() {
                return this.headers.filter(h => h.visible);
            },
            items() {
                return this.portfolioPlan.positions;
            },
            isMultiPositionExit() {
                return this.portfolioPlan.isMultiPositionExit;
            },
            controlTags() {
                return this.portfolioPlan.controlTags;
            }
        }
    }
</script>
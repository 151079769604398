<template>
<div>
    <v-expansion-panels :class="['payment-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="payment-details-expansion-panel">
            <v-expansion-panel-header class="payment-details-expansion-panel__header">
                <h3 class="h4">{{ dictionary.table.mobileRowTitle }} {{ payment.processingDateTime | toDate }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <PaymentDetailsContent :dictionary="dictionary" :payment="payment" :toggles="toggles" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</div>
</template>

<script>
import PaymentDetailsContent from "./payment-details-content.component.vue";

export default {
    components: {
        PaymentDetailsContent
    },
    props: {
        payment: Object,
        dictionary: Object,
        toggles: Object
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        },
    }
}
</script>
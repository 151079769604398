<template>
    <div id="reports-container">
        <p>{{dictionary.page.description}}</p>
        <loading-spinner :active="submitting"></loading-spinner>
        <alert-component v-if="success" variant="success">{{ success }}</alert-component>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <alert-component v-if="!anyCouponReportAvailable && !anyAnnualReportAvailable && !anyReportPortfolioAvailable" ref="no-reports-message">{{ dictionary.page.noReportsMessage }}</alert-component>

        <template v-if="boundaries.canRequestBeneficiaryYearResultsReport && yearsListAvailable">
            <div class="accordion report-accordion" role="tablist" v-if="isMobile">
                <ReportsAnnual @getAnnualStatementReport="getAnnualStatementReport" :boundaries="boundaries" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile"></ReportsAnnual>
            </div>
            <ReportsAnnual @getAnnualStatementReport="getAnnualStatementReport" :boundaries="boundaries" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile" v-else></ReportsAnnual>
        </template>

        <template v-if="boundaries.canRequestBeneficiaryQuarterlyReport && yearsListAvailable">
            <div class="accordion report-accordion" role="tablist" v-if="isMobile">
                <ReportsQuarterly @getQuarterlyStatementReport="getQuarterlyStatementReport" :boundaries="boundaries" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile" />
            </div>
            <ReportsQuarterly @getQuarterlyStatementReport="getQuarterlyStatementReport" :boundaries="boundaries" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile" v-else />
        </template>

        <template v-if="boundaries.canRequestCouponPaymentsReport">
            <div class="accordion report-accordion" role="tablist" v-if="isMobile">
                <ReportsCoupon @getCoupon="getCoupon" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile"></ReportsCoupon>
            </div>
            <ReportsCoupon @getCoupon="getCoupon" :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile" v-else></ReportsCoupon>
        </template>

        <template v-if="boundaries.canRequestPortfolioOverviewReport">
            <div class="accordion report-accordion" role="tablist" v-if="isMobile">
                <ReportsPortfolio :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile"></ReportsPortfolio>
            </div>
            <ReportsPortfolio :dictionary="dictionary" :toggles="toggles" :is-mobile="isMobile" v-else></ReportsPortfolio>
        </template>

    </div>
</template>

<script>
    import { post } from "api";
    import ReportsCoupon from "./reports-coupon.component.vue";
    import ReportsAnnual from "./reports-annual.component.vue";
    import ReportsPortfolio from "./reports-portfolio.component.vue";
    import ReportsQuarterly from "./reports-quarterly.component.vue";

    export default {
        components: {
            ReportsCoupon,
            ReportsAnnual,
            ReportsPortfolio,
            ReportsQuarterly
        },
        props: {
            dictionary: Object,
            toggles: Object,
            boundaries: Object,
            test: String
        },
        data() {
            return {
                submitting: false,
                success: null,
                error: null,
                isMobile: false
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        computed: {
            anyCouponReportAvailable() {
                return [
                    this.boundaries.canRequestCouponPaymentsReport
                ].includes(true);
            },
            anyAnnualReportAvailable() {
                return [
                    this.boundaries.canRequestBeneficiaryYearResultsReport
                ].includes(true);
            },
            anyReportPortfolioAvailable() {
                return [
                    this.boundaries.canRequestPortfolioOverviewReport
                ].includes(true);
            },
            yearsListAvailable() {
                return this.boundaries.beneficiaryYearResultsReportYears?.length;
            }
        },
        methods: {
            getCoupon() {
                this.submitting = true;
                this.success = null;
                this.error = null;
                post(
                    "/Reports/CouponReport",
                    {},
                    (response) => {
                        this.success = this.dictionary.coupon.messageSuccess;
                        this.submitting = false;
                    },
                    this,
                    () => {
                        this.submitting = false;
                    },
                    (err) => {
                        this.error = this.dictionary.coupon.messageFailure;
                        this.submitting = false;
                    }
                );
            },
            getAnnualStatementReport(selectedYear) {
                this.submitting = true;
                this.success = null;
                this.error = null;

                post(
                    "/Reports/AnnualReport",
                    { year: selectedYear },
                    (response) => {
                        this.success = this.dictionary.annual.messageSuccess;
                        this.submitting = false;
                    },
                    this,
                    () => {
                        this.submitting = false;
                    },
                    (err) => {
                        this.error = this.dictionary.annual.messageFailure;
                        this.submitting = false;
                    }
                );
            },
            getQuarterlyStatementReport(selectedYear, selectedQuarter) {
                this.submitting = true;
                this.success = null;
                this.error = null;

                post(
                    "/Reports/QuarterlyReport",
                    { year: selectedYear, quarter: selectedQuarter },
                    () => {
                        this.success = this.dictionary.quarterly.statementSuccess;
                    },
                    this,
                    () => {
                        this.submitting = false;
                    },
                    (err) => {
                        this.error = this.dictionary.quarterly.statementFailure;
                    }
                );
            },
            handleResize() {
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            }
        },
    };
</script>


<template>
    <div class="tab-bar">
        <b-tabs class="tab-container" ref="tabs">
            <portlet-tab  :tabTitle="dictionary.labels.tabActive"  tabId="activeTab">
                <RequiredDocumentsTable :dictionary="dictionary" :datatables-dictionary="dictionary.table" getUrl="/RequiredDocuments/ActiveRequiredDocuments" @action="viewActiveRequiredDocument" />
            </portlet-tab>
            <portlet-tab  :tabTitle="dictionary.labels.tabHistory" tabId="historyTab">
                <RequiredDocumentsTable :dictionary="dictionary" :datatables-dictionary="dictionary.table" getUrl="/RequiredDocuments/HistoryRequiredDocuments" @action="viewHistoryRequiredDocument" />
            </portlet-tab>
        </b-tabs>
    </div>
</template>

<script>
    import RequiredDocumentsTable from "./required-documents-table.component.vue";

    export default {
        mounted() {
            if(this.$route.hash)
            {
                var params = this.$route.hash.split("&");
                this.$refs.tabs.currentTab = params.includes("history")? 1:0;
            }
        },
        components: {
            RequiredDocumentsTable
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
        },
        methods: {
            viewActiveRequiredDocument(documentId, documentParticipationId){
                this.view(documentId, documentParticipationId,"active");
            },
            viewHistoryRequiredDocument(documentId, documentParticipationId){
                this.view(documentId, documentParticipationId,"history");
            },
            view(documentId, documentParticipationId, requiredDocumentsActiveTab) {
                this.$router.push({ path: `/requiredDocuments/details?documentId=${documentId}&documentParticipationId=${documentParticipationId}&returnUrl=/Beneficiaries#evaluation&${requiredDocumentsActiveTab}`});
            }
        }
    };
</script>
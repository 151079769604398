<template>
    <main id="mainContent">
        <div class="login-image-box"></div>
        <div class="login-box">
            <router-view></router-view>
        </div>
    </main>
</template>

<script>
    import { getSessionRepository } from 'Shared/factory';

    export default {
        name: 'login-main',
        mounted() {
            // Can't guarantee the user has logged out correctly so must clear this at login
            // TODO: Move this "clear" method call to the session keep alive functionality when complete (user story #2127 - User session management)
            getSessionRepository().clear();

            window.onload = function () {
                var $recaptcha = document.querySelector('#g-recaptcha-response');
                if ($recaptcha) {
                    $recaptcha.setAttribute("required", "required");
                }
            };
        },
    };
</script>
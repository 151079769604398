var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.model.isOutOfHours
        ? _c("alert-component", { attrs: { variant: "warning" } }, [
            _c("div", {
              staticClass: "rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(
                    _vm.dictionary.confirmation.realTimeDealWarningOutOfHours
                  )
                )
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.model.requiresInput
        ? _c("ExitConfirmationRtdCountdownTimer", {
            attrs: { dictionary: _vm.dictionary, timerValue: _vm.timerValue }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.requiresInput
        ? _c("p", [_vm._v(_vm._s(_vm.dictionary.widgets.textConfirmation))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          _vm.model.state && !_vm.toggles.hideOrderState
            ? _c("ExitConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.confirmation.stateName,
                  value: _vm.model.state.description
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.planName,
              value: _vm.model.planName
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationPositionsRow", {
            attrs: {
              label: _vm.dictionary.confirmation.positionName,
              positions: _vm.model.positions
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.stockMarket,
              value: "London Stock Exchange"
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.confirmation.securityPrice,
              value: _vm.model.securityPriceFormatted
            }
          }),
          _vm._v(" "),
          _c(
            "ExitConfirmationNeQuantity",
            _vm._g(
              {
                attrs: {
                  isActive: _vm.model.requiresInput,
                  dictionary: _vm.dictionary,
                  exitInstructionViewModel: _vm.model
                }
              },
              _vm.$listeners
            )
          ),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            attrs: {
              label: _vm.dictionary.exitsConfirmationLabelGrossAmount,
              value: _vm.model.grossAmountFormatted
            }
          }),
          _vm._v(" "),
          _c("payments-result", {
            attrs: {
              payments: _vm.model.feesPayments,
              noPaymentsLabel: _vm.dictionary.confirmation.paymentFees
            }
          }),
          _vm._v(" "),
          _c("ExitConfirmationRow", {
            staticClass: "confirmation-total-row",
            attrs: {
              label: _vm.dictionary.confirmation.proceeds,
              value: _vm.model.proceedsFormatted
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        !_vm.model.requiresInput
          ? _c("div", { staticClass: "float-md-right clearfix" }, [
              _c(
                "button",
                {
                  staticClass: "btn primaryButton",
                  attrs: { disabled: false },
                  on: {
                    click: function($event) {
                      return _vm.$emit("exit-instruction-go-back")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.dictionary.form.goBack))]
              )
            ])
          : !_vm.model.disclaimers.length
          ? _c(
              "div",
              { staticClass: "mr-0" },
              [
                _c(
                  "ExitConfirmationButtonsComponent",
                  _vm._g(
                    {
                      attrs: {
                        isQuoteExpires: _vm.quoteExpires,
                        exitInstructionViewModel: _vm.model
                      }
                    },
                    _vm.$listeners
                  )
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-basic",
    {
      attrs: {
        title: _vm.header,
        "is-on": _vm.isModalVisible,
        "ok-footer": false,
        staticMode: true,
        lazy: false,
        centered: true,
        "close-button": _vm.closeButton
      },
      on: {
        close: function($event) {
          return _vm.$emit("cancel")
        }
      }
    },
    [
      _c(
        "Mfa",
        _vm._g(
          {
            attrs: {
              initialCurrentFactorType: _vm.currentFactorType,
              currentFactorType: _vm.currentFactorType,
              message: _vm.message,
              isVisible: _vm.isModalVisible,
              "mfa-boundaries-data-model": _vm.mfaBoundariesDataModel,
              "mfa-enable-disable-data-model": _vm.mfaEnableDisableDataModel,
              phone: _vm.phone,
              showFirstScreen: _vm.showFirstScreen
            },
            on: {
              "disable-close-button": function($event) {
                _vm.closeButton = false
              }
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredInfoModel
    ? _c("required-info-step", {
        attrs: {
          id: "tax-residency-container",
          currentStep: _vm.currentStep,
          step: _vm.step,
          heading: _vm.dictionary.labels.step2,
          description: _vm.dictionary.labels.step2Description,
          dictionary: _vm.dictionary
        },
        on: {
          "edit-step": function(val) {
            return _vm.$emit("edit-step", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "previous",
              fn: function() {
                return [
                  _c(
                    "dl",
                    { staticClass: "details-list tax residency-details" },
                    [
                      _c(
                        "div",
                        { staticClass: "details-list-left" },
                        [
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.isUSCitizen
                                ? _vm.dictionary.labels.usCitizenYes
                                : _vm.dictionary.labels.usCitizenNo,
                              label: _vm.dictionary.labels.usCitizen
                            }
                          }),
                          _vm._v(" "),
                          _vm.requiredInfoModel.isUSCitizen
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "mb-1" },
                                  [
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value:
                                          _vm.requiredInfoModel
                                            .usCitizenNationality.fiscalNumber,
                                        label: _vm.dictionary.labels.usTin
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: _vm._f("toDate")(
                                          _vm.requiredInfoModel
                                            .usCitizenNationality.startDate
                                        ),
                                        label: _vm.dictionary.labels.startDate
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: _vm._f("toDate")(
                                          _vm.requiredInfoModel
                                            .usCitizenNationality.endDate
                                        ),
                                        label: _vm.dictionary.labels.endDate
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            _vm.requiredInfoModel.fiscalNationalities,
                            function(fiscalNationality, index) {
                              return [
                                _c(
                                  "div",
                                  { key: index, staticClass: "mb-1" },
                                  [
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: _vm.getCountryName(
                                          fiscalNationality.countryId
                                        ),
                                        label:
                                          _vm.dictionary.labels
                                            .countryOfTaxResidency
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: fiscalNationality.fiscalNumber,
                                        label: _vm.dictionary.labels.tiNorNI
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: _vm._f("toDate")(
                                          fiscalNationality.startDate
                                        ),
                                        label: _vm.dictionary.labels.startDate
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("DataLabelRow", {
                                      attrs: {
                                        value: _vm._f("toDate")(
                                          fiscalNationality.endDate
                                        ),
                                        label: _vm.dictionary.labels.endDate
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "current",
              fn: function() {
                return [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "mb-2" }, [
                        _c(
                          "span",
                          [
                            _vm._v(_vm._s(_vm.dictionary.labels.usCitizen)),
                            _c("i", {
                              staticClass: "far fa-info-circle popover-info",
                              attrs: {
                                id: "usCitizenToolTip",
                                "aria-hidden": "true"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "usCitizenToolTip",
                                  triggers: "hover",
                                  placement: "bottom"
                                }
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "us-citizen-tooltip rich-text-container",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(
                                        _vm.dictionary.tooltips.usCitizen
                                      )
                                    )
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "input-group mb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-check form-check-inline pl-2 pr-2"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.requiredInfoModel.isUSCitizen,
                                    expression: "requiredInfoModel.isUSCitizen"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  id: "isUSCitizenYes",
                                  name: "isUSCitizen"
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.requiredInfoModel.isUSCitizen,
                                    true
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.requiredInfoModel,
                                      "isUSCitizen",
                                      true
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "mb-0 form-input-label",
                              attrs: { for: "isUSCitizenYes" }
                            },
                            [_vm._v(_vm._s(_vm.dictionary.labels.usCitizenYes))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-check form-check-inline pl-2 pr-2"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.requiredInfoModel.isUSCitizen,
                                    expression: "requiredInfoModel.isUSCitizen"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  id: "isUSCitizenNo",
                                  name: "isUSCitizen"
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.requiredInfoModel.isUSCitizen,
                                    false
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.requiredInfoModel,
                                      "isUSCitizen",
                                      false
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "mb-0 form-input-label",
                              attrs: { for: "isUSCitizenNo" }
                            },
                            [_vm._v(_vm._s(_vm.dictionary.labels.usCitizenNo))]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.fiscalNationalitiesError
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "alert-component",
                              { attrs: { variant: "error" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.fiscalNationalitiesError.message)
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.requiredInfoModel.isUSCitizen
                    ? [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("input-element", {
                                attrs: {
                                  name: "usCitizenFiscalNumber",
                                  label: _vm.dictionary.labels.usTin,
                                  validationsList:
                                    _vm.usCitizenNationalityValidations.usTin
                                },
                                model: {
                                  value:
                                    _vm.requiredInfoData.usCitizenNationality
                                      .fiscalNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requiredInfoData.usCitizenNationality,
                                      "fiscalNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "requiredInfoData.usCitizenNationality.fiscalNumber"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("datepicker-element", {
                                attrs: {
                                  name: "usCitizenStartDate",
                                  id: "usCitizenStartDate",
                                  label: _vm.dictionary.labels.startDate,
                                  validationsList:
                                    _vm.usCitizenNationalityValidations
                                      .startDate,
                                  "is-mandatory": true,
                                  "year-range": "-150:+0"
                                },
                                model: {
                                  value:
                                    _vm.requiredInfoData.usCitizenNationality
                                      .startDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requiredInfoData.usCitizenNationality,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "requiredInfoData.usCitizenNationality.startDate"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("datepicker-element", {
                                attrs: {
                                  name: "usCitizenEndDate",
                                  id: "usCitizenEndDate",
                                  label: _vm.dictionary.labels.endDate,
                                  validationsList:
                                    _vm.usCitizenNationalityValidations.endDate,
                                  commonDictionary: _vm.dictionary.common,
                                  "show-button-panel": true,
                                  "year-range": "-150:+10"
                                },
                                model: {
                                  value:
                                    _vm.requiredInfoData.usCitizenNationality
                                      .endDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requiredInfoData.usCitizenNationality,
                                      "endDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "requiredInfoData.usCitizenNationality.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "fiscal-nationalities-container" },
                    [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.labels.otherTaxResidencyDescription
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.requiredInfoModel.fiscalNationalities,
                        function(fiscalNationality, index) {
                          return [
                            _c("tax-residency-fiscal-nationality", {
                              key: fiscalNationality._id,
                              attrs: {
                                countries: _vm.listCountries,
                                dictionary: _vm.dictionary,
                                validations: _vm.getFiscalNationalityValidation(
                                  index
                                )
                              },
                              on: {
                                "delete-item": function($event) {
                                  return _vm.removeFiscalNationality(
                                    fiscalNationality._id
                                  )
                                }
                              },
                              model: {
                                value:
                                  _vm.requiredInfoModel.fiscalNationalities[
                                    index
                                  ],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requiredInfoModel.fiscalNationalities,
                                    index,
                                    $$v
                                  )
                                },
                                expression:
                                  "requiredInfoModel.fiscalNationalities[index]"
                              }
                            })
                          ]
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "modal-basic",
                        {
                          attrs: {
                            "is-on": _vm.isDeleteNotificationModalVisible
                          },
                          on: {
                            close: function($event) {
                              return _vm.closeDeleteModal()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.dictionary.validation.unabletoDelete) +
                              "\n      "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.canAddNewFiscalNationality
                        ? _c("div", { staticClass: "mb-4" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn text-white",
                                on: { click: _vm.addFiscalNationality }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-plus mr-2",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  _vm._s(_vm.dictionary.buttons.addTaxResidency)
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-md-flex justify-content-md-between button-container"
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn secondaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.previousStepAccessibilityLabel(
                                _vm.step
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.previousStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dictionary.buttons.previous) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn primaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.nextStepAccessibilityLabel(
                                _vm.step,
                                _vm.steps
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.nextStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dictionary.buttons.next) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          960542713
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
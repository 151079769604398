<template>
    <div>
        <div class="title-block single-line">
            <h1 v-if="positionModel">{{ positionModel.name }}</h1>
        </div>
        <div class="portlets-wrapper">
            <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="positionModel && dictionary" class="row m-0">
                <div class="col-md-8 col-xl-9 p-0">
                    <div class="row m-0">
                    <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalAvailable" :text="positionModel.availableFormatted" />
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalValue" :text="getValueFormatted(positionModel, toggles)"></total-block>
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true" >
                            <template slot="body">
                               <security-price-block :title="dictionary.blocks.lastPrice" :endpoint="lastPriceEndpoint"/>
                            </template>
                        </portlet-layout>
                    </div>
                    <div class="row m-0">
                        <div class="col-xl-5 p-0">
                            <portlet-layout column-class="block" :title="dictionary.widgets.placeOrder" >
                                <template v-slot:body>
                                    <ExitOrderForm :dictionary="dictionary" :positionName="positionModel.name" :positionViewModel="positionModel" :isMultiExit="false" :exitOrderModel="instructionModel" />
                                    <loading-spinner :active="isSubmitting"></loading-spinner>
                                </template>
                            </portlet-layout>
                        </div>
                        <div class="col-xl-7 p-0">
                            <portlet-layout v-if="!toggles.hideSecurityPricesChartBlock" column-class="block" :title="dictionary.widgets.securityPricesChart" >
                                <template slot="body">
                                    <prices-chart :getSecurityPrices="true" :securityId="positionModel.securityId" />
                                </template>
                            </portlet-layout>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-3 p-0">
                    <portlet-layout v-if="!toggles.hideLastOrdersLinkBlock" column-class="block" :title="dictionary.widgets.lastOrders" >
                        <template slot="body">
                            <last-orders :toggles="toggles" :positionUsageId="positionModel.positionUsageId" />
                            <order-detail v-if="ordersDictionary" :title="ordersDictionary.modal.headerTitle" :toggles="toggles"></order-detail>
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/orders" :title="dictionary.widgets.seeAllOrders">{{dictionary.widgets.seeAllOrders}}</router-link>
                        </template>
                    </portlet-layout>
                    <portlet-layout v-if="!toggles.hideLastTransactionsLinkBlock" column-class="block" :title="dictionary.widgets.lastTransactions" >
                        <template slot="body">
                            <last-transactions :positionUsageId="positionModel.positionUsageId" />
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/transactions" :title="dictionary.widgets.seeAllTransactions">{{dictionary.widgets.seeAllTransactions}}</router-link>
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get } from "api";
    import OrderDetail from "/Features/Orders/Assets/Vue/order-detail.component.vue";
    import ExitOrderForm from "./exit-order-form.component.vue";
    import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';

    export default {
        mixins: [ positionMethods ],
        components: {
            ExitOrderForm,
            OrderDetail
        },
        props: {
            toggles: Object,
            dictionary: Object,
            ordersDictionary: Object
        },
        data() {
            return {
                positionModel: null,
                instructionModel: null,
                error: null,
                isLoading: true,
                isSubmitting: false
            };
        },
        computed:{
            lastPriceEndpoint(){
                let query = this.$route.query;
                return `/InternalExchanges/GetLastPriceBlock?positionUsageId=${query.positionUsageId}`;
            }
        },
        beforeCreate() {
            let query = this.$route.query;
            get(
                `/exits/InstructionOrder?sharePlanParticipationId=${query.sharePlanParticipationId}` +
                `&positionUsageId=${query.positionUsageId}`,
                (response) => {
                    this.positionModel = response.data;
                    this.instructionModel = response.data.exitInstructionOrderViewModel;
                    if (query.orderType) {
                        this.instructionModel.orderType = query.orderType;
                    }
                },
                this,
                () => (this.isLoading = false)
            );
        },
    };
</script>
<template>
    <div>
        <BeneficiaryAccountEdit :data-model="accountDataEditModel" :common-dictionary="commonDictionary"
                                :dictionary="accountDictionary" @submit-form="submit" @cancel-form="$emit('account-edit-form-cancelled')"></BeneficiaryAccountEdit>
    </div>
</template>

<script>
    import BeneficiaryAccountEdit from './account-edit.component.vue';
    import { post } from "api";

    export default {
        components: {
            BeneficiaryAccountEdit
        },
        props: {
            accountDataEditModel: Object,
            commonDictionary: Object,
            accountDictionary: Object
        },
        methods: {
            submit(submitedData) {
                post("/Beneficiaries/EditBeneficiary",
                    {
                        emailAddress: submitedData.emailAddress,
                        ccEmailAddress: submitedData.ccEmailAddress,
                        localeId: submitedData.locale.id,
                        telephone: submitedData.telephone,
                        mfaEnabled: submitedData.mfaState,
                        decimalSeparator: submitedData.decimalSeparator.value
                    },
                    (response) => {
                        this.$emit('reload-page');
                    },
                    this,
                    () => { },
                    (err) => { }
                );
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: { hideTitle: true },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm.displayDividendSubscriptions
              ? _c(
                  "div",
                  {
                    staticClass: "dividend-body",
                    attrs: { id: "dividend-body-dividend-subscriptions-table" }
                  },
                  [
                    _c("h2", [
                      _vm._v(
                        _vm._s(
                          _vm.dictionary.subscriptionWidgets
                            .titleDividendSubscription
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "page-description rich-text-container",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(
                            _vm.dictionary.page
                              .dividendPageSubscriptionDescription
                          )
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("DividendSubscriptionsTable", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        datatablesDictionary: _vm.datatablesDictionary
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dividend-body",
                attrs: { id: "dividend-body-dividend-table" }
              },
              [
                _c("h2", [
                  _vm._v(_vm._s(_vm.dictionary.widgets.particiaptions))
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "page-description rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(_vm.dictionary.page.dividendPageDescription)
                    )
                  }
                }),
                _vm._v(" "),
                _c("DividendTable", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    toggles: _vm.toggles,
                    datatablesDictionary: _vm.datatablesDictionary
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="plan-documents-container">

    <v-data-table id="dtPlanDocuments"
                  class="table"
                  hide-default-header
                  :no-data-text="dictionary.labels.noRecordPlanDocuments"
                  :headers="headers"
                  :items="rows"
                  :loading="isLoading"
                  :server-items-length="totalRecords"
                  :search="search"
                  :items-per-page="options.itemsPerPage"
                  @page-count="options.pageCount = $event;"
                  :options.sync="options"
                  hide-default-footer
                  >

      <template v-slot:top>
        <TablePlanDocumentsFilterAndSort :dictionary="dictionary" @filter-plan-documents="filterPlanDocuments" @sort-by-plan-document-date="sortByPlanDocumentDate" :plans="plans" />
        <datatable-search :label="dictionary.labels.searchByKeywords" v-model="search" />
      </template>

      <template v-slot:item="{ item }">
        <TablePlanDocumentsBody :dictionary="dictionary" :item="item" />
      </template>

      <template v-slot:footer>
        <datatable-pagination :datatablesDictionary="dictionary.labels" v-model="options" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { vueDataTableMixin } from "Shared/Mixins/vue-data-table.mixin";
  import TablePlanDocumentsFilterAndSort from "./table-plan-documents-filter-and-sort.component.vue";
  import TablePlanDocumentsBody from "./table-plan-documents-body.component.vue";
  import AlertComponent from "../../../../Shared/Assets/Components/Vue/Alert/alert.component.vue";
  import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

  export default {
    mixins: [vueDataTableMixin, ScreenSizeMixin],
    components: {
      TablePlanDocumentsFilterAndSort,
      TablePlanDocumentsBody,
      AlertComponent,
    },
    props: {
      dictionary: Object,
      plans: Array,
    },
    data() {
      return {
        getUrl: "/Document/PlanDocuments",
        options: {
          itemsPerPage: 10,
          page: 1,
          sortBy: ["documentDate"],
          sortDesc: [true],
          pageCount: 0,
          itemsPerPageOptions: [
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: this.dictionary.labels.showAll, value: -1 }
          ]
        },
        rows: [],
        totalRecords: 0,
        headers: [],
        timer: null
      };
    },
    created() {
      this.timer = setInterval(this.loadItems, 60 * 1000);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      filterPlanDocuments: function (planName) {
        this.search = planName;
      },
      sortByPlanDocumentDate: function (value) {
        if (value == 1) {
          this.options.sortDesc = [true];
        } else {
          this.options.sortDesc = [false];
        }
        this.loadItems();
      }
    }
  };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "beneficiary-data-group form-group" }, [
    _c(
      "label",
      {
        staticClass: "beneficiary-data-group__label-readonly form-input-label"
      },
      [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "beneficiary-data-group__true-false-icon" }, [
      _c("i", {
        class: ["fa", _vm.icon, _vm.iconStyle],
        attrs: { "aria-hidden": "true" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-header" }, [
    _c("div", { staticClass: "email-header__subject row" }, [
      _vm.isMobileOrTablet
        ? _c(
            "div",
            {
              staticClass: "col-1 back-button",
              on: {
                click: function($event) {
                  return _vm.$emit("back-click", false)
                }
              }
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-10" }, [
        _c("h2", [_vm._v(_vm._s(_vm.message.subject))]),
        _vm._v(" "),
        _c("div", { staticClass: "email-header__subject__date" }, [
          _c("span", [_vm._v(_vm._s(_vm.message.creationDate))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("i", {
        staticClass: "far fa-arrow-alt-circle-left",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "personal-documents-container" } },
    [
      _c("v-data-table", {
        staticClass: "table",
        attrs: {
          id: "dtPersonalDocuments",
          "hide-default-header": "",
          "no-data-text": _vm.dictionary.labels.noRecordPersonalDocuments,
          headers: _vm.headers,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("datatable-search", {
                  attrs: { label: _vm.dictionary.searchByKeywords },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("TablePersonalDocumentsTableBody", {
                  attrs: { dictionary: _vm.dictionary, item: item }
                })
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("datatable-pagination", {
                  attrs: { datatablesDictionary: _vm.dictionary.labels },
                  model: {
                    value: _vm.options,
                    callback: function($$v) {
                      _vm.options = $$v
                    },
                    expression: "options"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
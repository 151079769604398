<template>
    <div>
        <input-element v-if="isQuantityShown" name="quantity" :label="dictionary.form.quantity" type="text" :value="model.quantity" @input="$emit('quantity', $event)"
                       :validationsList="quantityValidations"></input-element>

        <template v-if="quantityType === $QuantityInputType.ALL">
            <fieldset>
                <legend class="form-input-label label">{{ dictionary.form.quantity }}</legend>
                <div class="form-group">
                    <label class="form-input-label">{{ quantityAllAvailableLabel }}</label>
                </div>
            </fieldset>
        </template>

        <template v-if="quantityType === $QuantityInputType.ALL_AND_SELECT">
            <fieldset>
                <legend class="form-input-label label">{{ dictionary.form.quantity }}</legend>
                <div class="form-group">
                    <div class="input-group">
                        <div class="form-check form-check-inline pl-2 pr-2">
                            <input id="quantitySelectionValue" class="form-check-input" type="radio" :value="$QuantityInputType.ALL" v-model="quantitySelectionValue" />
                        </div>
                        <label for="quantitySelectionValue" class="mb-0 form-input-label">{{ quantityLabel }}</label>
                    </div>
                </div>

                <input-element name="quantityAllAndSelectSelectAll" type="text" :value="model.quantityAllAndSelectSelect"
                               @input="$emit('quantityAllAndSelectSelect', $event)" :disabled="equalsQuantity($QuantityInputType.ALL)"
                               :validationsList="quantityAllAndSelectSelect" :labelClasses="['sr-only']">
                    <template slot="group">
                        <div class="form-check form-check-inline pl-2 pr-2">

                            <label for="quantityAllAndSelectSelect" class="sr-only form-input-label">{{ dictionary.form.quantity }}</label>
                            <input class="form-check-input" type="radio" :value="$QuantityInputType.SELECT" v-model="quantitySelectionValue" id="quantityAllAndSelectSelect" />

                        </div>
                    </template>
                </input-element>

            </fieldset>
        </template>
    </div>
</template>

<script>

    export default {
        props: {
            isMultiExit: Boolean,
            validations: Object,
            dictionary: Object,
            model: Object,
            quantityType: String
        },
        computed: {
            quantitySelectionValue: {
                get() {
                    return this.model.quantityAllAndSelectSelection;
                },
                set(value) {
                    if (value === this.$QuantityInputType.ALL) {
                        this.model.quantityAllAndSelectSelect = '';
                    }
                    this.$emit('quantitySelection', value);
                }
            },
            isQuantityShown() {
                return this.quantityType !== this.$QuantityInputType.ALL_AND_SELECT && this.quantityType !== this.$QuantityInputType.ALL;
            },
            quantityLabel() {
                return this.isMultiExit
                    ? `${this.dictionary.form.selectionQuantityAll} (${this.model.availableAmountScenarioFormatted})`
                    : this.model.quantityAllAndSelectAll;
            },
            quantityAllAvailableLabel() {
                return `${this.dictionary.form.selectionQuantityAllAvailable}: ${this.model.availableAmountScenarioFormatted}`;
            },
            quantityValidations() {
                return [
                    {
                        id: 1,
                        error: this.validations.quantity.$error,
                        type: this.validations.quantity.required,
                        message: this.dictionary.validation.quantityRequired,
                    },
                    {
                        id: 2,
                        error: this.validations.quantity.$error,
                        type: this.validations.quantity.mustBeDecimal,
                        message: this.dictionary.validation.quantityInvalid,
                    },
                    {
                        id: 3,
                        error: this.validations.quantity.$error,
                        type: this.validations.quantity.mustBeModuloMinimum,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.transferModuloMinimum,
                            {
                                "TransferModulo": this.model.exitInstructionBoundaries.transferModulo,
                                "TransferMinimum": this.model.exitInstructionBoundaries.transferMinimum,
                            }
                        ),
                    },
                    {
                        id: 4,
                        error: this.validations.quantity.$error,
                        type: this.validations.quantity.mustBeValidSecurityAmount,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.availableSecurityAmount,
                            {
                                "AvailableSecurityAmount": this.isMultiExit ? this.model.availableAmountScenario : this.model.available,
                            }
                        )
                    }
                ]
            },
            quantityAllValidations() {
                return [
                    {
                        id: 1,
                        error: this.validations.quantityAll.$error,
                        type: this.validations.quantityAll.required,
                        message: this.dictionary.validation.quantityRequired,
                    },
                    {
                        id: 2,
                        error: this.validations.quantityAll.$error,
                        type: this.validations.quantityAll.mustBeDecimal,
                        message: this.dictionary.validation.quantityInvalid,
                    },
                    {
                        id: 3,
                        error: this.validations.quantityAll.$error,
                        type: this.validations.quantityAll.mustBeModuloMinimum,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.transferModuloMinimum,
                            {
                                "TransferModulo": this.model.exitInstructionBoundaries.transferModulo,
                                "TransferMinimum": this.model.exitInstructionBoundaries.transferMinimum,
                            }
                        ),
                    },
                    {
                        id: 4,
                        error: this.validations.quantityAll.$error,
                        type: this.validations.quantityAll.mustBeValidSecurityAmount,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.availableSecurityAmount,
                            {
                                "AvailableSecurityAmount": this.isMultiExit ? this.model.availableAmountScenario : this.model.available,
                            }
                        )
                    }
                ]
            },
            quantityAllAndSelectSelect() {
                return [
                    {
                        id: 1,
                        error: this.validations.quantityAllAndSelectSelect.$error,
                        type: this.validations.quantityAllAndSelectSelect.required,
                        message: this.dictionary.validation.quantityRequired,
                    },
                    {
                        id: 2,
                        error: this.validations.quantityAllAndSelectSelect.$error,
                        type: this.validations.quantityAllAndSelectSelect.mustBeDecimal,
                        message: this.dictionary.validation.quantityInvalid,
                    },
                    {
                        id: 3,
                        error: this.validations.quantityAllAndSelectSelect.$error,
                        type: this.validations.quantityAllAndSelectSelect.mustBeModuloMinimum,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.transferModuloMinimum,
                            {
                                "TransferModulo": this.model.exitInstructionBoundaries.transferModulo,
                                "TransferMinimum": this.model.exitInstructionBoundaries.transferMinimum,
                            }
                        ),
                    },
                    {
                        id: 4,
                        error: this.validations.quantityAllAndSelectSelect.$error,
                        type: this.validations.quantityAllAndSelectSelect.mustBeValidSecurityAmount,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.availableSecurityAmount,
                            {
                                "AvailableSecurityAmount": this.isMultiExit ? this.model.availableAmountScenario : this.model.available,
                            }
                        )
                    }
                ]
            }
        },
        methods: {
            equalsQuantity(quantity) {
                return this.model.quantityAllAndSelectSelection === quantity;
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-block" }, [
      _vm.pagesDictionary
        ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.dashboard.title))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("Dashboard", {
          attrs: {
            dictionary: _vm.dictionary,
            toggles: _vm.toggles,
            "orders-dictionary": _vm.ordersDictionary,
            "security-chart-model": _vm.securityChartModel,
            "dashboard-data": _vm.dashboardData
          }
        }),
        _vm._v(" "),
        _c("component-spinner", { attrs: { spin: _vm.isLoading } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "modal-basic",
        {
          attrs: {
            title: _vm.title,
            "is-on": _vm.isModalVisible,
            "ok-footer": !_vm.hasModalFooter
          },
          on: {
            close: function($event) {
              _vm.isModalVisible = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _vm.hasModalFooter
                    ? _c("InternalExchangeCancelOrderModalWrapper", {
                        attrs: {
                          instructionData: _vm.instructionData,
                          dictionary: _vm.dictionary
                        },
                        on: {
                          close: function($event) {
                            _vm.isModalVisible = false
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            _vm.transactionTypeModalElement,
            _vm._b(
              { tag: "component" },
              "component",
              {
                "instruction-data": _vm.instructionData,
                dictionary: _vm.dictionary,
                toggles: _vm.toggles
              },
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
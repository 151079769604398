<template>
    <div class="row">
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="bankAccount.iban" 
            :display-value="displayValueForAccountNumber" 
            :label="financialDictionary.accountNumber" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="bankAccount.bankName" 
            :label="financialDictionary.bankName" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="bankAccount.currency.name" 
            :label="financialDictionary.currency" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="bankAccount.country.name" 
            :label="financialDictionary.country" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            v-if="showAccountRelationship"
            :value="bankAccount.relationType" 
            :label="financialDictionary.relationType" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            v-if="showUsedInPlanAs"
            :value="bankAccount.usedInPlanAs" 
            :label="financialDictionary.accountType" />
        <BeneficiaryDataLabel
            class="col-md-6" 
            :value="bankAccount.bic" 
            :label="financialDictionary.bic" />
    </div>
</template>

<script>
import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
  props: {
    financialDictionary: Object,
    bankAccount: Object,
    financialToggles: Object,
    accountType:String
  },
  components: {
    BeneficiaryDataLabel,
  },
  computed: {      
    showAccountRelationship() {
        return this.accountType === this.$BankAccountType.MONEY ? !this.financialToggles.hideMoneyAccountRelationship : true;
    },
    showUsedInPlanAs() {
        return this.accountType === this.$BankAccountType.MONEY ? !this.financialToggles.hideMoneyAccountUsedInPlanAs : true;
    },
    displayValueForAccountNumber() {
        if (this.accountType === this.$BankAccountType.MONEY && (this.bankAccount.iban && this.bankAccount.requiresIBAN)) {
            return this.bankAccount.iban
        }

        return this.bankAccount.accountNumber;
    }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isMobile
      ? _c("div", { staticClass: "orders-listitem-container" }, [
          _c("dt", [_vm._v(_vm._s(_vm.dictionary.modal.positionName))]),
          _vm._v(" "),
          _c(
            "dd",
            [
              _c("div", [_vm._v(_vm._s(_vm.instructionData.SecurityName))]),
              _vm._v(" "),
              _vm._l(_vm.exitSecondaryPositions, function(position, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    "            \n        " +
                      _vm._s(position.PositionUsageName) +
                      "\n        "
                  )
                ])
              })
            ],
            2
          )
        ])
      : _c(
          "div",
          [
            _c("div", { staticClass: "orders-row-flex" }, [
              _c("p", { staticClass: "orders-row__label" }, [
                _vm._v(_vm._s(_vm.dictionary.modal.positionName))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "orders-row-flex" }, [
              _c(
                "p",
                {
                  class: ["orders-row__value", { "text-right": _vm.textRight }]
                },
                [_vm._v(_vm._s(_vm.instructionData.SecurityName))]
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.exitSecondaryPositions, function(position, index) {
              return _c("div", { key: index, staticClass: "orders-row-flex" }, [
                _c(
                  "p",
                  {
                    class: [
                      "orders-row__value",
                      { "text-right": _vm.textRight }
                    ]
                  },
                  [_vm._v(_vm._s(position.PositionUsageName))]
                )
              ])
            }),
            _vm._v(" "),
            _c("v-divider", { staticClass: "orders-row-divider" })
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
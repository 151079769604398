var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "formCheckContainer mb-3" }, [
    _c("div", [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: _vm.inputType,
          name: "Answer_Mode_Select_Value_IsSelected",
          id: "Answer_Mode_Select_Value_" + _vm.itemIndex,
          disabled: !_vm.choiceInstructionModel.canInstruct
        },
        domProps: {
          checked: _vm.scenario.isSelected,
          value: _vm.scenario.isSelected
        },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-input-label",
          attrs: { for: "Answer_Mode_Select_Value_" + _vm.itemIndex }
        },
        [_vm._v(_vm._s(_vm.scenario.title))]
      )
    ]),
    _vm._v(" "),
    _vm.scenario.text
      ? _c("div", { staticClass: "label-container" }, [
          _c("label", { staticClass: "form-input-label" }, [
            _c("strong", [_vm._v(_vm._s(_vm.scenario.text))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
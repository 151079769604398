var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fiscal-nationality-item" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("select-element", {
            attrs: {
              label: _vm.dictionary.labels.countryOfTaxResidency,
              options: _vm.countries,
              name: "countryId_" + _vm.componentId,
              validationsList: _vm.validations.countryId,
              "is-mandatory": true,
              "tool-tip-text": _vm.dictionary.tooltips.countryOfTaxResidency
            },
            model: {
              value: _vm.fiscalNationality.countryId,
              callback: function($$v) {
                _vm.$set(_vm.fiscalNationality, "countryId", $$v)
              },
              expression: "fiscalNationality.countryId"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("input-element", {
            attrs: {
              name: "fiscalNumber_" + _vm.componentId,
              label: _vm.dictionary.labels.tiNorNI
            },
            model: {
              value: _vm.fiscalNationality.fiscalNumber,
              callback: function($$v) {
                _vm.$set(_vm.fiscalNationality, "fiscalNumber", $$v)
              },
              expression: "fiscalNationality.fiscalNumber"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("datepicker-element", {
            attrs: {
              id: "startDate_" + _vm.componentId,
              name: "startDate_" + _vm.componentId,
              label: _vm.dictionary.labels.startDate,
              validationsList: _vm.validations.startDate,
              "is-mandatory": true,
              "year-range": "-150:+0"
            },
            model: {
              value: _vm.fiscalNationality.startDate,
              callback: function($$v) {
                _vm.$set(_vm.fiscalNationality, "startDate", $$v)
              },
              expression: "fiscalNationality.startDate"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("datepicker-element", {
            attrs: {
              id: "endDate_" + _vm.componentId,
              name: "endDate_" + _vm.componentId,
              label: _vm.dictionary.labels.endDate,
              validationsList: _vm.validations.endDate,
              commonDictionary: _vm.dictionary.common,
              "show-button-panel": true,
              "year-range": "-150:+10"
            },
            model: {
              value: _vm.fiscalNationality.endDate,
              callback: function($$v) {
                _vm.$set(_vm.fiscalNationality, "endDate", $$v)
              },
              expression: "fiscalNationality.endDate"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn-link text-danger delete-tax-nationality",
              on: { click: _vm.deleteItem }
            },
            [
              _c("i", {
                staticClass: "far fa-trash",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.dictionary.buttons.removeTaxResidency))
              ]),
              _vm._v(" " + _vm._s(_vm.dictionary.buttons.removeTaxResidency))
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
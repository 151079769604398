var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vesting-details-content" },
    [
      _c("VestingDetailsContentRow", {
        staticClass: "plan-name-row",
        attrs: {
          label: _vm.dictionary.labels.planName,
          value: _vm.vesting.planName
        }
      }),
      _vm._v(" "),
      _c("VestingDetailsContentRow", {
        staticClass: "vesting-name-row",
        attrs: {
          label: _vm.dictionary.labels.vestingName,
          value: _vm.vesting.vestingName
        }
      }),
      _vm._v(" "),
      _c("VestingDetailsContentRow", {
        staticClass: "vesting-date-row",
        attrs: {
          label: _vm.dictionary.labels.date,
          value: _vm.vesting.vestingDate
        }
      }),
      _vm._v(" "),
      _c("VestingDetailsContentRow", {
        staticClass: "vesting-amount-row",
        attrs: {
          label: _vm.dictionary.labels.amount,
          value: _vm.vesting.vestingAmount
        }
      }),
      _vm._v(" "),
      _vm.toggles.showVestingTaxDetails
        ? _c("VestingDetailsContentRow", {
            staticClass: "share-price-row",
            attrs: {
              label: _vm.dictionary.labels.sharePrice,
              value: _vm.replaceNullOrEmpty(_vm.vesting.sharePrice)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.toggles.showVestingTaxDetails
        ? _c("VestingDetailsContentRow", {
            staticClass: "market-value-row",
            attrs: {
              label: _vm.dictionary.labels.marketValue,
              value: _vm.replaceNullOrEmpty(_vm.vesting.marketValue)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("VestingDetailsContentRow", {
        staticClass: "state-row",
        attrs: { label: _vm.dictionary.labels.state, value: _vm.vesting.state }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "vestings-row-flex" }, [
        _c("span", [
          _c("p", [
            _vm._v("* " + _vm._s(_vm.dictionary.labels.atTimeOfVesting))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.dividend.title }}</h1>
        </div>

        <div class="portlets-wrapper">
            <component-spinner :spin="isLoading"></component-spinner>
            <router-view v-if="!isLoading" :dictionary="dictionary" :toggles="toggles" :datatables-dictionary="datatablesDictionary" :display-dividend-subscriptions="displayDividendSubscriptions"/>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Dividend from './dividend.component.vue';

    export default {
        components: {
            Dividend
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: {},
                datatablesDictionary: {},
                isLoading: true,
                toggles: {},
                displayDividendSubscriptions: false,
            }
        },
        beforeCreate() {
            getAll([
                '/Dividend/Dictionary',
                '/Dividend/DatatablesDictionary',
                '/Dividend/toggles',
                '/Dividend/DisplayDividendSubscriptions'
            ], responses => {
                this.dictionary = responses[0].data;
                this.datatablesDictionary = responses[1].data;
                this.toggles = responses[2].data;
                this.displayDividendSubscriptions = responses[3].data.displayDividendSubscriptions;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    };
</script>
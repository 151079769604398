<template>
    <div class="questionnaire-container">
        <div v-if="dictionary && !viewModel.isModal" class="title-block">
            <h1>{{dictionary.page.title}}</h1>
        </div>
        <div class="portlets-wrapper">
        <router-view :dictionary="dictionary" :view-model="viewModel" :key="`questionnaire-${$route.fullPath}`"></router-view>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';

    export default {
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                isLoading: true,
                viewModel: null
            }
        },
        beforeCreate() {
            this.$emit('set-header', {header: null, subHeader: null});
                getAll([
                    "/Questionnaire/Dictionary",
                    "/Questionnaire/Data"],
                    (responses) => {
                        this.dictionary = responses[0].data;
                        this.viewModel = responses[1].data;

                        this.$setPageTitle(this.dictionary.page.title);
                        this.$emit('set-header', {header: this.dictionary.page.title});
                    },
                    this,
                    () => (this.isLoading = false)
                );
        }
    }
</script>
<template>
    <form :id="formName" method="post">
        <component :is="childComponent"
                   v-bind="{ 'edit-model': editModel, 'dictionary': accountDictionary, 'commonDictionary': commonDictionary, }"
                   @cancel-form="cancelForm()" @submit-form="submitForm()"></component>
    </form>
</template>

<script>
    import { post } from 'api';
    import BeneficiaryAccountEdit from "./account-edit.component.vue";
    import BeneficiaryAddressEdit from "./address-edit.component.vue";

    const validateForm = (model, validations) => {
        if (!validations) return [];

        var errorKeys = [];
        validations.forEach((x) => {
            var value = model[x.key];
            if (x.required && !value) {
                errorKeys.push(x.key);
            }
        });

        return errorKeys;
    };

    export default {
        props: {
            editModel: Object,
            validations: Array,
            formName: String,
            urlPostAction: String,
            childComponent: String,
            commonDictionary: Object,
            accountDictionary: Object
        },
        components: {
            BeneficiaryAccountEdit,
            BeneficiaryAddressEdit
        },
        methods: {
            cancelForm: function () {
                this.$emit("form-cancelled");
            },
            submitForm: async function () {
                this.errorKeys = validateForm(this.editModel, this.validations);
                if (this.errorKeys.length !== 0) return;

                post(this.urlPostAction,
                    new FormData(document.getElementById(this.formName)),
                    () => {
                        this.$emit("form-submitted");
                    }, this);
            }
        }
    };
</script>
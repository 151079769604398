<template>
    <modal-basic size="md" :title="dictionary.employmentModalTitle"
        :staticMode="true" :lazy="false" :centered="true" :close-button="true" :isOn="showModal"
        @close="$emit('close-modal')">
            <EmploymentHistoryDetailRow :label="dictionary.company" :value="selectedLocation.company"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.hr" :value="selectedLocation.hr"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.subsidiary" :value="selectedLocation.subsidiary"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.registrationNumber" :value="selectedLocation.registrationNumber"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.division" :value="selectedLocation.division"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.position" :value="selectedLocation.position"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.location" :value="selectedLocation.location"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.startDate" :value="selectedLocation.startDate" :displayValue="selectedLocation.startDate | toDate"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.endDate" :value="selectedLocation.endDate" :displayValue="selectedLocation.endDate | toDate"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.appointment" :value="selectedLocation.appointment"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.businessArea" :value="selectedLocation.businessArea"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.businessUnit" :value="selectedLocation.businessUnit"></EmploymentHistoryDetailRow>
            <EmploymentHistoryDetailRow :label="dictionary.costCenter" :value="selectedLocation.costCenter"></EmploymentHistoryDetailRow>
    </modal-basic>
</template>

<script>

import EmploymentHistoryDetailRow from './employment-history-detail-row.component.vue'; 

export default {
    components: {
        EmploymentHistoryDetailRow
    },
    props: {
        dictionary: Object,
        showModal: Boolean,
        selectedLocation: Object
    }
}
</script>

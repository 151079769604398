var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("p", { staticClass: "primary-color label font-weight-bold" }, [
      _vm._v(_vm._s(_vm.dictionary.form.orderLabelAuthorityToDeal))
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "rich-text-container",
      domProps: {
        innerHTML: _vm._s(
          _vm.$sanitize(_vm.dictionary.form.orderTextAuthorityToDeal)
        )
      }
    }),
    _vm._v(" "),
    _c("label", { staticClass: "mb-0 form-check-inline form-input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkboxValue,
            expression: "checkboxValue"
          }
        ],
        staticClass: "form-check-input",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.checkboxValue)
            ? _vm._i(_vm.checkboxValue, null) > -1
            : _vm.checkboxValue
        },
        on: {
          change: function($event) {
            var $$a = _vm.checkboxValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkboxValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkboxValue = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "pl-2" }, [
        _vm._v(_vm._s(_vm.dictionary.form.orderCheckboxAuthorityToDealLabel))
      ])
    ]),
    _vm._v(" "),
    _vm.validations.authorityCheckbox.$error &&
    !_vm.validations.authorityCheckbox.required
      ? _c("div", { staticClass: "error" }, [
          _vm._v(_vm._s(_vm.dictionary.validation.authorityCheckboxRequired))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <beneficiary-data-label v-if="beneficiaryBoundariesSpouseInfo.mayViewSpouseFirstName" :value="spouseDataModel.spouseFirstName" :label="dictionary.spouseFirstName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesSpouseInfo.mayViewSpouseLastName" :value="spouseDataModel.spouseLastName"  :label="dictionary.spouseLastName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesSpouseInfo.mayViewSpouseMaidenName" :value="spouseDataModel.spouseMaidenName" :label="dictionary.spouseMaidenName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesSpouseInfo.mayViewSpouseInitials" :value="spouseDataModel.spouseInitials" :label="dictionary.spouseInitials"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesSpouseInfo.mayViewSpouseGender" :value="spouseDataModel.spouseGender" :label="dictionary.spouseGender"></beneficiary-data-label>
    </div>
</template>

<script>

import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
    components: {
        BeneficiaryDataLabel,
    },
    props: { 
        spouseDataModel : Object, 
        dictionary: Object,
        beneficiaryBoundariesSpouseInfo:Object
    },
}
</script>
<template>
<div class="mobile">
    <v-expansion-panels :class="['orders-details-expansion-block', 'mobile', 
        { 'expansion-panel-background' : isPanelOpened }]" v-model="panel" accordion>
        <v-expansion-panel class="orders-details-expansion-panel">
            <v-expansion-panel-header class="orders-details-expansion-panel__header">
                <h4>{{ item.productName }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile mobile-orders-details">
                <OrdersDetailsMobileContent :dictionary="dictionary" :toggles="toggles" :item="item" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</div>
</template>

<script>
import OrdersDetailsMobileContent from './orders-details-mobile-content.component.vue';

export default {
    components: {
        OrdersDetailsMobileContent,
    },
    props: {
        dictionary: Object,
        toggles: Object,
        item: Object
    },
    watch: {
        item() {
            this.panel = null;
        }
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        }
    }
}
</script>
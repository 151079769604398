import OrdersContainer from "./orders-container.component.vue";

export const ordersRoutes =
{
    path: '/orders',
    component: OrdersContainer,
    children: [
        {
            path: '*',
            redirect: '/orders'
        }
    ]
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.displayTaxData(_vm.offer)
      ? _c("div", { staticClass: "security-subscription-tax" }, [
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-bold security-subscription-tax-header"
                },
                [_vm._v(_vm._s(_vm.dictionary.table.taxPayableHeadingLabel))]
              ),
              _vm._v(" "),
              _vm.offer.taxableAmountFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label:
                        _vm.dictionary.table
                          .subscriptionsLabelFullTaxableAmount,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.taxableAmountFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.taxPayableAmountFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.taxPayableLabel,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.taxPayableAmountFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.taxMonthlyDeductionAmountFormatted
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.monthlyDeductionLabel,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.taxMonthlyDeductionAmountFormatted
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.nrOfTaxDeductionPeriods
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.noOfDeductionPeriodsLabel,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.nrOfTaxDeductionPeriods
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.displayTaxComplementaryData(_vm.offer)
      ? _c("div", { staticClass: "security-subscription-tax-complement" }, [
          _c(
            "div",
            [
              _vm.offer.fiscalCountryDescription
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label:
                        _vm.dictionary.table.subscriptionsLabelFiscalCountry,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.fiscalCountryDescription
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.taxationMoment
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label:
                        _vm.dictionary.table.subscriptionsLabelTaxationType,
                      value: _vm.replaceNullOrEmpty(
                        _vm.offer.taxationMoment.description
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.taxPercentage != null
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.subscriptionsLabelTaxRate,
                      value: _vm.displayPercentageValue(
                        _vm.offer.taxPercentage
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.offer.taxAllowancePercentage != null &&
              _vm.offer.taxAllowancePercentage != 0
                ? _c("SubscriptionDetailsRow", {
                    attrs: {
                      label: _vm.dictionary.table.subscriptionsLabelAllowance,
                      value: _vm.displayPercentageValue(
                        _vm.offer.taxAllowancePercentage
                      ),
                      isMobile: _vm.isMobile
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
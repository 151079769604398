var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-action-link" }, [
    _c(
      "a",
      {
        staticClass: "position-action-links__link",
        on: {
          click: function($event) {
            return _vm.conditionalPath()
          }
        }
      },
      [_c("span", [_vm._v(_vm._s(_vm.position.name))])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
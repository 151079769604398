var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("div", { staticClass: "beneficiary-details form-group" }, [
        _c(
          "label",
          { staticClass: "form-input-label", attrs: { for: _vm.name } },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: {
            type: _vm.getInputType(),
            id: _vm.name,
            name: _vm.name,
            disabled: !_vm.isEditable
          },
          domProps: { value: _vm.value },
          on: { input: _vm.handleInput }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getErrorKey(),
                expression: "getErrorKey()"
              }
            ],
            staticClass: "beneficiary-edit-group__error-label"
          },
          [_vm._v(_vm._s(_vm.getErrorMessage()))]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
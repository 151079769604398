<template>
    <div>
        <router-view :is-loading="isLoading" :view-model="viewModel" :dictionary="dictionary" ></router-view>
    </div>
</template>

<script>
    import { getAll, post } from "api";
    
    export default {
        data() {
            return {
                dictionary: null,
                isLoading: true,
                viewModel: null
            }
        },
        beforeCreate() {
            this.$emit('set-header', {header: null, subHeader: null});

            getAll([
                "/RequiredDocuments/Dictionary",
                "/RequiredDocuments/Data"
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.viewModel = responses[1].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.$emit('set-header', {header: this.dictionary.page.title});
                this.isLoading = false;
            }, this);
        }
    }
</script>
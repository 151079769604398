var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.position.isSecurityPosition
      ? _c("span", [_vm._v(_vm._s(_vm.position.name))])
      : _c("span", [
          _vm._v(_vm._s(_vm.position.name) + " "),
          _c("i", {
            staticClass: "far fa-money-bill-wave primary-color",
            attrs: { "aria-hidden": "true" }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
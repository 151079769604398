<template>
  <div class="subscription-name-label">
    <a href="javascript:void(0);" v-if="isLink" @click="$emit('action')">{{ replaceNullOrEmpty(name) }}</a>
    <div v-else>{{ replaceNullOrEmpty(name) }}</div>
    <div>{{ replaceNullOrEmpty(planName) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    planName: String,
    isLink: {
      type: Boolean,
      default: false
    }
  }
};
</script>
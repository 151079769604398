var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          !_vm.toggles.hideOrderState
            ? _c("OrderDataLabel", {
                attrs: {
                  label: _vm.dictionary.modal.state,
                  value: _vm.instructionData.State,
                  "custom-class-data": "font-weight-bold"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.reference,
              value: _vm.instructionData.Reference
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.planName,
              value: _vm.instructionData.PlanName
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.positionName,
              value: _vm.instructionData.BuyUnderlyingDescription
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.instructionDate,
              value: _vm.instructionData.InstructionDate
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.buyDate,
              value: _vm.instructionData.BuyDate
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.scenarioName,
              value: _vm.instructionData.BuyScenarioName
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.executionMoment,
              value: _vm.instructionData.ExecutionMoment
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: { label: _vm.dictionary.modal.ordered, value: _vm.ordered }
          }),
          _vm._v(" "),
          _vm.instructionData.LimitPrice > 0
            ? _c("OrderDataLabel", {
                attrs: {
                  label: _vm.dictionary.modal.limitPrice,
                  value: _vm.instructionData.LimitPriceFormatted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: { label: _vm.labelNr, value: _vm.dataNr }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.securityPrice,
              value: _vm.instructionData.SecurityPriceFormatted
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.grossAmount,
              value: _vm.instructionData.GrossAmountFormatted
            }
          }),
          _vm._v(" "),
          _c("OrderPayments", {
            attrs: {
              payments: _vm.instructionData.FeesPayments,
              label: _vm.dictionary.modal.paymentFees
            }
          }),
          _vm._v(" "),
          _c("OrderBrokerFees", {
            attrs: {
              brokerFees: _vm.instructionData.BrokerFees,
              brokerFeesFormated: _vm.instructionData.BrokerFeesFormatted,
              label: _vm.dictionary.modal.brokerFees
            }
          }),
          _vm._v(" "),
          _c("OrderDataLabel", {
            attrs: {
              label: _vm.dictionary.modal.netAmount,
              value: _vm.instructionData.netAmountFormatted
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

<template>
    <div>
        <div>
            <div v-if="!isModal" class="title-block single-line">
                <h1 v-if="dictionary">{{ dictionary.page.title }}</h1>
            </div>
            <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="requiredUploadDocument">
                <div v-if="requiredUploadDocument && requiredDocumentDetails.isActive && !inEdit" class="row required-document-adhoc-upload m-0 no-gutters" data-toggle="portlet">
                    <conditional-portlet-layout :condition="!isModal" portlet-class="col col-md-10 col-lg-8 col-xxl-6" div-class="col" :hideTitle="true" :allowCollapse="false">
                        <template v-slot:body>
                            <div class="required-document-header">
                                <div class="row no-gutters">
                                    <div class="required-document-header__text rich-text-container col-md-9" v-html="$sanitize(dictionary.labels.textHeaderRequiredDocument)"></div>
                                    <div class="required-document-header__button col-md-3 button-container">
                                        <button class="btn btn-primary text-nowrap float-md-right" @click="showUploadForm()">{{ dictionary.labels.actionLinkUploadNew }}</button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </conditional-portlet-layout>
                </div>
                <div class="row m-0 no-gutters" data-toggle="portlet">
                    <conditional-portlet-layout :condition="!isModal" portlet-class="col col-md-10 col-lg-8 col-xxl-6" div-class="col" :hideTitle="true" :allowCollapse="false">
                        <template v-slot:body>
                            <div class="container-required-documents  mb-4">
                                <div class="documents-container" id="requiredDocuments">
                                    <div class="required-document-block">
                                        <div class="document-header">
                                            <h2>{{ requiredDocumentDetails.name }}</h2>
                                            <div class="document-upload">
                                                <p v-if="requiredDocumentDetails.requiredDocumentParticipation">{{ requiredDocumentDetails.requiredDocumentParticipation.state }}</p>
                                            </div>
                                        </div>
                                        <div v-html="$sanitize(requiredDocumentDetails.text)" class="document-body rich-text-container">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RequiredDocumentsDetailsForm :dictionary="dictionary" :requiredUploadDocument="requiredUploadDocument" :inEdit="inEdit" />
                        </template>
                    </conditional-portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RequiredDocumentsDetailsForm from "./required-documents-details-form.component.vue";
    import ConditionalPortletLayout from "Shared/Components/Vue/Portlet/conditional-portlet.component.vue";
    import { get } from "api";

    export default {
        components: {
            RequiredDocumentsDetailsForm,
            ConditionalPortletLayout
        },
        props: {
            dictionary: Object,
            viewModel :Object
        },
        data() {
            return {
                requiredUploadDocument: null,
                isLoading: true
            }
        },
        computed: {
            requiredDocumentDetails() {
                return this.requiredUploadDocument.requiredDocumentDetails;
            },
            inEdit() {
                return (this.requiredDocumentDetails.canEdit && this.requiredDocumentDetails.requiredDocumentParticipation.approvalState.value == 'rdasNew')
                    || !!this.$route.query.inEdit;
            },
            isModal(){
                return this.viewModel?.isModal;
            }
        },
        methods: {
            showUploadForm() {
                let query = this.$route.query;
                this.$router.push({
                    name: "required-documents-details",
                    query: Object.assign({ ...query }, { inEdit: true }),
                    hash: this.$route.hash
                });
            }
        },
        beforeCreate() {
            let query = this.$route.query;

            get(`/RequiredDocuments/UploadDocument?documentId=${query.documentId}&documentParticipationId=${query.documentParticipationId}`,
                (response) => {
                    this.requiredUploadDocument = response.data;
                },
                this,
                () => (this.isLoading = false)
            );
        }
    };
</script>
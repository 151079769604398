import { required, requiredIf } from "vuelidate/lib/validators";
import { fileMaxSize, fileType, dateGreaterThan, dateLesserThan} from 'Shared/Components/Vue/Validations/custom-validator'

const fileMaxSizeInBytes = 1024*1024*10;
const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

export const documentsUploadValidation = {
    validations() {
        return {
            documentUploadDetails:{
                documentNumber: {
                    required:requiredIf(function () {
                        return this.requiredDocumentDetails.isPersonalIdentificationDocument;
                    }),
                    notSpecialCharacters: function(value) {
                        if(value)
                            return !(value.includes('<') || value.includes('>') || value.includes('=') || value.includes("'"));
                        return true;
                    }
                },
                documentType: {
                    required:requiredIf(function () {
                        return this.requiredDocumentDetails.isPersonalIdentificationDocument;
                    })
                },
                documentIssueDate: {
                    required:requiredIf(function () {
                        return this.requiredDocumentDetails.isPersonalIdentificationDocument;
                    }),
                    dateLesserThan: function(value) {
                        return dateLesserThan(value, this.documentUploadDetails.documentExpiryDate)
                    } 
                },
                documentExpiryDate: {
                    required:requiredIf(function () {
                        return this.requiredDocumentDetails.isPersonalIdentificationDocument;
                    }),
                    dateGreaterThan: function(value) {
                        return dateGreaterThan(value, this.documentUploadDetails.documentIssueDate);
                    }
                },
                documentCountry: {
                    required:requiredIf(function () {
                        return this.requiredDocumentDetails.isPersonalIdentificationDocument;
                    })
                },
                uploadFiles: {
                    required,
                    fileMaxSize: function (value) {
                        return fileMaxSize(value, fileMaxSizeInBytes);
                    },
                    fileType: function(value) {
                        return fileType(value, allowedFileTypes);
                    }
                }
            }
        }
    },
    computed: {
        validationLists() {
            return {
                documentNumberValidation: [
                    {
                        id: 1,
                        error: this.$v.documentUploadDetails.documentNumber.$error,
                        type: this.$v.documentUploadDetails.documentNumber.required,
                        message: this.dictionary.validation.invalidDocumentNumberError
                    },
                    {
                        id: 2,
                        error: this.$v.documentUploadDetails.documentNumber.$error,
                        type: this.$v.documentUploadDetails.documentNumber.notSpecialCharacters,
                        message: this.dictionary.validation.invalidDocumentNumberError
                    }
                ],
                documentIssueDateValidation: [
                    {
                        id: 1,
                        error: this.$v.documentUploadDetails.documentIssueDate.$error,
                        type: this.$v.documentUploadDetails.documentIssueDate.required,
                        message: this.dictionary.validation.invalidIssueDateError
                    },
                    {
                        id: 2,
                        error: this.$v.documentUploadDetails.documentIssueDate.$error,
                        type: this.$v.documentUploadDetails.documentIssueDate.dateLesserThan,
                        message: this.dictionary.validation.invalidIssueDateError
                    }
                ],
                documentExpiryDateValidation: [
                    {
                        id: 1,
                        error: this.$v.documentUploadDetails.documentExpiryDate.$error,
                        type: this.$v.documentUploadDetails.documentExpiryDate.required,
                        message: this.dictionary.validation.invalidExpiryDateError
                    },
                    {
                        id: 2,
                        error: this.$v.documentUploadDetails.documentExpiryDate.$error,
                        type: this.$v.documentUploadDetails.documentExpiryDate.dateGreaterThan,
                        message: this.dictionary.validation.invalidExpiryDateError
                    }
                ],
                uploadFilesValidation: [
                    {
                        id: 1,
                        error: this.$v.documentUploadDetails.uploadFiles.$error,
                        type: this.$v.documentUploadDetails.uploadFiles.required,
                        message: this.dictionary.validation.fileRequiredError
                    },
                    {
                        id: 2,
                        error: this.$v.documentUploadDetails.uploadFiles.$error,
                        type: this.$v.documentUploadDetails.uploadFiles.fileMaxSize,
                        message: this.dictionary.validation.fileMaxSizeError
                    },
                    {
                        id: 3,
                        error: this.$v.documentUploadDetails.uploadFiles.$error,
                        type: this.$v.documentUploadDetails.uploadFiles.fileType,
                        message: this.dictionary.validation.invalidFileTypeError
                    }
                ]
            }
        }
    }
}
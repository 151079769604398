var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentQuestion
    ? _c(
        "fieldset",
        [
          _c("legend", {
            staticClass: "rich-text-container",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.currentQuestion.questionDescription)
              )
            }
          }),
          _vm._v(" "),
          _vm.currentQuestion.questionType == _vm.$QuestionType.StandardChoices
            ? [
                _c("label", { staticClass: "question-container__label" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentQuestion.givenAnswer,
                        expression: "currentQuestion.givenAnswer"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "qqaYesAnswer",
                      value: "qqaYes"
                    },
                    domProps: {
                      checked: _vm._q(_vm.currentQuestion.givenAnswer, "qqaYes")
                    },
                    on: {
                      change: [
                        function($event) {
                          return _vm.$set(
                            _vm.currentQuestion,
                            "givenAnswer",
                            "qqaYes"
                          )
                        },
                        _vm.changeAnswer
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "question-container__text" }, [
                    _vm._v(_vm._s(_vm.dictionary.labels.yesAnswer))
                  ])
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "question-container__label" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentQuestion.givenAnswer,
                        expression: "currentQuestion.givenAnswer"
                      }
                    ],
                    attrs: { type: "radio", id: "qqaNoAnswer", value: "qqaNo" },
                    domProps: {
                      checked: _vm._q(_vm.currentQuestion.givenAnswer, "qqaNo")
                    },
                    on: {
                      change: [
                        function($event) {
                          return _vm.$set(
                            _vm.currentQuestion,
                            "givenAnswer",
                            "qqaNo"
                          )
                        },
                        _vm.changeAnswer
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "question-container__text" }, [
                    _vm._v(_vm._s(_vm.dictionary.labels.noAnswer))
                  ])
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "question-container__label" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentQuestion.givenAnswer,
                        expression: "currentQuestion.givenAnswer"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "qqaDontKnow",
                      value: "qqaDontKnow"
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.currentQuestion.givenAnswer,
                        "qqaDontKnow"
                      )
                    },
                    on: {
                      change: [
                        function($event) {
                          return _vm.$set(
                            _vm.currentQuestion,
                            "givenAnswer",
                            "qqaDontKnow"
                          )
                        },
                        _vm.changeAnswer
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "question-container__text" }, [
                    _vm._v(_vm._s(_vm.dictionary.labels.dontKnowAnswer))
                  ])
                ])
              ]
            : _vm.currentQuestion.questionType ==
              _vm.$QuestionType.DefinedChoices
            ? [
                _vm.currentQuestion.choices.length == 0
                  ? [
                      _c("alert-component", { attrs: { variant: "error" } }, [
                        _vm._v(_vm._s(_vm.dictionary.labels.noChoiceAvailable))
                      ])
                    ]
                  : _vm._l(_vm.currentQuestion.choices, function(choice) {
                      return _c(
                        "label",
                        {
                          key: choice.questionChoiceId,
                          staticClass: "question-container__label"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentQuestion.givenChoiceId,
                                expression: "currentQuestion.givenChoiceId"
                              }
                            ],
                            attrs: {
                              type: "radio",
                              name: _vm.currentQuestion.questionId,
                              id: choice.questionChoiceId
                            },
                            domProps: {
                              value: choice.questionChoiceId,
                              checked: _vm._q(
                                _vm.currentQuestion.givenChoiceId,
                                choice.questionChoiceId
                              )
                            },
                            on: {
                              change: [
                                function($event) {
                                  return _vm.$set(
                                    _vm.currentQuestion,
                                    "givenChoiceId",
                                    choice.questionChoiceId
                                  )
                                },
                                _vm.changeAnswer
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "question-container__text" },
                            [_vm._v(_vm._s(choice.choiceText))]
                          )
                        ]
                      )
                    })
              ]
            : [
                _c(
                  "div",
                  [
                    _c("textarea-element", {
                      attrs: {
                        name: "givenOpenAnswer",
                        id: "givenOpenAnswer",
                        row: 3,
                        cols: 30,
                        maxlength: 250,
                        validationsList: _vm.questionValidations(
                          _vm.questionIndex
                        )
                      },
                      model: {
                        value: _vm.currentQuestion.givenOpenAnswer,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.currentQuestion,
                            "givenOpenAnswer",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "currentQuestion.givenOpenAnswer"
                      }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "relationship-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "relationship-details-expansion-panel" },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "relationship-details-expansion-panel__header" },
                [
                  _c("h3", { staticClass: "h4" }, [
                    _vm._v(
                      " " + _vm._s(_vm.relationship.relatedBeneficiaryName)
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile" },
                [
                  _c("RelationsWithBeneficiaryContent", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      relationship: _vm.relationship
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
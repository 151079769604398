var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: { "hide-title": true, "hide-shadow": false },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { attrs: { id: "inbox-container" } }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isMobileRowClicked || !_vm.isMobileOrTablet,
                        expression: "!isMobileRowClicked || !isMobileOrTablet"
                      }
                    ],
                    staticClass: "col-md-6 col-lg-5 col-xl-4 email-list"
                  },
                  [
                    _c("EmailsTable", {
                      attrs: {
                        datatablesDictionary: _vm.datatablesDictionary,
                        dictionary: _vm.dictionary
                      },
                      on: {
                        dataRefreshed: _vm.onDataRefresh,
                        rowClicked: _vm.onRowClicked,
                        loadedTable: _vm.onLoadTable
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isMobileOrTablet ||
                (_vm.isMobileRowClicked && _vm.isMobileOrTablet)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-md-6 col-lg-7 col-xl-8 message-container",
                        attrs: { id: "message-container" }
                      },
                      [
                        _c("component-spinner", {
                          attrs: { spin: _vm.isLoading }
                        }),
                        _vm._v(" "),
                        !_vm.isLoading
                          ? _c("EmailContent", {
                              attrs: {
                                headerVisible: "",
                                isMobileOrTablet: _vm.isMobileOrTablet,
                                message: _vm.message,
                                model: _vm.model
                              },
                              on: { "back-click": _vm.backClick }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { requiredIf } from "vuelidate/lib/validators";
import { phoneCountryCodeAndSpecialCharacters, phoneValid, phoneValidLength } from 'Shared/Components/Vue/Validations/custom-validator'

export const accountEditValidation = {
    validations() {
        return {
            editModel: {
                telephone: {
                    isValidCountryCode: function (value) {
                        if (this.dataModel.beneficiaryBoundaries.mayEditTelephone) {
                            return phoneCountryCodeAndSpecialCharacters(value);
                        }
                        return true;
                    },
                    isValidLength: function (value) {
                        if (this.dataModel.beneficiaryBoundaries.mayEditTelephone) {
                            return phoneValidLength(value);
                        }
                        return true;
                    },
                    isValid: function (value) {
                        if (this.dataModel.beneficiaryBoundaries.mayEditTelephone) {
                            return phoneValid(value);
                        }
                        return true;
                    },
                    required:requiredIf(function () {
                        if (this.dataModel.beneficiaryBoundaries.mayEditTelephone) {
                            return this.dataModel.mfaEnabled;
                        }
                        return false;
                    }),
                }
            }
        }
    },
    computed: {
        validationLists() {
            return {
                mobileTelephoneValidation: [
                    {
                        id: 1,
                        error: this.$v.editModel.telephone.$error,
                        type: this.$v.editModel.telephone.isValidCountryCode,
                        message: this.dictionary.validation.mobileTelephoneInvalidPrefixOrSpecialCharacters
                    },
                    {
                        id: 2,
                        error: this.$v.editModel.telephone.$error,
                        type: this.$v.editModel.telephone.isValidLength,
                        message: this.dictionary.validation.mobileTelephoneMaxLength
                    },
                    {
                        id: 3,
                        error: this.$v.editModel.telephone.$error,
                        type: this.$v.editModel.telephone.isValid,
                        message: this.dictionary.validation.mobileTelephoneInvalidFormat
                    },
                    {
                        id: 4,
                        error: this.$v.editModel.telephone.$error,
                        type: this.$v.editModel.telephone.required,
                        message: this.dictionary.validation.mobileTelephoneRequiredWhenMfaEnabled
                    }
                ]
            }
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vestings-expanded-rows" },
    _vm._l(_vm.rows, function(row, index) {
      return _c(
        "div",
        { key: row.type + "-" + index, staticClass: "vestings-expanded-row" },
        [
          index == 0 && !_vm.isMobile
            ? _c(
                "v-card",
                { class: "vesting-" + row.type },
                [
                  _c("v-card-title", [
                    _c("h3", { staticClass: "primary-color h4" }, [
                      _vm._v(_vm._s(row.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(row.type, {
                    tag: "component",
                    staticClass: "flex-row",
                    attrs: { dictionary: _vm.dictionary, details: row.data }
                  })
                ],
                1
              )
            : _c(
                "v-expansion-panels",
                {
                  class: "vesting-" + row.type,
                  attrs: { accordion: "", multiple: "" }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "primary-color h4" }, [
                          _vm._v(_vm._s(row.title))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "flex column-details" },
                        [
                          _c(row.type, {
                            tag: "component",
                            attrs: {
                              dictionary: _vm.dictionary,
                              details: row.data
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
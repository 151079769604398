var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TransactionDetailsContentRow", {
        attrs: {
          label: _vm.dictionary.columns.transactionsTblColExecutionDate,
          value: _vm.item.executionDate
        }
      }),
      _vm._v(" "),
      _c("TransactionDetailsContentRow", {
        attrs: {
          label: _vm.dictionary.columns.transactionsTblColType,
          value: _vm.item.transactionType
        }
      }),
      _vm._v(" "),
      _c("TransactionDetailsContentRow", {
        attrs: {
          label: _vm.dictionary.columns.transactionsTblColAmount,
          value: _vm.item.amountFormatted
        }
      }),
      _vm._v(" "),
      _c("TransactionDetailsContentRow", {
        attrs: {
          label: _vm.dictionary.columns.transactionsTblColReference,
          value: _vm.item.reference
        }
      }),
      _vm._v(" "),
      !_vm.toggles.hideTransactionOriginColumn
        ? _c("TransactionDetailsContentRow", {
            attrs: {
              label: _vm.dictionary.columns.transactionsTblColOrigin,
              value: _vm.item.origin
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("TransactionDetailsContentRow", {
        attrs: {
          label: _vm.dictionary.columns.transactionsTblColComment,
          value: _vm.item.comment
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
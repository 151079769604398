var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("LeaversPromptModal", {
        attrs: {
          dictionary: _vm.dictionary,
          isModalVisible: _vm.isModalVisible
        },
        on: {
          "leavers-prompt-close-modal": function($event) {
            return _vm.closeModal()
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="mfa-container">
        <div v-if="dictionary && mfaBoundariesDataModel">
            <template v-if="getCurrentFactorType=='mftTOTP'">
                <MfaMsauthenticator :currentFactorType="getCurrentFactorType" :dictionary="dictionary"
                :mfa-boundaries-data-model="mfaBoundariesDataModel"
                :mfa-enable-disable-data-model="mfaEnableDisableDataModel"
                :mfaData="mfaData"
                @disable-close-button="$emit('disable-close-button')"
                @mfa-success-login="$emit('mfa-success-login')"
                :showFirstScreen="showFirstScreen" :isVisible="isVisible" :error="error"
                @show-ms-authenticator-code="getCodeState"
                :skipConfirmation="skipConfirmation"
                v-on="$listeners" @in-loading="inLoading" />
            </template>
            <template v-else>
                <MfaPac :pac="pac" :isVisible="isVisible" :mfaData="mfaData" :dictionary="dictionary" :message="message" :phone="phone" :skipConfirmation="skipConfirmation" :error="error" v-on="$listeners" @in-loading="inLoading" @update-mfa-data="updateMfaData"  @disable-close-button="$emit('disable-close-button')" />
            </template>
        </div>
        <loading-spinner :active="isLoading"></loading-spinner>
    </div>
</template>

<style>
    @import './mfa.scss';
</style>

<script>
    import { get } from "api";
    import MfaPac from "./pac/mfa-pac.component.vue";
    import { EventBus } from "Shared/event-bus";
    import MfaMsauthenticator from "./msauthenticator/mfa-msauthenticator.component.vue"

    export default {
        components: {
            MfaMsauthenticator,
            MfaPac
        },
        props: {
            message: String,
            isVisible: Boolean,
            phone: String,
            skipConfirmation: Boolean,
            showFirstScreen:Boolean,
            mfaBoundariesDataModel:Object,
            mfaEnableDisableDataModel:Object,
            currentFactorType:String
        },
        watch: {
            isVisible: function (newVal, oldVal) {
                if (newVal) {
                    this.mfaData = null;
                    this.pac = '';
                    this.error = '';
                }
            },
            skipConfirmation: function (newVal, oldVal) {
                if(newVal) {
                    this.getCodeState();
                }
            },
            mfaEnableDisableDataModel: function (newVal) {
                if (newVal) {
                    this.getCodeState();
                }
            },
            mfaBoundariesDataModel: function (newVal) {
                if (newVal && this.getCurrentFactorType == 'mftSMS') {
                    this.getCodeState();
                }
            }
        },
        data() {
            return {
                dictionary: {},
                pac: '',
                isLoading: true,
                mfaData: null,
                error: ''
            };
        },
        beforeCreate() {
            get('/Mfa/Dictionary',
                (response) => {
                    this.dictionary = response.data;
                },
                this,
                () => {
                    this.inLoading(false);
                });
        },
        computed:{
            getCurrentFactorType() {
                if (!this.currentFactorType){
                    if (this.mfaBoundariesDataModel)
                        return this.mfaBoundariesDataModel.currentFactorType;
                }

                return this.currentFactorType;
            },
            hasFactorDefined() {
                return ((this.mfaEnableDisableDataModel && this.mfaEnableDisableDataModel.factor) || (this.mfaData && this.mfaData.factor));
            },
            mfaStateUrl() {
                if (this.mfaData) {
                    return `/Mfa/Mfa?identifier=${encodeURIComponent(this.mfaData.identifier)}`;
                }
                else if (this.mfaEnableDisableDataModel && this.mfaEnableDisableDataModel.identifier) {
                    return `/Mfa/Mfa?identifier=${encodeURIComponent(this.mfaEnableDisableDataModel.identifier)}`;
                }
                else {
                    return `/Mfa/Mfa?identifier`;
                }
            }
        },
        mounted() {
            EventBus.$on("event-language-changed", () =>  {
                this.loadDictionaries();
            });

            if(this.skipConfirmation){
                this.getCodeState();
            }
        },
        methods: {
            getCodeState() {
                if ((!this.mfaEnableDisableDataModel && this.getCurrentFactorType == 'mftTOTP') || this.getCurrentFactorType == 'mftSMS') {
                    this.inLoading(true);

                    get(this.mfaStateUrl,
                        (response) => {

                            if (response.data.isSuccess) {
                                this.mfaData = response.data;
                            }

                            if (response.data.errorMessage) {
                                this.error = response.data.errorMessage;
                                this.mfaData = response.data;
                                
                                if(!this.mfaData || !this.mfaData.factor)
                                {
                                    this.$emit("mfa-get-state-error",this.error);
                                }
                            }
                        },
                        this,
                        () => {
                            this.inLoading(false);
                    });
                }
            },
            inLoading(isLoading) {
                this.isLoading = isLoading;

                this.$emit("in-loading", isLoading);
            },
            updateMfaData(mfaData) {
                this.mfaData = mfaData;
                this.pac = '';
            },
            loadDictionaries(){
                this.inLoading(true);

                 get('/Mfa/Dictionary',
                    (response) => {
                        this.dictionary = response.data;
                    },
                    this,
                    () => {
                        this.inLoading(false);
                    });
            },
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _vm.dictionary
        ? _c("div", [
            _c("div", { staticClass: "title-block single-line" }, [
              _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.changePassword))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "portlets-wrapper" }, [
              _c(
                "div",
                { staticClass: "row m-0" },
                [
                  _c(
                    "portlet-layout",
                    {
                      attrs: {
                        "column-class": "col-xl-6",
                        title: _vm.dictionary.widgets.changePassword
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _vm.error
                            ? _c(
                                "alert-component",
                                { attrs: { variant: "error" } },
                                [_vm._v(_vm._s(_vm.error))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loading-spinner", {
                            attrs: { active: _vm.isSubmitting }
                          }),
                          _vm._v(" "),
                          _c("input-element", {
                            attrs: {
                              type: "text",
                              label: _vm.dictionary.labels.userName,
                              "is-mandatory": false,
                              name: "userName",
                              disabled: true
                            },
                            model: {
                              value: _vm.changePasswordBoundaries.userName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.changePasswordBoundaries,
                                  "userName",
                                  $$v
                                )
                              },
                              expression: "changePasswordBoundaries.userName"
                            }
                          }),
                          _vm._v(" "),
                          _c("ChangePasswordInputs", {
                            attrs: {
                              dictionary: _vm.dictionary,
                              model: _vm.model,
                              boundaries: _vm.changePasswordBoundaries
                            },
                            on: {
                              back: _vm.backToBeneficiaries,
                              submit: _vm.submit
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "buttons",
                                  fn: function(ref) {
                                    var back = ref.back
                                    var submit = ref.submit
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group button-container d-md-flex justify-content-md-end"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn secondaryButton mb-0",
                                              on: { click: back }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.commonDictionary
                                                      .btnGoBack
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn primaryButton",
                                              on: { click: submit }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.commonDictionary.btnSubmit
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              519150434
                            )
                          })
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "portlet-layout",
                    {
                      attrs: {
                        "column-class": "col-xl-6",
                        title: _vm.dictionary.widgets.passwordValidationNote
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _c("PasswordValidationNote", {
                            attrs: {
                              dictionary: _vm.dictionary.labels,
                              boundaries: _vm.changePasswordBoundaries
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("component-spinner", { attrs: { spin: _vm.loadingAccountData } }),
      _vm._v(" "),
      !_vm.loadingAccountData
        ? [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "portlet-next-row" },
                  [
                    _c("BeneficiaryAccountPortlet", {
                      attrs: {
                        "account-dictionary": _vm.accountDictionary,
                        "common-dictionary": _vm.commonDictionary,
                        "account-data-model": _vm.accountDataModel,
                        "is-account-edited": _vm.isAccountEdited,
                        "account-data-edit-model": _vm.accountDataEditModel
                      },
                      on: {
                        "edit-account": _vm.editAccount,
                        "account-edit-form-cancelled":
                          _vm.accountEditFormCancelled,
                        "reload-page": _vm.reloadPage
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "portlet-next-row" },
                  [
                    _c("BeneficiaryMfaPortlet", {
                      attrs: {
                        "account-dictionary": _vm.accountDictionary,
                        "account-data-model": _vm.accountDataModel,
                        "mfa-boundaries-data-model": _vm.mfaBoundariesDataModel
                      },
                      on: {
                        "reload-page": _vm.reloadPage,
                        "edit-mfa": function(value) {
                          return _vm.$emit("edit-mfa", value)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "portlet-next-row" },
                  [
                    _c("BeneficiaryAddressPortlet", {
                      attrs: {
                        "account-dictionary": _vm.accountDictionary,
                        "common-dictionary": _vm.commonDictionary,
                        "address-data-model": _vm.addressDataModel,
                        "is-address-edited": _vm.isAddressEdited,
                        "address-data-edit-model": _vm.addressDataEditModel,
                        "beneficiary-boundaries-data-model":
                          _vm.beneficiaryBoundariesDataModel
                      },
                      on: {
                        "edit-address": _vm.editAddress,
                        "address-edit-form-cancelled":
                          _vm.addressEditFormCancelled,
                        "reload-page": _vm.reloadPage
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isBeneficiaryCompanyNull ||
              !_vm.isAllViewPropertiesOfPersonalInfoFalse
                ? _c("div", { staticClass: "col-md-6 col-lg-4" }, [
                    _c(
                      "div",
                      { staticClass: "portlet-next-row" },
                      [
                        _c("BeneficiaryCompanyPortlet", {
                          attrs: {
                            "account-dictionary": _vm.accountDictionary,
                            "account-data-model": _vm.accountDataModel,
                            "beneficiary-boundaries": _vm.beneficiaryBoundaries
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "portlet-next-row" },
                      [
                        !_vm.isAllViewPropertiesOfPersonalInfoFalse
                          ? _c("BeneficiaryPersonalPortlet", {
                              attrs: {
                                "account-dictionary": _vm.accountDictionary,
                                "account-data-model": _vm.accountDataModel,
                                "beneficiary-boundaries-personal-info":
                                  _vm.beneficiaryBoundariesPersonalInfo
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "portlet-next-row" },
                  [
                    !_vm.toggles.hideSpousalBlock &&
                    !!_vm.accountDataModel.spouseData &&
                    !_vm.isAllViewPropertiesOfSpouseInfoFalse
                      ? _c("BeneficiarySpousalPortlet", {
                          attrs: {
                            "account-dictionary": _vm.accountDictionary,
                            "account-data-model": _vm.accountDataModel,
                            "beneficiary-boundaries-spouse-info":
                              _vm.beneficiaryBoundariesSpouseInfo
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                !!_vm.accountDataModel.legalNationality
                  ? _c(
                      "div",
                      { staticClass: "portlet-next-row" },
                      [
                        _c("BeneficiaryNationalityPortlet", {
                          attrs: {
                            "account-dictionary": _vm.accountDictionary,
                            "account-data-model": _vm.accountDataModel
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !!_vm.accountDataModel.fiscalData
                  ? _c(
                      "div",
                      { staticClass: "portlet-next-row" },
                      [
                        _c("BeneficiaryFiscalPortlet", {
                          attrs: {
                            "account-dictionary": _vm.accountDictionary,
                            "account-data-model": _vm.accountDataModel
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vesting-modification-content vesting-expanded-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.date,
                value: _vm.details.effectiveDate
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.type,
                value: _vm.details.vestingType
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.amount,
                value: _vm.details.baseAmount
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.percentage,
                value: _vm.details.modificationPercentage + "%"
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.modification,
                value: _vm.details.modificationAmount
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.modifications.total,
                value: _vm.details.total
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <p>
        <span class="primary-label">
            <template v-if="hasIcon">
                <i class="far fa-tag" aria-hidden="true"></i>
            </template>        
            {{label}}:
        </span>
        <span>{{value}}</span>
    </p>
</template>

<script>
    export default {
        props: {
            label: String,
            value: Number | String,
            hasIcon: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
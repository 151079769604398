<template>
<div class="payment-details-content">
    <PaymentDataLabelRow :label="dictionary.table.reference" :value="payment.reference" />
    <PaymentDataLabelRow :label="dictionary.table.instructionType" :value="payment.type" />
    <PaymentDataLabelRow :label="dictionary.table.processingDate" :value="replaceNullOrEmpty(payment.processingDateTime) | toDate" />    
    <PaymentDataLabelRow :label="dictionary.table.description" :value="replaceNullOrEmpty(payment.description)" />
    <PaymentDataLabelRow :label="dictionary.table.confirmedAmount" :value="replaceNullOrEmpty(payment.confirmedAmountFormatted)" />
    <PaymentDataLabelRow :label="dictionary.table.unconfirmedAmount" :value="replaceNullOrEmpty(payment.unconfirmedAmountFormatted)" />
    <PaymentDataLabelRow v-if="!toggles.hideOrderState" :label="dictionary.table.state" :value="payment.state" />
    <PaymentDataLabelRow :label="dictionary.table.accountNumber" :value="replaceNullOrEmpty(payment.debitAccountNumber)" />
    <PaymentDataLabelRow :label="dictionary.table.bankName" :value="replaceNullOrEmpty(payment.debitAccountBankName)" /> 
    <PaymentDataLabelRow :label="dictionary.table.bankCountry" :value="replaceNullOrEmpty(payment.debitAccountBankCountryName)" />
    <PaymentDataLabelRow :label="dictionary.table.bic" :value="replaceNullOrEmpty(payment.debitAccountBIC)" />
</div>
</template>

<script>
import PaymentDataLabelRow from "./payment-data-label-row.component.vue";
export default {
  components: {
    PaymentDataLabelRow
  },
  props: {
    payment: Object,
    dictionary: Object,
    toggles: Object
  }
};
</script>
<template>
    <div v-if="!isLoading" class="body-container">
        <div class="body-header-container">
            <HeaderContainer :header="layout.header" :skin="layout.skin" :isImpersonated="isImpersonated" :isAuthenticated="isAuthenticated" :isDisclaimer="isDisclaimer" :logoutEndpoint="layout.logoutEndpoint" :isPasswordExpired="isPasswordExpired" />
        </div>
        <div :class="['body-row-container', { 'left-sidebar' : showSidebar }]">
            <div class="body-sidebar-container">
                <Sidebar v-if="showSidebar" :dictionary="layout.sidebar.dictionary" :toggles="layout.sidebar.toggles" :features="layout.features" :is-loading="isLoading" :logout-endpoint="layout.logoutEndpoint"></Sidebar>
            </div>
            <div class="body-content-container">
                <div v-if="(!isAuthenticated && !isDisclaimer) || (isAuthenticated && user.isPasswordExpired)" id="container-login" class="login-container">
                    <router-view :isAuthenticated="isAuthenticated"></router-view>
                </div>
                <div v-else-if="isDisclaimer" ref="accessibilityMainFocus" id="container-modal" class="disclaimer-container">
                    <BodyModalContainer :layout="layout" :isAuthenticated="isAuthenticated" />
                </div>
                <div v-else class="content-container">
                    <main id="mainContent" class="section-container" ref="accessibilityMainFocus">
                        <div class="container-fluid content-wrapper">
                            <router-view :isAuthenticated="isAuthenticated" :pagesDictionary="layout.pagesDictionary" :skin="layout.skin" :key="getMainRouteKey($route)"></router-view>
                        </div>
                    </main>
                    <LeaversPromptContainer :toggles="layout.body.toggles" @modal-closed="accessibilityMainContentFocus()"/>
                </div>
                <FooterContainer v-if="!isDisclaimer" :model="layout.footer" :logo-desktop="layout.skin.images.footerLogo" :logo-mobile="layout.skin.images.footerLogoMobile" :is-default-footer="isDefaultFooter" />
                <InactivityPrompt v-if="isAuthenticated"></InactivityPrompt>
                <CookiePolicyModal :user="user" :toggles="layout.body.toggles"></CookiePolicyModal>
                <LoadingSr :accessibilityDictionary="layout.accessibilityDictionary"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { get } from "api";
    import { EventBus } from "Shared/event-bus";
    import Sidebar from 'Shared/Layout/Sidebar/Vue/sidebar.component.vue';
    import BodyModalContainer from './body-modal-container.component.vue';
    import FooterContainer from 'Shared/Footer/Vue/footer-container.component.vue';
    import HeaderContainer from 'Shared/Layout/Header/Vue/header-container.component.vue';
    import InactivityPrompt from 'Shared/SessionHeart/Vue/inactivity-prompt.component.vue';
    import numeral from 'numeral';
    import 'numeral/locales';
    import { skinMethods } from "./mixins/skin-methods.mixin.js";
    import CookiePolicyModal from 'Features/CookiePolicy/Assets/Vue/cookie-policy-modal.component.vue';
    import LeaversPromptContainer from 'Features/LeaversPrompt/Assets/Vue/leavers-prompt-container.component.vue';
    import LoadingSr from "../../../Components/Vue/LoadingSpinner/loading-sr.component.vue";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";

    export default {
        mixins: [skinMethods, accessibility],
        components: {
            Sidebar,
            BodyModalContainer,
            FooterContainer,
            HeaderContainer,
            InactivityPrompt,
            CookiePolicyModal,
            LeaversPromptContainer,
            LoadingSr
        },
        data() {
            return {
                layout: null,
                user: null,
                isLoading: true,
                disclaimerPaths: [
                    '/disclaimer',
                    '/cookiePolicy'
                ],
                currentPath: this.$route?.path
            }
        },
        watch: {
            $route(to, from) {
                this.currentPath = to.path;
            },
        },
        created() {
            this.reloadLayout();
            this.$router.beforeEach((to, from, next) => {
                if (to.meta.bodyModal && !this.isDisclaimer) {
                    window.location.replace("/");
                    return next(false);
                }

                if (to.path.toLowerCase() == '/login') {
                    this.reloadLayout();

                    if (this.isAuthenticated) {
                        return next(this.firstAvailableFeaturePath('dashboard'));
                    }
                }

                var requiredRedirection = this.checkRequiredRedirections(this.user, to);

                if (requiredRedirection && !to.path.startsWith(requiredRedirection)) {
                    return next(requiredRedirection);
                } else { next(); }
            });

            this.$router.afterEach((to, from, failure) => {
                if (this.isShowKeysInQuery(from.query) && !this.isShowKeysInQuery(to.query)) {
                    this.reloadLayout();
                }

                this.$bvToast.hide();
                this.accessibilityMainContentFocus();
            });
        },
        mounted() {
            EventBus.$on("event-language-changed", () => {
                this.reloadLayout('', false);
            });

            EventBus.$on("event-reload-layout", (path) => {
                this.reloadLayout(path);
            });
        },
        methods: {
            reloadLayout(path, withUser = true) {
                get('/Layout/Layout', (response) => {
                    this.layout = response.data;
                    if (withUser) { 
                        if (!!this.layout.user && this.layout.user.mfaRequired) {
                            this.user = null;
                        }
                        else {
                            this.user = this.layout.user;
                        }
                    }

                    window.culture = this.layout.culture;
                    numeral.locale(window.culture.decimalSeparator == "," ? 'nl-nl' : 'en-gb');

                    this.updateSkin(this.layout.skin);

                    this.isLoading = false;

                    if(this.$session.get(this.$SessionKeys.ForcedLogout)) {
                        this.showForcedLogoutToast();
                        this.$session.remove(this.$SessionKeys.ForcedLogout)
                    }

                    if (path) {
                        this.redirectToPath(path);
                    } else if (this.$route?.meta.bodyModal && !this.isDisclaimer) {
                        window.location.replace("/");
                    }
                    
                    if(this.isImpersonated && this.currentPath.startsWith('/login') ) {
                        this.redirectToPath('/');
                    }
                }, this);
            },
            checkRequiredRedirections(user, to) {
                if (!this.isAuthenticated) {
                    return null;
                }

                if (this.disclaimerPaths.includes(to.path)) {
                    return null;
                }

                if (user.isPasswordExpired) {
                    return '/login/passwordExpired';
                } else if (user.shouldSignDisclaimers) {
                    return '/loginDisclaimers';
                } else if (user.hasRequiredDocuments) {
                    return '/requiredDocuments';
                } else if (user.miFidInfoRequired) {
                    return '/requiredInfo/mifid';
                } else if (user.crsInfoRequired) {
                    return '/requiredInfo/crs';
                } else if (user.hasMandatoryQuestionaires) {
                    return '/questionnaire';
                }
                return null;
            },
            isShowKeysInQuery(query) {
                return (query?.showkeys || query?.showKeys);
            },
            firstAvailableFeaturePath(path) {
                if (path === '/') {
                    path = 'dashboard'
                }

                let feature = this.layout?.features.find(f => f.pathName === path.replace('/', ''));
                if (feature && feature.isDisabled && this.layout.defaultFeature) {
                    return "/" + this.layout.defaultFeature.pathName;
                }
                return path;
            },
            redirectToPath(path) {
                this.$router.push({ path: this.firstAvailableFeaturePath(path) }).catch((e) => e);
            },
            getMainRouteKey(route) {
                return route.fullPath.replace(route.hash, '');
            },
            showForcedLogoutToast() {
                this.$bvToast.toast(
                        this.layout.toastDictionary.sessionErrorText,
                            {
                                title: this.layout.toastDictionary.sessionErrorTitle,
                                solid: true,
                                variant: 'warning',
                                noAutoHide: true
                            });
            }
        },
        computed: {
            isDisclaimer() {
                return this.disclaimerPaths.includes(this.currentPath) || (this.user &&
                    (this.user.hasRequiredDocuments || this.user.hasMandatoryQuestionaires || this.user.shouldSignDisclaimers || this.user.crsInfoRequired || this.user.miFidInfoRequired));
            },
            isAuthenticated() {
                return !!this.user;
            },
            showSidebar() {
                return this.isAuthenticated && !this.isDisclaimer && !this.isPasswordExpired;
            },
            isDefaultFooter() {
                return this.isAuthenticated && !this.isDisclaimer && !this.isPasswordExpired;
            },
            isPasswordExpired() {
                return this.isAuthenticated && this.user.isPasswordExpired;
            },
            isImpersonated() {
                return this.isAuthenticated && this.user.isImpersonated;
            }
        }
    };
</script>

<template>
    <required-info-step v-if="requiredInfoModel"
                        id="accountholder-container"
                        :currentStep="currentStep"
                        :step="step"
                        :steps="steps"
                        :summaryType="summaryType"
                        :heading="dictionary.labels.step3"
                        :description="dictionary.labels.step3Description"
                        :dictionary="dictionary"
                        @edit-step="(val) => $emit('edit-step', val)">
        <template v-slot:previous>
            <div class="row">
                <RequiredInfoDataLabelRow class="col-md-6" :value="requiredInfoModel.fiscalNumber" :label="dictionary.labels.ssnFiscalNumber" :isMandatory="true" />
            </div>
        </template>
        <template v-slot:current>
            <div class="row mb-2">
                <div class="col-12 col-md-6">
                    <input-element type="text"
                                   name="fiscalNumber"
                                   :isMandatory="true"
                                   :readonly="!boundaries.mayEditFiscalNumber"
                                   :label="dictionary.labels.ssnFiscalNumber"
                                   v-model="requiredInfoData.fiscalNumber"
                                   :validationsList="fiscalValidations.fiscalNumber"></input-element>
                </div>
            </div>
            <div class="d-md-flex justify-content-md-between button-container">
                <div>
                    <button class="btn secondaryButton"
                            type="button"
                            @click="previousStep()"
                            :aria-describedby="previousStepAccessibilityLabel(step)">
                        {{ dictionary.buttons.previous }}
                    </button>
                    <button
                            class="btn primaryButton"
                            type="button"
                            @click="nextStep()"
                            :aria-describedby="nextStepAccessibilityLabel(step, steps)">
                        {{ dictionary.buttons.next }}
                    </button>
                </div>
            </div>
        </template>
    </required-info-step>
</template>

<script>
    import { required, requiredIf } from "vuelidate/lib/validators";
    import RequiredInfoDataLabelRow from "./required-info-data-label-row.component.vue";
    import RequiredInfoStep from "./required-info-step.component.vue";
    import { EventBus } from "Shared/event-bus";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";
    import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

    export default {
        mixins: [accessibility, stepperAccessibilityPreviousNextLabel],
        components: {
            RequiredInfoDataLabelRow,
            RequiredInfoStep
        },
        props: {
            dictionary: Object,
            currentStep: Number,
            step: Number,
            steps: Number,
            summaryType: String,
            requiredInfoData: Object,
            boundaries: Object
        },
        validations() {
            return {
                requiredInfoModel: {
                    fiscalNumber: {
                        required: requiredIf(function () {
                            return this.boundaries.mayEditFiscalNumber;
                        })
                    }
                }
            };
        },
        computed: {
            requiredInfoModel() {
                return this.requiredInfoData;
            },
            fiscalValidations() {
                return {
                    fiscalNumber: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.fiscalNumber,
                            error: this.$v.requiredInfoModel.fiscalNumber.$error,
                            type: this.$v.requiredInfoModel.fiscalNumber.required,
                            message: this.dictionary.validation.ssnFiscalNumberInvalid
                        }
                    ]
                };
            }
        },
        methods: {
            nextStep() {
                EventBus.$emit("v-touch");
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return this.focusOnFirstInvalidInput();
                }
                this.$emit("next-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            },
            previousStep() {
                this.$emit("previous-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "localhr-container" }, [
    _c("div", { staticClass: "localhr__icon" }, [
      _c("i", {
        staticClass: "far fa-glasses fa-2x",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "sr-only" }, [
        _vm._v(_vm._s(_vm.dictionary.impersonationMode))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "localhr__text" }, [
      _c("span", [_vm._v(_vm._s(_vm.dictionary.impersonationMode))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
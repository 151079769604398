var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-confirmation" },
    [
      _c(
        "div",
        { staticClass: "mfa-confirmation-body" },
        [
          _c("p", {
            staticClass: "mb-2 rich-text-container",
            domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.message)) }
          }),
          _vm._v(" "),
          _vm.error
            ? _c("alert-component", { attrs: { variant: "error" } }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _c("label", { staticClass: "form-input-label" }, [
              _vm._v(_vm._s(_vm.dictionary.labelMfaPromptMobileNumber) + ":")
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.phone))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("MfaButtons", {
        attrs: { dictionary: _vm.dictionary },
        on: {
          cancel: function($event) {
            return _vm.cancel()
          },
          submit: function($event) {
            return _vm.submit()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <section>
        <modal-basic :title="dictionary.widget.labelHeaderTitle" :is-on="isModalVisible" :ok-footer="false" :centered="true" @close="$emit('close')">
            <SubscriptionMobileRow :label="dictionary.table.moduleName" :value="replaceNullOrEmpty(model.name)" />
            <SubscriptionMobileRow v-if="model.state" :label="dictionary.table.state" :value="replaceNullOrEmpty(model.state.description)" />
            <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(model.securityName)" />
            <SubscriptionMobileRow v-if="!toggles.hideEmissionsGrantedColumn" :label="dictionary.table.granted" :value="replaceNullOrEmpty(model.granted)" />
            <SubscriptionMobileRow :label="dictionary.table.grantedDate" :value="model.grantedDate | toDate" />
            <SubscriptionMobileRow :label="dictionary.table.startDate" :value="model.subscriptionDate | toDate" />
            <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(model.securityName)" />
            <SubscriptionMobileRow :label="dictionary.table.subscribed" :value="replaceNullOrEmpty(model.subscribed)" />
            <SubscriptionMobileRow v-if="!!model.feesPrice" :label="dictionary.table.feesPrice" :value="replaceNullOrEmpty(model.feesPrice)" />
            <SubscriptionMobileRow v-if="!!model.expectedContributionAmountFormatted" :label="dictionary.table.expectedContributionAmount" :value="replaceNullOrEmpty(model.expectedContributionAmountFormatted)" />
            <SubscriptionMobileRow v-if="!!model.contributionAmountFormatted" :label="dictionary.table.contributionAmount" :value="replaceNullOrEmpty(model.contributionAmountFormatted)" />
            <SubscriptionMobileRow v-if="!!model.contributionDate" :label="dictionary.table.contributionDate" :value="model.contributionDate | toDate" />
            <button class="btn btn-secondary float-right mt-3" @click="$emit('close')">{{ dictionary.widget.btnOk }}</button>
        </modal-basic>
    </section>
</template>

<script>

    import SubscriptionMobileRow from "/Features/Subscriptions/Assets/Vue/Shared/subscription-mobile-row.component.vue";

    export default {
        components: {
            SubscriptionMobileRow
        },
        props: {
            dictionary: Object,
            toggles: Object,
            model: Object,
            isModalVisible: Boolean
        }
    }
</script>
<template>
    <b-tabs content-class="mt-0" class="tab-container" ref="tabs">
        <portlet-tab :tabTitle="dictionaryDocuments.tabs.plan" tabId="tabPlanDocuments">
            <div class="page-description rich-text-container" v-html="$sanitize(dictionaryDocuments.page.documentsPageDescriptionPlan)"></div>
            <TablePlanDocuments :dictionary="dictionaryDocuments" :plans="plans"/>
        </portlet-tab>
        <portlet-tab v-if="!toggles.tabHidePersonalDocs" :tabTitle="dictionaryDocuments.tabs.personal" tabId="tabPersonalDocuments">
            <div class="page-description rich-text-container" v-html="$sanitize(dictionaryDocuments.page.documentsPageDescriptionPersonal)"></div>
            <TablePersonalDocuments :dictionary="dictionaryPersonalDocuments"/>
        </portlet-tab>       
    </b-tabs>    
</template>

<script>
import TablePlanDocuments from "./table-plan-documents.component.vue";
import TablePersonalDocuments from "./table-personal-documents.component.vue";


export default {
  components: {
    TablePlanDocuments,
    TablePersonalDocuments
  },  
  props: {
    dictionaryDocuments: Object,
    dictionaryPersonalDocuments: Object,
    toggles: Object,
    plans: Array,
  },
  data() {
    return {
      tabs: ['#plan', '#personal']
    }
  },
  mounted() {
    this.$refs.tabs.currentTab = this.tabs.findIndex(tab => tab === this.$route.hash)
  }
};
</script>
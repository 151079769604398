<template>
    <div class="mt-3 button-container d-md-flex justify-content-end">
        <div>
            <button class="btn secondaryButton" type="button" @click="$emit('cancel')">{{cancelButtonLabel}}</button>
        </div>
        <div>
            <button class="btn primaryButton" type="button" @click="$emit('subscribe')">{{subscribeButtonLabel}}</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cancelButtonLabel: String,
        subscribeButtonLabel: String
    }
}
</script>
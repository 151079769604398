import numeral from 'numeral'
import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js'

export const mustBeDecimal = (value) => !value || (!isNaN(numeral(value).value()) && regexValidator(value));

export const mustBeModulo = (value, param) =>
    !value || !param || !mustBeDecimal(value) || (numeral(value).value() ? isPositiveInteger(numeral(value).divide(param).value()) : false);

export const mustBeMinimum = (value, param) =>
    (value && param && mustBeDecimal(value) ? numeral(value).value() >= param : true);

export const mustBeMaximum = (value, param) =>
    (value && param && mustBeDecimal(value) ? numeral(value).value() <= param : true);

export const mustBeLesserThan = (value, compareWith) => (!isNaN(numeral(value).value())) ? value <= compareWith : false;

export const mustBeGreatherThan = (value, compareWith) => (!isNaN(numeral(value).value())) ? value > compareWith : false;

export const dateGreaterThan = (value, compareWith) => {
    if (value && compareWith) {
        return new Date(value) > new Date(compareWith);
    }

    return true;
}

export const dateLesserThan = (value, compareWith) => {
    if (value && compareWith) {
        return new Date(value) < new Date(compareWith);
    }

    return true;
}

export const between = (value, minimum, maximum) =>
    (!isNaN(numeral(value).value()) && !isNaN(numeral(minimum).value()) && !isNaN(numeral(maximum).value()) ? numeral(value).value() >= minimum && numeral(value).value() <= maximum : true);

export const fileMaxSize = (value, param) => {
    if (value) {
        for (var i = 0; i < value.length; i++) {
            if (value[i].size > param) {
                return false
            }
        }
    }

    return true;
}

export const fileType = (value, param) => {
    if (value) {
        for (var i = 0; i < value.length; i++) {
            if (!param.includes(value[i].type)) {
                return false
            }
        }
    }

    return true;
}

export const phoneCountryCodeAndSpecialCharacters = (value) => {
    return !value || (!(validatePhoneNumberLength(value) === 'INVALID_COUNTRY') && /^[+][0-9]*$/.test(value))
}

export const phoneValid = (value) => {
    return !value || isValidPhoneNumber(value);
}

export const phoneValidLength = (value) => {
    return !value || !(validatePhoneNumberLength(value) === 'TOO_LONG');
}

export const phoneValidWithPrefixAndLenght = (value) => {
    return phoneCountryCodeAndSpecialCharacters(value) && phoneValid(value) && phoneValidLength(value);
}

export const correctDecimalSeparator = (value) => {
    if (value == 0) return true;

    if(value.toString().includes(".") || value.toString().includes(","))
    {
        var separators = numeral.localeData().delimiters;

        if (separators.decimal == ',') {
            return !(/^(?!0+\.0{*})(?=.{1,}(\.|$))(?!0(?!\.))\d{1,}(,\d{*})*(\.\d+)?$/.test(value))
        }
        else {
            return !(/^(?!0+\,0{*})(?=.{1,}(\.|$))(?!0(?!\,))\d{1,}(.\d{*})*(\,\d+)?$/.test(value))
        }
    }

    return true;
}

const regexValidator = (value) => {
    if (value == 0) return true;
    var separators = numeral.localeData().delimiters;
    if (separators.decimal == ',') {
        return /^(?!0+\,0{*})(?=.{1,}(\.|$))(?!0(?!\,))\d{1,}(.\d{3})*(\,\d+)?$/.test(value)
    }
    else {
        return /^(?!0+\.0{*})(?=.{1,}(\.|$))(?!0(?!\.))\d{1,}(,\d{3})*(\.\d+)?$/.test(value)
    }
}

const isPositiveInteger = (value) => Number.isInteger(value) && value > 0;

export const containsUppercase = (value) => { return /[A-Z]/.test(value); }
export const containsLowercase = (value) => { return /[a-z]/.test(value); }
export const containsNumber = (value) => { return /[0-9]/.test(value); }
export const containsSpecial = (value) => { return /[^a-zA-Z0-9]/.test(value); }
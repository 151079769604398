<template>
<div class="portfolio-mobile-details-content">
    <PortfolioDataLabelRow v-if="showVestingDateColumnForControlTags" :label="dictionary.table.vestingDate" :value="position.lastVestingDate" /> 
    <PortfolioDataLabelRow v-if="showLastDayExercisePeriodColumnForControlTags" :label="dictionary.table.lastDayExPeriod" :value="position.expiryDate" />
    <template  v-if="anyVestings && showVestingsColumn">
      <PortfolioDataLabelRow :label="dictionary.table.granted" :value="position.grantedFormatted" />
      <PortfolioDataLabelRow v-if="toggles.showAcceptedAmount" :label="dictionary.table.accepted" :value="position.acceptedFormatted" />
      <PortfolioDataLabelRow :label="dictionary.table.vested" :value="position.vestedFormatted" />
      <PortfolioDataLabelRow :label="dictionary.table.unvested" :value="position.unvestedFormatted" />
    </template>
    <PortfolioDataLabelRow v-if="showBalanceColumn" :label="dictionary.table.balance" :value="position.balanceFormatted" />
    <PortfolioDataLabelRow v-if="showAvailableColumn" :label="dictionary.table.available" :value="position.availableFormatted" />    
    <PortfolioDataLabelRow v-if="showUnvestedColumn" :label="dictionary.table.unvested" :value="position.unvestedFormatted" />
    <PortfolioDataLabelRow v-if="showUnvestedValueColumn" :label="dictionary.table.unvestedValue" :value="position.unvestedValueFormatted" />
    <PortfolioDataLabelRow v-if="showLastPriceColumn && position.lastSecurityPriceFormatted" :label="dictionary.table.lastPrice" :value="position.lastSecurityPriceFormatted" />
    <PortfolioDataLabelRow v-if="showDerivativeSecurityPriceColumn && derivativeSecurity" :label="dictionary.table.underlyingSharePrice" :value="lastDerivativeSecurityPrice" />

    <PortfolioDataLabelRowWithTooltip v-if="showReferencePriceColumn && position.lastVestingReferencePriceFormatted" 
        :label="this.dictionary.table.referencePrice" 
        :value="position.lastVestingReferencePriceFormatted" 
        :tooltipText="dictionary.block.portfolioReferencePriceColumnTooltip" 
        :tooltipShow="!toggles.hideReferencePriceTooltipColumn" />

    <PortfolioDataLabelRow v-if="showExercisePriceColumn" :label="this.dictionary.table.exercisePrice" :value="position.strikePriceFormatted" />
    <PortfolioDataLabelRow v-if="showVestingDateColumn" :label="this.dictionary.table.vestingDate" :value="position.lastVestingDate" />
    <PortfolioDataLabelRow v-if="showLastDayExercisePeriodColumn" :label="this.dictionary.table.lastDayExPeriod" :value="position.expiryDate" />

    <PortfolioDataLabelRowWithTooltip v-if="showExpectedValueColumn" 
        :label="this.dictionary.table.expectedValue" 
        :value="getExpectedValue(position, toggles)" 
        :tooltipText="dictionary.block.portfolioExpectedValueColumnTooltip" 
        :tooltipShow="!toggles.hideExpectedValueTooltipColumn" />

    <PortfolioPlanActionButtons v-if="showPlanActionButtons" :position="position" :dictionary="dictionary" :isMobile="isMobile" />
    <PortfolioPlanActionLinks v-if="!isMultiPositionExit" class="mt-2" :position="position" :dictionary="dictionary" :isMobile="isMobile" />
    
    </div>
</template>
    
<script>

import PortfolioDataLabelRow from "./portfolio-data-label-row.component.vue";
import PortfolioPlanActionButtons from "./Shared/position-action-buttons.component.vue";
import PortfolioPlanActionLinks from "./portfolio-plan-action-links.component.vue";
import PortfolioDataLabelRowWithTooltip from "./portfolio-data-label-row-with-tooltip.component.vue";
import { controlTagsMethods } from "./mixins/portfolio-plan-control-tags.mixin";
import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';

export default {
  mixins: [ controlTagsMethods, positionMethods ],
  components: {
        PortfolioDataLabelRow,
        PortfolioPlanActionButtons,
        PortfolioPlanActionLinks,
        PortfolioDataLabelRowWithTooltip
       },
  props: {
    isMultiPositionExit: Boolean,
    dictionary: Object,
    position: Object,
    isMobile: Boolean,
    controlTags: String,
    anyVestings: Boolean,
    derivativeSecurity: Object,
    toggles: Object
},
  computed: {
   lastDerivativeSecurityPrice() {
        return this.derivativeSecurity != null ? this.derivativeSecurity.lastSecurityPriceFormatted : ''
    }
  }
};
</script>
<template>
  <dl class="order-details-list"> 
      <OrderDataLabel :value="valueAmountToDeliver" :label="labelAmountToDeliver"></OrderDataLabel>
      <OrderDataLabel v-if="valueProceedsAccount != null" :value="valueProceedsAccount" :label="labelProceedsAccount"></OrderDataLabel>      
  </dl>      
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";

export default {
  components: {
    OrderDataLabel,
  },
  props: {
    valueAmountToDeliver: String,
    labelAmountToDeliver: String,
    valueProceedsAccount: String,
    labelProceedsAccount: String,
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "beneficiary-details form-group" }, [
    _c("p", [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]),
    _vm._v(" "),
    _c("p", [
      _c("a", { attrs: { href: _vm.$sanitize(_vm.urlAction) } }, [
        _vm._v(_vm._s(_vm.value))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
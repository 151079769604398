var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "email-content" },
          [
            _vm.headerVisible
              ? _c("EmailHeader", {
                  attrs: {
                    message: _vm.message,
                    isMobileOrTablet: _vm.isMobileOrTablet
                  },
                  on: {
                    "back-click": function($event) {
                      return _vm.$emit("back-click", false)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("EmailMessage", {
              attrs: { message: _vm.message, model: _vm.model }
            }),
            _vm._v(" "),
            _c("EmailAttachments", { attrs: { message: _vm.message } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
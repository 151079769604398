var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-block title-actions" }, [
      _vm.pagesDictionary
        ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.coupon.title))])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "title-actions-container" },
            [
              _vm.boundaries.canRequestCouponPaymentsReport
                ? _c("router-link", { attrs: { to: "/reports" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "title-action-icon",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_c("i", { staticClass: "fas fa-download" })]
                    ),
                    _vm._v(" " + _vm._s(_vm.dictionary.labels.downloadExtract))
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("Coupon", {
              attrs: {
                dictionary: _vm.dictionary,
                "datatables-dictionary": _vm.datatablesDictionary
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
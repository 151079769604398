var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionnaire-details-content" },
    [
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.questionnaireName,
          value: _vm.questionnaire.questionnaireName
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.nrOfQuestions,
          value: _vm.questionnaire.nrQuestions
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.nrOfUnansweredQuestions,
          value: _vm.questionnaire.unansweredQuestions
        }
      }),
      _vm._v(" "),
      _c("EvaluationDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.result,
          value: _vm.questionnaire.isEligible
            ? _vm.dictionary.labels.eligible
            : _vm.dictionary.labels.notEligible
        }
      }),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn btn-primary w-100",
          attrs: {
            role: "button",
            to: {
              name: "questionnaire-detail",
              params: {
                questionnaireId: _vm.questionnaire.questionnaireId,
                returnUrl: "/Beneficiaries#evaluation"
              }
            }
          }
        },
        [
          _vm._v(
            "\r\n    " +
              _vm._s(
                _vm.questionnaire.canEdit
                  ? _vm.dictionary.labels.modify
                  : _vm.dictionary.labels.view
              ) +
              " asd"
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <portlet-layout :title="dictionary.page.subTitle">
        <template v-slot:body>
            <ListedTradingTable :dictionary="dictionary" />
        </template>
    </portlet-layout>
</template>

<style>
    @import '../listed-trading.scss';
</style>

<script>
    import ListedTradingTable from './listed-trading-table.component.vue';

    export default {
        components: {
            ListedTradingTable
        },
        props: {
            dictionary: Object,
        }
    }
</script>
<template>
    <div id="login-template">
        <div>
            <slot name="title"></slot>
        </div>
        <div>
            <slot name="body"></slot>
        </div>
    </div> 
</template>

<script>
    export default {
        name: "login-template" 
    }

</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-details payment-expanded-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("PaymentDetailsExpandedRowDataLabel", {
              attrs: {
                label: _vm.dictionary.table.accountNumber,
                value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountNumber)
              }
            }),
            _vm._v(" "),
            _c("PaymentDetailsExpandedRowDataLabel", {
              attrs: {
                label: _vm.dictionary.table.bankName,
                value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountBankName)
              }
            }),
            _vm._v(" "),
            _c("PaymentDetailsExpandedRowDataLabel", {
              attrs: {
                label: _vm.dictionary.table.unconfirmedAmount,
                value: _vm.replaceNullOrEmpty(
                  _vm.payment.unconfirmedAmountFormatted
                )
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("PaymentDetailsExpandedRowDataLabel", {
              attrs: {
                label: _vm.dictionary.table.bankCountry,
                value: _vm.replaceNullOrEmpty(
                  _vm.payment.debitAccountBankCountryName
                )
              }
            }),
            _vm._v(" "),
            _c("PaymentDetailsExpandedRowDataLabel", {
              attrs: {
                label: _vm.dictionary.table.bic,
                value: _vm.replaceNullOrEmpty(_vm.payment.debitAccountBIC)
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isEditable
    ? _c("div", { staticClass: "beneficiary-details form-group" }, [
        _c(
          "label",
          { staticClass: "form-input-label", attrs: { for: _vm.name } },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectValue,
                  expression: "selectValue"
                }
              ],
              staticClass: "form-control",
              attrs: { id: _vm.name, name: _vm.name },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectValue = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.handleInput
                ]
              }
            },
            _vm._l(_vm.options, function(option) {
              return _c(
                "option",
                {
                  key: option.value,
                  attrs: { disabled: option.disabled },
                  domProps: { value: option.value }
                },
                [
                  _vm._v(
                    "\n              " + _vm._s(option.text) + "\n          "
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getErrorKey(),
                expression: "getErrorKey()"
              }
            ],
            staticClass: "beneficiary-edit-group__error-label"
          },
          [_vm._v(_vm._s(_vm.getErrorMessage()))]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text
    ? _c("div", { staticClass: "current-environment-container" }, [
        _c("p", { staticClass: "current-environment-text" }, [
          _vm._v(_vm._s(_vm.text))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="form-row">
        <div class="column-icon col-sm-auto d-none d-sm-block">
            <div v-if="!!stackContent"  :stack-content="stackContent">
                <span class="fa-stack fa-1x">
                    <i :class="`far ${stackFontName} fa-stack-2x fa-shadow`" aria-hidden="true"></i>
                    <span class="fa-stack-1x fa-inverse text-bold rich-text-container" v-html="$sanitize(stackContent)"></span>
                </span>
            </div>
        </div>
        <div class="column-title col col-sm-3">
            <p class="primary-label">{{label}}</p>
        </div>
        <div  class="column-expression col d-none d-sm-block text-right"><p  class="primary-color">{{expression}}</p></div>
        <div  class="column-result col  text-right"><p :class="resultClass">{{result}}</p></div>
    </div>
</template>

<script>

export default {
  props: {
    label: String,
    expression: String,
    result: String,
    resultClass:  String,
    stackContent: String,
    stackFontName: {
          type : String,
          default: "fa-square"
      }
    }
};
</script>
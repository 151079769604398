var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "financial-card-container" }, [
    _c("div", { staticClass: "financial-card-header" }, [
      _c("div", [
        _c("h3", [_c("small", [_vm._v(_vm._s(_vm.financialCardHeaderLabel))])])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.mayAddOrEditAccount && !_vm.isMobile
          ? _c("div", { attrs: { id: "linksContainer" } }, [
              !_vm.bankAccount.isDefault
                ? _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)", role: "button" },
                      on: {
                        click: function($event) {
                          return _vm.setDefaultAccount()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.financialDictionary.linkSetDefaultAccount)
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.editAccount()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "far fa-edit",
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v(_vm._s(_vm.financialDictionary.linkEditAccount))
                ]
              ),
              _vm._v(" "),
              !_vm.bankAccount.isInUse
                ? _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.removeAccount()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-trash",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(_vm._s(_vm.financialDictionary.removeAccount))
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "financial-card-body" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm.mayAddOrEditAccount && _vm.isMobile
          ? _c(
              "div",
              {
                staticClass: "d-md-flex mt-2",
                attrs: { id: "buttonsContainer" }
              },
              [
                !_vm.bankAccount.isDefault
                  ? _c(
                      "button",
                      {
                        staticClass: "btn secondaryButton ml-auto mb-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.setDefaultAccount()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.financialDictionary.linkSetDefaultAccount)
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn secondaryButton ml-auto mb-2",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.editAccount()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "far fa-edit",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.financialDictionary.linkEditAccount)
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.bankAccount.isInUse
                  ? _c(
                      "button",
                      {
                        staticClass: "btn secondaryButton ml-auto mb-0",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.removeAccount()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-trash",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.financialDictionary.removeAccount)
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
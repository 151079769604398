import { getUrlWithParameters } from 'urlHelper';

export const actionMethods = {
    methods: {
        getActionLink(endpoint, parameterName, parameterValue) {
            let parameters = this.urlParameters;
            if (parameterName && parameterValue) {
                parameters[parameterName] = parameterValue;
            }

            return getUrlWithParameters(endpoint, "Index", parameters);
        },
        goToPath(path, parameterName, parameterValue) {

            let parameters = this.urlParameters;
            if (parameterName && parameterValue) {
                parameters[parameterName] = parameterValue;
            }

            this.$router.push({
                path: path,
                query: parameters
                });
        },
        conditionalPath() {
            if (this.position.canInstructExit) {
                return this.goToPath('/exits');
            } else if (this.position.canInstructBuy) {
                return this.goToPath('/buy');
            } else if (this.position.canInstructExercise) {
                return this.goToPath('/exercises');
            } else {
                return this.goToPath('/internalExchanges', 'orderType', "Buy")
            }
        }
    },
    computed: {
        urlParameters() {
            return {
                positionUsageId: this.position.positionUsageId,
                sharePlanParticipationId: this.position.sharePlanParticipationId
            }
        },
    }
}
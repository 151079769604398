<template>
    <portlet-layout column-class="col-lg-8 col-xl-9" :title="dictionary.subscriptionWidgets.titleConfirmSubscription" >
        <template slot="body">             
            <div class="dividend-subscription-form" v-if="dictionary && dividendSubscription">
                <alert-component class="subscription-form-alert" v-if="error" variant="danger">{{ error }}</alert-component>
                <loading-spinner :active="isSubmitting"></loading-spinner>
                <div class="page-description rich-text-container" v-html="$sanitize(dictionary.subscriptionWidgets.textConfirmSubscription)"></div>

                <div class="confirm-dividend-subscription">
                     <span class="font-weight-bold">{{dividendSubscription.securityName}}</span>
                </div>

                <div>
                    <DividendSubscriptionDetailsRow :label="dictionary.subscriptionLabels.money" :value="moneyDividendAmountPercentage" :isMobile="isMobile" />
                    <DividendSubscriptionDetailsRow :label="dictionary.subscriptionLabels.shares" :value="sharesDividendAmountPercentage" :isMobile="isMobile" />
                </div>
                
                <DividendSubscriptionButtons :cancelButtonLabel="dictionary.subscriptionLabels.btnCancel" :subscribeButtonLabel="dictionary.subscriptionLabels.btnSubscribe" @cancel="cancel" @subscribe="subscribe" />
            </div>
        </template>
    </portlet-layout>
</template>

<script>
    import DividendSubscriptionButtons from "./dividend-subscription-buttons.component.vue";
    import DividendSubscriptionDetailsRow from "./dividend-subscription-details-row.component.vue";
    import numeral from 'numeral';

    export default {
        components: {
            DividendSubscriptionButtons,
            DividendSubscriptionDetailsRow,
        },
        props: {
            dictionary: Object,
            dividendSubscription: Object,
            subscribedDividend: Object,
            isMobile: Boolean
        },
        data() {
            return {
                error: null,
                isSubmitting: null,
            }
        },
        computed: { 
            moneyDividendAmountPercentage() {
                return `${numeral(100 - numeral(this.subscribedDividend.dividendAmountPercentage).value()).format('0.[000000000]')} %`;
            },
            sharesDividendAmountPercentage() {
                return `${numeral(this.subscribedDividend.dividendAmountPercentage).format('0.[000000000]')} %`;
            }
        },
        methods: {
            cancel() {
                this.$emit("cancel");
            },
            subscribe() {
                this.$emit("confirm-subscription", this.subscribedDividend);
            },
        },
    };
</script>
<template>
    <div class="vestings-expanded-rows">
        <div :key="`${row.type}-${index}`" v-for="(row, index) in rows" class="vestings-expanded-row">
            <v-card v-if="index == 0 && !isMobile" :class="`vesting-${row.type}`">
                <v-card-title>
                    <h3 class="primary-color h4">{{ row.title }}</h3>
                </v-card-title>
                <component class="flex-row" :is="row.type" :dictionary="dictionary" :details="row.data" />
            </v-card>

            <v-expansion-panels v-else :class="`vesting-${row.type}`" accordion multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <h3 class="primary-color h4">{{ row.title }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="flex column-details">
                        <component :is="row.type" :dictionary="dictionary" :details="row.data" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    import VestingModificationDetails from './vesting-modification-details.component.vue';
    import VestingAwardPurchaseDetails from './vesting-award-purchase-details.component.vue';

    export default {
        components: {
            modification: VestingModificationDetails,
            awardPurchase: VestingAwardPurchaseDetails
        },
        props: {
            vesting: Object,
            dictionary: Object,
            toggles: Object,
            isMobile: Boolean
        },
        computed: {
            rows() {
                let rows = [];

                if (this.vesting?.hasPurchase || this.toggles.showVestingTaxDetails ) {
                    rows.push({
                        type: 'awardPurchase', title: this.dictionary.details.title,
                        data: this.vesting.details
                    });
                }

                if (!this.toggles.hideVestingModifications) {

                    this.vesting?.modifications?.forEach(modification => {
                        rows.push({
                            type: 'modification', title: this.dictionary.modifications.title + " " + modification.effectiveDate,
                            data: modification
                        });
                    });
                }
                return rows;
            }
        }
    }
</script>
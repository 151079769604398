<template>
    <div>
        <div id="address-edit-form-body">
            <input type="hidden"
                   name="AddressId"
                   class="form-control"
                   :value="editModel.addressId" />
            <input type="hidden"
                   name="EmailAddress"
                   class="form-control"
                   :value="editModel.emailAddress" />
            <input type="hidden"
                   name="CcEmailAddress"
                   class="form-control"
                   :value="editModel.ccEmailAddress" />
            <input type="hidden"
                   name="LocaleId"
                   class="form-control"
                   :value="editModel.localeId" />

            <beneficiary-edit-label v-model="editModel.addressProperty"
                                    :label="dictionary.address.address"
                                    name="AddressProperty"
                                    :is-visible="editModel.mayEditCurrentAddress"
                                    :is-editable="editModel.mayEditCurrentAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"></beneficiary-edit-label>

            <beneficiary-edit-label v-model="editModel.complementaryAddress"
                                    :label="dictionary.address.complementaryAddress"
                                    name="ComplementaryAddress"
                                    :is-visible="editModel.mayEditCurrentAddress"
                                    :is-editable="editModel.mayEditCurrentAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"></beneficiary-edit-label>

            <beneficiary-edit-label v-model="editModel.postalCode"
                                    :label="dictionary.address.postalCode"
                                    name="PostalCode"
                                    :is-visible="editModel.mayEditCurrentAddress"
                                    :is-editable="editModel.mayEditCurrentAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"></beneficiary-edit-label>

            <beneficiary-edit-label v-model="editModel.city"
                                    :label="dictionary.address.city"
                                    name="City"
                                    :is-visible="editModel.mayEditCurrentAddress"
                                    :is-editable="editModel.mayEditCurrentAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"></beneficiary-edit-label>

            <beneficiary-select-edit-label v-model="editModel.countryId"
                                           :label="dictionary.address.country"
                                           name="CountryId"
                                           :options="countries"
                                           :is-editable="editModel.mayEditCurrentAddress"
                                           :error-keys="errorKeys"
                                           :validations="validations"></beneficiary-select-edit-label>

            <beneficiary-edit-label v-model="editModel.telephone"
                                    :label="dictionary.address.telephone"
                                    name="Telephone"
                                    :is-visible="editModel.mayEditCurrentAddress"
                                    :is-editable="editModel.mayEditCurrentAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"></beneficiary-edit-label>
        </div>
        <FormButtons :commonDictionary="commonDictionary" @submit-form="submitForm()" @cancel-form="$emit('cancel-form')" />
    </div>
</template>

<script>
    import BeneficiaryEditLabel from "./../edit-label.component.vue";
    import BeneficiarySelectEditLabel from "./../select-edit-label.component.vue";
    import FormButtons from "./form-buttons.component.vue";

    const createDropDownOption = (text, value) => ({
        text: text,
        value: value,
    });

    export default {
        props: {
            editModel: Object,
            errorKeys: Array,
            validations: Array,
            dictionary: Object,
            commonDictionary: Object
        },
        components: {
            BeneficiaryEditLabel,
            BeneficiarySelectEditLabel,
            FormButtons
        },
        computed: {
            countries() {
                return this.editModel.countries.map((c) => {
                    return createDropDownOption(c.name, c.id);
                });
            },
        },
        methods: {
            submitForm() {
                this.$emit('submit-form');
            }
        }
    };
</script>
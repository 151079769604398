<template>
<div>
    <div class="form-row">
        <div class="col">
            
            <div class="email-content">
                <EmailHeader v-if="headerVisible" :message="message" :isMobileOrTablet="isMobileOrTablet" @back-click="$emit('back-click', false)" />
                <hr>
                <EmailMessage :message="message" :model="model" />
                <EmailAttachments :message="message" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import EmailHeader from './email-header.component.vue';
import EmailMessage from './email-message.component.vue';
import EmailAttachments from './email-attachments.component.vue';

export default {
    components: {
        EmailHeader,
        EmailMessage,
        EmailAttachments
    },
    props: {
        model: Object,
        message: Object,
        headerVisible: Boolean,
        isMobileOrTablet:Boolean
    },
}
</script>
<template>
    <div id="expansion-panel-subscriptions">
        <v-expansion-panels :class="['subscriptions-details-expansion-block', 'mobile',
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion>
            <v-expansion-panel class="subscriptions-details-expansion-panel">
                <v-expansion-panel-header>
                    <div class="subscriptions-details-expansion-panel__header">
                        <slot name="header"></slot>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mobile" eager>
                    <div>
                        <slot name="content"></slot>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>

    export default {
        props: {
            position: Object,
            dictionary: Object,
            isMobile: Boolean
        },
        data() {
            return {
                panel: null
            }
        },
        computed: {
            isPanelOpened() {
                return this.panel !== undefined && this.panel !== null;
            }
        },
        methods: {
            togglePanel() {
                if (this.isPanelOpened) {
                    this.panel = null;
                } else {
                    this.panel = 0;
                }
            }
        }
    }
</script>
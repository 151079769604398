var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
    _vm.isMobile
      ? _c(
          "p",
          { staticClass: "font-weight-bold subscribe-details-row-title" },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _c("p", { staticClass: "subscribe-details-row-title" }, [
          _vm._v(_vm._s(_vm.label))
        ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.value))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible && _vm.isLoaded && _vm.showAvatarIcon
    ? _c("div", { staticClass: "personal-data-menu" }, [
        _c(
          "ul",
          { staticClass: "sidebar-nav personal-data-menu__list" },
          [
            _vm.userFullName
              ? _c("li", { staticClass: "personal-data-menu__user" }, [
                  _c("div", { staticClass: "nav-item" }, [
                    _c("i", { staticClass: "far fa-user fa-2x" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "navigation-text" }, [
                      _vm._v(_vm._s(_vm.userFullName))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.filteredItems, function(item, index) {
              return _c(
                "li",
                { key: item.name + "-" + index },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item personal-data-link",
                      attrs: { to: item.relativeUrl }
                    },
                    [
                      _c("i", { class: ["far", "fa-2x", item.icon] }),
                      _vm._v(" "),
                      _c("span", { staticClass: "navigation-text" }, [
                        _vm._v(_vm._s(item.text))
                      ])
                    ]
                  )
                ],
                1
              )
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
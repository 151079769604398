var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("beneficiary-form", {
    attrs: {
      "edit-model": _vm.addressDataEditModel,
      "form-name": "address-edit-form",
      "common-dictionary": _vm.commonDictionary,
      "account-dictionary": _vm.accountDictionary,
      "url-post-action": "/Beneficiaries/EditAddress",
      "child-component": "beneficiary-address-edit"
    },
    on: {
      "form-cancelled": _vm.addressEditFormCancelled,
      "form-submitted": _vm.reloadPage
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="beneficiaryAccountData" class="print-header print-only">
        <div class="row"><img :src="skin.images.logo" alt="App Logo"></div>
        <div class="row print-header-address">
            <div class="col-6 text-left" v-if="beneficiaryAccountData.beneficiaryBoundaries && beneficiaryAccountData.beneficiaryBoundaries.mayViewPersonalData" >
                <div class="row">{{ beneficiaryAccountData.fullName }}</div>
                <div class="row">{{ beneficiaryAccountData.currentAddress.addressProperty }} </div>
                <div class="row">{{ beneficiaryAccountData.currentAddress.postalCode }} {{ beneficiaryAccountData.currentAddress.city }}</div>
                <div class="row">{{ beneficiaryAccountData.currentAddress.country }}</div>
            </div>
        </div>
        <div class="print-header-address" v-if="dictionary">
            <div class="row"><span>{{ dictionary.block.portfolioOverviewSubHeadingReportDate }} </span> <span class="col-8">{{ getDateTimeNow | toDateTime  }}</span></div>
            <div class="row"><span>{{ dictionary.block.portfolioOverviewSubHeadingReportUserId}} </span> <span class="col-8">{{ beneficiaryAccountData.userName }}</span></div>
        </div>
    </div>
</template>

<script>
import { get } from 'api';

export default {
    created() {
        get("/Beneficiaries/BeneficiaryAccountData", response => {
            this.beneficiaryAccountData = response.data;
        }, this);
    },
    props: {
        dictionary:Object,
        skin: Object
    },
    data() {
        return {
            beneficiaryAccountData: {},
            layout: {}
        }
    },
    computed: {
        getDateTimeNow() {
            return new Date(Date.now());
        }
    }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "payment" },
        [
          _c("v-card-title", { staticClass: "payment-title" }, [
            _c("h3", { staticClass: "primary-color h4" }, [
              _vm._v(_vm._s(_vm.dictionary.table.expandedRowTitle))
            ])
          ]),
          _vm._v(" "),
          _c("PaymentExpandedItemDetails", {
            staticClass: "flex-row",
            attrs: { dictionary: _vm.dictionary, payment: _vm.payment }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
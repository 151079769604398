var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "required-documents-details-row-flex form-row" },
    [
      _c(
        "p",
        {
          staticClass:
            "col required-documents-details-row__label font-weight-bold"
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          class: [
            "col required-documents-details-row__value",
            { "text-right": _vm.textRight }
          ]
        },
        [_vm._v(_vm._s(_vm.value))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
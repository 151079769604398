var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scenario
    ? _c(
        "form",
        { staticClass: "subscription-form" },
        [
          _vm.error
            ? _c(
                "alert-component",
                {
                  staticClass: "subscription-form-alert",
                  attrs: { variant: "error" }
                },
                [_vm._v(_vm._s(_vm.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
          _vm._v(" "),
          _c("SubscriptionScenario", {
            attrs: {
              dictionary: _vm.dictionary,
              validationLists: _vm.validationLists,
              isSubscriptions: _vm.isSubscriptions,
              isAcceptCollumn: _vm.isAcceptCollumn,
              isValueLocked: _vm.isValueLocked
            },
            model: {
              value: _vm.scenario,
              callback: function($$v) {
                _vm.scenario = $$v
              },
              expression: "scenario"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt-3 button-container d-md-flex justify-content-end"
            },
            [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn secondaryButton",
                    attrs: { type: "button" },
                    on: { click: _vm.cancel }
                  },
                  [_vm._v(_vm._s(_vm.dictionary.widget.btnGoBack))]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn primaryButton",
                    attrs: { type: "button" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v(_vm._s(_vm.primaryButtonText))]
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bank-account-body" },
    [
      _vm.currentStep > 3
        ? [
            _c(
              "FinancialAddAccountStepAccordion",
              {
                attrs: {
                  heading: _vm.dictionary.custodianSubHeading,
                  isCustodian: true,
                  "current-step": _vm.currentStep,
                  dictionary: _vm.dictionary,
                  showStep3: true
                }
              },
              [
                _c("dl", { staticClass: "details-list bank-account-details" }, [
                  _c(
                    "div",
                    { staticClass: "details-list-left" },
                    [
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.name,
                          label: _vm.dictionary.name
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.address.addressProperty,
                          label: _vm.dictionary.address
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.address.postalCode,
                          label: _vm.dictionary.postalcode
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.address.city,
                          label: _vm.dictionary.city
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.address.state,
                          label: _vm.dictionary.state
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.centralSecuritiesDepositoryTypesText(
                            _vm.custodianModel.csdType
                          ),
                          label: _vm.dictionary.settlementService
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details-list-right" },
                    [
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.csdIdentifier,
                          label: _vm.dictionary.identifier
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.contactName,
                          label: _vm.dictionary.contactName
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.contactPhone,
                          label: _vm.dictionary.contactPhoneNumber
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.contactFax,
                          label: _vm.dictionary.contactFaxNumber
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.contactEmail,
                          label: _vm.dictionary.contactEmail
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.custodianModel.contactReference,
                          label: _vm.dictionary.contactReference
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.toggles.hideSubCustodianFieldsForSecurityAccounts
              ? _c("FinancialAddAccountStepSubCustodian", {
                  attrs: {
                    "custodian-data": _vm.custodianData,
                    dictionary: _vm.dictionary.subCustodian,
                    "current-step": _vm.currentStep
                  }
                })
              : _vm._e()
          ]
        : _vm.currentStep >= 3
        ? _c(
            "FinancialAddAccountStepAccordion",
            {
              ref: "FinancialAddAccountStepAccordion",
              attrs: {
                heading: _vm.dictionary.custodianSubHeading,
                isCustodian: true,
                showStep3: true
              },
              on: { "hook:mounted": _vm.expandCustodian }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-name",
                        label: _vm.dictionary.name,
                        validationsList: _vm.custodianValidations.custodianName,
                        "is-mandatory": _vm.isBankNameEmpty,
                        toolTipText: _vm.dictionary.nameTootip
                      },
                      model: {
                        value: _vm.custodianModel.name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "custodianModel.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-address",
                        label: _vm.dictionary.address
                      },
                      model: {
                        value: _vm.custodianModel.address.addressProperty,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel.address,
                            "addressProperty",
                            $$v
                          )
                        },
                        expression: "custodianModel.address.addressProperty"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-postalcode",
                        label: _vm.dictionary.postalcode
                      },
                      model: {
                        value: _vm.custodianModel.address.postalCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel.address,
                            "postalCode",
                            $$v
                          )
                        },
                        expression: "custodianModel.address.postalCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        validationsList: _vm.custodianValidations.city,
                        name: "custodian-city",
                        label: _vm.dictionary.city,
                        "is-mandatory": _vm.isCustodianCityRequired
                      },
                      model: {
                        value: _vm.custodianModel.address.city,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel.address,
                            "city",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "custodianModel.address.city"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-state",
                        label: _vm.dictionary.state
                      },
                      model: {
                        value: _vm.custodianModel.address.state,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel.address, "state", $$v)
                        },
                        expression: "custodianModel.address.state"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("select-element", {
                      attrs: {
                        name: "custodian-settlemetservice",
                        options: _vm.centralSecuritiesDepositoryTypesList,
                        disabled:
                          _vm.securityAccountDisplaySettings
                            .allowUKShareTransferSettingsOnly,
                        label: _vm.dictionary.settlementService,
                        validationsList: _vm.custodianValidations.csdType,
                        "is-mandatory":
                          _vm.isCustodianNameEmpty &&
                          !_vm.usesDomesticInstructionsOnly,
                        toolTipText: _vm.dictionary.settlementServiceTooltip
                      },
                      model: {
                        value: _vm.custodianModel.csdType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel,
                            "csdType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "custodianModel.csdType"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-identifier",
                        label: _vm.dictionary.identifier,
                        validationsList: _vm.custodianValidations.csdIdentifier,
                        "is-mandatory":
                          (_vm.isCustodianNameEmpty &&
                            !_vm.usesDomesticInstructionsOnly) ||
                          (!!this.custodianModel.csdType &&
                            this.custodianModel.csdType !== "csdtNone"),
                        toolTipText: _vm.dictionary.identifierTooltip
                      },
                      model: {
                        value: _vm.custodianModel.csdIdentifier,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.custodianModel,
                            "csdIdentifier",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "custodianModel.csdIdentifier"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-contact-name",
                        label: _vm.dictionary.contactName
                      },
                      model: {
                        value: _vm.custodianModel.contactName,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel, "contactName", $$v)
                        },
                        expression: "custodianModel.contactName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-contact-phone",
                        label: _vm.dictionary.contactPhoneNumber
                      },
                      model: {
                        value: _vm.custodianModel.contactPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel, "contactPhone", $$v)
                        },
                        expression: "custodianModel.contactPhone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-contact-fax",
                        label: _vm.dictionary.contactFaxNumber
                      },
                      model: {
                        value: _vm.custodianModel.contactFax,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel, "contactFax", $$v)
                        },
                        expression: "custodianModel.contactFax"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-contact-email",
                        label: _vm.dictionary.contactEmail
                      },
                      model: {
                        value: _vm.custodianModel.contactEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel, "contactEmail", $$v)
                        },
                        expression: "custodianModel.contactEmail"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "custodian-contact-reference",
                        label: _vm.dictionary.contactReference
                      },
                      model: {
                        value: _vm.custodianModel.contactReference,
                        callback: function($$v) {
                          _vm.$set(_vm.custodianModel, "contactReference", $$v)
                        },
                        expression: "custodianModel.contactReference"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("FinancialAddAccountMandatoryTooltip", {
                attrs: { tooltipText: _vm.dictionary.mandatory }
              }),
              _vm._v(" "),
              !_vm.toggles.hideSubCustodianFieldsForSecurityAccounts
                ? _c("FinancialAddAccountStepSubCustodian", {
                    attrs: {
                      "custodian-data": _vm.custodianData,
                      dictionary: _vm.dictionary.subCustodian,
                      "current-step": _vm.currentStep
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
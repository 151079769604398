<template>
<div :class="[ type + '-order-buttons', {'mobile' : isMobile}]">
    <div v-if="position.canInstructInternalExchange" :class="type + '-buysell-buttons'">
        <div v-if="position.canBuy" class="green-button">
            <a @click="goToPath('/internalExchanges', 'orderType', 'Buy')" role="button">{{ getBuyButtonText }}</a>
        </div>
        <div v-else class="grayed-button">
            <a class="isDisabled" href="" role="button">{{ getBuyButtonText }}</a>
        </div>

        <div v-if="position.canSell" class="red-button">
            <a @click="goToPath('/internalExchanges', 'orderType', 'Sell')" role="button">{{ getSellButtonText }}</a>
        </div>
        <div v-else class="grayed-button">
            <a class="isDisabled" href="" role="button">{{ getSellButtonText }}</a>
        </div>
    </div>

    <button v-if="position.canInstructExit" :class="getButtonClasses" @click="goToPath('/exits')">
        {{ dictionary.table.btnInstructionOrder }}
    </button>

     <button v-else-if="position.canInstructBuy" :class="getButtonClasses" @click="goToPath('/buy')">
        {{ dictionary.table.btnInstructionOrder }}
     </button>

    <button v-else-if="position.canInstructExercise" :class="getButtonClasses" @click="goToPath('/exercises')">
        {{ dictionary.table.btnInstructionOrder }}
    </button>
    <button v-else-if="!position.isSecurityPosition && position.canPositionPay" :class="getButtonClasses" @click="goToPath('/moneyTransfer')">
        {{ dictionary.table.btnInstructionOrder }}
    </button>
</div>
</template>

<script>
import { actionMethods } from './mixins/position-action-methods.mixin';

export default {
    mixins: [actionMethods],
    props: {
        dictionary: Object,
        position: Object,
        isMobile: Boolean,
        type: {
            type: String,
            default: 'portfolio'
        }
    },
    methods: {
        redirectToActionLink(href) {
            this.$router.push({ path: href });
        }
    },
    computed: {
        getButtonClasses() {
            const basicClasses = "btn primaryButton order-btn";
            return this.position.canInstructInternalExchange ? [basicClasses, this.isMobile ? "mt-2" : "mt-1" ] : basicClasses;
        },
        getSellButtonText() {
            return (this.isMobile && this.type == 'portfolio') ? this.dictionary.table.btnInstructionSell : this.dictionary.table.btnInstructionSell.charAt(0);
        },
        getBuyButtonText() {
            return (this.isMobile && this.type == 'portfolio') ? this.dictionary.table.btnInstructionBuy : this.dictionary.table.btnInstructionBuy.charAt(0);
        }
    }
}
</script>


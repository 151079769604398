<template>
    <LoginTemplate v-if="dictionary">
        <template slot="title">
            <div>
                <h1 class="sr-only welcome-text">{{dictionary.ssoPage.welcomeText}}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{dictionary.ssoPage.welcomeText}}</p>
                <hr>
            </div>
        </template>
        <template slot="body">
            <div>
                <div class="form-group">
                    <div class="text-center py-2 rich-text-container" v-html="$sanitize(dictionary.ssoPage.supportText)"></div>
                </div>
                <button class="btn btn-block btn-primary mt-5" type="submit" tabindex="3" @click="login()">
                    {{dictionary.ssoPage.btnText}}
                </button>
            </div>
        </template>
    </LoginTemplate>
</template>

<script>
    import { get,post } from "api";
    import LoginTemplate from "./login-template.component.vue";

    export default {
        components: {
            LoginTemplate
        },
        data() {
            return {
                dictionary: null,
                isLoading: true,
            };
        },
        beforeCreate() {
            get("/login/Dictionary", (response) => {
                this.dictionary = response.data;
                this.isLoading = false;
            }, this);
        },
        methods: {
            login() {
                post(
                    '/login-sso',
                    null,
                    (response) => {
                        window.location.replace(response.data);
                    },
                    this,
                    () => {},
                    (err) => {
                        window.location.replace("/error");
                    }
                );
            }
        }      
    }

</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("ReportsDetail", {
        attrs: {
          id: "CouponReportDetails",
          dictionary: _vm.dictionary,
          toggles: _vm.toggles,
          reportName: _vm.dictionary.coupon.name,
          reportDescription: _vm.dictionary.coupon.description,
          "is-mobile": _vm.isMobile
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  !_vm.isMobile
                    ? _c("div", { staticClass: "custom-report-form" }, [
                        _c("div", {
                          staticClass: "custom-report-form-empty-height"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "custom-report-form-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn primaryButton",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.getCoupon()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.dictionary.coupon.extractButton)
                              )
                            ]
                          )
                        ])
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-md-flex justify-content-md-between" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-report-form-empty-height" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.dictionary.coupon.description)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "custom-report-form-btn col" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn primaryButton",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.getCoupon()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.dictionary.coupon.extractButton)
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          442448512
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<portlet-layout v-if="!!accountDataModel.spouseData" :title="accountDictionary.widgets.spousal" :hideShadow="true">
    <template v-slot:body>
        <div>
            <SpousalView :spouseDataModel="accountDataModel.spouseData" 
            :dictionary="accountDictionary.spouse"
            :beneficiary-boundaries-spouse-info="beneficiaryBoundariesSpouseInfo"></SpousalView>
        </div>
    </template>
</portlet-layout>
</template>

<script>

import SpousalView from './spousal-view.component.vue';

export default {
    components: {
        SpousalView,
    },
    props: {
        accountDictionary: Object,
        accountDataModel:Object,
        beneficiaryBoundariesSpouseInfo:Object
    }
}
</script>
import { requiredIf } from "vuelidate/lib/validators";

const isGivenOpenAnswerRequired = (question, vm) => {
  return vm.currentQuestionnaire.questions.filter(q => q.questionId == question.questionId && q.questionType == 'qqtOpen').length == 1
}

const isValidAnswer = (inputText) => {
  const pattern = /^[a-zA-Z0-9 !@#$£,€%^&*()_+\-=\[\]{};':"\\|,.\/?]*$/;
  const rg = new RegExp(pattern)
  return rg.test(inputText)
}

export const questionnaireValidations = {
  validations: {
      currentQuestionnaire: {
        questions: {
          $each: { 
            givenOpenAnswer: { 
              required: requiredIf(function (question) {
                    return isGivenOpenAnswerRequired(question, this);
              }),
              isValidAnswer 
            },
          }
        }       
      }      
  },
}
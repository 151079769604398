<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>

        <alert-component v-if="model.isOutOfHours" variant="warning">
            <div class="rich-text-container" v-html="$sanitize(dictionary.confirmation.realTimeDealWarningOutOfHours)"></div>
        </alert-component>

        <ExitConfirmationRtdCountdownTimer v-if="model.requiresInput" :dictionary="dictionary" :timerValue="timerValue" />

        <p v-if="model.requiresInput">{{ dictionary.widgets.textConfirmation }}</p>
        <dl class="order-details-list">
            <ExitConfirmationRow v-if="model.state && !toggles.hideOrderState" :label="dictionary.confirmation.stateName" :value="model.state.description" />
            <ExitConfirmationRow :label="dictionary.confirmation.planName" :value="model.planName" />
            <ExitConfirmationPositionsRow :label="dictionary.confirmation.positionName" :positions="model.positions" />
            <!-- TODO: should come from tOption API -->
            <ExitConfirmationRow :label="dictionary.confirmation.stockMarket" :value="'London Stock Exchange'" />

            <ExitConfirmationRow :label="dictionary.confirmation.securityPrice" :value="model.securityPriceFormatted" />
            <ExitConfirmationNeQuantity :isActive="model.requiresInput" v-on="$listeners" :dictionary="dictionary" :exitInstructionViewModel="model" />

            <ExitConfirmationRow :label="dictionary.exitsConfirmationLabelGrossAmount" :value="model.grossAmountFormatted" />
            <payments-result :payments="model.feesPayments" :noPaymentsLabel="dictionary.confirmation.paymentFees" />
            <ExitConfirmationRow :label="dictionary.confirmation.proceeds" :value="model.proceedsFormatted" class="confirmation-total-row"/>
        </dl>

        <div class="mt-4">
            <div v-if="!model.requiresInput" class="float-md-right clearfix">
                <button class="btn primaryButton" :disabled="false" @click="$emit('exit-instruction-go-back')">{{ dictionary.form.goBack }}</button>
            </div>
            <div v-else-if="!model.disclaimers.length" class="mr-0">
                <ExitConfirmationButtonsComponent :isQuoteExpires="quoteExpires" :exitInstructionViewModel="model" v-on="$listeners" />
            </div>
        </div>
    </div>
</template>

<script>
    import ExitConfirmationRow from "./exit-confirmation-row.components.vue";
    import ExitConfirmationPositionsRow from "./exit-confirmation-positions-row.components.vue";
    import ExitConfirmationButtonsComponent from "./exit-confirmation-buttons.component.vue";
    import ExitConfirmationNeQuantity from "./exit-confirmation-rtd-new-quantity.component.vue";
    import ExitConfirmationRtdCountdownTimer from "./exit-confirmation-rtd-countdown-timer.component.vue";
    import Timer from "Shared/Basics/timer";

    const countdownStartValue = 15;

    export default {
        components: {
            ExitConfirmationRow,
            ExitConfirmationPositionsRow,
            ExitConfirmationButtonsComponent,
            ExitConfirmationNeQuantity,
            ExitConfirmationRtdCountdownTimer
        },
        props: {
            exitInstructionViewModel: Object,
            toggles: Object,
            dictionary: Object,
            error: String,
            quoteExpires: Boolean,
        },
        watch: {
            exitInstructionViewModel(newVal, oldVal) {
                if (newVal.requiresInput) {
                    this.$emit("on-quote-expires", false);
                    this.timerValue = countdownStartValue;
                    this.timer.start(this.timerValue);
                }
            },
            quoteExpires(newVal, oldVal) {
                if (newVal) {
                    this.timer.stop();
                    this.timerValue = 0;
                }
            }
        },
        data() {
            return {
                timerValue: countdownStartValue,
                timer: new Timer({ duration: countdownStartValue }),
            };
        },
        computed: {
            model() {
                return this.exitInstructionViewModel;
            }
        },
        mounted() {
            this.timer.on("tick", (time) => {
                this.timerValue = time;
            });
            this.timer.on("end", () => {
                this.$emit("on-quote-expires", true);
                this.timerValue = 0;
                this.error = this.dictionary.confirmation.dealAlertQuoteExpired;
            });
            if (this.model.requiresInput) {
                this.timer.start(this.countdownStartValue);
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("div", { staticClass: "cookie-policy-details" }, [
        _c(
          "div",
          { staticClass: "cookie-policy-details__content" },
          [
            _c("CookiePolicyModalDetailRow", {
              attrs: {
                title: _vm.dictionary.modal.titleStrictlyNecessary,
                text: _vm.dictionary.modal.textStrictlyNecessary
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "switch",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "cookie-policy-detail__always-on" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.dictionary.modal
                                    .alertStrictlyNecessaryAlwaysOn
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "far fa-check-circle fa ml-1",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3052117417
              )
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("CookiePolicyModalDetailRow", {
              attrs: {
                title: _vm.dictionary.modal.titleFunctional,
                text: _vm.dictionary.modal.textFunctional
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "switch",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            class: [
                              "toggle-button",
                              "btn-sm",
                              "btn-toggle",
                              _vm.functionalToggleValue ? "active" : ""
                            ],
                            attrs: {
                              type: "button",
                              "data-toggle": "button",
                              "aria-pressed": _vm.functionalToggleValue,
                              "aria-label": _vm.dictionary.modal.titleFunctional
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("onFunctionalToggle")
                              }
                            }
                          },
                          [_c("span", { staticClass: "handle" })]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1866700250
              )
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("CookiePolicyModalDetailRow", {
              attrs: {
                title: _vm.dictionary.modal.titlePerformanceAnalytics,
                text: _vm.dictionary.modal.textPerformanceAnalytics
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "switch",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            class: [
                              "toggle-button",
                              "btn-sm",
                              "btn-toggle",
                              _vm.performanceToggleValue ? "active" : ""
                            ],
                            attrs: {
                              type: "button",
                              "data-toggle": "button",
                              "aria-pressed": _vm.performanceToggleValue,
                              "aria-label":
                                _vm.dictionary.modal.titlePerformanceAnalytics
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("onPerformanceToggle")
                              }
                            }
                          },
                          [_c("span", { staticClass: "handle" })]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1512224524
              )
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row cookie-policy__buttons" }, [
          _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary btn-primary-white-background w-100",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cookie-allow-selection")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.modal.btnAllowSelection))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 mt-2" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary btn-primary-white-background w-100",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cookie-allow-all")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.dictionary.modal.btnAllowAll))]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
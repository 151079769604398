var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isUserNameVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.accountDataModel.userName,
              label: _vm.dictionary.loginName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isEmailAddressVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.accountDataModel.emailAddress,
              label: _vm.dictionary.emailAddress
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCCEmailAddressVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.accountDataModel.ccEmailAddress,
              label: _vm.dictionary.ccEmailAddress
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPersonalTelephoneVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.accountDataModel.telephone,
              label: _vm.dictionary.mobileTelephone
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isBusinessTelephoneVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.accountDataModel.businessTelephone,
              label: _vm.dictionary.businessTelephone
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.accountDataModel.beneficiaryBoundaries &&
      _vm.accountDataModel.beneficiaryBoundaries.mayChangePassword
        ? [
            _c("BeneficiaryAccountChangePasswordLink", {
              attrs: {
                label: _vm.dictionary.password,
                link: _vm.dictionary.changePassword
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.accountDataModel.locale
            ? _vm.accountDataModel.locale.name
            : null,
          label: _vm.dictionary.language
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.accountDataModel.decimalSeparator.description,
          label: _vm.dictionary.decimalSeparator
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section>
    <div class="row internal-exchange-orderbook-tabels">
          <div class="col-sm-12 col-xl-12 col-xxl-6 orderbook-buy">
            <div class="orderbook-topheader table-topheader-bg-color">
                  <h4 class="text-white mb-1">{{ dictionary.orderBook.orderTypeBuy }}</h4>
            </div>
            <InternalExchangesOrderbookTable type="buy" :dictionary="dictionary"></InternalExchangesOrderbookTable>
          </div>

          <div class="col-sm-12 col-xl-12 col-xxl-6 orderbook-sell">
              <div class="orderbook-topheader table-topheader-bg-color">
                  <h4 class="text-white mb-1">{{ dictionary.orderBook.orderTypeSell }}</h4>
              </div>
              <InternalExchangesOrderbookTable type="sell" :dictionary="dictionary"></InternalExchangesOrderbookTable>
          </div>
    </div>
  </section>
</template>

<script>
import InternalExchangesOrderbookTable from "./internal-exchanges-orderbook-table.component.vue";

export default {
  components: {
    InternalExchangesOrderbookTable
  },
  props: {
    dictionary:Object
  }
};
</script>
<template>
    <div :class="{'row' : !isMobile}">
        <div class="col disclaimers-image-container">
        </div>
        <div class="col">
            <div class="d-flex justify-content-center">
                <div class="disclaimers-content-container">
                    <BodyModalHeader :layout="layout" :isAuthenticated="isAuthenticated" :header="header" :subHeader="subHeader" :loginButton="loginButton" />
                    <router-view @set-header="setHeader" :isAuthenticated="isAuthenticated"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BodyModalHeader from './body-modal-header.component.vue';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

    export default {
        mixins: [ScreenSizeMixin],
        props: {
            layout: Object,
            isAuthenticated: Boolean
        },
        data() {
            return {
                header: null,
                subHeader: null,
                loginButton: false
            }
        },
        components: {
            BodyModalHeader
        },
        methods: {
            setHeader(payload) {
                this.header = payload.header,
                    this.subHeader = payload.subHeader,
                    this.loginButton = payload.loginButton ?? true
            }
        }
    };
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "LoginTemplate",
        [
          _c("template", { slot: "title" }, [
            _c("div", [
              _c("h1", { staticClass: "sr-only welcome-text" }, [
                _vm._v(_vm._s(_vm.dictionary.ssoPage.welcomeText))
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-center py-2 welcome-text",
                  attrs: { "aria-hidden": "true" }
                },
                [_vm._v(_vm._s(_vm.dictionary.ssoPage.welcomeText))]
              ),
              _vm._v(" "),
              _c("hr")
            ])
          ]),
          _vm._v(" "),
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("div", { staticClass: "form-group" }, [
                _c("div", {
                  staticClass: "text-center py-2 rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(_vm.dictionary.ssoPage.supportText)
                    )
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-primary mt-5",
                  attrs: { type: "submit", tabindex: "3" },
                  on: {
                    click: function($event) {
                      return _vm.login()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.dictionary.ssoPage.btnText) +
                      "\n            "
                  )
                ]
              )
            ])
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-row",
    {
      ref: "reportParameters",
      attrs: {
        "column-class": "col-12",
        title: _vm.portfolioOverviewDictionary.reportParameters
      }
    },
    [
      _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
      _vm._v(" "),
      _c("form", [
        _c("div", { attrs: { id: "report-date-container" } }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-4"] },
              [
                _c("label", { staticClass: "form-input-label" }, [
                  _c("span", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.portfolioOverviewDictionary.labels.date)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "px-2 noprint" },
                    [
                      _c("tooltip", {
                        attrs: {
                          tooltipText:
                            _vm.portfolioOverviewDictionary.labels.tooltipDate,
                          hideTooltip: false
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-8"] },
              [
                _c(
                  "datepicker-element",
                  {
                    attrs: { id: "reportDate", hideLabel: true },
                    model: {
                      value: _vm.model.reportDate,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "reportDate", $$v)
                      },
                      expression: "model.reportDate"
                    }
                  },
                  [
                    _c("template", { slot: "customAction" }, [
                      _c("a", {
                        staticClass: "fas fa-sync noprint",
                        attrs: {
                          href: "javascript:void(0)",
                          role: "button",
                          title:
                            _vm.portfolioOverviewDictionary.labels.resetDefault
                        },
                        on: { click: _vm.resetDefault }
                      })
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "security-price-container" } }, [
          _c(
            "div",
            { attrs: { id: "security-price-label-container" } },
            [
              _c(
                "PortfolioFormCustomLabel",
                {
                  attrs: {
                    label: _vm.portfolioOverviewDictionary.labels.securityPrice,
                    tooltipText:
                      _vm.portfolioOverviewDictionary.labels
                        .tooltipSecurityPrice
                  }
                },
                [
                  _c("template", { slot: "reset-action" }, [
                    _c(
                      "a",
                      {
                        staticClass: "fas fa-sync noprint",
                        attrs: {
                          href: "javascript:void(0)",
                          role: "button",
                          title:
                            _vm.portfolioOverviewDictionary.labels.resetDefault
                        },
                        on: { click: _vm.resetDefaultSecurityPrices }
                      },
                      [
                        _c("span", { staticClass: "p-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.portfolioOverviewDictionary.labels
                                .resetDefault
                            )
                          )
                        ])
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "security-prices-container" } },
            _vm._l(_vm.model.customizableSecurityPrices, function(
              customSecurityPrice,
              index
            ) {
              return _c(
                "div",
                {
                  key: customSecurityPrice.securityId,
                  staticClass: "row",
                  attrs: { index: index }
                },
                [
                  _c(
                    "div",
                    { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-4"] },
                    [
                      _c("label", { staticClass: "form-input-label" }, [
                        _vm._v(_vm._s(customSecurityPrice.name))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-8"] },
                    [
                      _c(
                        "input-element",
                        {
                          attrs: {
                            id:
                              "customSecurityPrice-" +
                              customSecurityPrice.securityId,
                            name:
                              "customSecurityPrice-" +
                              customSecurityPrice.securityId,
                            type: "text",
                            validationsList: _vm.reportValidationsSecurityPrices(
                              index
                            ),
                            autoFocus: false
                          },
                          model: {
                            value: customSecurityPrice.price,
                            callback: function($$v) {
                              _vm.$set(customSecurityPrice, "price", $$v)
                            },
                            expression: "customSecurityPrice.price"
                          }
                        },
                        [
                          _c("template", { slot: "group" }, [
                            _c(
                              "div",
                              {
                                staticClass: "input-group-prepend",
                                attrs: { "aria-hidden": "true" }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_vm._v(_vm._s(customSecurityPrice.symbol))]
                                )
                              ]
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "currency-conversion-label-container" } },
          [
            _c(
              "PortfolioFormCustomLabel",
              {
                attrs: {
                  label:
                    _vm.portfolioOverviewDictionary.labels.currencyConversion,
                  tooltipText:
                    _vm.portfolioOverviewDictionary.labels
                      .tooltipCurrencyConversion
                }
              },
              [
                _c("template", { slot: "reset-action" }, [
                  _c(
                    "a",
                    {
                      staticClass: "fas fa-sync noprint",
                      attrs: {
                        href: "javascript:void(0)",
                        role: "button",
                        title:
                          _vm.portfolioOverviewDictionary.labels.resetDefault
                      },
                      on: { click: _vm.resetDefaultCustomCurrencyRates }
                    },
                    [
                      _c("span", { staticClass: "p-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioOverviewDictionary.labels.resetDefault
                          )
                        )
                      ])
                    ]
                  )
                ])
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row", attrs: { id: "currency-dropdown-container" } },
          [
            _c(
              "div",
              { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-4"] },
              [
                _c("label", { staticClass: "form-input-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.portfolioOverviewDictionary.labels.currencyDropdown
                    )
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-8"] },
              [
                _c("select-element", {
                  attrs: {
                    name: "selectableCurrencies",
                    options: _vm.customCurrencies
                  },
                  model: {
                    value: _vm.model.selectedCurrency,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "selectedCurrency", $$v)
                    },
                    expression: "model.selectedCurrency"
                  }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "custom-conversion-rate-container" } },
          _vm._l(_vm.model.customCurrencyRates, function(
            customCurrencyRate,
            index
          ) {
            return _c(
              "div",
              {
                key: customCurrencyRate.currencyId,
                staticClass: "row",
                attrs: { index: index }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "primary-color",
                    class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-4"]
                  },
                  [
                    _c("label", { staticClass: "form-input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.conversionRateCurrecyTitle(customCurrencyRate)
                        )
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: [_vm.isMobile ? "col-md-6 col-lg-4" : "col-8"] },
                  [
                    _c("div", { staticClass: "custom-conversion-rate" }, [
                      _c(
                        "div",
                        { staticClass: "input-conversion-rate" },
                        [
                          _c(
                            "input-element",
                            {
                              attrs: {
                                id:
                                  "customCurrencyRate-" +
                                  customCurrencyRate.name,
                                validationsList: _vm.reportValidationsCurrencyRates(
                                  index
                                ),
                                disabled: !_vm.hasSelectedCurrency,
                                autoFocus: false
                              },
                              model: {
                                value: customCurrencyRate.rate,
                                callback: function($$v) {
                                  _vm.$set(customCurrencyRate, "rate", $$v)
                                },
                                expression: "customCurrencyRate.rate"
                              }
                            },
                            [
                              _c("template", { slot: "group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-group-prepend",
                                    attrs: { "aria-hidden": "true" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "input-group-text" },
                                      [
                                        _vm._v(
                                          _vm._s(customCurrencyRate.symbol)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isMobile
                        ? _c("div", { staticClass: "output-conversion-rate" }, [
                            _vm.hasSelectedCurrency
                              ? _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "input-group-text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.customConversionForSelectedCurrency
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-md-flex justify-content-end" }, [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn secondaryButton mr-2",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.resetAllToDefault()
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.portfolioOverviewDictionary.labels.resetAllToDefault
                  )
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn primaryButton",
                attrs: { type: "button", disabled: _vm.$v.$invalid },
                on: {
                  click: function($event) {
                    return _vm.getPortfolio()
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.portfolioOverviewDictionary.labels.btnRecalculate)
                )
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
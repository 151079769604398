var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-link-login" },
    [
      _c(
        "button",
        {
          on: {
            click: function($event) {
              _vm.isModalVisible = true
            }
          }
        },
        [
          _c("i", {
            staticClass: "far fa-question-circle fa-2x",
            attrs: { "aria-hidden": "true" }
          }),
          _c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(_vm.label))]),
          _c("span", { staticClass: "text-center" }, [
            _vm._v(" " + _vm._s(_vm.label))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.dictionary
        ? _c(
            "modal-basic",
            {
              attrs: {
                title: _vm.dictionary.page.supportTitle,
                "is-on": _vm.isModalVisible,
                "ok-footer": false
              },
              on: {
                close: function($event) {
                  _vm.isModalVisible = false
                }
              }
            },
            [
              _c("div", [
                _c("div", [
                  _c("div", {
                    staticClass: "rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(_vm.dictionary.page.supportText)
                      )
                    }
                  })
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>

    <b-form-group :label="dictionary.labels.filterByPlanName" label-for="plans">
      <b-form-select id="plans" v-model="selected" class="form-control form-control-sm" :options="plans" text-field="plan" value-field="plan" @change="filterPlanDocuments($event)">
        <template #first>
          <b-form-select-option value>{{ dictionary.labels.allPlans }}</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

</template>

<script>
export default {
  props: {
    plans: Array,
    dictionary: Object,
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    filterPlanDocuments: function (planName) {
      this.$emit("filter-plan-documents", planName);
    },
  },
};
</script>
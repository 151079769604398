var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-mobile-details-content" },
    [
      _c("PortfolioMobileRow", {
        attrs: {
          label: _vm.dictionary.tables.subscribedAccepted,
          value: _vm.position.acceptedAmountFormatted,
          textRight: true
        }
      }),
      _vm._v(" "),
      _c("PortfolioMobileRow", {
        attrs: {
          label: _vm.dictionary.tables.balance,
          value: _vm.position.balanceFormatted,
          textRight: true
        }
      }),
      _vm._v(" "),
      _c("PortfolioMobileRow", {
        attrs: {
          label: _vm.dictionary.tables.credited,
          value: _vm.position.creditedAmountFormatted,
          textRight: true
        }
      }),
      _vm._v(" "),
      _c("PortfolioMobileRow", {
        attrs: {
          label: _vm.dictionary.tables.debited,
          value: _vm.position.debitedAmountFormatted,
          textRight: true
        }
      }),
      _vm._v(" "),
      _vm.position.securityPrice
        ? _c("PortfolioMobileRow", {
            attrs: {
              label: _vm.dictionary.tables.securityPrice,
              value: _vm.position.securityPriceFormatted,
              textRight: true
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("PortfolioMobileRow", {
        attrs: {
          label: _vm.dictionary.tables.value,
          value: _vm.position.valueFormatted,
          textRight: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
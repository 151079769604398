var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "body-container" }, [
        _c(
          "div",
          { staticClass: "body-header-container" },
          [
            _c("HeaderContainer", {
              attrs: {
                header: _vm.layout.header,
                skin: _vm.layout.skin,
                isImpersonated: _vm.isImpersonated,
                isAuthenticated: _vm.isAuthenticated,
                isDisclaimer: _vm.isDisclaimer,
                logoutEndpoint: _vm.layout.logoutEndpoint,
                isPasswordExpired: _vm.isPasswordExpired
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: ["body-row-container", { "left-sidebar": _vm.showSidebar }]
          },
          [
            _c(
              "div",
              { staticClass: "body-sidebar-container" },
              [
                _vm.showSidebar
                  ? _c("Sidebar", {
                      attrs: {
                        dictionary: _vm.layout.sidebar.dictionary,
                        toggles: _vm.layout.sidebar.toggles,
                        features: _vm.layout.features,
                        "is-loading": _vm.isLoading,
                        "logout-endpoint": _vm.layout.logoutEndpoint
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "body-content-container" },
              [
                (!_vm.isAuthenticated && !_vm.isDisclaimer) ||
                (_vm.isAuthenticated && _vm.user.isPasswordExpired)
                  ? _c(
                      "div",
                      {
                        staticClass: "login-container",
                        attrs: { id: "container-login" }
                      },
                      [
                        _c("router-view", {
                          attrs: { isAuthenticated: _vm.isAuthenticated }
                        })
                      ],
                      1
                    )
                  : _vm.isDisclaimer
                  ? _c(
                      "div",
                      {
                        ref: "accessibilityMainFocus",
                        staticClass: "disclaimer-container",
                        attrs: { id: "container-modal" }
                      },
                      [
                        _c("BodyModalContainer", {
                          attrs: {
                            layout: _vm.layout,
                            isAuthenticated: _vm.isAuthenticated
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "content-container" },
                      [
                        _c(
                          "main",
                          {
                            ref: "accessibilityMainFocus",
                            staticClass: "section-container",
                            attrs: { id: "mainContent" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "container-fluid content-wrapper"
                              },
                              [
                                _c("router-view", {
                                  key: _vm.getMainRouteKey(_vm.$route),
                                  attrs: {
                                    isAuthenticated: _vm.isAuthenticated,
                                    pagesDictionary: _vm.layout.pagesDictionary,
                                    skin: _vm.layout.skin
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("LeaversPromptContainer", {
                          attrs: { toggles: _vm.layout.body.toggles },
                          on: {
                            "modal-closed": function($event) {
                              return _vm.accessibilityMainContentFocus()
                            }
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                !_vm.isDisclaimer
                  ? _c("FooterContainer", {
                      attrs: {
                        model: _vm.layout.footer,
                        "logo-desktop": _vm.layout.skin.images.footerLogo,
                        "logo-mobile": _vm.layout.skin.images.footerLogoMobile,
                        "is-default-footer": _vm.isDefaultFooter
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated ? _c("InactivityPrompt") : _vm._e(),
                _vm._v(" "),
                _c("CookiePolicyModal", {
                  attrs: { user: _vm.user, toggles: _vm.layout.body.toggles }
                }),
                _vm._v(" "),
                _c("LoadingSr", {
                  attrs: {
                    accessibilityDictionary: _vm.layout.accessibilityDictionary
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-row" },
    [
      _c("div", { staticClass: "title-block" }, [
        _vm.pagesDictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.subscriptions.title))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dictionary,
              expression: "dictionary"
            }
          ]
        },
        [
          _c(
            "b-tabs",
            {
              ref: "tabs",
              staticClass: "tab-container tab-bar",
              attrs: { "content-class": "mt-0" }
            },
            [
              _vm.dictionary
                ? _c(
                    "portlet-tab",
                    {
                      attrs: {
                        tabTitle: _vm.dictionary.widget.tabOpen,
                        isActive: true,
                        tabId: "tabOpenSubscriptions"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "page-description rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.page
                                .subscriptionsPageDescriptionOpen
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("SubscriptionTable", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          datatablesDictionary: _vm.dictionary.datatables,
                          id: "openSubscriptions"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dictionary
                ? _c(
                    "portlet-tab",
                    {
                      attrs: {
                        tabTitle: _vm.dictionary.widget.tabHistory,
                        tabId: "tabHistorySubscriptions"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "page-description rich-text-container",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$sanitize(
                              _vm.dictionary.page
                                .subscriptionsPageDescriptionHistory
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("SubscriptionHistoricalTable", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          datatablesDictionary: _vm.dictionary.datatables,
                          id: "historicalSubscriptions"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BeneficiaryAccountEdit", {
        attrs: {
          "data-model": _vm.accountDataEditModel,
          "common-dictionary": _vm.commonDictionary,
          dictionary: _vm.accountDictionary
        },
        on: {
          "submit-form": _vm.submit,
          "cancel-form": function($event) {
            return _vm.$emit("account-edit-form-cancelled")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
    <v-expansion-panels :class="['relationship-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="relationship-details-expansion-panel">
            <v-expansion-panel-header class="relationship-details-expansion-panel__header">
                <h3 class="h4"> {{ relationship.relatedBeneficiaryName }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <RelationsWithBeneficiaryContent :dictionary="dictionary" :relationship="relationship"  />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</div>
</template>

<script>

import RelationsWithBeneficiaryContent from "./relations-with-beneficiary-content.component.vue";

export default {
    components: {
        RelationsWithBeneficiaryContent
    },
    props: {
        relationship: Object,
        dictionary: Object,
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        },
    }
}
</script>
<template>
    <div class="choice-expanded-content">
      <div class="row">
          <div class="col-md-6 col-lg-4">
            <ChoiceDataLabelRow  :label="dictionary.table.choiceName" :value="choice.title" />  
            <ChoiceDataLabelRow  :label="dictionary.table.startDate" :value="choice.choiceStartDate" />
          </div>
          <div class="col-md-6 col-lg-4">
            <ChoiceDataLabelRow  :label="dictionary.table.endDate" :value="choice.choiceEndDate" />
            <ChoiceDataLabelRow  :label="dictionary.table.state" :value="choice.stateDescription" />  
          </div>
      </div>
    </div>
</template>

<script>
import ChoiceDataLabelRow from "./choice-data-label-row.component.vue";

export default {
  components: {
    ChoiceDataLabelRow,
  },
  props: {
    choice: Object,
    dictionary: Object,
    isTablet: Boolean,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-block single-line" }, [
      _vm.moneyTransferPosition
        ? _c("h1", [_vm._v(_vm._s(_vm.moneyTransferPosition.name))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        _vm.moneyTransferPosition && _vm.dictionary
          ? _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "portlet-container col-md-8 col-xl-9 p-0" },
                [
                  _c(
                    "div",
                    { staticClass: "row m-0" },
                    [
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-xl col-lg-12",
                            hideTitle: true
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("total-block", {
                                attrs: {
                                  title: _vm.dictionary.blocks.totalAvailable,
                                  text:
                                    _vm.moneyTransferPosition.availableFormatted
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-xl col-lg-12",
                            hideTitle: true
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("total-block", {
                                attrs: {
                                  title: _vm.dictionary.blocks.totalValue,
                                  text: _vm.getValueFormatted(
                                    _vm.moneyTransferPosition,
                                    _vm.toggles
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-xl col-lg-12",
                            hideTitle: true
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("security-price-block", {
                                attrs: {
                                  title: _vm.dictionary.blocks.lastPrice,
                                  endpoint: _vm.lastPriceEndpoint
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row m-0" },
                    [
                      _c("MoneyTransferPlaceOrder", {
                        attrs: {
                          moneyTransferPosition: _vm.moneyTransferPosition,
                          dictionary: _vm.dictionary
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-4 col-xl-3 p-0" },
                [
                  !_vm.toggles.hideLastTransactionsLinkBlock
                    ? _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "block",
                            title: _vm.dictionary.widgets.lastTransactions
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("last-transactions", {
                                attrs: {
                                  positionUsageId:
                                    _vm.moneyTransferPosition.positionUsageId
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "footerLink" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "portlet-title-see-all-link",
                                  attrs: {
                                    to: "/transactions",
                                    title:
                                      _vm.dictionary.widgets.seeAllTransactions
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.widgets.seeAllTransactions
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
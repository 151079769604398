<template>
  <dl class="order-details-list" v-if="dictionary">
    <OrderDataLabel v-if="!toggles.hideOrderState" :label="dictionary.modal.state" :value="instructionData.State" custom-class-data="font-weight-bold"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.reference" :value="instructionData.Reference"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.planName" :value="instructionData.PlanName"></OrderDataLabel>
    <ExitPositionNames :dictionary="dictionary" :instructionData="instructionData"></ExitPositionNames>
    <OrderDataLabel :label="dictionary.modal.instructionDate" :value="instructionData.SubmissionDate | toDateTime"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.scenarioName" :value="instructionData.ExitScenarioName"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.executionMoment" :value="instructionData.ExecutionMoment"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.ordered" :value="instructionData.NrOrdered"></OrderDataLabel>
    <OrderDataLabel :label="labelNr" :value="dataNr"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.securityPrice" :value="instructionData.SecurityPriceFormatted"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.grossAmount" :value="instructionData.GrossAmountFormatted"></OrderDataLabel>
    <OrderPayments :payments="instructionData.TaxPayments" :label="dictionary.modal.taxPayment"></OrderPayments>
    <OrderPayments :payments="instructionData.FeesPayments" :label="dictionary.modal.paymentFees"></OrderPayments>
    <OrderBrokerFees  :brokerFees="instructionData.BrokerFees" :brokerFeesFormated="instructionData.BrokerFeesFormatted" :label="dictionary.modal.brokerFees" ></OrderBrokerFees>
    <OrderDataLabel :label="dictionary.modal.proceeds" :value="instructionData.NetAmountFormatted" ></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.netAmount" :value="instructionData.ProceedsFormatted"></OrderDataLabel>
    <template v-if="instructionData.RequiresCurrencyConversion == true">
        <OrderDataLabel :label="dictionary.modal.conversionRate" :value="calHint"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.amountToDeliver" :value="instructionData.ConvertedDeliveryAmountFormatted"></OrderDataLabel>
    </template>
  </dl>
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
import OrderPayments from "./order-payments.component.vue";
import OrderBrokerFees from "./order-broker-fees.component.vue";
import ExitPositionNames from "./exit-position-names.component.vue";

export default {
  components: {
    OrderDataLabel,
    OrderPayments,
    OrderBrokerFees,
    ExitPositionNames
  },
  props: {
    instructionData: Object,
    dictionary: Object,
    toggles: Object
  },
  computed: {
    labelNr() {
      return `${this.dictionary.modal.nrExited} / ${this.dictionary.modal.nrInExit} / ${this.dictionary.modal.nrCancelled}`;
    },
    dataNr() {
      return `${this.instructionData.NrExitedFormatted} / ${this.instructionData.NrInExitFormatted} / ${this.instructionData.NrCancelledFormatted}`;
    },
    calHint() {
        return `${this.instructionData.NetAmountFormatted} x ${this.instructionData.ConversionRate}  ${this.dictionary.modal.conversionRateDivider} ${this.$options.filters.toDateTime(this.instructionData.ConversionRateDate)}`;
    },
  },
};
</script>
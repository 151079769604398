<template>
    <div class="d-sm-flex justify-content-between">
        <p v-if="isMobile" class="font-weight-bold subscribe-details-row-title">{{ label }}</p>
        <p v-else class="subscribe-details-row-title" >{{ label }}</p>
        <p>{{ value }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            value: String | Number,
            isMobile: Boolean 
        }
    }
</script>
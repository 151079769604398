<template>
    <div>
        <div v-if="!isLoading && dictionary && viewModel" class="row m-0">
            <conditional-portlet-layout v-if="!showOverview" :portlet-class="portletLayoutClasses" div-class="col" :condition="!viewModel.isModal" :hideTitle="true" :allow-collapse="false">
                <template slot="body">
                    <QuestionnaireDetail v-if="currentQuestionnaire"
                                         :dictionary="dictionary"
                                         :currentQuestionnaire="currentQuestionnaire"
                                         :questionnaireCount="questionnaireCount"
                                         :questionnaireIndex="questionnaireIndex"
                                         :showCorrectAnswer="showCorrectAnswer"
                                         :error="error"
                                         :isLoading="isLoading"
                                         :returnUrl="returnUrl"
                                         :isModal="viewModel.isModal"
                                         @submit-questionnaire="handleSubmitQuestionnaire" />
                    <loading-spinner :active="isSubmitting"></loading-spinner>
                </template>
            </conditional-portlet-layout>
            <conditional-portlet-layout v-else :portlet-class="portletLayoutClasses" div-class="col" :condition="!viewModel.isModal" :hideTitle="true" :allow-collapse="false">
                <template slot="body">
                    <QuestionnaireOverview v-if="questionnaireOverview"
                                           :dictionary="dictionary"
                                           :questionnaireCount="questionnaireCount"
                                           :questionnaireIndex="questionnaireIndex"
                                           :questionnaireOverview="questionnaireOverview"
                                           :overviewText="overviewText"
                                           :returnUrl="returnUrl"
                                           :isModal="viewModel.isModal"
                                           :correctAnswerMode="correctAnswerMode"
                                           :canEdit="currentQuestionnaire.canEdit"
                                           @next-questionnaire="nextQuestionnaire"
                                           @update-data="getData(true)" />
                    <loading-spinner :active="isSubmitting"></loading-spinner>
                </template>
            </conditional-portlet-layout>
        </div>
        <component-spinner :spin="isLoading"></component-spinner>
    </div>
</template>

<script>
    import { EventBus } from "Shared/event-bus";
    import { post, get } from "api";
    import QuestionnaireOverview from "./questionnaire-overview.component.vue";
    import QuestionnaireDetail from "./questionnaire-detail.component.vue";
    import ConditionalPortletLayout from "Shared/Components/Vue/Portlet/conditional-portlet.component.vue";

    export default {
        components: {
            QuestionnaireOverview,
            QuestionnaireDetail,
            ConditionalPortletLayout
        },
        props: {
            dictionary: Object,
            viewModel: Object
        },
        data() {
            return {
                questionnaireIndex: -1,
                currentQuestionnaire: null,
                questionnaireOverview: null,
                questionnaireCount: 0,
                showOverview: false,
                error: '',
                questionIndex: -1,
                overviewText: '',
                isLoading: true,
                portletLayoutClasses: 'col-lg-6',
                isSubmitting: false,
                returnUrl: '/',
                questionnaires: []
            };
        },
        mounted() {
            this.getData(false);
        },
        methods: {
            getData(isUpdating) {
                this.isSubmitting = isUpdating;

                let params = this.$route.params;
                if (params.questionnaireId) {
                    get(`/Questionnaire/GetQuestionnaire?questionnaireId=${params.questionnaireId}`,
                        (response) => {
                            this.questionnaires.push(response.data);
                            this.returnUrl = params.returnUrl ? params.returnUrl : this.returnUrl;
                            this.prepareData();
                        },
                        this,
                        () => { 
                            this.isLoading = false;
                            this.isSubmitting  = false;
                        }
                    );
                }
                else {
                    get("/Questionnaire/GetMandatoryQuestionnaires",
                        (response) => {
                            this.questionnaires = response.data;
                            if(!!this.questionnaires?.length) {
                                this.prepareData();
                            } else {
                                EventBus.$emit("event-reload-layout", this.returnUrl);
                            }
                        },
                        this,
                        () => { 
                            this.isLoading = false;
                            this.isSubmitting  = false;
                        }
                    );
                }
            },
            prepareData() {
                this.questionnaireOverview = null;
                this.showOverview = false;

                this.questionnaireCount = this.questionnaires.length;
                this.questionnaireIndex = 0;
                if (this.questionnaireCount == 0) {
                    EventBus.$emit("event-reload-layout", this.returnUrl);
                }
            },
            nextQuestionnaire() {
                this.questionnaireIndex++;
            },
            prevQuestionnaire() {
                this.questionnaireIndex--;
            },
            handleSubmitQuestionnaire(payload) {
                this.questionnaireOverview = null;
                this.isSubmitting = true;
                post(
                    "/Questionnaire/SubmitQuestionnaire",
                    payload,
                    (response) => {
                        this.questionnaireOverview = response.data;
                        this.showOverview = true;
                        this.overviewText = this.dictionary.labels.submitConfirmation;
                    },
                    this,
                    () => { this.isSubmitting = false; },
                    (error) => {
                        this.error = error.response.data;
                    }
                );
            },
            nextQuestion() {
                this.questionIndex++;
            },

            prevQuestion() {
                this.questionIndex--;
            }
        },
        computed: {
            showCorrectAnswer() {
                if (this.currentQuestionnaire && this.currentQuestionnaire.questions) {
                    return this.currentQuestionnaire.questions.filter(
                        e => (e.givenAnswer == null && e.givenOpenAnswer == null && e.givenChoiceId == null)
                    ).length == 0 && this.currentQuestionnaire.canEdit;
                }
                return false
            },
            correctAnswerMode() {
                return this.currentQuestionnaire.correctAnswerMode
            }
        },
        watch: {
            questionnaireIndex(index) {
                this.questionnaireOverview = null;
                this.showOverview = false;
                if (index !== -1 && this.questionnaireCount > 0) {
                    this.currentQuestionnaire = this.questionnaires[index];
                    this.questionIndex = 0;
                }
                if (this.currentQuestionnaire?.canEdit) {
                    this.questionnaireOverview = this.currentQuestionnaire;
                    this.overviewText = this.dictionary.labels.submitConfirmation;
                } else {
                    this.questionnaireOverview = this.currentQuestionnaire;
                    this.showOverview = true;
                    this.overviewText = this.dictionary.labels.questionnaireCompleted;
                }
            }
        }
    };
</script>
<template>
    <div>
        <input-element v-if="moduleBounderie.isInstructionInMoney" name="AmountOrdered" type="text" :label="dictionary.form.amountOrdered" :value="model.amountOrdered" @input="$emit('amountOrdered', $event)" :validationsList="amountValidations"></input-element>
        <input-element v-else name="Quantity" :label="dictionary.form.quantity" type="text" :value="model.nrOrdered" @input="$emit('nrOrdered', $event)" :validationsList="quantityValidations"></input-element>
    </div>
</template>

<script>
    export default {
        props: {
            validations: Object,
            dictionary: Object,
            model: Object,
            moduleBounderie: Object
        },
        computed: {
            quantityValidations() {
                return [
                    {
                        id: 1,
                        error: this.validations.nrOrdered.$error,
                        type: this.validations.nrOrdered.required,
                        message: this.dictionary.validation.quantityRequired
                    },
                    {
                        id: 2,
                        error: this.validations.nrOrdered.$error,
                        type: this.validations.nrOrdered.mustBeDecimal,
                        message: this.dictionary.validation.quantityInvalid
                    },
                    {
                        id: 3,
                        error: this.validations.nrOrdered.$error,
                        type: this.validations.nrOrdered.mustBeModuloMinimum,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.transferModuloMinimum,
                            {
                                TransferModulo: this.$setDecimalSeparatorBySettings(this.moduleBounderie.transferModulo),
                                TransferMinimum: this.$setDecimalSeparatorBySettings(this.moduleBounderie.transferMinimum),
                            }
                        )
                    }
                ];
            },
             amountValidations() {
                return [
                         {
                        id: 1,
                        error: this.validations.amountOrdered.$error,
                        type: this.validations.amountOrdered.required,
                        message: this.dictionary.validation.amountRequired
                    },
                    {
                        id: 2,
                        error: this.validations.amountOrdered.$error,
                        type: this.validations.amountOrdered.mustBeDecimal,
                        message: this.dictionary.validation.amountInvalid
                    },
                    {
                        id: 3,
                        error: this.validations.amountOrdered.$error,
                        type: this.validations.amountOrdered.mustBeModuloMinimum,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.transferModuloMinimum,
                            {
                                TransferModulo: this.$setDecimalSeparatorBySettings(this.moduleBounderie.transferModulo),
                                TransferMinimum: this.$setDecimalSeparatorBySettings(this.moduleBounderie.transferMinimum),
                            }
                        )
                    }
                ]
             }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vesting-award-details vesting-expanded-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.name,
                value: _vm.details.purchase
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.date,
                value: _vm.details.date
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.state,
                value: _vm.details.state
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.amount,
                value: _vm.details.amount
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.price,
                value: _vm.details.price
              }
            }),
            _vm._v(" "),
            _c("VestingDetailsContentRow", {
              attrs: {
                label: _vm.dictionary.details.baseAmount,
                value: _vm.details.baseAmount
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
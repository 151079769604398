import ErrorContainer from "./error-container.component.vue";

export const errorRoutes = {
    path: '/error',
    name: 'error',
    component: ErrorContainer,
    children: [
        {
            path: '*',
            redirect: '/error'
        }
    ]
};
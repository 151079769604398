var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-container d-md-flex justify-content-end" },
    [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn secondaryButton",
            on: {
              click: function($event) {
                return _vm.decline()
              }
            }
          },
          [_vm._v(_vm._s(_vm.dictionary.widget.btnDecline))]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn primaryButton",
            attrs: {
              disabled: !_vm.isDisclaimersAccepted,
              title: _vm.confirmButtonTitle
            },
            on: {
              click: function($event) {
                return _vm.accept()
              }
            }
          },
          [_vm._v(_vm._s(_vm.dictionary.widget.btnConfirm))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="dictionary" id="relationship-container">
        <RelationsOfBeneficiary class="evaluation-body" :widgetTitle="dictionary.widgets.relationsOfBeneficiary" :dictionary="dictionary.relationships" :datatables-dictionary="datatablesDictionary" :toggles="toggles"/>
        <RelationsWithBeneficiary class="evaluation-body" :widgetTitle="dictionary.widgets.relationsWithBeneficiary"  :dictionary="dictionary.relationships" :datatables-dictionary="datatablesDictionary" :toggles="toggles"/>        
    </div> 
</template>

<script>
import RelationsWithBeneficiary from "./relations-with-beneficiary.component.vue";
import RelationsOfBeneficiary from "./relations-of-beneficiary.component.vue";

export default {
  components: {
    RelationsWithBeneficiary,
    RelationsOfBeneficiary
  },
  props: {
    dictionary: Object,
    datatablesDictionary: Object,
    toggles: Object
  }
};
</script>
<template>
    <div class="vestings-state-info">
        <p class="vestings-row"><i class="far fa-clock" aria-hidden="true"></i> {{ dictionary.labels.futureVesting }}</p>
        <p class="vestings-row"><i class="far fa-check-circle" aria-hidden="true"></i> {{ dictionary.labels.alreadyVested }}</p>
    </div>
</template>

<script>
export default {
    props: {
        dictionary: Object
    }
}
</script>
<template>
    <div class="text-right force-nowrap">
        <span>{{ header.text }} </span><i :id="spanId" v-if="!toggles.hideReferencePriceTooltipColumn" class="far fa-info-circle popover-info" aria-hidden="true"></i>
        <div>
            <b-popover :target="spanId" triggers="hover" placement="top" boundary="window">
                <span class="rich-text-container" v-html="$sanitize(dictionary.block.portfolioReferencePriceColumnTooltip)"> </span>
            </b-popover>
        </div>    
    </div>
</template>

<script>

import { v4 as uuid } from 'uuid';

export default {
    props: {
        header: Object,
        toggles: Object,
        dictionary: Object
    },
    computed: {
        spanId() {
            return `reference-price-popover-target_${uuid()}`;
        }
    }
}
</script>
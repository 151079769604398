<template>
    <div>
        <div class="title-block single-line">
            <h1 v-if="dictionary">{{ dictionary.page.title }}</h1>
        </div>

        <div class="portlets-wrapper inbox inbox-container">
            <component-spinner :spin="isLoading"></component-spinner>
            <MessagesInbox v-if="!isLoading" :model="model" :datatables-dictionary="datatablesDictionary" :dictionary="dictionary"></MessagesInbox>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import MessagesInbox from './messages-inbox.component.vue';

    export default {
        components: {
            MessagesInbox
        },
        data() {
            return {
                isLoading: true,
                datatablesDictionary: null,
                model: {},
                dictionary: null
            }
        },
        beforeCreate() {
            getAll([
                "/Inbox/Model",
                '/Inbox/DatatablesDictionary',
                '/Inbox/Dictionary'
            ], responses => {
                this.model = responses[0].data;
                this.datatablesDictionary = responses[1].data;
                this.dictionary = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false
            }, this);
        }
    };
</script>

import { beneficiaryTabsViewRightsMixin } from "Features/Beneficiaries/Assets/Vue/Main/mixins/beneficiary-tabs-view-rights.mixin.js";

export const personalDataDropdownMixin = {
    mixins: [beneficiaryTabsViewRightsMixin],
    computed: {
        items() {
            return [
                { name: 'account', visible: this.mayViewAccountData, text: this.dictionary.beneficiaryTabsDictionary.account, icon: 'fa-file-user', relativeUrl: '/beneficiaries#account' },
                { name: 'financial', visible: this.mayViewFinancialData, text: this.dictionary.beneficiaryTabsDictionary.financial, icon: 'fa-coins', relativeUrl: '/beneficiaries#financial' },
                { name: 'evaluation', visible: this.mayViewEvaluationData, text: this.dictionary.beneficiaryTabsDictionary.evaluation, icon: 'fa-book', relativeUrl: '/beneficiaries#evaluation' },
                { name: 'company', visible: this.mayViewCompanyData, text: this.dictionary.beneficiaryTabsDictionary.company, icon: 'fa-house', relativeUrl: '/beneficiaries#company' }
            ];
        },
        filteredItems() {
            return this.items.filter(i => i.visible);
        },
        beneficiaryToggles() {
            return this.toggles.beneficiaryToggles;
        },
        isLoaded() {
            return this.dictionary.beneficiaryTabsDictionary &&  this.toggles.beneficiaryToggles && this.accountDataModel && this.beneficiaryBoundariesDataModel;
        }
    }
}
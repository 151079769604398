var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _c("input-element", {
              attrs: {
                type: "text",
                name: "legalEntityIdentifier",
                isMandatory: true,
                label: _vm.dictionary.labels.lei,
                readonly: !_vm.boundaries.mayEditLegalEntityIdentifier,
                validationsList:
                  _vm.legalEntityValidations.legalEntityIdentifier
              },
              model: {
                value: _vm.requiredInfoData.legalEntityIdentifier,
                callback: function($$v) {
                  _vm.$set(_vm.requiredInfoData, "legalEntityIdentifier", $$v)
                },
                expression: "requiredInfoData.legalEntityIdentifier"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("RequiredInfoMifidButtons", {
        attrs: { dictionary: _vm.dictionary, errors: _vm.errors },
        on: {
          skip: function($event) {
            return _vm.$emit("skip")
          },
          submit: function($event) {
            return _vm.submit()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
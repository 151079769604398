import { get } from 'api';

const searchDelay = 500;

export const vueDataTableMixin = {
    data() {
        return {
            isLoading: false,
            search: '',
            sortBy: '',
            sortDirection: '',
            isSearchDirty: false,
            searchTimeoutId: null,
            queryStringExtraParams: null
        }
    },
    watch: {
        'options.page': {
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.loadItems();
                }
            },
            deep: true,
            immediate: true
        },
        'options.itemsPerPage': {
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.options.page = 1;
                    this.loadItems();
                }
            },
            deep: true
        },
        'options.sortBy': {
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.loadItems();
                }
            },
            deep: true
        },
        search: function () {
            this.isSearchDirty = true;

            this.cancelScheduledLoadItems();
            this.searchTimeoutId = setTimeout(() => {
                this.loadItems();
            }, searchDelay)
        }
    },
    methods: {
        cancelScheduledLoadItems() {
            if (this.searchTimeoutId) {
                clearTimeout(this.searchTimeoutId);
                delete this.searchTimeoutId
            }
        },
        onSortChange() {
            if (this.options.sortBy[0] === this.sortBy) {
                this.options.sortDesc[0] = !this.options.sortDesc[0];
            }
            else {
                this.options.sortBy[0] = this.sortBy;
                this.options.sortDesc[0] = false;
            }

            this.loadItems();
        },
        getSortDirection() {
            return this.options.sortDesc.length ? this.options.sortDesc[0] ? "desc" : "asc" : "none";
        },
        loadItems() {
            this.isLoading = true;

            if (!this.options.sortBy.length && this.sortBy) {
                this.options.sortBy[0] = this.sortBy;
                this.options.sortDesc[0] = !this.sortDirection;
            }

            this.sortBy = this.options.sortBy[0];
            this.sortDirection = this.getSortDirection();
            var sortBy = this.options.sortBy.length ? this.options.sortBy[0] : "";

            if (this.requestType && this.requestType.toLowerCase() === "post") {
                this.post(this.postUrl, sortBy, this.sortDirection, this.search, this.options.page, this.options.itemsPerPage)
            }
            else {

                let url = `${this.getUrl}` +
                    `?sortBy=${sortBy ?? ""}` +
                    `&sortDirection=${this.sortDirection}` +
                    `&searchValue=${this.search}` +
                    `&page=${this.options.page}` +
                    `&perPage=${this.options.itemsPerPage}`;

                if (this.queryStringExtraParams) {
                    const keys = Object.keys(this.queryStringExtraParams);
                    keys.forEach((key) => {
                        url = url + `&${key}=${this.queryStringExtraParams[key]}`;
                    });
                }

                get(url,
                    (response) => {
                        this.rows = response.data.rows;
                        this.totalRecords = response.data.totalRecords;
                    }, this, () => {
                        this.isLoading = false;
                    });
            }
        },
        footerPageText(pageSeparatorText) {
            if (!this.totalRecords || this.totalRecords == 0 || !pageSeparatorText) {
                return "-"
            }

            var endOfRange = this.options.itemsPerPage * this.options.page;
            if (this.totalRecords < endOfRange) {
                endOfRange = this.totalRecords;
            }

            var startOfRange = (this.options.page - 1) * this.options.itemsPerPage + 1;

            return `${startOfRange}-${endOfRange} ${pageSeparatorText} ${this.totalRecords}`;
        },
    }
}
<template>
    <div class="mt-2 d-md-flex justify-content-md-between button-container summary-buttons-container">
        <button class="btn secondaryButton" type="button" @click="$emit('skip')">{{ dictionary.buttons.skip }}</button>
        <button v-if="isSubmitButton" class="btn primaryButton" type="button" @click="submit()">{{ dictionary.buttons.submit }}</button>
        <button v-if="isEditButton" class="btn primaryButton" type="button" @click="edit();">{{ dictionary.buttons.edit }}</button>
    </div>
</template>

<script>

    export default {
        props: {
            dictionary: Object,
            currentStep: {
                type: Number,
                default: 0
            },
            steps: {
                type: Number,
                default: 0
            },
            errors: Array
        },
        data() {
            return {
                canBeEdited: true
            }
        },
        computed: {
            isSubmitButton() {
                return ((!this.currentStep && !this.steps) || (this.currentStep > this.steps)) && !this.isEditButton;
            },
            isEditButton() {
                return ((this.currentStep > this.steps)) && this.errors.length && this.canBeEdited;
            }
        },
        methods: {
            submit() {
                this.canBeEdited = true;
                this.$emit('submit');
            },
            edit() {
                this.canBeEdited = false;
                this.$emit('edit');
            }
        }
    };
</script>
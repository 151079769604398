<template>
    <div>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewFirstName" :value="personalDataModel.firstName" :label="dictionary.firstName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewFirstNames" :value="personalDataModel.firstNames" :label="dictionary.firstNames"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewInitials" :value="personalDataModel.initials" :label="dictionary.initials"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewLastName" :value="personalDataModel.lastName" :label="dictionary.lastName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewMaidenName" :value="personalDataModel.maidenName" :label="dictionary.maidenName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewGender" :value="personalDataModel.gender" :label="dictionary.gender"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewTitles" :value="personalDataModel.titles" :label="dictionary.titles"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewTitleOfAddress" :value="personalDataModel.titleOfAddress" :label="dictionary.titleOfAddress"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewHonoraryName" :value="personalDataModel.honoraryName" :label="dictionary.honoraryName"></beneficiary-data-label>
        <beneficiary-data-label v-if="beneficiaryBoundariesPersonalInfo.mayViewDateOfBirth" :value="personalDataModel.dateOfBirth | toFullYearDate" :label="dictionary.dateOfBirth" ></beneficiary-data-label>
    </div>
</template>

<script>

import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
    components: {
        BeneficiaryDataLabel,
    },
    props: { 
        personalDataModel : Object, 
        dictionary: Object,
        beneficiaryBoundariesPersonalInfo:Object
    },
}
</script>
<template>
    <component-spinner v-if="isLoading" :spin="isLoading"></component-spinner>
    <form id="required-documents-form" v-else-if="dictionary && boundaries" class="required-info-box">
        <RequiredInfoMifidTimelineCompany v-if="boundaries.isCompany" :dictionary="dictionary" :errors="errors"
                                          :requiredInfo="requiredInfo" :boundaries="boundaries" @submit="submit()" @skip="skip()" />
        <RequiredInfoMifidTimelineStandard v-else :dictionary="dictionary" :errors="errors"
                                           :requiredInfo="requiredInfo" :boundaries="boundaries" :countries="countries" @submit="submit()" @skip="skip()" />
        <RequiredInfoSkipModal :dictionary="dictionary" :isModalVisible="isSkipModalVisible"
                               @close-modal="cancelSkipModal()" @submit-cancel="submitSkipModal()" />
        <loading-spinner :active="isSubmitting"></loading-spinner>
    </form>
</template>

<script>
    import { get, getAll, post } from "api";
    import RequiredInfoMifidTimelineStandard from "./required-info-mifid-timeline-standard.component.vue";
    import RequiredInfoMifidTimelineCompany from "./required-info-mifid-timeline-company.component.vue";
    import RequiredInfoSkipModal from "./required-info-skip-modal.component.vue";
    import { EventBus } from "Shared/event-bus";


    export default {
        components: {
            RequiredInfoMifidTimelineStandard,
            RequiredInfoMifidTimelineCompany,
            RequiredInfoSkipModal
        },
        data() {
            return {
                requiredInfo: null,
                boundaries: null,
                countries: null,
                dictionary: {},
                isLoading: true,
                errors: [],
                isSkipModalVisible: false,
                isSubmitting: false
            };
        },
        beforeCreate() {
            this.$emit('set-header', {header: null, subHeader: null});

            getAll(
                ["/RequiredInfo/MiFidDictionary", "/RequiredInfo/MiFidInfoBoundaries"],
                responses => {
                    this.dictionary = responses[0].data;
                    this.boundaries = responses[1].data;
                    this.requiredInfo = this.boundaries.defaultValues;

                    if (!this.boundaries.isCompany) {
                        get(
                            "/Common/GetCountries",
                            response => {
                                this.countries = response.data;
                                this.removeUnknownFromCountries();
                                this.isLoading = false;
                            },
                            this,
                            () => { }
                        );
                    }
                    else {
                        this.isLoading = false;
                    }
                    
                    this.$setPageTitle(this.dictionary.page.title);
                    this.$emit('set-header', {header: this.dictionary.page.title});

                    this.removeUnknownFromDocumentTypes();
                },
                this,
                () => { }
            );
        },
        methods: {
            skip() {
                if (!this.isSkipModalVisible) {
                    this.isSkipModalVisible = true;
                }
            },
            cancelSkipModal() {
                this.isSkipModalVisible = false;
            },
            submitSkipModal() {
                this.isSubmitting = true;

                get("/RequiredInfo/SkipMiFidInfo",
                    (response) => {
                        EventBus.$emit("event-reload-layout", '/dashboard');
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                        this.isSkipModalVisible = false;
                    },
                    err => {
                        this.handleErrors(err);
                    }
                );
            },
            submit() {
                this.isSubmitting = true;
                this.errors = [];

                post(
                    "/RequiredInfo/MiFidInfoSummary",
                    this.requiredInfo,
                    response => {
                        EventBus.$emit("event-reload-layout", '/dashboard');
                    },
                    this,
                    () => { this.isSubmitting = false; },
                    err => {
                        this.handleErrors(err);
                    }
                );
            },
            removeUnknownFromCountries() {
                if (this.countries) {
                    this.countries = this.countries.filter(
                        (country) => country.externalIdentifier !== "ZZ"
                    );

                    if (!this.countries.some(x => x.id == this.requiredInfo.legalDocumentCountryId)) {
                        this.requiredInfo.legalDocumentCountryId = null;
                    }
                }
            },
            removeUnknownFromDocumentTypes() {
                if (this.boundaries.legalDocumentTypes) {
                    this.boundaries.legalDocumentTypes = this.boundaries.legalDocumentTypes.filter(
                        (documentType) => documentType.value !== "ldtUnknown"
                    );

                    if (this.requiredInfo.legalDocumentType
                        && !this.boundaries.legalDocumentTypes.some(x => x.value == this.requiredInfo.legalDocumentType.value)) {
                        this.requiredInfo.legalDocumentType = {};
                    }
                }
            },
            handleErrors(err) {
                if (err.response.status === this.$HttpStatusCodes.BAD_REQUEST) {
                    //errors from the api will be merged into one string with \r\n
                    this.errors = err.response.data
                        .split("\r\n")
                        .filter(x => x.length > 0);
                } else {
                    this.errors = [context.dictionary.common.errorUnknownException];
                }
                if (window) {
                    //move to error list
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            }
        }
    };
</script>
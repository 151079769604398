import PaymentsContainer from "./payments-container.component.vue";

export const paymentsRoutes =
{
    path: '/payments',
    component: PaymentsContainer,
    children: [
        {
            path: '*',
            redirect: '/payments'
        }
    ]
}

import DisclaimerContainer from "./disclaimer-container.component.vue";

export const disclaimerRoutes = {
    path: '/disclaimer',
    alias: '/disclaimer/index',
    name: 'disclaimer',
    component: DisclaimerContainer,
    children: [
        {
            path: '*',
            redirect: '/disclaimer'
        }
    ]
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        [
          _c("div", { staticClass: "title-block" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.dictionary.page.titleExecuteContribution))
            ])
          ]),
          _vm._v(" "),
          _vm.isLoading
            ? _c("component-spinner", { attrs: { spin: _vm.isLoading } })
            : _vm._e(),
          _vm._v(" "),
          _vm.contributionsToExecute
            ? _c("div", { staticClass: "portlets-wrapper" }, [
                _c("div", { staticClass: "portlet-container" }, [
                  _c(
                    "div",
                    { staticClass: "row m-0" },
                    [
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-lg-6 col-xl-6",
                            title:
                              _vm.dictionary.widget.titleExecuteContribution
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              !_vm.contributionsToExecute.canReserve
                                ? _c(
                                    "alert-component",
                                    { attrs: { variant: "error" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary.table
                                            .labelInsufficientFund
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.error
                                ? _c(
                                    "alert-component",
                                    { attrs: { variant: "error" } },
                                    [_vm._v(_vm._s(_vm.error))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loading-spinner", {
                                attrs: { active: _vm.isSubmitting }
                              }),
                              _vm._v(" "),
                              _c("SubscriptionConfirmationDetails", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  participationSubscription:
                                    _vm.contributionsToExecute
                                      .participationSubscription
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "button-container d-md-flex justify-content-end mt-3"
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn secondaryButton",
                                        on: {
                                          click: function($event) {
                                            return _vm.back()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary.widget.btnGoBack
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm.contributionsToExecute.canReserve
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn primaryButton",
                                            on: {
                                              click: function($event) {
                                                return _vm.confirm()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dictionary.widget.btnConfirm
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "accessibilityMainFocus",
      staticClass: "questionnaire-card-container"
    },
    [
      _c("div", { staticClass: "questionnaire-card-header" }, [
        _c("h3", { staticClass: "primary-color" }, [
          _vm._v(
            _vm._s(
              _vm.dictionary.labels.overviewTitle +
                " - " +
                _vm.questionnaireOverview.questionnaireName
            )
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _vm._v(_vm._s(_vm.overviewText))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "questionnaire-card-body" },
        _vm._l(_vm.questionnaireOverview.questions, function(
          currentQuestion,
          index
        ) {
          return _c("div", { key: index, staticClass: "question-container" }, [
            _c("p", [
              _c("span", { staticClass: "font-weight-bold primary-color" }, [
                _vm._v(_vm._s(_vm.dictionary.labels.question) + ": ")
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "(" +
                    _vm._s(index + 1) +
                    "/" +
                    _vm._s(_vm.questionnaireOverview.questions.length) +
                    ")"
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "card-text rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(currentQuestion.questionDescription)
                )
              }
            }),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "font-weight-bold primary-color" }, [
                _vm._v(_vm._s(_vm.dictionary.labels.answer) + ": ")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatAnswerType")(
                      currentQuestion,
                      _vm.dictionary,
                      _vm.$QuestionType
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _vm.invalidAnswer(currentQuestion)
              ? _c(
                  "p",
                  [
                    currentQuestion.questionType ==
                    _vm.$QuestionType.StandardChoices
                      ? [
                          _c(
                            "span",
                            { staticClass: "text-danger font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("validateAnswer")(
                                    currentQuestion.givenAnswer,
                                    currentQuestion,
                                    _vm.correctAnswerMode,
                                    _vm.canEdit,
                                    _vm.dictionary,
                                    _vm.$QuestionType
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      : currentQuestion.questionType ==
                        _vm.$QuestionType.DefinedChoices
                      ? [
                          _c(
                            "span",
                            { staticClass: "text-danger font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("validateAnswer")(
                                    currentQuestion.givenChoiceId,
                                    currentQuestion,
                                    _vm.correctAnswerMode,
                                    _vm.canEdit,
                                    _vm.dictionary,
                                    _vm.$QuestionType
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-md-flex justify-content-md-end" }, [
        _c(
          "button",
          {
            staticClass: "btn primaryButton",
            on: { click: _vm.handleCloseQuestionnaireOverview }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.dictionary.labels.btnOk) +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* 
    Description:    Accurate countdown timer that corrects for thread interuptions

    Usage:
                    <script>
                        // get an instance of the timer object passing in a duration of 10 seconds
                        var timer = new Timer({ duration: 10 });

                        // Event to be fired at the start of the countdown
                        timer.on('start', function(position){
                            console.log('Timer Started');
                        });

                        // Event to be fired when the countdown finishes 
                        timer.on('end', function(position){
                            console.log('Timer stopped');
                        });

                        // Event to be fired each second the timer counts down
                        timer.on('tick', function(position){
                            vm.time(position);
                        });

                        // Event to be fired when there's 5 seconds remaining
                        timer.on(5, function(){
                            console.log("Half way point event!");
                        });

                        timer.start();

                        // or start timer for specific duration
                        timer.start(50);
                    </script>
*/

// Counts down allowing the user to trigger events at given times
export default class Timer {
    constructor(config) {
        if (config) {
            this.#duration = config.duration;
        }
    }

    #timer;
    #timerInterval = 1000;
    #events = [];
    #duration;
    #position;
    #startTime;
    #elapsedTime;
    #running = false;

    // start timer
    // newDuration is optional parameter to set the timer duration to a specific value.
    // if newDuration is not passed in the call to the method, then the default duration value is used.
    start(newDuration) {
        if (this.#running == false) {

            this.#running = true;
            this.#position = this.#duration;

            if (typeof newDuration !== "undefined") {
                this.#position = newDuration;
            }
            this.#elapsedTime = 0;
            this.#startTime = new Date().getTime();
            window.clearTimeout(this.#timer);
            this.#countdownInstance();
        }
    }

    stop() {
        if (this.#running == true) {
            window.clearTimeout(this.#timer);
            this.#running = false;
        }
    }

    on(position, func) {

        position = this.#formatPosition(position);

        if (position != null) {
            if (position < 0 || position > this.#duration) {
                throw "invalid time position! time must be more than 0 and less than " + this.#duration;
            }
        }

        this.#events.push({ position: position, func: func });
    }

    getCurrentPosition() {
        return this.#position;
    }

    #countdownInstance() {
        if (this.#position < 0) {
            this.stop();
        } else {
            var diff = (new Date().getTime() - this.#startTime) - this.#elapsedTime;

            this.#timer = window.setTimeout(() => this.#countdownInstance(), (this.#timerInterval - diff));
            this.#elapsedTime += this.#timerInterval;

            var events = this.#readEvents(this.#position);

            if (events.length > 0) {

                for (var event in events) {

                    if (events[event].func !== undefined) {

                        events[event].func(this.#position);
                    }
                }
            }

            this.#position--;
        }
    }

    #readEvents(position) {

        var events = [];

        for (let i = 0; i < this.#events.length; i++) {

            var pos = this.#events[i].position;

            if (pos === position || pos === null) {
                events.push(this.#events[i]);
            }
        }
        return events;
    }

    #formatPosition(position) {
        let formattedPosition;

        if (typeof position === "string") {
            switch (position.toLowerCase()) {
                case 'start':
                    formattedPosition = this.#duration;
                    break;
                case 'end':
                    formattedPosition = 0;
                    break;
                case 'tick':
                    formattedPosition = null;
                    break;
                default:
                    throw "Invalid position passed to the timers create event method"
            }
        } else {
            formattedPosition = position;
        }

        return formattedPosition;
    }
}

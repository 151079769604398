<template>
  <div class="default-account-card-body">  
      <div class="default-account-description">
        <p>{{dictionary.removeAccountModal.description}}</p>
      </div>
      <div class="py-1">
        <FormErrorAlert :errors="errors"></FormErrorAlert>
      </div> 
      <div class="row">
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="accountData.iban" 
            :display-value="accountData.accountNumber" 
            :label="dictionary.removeAccountModal.accountNumber" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="accountData.bankName" 
            :label="dictionary.removeAccountModal.bankName" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="accountData.currency.name" 
            :label="dictionary.removeAccountModal.currency" />
        <BeneficiaryDataLabel 
            class="col-md-6" 
            :value="accountData.country.name" 
            :label="dictionary.removeAccountModal.country" />
      </div>      
  </div>
</template>

<script>
import BeneficiaryDataLabel from "./../data-label.component.vue";
import FormErrorAlert from "Shared/Components/Vue/Alert/form-error-alert.component.vue";

export default {
  components: {
    BeneficiaryDataLabel,
    FormErrorAlert,
  },
  props: {
    dictionary: Object,
    accountData: Object,
    errors: Array,
  }
};
</script>
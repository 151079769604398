<template>
    <div class="row">
        <div class="col-12">
            <p :class="listStyle ? 'cookie-policy-row__title_list' : 'cookie-policy-row__title'">{{ title }}</p>
            <div v-if="richText" class="rich-text-container" v-html="$sanitize(text)"></div>
            <p v-else>{{ text }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            text: String,
            richText: {
                type: Boolean,
                default: false
            },
            listStyle: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
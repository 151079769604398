<template>
    <div class="button-container d-md-flex justify-content-end">
        <div>
            <button class="btn secondaryButton" @click="decline()">{{ dictionary.widget.btnDecline }}</button>
        </div>
        <div>
            <button class="btn primaryButton" :disabled="!isDisclaimersAccepted" :title="confirmButtonTitle" @click="accept()">{{ dictionary.widget.btnConfirm }}</button>
        </div>
    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            disclaimers: Array,
            dictionary: Object,
            presentationMode: Object,
        },
        computed: {
            isDisclaimersAccepted() {
                return !this.disclaimers.filter(d => !d.confirmed).length;
            },
            confirmButtonTitle() {
                return this.isDisclaimersAccepted ? "" : "please accept disclaimers";
            },
            primaryButtonText() {
                return this.presentationMode.value == 'spmAcceptation' ? this.dictionary.table.presentationModeAccept : this.dictionary.table.presentationModeSubscribe;
            }
        },
        methods: {
            accept() {
                this.$emit('accept');
            },
            decline() {
                this.$emit('decline');
            }
        }
    };
</script>



<template>
<div>
    <v-data-table
        class="table-striped"
        show-expand
        single-expand
        :no-data-text="dictionary.table.noRecordFound"
        item-key="couponParticipationDeliveryId"
        :hide-default-header="isMobile"
        :headers="computedHeaders"
        :items="rows"
        :loading="isLoading"
        :server-items-length="totalRecords"
        :search="search"
        :items-per-page="options.itemsPerPage"
        @page-count="options.pageCount = $event;"
        :options.sync="options"
        hide-default-footer
        >   
        <!-- Search input at top --->
        <template v-slot:top>
            <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
        </template>   

        <!-- Expanded item template (coupon details) --->
        <template v-slot:expanded-item="{ headers, item }">
            <td class="expanded-item-td" :colspan="headers.length">
                <CouponTableExpandedItem :dictionary="dictionary" :coupon="item"  :isTablet="isTablet" />
            </td>
        </template>

        <!-- Datatable template for mobile view before data rows --->
        <template v-if="isMobile" v-slot:[`body.prepend`]>
            <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                    v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
        </template>
        <!-- Datatable template for mobile view of data rows --->
        <template v-if="isMobile" v-slot:item="{ item }">
            <CoupondDetailsMobileContent :dictionary="dictionary" :coupon="item"  />
        </template>

        <template v-slot:footer>
            <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
        </template>
    </v-data-table>


</div>
</template>

<script>
import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
import CouponTableExpandedItem from "./coupon-table-expanded-item.component.vue";
import CoupondDetailsMobileContent from "./coupon-details-mobile-content.component.vue";

export default {
    mixins: [vueDataTableMixin],
    components: {
        CouponTableExpandedItem,
        CoupondDetailsMobileContent,
    },
    props: {
        dictionary: Object,
        datatablesDictionary: Object,
    },
    data() {
        return {
            isTablet: false,
            isMobile: false,            
            nonCriticalHeaders: [],
            getUrl: '/Coupon/GetAllCouponParticipationDeliveries',
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["recordDate"],
                sortDesc: [true],
                pageCount: 0,
                itemsPerPageOptions: [
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: this.datatablesDictionary.showAll, value: -1 }
                ]
            },
            headers: [
                { value: 'state', text: this.dictionary.table.state, align: 'left', sortable: false },
                { value: 'recordDate', text: this.dictionary.table.recordDate, align: 'left', sortable: true },
                { value: 'deliveryDescription', text: this.dictionary.table.deliveryDescription, align: 'left', sortable: true },
                { value: 'nrSecuritiesFormatted', text: this.dictionary.table.nrSecurities, align: 'right', sortable: true },
                { value: 'grossAmountFormatted', text: this.dictionary.table.grossAmount, align: 'right', sortable: true },
                { value: 'feesAmountFormatted', text: this.dictionary.table.feesAmount, align: 'right', sortable: true },
                { value: 'netAmountFormatted', text: this.dictionary.table.netAmount, align: 'right', sortable: true },
                { value: 'data-table-expand', text: this.dictionary.table.details, align: 'center', sortable: false }
            ],
            rows: [],
            totalRecords: 0
        }
    },
    computed: {
        computedHeaders() {            
            return this.headers;           
        },
        sortableColumns() {
            return this.computedHeaders.filter(x => x.sortable);
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isTablet = window.innerWidth <= this.$windowSizes.TABLET 
                && window.innerWidth > this.$windowSizes.MOBILE;
            this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
        },
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-block single-line" }, [
      _vm.positionModel
        ? _c("h1", [_vm._v(_vm._s(_vm.positionModel.name))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        _vm.positionModel && _vm.dictionary
          ? _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-md-8 col-xl-9 p-0" }, [
                _c(
                  "div",
                  { staticClass: "row m-0" },
                  [
                    _c(
                      "portlet-layout",
                      {
                        attrs: {
                          "column-class": "col-xl col-lg-12",
                          hideTitle: true
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "body" },
                          [
                            _c("total-block", {
                              attrs: {
                                title: _vm.dictionary.blocks.totalAvailable,
                                text: _vm.positionModel.availableFormatted
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "portlet-layout",
                      {
                        attrs: {
                          "column-class": "col-xl col-lg-12",
                          hideTitle: true
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "body" },
                          [
                            _c("total-block", {
                              attrs: {
                                title: _vm.dictionary.blocks.totalValue,
                                text: _vm.getValueFormatted(
                                  _vm.positionModel,
                                  _vm.toggles
                                )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "portlet-layout",
                      {
                        attrs: {
                          "column-class": "col-xl col-lg-12",
                          hideTitle: true
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "body" },
                          [
                            _c("security-price-block", {
                              attrs: {
                                title: _vm.dictionary.blocks.lastPrice,
                                endpoint: _vm.lastPriceEndpoint
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-xl-5 p-0" },
                    [
                      _c("portlet-layout", {
                        attrs: {
                          "column-class": "block",
                          title: _vm.dictionary.widgets.placeOrder
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c("ExitOrderForm", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      positionName: _vm.positionModel.name,
                                      positionViewModel: _vm.positionModel,
                                      isMultiExit: false,
                                      exitOrderModel: _vm.instructionModel
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("loading-spinner", {
                                    attrs: { active: _vm.isSubmitting }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1481807873
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xl-7 p-0" },
                    [
                      !_vm.toggles.hideSecurityPricesChartBlock
                        ? _c(
                            "portlet-layout",
                            {
                              attrs: {
                                "column-class": "block",
                                title:
                                  _vm.dictionary.widgets.securityPricesChart
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "body" },
                                [
                                  _c("prices-chart", {
                                    attrs: {
                                      getSecurityPrices: true,
                                      securityId: _vm.positionModel.securityId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-4 col-xl-3 p-0" },
                [
                  !_vm.toggles.hideLastOrdersLinkBlock
                    ? _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "block",
                            title: _vm.dictionary.widgets.lastOrders
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("last-orders", {
                                attrs: {
                                  toggles: _vm.toggles,
                                  positionUsageId:
                                    _vm.positionModel.positionUsageId
                                }
                              }),
                              _vm._v(" "),
                              _vm.ordersDictionary
                                ? _c("order-detail", {
                                    attrs: {
                                      title:
                                        _vm.ordersDictionary.modal.headerTitle,
                                      toggles: _vm.toggles
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "footerLink" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "portlet-title-see-all-link",
                                  attrs: {
                                    to: "/orders",
                                    title: _vm.dictionary.widgets.seeAllOrders
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.dictionary.widgets.seeAllOrders)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.toggles.hideLastTransactionsLinkBlock
                    ? _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "block",
                            title: _vm.dictionary.widgets.lastTransactions
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("last-transactions", {
                                attrs: {
                                  positionUsageId:
                                    _vm.positionModel.positionUsageId
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "footerLink" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "portlet-title-see-all-link",
                                  attrs: {
                                    to: "/transactions",
                                    title:
                                      _vm.dictionary.widgets.seeAllTransactions
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.widgets.seeAllTransactions
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
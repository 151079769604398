<template>
    <div>
        <beneficiary-data-label :value="legalNationalityDataModel.country" :label="dictionary.country"></beneficiary-data-label>
        <beneficiary-data-label :value="legalNationalityDataModel.nationalNumber" :label="dictionary.residentNumber"></beneficiary-data-label>
        <beneficiary-data-label :value="legalNationalityDataModel.legalDocumentType" :label="dictionary.legalDocumentType"></beneficiary-data-label>
        <beneficiary-data-label :value="legalNationalityDataModel.legalDocumentNumber" :label="dictionary.legalDocumentNumber"></beneficiary-data-label>
        <beneficiary-data-label :value="legalNationalityDataModel.startDate" :label="dictionary.issueDate" :display-value="legalNationalityDataModel.startDate | toDate"></beneficiary-data-label>
        <beneficiary-data-label :value="legalNationalityDataModel.endDate" :label="dictionary.expiryDate" :display-value="legalNationalityDataModel.endDate | toDate"></beneficiary-data-label>
        <beneficiary-true-false-label :value="legalNationalityDataModel.isResident" :label="dictionary.isResident"></beneficiary-true-false-label>
    </div>
</template>

<script>
import BeneficiaryDataLabel from "./../data-label.component.vue";
import BeneficiaryTrueFalseLabel from "./../true-false-label.component.vue";

export default {
  components: {
    BeneficiaryDataLabel,
    BeneficiaryTrueFalseLabel,
  },
  props: {
    legalNationalityDataModel: Object,
    dictionary: Object,
  },
};
</script>
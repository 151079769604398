var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "li",
        { staticClass: "personal-nav-link" },
        [
          [
            _c(
              "div",
              [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", "open-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var attrs = ref.attrs
                            var on = ref.on
                            return [
                              _c(
                                "a",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "nav-link",
                                      attrs: { href: "javascript:;" }
                                    },
                                    "a",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.userFullName
                                    ? _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(_vm._s(_vm.userFullName))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "nav-icon" }, [
                                    _c("i", {
                                      staticClass: "far fa-user",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(_vm._s(_vm.navPersonalDataLabel))
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2457088579
                    )
                  },
                  [
                    _vm._v(" "),
                    _vm.isLoaded && _vm.showAvatarIcon && _vm.items.length > 0
                      ? _c(
                          "v-list",
                          { staticClass: "personal-data-dropdown-menu" },
                          _vm._l(_vm.filteredItems, function(item, index) {
                            return _c(
                              "v-list-item",
                              {
                                key: item.name + "-" + index,
                                attrs: { link: "", to: item.relativeUrl }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", {
                                      domProps: {
                                        textContent: _vm._s(item.icon)
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        textContent: _vm._s(item.text)
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="tableInbox_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <v-data-table class="dataTable no-footer" role="grid"
                      :no-data-text="dictionary.table.noRecordFound"
                      :headers="headers"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      @pagination="setCurrentPage"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer                      
                      >
            <template v-slot:item="{item}">
                <tr :id="item.messageId" @click="rowClick">
                    <td>
                        <a class="email-list-item form-row" href="javascript:;" :data-messageid="item.messageId">
                            <div class="col-9 email-list-item__subject">
                                <h4>{{item.subject}}</h4>
                            </div>
                            <div class="col-3 text-right">
                                <span class="email-list-item__date force-nowrap">{{item.creationDate | toShortDateOrTime}}</span>
                            </div>
                        </a>
                    </td>
                </tr>
            </template>
            <!-- Search input at top --->
            <template v-slot:top>
                <datatable-search class="tableSerachBox-align" :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" :totalVisible="8" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

    export default {
        mixins: [vueDataTableMixin, ScreenSizeMixin],
        props: {
            datatablesDictionary: Object,
            dictionary: Object
        },
        data() {
            return {
                currentPage: 1,
                selectionPage: 0,
                messageId: null,
                isLoadingFirstTime: true,
                nonCriticalHeaders: [],
                getUrl: '/Inbox/Messages',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["creationDate"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: '', text: '', align: 'left', sortable: false },
                ],
                rows: [],
                totalRecords: 0,
                timer: null
            }
        },
        created() {
            this.timer = setInterval(this.loadItems, 5 * 60 * 1000);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            rowClick(item) {
                this.messageId = item?.currentTarget.id;
                this.clearSelection();
                this.setSelection(this.messageId);
                this.selectionPage = this.currentPage;
                this.$emit('rowClicked', this.messageId);
            },
            onDataRefresh() {
                this.$emit('dataRefreshed', this.messageId);
            },
            setCurrentPage(paginationObject) {
                this.clearSelection();
                this.currentPage = paginationObject.page
            },
            clearSelection() {
                const trSelected = document.querySelector('tr.selected');
                if (trSelected) {
                    trSelected.className = '';
                }
            },
            setSelection(elementId) {
                let inboxTableRow = document.getElementById(elementId);
                if(inboxTableRow){
                inboxTableRow.className = 'selected';
                }
            },
        },
        watch: {
            isLoading(val) {
                if (!val) {
                    if (this.isLoadingFirstTime) {
                        this.$emit('loadedTable');
                        this.isLoadingFirstTime = false;
                        this.selectionPage = this.currentPage;

                        if (this.rows.length > 0) {
                            this.messageId = this.rows[0].messageId;
                            this.setSelection(this.rows[0].messageId);
                        }
                    }
                    if (this.selectionPage === this.currentPage) {
                        this.setSelection(this.messageId);
                    }
                    this.onDataRefresh();
                }
            }
        }
    }
</script>

<style scoped>
</style>

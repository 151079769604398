var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    { staticClass: "order-details-list" },
    [
      _c("OrderDataLabel", {
        attrs: {
          value: _vm.valueAmountToDeliver,
          label: _vm.labelAmountToDeliver
        }
      }),
      _vm._v(" "),
      _vm.valueProceedsAccount != null
        ? _c("OrderDataLabel", {
            attrs: {
              value: _vm.valueProceedsAccount,
              label: _vm.labelProceedsAccount
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <InternalExchangeCancelOrderModalFooter :instructionData="instructionData" :dictionary="dictionary"
                                            @close="$emit('close')" @submit-completed="submitCancelOrder()" />
</template>

<script>
    import { post } from "api";
    import InternalExchangeCancelOrderModalFooter from "./internal-exchange-cancel-order-modal-footer.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            InternalExchangeCancelOrderModalFooter,
        },
        props: {
            instructionData: Object,
            dictionary: Object
        },
        methods: {
            submitCancelOrder() {

                post("/InternalExchanges/CancelInstruction",
                    { InstructionId: this.instructionData.InternalExchangeInstructionId },
                    () => { },
                    this,
                    () => {
                        this.$emit('close');
                        EventBus.$emit("reload-orders-items");
                    }
                );
            }
        }
    };
</script>
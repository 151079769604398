<template>
    <div class="v-application">
        <v-alert :icon="iconValue" :type="variant"><slot></slot></v-alert>
    </div>

</template>

<script>
    export default {
        props: {
            variant: {
                type: String,
                default: "info"
            },
            iconClass: String
        },
        computed: {
            iconValue() {
                if (this.iconClass) {
                    return this.iconClass
                }
                return false;
            }
        }
    };
</script>

<template>
    <div class="transfer-settings-row-flex form-row">
        <p class="transfer-settings-row__label col font-weight-bold">{{ label }}</p>
        <p :class="['col transfer-settings-row__value', { 'text-right' : textRight }]">{{ value }}</p>
    </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String | Number,
    textRight: Boolean,
  },
};
</script>
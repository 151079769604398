import { get, post } from 'api';
import moment from 'moment';

const heartbeatIntervalTime = 60 * 1000;

export const sessionHeartbeat = {
    data() {
        return {
            heartbeatInterval: null,
            isEventQueued: false,
            isProcessingEvent: false,
            sessionExpiresUtc: null,
            inactivityCountdownInSeconds: null
        }
    },
    mounted() {
        document.addEventListener('keydown', this.onEvent);
        document.addEventListener('mousemove', this.onEvent);
        document.addEventListener('touchstart', this.onEvent);
        document.addEventListener('touchmove', this.onEvent);
        window.addEventListener('beforeunload', this.cancelScheduledHeartbeat);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onEvent);
        document.removeEventListener('mousemove', this.onEvent);
        document.removeEventListener('touchstart', this.onEvent);
        document.removeEventListener('touchmove', this.onEvent);
        window.removeEventListener('beforeunload', this.cancelScheduledHeartbeat);
        this.cancelScheduledHeartbeat();
    },
    methods: {
        async onEvent() {
            const heartbeat = this.$session.get(this.$SessionKeys.Heartbeat);

            if (this.isTimeRangeForInactivityPrompt()) {
                this.cancelScheduledHeartbeat();
            }
            else if (!heartbeat && !this.isProcessingEvent) {
                this.isProcessingEvent = true;
                await this.processHeartbeat();
            } else if (heartbeat && !this.isEventQueued) {
                this.isEventQueued = true;
                const utc = moment.utc();
                const heartbeatUtc = moment.utc(heartbeat);
                const timeLeft = moment.duration(heartbeatUtc.diff(utc)).asMilliseconds();
                this.scheduleHeartbeat(timeLeft);
            }
        },
        async processHeartbeat() {
            return post(
                '/api/session/heartbeat', {},
                (_response) => {
                    const utc = moment.utc();
                    this.$session.set(this.$SessionKeys.Heartbeat, utc.add(heartbeatIntervalTime, 'ms'), heartbeatIntervalTime);
                }, this, () => {
                    this.isProcessingEvent = false;
                    this.isEventQueued = false;
                    this.getNewHeartbeatData();
                },
                (error) => {
                    if (__DEBUG__) {
                        console.error("heartbeat error", error);
                    }
                });
        },
        getNewHeartbeatData() {
            return get(
                '/api/session/heartbeat',
                (_response) => {
                    const utc = moment.utc();
                    this.sessionExpiresUtc = _response.data.expiresUtc;
                    this.inactivityCountdownInSeconds = _response.data.inactivityCountdownInSeconds;

                    this.$session.set(this.$SessionKeys.Expires, _response.data.expiresUtc,
                        moment.utc(_response.data.expiresUtc).diff(utc));
                },
                this,
                () => { },
                (error) => {
                    console.log(error);
                    if (error.response?.status === this.$HttpStatusCodes.UNAUTHORIZED) {
                        window.location.reload();
                    }
                });
        },
        cancelScheduledHeartbeat() {
            if (this.heartbeatInterval) {
                clearInterval(this.heartbeatInterval);
                delete this.heartbeatInterval
            }
        },
        scheduleHeartbeat(timeLeft) {
            this.cancelScheduledHeartbeat();
            this.heartbeatInterval = setTimeout(this.processHeartbeat, timeLeft);
        },
        getSessionTimeLeft() {
            const utc = moment.utc();
            const expiresUtc = moment.utc(this.$session.get(this.$SessionKeys.Expires));
            return moment.duration(expiresUtc.diff(utc)).asMilliseconds();
        },
        isTimeRangeForInactivityPrompt() {
            const timeLeft = this.getSessionTimeLeft();
            if (timeLeft > 0 && timeLeft < (this.inactivityCountdownInSeconds * 1000)) {
                return true;
            }
            return false;
        }
    }
}

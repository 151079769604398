var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-lg-4" },
        [
          _c("TablePlanDocumentsFilterByPlanName", {
            attrs: { dictionary: _vm.dictionary, plans: _vm.plans },
            on: { "filter-plan-documents": _vm.filterPlanDocuments }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-3 col-lg-2" },
        [
          _c("TablePlanDocumentsSortByDate", {
            attrs: { dictionary: _vm.dictionary },
            on: { "sort-by-plan-document-date": _vm.sortByPlanDocumentDate }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
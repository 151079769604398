<template>
    <div v-if="dictionary" class="cookie-policy-page">
        <div class="cookie-policy-page__header">
            <button :aria-label="dictionary.labels.btnGoBack" @click="goBack()" class="cookie-policy-page__arrow"><i aria-hidden="true" class="far fa-arrow-left fa"></i></button>
            <span class="cookie-policy-page__title"> {{ dictionary.page.headerCookiesPolicy }}</span>
        </div>
        <span>{{ dictionary.page.textCookiesPolicyLastUpdated }}</span>
        <CookiePolicyRow class="mt-13" :richText="true" :title="dictionary.page.titleCookiesPolicyCookiesExplain" :text="dictionary.page.textCookiesPolicyConsent" />

        <div class="row">
            <div class="col-12 col-sm-4 mb-4">
                <button class="btn btn-primary btn-primary-white-background" type="button" @click="openCookiePolicyModal()">{{ dictionary.page.btnManageCookies }}</button>
            </div>
        </div>

        <CookiePolicyRow class="mt-13" :title="dictionary.page.titleCookiesPolicyDetails" :text="dictionary.page.textCookiesPolicyDetails" />

        <CookiePolicyRow :listStyle="true" :title="dictionary.page.titleStrictlyNecessary" :text="dictionary.page.textCookiesStrictlyNecessary" />
        <CookiePolicyRow :listStyle="true" :title="dictionary.page.titleFunctional" :text="dictionary.page.textFunctionalCookies" />
        <CookiePolicyRow :listStyle="true" :title="dictionary.page.titlePerformanceAnalytics" :text="dictionary.page.textPerformanceAnalytics" />

        <CookiePolicyRow class="mt-13" :richText="true" :title="dictionary.page.titleManageBrowser" :text="dictionary.page.textManageBrowser" />
        <CookiePolicyRow class="mt-13" :richText="true" :title="dictionary.page.policyMoreInfoTitle" :text="dictionary.page.policyMoreInfoText" />
    </div>
</template>

<script>
    import { EventBus } from "Shared/event-bus";
    import CookiePolicyRow from "./cookie-policy-row.component.vue";

    export default {
        components: {
            CookiePolicyRow
        },
        props: {
            dictionary: Object,
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            openCookiePolicyModal() {
                EventBus.$emit('open-cookie-policy-modal');
            }
        },
        computed: {
            documentTitle() {
                if (this.dictionary) {
                    return this.dictionary?.page?.title;
                }
            },
        },
        watch: {
            documentTitle: {
                immediate: true,
                handler(value) {
                    if (value) document.title = value;
                },
            },
        }
    }
</script>
<template>
    <modal-basic v-if="!isLoading" class="cookie-policy-modal" size="lg" :is-on="isModalVisible" :ok-footer="false" :staticMode="true" :lazy="false" :centered="true" :noCloseOnEsc="true" @hook:mounted="tryShowCookieModal()">
        <div class="tab-row">
            <div class="tab-bar">
                <b-tabs content-class="mt-0" class="tab-container" fill ref="cookie_policy_modal_tabs">
                    <portlet-tab :tabTitle="dictionary.modal.consentTab" :isActive="true" tabId="tabOpenOrders">
                        <CookiePolicyModalConsent :dictionary="dictionary" @cookie-manage-cookies="switchTabToDetails()" @cookie-allow-all="updateSettings(true)" />
                    </portlet-tab>
                    <portlet-tab :tabTitle="dictionary.modal.detailsTab" tabId="tabHistoryOrders">
                        <CookiePolicyModalDetails :dictionary="dictionary" :functionalToggleValue="functionalToggleValue" :performanceToggleValue="performanceToggleValue"
                                                  @onFunctionalToggle="functionalToggleValue = !functionalToggleValue" @onPerformanceToggle="performanceToggleValue = !performanceToggleValue"
                                                  @cookie-allow-selection="updateSettings(false)" @cookie-allow-all="updateSettings(true)" />
                    </portlet-tab>
                </b-tabs>
            </div>
        </div>
    </modal-basic>
</template>

<script>
    import { get, post } from 'api';
    import CookiePolicyModalConsent from "./cookie-policy-modal-consent.component.vue";
    import CookiePolicyModalDetails from "./cookie-policy-modal-details.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            CookiePolicyModalConsent,
            CookiePolicyModalDetails
        },
        props: {
            user: Object,
            toggles: Object
        },
        data() {
            return {
                dictionary: null,
                isLoading: true,
                isModalVisible: false,
                functionalToggleValue: false,
                performanceToggleValue: false
            }
        },
        beforeCreate() {
            get('/CookiePolicy/Dictionary', response => {
                    this.dictionary = response.data;
                    this.isLoading = false;
            }, this);
        },
        mounted() {
            EventBus.$on('open-cookie-policy-modal', () => {
                this.openCookieModal();
            });

            EventBus.$on("event-language-changed", () => {
                this.getDictionary();
            });
        },
        methods: {
            tryShowCookieModal() {
                if (!this.user?.isImpersonated && this.toggles.enableCookiePolicyModal) {
                    this.$nextTick(function () {
                        this.openCookieModal(true);
                    });
                }
            },
            updateSettings(allowAll) {
                let cookiePolicySettings = {
                    Functional: allowAll ? true : this.functionalToggleValue,
                    PerformanceAnalytics: allowAll ? true : this.performanceToggleValue
                };

                post('/CookiePolicySettings',
                    cookiePolicySettings,
                    () => {
                        this.isModalVisible = false;
                    },
                    this,
                    () => { },
                    () => { }
                );
            },
            fillSettings(settingsModel) {
                if (settingsModel) {
                    this.functionalToggleValue = settingsModel.functional;
                    this.performanceToggleValue = settingsModel.performanceAnalytics;
                } else {
                    this.functionalToggleValue = false;
                    this.performanceToggleValue = false;                   
                }
            },
            openCookieModal(onlyIfCookieSettingsNotExist) {
                get('/CookiePolicySettings', response => {
                    this.fillSettings(response.data);

                    if(onlyIfCookieSettingsNotExist) {
                        if(!response.data) {
                            this.isModalVisible = true;
                        }
                    } else {
                        this.switchTabToDetails();
                        this.isModalVisible = true;
                    }
                }, this);
            },
            switchTabToDetails() {
                this.$refs.cookie_policy_modal_tabs.currentTab = 1;
            },
            getDictionary() {
                get('/CookiePolicy/Dictionary', response => {
                    this.dictionary = response.data;
                }, this);
            }
        }
    }
</script>
<template>
    <div>
        <div>
            <disclaimers :disclaimers="choiceInstructionModel.disclaimers"  />
        </div>
        <div class="pt-2">
            <ChoiceInstructionButtonsComponent :validation="v" :choiceInstructionModel="choiceInstructionModel" @accept-instruction="acceptInstruction"  @decline-instruction="declineInstruction" />
        </div>
    </div>
</template>

<script>
import ChoiceInstructionButtonsComponent from "./choice-instruction-buttons.component.vue";

export default {
  components: {
    ChoiceInstructionButtonsComponent,
  },
  props: {
    dictionary: Object,
    choiceInstructionModel: Object,
    v: Object
  },
  methods: {
    acceptInstruction() {
      this.$emit("accept-instruction", this.choiceInstructionModel);
    },
    declineInstruction() {
      this.$emit("decline-instruction");
    },
  },
};
</script>
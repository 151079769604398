<template>
    <portlet-layout :title="dictionary.page.title">
        <template v-slot:body>
            <PaymentsTable :dictionary="dictionary" :toggles="toggles" :datatablesDictionary="datatablesDictionary" />
        </template>
    </portlet-layout>
</template>

<script>
    import PaymentsTable from './payments-table.component.vue';

    export default {
        components: {
            PaymentsTable
        },
        props: {
            dictionary: Object,
            toggles: Object,
            datatablesDictionary: Object
        }
    }</script>
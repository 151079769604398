var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { staticClass: "form-subscriptions-scenario" }, [
        _c(
          "p",
          {
            staticClass: "font-weight-bold scenarioName-title",
            attrs: { id: "scenario-name-title", tabindex: "0" }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.subscriptionScenario.scenarioName) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _vm.subscriptionScenario.noticeTitle
          ? _c(
              "p",
              { staticClass: "font-weight-bold", attrs: { tabindex: "0" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.subscriptionScenario.noticeTitle) +
                    "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subscriptionScenario.noticeText
          ? _c("p", { attrs: { tabindex: "0" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.subscriptionScenario.noticeText) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            attrs: { role: "table", "aria-describedby": "scenario-name-title" }
          },
          [
            _c("div", { attrs: { role: "rowgroup" } }, [
              _c(
                "div",
                {
                  staticClass: "scenario-subscriptions-header hidden-xs-down",
                  attrs: { role: "row" }
                },
                [
                  _c("div", { staticClass: "underlying-usage-name" }, [
                    _c(
                      "p",
                      {
                        attrs: {
                          role: "columnheader",
                          "aria-sort": "none",
                          tabindex: "0"
                        }
                      },
                      [_vm._v(_vm._s(_vm.dictionary.table.securityName))]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isSubscriptions
                    ? _c("div", { staticClass: "grant-amount" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [_vm._v(_vm._s(_vm.dictionary.table.granted))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSubscriptions
                    ? _c("div", { staticClass: "accepted-amount" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [_vm._v(_vm._s(_vm.dictionary.table.subscribed))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSubscriptions && _vm.hasEmissionPrice
                    ? _c("div", { staticClass: "emission-price" }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [_vm._v(_vm._s(_vm.dictionary.table.emissionPrice))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasTaxableAmount
                    ? _c("div", { staticClass: "taxable-amount" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [_vm._v(_vm._s(_vm.dictionary.table.taxableAmount))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "subscribe-amount" }, [
                    !_vm.isAcceptCollumn
                      ? _c(
                          "p",
                          {
                            staticClass: "scenario-title",
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.table.presentationModeSubscribe
                              )
                            )
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass: "scenario-title",
                            attrs: {
                              role: "columnheader",
                              "aria-sort": "none",
                              tabindex: "0"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.table.presentationModeAccept
                              )
                            )
                          ]
                        )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { role: "rowgroup" } },
              _vm._l(_vm.value.offers, function(offer) {
                return _c(
                  "div",
                  { key: offer.participationSubscriptionOfferId },
                  [
                    _c("SubscriptionScenarioOffer", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        hasTaxableAmount: _vm.hasTaxableAmount,
                        hasEmissionPrice: _vm.hasEmissionPrice,
                        isSubscriptions: _vm.isSubscriptions,
                        validationLists: _vm.validationLists,
                        isAcceptCollumn: _vm.isAcceptCollumn
                      },
                      model: {
                        value: offer.scenarioOffer,
                        callback: function($$v) {
                          _vm.$set(offer, "scenarioOffer", $$v)
                        },
                        expression: "offer.scenarioOffer"
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scenario-subscriptions-grid", attrs: { role: "row" } },
    [
      _c("div", { staticClass: "underlying-usage-name" }, [
        _c("p", { staticClass: "scenario-title" }, [
          _vm._v(_vm._s(_vm.dictionary.table.securityName))
        ]),
        _vm._v(" "),
        _c("p", { attrs: { role: "cell", tabindex: "0" } }, [
          _vm._v(_vm._s(_vm.value.offer.underlyingUsageName))
        ])
      ]),
      _vm._v(" "),
      _vm.isSubscriptions
        ? _c("div", { staticClass: "grant-amount" }, [
            _c("p", { staticClass: "scenario-title" }, [
              _vm._v(_vm._s(_vm.dictionary.table.granted))
            ]),
            _vm._v(" "),
            _c("p", { attrs: { role: "cell", tabindex: "0" } }, [
              _vm._v(_vm._s(_vm.grantAmount))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isSubscriptions
        ? _c("div", { staticClass: "accepted-amount" }, [
            _c("p", { staticClass: "scenario-title" }, [
              _vm._v(_vm._s(_vm.dictionary.table.subscribed))
            ]),
            _vm._v(" "),
            _c("p", { attrs: { role: "cell", tabindex: "0" } }, [
              _vm._v(_vm._s(_vm.value.offer.acceptedAmountFormatted))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSubscriptions && _vm.hasEmissionPrice
        ? _c("div", { staticClass: "emission-price" }, [
            _c("p", { staticClass: "scenario-title" }, [
              _vm._v(_vm._s(_vm.dictionary.table.emissionPrice))
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-center",
                attrs: { role: "cell", tabindex: "0" }
              },
              [_vm._v(_vm._s(_vm.value.offer.emissionPriceFormatted))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTaxableAmount
        ? _c("div", { staticClass: "taxable-amount" }, [
            _c("p", { staticClass: "scenario-title" }, [
              _vm._v(_vm._s(_vm.dictionary.table.taxableAmount))
            ]),
            _vm._v(" "),
            _c("p", { attrs: { role: "cell", tabindex: "0" } }, [
              _vm._v(_vm._s(_vm.value.offer.taxableAmountFormatted))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "subscribe-amount" },
        [
          !_vm.isAcceptCollumn
            ? _c("p", { staticClass: "scenario-title" }, [
                _vm._v(_vm._s(_vm.dictionary.table.presentationModeSubscribe))
              ])
            : _c("p", { staticClass: "scenario-title" }, [
                _vm._v(_vm._s(_vm.dictionary.table.presentationModeAccept))
              ]),
          _vm._v(" "),
          _c("input-element", {
            attrs: {
              id: _vm.value.offer.participationSubscriptionOfferId,
              name: "participationSubscriptionOfferId",
              type: "text",
              validationsList: _vm.amountValidation,
              role: "cell",
              readonly: _vm.isInputReadonly,
              isMandatory: true
            },
            model: {
              value: _vm.value.amount,
              callback: function($$v) {
                _vm.$set(_vm.value, "amount", $$v)
              },
              expression: "value.amount"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-layout",
    {
      attrs: {
        "column-class": "col-lg-8 col-xl-9",
        title: _vm.dictionary.subscriptionWidgets.titleEnterSubscription
      }
    },
    [
      _c("template", { slot: "body" }, [
        _vm.dictionary && _vm.dividendSubscription
          ? _c(
              "div",
              { staticClass: "dividend-subscription-form" },
              [
                _c("div", {
                  staticClass: "page-description rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(_vm.$sanitize(_vm.pageDescription))
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "dividend-subscription-headers" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dividend-security-name dividend-subscription-header"
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.dictionary.subscriptionTable.securityName)
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dividend-subscription dividend-subscription-header"
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.dictionary.subscriptionTable.subscription)
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.gridClass }, [
                  _c("div", { staticClass: "dividend-security-name" }, [
                    _c(
                      "p",
                      { staticClass: "dividend-subscription-mobile-header" },
                      [
                        _vm._v(
                          _vm._s(_vm.dictionary.subscriptionTable.securityName)
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(_vm._s(_vm.dividendSubscription.securityName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dividend-subscription" },
                    [
                      _c("DividendAllocationPercentageInput", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          restrictToMoneyOrSecurityOnly:
                            _vm.dividendSubscription
                              .restrictToMoneyOrSecurityOnly,
                          validation:
                            _vm.validationsList
                              .dividendAllocationPercentagesValidation
                        },
                        on: { "allocation-percentage-changed": _vm.validate },
                        model: {
                          value: _vm.dividendAllocationPercentages,
                          callback: function($$v) {
                            _vm.dividendAllocationPercentages = $$v
                          },
                          expression: "dividendAllocationPercentages"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("DividendSubscriptionButtons", {
                  attrs: {
                    cancelButtonLabel:
                      _vm.dictionary.subscriptionLabels.btnCancel,
                    subscribeButtonLabel:
                      _vm.dictionary.subscriptionLabels.btnSubscribe
                  },
                  on: { cancel: _vm.cancel, subscribe: _vm.subscribe }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accountDictionary
    ? _c("portlet-layout", {
        attrs: {
          title: _vm.accountDictionary.widgets.account,
          hideShadow: true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.accountDictionary.widgets.account) +
                      "\n        "
                  ),
                  _vm.mayEditAccount
                    ? _c("button", {
                        staticClass: "far fa-edit beneficiary-edit-button",
                        attrs: {
                          type: "button",
                          "aria-label":
                            _vm.accountDictionary.widgets.account +
                            " " +
                            _vm.accountDictionary.accessibility.edit
                        },
                        on: { click: _vm.editAccount }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "body",
              fn: function() {
                return [
                  !_vm.isAccountEdited
                    ? _c(
                        "div",
                        [
                          _c("AccountView", {
                            attrs: {
                              accountDataModel: _vm.accountDataModel,
                              dictionary: _vm.accountDictionary.account,
                              isAccountEdited: _vm.isAccountEdited
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("BeneficiaryAccountEditForm", {
                            attrs: {
                              accountDataEditModel: _vm.accountDataEditModel,
                              accountDictionary: _vm.accountDictionary,
                              commonDictionary: _vm.commonDictionary,
                              isAccountEdited: _vm.isAccountEdited
                            },
                            on: {
                              "account-edit-form-cancelled":
                                _vm.accountEditFormCancelled,
                              "reload-page": _vm.reloadPage
                            }
                          })
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1099244339
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <dl class="order-details-list" v-if="dictionary">
    <OrderDataLabel v-if="!toggles.hideOrderState" :label="dictionary.modal.state" :value="instructionData.State" custom-class-data="font-weight-bold"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.reference" :value="instructionData.Reference"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.planName" :value="instructionData.PlanName"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.positionName" :value="instructionData.BuyUnderlyingDescription"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.instructionDate" :value="instructionData.InstructionDate"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.buyDate" :value="instructionData.BuyDate"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.scenarioName" :value="instructionData.BuyScenarioName"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.executionMoment" :value="instructionData.ExecutionMoment"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.ordered" :value="ordered"></OrderDataLabel>
    <OrderDataLabel  v-if="instructionData.LimitPrice > 0"  :label="dictionary.modal.limitPrice" :value="instructionData.LimitPriceFormatted"></OrderDataLabel>
    <OrderDataLabel :label="labelNr" :value="dataNr"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.securityPrice" :value="instructionData.SecurityPriceFormatted"></OrderDataLabel>
    <OrderDataLabel :label="dictionary.modal.grossAmount" :value="instructionData.GrossAmountFormatted"></OrderDataLabel>
    <OrderPayments   :payments="instructionData.FeesPayments" :label="dictionary.modal.paymentFees"></OrderPayments>
    <OrderBrokerFees :brokerFees="instructionData.BrokerFees" :brokerFeesFormated="instructionData.BrokerFeesFormatted" :label="dictionary.modal.brokerFees" ></OrderBrokerFees>
    <OrderDataLabel  :label="dictionary.modal.netAmount" :value="instructionData.netAmountFormatted"></OrderDataLabel>
  </dl>
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
import OrderPayments from "./order-payments.component.vue";
import OrderBrokerFees from "./order-broker-fees.component.vue";

export default {
  components: {
    OrderDataLabel,
    OrderPayments,
    OrderBrokerFees,
  },
  props: {
    instructionData: Object,
    dictionary: Object,
    toggles: Object
  },
  computed: {
    ordered() {
      return this.instructionData.IsInstructionInMoney === true
        ? this.instructionData.AmountOrderedFormatted
        : this.instructionData.NrOrderedFormatted;
    },
    labelNr() {
      return `${this.dictionary.modal.nrBought} / ${this.dictionary.modal.nrInBuy} / ${this.dictionary.modal.nrCancelled}`;
    },
    dataNr() {
      return `${this.instructionData.NrBoughtFormatted} / ${this.instructionData.NrInBuyFormatted} / ${this.instructionData.NrCancelledFormatted}`;
    },
  },
};
</script>
import DividendContainer from "./dividend-container.component.vue";
import DividendSubscribePage from './dividend-subscribe-page.componet.vue';
import Dividend from './dividend.component.vue';

export const dividendRoutes =
{
    path: '/dividend',
    component: DividendContainer,
    children: [
        {
            path: '',
            component: Dividend
        },
        {
            path: '/dividend/subscribe',
            name: 'dividend-subscribe-page',
            component: DividendSubscribePage
        },
        {
            path: '*',
            redirect: '/dividend'
        }
    ]
}
<template>
    <div>
        <v-expansion-panels :class="['listed-trading-details-expansion-block', 'mobile',
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion>
            <v-expansion-panel class="listed-trading-details-expansion-panel">
                <v-expansion-panel-header>
                    <div @click.stop class="listed-trading-details-expansion-panel__header">
                        <PositionActionButtons :position="position" :dictionary="dictionary" :isMobile="isMobile" type="listed-trading"
                                               class="action-buttons-table-cell" />
                        <div @click="togglePanel()" class="font-weight-bold listed-trading-details-expansion-panel__text">{{ position.positionName}}</div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mobile" eager>
                    <ListedTradingTableMobileRow :label="this.dictionary.table.positionName" :value="position.positionName" />
                    <ListedTradingTableMobileRow :label="this.dictionary.table.bid" :value="replaceNullOrEmpty(position.buyPriceFormatted)" />
                    <ListedTradingTableMobileRow :label="this.dictionary.table.ask" :value="replaceNullOrEmpty(position.sellPriceFormatted)" />
                    <ListedTradingTableMobileRow :label="this.dictionary.table.last" :value="replaceNullOrEmpty(position.securityPriceFormatted)" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import ListedTradingTableMobileRow from "./listed-trading-table-mobile-row.component.vue";
    import PositionActionButtons from "/Features/Portfolio/Assets/Vue/Shared/position-action-buttons.component.vue"

    export default {
        components: {
            ListedTradingTableMobileRow,
            PositionActionButtons
        },
        props: {
            position: Object,
            dictionary: Object,
            isMobile: Boolean
        },
        data() {
            return {
                panel: null
            }
        },
        computed: {
            isPanelOpened() {
                return this.panel !== undefined && this.panel !== null;
            }
        },
        methods: {
            togglePanel() {
                if (this.isPanelOpened) {
                    this.panel = null;
                } else {
                    this.panel = 0;
                }
            }
        }
    }
</script>
<template>
<div class="mobile">
    <v-expansion-panels :class="['vesting-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="vesting-details-expansion-panel">
            <v-expansion-panel-header class="vesting-details-expansion-panel__header">
                <h3 class="h4"><i :class="stateIconClass" aria-hidden="true"></i> {{ dictionary.labels.vesting }}: {{ item.vestingDate }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <VestingDetailsContent :toggles="toggles" :dictionary="dictionary" :vesting="item" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <VestingTableExpandedRows v-if="isPanelOpened" :isMobile="true" :dictionary="dictionary" :vesting="item" :toggles="toggles" />
</div>
</template>

<script>
import VestingDetailsContent from './vesting-details-content.component.vue';
import VestingTableExpandedRows from './vestings-table-expanded-rows.component.vue';

export default {
    components: {
        VestingDetailsContent,
        VestingTableExpandedRows
    },
    props: {
        dictionary: Object,
        toggles: Object,
        item: Object
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        stateIconClass() {
            return this.item.isFuture ? "far fa-clock" : "far fa-check-circle";
        },
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        }
    }
}
</script>
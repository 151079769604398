<template>
  <div>
    <div v-if="!isMobile" class="orders-listitem-container">
      <dt>{{ dictionary.modal.positionName }}</dt>
      <dd>
        <div>{{instructionData.SecurityName}}</div>
          <div v-for="(position, index) in exitSecondaryPositions" :key="index" >            
          {{position.PositionUsageName}}
          </div>
      </dd>
    </div>
    <div v-else>
        <div class="orders-row-flex">
            <p class="orders-row__label">{{ dictionary.modal.positionName }}</p>
        </div>
        <div class="orders-row-flex">
            <p :class="['orders-row__value', { 'text-right' : textRight }]">{{ instructionData.SecurityName }}</p>
        </div>
        <div class="orders-row-flex" v-for="(position, index) in exitSecondaryPositions" :key="index">
            <p :class="['orders-row__value', { 'text-right' : textRight }]">{{ position.PositionUsageName }}</p>
        </div>
        <v-divider class="orders-row-divider" ></v-divider>
    </div>
  </div>  
</template>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

export default {
  props: {
    dictionary:Object,
    instructionData:Object,
    textRight:Boolean
  },
  mixins:[ScreenSizeMixin],
  computed: {
    exitSecondaryPositions() {
      return this.instructionData.HasExitSecondaryPositions && 
      this.instructionData.ExitSecondaryPositions !== null && 
      this.instructionData.ExitSecondaryPositions.length > 0
       ? this.instructionData.ExitSecondaryPositions.filter(c=>c.NrOrdered > 0) : [];
    }
  }
};
</script>
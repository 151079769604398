<template>
    <div>
        <div v-if="lostPhoneScreen"  class="mfa-confirmation">
            <ILostMyPhone :dictionary="dictionary"  @cancel="cancel()"></ILostMyPhone>
        </div>
        <div v-else-if="showFirstScreen && isOnFirstScreen" class="mfa-confirmation">
            <MfaMsauthenticatorConfiguration :currentFactorType="currentFactorType"  
            :mfaBoundariesDataModel="mfaBoundariesDataModel"
            :mfaEnableDisableDataModel="mfaEnableDisableDataModel"
            :error="currentError"
            @disable-close-button="$emit('disable-close-button')"
            :showError="showErrorInConfiguration"
            :isMobile="isMobile" :dictionary="dictionary" @cancel="cancel()" @continue-mfa="continueMfa()"/>
        </div>
        <div v-else-if="(!showFirstScreen) || !isOnFirstScreen" class="mfa-confirmation">
            <MfaMsauthenticatorCode v-if="getIdentifier" :isDisabled='isDisabled' :currentFactorType="currentFactorType" :isMobile="isMobile" 
            :mfaEnableDisableDataModel="mfaEnableDisableDataModel" 
            @disable-close-button="$emit('disable-close-button')"
            :isVisible="isVisible"
            :identifier="getIdentifier" :error="currentError" :dictionary="dictionary" 
            @navigateToLostMyPhone="navigateToLostMyPhone()"
            @post-mfa="postMfa" @cancel="cancel()" />           
        </div> 
    </div>
</template>

<script>
    import MfaMsauthenticatorConfiguration from './mfa-msauthenticator-configuration.component.vue';
    import MfaMsauthenticatorCode from './mfa-msauthenticator-code.component.vue';
    import ILostMyPhone from './ILostMyPhone.component.vue';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import { post } from "api";
    import { MfaMixin } from "../mixins/mfa.mixin.js"


    export default {
        components: {
            MfaMsauthenticatorConfiguration,
            MfaMsauthenticatorCode,
            ILostMyPhone
        },
        mixins:[ScreenSizeMixin, MfaMixin],
        beforeCreate() {
            this.$emit('show-ms-authenticator-code');
        },
        mounted() {
            this.initializeTimer(this.dictionary.mfaErrorTOTPServiceDisabled);
        },
        data() {
            return {
                isOnFirstScreen: true,
                currentError: '',
                currentRemainingAttempts:999,
                lostPhoneScreen:false,
                currentDelayInSeconds:0
            }
        },
        props: {
            dictionary: Object,
            error: String,
            isVisible:Boolean,
            mfaBoundariesDataModel:Object,
            mfaEnableDisableDataModel:Object,
            mfaData:Object,
            currentFactorType:String
        },
        watch: {
            isVisible: function (newVal, oldVal) {
                if (!newVal) {
                    this.currentError = '';
                    this.isOnFirstScreen = true;
                    this.lostPhoneScreen = false;
                }
            },
            error: function(newVal, oldVal) {
                if (newVal) {
                    this.currentError = this.error;
                }
            },
            mfaEnableDisableDataModel(newValue) {
                if (newValue) {
                    this.currentError = newValue.errorMessage;

                    if ( newValue.factor && newValue.factor.delayInSeconds) {
                        this.currentDelayInSeconds = newValue.factor.delayInSeconds;
                    }

                    if (this.hasDelayInSeconds) {
                        this.initializeTimer(this.dictionary.mfaErrorTOTPServiceDisabled);
                        this.startDelayErrorMessage(newValue.factor.delayInSeconds);
                    }
                }
            },
            mfaData(newValue) {
                if (newValue) {
                    this.currentError = newValue.errorMessage;

                    if ( newValue.factor && newValue.factor.delayInSeconds) {
                        this.currentDelayInSeconds = newValue.factor.delayInSeconds;
                    }

                    if (this.hasDelayInSeconds) { 
                        this.initializeTimer(this.dictionary.mfaErrorTOTPServiceDisabled);
                        this.startDelayErrorMessage(newValue.factor.delayInSeconds);
                    }
                }
            }
        },
        computed:{
            showErrorInConfiguration(){
                return !this.hasDelayInSeconds;
            },
            hasDelayInSeconds() {
                return (this.getDelayInSeconds > 0);
            },
            hasRemainingAttempts(){
                return (this.currentRemainingAttempts > 0)
            },
            getDelayInSeconds() {
                return this.currentDelayInSeconds;
            },
            showFirstScreen() {
                return !this.mfaBoundariesDataModel.isEnabled;
            },
            isDisabled(){
                if (this.currentError && (this.hasDelayInSeconds || !this.hasRemainingAttempts))
                    return true;
                return false;
            },
            getIdentifier() {
                if (this.mfaData && this.mfaData.identifier)
                    return this.mfaData.identifier;
                else if (this.mfaEnableDisableDataModel && this.mfaEnableDisableDataModel.identifier)
                    return this.mfaEnableDisableDataModel.identifier;
                else
                    return null;
            }
        },
        methods: {
            navigateToLostMyPhone(){
                this.lostPhoneScreen = true;
            },
            continueMfa() {
                this.isOnFirstScreen = false;
            },
            updateCurrentRemainingAttempts(dataModel){
                if (dataModel && dataModel.factor) 
                    this.currentRemainingAttempts = dataModel.factor.remainingAttempts;
            },
            postMfa(payload) {
                this.$emit("disable-close-button");
                this.$emit("in-loading", true);
                
                if (!this.showFirstScreen) {
                    this.$emit('continue-mfa');
                }
                
                if (this.mfaData && this.mfaData.identifier)
                    payload.identifier = this.mfaData.identifier;

                post('/Mfa/Mfa',
                    payload,
                    (res) => {
                        if (res.data.isSuccess) {
                            if (this.mfaEnableDisableDataModel)
                                this.$emit('confirm-code',
                                {
                                    identifier: res.data.identifier,
                                    isSuccess: res.data.isSuccess
                                });
                            else {
                                this.$emit('mfa-success-login');
                            }
                        }
                        else {
                            this.currentError = res.data.errorMessage;
                            this.updateCurrentRemainingAttempts(res.data);
                            if (!this.hasRemainingAttempts)
                                return;

                            if (res.data.factor && res.data.factor.delayInSeconds) {
                                this.currentDelayInSeconds = res.data.factor.delayInSeconds;
                            }
                            else 
                                this.currentDelayInSeconds = 0;

                            if (this.hasDelayInSeconds) 
                                this.startDelayErrorMessage(this.getDelayInSeconds);
                        }
                    },
                    this,
                    () => { this.$emit("in-loading", false); },
                    (err) => {
                        this.currentError = err.response.data;
                        this.$emit("in-loading", false);
                    }
                );
            },
            cancel() {
                this.lostPhoneScreen = false;
                this.$emit("cancel");
            }
        }
    };
</script>
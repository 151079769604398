var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-tab",
    {
      attrs: {
        tabTitle: _vm.financialDictionary.transferSettingsTabName,
        tabId: "TransferSettings",
        isActive: _vm.isActive
      }
    },
    [
      _c("h2", [
        _vm._v(_vm._s(_vm.financialDictionary.widget.transferSettings))
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "page-description rich-text-container",
        domProps: {
          innerHTML: _vm._s(
            _vm.$sanitize(
              _vm.financialDictionary.transferSettings
                .beneficiaryPageDescriptionTransferSettings
            )
          )
        }
      }),
      _vm._v(" "),
      _c("FinancialTransferSettingsTable", {
        attrs: {
          dictionary: _vm.financialDictionary.transferSettings,
          "datatables-dictionary": _vm.datatablesDictionary
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="bank-account-body">
    <template v-if="currentStep > 3">
        <FinancialAddAccountStepAccordion :heading="dictionary.custodianSubHeading" :isCustodian="true" :current-step="currentStep" :dictionary="dictionary" :showStep3=true>
        <dl class="details-list bank-account-details">
            <div class="details-list-left">
            <BeneficiaryDataLabelRow :value="custodianModel.name" :label="dictionary.name" />
            <BeneficiaryDataLabelRow :value="custodianModel.address.addressProperty" :label="dictionary.address" />
            <BeneficiaryDataLabelRow :value="custodianModel.address.postalCode" :label="dictionary.postalcode" />
            <BeneficiaryDataLabelRow :value="custodianModel.address.city" :label="dictionary.city" />
            <BeneficiaryDataLabelRow :value="custodianModel.address.state" :label="dictionary.state" />
            <BeneficiaryDataLabelRow :value="centralSecuritiesDepositoryTypesText(custodianModel.csdType)" :label="dictionary.settlementService" />
            </div>
            <div class="details-list-right">
                <BeneficiaryDataLabelRow :value="custodianModel.csdIdentifier" :label="dictionary.identifier" />
            <BeneficiaryDataLabelRow :value="custodianModel.contactName" :label="dictionary.contactName" />
            <BeneficiaryDataLabelRow :value="custodianModel.contactPhone" :label="dictionary.contactPhoneNumber" />
            <BeneficiaryDataLabelRow :value="custodianModel.contactFax" :label="dictionary.contactFaxNumber" />
            <BeneficiaryDataLabelRow :value="custodianModel.contactEmail" :label="dictionary.contactEmail" />
            <BeneficiaryDataLabelRow :value="custodianModel.contactReference" :label="dictionary.contactReference" />
            </div>
        </dl>
        </FinancialAddAccountStepAccordion>
        <FinancialAddAccountStepSubCustodian 
        v-if="!toggles.hideSubCustodianFieldsForSecurityAccounts"
        :custodian-data="custodianData"
        :dictionary="dictionary.subCustodian" :current-step="currentStep" />
    </template>
    <FinancialAddAccountStepAccordion v-else-if="currentStep >= 3" 
        @hook:mounted="expandCustodian" 
        :heading="dictionary.custodianSubHeading" 
        :isCustodian="true" 
        :showStep3="true"
        ref="FinancialAddAccountStepAccordion">
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model.trim="custodianModel.name" name="custodian-name"
                :label="dictionary.name"
                :validationsList="custodianValidations.custodianName"
                :is-mandatory="isBankNameEmpty"
                :toolTipText="dictionary.nameTootip" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.address.addressProperty" name="custodian-address" :label="dictionary.address" />
            </div>

            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.address.postalCode" name="custodian-postalcode" :label="dictionary.postalcode" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model.trim="custodianModel.address.city" :validationsList="custodianValidations.city"
                 name="custodian-city" :label="dictionary.city" :is-mandatory="isCustodianCityRequired" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.address.state" name="custodian-state" :label="dictionary.state" />
            </div>
            <div class="col-md-6 col-lg-4">
                <select-element v-model.trim="custodianModel.csdType" name="custodian-settlemetservice"
                :options="centralSecuritiesDepositoryTypesList"
                :disabled="securityAccountDisplaySettings.allowUKShareTransferSettingsOnly"
                :label="dictionary.settlementService" :validationsList="custodianValidations.csdType"
                :is-mandatory="isCustodianNameEmpty && !usesDomesticInstructionsOnly"
                :toolTipText="dictionary.settlementServiceTooltip" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model.trim="custodianModel.csdIdentifier"
                name="custodian-identifier" :label="dictionary.identifier"
                :validationsList="custodianValidations.csdIdentifier"
                :is-mandatory="(isCustodianNameEmpty && !usesDomesticInstructionsOnly) || (!!this.custodianModel.csdType && this.custodianModel.csdType !== 'csdtNone')"
                :toolTipText="dictionary.identifierTooltip" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.contactName" name="custodian-contact-name" :label="dictionary.contactName" />
            </div>

            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.contactPhone" name="custodian-contact-phone" :label="dictionary.contactPhoneNumber" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.contactFax" name="custodian-contact-fax" :label="dictionary.contactFaxNumber" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.contactEmail" name="custodian-contact-email" :label="dictionary.contactEmail" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="custodianModel.contactReference" name="custodian-contact-reference" :label="dictionary.contactReference" />
            </div>
        </div>

        <FinancialAddAccountMandatoryTooltip :tooltipText="dictionary.mandatory" />
        <FinancialAddAccountStepSubCustodian 
            v-if="!toggles.hideSubCustodianFieldsForSecurityAccounts"
            :custodian-data="custodianData"
            :dictionary="dictionary.subCustodian" :current-step="currentStep" />
    </FinancialAddAccountStepAccordion>
</div>    
</template>
<script>
    import { custodianModelValidations } from "./financial-add-account-form-validation.mixin";
    import FinancialAddAccountStepAccordion from "./finanacial-add-account-step-accordion.component.vue";
    import BeneficiaryDataLabelRow from "../data-label-row.component.vue";
    import FinancialAddAccountMandatoryTooltip from "./financial-add-account-mandatory-tool-tip.component.vue";
    import FinancialAddAccountStepSubCustodian from "./financial-add-account-step-sub-custodian.component.vue";
    export default {
        name: 'FinancialAddAccountStepCustodian',
        mixins: [custodianModelValidations],
        components: {
            FinancialAddAccountStepAccordion,
            BeneficiaryDataLabelRow,
            FinancialAddAccountMandatoryTooltip,
            FinancialAddAccountStepSubCustodian
        },
        props: {
            dictionary: Object,
            currentStep: Number,
            custodianData: Object,
            usesDomesticInstructionsOnly: Boolean,
            bankName:String,
            externalIdentifier:String,
            centralSecuritiesDepositoryTypes: Array,
            validationDictionary: Object,
            steps:Object,
            toggles:Object,
            securityAccountDisplaySettings:Object
        },
        methods: {
            expandCustodian() {
                this.$refs.FinancialAddAccountStepAccordion.isVisible = true;
            },
            centralSecuritiesDepositoryTypesText(csdType) {
                if(csdType === null || csdType == '')
                {
                    this.custodianModel.csdType = "csdtNone";
                }
                const csdText = this.centralSecuritiesDepositoryTypes.filter(c=>c.value === csdType);
                return csdText.length > 0 ? csdText[0].text : '';
            }
        },
        computed: {
            centralSecuritiesDepositoryTypesList() {
                return this.centralSecuritiesDepositoryTypes;
            },
            custodianModel() {
                return this.custodianData;
            },

            custodianValidations() {
                return {
                    custodianName: [
                        {
                            id: 1,
                            field: this.$v.custodianModel.name,
                            error: this.$v.custodianModel.name.$error,
                            type: this.$v.custodianModel.name.required,
                            message: this.validationDictionary.required.custodianRequired.custodianName
                        }
                    ],
                    csdType: [
                        {
                            id: 1,
                            field: this.$v.custodianModel.csdType,
                            error: this.$v.custodianModel.csdType.$error,
                            type: this.$v.custodianModel.csdType.required,
                            message: this.validationDictionary.required.custodianRequired.settlementService
                        },
                        {
                            id: 2,
                            field: this.$v.custodianModel.csdType,
                            error: this.$v.custodianModel.csdType.$error,
                            type: this.$v.custodianModel.csdType.validCSDType,
                            message: this.validationDictionary.invalid.custodianInvalid.settlementServiceInvalid
                        }, 
                    ],
                    csdIdentifier: [
                        {
                            id: 1,
                            field: this.$v.custodianModel.csdIdentifier,
                            error: this.$v.custodianModel.csdIdentifier.$error,
                            type: this.$v.custodianModel.csdIdentifier.required,
                            message: this.validationDictionary.required.custodianRequired.identifier
                        }
                    ],
                    city: [
                        {
                            id: 1,
                            field: this.$v.custodianModel.address.city,
                            error: this.$v.custodianModel.address.city.$error,
                            type: this.$v.custodianModel.address.city.required,
                            message: this.validationDictionary.required.custodianRequired.custodianCity
                        }
                    ],
                }
            },
            isBankNameEmpty() {
                if(this.usesDomesticInstructionsOnly){
                    return false;
                }
                return !(!!this.bankName);
            },
            isCustodianNameEmpty() {
                return (!!this.custodianModel.name);
            },
            isCustodianCityRequired() {
                return (!!this.custodianModel.address.addressProperty);
            }
        },
        updated() {
            if (this.custodianModel.name && this.custodianModel.csdType === 'csdtNone' && !this.usesDomesticInstructionsOnly) {
                this.custodianModel.csdType = null;
            }
        },
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "h4 primary-color" }, [
        _vm._v(_vm._s(_vm.dictionary.instructionTitle))
      ]),
      _vm._v(" "),
      _vm.shouldShowDeviationWarningMessage
        ? _c("p", { attrs: { role: "alert" } }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.internalExchangesWarningLimitPriceDeviation))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.positionName,
              value: _vm.intExchangeInstructionModel.securityName
            }
          }),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.orderType,
              value:
                _vm.intExchangeInstructionModel.orderType == "Buy"
                  ? _vm.dictionary.orderTypeBuy
                  : _vm.dictionary.orderTypeSell
            }
          }),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.expirationDate,
              value: _vm._f("toDateTime")(
                _vm.intExchangeInstructionModel.expirationDate
              )
            }
          }),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.quantity,
              value: _vm.intExchangeInstructionModel.quantityFormatted
            }
          }),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.limitPrice,
              value: _vm.intExchangeInstructionModel.limitPriceFormatted
            }
          }),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            attrs: {
              label: _vm.dictionary.maxFeeReservationAmount,
              value:
                _vm.intExchangeInstructionModel.maxFeeReservationAmountFormatted
            }
          }),
          _vm._v(" "),
          _vm.toggles.displayOrderTaxDetails &&
          _vm.intExchangeInstructionModel.taxRate !== null
            ? _c("InternalExcahngesConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.taxRate,
                  value: _vm.intExchangeInstructionModel.taxRate
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.toggles.displayOrderTaxDetails &&
          _vm.intExchangeInstructionModel.estimatedTaxesFormatted !== null
            ? _c("InternalExcahngesConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.taxAmount,
                  value: _vm.intExchangeInstructionModel.estimatedTaxesFormatted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("InternalExcahngesConfirmationRow", {
            staticClass: "confirmation-total-row",
            attrs: {
              label: _vm.dictionary.expectedOrderAmount,
              value:
                _vm.intExchangeInstructionModel.expectedOrderAmountFormatted
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.intExchangeInstructionModel.disclaimers.length
        ? _c(
            "InternalExcahngesConfirmationButtonsComponent",
            _vm._g(
              {
                attrs: {
                  intExchangeInstructionModel: _vm.intExchangeInstructionModel
                }
              },
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "portlets-wrapper" }, [
      _c("div", { staticClass: "title-block" }, [
        _c("h1", [_vm._v(_vm._s(_vm.dictionary.errorPageTitle))])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "portlets-wrapper" }, [
      _c("div", { staticClass: "row m-0", attrs: { id: "portlet-1" } }, [
        _c("div", { staticClass: "col-12 p-0" }, [
          _c("section", [
            _c("div", { staticClass: "card card-shadow" }, [
              _c("div", { staticClass: "card-wrapper collapse show" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "text-center py-2" }, [
                        _vm._v(_vm._s(_vm.dictionary.errorPageTitle))
                      ]),
                      _vm._v(" "),
                      _c("alert-component", { attrs: { variant: "error" } }, [
                        _vm._v(
                          _vm._s(_vm.dictionary.internalServerFriendlyError)
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="transaction-header">
        <div v-if="!isMobile">
            <span class="transaction-header-overview font-weight-bold mr-8">{{ dictionary.page.transactionsSubTitlePositionOverview }}</span>
            <span>{{ dictionary.labels.transactionsLabelOpeningBalance }}</span>
            <span class="font-weight-bold ml-2">{{ replaceNullOrEmpty(transaction.startPositionFormatted) }}</span>
            <span class="ml-8 mr-8">|</span>
            <span>{{ dictionary.labels.transactionsLabelClosingBalance }}</span>
            <span class="font-weight-bold ml-2">{{ replaceNullOrEmpty(transaction.endPositionFormatted) }}</span>
        </div>
        <div v-else>
            <span class="transaction-header-overview font-weight-bold">{{ dictionary.page.transactionsSubTitlePositionOverview }}</span>
            <div class="row">
                <div class="col-auto">
                    <div>{{ dictionary.labels.transactionsLabelOpeningBalance }}</div>
                    <div class="font-weight-bold">{{ replaceNullOrEmpty(transaction.startPositionFormatted) }}</div>
                </div>
                <div class="col-auto">
                    <div>{{ dictionary.labels.transactionsLabelClosingBalance }}</div>
                    <div class="font-weight-bold">{{ replaceNullOrEmpty(transaction.endPositionFormatted) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            dictionary: Object,
            transaction: Object,
            isMobile: Boolean
        }
    };
</script>
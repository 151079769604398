var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-action-links" },
    [
      _vm.position.canInstructExit
        ? _c("PortfolioPlanActionLink", {
            attrs: {
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails,
              disableLink: _vm.disableLink,
              position: _vm.position
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath("/exits")
              }
            }
          })
        : _vm.position.canInstructBuy
        ? _c("PortfolioPlanActionLink", {
            attrs: {
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails,
              disableLink: _vm.disableLink,
              position: _vm.position
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath("/buy")
              }
            }
          })
        : _vm.position.canInstructExercise
        ? _c("PortfolioPlanActionLink", {
            attrs: {
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails,
              disableLink: _vm.disableLink,
              position: _vm.position
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath("/exercises")
              }
            }
          })
        : !_vm.position.isSecurityPosition
        ? _c("PortfolioPlanActionLink", {
            attrs: {
              disableLink: _vm.disableLink,
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails,
              position: _vm.position
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath("/moneyTransfer")
              }
            }
          })
        : (_vm.position.canInstructInternalExchange &&
            (_vm.position.canBuy || _vm.position.canSell)) ||
          _vm.position.hasInternalExchange
        ? _c("PortfolioPlanActionLink", {
            attrs: {
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails,
              disableLink: _vm.disableLink,
              position: _vm.position
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath(
                  "/internalExchanges",
                  "orderType",
                  _vm.position.orderType
                )
              }
            }
          })
        : _c("PortfolioPlanActionLink", {
            attrs: {
              disableLink: _vm.disableLink,
              position: _vm.position,
              isMobile: _vm.isMobile,
              mobileLabel: _vm.dictionary.table.btnViewPositionDetails
            },
            on: {
              onClick: function($event) {
                return _vm.goToPath(
                  "/positionDetail",
                  "securityUnderlyingId",
                  _vm.position.securityUnderlyingId
                )
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BeneficiaryContainer from "./beneficiary-container.component.vue";
import BeneficiaryMain from "./Main/beneficiary-main.component.vue";
import BeneficiaryChangePassword from './Account/beneficiary-change-password.component.vue';

export const beneficiaryRoutes =
{
    path: '/beneficiaries',
    alias: [
        '/beneficiaries#account',
        '/beneficiaries#financial',
        '/beneficiaries#evaluation',
        '/beneficiaries#company'
    ],
    component: BeneficiaryContainer,
    children: [
        {
            path: '',
            component: BeneficiaryMain
        },
        {
            path: 'password/change',
            component: BeneficiaryChangePassword
        },
        {
            path: '*',
            redirect: '/beneficiaries'
        }
    ]
}
<template>
<div>
    <div class="title-block">
        <h1 v-if="pagesDictionary">{{ pagesDictionary.choices.title }}</h1>
    </div>

    <div class="choices">
        <component-spinner :spin="isLoading"></component-spinner>
        <router-view v-if="!isLoading" :dictionary="dictionary" :datatables-dictionary="datatablesDictionary"></router-view>
    </div>
</div>
</template>

<script>
    import { getAll } from 'api';

    export default {
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                datatablesDictionary: {},
                isLoading: true,
            }
        },
        beforeCreate() {
            getAll([
                '/choices/Dictionary',
                '/choices/DatatablesDictionary',
            ],
                (responses) => {
                    this.dictionary = responses[0].data;
                    this.datatablesDictionary = responses[1].data;

                    this.$setPageTitle(this.dictionary.page.title);
                    this.isLoading = false;
                }, this);
        }
    };
</script>
import ReportsContainer from "./reports-container.component.vue";
import ReportsPortlet from "./reports-portlet.component.vue";
import ReportsPortfolioOverview from "./reports-portfolio-overview.component.vue";


export const reportsRoutes =
{
    path: '/reports',
    component: ReportsContainer,
    children: [
        {
            path: '',
            component: ReportsPortlet
        }, {
            path: 'portfolioOverview',
            component: ReportsPortfolioOverview
        },
        {
            path: '*',
            redirect: '/reports'
        }
    ]
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-container" },
    [
      _c("PortfolioPrintHeader", {
        attrs: { dictionary: _vm.dictionary, skin: _vm.skin }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-block title-actions" },
        [
          _vm.pagesDictionary
            ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.portfolio.title))])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoadingSummaryData
            ? [
                _c(
                  "div",
                  { staticClass: "title-actions-container noprint" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          id: "printPage",
                          href: "javascript:void(0)",
                          role: "button"
                        },
                        on: { click: _vm.printPage }
                      },
                      [
                        _vm._m(0),
                        _vm._v(" " + _vm._s(_vm.dictionary.page.btnPrint))
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canRequestPortfolioOverviewReport &&
                    !_vm.toggles.hidePortfolioCalculatorLink
                      ? _c("portfolio-overview-link", {
                          attrs: { dictionary: _vm.dictionary }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "portlets-wrapper print-wrapper" }, [
        _c(
          "div",
          { staticClass: "portlet-flex-container", attrs: { id: "portlet-1" } },
          [
            _c("component-spinner", {
              attrs: {
                spin: _vm.isLoadingSummaryData || _vm.isLoadingCurrenciesData
              }
            }),
            _vm._v(" "),
            !_vm.isLoadingCurrenciesData && !_vm.isLoadingSummaryData
              ? _c(
                  "div",
                  { staticClass: "clearfix portlet-portfolio-summary" },
                  [
                    _c("portfolio-summary", {
                      staticClass: "row m-0",
                      attrs: {
                        toggles: _vm.toggles,
                        "portfolio-summary": _vm.portfolioSummary
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoadingCurrenciesData && !_vm.isLoadingSummaryData
              ? [
                  _vm.toggles.showCurrencyConversionDropdown &&
                  _vm.availableCurrencies.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "portlet-portfolio-panel portlet-currency-panel"
                        },
                        [
                          _c("portfolio-tooltip", {
                            attrs: {
                              placement: "top",
                              icon: "far fa-info-circle",
                              "tooltip-text":
                                _vm.dictionary.block.currencySelectBoxTooltip
                            }
                          }),
                          _vm._v(" "),
                          _c("custom-dropdown", {
                            attrs: {
                              "id-name": "currencySelection",
                              items: _vm.currencies,
                              label:
                                _vm.dictionary.block.currencySelectBoxLabel,
                              "initial-item": _vm.selectedCurrency,
                              "show-label": true,
                              "event-name": "currency-changed"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dictionary && _vm.portfolioPlans
                    ? _c(
                        "div",
                        { staticClass: "portlet-portfolio-plan" },
                        _vm._l(_vm.portfolioPlans, function(plan) {
                          return _c(
                            "div",
                            { key: plan.name, staticClass: "row m-0" },
                            [
                              _c("portfolio-plan", {
                                attrs: {
                                  plan: plan,
                                  dictionary: _vm.dictionary,
                                  toggles: _vm.toggles,
                                  currency: _vm.selectedCurrency
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "title-action-icon", attrs: { "aria-hidden": "true" } },
      [
        _c("i", {
          staticClass: "far fa-print",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
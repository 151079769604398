var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.type + "-order-buttons", { mobile: _vm.isMobile }] },
    [
      _vm.position.canInstructInternalExchange
        ? _c("div", { class: _vm.type + "-buysell-buttons" }, [
            _vm.position.canBuy
              ? _c("div", { staticClass: "green-button" }, [
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goToPath(
                            "/internalExchanges",
                            "orderType",
                            "Buy"
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.getBuyButtonText))]
                  )
                ])
              : _c("div", { staticClass: "grayed-button" }, [
                  _c(
                    "a",
                    {
                      staticClass: "isDisabled",
                      attrs: { href: "", role: "button" }
                    },
                    [_vm._v(_vm._s(_vm.getBuyButtonText))]
                  )
                ]),
            _vm._v(" "),
            _vm.position.canSell
              ? _c("div", { staticClass: "red-button" }, [
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goToPath(
                            "/internalExchanges",
                            "orderType",
                            "Sell"
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.getSellButtonText))]
                  )
                ])
              : _c("div", { staticClass: "grayed-button" }, [
                  _c(
                    "a",
                    {
                      staticClass: "isDisabled",
                      attrs: { href: "", role: "button" }
                    },
                    [_vm._v(_vm._s(_vm.getSellButtonText))]
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.position.canInstructExit
        ? _c(
            "button",
            {
              class: _vm.getButtonClasses,
              on: {
                click: function($event) {
                  return _vm.goToPath("/exits")
                }
              }
            },
            [
              _vm._v(
                "\r\n        " +
                  _vm._s(_vm.dictionary.table.btnInstructionOrder) +
                  "\r\n    "
              )
            ]
          )
        : _vm.position.canInstructBuy
        ? _c(
            "button",
            {
              class: _vm.getButtonClasses,
              on: {
                click: function($event) {
                  return _vm.goToPath("/buy")
                }
              }
            },
            [
              _vm._v(
                "\r\n        " +
                  _vm._s(_vm.dictionary.table.btnInstructionOrder) +
                  "\r\n     "
              )
            ]
          )
        : _vm.position.canInstructExercise
        ? _c(
            "button",
            {
              class: _vm.getButtonClasses,
              on: {
                click: function($event) {
                  return _vm.goToPath("/exercises")
                }
              }
            },
            [
              _vm._v(
                "\r\n        " +
                  _vm._s(_vm.dictionary.table.btnInstructionOrder) +
                  "\r\n    "
              )
            ]
          )
        : !_vm.position.isSecurityPosition && _vm.position.canPositionPay
        ? _c(
            "button",
            {
              class: _vm.getButtonClasses,
              on: {
                click: function($event) {
                  return _vm.goToPath("/moneyTransfer")
                }
              }
            },
            [
              _vm._v(
                "\r\n        " +
                  _vm._s(_vm.dictionary.table.btnInstructionOrder) +
                  "\r\n    "
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
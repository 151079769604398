<template>
    <div class="portfolio-plan table-responsive mt-0">
        <v-data-table class="elevation-1"
                     item-key="positionName"
                     :headers="customHeaders"
                     :items="records"
                     :disable-pagination="true"
                     :footer-props="{ disablePagination: true, disableItemsPerPage : true }"
                     :disable-sort="true"
                     :items-per-page="-1"
                     :hide-default-footer="true"
                     :mobile-breakpoint="0"
                     :show-expand="isMobile" 
                     >           

            <template v-slot:[`item.custom-name`]="{ item }">
                <span class="font-weight-bold">{{item.positionName}}</span>
            </template>
            <template v-slot:[`item.custom-value`]="{ item }">
                 <span class="text-nowrap" :class="{ 'font-weight-bold' : isMobile }">{{ item.valueFormatted }}</span>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                        @click.stop="expand(!isExpanded)" :aria-label="`${item.positionName} ${isExpanded ? 'collapse' : 'expand'} more details`">$expand</v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td v-if="isMobile" class="expanded-item-td" :colspan="headers.length">
                    <PortfolioMobileDetailsContent :position="item" :dictionary="dictionary" :isMobile="isMobile" />
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>

import PortfolioMobileDetailsContent from "./portfolio-mobile-details-content.component.vue";
export default {
    components: { PortfolioMobileDetailsContent},
    props: {
        dictionary: Object,
        records: Array,
        isTablet: Boolean,
        isMobile: Boolean
    },
    data() {
        return {
            headers: [
                { value: 'custom-name', text: this.dictionary.tables.positionName, align: 'left', sortable: false, visible: true },
                { value: 'acceptedAmountFormatted', text: this.dictionary.tables.subscribedAccepted, align: 'right', sortable: false, visible: !this.isMobile },
                { value: 'balanceFormatted', text: this.dictionary.tables.balance, align: 'right', sortable: false, visible: !this.isMobile },
                { value: 'creditedAmountFormatted', text: this.dictionary.tables.credited, align: 'right', sortable: false , visible: !this.isMobile},
                { value: 'debitedAmountFormatted', text: this.dictionary.tables.debited, align: 'right', sortable: false , visible: !this.isMobile},
                { value: 'securityPriceFormatted', text: this.dictionary.tables.securityPrice, align: 'right', sortable: false, visible: !this.isMobile },
                { value: 'custom-value', text: this.dictionary.tables.value, align: 'right', sortable: false, visible: true },
                { value: 'data-table-expand', text: '', align: 'right', sortable: false, visible: this.isMobile }
            ],
        }
    },
    computed: {
        totalRecords(){
            return this.records.length;
        },
        customHeaders(){
            return this.headers.filter(h => h.visible);
        },
    }
    
}
</script>

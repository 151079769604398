<template>
    <div class="dividend-expanded-content">
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <DividendDataLabelRow :label="dictionary.table.state" :value="dividend.state.description" />
                <DividendDataLabelRow v-if="isTablet" :label="dictionary.table.grossAmount" :value="dividend.grossAmountFormatted" />
                <DividendDataLabelRow v-if="isTablet" :label="dictionary.table.tax" :value="dividend.taxAmountFormatted" />
                <DividendDataLabelRow v-if="isTablet" :label="dictionary.table.netAmount" :value="dividend.netAmountFormatted" />
            </div>
            <div class="col-md-6 col-lg-4">
                <DividendDataLabelRow :label="dictionary.table.dividendType" :value="dividendTypeTitle" />
                <DividendDataLabelRow v-if="showQuantityPerShare" :label="dictionary.table.quantityPerShare" :value="quantityPerShare" />
                <DividendDataLabelRow v-if="showAmountPerShare" :label="dictionary.table.amountPerShare" :value="amountPerShare" />
            </div>
        </div>
    </div>
</template>

<script>
import DividendDataLabelRow from "./dividend-data-label-row.component.vue";
import { dividendMixin } from "./dividend.mixin";

export default {
  mixins: [dividendMixin],
  components: {
    DividendDataLabelRow,
  },
  props: {
    dividend: Object,
    dictionary: Object,
    toggles: Object,
    isTablet:Boolean
  },
};
</script>
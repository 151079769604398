<template>
    <div>
        <disclaimers :disclaimers="confimationModel.disclaimers" />
        <SubscriptionConfirmationButtons :dictionary="dictionary" v-on="$listeners" :disclaimers="confimationModel.disclaimers" :presentationMode="confimationModel.participationSubscription.presentationMode" />
    </div>
</template>

<script>
    import SubscriptionConfirmationButtons from "./subscription-confirmation-buttons.component.vue";

    export default {
        components: {
            SubscriptionConfirmationButtons
        },
        props: {
            confimationModel: Object,
            dictionary: Object
        }
    }
</script>
<template>
    <div v-if="dictionary" class="cookie-policy-consent">
        <div class="cookie-policy-consent__content">
            <div class="row">
                <div class="col-12">
                    <p class="cookie-policy-consent__title">{{ dictionary.modal.policyModalTitle }}</p>
                    <div class="rich-text-container" v-html="$sanitize(dictionary.modal.consent)"></div>
                </div>
            </div>
        </div>
        <div class="row cookie-policy__buttons">
            <div class="col-12 col-md-6 mt-2">
                <button class="btn btn-primary btn-primary-white-background w-100" type="button" @click="$emit('cookie-manage-cookies')">{{ dictionary.modal.btnManageCookies }}</button>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <button class="btn btn-primary btn-primary-white-background w-100" type="button" @click="$emit('cookie-allow-all')">{{ dictionary.modal.btnAllowAll }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        { staticClass: "orders" },
        [
          _c(
            "b-tabs",
            {
              staticClass: "tab-container",
              attrs: { "content-class": "mt-0" }
            },
            [
              _c(
                "portlet-tab",
                {
                  attrs: {
                    tabTitle: _vm.dictionary.tabs.open,
                    isActive: true,
                    tabId: "tabOpenOrders"
                  }
                },
                [
                  _c("div", {
                    staticClass: "page-description rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.dictionary.page.ordersPageDescriptionOpen
                        )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("OrdersTable", {
                    attrs: {
                      dictionary: _vm.dictionary.openTable,
                      datatablesDictionary: _vm.dictionary.dataTables,
                      toggles: _vm.toggles,
                      id: "OpenOrders",
                      getUrl: "/Orders/OpenOrders"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "portlet-tab",
                {
                  attrs: {
                    tabTitle: _vm.dictionary.tabs.history,
                    tabId: "tabHistoryOrders"
                  }
                },
                [
                  _c("div", {
                    staticClass: "page-description rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.dictionary.page.ordersPageDescriptionHistory
                        )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("OrdersTable", {
                    attrs: {
                      dictionary: _vm.dictionary.historyTable,
                      datatablesDictionary: _vm.dictionary.dataTables,
                      toggles: _vm.toggles,
                      isHistoryOrders: true,
                      id: "ClosedOrders",
                      getUrl: "/Orders/HistoryOrders"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("OrderDetail", {
            attrs: {
              toggles: _vm.toggles,
              title: _vm.dictionary.modal.headerTitle
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthenticated && !_vm.isLoading
    ? _c("Error", { attrs: { dictionary: _vm.dictionary } })
    : !_vm.isLoading
    ? _c("ErrorForUnauthenticatedUser", {
        attrs: { dictionary: _vm.dictionary }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        { attrs: { id: "relationship-container" } },
        [
          _c("RelationsOfBeneficiary", {
            staticClass: "evaluation-body",
            attrs: {
              widgetTitle: _vm.dictionary.widgets.relationsOfBeneficiary,
              dictionary: _vm.dictionary.relationships,
              "datatables-dictionary": _vm.datatablesDictionary,
              toggles: _vm.toggles
            }
          }),
          _vm._v(" "),
          _c("RelationsWithBeneficiary", {
            staticClass: "evaluation-body",
            attrs: {
              widgetTitle: _vm.dictionary.widgets.relationsWithBeneficiary,
              dictionary: _vm.dictionary.relationships,
              "datatables-dictionary": _vm.datatablesDictionary,
              toggles: _vm.toggles
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
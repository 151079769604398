var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("disclaimers", {
            attrs: { disclaimers: _vm.intExchangeInstructionModel.disclaimers }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "InternalExchangeConfirmationButtonsComponent",
        _vm._g(
          {
            attrs: {
              intExchangeInstructionModel: _vm.intExchangeInstructionModel
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
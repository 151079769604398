var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choice-details-content" },
    [
      _c("ChoiceDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.choiceName,
          value: _vm.choice.title
        }
      }),
      _vm._v(" "),
      _c("ChoiceDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.startDate,
          value: _vm.choice.choiceStartDate
        }
      }),
      _vm._v(" "),
      _c("ChoiceDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.endDate,
          value: _vm.choice.choiceEndDate
        }
      }),
      _vm._v(" "),
      _c("ChoiceDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.state,
          value: _vm.choice.stateDescription
        }
      }),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn btn-primary w-100",
          attrs: {
            role: "button",
            to:
              "/choices/instruction?participationChoiceId=" +
              _vm.choice.participationChoiceId
          }
        },
        [_vm._v(_vm._s(_vm.actionLinkText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const skinMethods = {
    methods: {
        updateSkin(skin) {
            if(!skin)
                return;

                document.documentElement.style.setProperty('--primary-color', skin.colors.primary);
                document.documentElement.style.setProperty('--secondary-color', skin.colors.secondary);
                document.documentElement.style.setProperty('--tertiary-color-light', skin.colors.tertiaryLight);
                document.documentElement.style.setProperty('--tertiary-color-dark', skin.colors.tertiaryDark);
                document.documentElement.style.setProperty('--border-radius', `${skin.layout.borderRadius}px !important`);
                document.documentElement.style.setProperty('--button-bg-color', skin.colors.buttonBackground);
                document.documentElement.style.setProperty('--button-hover-bg-color', skin.colors.buttonHoverBackground);
                document.documentElement.style.setProperty('--table-header-bg-color', skin.colors.tableHeaderBackground);
                document.documentElement.style.setProperty('--table-topheader-bg-color', skin.colors.tableTopHeaderBackground);
                document.documentElement.style.setProperty('--main-navigation-bg-color', skin.colors.mainNavigationBackground);
                document.documentElement.style.setProperty('--topbar-bg-color', skin.colors.topBarBackground);
                document.documentElement.style.setProperty('--navigation-text-active-color', skin.colors.navigationTextActive);
                document.documentElement.style.setProperty('--navigation-icon-text-color', skin.colors.navigationIconText);
                document.documentElement.style.setProperty('--navigation-icon-text-hover-color', skin.colors.navigationIconTextHover);
                document.documentElement.style.setProperty('--navigation-selected-item-background-color', skin.colors.navigationSelectedItemBackground);
                document.documentElement.style.setProperty('--navigation-selected-item-bar-color', skin.colors.navigationSelectedItemBar);
                document.documentElement.style.setProperty('--navigation-item-background-hover-color', skin.colors.navigationItemBackgroundHover);
                document.documentElement.style.setProperty('--footer-bg-color', skin.colors.footerBackground);
                document.documentElement.style.setProperty('--footer-text-color', skin.colors.footerText);
                document.documentElement.style.setProperty('--footer-links-color', skin.colors.footerLinks);
                document.documentElement.style.setProperty('--footer-links-hover-color', skin.colors.hoverFooterLinks);
                document.documentElement.style.setProperty('--login-footer-bg-color', skin.colors.loginFooterBackground);
                document.documentElement.style.setProperty('--login-footer-text-color', skin.colors.loginFooterText);
                document.documentElement.style.setProperty('--login-footer-links-color', skin.colors.loginFooterLinks);
                document.documentElement.style.setProperty('--login-footer-links-hover-color', skin.colors.loginHoverFooterLinks);
                document.documentElement.style.setProperty('--header-links-color', skin.colors.headerLinks);
                document.documentElement.style.setProperty('--header-links-hover-color', skin.colors.hoverHeaderLinks);
                document.documentElement.style.setProperty('--header-icons-color', skin.colors.headerIcon);
                document.documentElement.style.setProperty('--header-icons-hover-color', skin.colors.headerIconHover);
                document.documentElement.style.setProperty('--chart-data-series-1-color', skin.colors.chartDataSeries1);
                document.documentElement.style.setProperty('--chart-data-series-2-color', skin.colors.chartDataSeries2);
                document.documentElement.style.setProperty('--tab-border-color', skin.colors.tabBorder);
                document.documentElement.style.setProperty('--tab-active-tab-color', skin.colors.tabActiveTab);
                document.documentElement.style.setProperty('--tab-non-active-tab-color', skin.colors.tabNonActiveTab);
                document.documentElement.style.setProperty('--tab-text-color', skin.colors.tabText);
                document.documentElement.style.setProperty('--tab-hover-background-color', skin.colors.tabHoverBackground);
                document.documentElement.style.setProperty('--tab-hover-text-color', skin.colors.tabHoverText);
                document.documentElement.style.setProperty('--logo-padding-top', `${skin.layout.logoPaddingTop}px`);
                document.documentElement.style.setProperty('--logo-padding-left', `${skin.layout.logoPaddingLeft}px`);
                document.documentElement.style.setProperty('--login-image-alignment', `${skin.layout.loginImageAlignment}`);
                document.documentElement.style.setProperty('--onBoarding-image-alignment', `${skin.layout.onBoardingImageAlignment}`);
        
                document.documentElement.style.setProperty('--label-colour', skin.formStyle.labelColour);
                document.documentElement.style.setProperty('--form-field-border-colour', skin.formStyle.formFieldBorderColour);
        
                document.documentElement.style.setProperty('--primary-button-background-colour', skin.formStyle.primaryButtonBackgroundColour);
                document.documentElement.style.setProperty('--primary-button-text-colour', skin.formStyle.primaryButtonTextColour);
                document.documentElement.style.setProperty('--primary-button-border-colour', skin.formStyle.primaryButtonBorderColour);
                document.documentElement.style.setProperty('--primary-button-hover-background-colour', skin.formStyle.primaryButtonHoverBackgroundColour);
                document.documentElement.style.setProperty('--primary-button-hover-text-colour', skin.formStyle.primaryButtonHoverTextColour);
                document.documentElement.style.setProperty('--primary-button-hover-border-colour', skin.formStyle.primaryButtonHoverBorderColour);
                document.documentElement.style.setProperty('--primary-button-disabled-background-colour', skin.formStyle.primaryButtonDisabledBackgroundColour);
                document.documentElement.style.setProperty('--primary-button-disabled-text-colour', skin.formStyle.primaryButtonDisabledTextColour);
                document.documentElement.style.setProperty('--primary-button-disabled-border-colour', skin.formStyle.primaryButtonDisabledBorderColour);
        
                document.documentElement.style.setProperty('--secondary-button-background-colour', skin.formStyle.secondaryButtonBackgroundColour);
                document.documentElement.style.setProperty('--secondary-button-text-colour', skin.formStyle.secondaryButtonTextColour);
                document.documentElement.style.setProperty('--secondary-button-border-colour', skin.formStyle.secondaryButtonBorderColour);
                document.documentElement.style.setProperty('--secondary-button-hover-background-colour', skin.formStyle.secondaryButtonHoverBackgroundColour);
                document.documentElement.style.setProperty('--secondary-button-hover-text-colour', skin.formStyle.secondaryButtonHoverTextColour);
                document.documentElement.style.setProperty('--secondary-button-hover-border-colour', skin.formStyle.secondaryButtonHoverBorderColour);
                document.documentElement.style.setProperty('--secondary-button-disabled-background-colour', skin.formStyle.secondaryButtonDisabledBackgroundColour);
                document.documentElement.style.setProperty('--secondary-button-disabled-text-colour', skin.formStyle.secondaryButtonDisabledTextColour);
                document.documentElement.style.setProperty('--secondary-button-disabled-border-colour', skin.formStyle.secondaryButtonDisabledBorderColour);
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfa-pac" },
    [
      _c("div", { staticClass: "mfa-pac-body" }, [
        _c("p", { staticClass: "mb-2" }, [
          _c("span", {
            staticClass: "rich-text-container",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(
                  _vm.dictionary.beneficiaryModalHeaderUpdateMFASetting
                )
              )
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _c("span", {
            staticClass: "rich-text-container",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.dictionary.beneficiaryAlertMFAEnableTOTP)
              )
            }
          })
        ]),
        _vm._v(" "),
        _c("h3", {
          domProps: {
            innerHTML: _vm._s(_vm.$sanitize(_vm.dictionary.mfaFieldTOTPMobile))
          }
        }),
        _vm._v(" "),
        !_vm.isMobile
          ? _c("p", [
              _c("ol", [
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep1
                      )
                    )
                  }
                }),
                _vm._v(" "),
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep2
                      )
                    )
                  }
                }),
                _vm._v(" "),
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep3
                      )
                    )
                  }
                })
              ])
            ])
          : _c("p", [
              _c("ol", [
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPMobileStep1
                      )
                    )
                  }
                }),
                _vm._v(" "),
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPMobileStep2
                      )
                    )
                  }
                }),
                _vm._v(" "),
                _c("li", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.dictionary.beneficiaryAlertMFAEnableTOTPMobileStep3
                      )
                    )
                  }
                })
              ])
            ]),
        _vm._v(" "),
        !_vm.isMobile
          ? _c(
              "div",
              { staticClass: "qr-code-container" },
              [
                _vm.secretKeyUrl
                  ? _c("qr-code", {
                      attrs: { text: _vm.secretKeyUrl, size: 150 }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isMobile
          ? _c("p", [
              _c("span", {
                staticClass: "rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionary.beneficiaryAlertMFAEnterSecretDesktop
                    )
                  )
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _c("span", {
            staticClass: "rich-text-container",
            domProps: {
              innerHTML: _vm._s(
                _vm.$sanitize(_vm.dictionary.mfaFieldTOTPSecretCode)
              )
            }
          })
        ]),
        _vm._v(" "),
        _vm.mfaEnableDisableDataModel
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mfaEnableDisableDataModel.secret,
                  expression: "mfaEnableDisableDataModel.secret"
                }
              ],
              staticClass: "form-control input-lg secret-key-input",
              attrs: { name: "secretKey", readonly: "readonly" },
              domProps: { value: _vm.mfaEnableDisableDataModel.secret },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.mfaEnableDisableDataModel,
                    "secret",
                    $event.target.value
                  )
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.error && _vm.showError
              ? _c("alert-component", { attrs: { variant: "error" } }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("MfaButtons", {
        attrs: {
          dictionary: _vm.dictionary,
          hasContinueButton: true,
          hasOnlyCancelButton: false
        },
        on: {
          cancel: function($event) {
            return _vm.cancel()
          },
          continueMfa: function($event) {
            return _vm.$emit("continue-mfa")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div v-if="errors && errors.length" class="form-group">
        <alert variant="error">
            <ul>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </alert>
    </div>
</template>

<script>
    import Alert from "./alert.component.vue";

    export default {
        components: { Alert },
        props: {
            errors: Array
        }
    };
</script>

<template>
    <required-info-step v-if="requiredInfoModel"
                        id="personal-info-container"
                        :currentStep="currentStep"
                        :step="step"
                        :steps="steps"
                        :summaryType="summaryType"
                        :heading="dictionary.labels.step1"
                        :description="dictionary.labels.step1Description"
                        :dictionary="dictionary"
                        @edit-step="(val) => $emit('edit-step', val)">
        <template v-slot:previous>
            <div class="row">
                <RequiredInfoDataLabelRow class="col-md-6" :value="requiredInfoModel.firstNames" :label="dictionary.labels.firstNames" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" :value="requiredInfoModel.lastName" :label="dictionary.labels.lastName" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" :value="requiredInfoModel.dateOfBirth | toDate" :label="dictionary.labels.dateOfBirth" :isMandatory="true" />
            </div>
        </template>
        <template v-slot:current>
            <div class="row">
                <div class="col-md-6">
                    <input-element name="firstNames" v-model="requiredInfoModel.firstNames" :label="dictionary.labels.firstNames"
                                   :validationsList="accountholderValidations.firstNames"
                                   :readonly="!boundaries.mayEditFirstNames"
                                   :is-mandatory="true"></input-element>
                </div>
                <div class="col-md-6">
                    <input-element name="lastName" v-model="requiredInfoModel.lastName" :label="dictionary.labels.lastName"
                                   :validationsList="accountholderValidations.lastName"
                                   :readonly="!boundaries.mayEditLastName"
                                   :is-mandatory="true"></input-element>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-12 col-md-6">
                    <datepicker-element id="personal-date-of-birth"
                                        v-model="requiredInfoModel.dateOfBirth"
                                        :label="dictionary.labels.dateOfBirth"
                                        :validationsList="accountholderValidations.dateOfBirth"
                                        :readonly="!boundaries.mayEditDateOfBirth"
                                        :is-mandatory="true" :yearRange="'-150:+0'"
                                        :readonlyLabel="dictionary.accessibility.readonlyLabel"></datepicker-element>
                </div>
            </div>
            <div class="d-md-flex justify-content-md-between button-container">
                <button
                        class="btn primaryButton"
                        type="button"
                        @click="nextStep()"
                        :aria-describedby="nextStepAccessibilityLabel(step, steps)">
                    {{ dictionary.buttons.next }}
                </button>
            </div>
        </template>
    </required-info-step>
</template>

<script>
    import { required, requiredIf } from "vuelidate/lib/validators";
    import DataLabelRow from "../../../Beneficiaries/Assets/Vue/data-label-row.component.vue";
    import RequiredInfoStep from "./required-info-step.component.vue";
    import { notInFuture } from "./mixins/required-info-form-validation.mixin";
    import RequiredInfoDataLabelRow from "./required-info-data-label-row.component.vue";
    import { EventBus } from "Shared/event-bus";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";
    import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

    export default {
        mixins: [accessibility, stepperAccessibilityPreviousNextLabel],
        components: {
            DataLabelRow,
            RequiredInfoStep,
            RequiredInfoDataLabelRow
        },
        props: {
            dictionary: Object,
            currentStep: Number,
            step: Number,
            steps: Number,
            summaryType: String,
            requiredInfoData: Object,
            countries: Array,
            boundaries: Object
        },
        validations() {
            return {
                requiredInfoModel: {
                    firstNames: {
                        required: requiredIf(function () {
                            return this.boundaries.mayEditFirstNames;
                        })
                    },
                    lastName: {
                        required: requiredIf(function () {
                            return this.boundaries.mayEditLastName;
                        })
                    },
                    dateOfBirth: {
                        required: requiredIf(function () {
                            return this.boundaries.mayEditDateOfBirth;
                        }),
                        notInFuture: function (value) {
                            return !this.boundaries.mayEditDateOfBirth || notInFuture(value);
                        }
                    }
                }
            };
        },
        computed: {
            requiredInfoModel() {
                return this.requiredInfoData;
            },
            accountholderValidations() {
                return {
                    firstNames: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.firstNames,
                            error: this.$v.requiredInfoModel.firstNames.$error,
                            type: this.$v.requiredInfoModel.firstNames.required,
                            message: this.dictionary.validation.firstNameInvalid
                        }
                    ],
                    lastName: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.lastName,
                            error: this.$v.requiredInfoModel.lastName.$error,
                            type: this.$v.requiredInfoModel.lastName.required,
                            message: this.dictionary.validation.lastNameInvalid
                        }
                    ],
                    dateOfBirth: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.dateOfBirth,
                            error: this.$v.requiredInfoModel.dateOfBirth.$error,
                            type: this.$v.requiredInfoModel.dateOfBirth.required,
                            message: this.dictionary.validation.dobInvalid
                        },
                        {
                            id: 2,
                            field: this.$v.requiredInfoModel.dateOfBirth,
                            error: this.$v.requiredInfoModel.dateOfBirth.$error,
                            type: this.$v.requiredInfoModel.dateOfBirth.notInFuture,
                            message: this.dictionary.validation.dobInvalid
                        }
                    ]
                };
            }
        },
        methods: {
            nextStep() {
                EventBus.$emit("v-touch");
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return this.focusOnFirstInvalidInput();
                }
                this.$emit("next-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            },
            previousStep() {
                this.$emit("previous-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            }
        }
    };
</script>
<template>
   <p><strong class="form-input-label exercises-data_label">{{ label }}: </strong><span class="exercises-data_value">{{ value }}</span> 
   <template v-if="hasTooltip && !hideSecurityPriceInformationTooltip"><i :id="tooltipId" class="far fa-info-circle popover-info" aria-hidden="true"></i>
            <b-popover :target="tooltipId"
                       triggers="hover"
                       placement="top">
              <span>{{ tooltip }}</span>
            </b-popover></template></p>
</template>

<script>
    import { v4 as uuid } from "uuid";

    export default {
        props: {
            label: String,
            value: String,
            tooltip: String,
            hideSecurityPriceInformationTooltip: Boolean
        },
        computed: {
            hasTooltip() {
                return this.tooltip && this.tooltip.length > 0;
            },
            tooltipId() {
                return `block-tooltip-${uuid()}`;
            }
        }
    };
</script>
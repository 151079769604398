<template>
    <div class="mfa-pac">
        <div class="mfa-pac-body">
            <p class="mb-2">
                <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryModalHeaderUpdateMFASetting)"> </span>
            </p>
            <p>
                <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTP)"></span>
            </p>
            <h3 v-html="$sanitize(dictionary.mfaFieldTOTPMobile)"></h3>
            <p v-if="!isMobile">
                <ol>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep1)"></li>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep2)"></li>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPDesktopStep3)"></li>
                </ol>
            </p>
            <p v-else>
                <ol>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPMobileStep1)"></li>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPMobileStep2)"></li>
                    <li v-html="$sanitize(dictionary.beneficiaryAlertMFAEnableTOTPMobileStep3)"></li>
                </ol>
            </p>
            <div v-if="!isMobile" class="qr-code-container">
                <qr-code v-if="secretKeyUrl" :text="secretKeyUrl" :size="150"></qr-code>
            </div>
            <p v-if="!isMobile">
                <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryAlertMFAEnterSecretDesktop)"> </span>
            </p>
            <p>
                <span class="rich-text-container" v-html="$sanitize(dictionary.mfaFieldTOTPSecretCode)"> </span>
            </p>
            <input v-if="mfaEnableDisableDataModel" name="secretKey" readonly="readonly" class="form-control input-lg secret-key-input" v-model="mfaEnableDisableDataModel.secret">
            <div>
                <alert-component  v-if="error && showError" variant="error">{{ error }}</alert-component>
            </div>
        </div>
        <MfaButtons :dictionary="dictionary" :hasContinueButton="true" :hasOnlyCancelButton="false" @cancel="cancel()" @continueMfa="$emit('continue-mfa')" />
    </div>
</template>

<script>
    import MfaButtons from "../mfa-buttons.component.vue";


    export default {
        components: {
            MfaButtons
        },
        props: {
            dictionary: Object,
            mfaEnableDisableDataModel: Object,
            isMobile:Boolean,
            error:String,
            showError:Boolean
        },
        methods: {
            cancel() {
                this.$emit("cancel");
            }
        },
        computed: {
            secretKeyUrl() {
                let issuer = 'tOption';
                let appName = 'tOption';

                return `otpauth://totp/${appName}?secret=${this.mfaEnableDisableDataModel.secret}&issuer=${issuer}`;
            }
        }
    };
</script>

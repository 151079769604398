<template>
    <portlet-layout ref="portfolioOverview" :title="reportCustomTitle" :is-custom-title="true" :is-custom-body="true">
        <template v-slot:customTitle>
            <a id="printPage" href="javascript:void(0)" v-if="!isMobile" role="button" @click="printPage" :title="portfolioOverviewDictionary.labels.linkPrint"><span aria-hidden="true" class="title-action-icon"><i class="far fa-print p-1"></i></span>{{portfolioOverviewDictionary.labels.linkPrint}}</a>
        </template>
        <template v-slot:body>
          <loading-spinner :active="isSubmitting"></loading-spinner>
          <alert-component v-if="success" variant="success">{{ success }}</alert-component>
          <alert-component v-if="error" variant="error">{{ error }}</alert-component> 
          <div id="portfolio-values-container">
            <PortfolioPlanValues v-if="reportOverview.values.length" :title="portfolioOverviewDictionary.labels.portfolioTotal" :values="reportOverview.values"></PortfolioPlanValues> 
          </div>          
          <div v-if="mostOfSecurityPricesValuesAreNotZero()" id="portfolio-plans-container">
              <div class="p-3"><strong>{{portfolioOverviewDictionary.labels.breakdown}}</strong></div>
              <div v-for="(plan,index) in reportOverview.plans" :key="`${plan.planName}-${index}`">
                <portlet-row :title="plan.planName" class="plan-portlet-row">
                    <PortfolioPlanValues v-if="plan.values.length" :title="portfolioOverviewDictionary.labels.planValue" :values="plan.values"></PortfolioPlanValues>   
                    <PortfolioOverviewTable :dictionary="portfolioOverviewDictionary" :records="[...plan.moneyPositions, ...plan.securityPositions]" :isMobile="isMobile" :isTablet="isTablet"></PortfolioOverviewTable>                     
                </portlet-row>                      
              </div>
          </div>
          <div v-else>
              <div class="p-3"><strong>{{portfolioOverviewDictionary.labels.errorNoResultsReturned}}</strong></div>
          </div>
          <div class="d-md-flex justify-content-end">
              <div>                
                <button v-if="isMobile" type="button"  class="btn secondaryButton pl-2" @click="printPage">{{portfolioOverviewDictionary.labels.linkPrint}}</button>
                <button type="button"  class="btn primaryButton" :disabled="!mostOfSecurityPricesValuesAreNotZero()" @click="sendToInboxPortfolio">{{portfolioOverviewDictionary.labels.btnSendToInbox}}</button>
              </div>
          </div>
        </template>
    </portlet-layout>
</template>

<script>
import { EventBus } from "Shared/event-bus";
import PortfolioOverviewTable from "./portfolio-overview-table.component.vue";
import PortfolioPlanValues from "./portfolio-plan-values.component.vue";

export default {
  components: {
        PortfolioOverviewTable,
        PortfolioPlanValues,
    },
    props: {
        portfolioOverviewDictionary: Object,
        reportOverview: Object,
        model: Object,
        reportCustomTitle: String,
        success: String,
        error: String,
        isSubmitting: Boolean,
        isMobile: Boolean,
        isTablet: Boolean,
        isValid: Boolean
    },
    methods: {
        sendToInboxPortfolio() {
          EventBus.$emit("sendToInboxPortfolio", this.model);
        },
        printPage(){
          EventBus.$emit('printPage')
        },
        mostOfSecurityPricesValuesAreNotZero() {
          if(this.reportOverview.plans != null && this.reportOverview.plans.length > 0) {

            const AreMostOfSecurityPositionValuesEqualsToZero = (secPos) => 
            (secPos.acceptedAmount + secPos.balance + secPos.creditedAmount + secPos.debitedAmount == 0);  
            
            let result = this.reportOverview.plans.every(x=> x.securityPositions.every(y => AreMostOfSecurityPositionValuesEqualsToZero(y)));
            return !result;
          }
          return false;
        }                
    }
}
</script>
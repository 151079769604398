<template>
    <component-spinner v-if="isLoading" :spin="isLoading"></component-spinner>
    <LoginTemplate v-else-if="dictionary && changePasswordBoundaries">
        <template>
            <template slot="title">
                <h1 class="sr-only welcome-text">{{dictionary.accountPageDictionary.passwordExpired}}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{dictionary.accountPageDictionary.passwordExpired}}</p>
                <hr>
            </template>
            <template slot="body">
                <div class="login-form">
                    <div class="forgot-password-message">
                        <p class="text-left pb-2">{{dictionary.labels.passwordExpiredTitle}}</p>
                    </div>
                    <alert-component v-if="error" variant="error">{{ error }}</alert-component>
                    
                    <loading-spinner :active="isSubmitting"></loading-spinner>
                    <div class="mb-lg-3" id="loginForm">
                        <ChangePasswordInputs :dictionary="accountDictionary"
                                              :model="model"
                                              :showPasswordValidationNote="true"
                                              :boundaries="changePasswordBoundaries"
                                              @back="back" @submit="submit">
                        
                            <template v-slot:buttons="{ back, submit }">
                                <div class="form-group d-md-flex justify-content-md-between button-container">
                                    <button class="btn secondaryButton" @click="back"> {{ commonDictionary.btnGoBack }}</button>
                                    <button class="btn primaryButton" @click="submit">{{ commonDictionary.btnContinue }}</button>
                                </div>
                            </template>
                        </ChangePasswordInputs>
                    </div>
                </div>
            </template>
        </template>
    </LoginTemplate>
</template>

<script>
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import LoginTemplate from "../login-template.component.vue";
    import { get, getAll, post } from "api";
    import ChangePasswordInputs from 'Shared/Components/Vue/ChangePassword/change-password-inputs.component.vue'
    import { EventBus } from "Shared/event-bus";

    export default {
        components: {
            LoginTemplate,
            ChangePasswordInputs
        },
        mixins: [ScreenSizeMixin],
        data() {
            return {
                model: {
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmed: ""
                },
                error: null,
                isLoading: true,
                isSubmitting: false,
                dictionary: null,
                accountDictionary: null,
                commonDictionary: null,
                changePasswordBoundaries: null
            };
        },
        beforeCreate() {
            get(
                "/account/ChangePasswordBoundaries"
                , (response) => {
                    this.changePasswordBoundaries = response.data;

                    if (!this.changePasswordBoundaries.isPasswordExpired) {
                        this.$router.push({ path: '/' });
                    }
                },
                this,
                () => { this.isLoading = false; },
                () => { location.replace(`/`); });
        },
        created() {
            this.loadDictionaries();
        },
        mounted() {
            EventBus.$on("event-language-changed", () => {
                this.loadDictionaries();
            });
        },
        methods: {
            submit() {
                this.isSubmitting = true;
                post("/account/ChangePassword",
                    this.model,
                    (response) => {
                        this.logout(false);
                    },
                    this,
                    () => { this.isSubmitting = false; },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            back() {
                this.logout(true);
            },
            logout(isBack) {
                post(
                    '/Login/Logout',
                    {},
                    (res) => {
                        if (!isBack) {
                            EventBus.$emit("event-reload-layout", '/login/passwordChangedSuccessful');
                        } else {
                            EventBus.$emit("event-reload-layout", '/login');
                        }
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    }
                );
            },
            loadDictionaries() {
                getAll([
                    "/login/Dictionary",
                    "/login/CommonDictionary",
                    "/account/Dictionary"
                ], (responses) => {
                    this.dictionary = responses[0].data;
                    this.commonDictionary = responses[1].data;
                    this.accountDictionary = responses[2].data;
                },
                    this,
                    () => { this.isLoading = false; },
                    () => { this.$router.push({ path: '/' }); });
            }
        }
    }

</script>
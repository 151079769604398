<template>
    <form class="subscription-form" v-if="scenario">
        <alert-component class="subscription-form-alert" v-if="error" variant="error">{{ error }}</alert-component>
        <loading-spinner :active="isSubmitting"></loading-spinner>
        <SubscriptionScenario :dictionary="dictionary" v-model="scenario" :validationLists="validationLists" :isSubscriptions="isSubscriptions" :isAcceptCollumn="isAcceptCollumn" :isValueLocked="isValueLocked" />
        <div class="mt-3 button-container d-md-flex justify-content-end">
            <div>
                <button class="btn secondaryButton" type="button" @click="cancel">{{dictionary.widget.btnGoBack}}</button>
            </div>
            <div>
                <button class="btn primaryButton" type="button" @click="submit">{{ primaryButtonText }}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import { post } from "api";
    import SubscriptionScenario from "./subscription-scenario.component.vue";
    import { EventBus } from "Shared/event-bus";
    import { required } from "vuelidate/lib/validators";
    import { mustBeDecimal } from "Shared/Components/Vue/Validations/custom-validator"
    import numeral from 'numeral'
    import { accessibility } from "Shared/Mixins/accessibility.mixin.js";

    export default {
        mixins: [accessibility],
        components: {
            SubscriptionScenario,
        },
        props: {
            dictionary: Object,
            subscriptionScenario: Object,
            isSubscriptions: Boolean,
            participationSubscription: Object
        },
        data() {
            return {
                scenario: null,
                error: null,
                isSubmitting: null,
                isValueLocked: false
            }
        },
        computed: {
            validationLists() {
                var amountValidationList = new Array();

                for (var i = 0; i < this.scenario.offers.length; i++) {

                    amountValidationList.push({
                        id: 1,
                        participationSubscriptionOfferId: this.scenario.offers[i].participationSubscriptionOfferId,
                        error: this.$v.scenario.offers.$each[i].scenarioOffer.amount.$error,
                        type: this.$v.scenario.offers.$each[i].scenarioOffer.amount.required,
                        message: this.dictionary.widget.errorInvalidAmount
                    });

                    amountValidationList.push({
                        id: 2,
                        participationSubscriptionOfferId: this.scenario.offers[i].participationSubscriptionOfferId,
                        error: this.$v.scenario.offers.$each[i].scenarioOffer.amount.$error,
                        type: this.$v.scenario.offers.$each[i].scenarioOffer.amount.mustBeDecimal,
                        message: this.dictionary.widget.errorInvalidAmount
                    });

                    amountValidationList.push({
                        id: 3,
                        participationSubscriptionOfferId: this.scenario.offers[i].participationSubscriptionOfferId,
                        error: this.$v.scenario.offers.$error && !this.$v.scenario.offers.invalidAmount,
                        type: this.$v.scenario.offers.invalidAmount,
                        message: this.dictionary.widget.errorInvalidAmount
                    })
                }

                return {
                    amount: amountValidationList
                };
            },
            primaryButtonText() {
                return this.participationSubscription.presentationMode.value == 'spmAcceptation' ? this.dictionary.table.presentationModeAccept : this.dictionary.widget.buttonSubscribe;
            },
            isAcceptCollumn() {
                return this.participationSubscription.presentationMode.value == 'spmAcceptation' ? true : false;
            },
            actionTarget() {
                return this.isSubscriptions ? "subscriptions" : "offerings";
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    this.focusOnFirstInvalidInput();
                    return;
                }

                this.isSubmitting = true;
                this.error = null;

                var subscribeSubscribtion = {
                    participationSubscriptionScenarioId: this.scenario.participationSubscriptionScenarioId,
                    offers: this.scenario.offers.map((offer) => {
                        return {
                            participationSubscriptionOfferId: offer.participationSubscriptionOfferId,
                            amount: offer.scenarioOffer.amount ? numeral(offer.scenarioOffer.amount).value() : null
                        }
                    })
                }

                post('/Subscriptions/Subscribe',
                    subscribeSubscribtion,
                    (response) => {
                        this.$router.push({
                            name: this.actionTarget + '-confirmation',
                            params: {
                                confirmationData: response.data
                            }
                        });
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    });
            },
            cancel() {
                this.$router.push({ path: "/" + this.actionTarget });
            },
        },
        mounted() {
            var offers = this.subscriptionScenario.offers.map((offer) => {
                return {
                    participationSubscriptionOfferId: offer.participationSubscriptionOfferId,
                    scenarioOffer: {
                        offer: offer,
                        amount: 
                            this.isSubscriptions &&
                            this.participationSubscription.presentationMode.value == 'spmAcceptation' &&
                            offer.minimumAwardedAmount === offer.maximumAwardedAmount ? 
                            this.$setDecimalSeparatorBySettings(offer.maximumAwardedAmount) :
                            null
                    }
                }
            });
            this.scenario = this.subscriptionScenario;
            this.scenario.offers = offers;
        },
        validations() {
            return {
                scenario: {
                    offers: {
                        $each: {
                            scenarioOffer: {
                                amount: {
                                    mustBeDecimal,
                                    required
                                }
                            }
                        },
                        invalidAmount: function (value) {
                            if (!value) {
                                return false;
                            }

                            return value.some(x => numeral(x.scenarioOffer.amount).value() >= 0);
                        },
                    }
                }
            }
        }
    };
</script>
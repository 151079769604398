var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.skin
    ? _c(
        "nav",
        {
          staticClass: "navbar topnavbar",
          attrs: { "aria-label": "Top navigation" }
        },
        [
          _c("div", { staticClass: "navbar-header" }, [
            _c(
              "div",
              { attrs: { id: "navbar-logo-vm" } },
              [
                _c("logo-image", {
                  attrs: {
                    "logo-desktop": _vm.skin.images.logo,
                    "logo-mobile": _vm.skin.images.logoMobile,
                    "logo-favicon": _vm.skin.images.favicon,
                    "logo-alignment": _vm.skin.layout.logoAlignment,
                    "logo-overlap": _vm.skin.layout.logoOverlap
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.currentEnvironment
            ? _c("CurrentEnvironment", {
                attrs: { text: _vm.currentEnvironment }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav" }, [
            !_vm.isMobile && _vm.showHeaderLoginHelp
              ? _c(
                  "li",
                  { staticClass: "nav-item pr-1" },
                  [
                    _vm.dictionary
                      ? _c("HeaderLoginHelp", {
                          attrs: { label: _vm.dictionary.loginHelp }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showHeaderLoginLanguage
              ? _c(
                  "li",
                  { staticClass: "nav-item pl-1 pr-lg-2" },
                  [
                    _c("HeaderLoginLanguage", {
                      attrs: { "current-language": _vm.currentLanguage }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="vesting-award-details vesting-expanded-content">
    <div class="row">
        <div class="col-md-6">
            <VestingDetailsContentRow :label="dictionary.details.name" :value="details.purchase" />
            <VestingDetailsContentRow :label="dictionary.details.date" :value="details.date" />
            <VestingDetailsContentRow :label="dictionary.details.state" :value="details.state" />
        </div>
        <div class="col-md-6">
            <VestingDetailsContentRow :label="dictionary.details.amount" :value="details.amount" />
            <VestingDetailsContentRow :label="dictionary.details.price" :value="details.price" />
            <VestingDetailsContentRow :label="dictionary.details.baseAmount" :value="details.baseAmount" />
        </div>
    </div>
</div>
</template>

<script>
import VestingDetailsContentRow from './vesting-details-content-row.component.vue';

export default {
    components: {
        VestingDetailsContentRow
    },
    props: {
        details: Object,
        dictionary: Object
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors && _vm.errors.length > 0
        ? [_c("form-error-alert-component", { attrs: { errors: _vm.errors } })]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasHeaders
        ? _vm._l(this.boundaries.headers, function(header, index) {
            return _c("div", {
              key: index,
              staticClass: "mb-2 rich-text-container",
              domProps: { innerHTML: _vm._s(_vm.$sanitize(header)) }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.requiredInfo
        ? _c(
            "timeline-container",
            [
              _c(
                "timeline-step",
                { attrs: { "current-step": _vm.currentStep, step: 1 } },
                [
                  _c("RequiredInfoMifidPersonalInformation", {
                    staticClass: "timeline-step",
                    attrs: {
                      "current-step": _vm.currentStep,
                      step: 1,
                      steps: _vm.steps,
                      summaryType: _vm.summaryType,
                      dictionary: _vm.dictionary,
                      boundaries: _vm.boundaries,
                      "required-info-data": _vm.requiredInfo
                    },
                    on: {
                      "next-step": _vm.nextStep,
                      "previous-step": _vm.previousStep,
                      "edit-step": _vm.editStep
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "timeline-step",
                { attrs: { "current-step": _vm.currentStep, step: 2 } },
                [
                  _c("RequiredInfoMifidCitizenshipInformation", {
                    staticClass: "timeline-step",
                    attrs: {
                      "current-step": _vm.currentStep,
                      step: 2,
                      steps: _vm.steps,
                      summaryType: _vm.summaryType,
                      dictionary: _vm.dictionary,
                      boundaries: _vm.boundaries,
                      countries: _vm.knownCountries,
                      "required-info-data": _vm.requiredInfo
                    },
                    on: {
                      "next-step": _vm.nextStep,
                      "previous-step": _vm.previousStep,
                      "edit-step": _vm.editStep
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.boundaries.canViewFiscalNumber
                ? _c(
                    "timeline-step",
                    { attrs: { "current-step": _vm.currentStep, step: 3 } },
                    [
                      _c("RequiredInfoMifidFiscalInformation", {
                        staticClass: "timeline-step",
                        attrs: {
                          "current-step": _vm.currentStep,
                          step: 3,
                          steps: _vm.steps,
                          summaryType: _vm.summaryType,
                          dictionary: _vm.dictionary,
                          boundaries: _vm.boundaries,
                          "required-info-data": _vm.requiredInfo
                        },
                        on: {
                          "next-step": _vm.nextStep,
                          "previous-step": _vm.previousStep,
                          "edit-step": _vm.editStep
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("RequiredInfoMifidButtons", {
        attrs: {
          dictionary: _vm.dictionary,
          currentStep: _vm.currentStep,
          steps: _vm.steps,
          errors: _vm.errors
        },
        on: {
          skip: function($event) {
            return _vm.$emit("skip")
          },
          submit: function($event) {
            return _vm.$emit("submit")
          },
          edit: function($event) {
            return _vm.editSummary()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
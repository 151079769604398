<template>
    <div>
        <div v-if="displayTaxData(offer)" class="security-subscription-tax">
            <div>
                <span class="font-weight-bold security-subscription-tax-header">{{dictionary.table.taxPayableHeadingLabel}}</span>
                <SubscriptionDetailsRow v-if="offer.taxableAmountFormatted" :label="dictionary.table.subscriptionsLabelFullTaxableAmount" :value="replaceNullOrEmpty(offer.taxableAmountFormatted)" :isMobile="isMobile" />
                <SubscriptionDetailsRow v-if="offer.taxPayableAmountFormatted" :label="dictionary.table.taxPayableLabel" :value="replaceNullOrEmpty(offer.taxPayableAmountFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="offer.taxMonthlyDeductionAmountFormatted" :label="dictionary.table.monthlyDeductionLabel" :value="replaceNullOrEmpty(offer.taxMonthlyDeductionAmountFormatted)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="offer.nrOfTaxDeductionPeriods" :label="dictionary.table.noOfDeductionPeriodsLabel" :value="replaceNullOrEmpty(offer.nrOfTaxDeductionPeriods)" :isMobile="isMobile" />
            </div>
        </div>
        <div v-if="displayTaxComplementaryData(offer)" class="security-subscription-tax-complement">
            <div>
                <SubscriptionDetailsRow v-if="offer.fiscalCountryDescription" :label="dictionary.table.subscriptionsLabelFiscalCountry" :value="replaceNullOrEmpty(offer.fiscalCountryDescription)" :isMobile="isMobile" />
                <SubscriptionDetailsRow v-if="offer.taxationMoment" :label="dictionary.table.subscriptionsLabelTaxationType" :value="replaceNullOrEmpty(offer.taxationMoment.description)" :isMobile="isMobile" />
                <SubscriptionDetailsRow v-if="offer.taxPercentage != null" :label="dictionary.table.subscriptionsLabelTaxRate" :value="displayPercentageValue(offer.taxPercentage)" :isMobile="isMobile"/>
                <SubscriptionDetailsRow v-if="offer.taxAllowancePercentage!=null && offer.taxAllowancePercentage!=0" :label="dictionary.table.subscriptionsLabelAllowance" :value="displayPercentageValue(offer.taxAllowancePercentage)" :isMobile="isMobile" />            
            </div>
        </div>

    </div>
</template>

<script>
    import SubscriptionDetailsRow from "./subscription-details-row.component.vue";

    export default {
        components: {
            SubscriptionDetailsRow
        },
        props: {
            dictionary: Object,
            offer: Object,
            isMobile:Boolean
        },
        
        methods: {
            displayTaxData(offer) {
                return !!offer.taxPayableAmountFormatted || !!offer.taxMonthlyDeductionAmountFormatted || !!offer.nrOfTaxDeductionPeriods || (offer.taxableAmountFormatted != null && offer.taxableAmount !=0)
            },
            displayTaxComplementaryData(offer) {
                return !!offer.fiscalCountryDescription || !!offer.taxationMoment || offer.taxPercentage != null || offer.taxAllowancePercentage != null 
            },
            displayPercentageValue(value) {
                var strResult = this.replaceNullOrEmpty(value);
                if (strResult == '-')
                    return strResult;
                else
                    return strResult + '%';
            }
        }
    }
</script>
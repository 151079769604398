<template>
    <div>
        <vue-topprogress ref="topProgress"></vue-topprogress>
        <div>
            <nav class="navbar topnavbar" aria-label="Top navigation">
                <div class="navbar-header">
                    <div>
                        <logo-image :logo-desktop="skin.images.logo" :logo-mobile="skin.images.logoMobile" :logo-favicon="skin.images.favicon" :logo-alignment="skin.layout.logoAlignment" :logo-overlap="skin.layout.logoOverlap"></logo-image>
                    </div>
                </div>

                <div class="header-additional-info-container">
                    <CurrentEnvironment v-if="!isLoading && headerAuthenticated" :text="headerAuthenticated.currentEnvironment"></CurrentEnvironment>
                    <LocalhrContainer v-if="isImpersonated" :dictionary="dictionary.header" />
                </div>
               
                <SecurityPricesDropdown v-if="!isLoading && headerAuthenticated && !isDisclaimer && !toggles.topbarToggles.hideSecurityPriceDropdown"
                                        position="top" :dictionary="dictionary.securityPricesDropdown" :securities="securities" :is-loading="isLoading" />
                <ul class="navbar-nav">
                    <template v-if="!isDisclaimer">
                        <li class="nav-item d-lg-none justify-content-around nav-bar-no-desktop">
                            <div v-if="!isLoading && headerAuthenticated && showAvatarIcon" class="p-1 personal-menu">
                                <a class="nav-link sidebar-toggle" href="javascript:void(0)" @click="toggleBodyClass('aside-toggled', 'personal-menu')">
                                    <span class="nav-icon">
                                        <i class="far fa-user fa-2x" aria-hidden="true"></i>
                                        <span class="sr-only">{{ dictionary.sidebar.navPersonalData }}</span>
                                    </span>
                                    <span class="personal-menu-btn-close">
                                        <i class="far fa-times fa-2x pr-2" aria-hidden="true"></i>
                                        <span class="sr-only">{{ dictionary.sidebar.navMobileMenuCloseSRText }}</span>
                                    </span>
                                </a>
                            </div>
                            <div class="p-1">
                                <router-link class="nav-link" to="/inbox" :title="dictionary.sidebar.navInbox">
                                    <span class="nav-icon"><i class="far fa-envelope fa-2x" aria-hidden="true"></i></span>
                                    <span class="sr-only">{{ dictionary.sidebar.navInbox }}</span>
                                </router-link>
                            </div>
                            <div class="navigation-menu p-1" aria-live="polite">
                                <a class="nav-link sidebar-toggle" href="javascript:void(0)" @click="toggleBodyClass('aside-toggled', 'navigation-menu')">
                                    <span class="nav-icon">
                                        <i class="far fa-bars fa-2x" aria-hidden="true"></i>
                                        <span class="sr-only"> {{ dictionary.sidebar.navMobileMenuOpenSRText }}</span>
                                    </span>
                                    <span class="navigation-menu-btn-close">
                                        <i class="far fa-times fa-2x pr-2" aria-hidden="true"></i>
                                        <span class="sr-only">{{ dictionary.sidebar.navMobileMenuCloseSRText }}</span>
                                    </span>
                                </a>

                            </div>
                        </li>
                        <li v-if="!isLoading && headerAuthenticated && showAvatarIcon" class="nav-item personal-data">
                            <ul>
                                <PersonalDataDropdown :beneficiary-boundaries-data-model="beneficiaryBoundariesDataModel" :dictionary="dictionary" :toggles="toggles" :account-data-model="accountDataModel" :user-full-name="headerAuthenticated.fullName" :nav-personal-data-label="dictionary.sidebar.navPersonalData" />
                            </ul>
                        </li>
                        <li class="nav-item nav-bar-desktop">
                            <router-link class="nav-link" to="/inbox" :title="dictionary.sidebar.navInbox">
                                <span class="nav-icon"><i class="far fa-envelope" aria-hidden="true"></i></span>
                                <span class="sr-only">{{ dictionary.sidebar.navInbox }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item pr-4 nav-bar-desktop" :title="dictionary.sidebar.navLogout">
                            <a :href="$sanitize(logoutEndpoint)" class="nav-link">
                                <button type="submit" id="LogOutButton" class="btn btn-link btn-xl btn-signout">
                                    <span class="nav-icon"><i class="far fa-sign-out-alt" aria-hidden="true"></i></span>
                                    <span class="sr-only">{{ dictionary.sidebar.navLogout }}</span>
                                </button>
                            </a>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nav-item pr-4" :title="dictionary.sidebar.navLogout">
                            <a :href="$sanitize(logoutEndpoint)" class="nav-link">
                                <button type="submit" id="LogOutButton" class="btn btn-link btn-xl btn-signout">
                                    <span class="nav-icon-not-scalable"><i class="far fa-sign-out-alt" aria-hidden="true"></i></span>
                                    <span class="sr-only">{{ dictionary.sidebar.navLogout }}</span>
                                </button>
                            </a>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import { getAll, get } from 'api';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import { beneficiaryTabsViewRightsMixin } from "Features/Beneficiaries/Assets/Vue/Main/mixins/beneficiary-tabs-view-rights.mixin.js";
    import SecurityPricesDropdown from "Features/SecurityPricesDropdown/Assets/Vue/security-price.dropdown.component.vue";
    import PersonalDataDropdown from 'Features/PersonalDataDropdown/Assets/Vue/personal-data.dropdown.component.vue';
    import { EventBus } from "Shared/event-bus";
    import { vueTopprogress } from 'vue-top-progress';
    import  CurrentEnvironment from './header-current-environment.component.vue';
    import LocalhrContainer from './localhr-container.component.vue';

    const interval = 300000;

    export default {
        name: "header-login-container",
        mixins: [ScreenSizeMixin, beneficiaryTabsViewRightsMixin],
        props: {
            skin: Object,
            dictionary: Object,
            logoutEndpoint: String,
            isDisclaimer: Boolean,
            isImpersonated: Boolean
        },
        components: {
            SecurityPricesDropdown,
            PersonalDataDropdown,
            vueTopprogress,
            CurrentEnvironment,
            LocalhrContainer
        },
        data() {
            return {
                beneficiaryBoundariesDataModel: {},
                accountDataModel: null,
                headerAuthenticated: {},
                isLoading: true,
                securities: [],
                securityPricesUpdateInterval: null,
                toggles: null
            }
        },
        watch: {
            isDisclaimer(newVal, oldVal) {
                if (!newVal && oldVal) {
                    this.getAdditionalData();
                }
            }
        },
        computed: {
            beneficiaryToggles() {
                return this.toggles.beneficiaryToggles;
            },
        },
        mounted() {
            this.securityPricesUpdateInterval = setInterval(this.getSecurityPrices, interval);
            this.$refs.topProgress.start();
        },
        beforeDestroy() {
            if (!!this.securityPricesUpdateInterval) {
                clearInterval(this.securityPricesUpdateInterval);
                this.securityPricesUpdateInterval = null;
            }
        },
        beforeCreate() {
            get('/Layout/HeaderAuthenticated',
                (response) => {
                    this.headerAuthenticated = response.data
                    this.toggles = this.headerAuthenticated.toggles;

                    if (!this.isDisclaimer) {
                        this.getAdditionalData();
                    } else {
                        this.$refs.topProgress.done();
                    }
                    this.isLoading = false;
                }, this);
        },
        methods: {
            getAdditionalData() {

                this.getPersonalData();

                if (!this.toggles.topbarToggles.hideSecurityPriceDropdown) {
                    this.getSecurityPrices();
                }

            },
            getSecurityPrices() {
                get('/SecurityPricesDropdown/SecurityPrices',
                    (response) => {
                        if(response.data instanceof Array) {
                            this.securities = response.data;
                        }
                        
                        EventBus.$emit('security-prices-data', this.dictionary.securityPricesDropdown, this.securities);
                        this.isLoading = false;
                    }, this);
            },
            getPersonalData() {
                getAll([
                    '/Beneficiaries/BeneficiaryBoundaries',
                    '/Beneficiaries/BeneficiaryAccountData'
                ],
                    (response) => {

                        this.beneficiaryBoundariesDataModel = response[0].data;
                        this.accountDataModel = response[1].data;

                        EventBus.$emit('personal-data-dropdown', this.dictionary,
                            this.toggles, this.accountDataModel, this.beneficiaryBoundariesDataModel);
                        this.$refs.topProgress.done();
                        this.isLoading = false;
                    }, this);
            },
            toggleBodyClass(...args) {
                args.map(e => document.body.classList.toggle(e))
            }
        }
    };
</script>
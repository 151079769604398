<template>
    <dl class="order-details-list">
        <OrderDataLabel v-if="!toggles.hideOrderState" :label="dictionary.modal.state" :value="instructionData.State" custom-class-data="font-weight-bold"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.reference" :value="instructionData.Reference"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.positionName" :value="instructionData.SecurityName"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.orderType" :value="orderType"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.instructionDate" :value="instructionData.SubmissionDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.expirationDate" :value="instructionData.ExpirationDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.quantity" :value="instructionData.QuantityFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.limitPrice" :value="instructionData.LimitPriceFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.maxFeeReservationAmount" :value="instructionData.MaxFeeReservationAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.expectedOrderAmount" :value="instructionData.ExpectedOrderAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.processedAmount" :value="instructionData.ProcessedAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.cancelledAmount" :value="instructionData.CancelledAmountFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.averagePrice" :value="instructionData.AveragePriceFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.lastExecutionDate" :value="instructionData.LastExecutionDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.totalPaidFees" :value="instructionData.TotalPaidFeesFormatted"></OrderDataLabel>
        <OrderDataLabel v-if="toggles.displayOrderTaxDetails && !isCancelled && (instructionData.TaxRate !== null)" :label="dictionary.modal.taxRate" :value="instructionData.TaxRate"></OrderDataLabel>
        <OrderDataLabel v-if="toggles.displayOrderTaxDetails && !isCancelled && (taxAmount !== null)" :label="dictionary.modal.taxAmount" :value="taxAmount"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.totalTransactionValue" :value="instructionData.TotalTransactionValueFormatted"></OrderDataLabel>
    </dl>
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
export default {
  components: {
      OrderDataLabel,
  },
  props: {
    instructionData: Object,
    dictionary: Object,
    toggles: Object
  },
  computed: {
    orderType() {
          return this.instructionData.OrderType === "Sell" ? this.dictionary.confirmation.orderTypeSell : this.dictionary.confirmation.orderTypeBuy;
    },
    taxAmount() {
      return this.instructionData.State === "Succeeded" ? this.instructionData.TotalPaidTaxesFormatted : this.instructionData.EstimatedTaxesFormatted;
    },
    isCancelled() {
      return this.instructionData.State === "Rejected" || this.instructionData.State === "Cancelled";
    }
  }
};
</script>
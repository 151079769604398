import axios from 'axios'

var _sessionRefreshAttempts = 0;

export const headers = { headers: { Pragma: 'no-cache' } };
export const errorMessage = "Something went wrong. Try refreshing the page and if the problem persists contact your system administrator.";

const handleError = (error, context, customErrorHandlingCallback) => {
    let customErrorHandling = true;

    if (Array.isArray(error)) {
        customErrorHandling = error.every(err => {
            return !forcedLogout(err, context);
        });
    } else {
        customErrorHandling = !forcedLogout(error, context);
    }

    if (customErrorHandling) {
        if (customErrorHandlingCallback) {
            customErrorHandlingCallback(error, context);
        } else {
            if (Array.isArray(error)) {
                error.every(err => {
                    return !tryToShowErrorToast(err, context);
                });
            } else {
                tryToShowErrorToast(error, context);
            }
        }
    }
}

const tryToShowErrorToast = (error, context) => {
    let statusCode = error?.response?.status;
    if ((error.message != undefined && error.message != 'Request aborted') &&
        (statusCode === context.$HttpStatusCodes.BAD_REQUEST ||
            statusCode === context.$HttpStatusCodes.INTERNAL_SERVER_ERROR)) {

        context.$bvToast.toast(
            errorMessage,
            context.$errorMessageConfig());
        return true;
    }
}

const forcedLogout = (error, context, skipHeartbeat = true) => {
    let statusCode = error?.response?.status;
    if ((!error?.response?.config?.url?.includes("heartbeat") || !skipHeartbeat) &&
        (statusCode === context.$HttpStatusCodes.FORBIDDEN ||
            statusCode === context.$HttpStatusCodes.UNAUTHORIZED)) {

        if (!context.$route?.path?.includes("login")) {
            window.location.replace("/logout");
            context.$session.set(context.$SessionKeys.ForcedLogout, true);
            return true;
        }
    }

    return false;
}

const checkSesionStatus = (response, context, successCallback) => {
    return head(
        '/api/session/heartbeat',
        () => {
            if (successCallback) {
                successCallback(response, context);
            }
        },
        context,
        () => { },
        (error) => {
            if (!forcedLogout(error, context, false)) {
                throw response;
            }
        });
}

const isResponseValid = (response) => {
    if (Array.isArray(response)) {
        return response.every(rsp => checkIfResponseIsValid(rsp));
    } else {
        return checkIfResponseIsValid(response);
    }
}

const checkIfResponseIsValid = (response) => {
    if (response?.config?.method === 'get' && response?.status === 200
         && typeof response?.data !== 'object' && typeof response?.data !== 'boolean' && response?.data) {
        return false;
    } else {
        return true;
    }
}

const handleFinally = (finallyCallback, context) => {
    if (finallyCallback) {
        finallyCallback(context);
    }
}

const handlePostSuccess = (apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback, response) => {

    if (response.request.responseURL.includes("error=xsrf") && _sessionRefreshAttempts == 0) {
        _sessionRefreshAttempts++;
        post(apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback);
    } else {
        _sessionRefreshAttempts = 0;
        handleSuccess(response, successCallback, context);
    }
}

const handleSuccess = (response, successCallback, context) => {
    if (successCallback) {
        try {
            if (isResponseValid(response)) {
                successCallback(response, context);
            } else {
                checkSesionStatus(response, context, successCallback);
            }
        } catch {
            checkSesionStatus(response, context);
        }
    }
}

const checkFailed = (then) => {
    return (responses) => {
        const errors = responses.filter(x => !x.status);

        if (errors.length) {
            throw errors;
        }

        if (errors.length !== responses.length) {
            return then(responses);
        }
    }
}

export const prepareUrl = (url, context) => {
    //custom method to getting labels instead of key values
    //passing '$showKeys' query string to background 'dictionary' api call url, or any other call when called with forced = true
    return context ? url + context.$showKeys(context, url) : url;
}

export const get = (apiUrl, successCallback, context, finallyCallback, customErrorHandlingCallback) => {
    return axios
        .get(prepareUrl(apiUrl, context), headers)
        .then(response => handleSuccess(response, successCallback, context))
        .catch((error) => handleError(error, context, customErrorHandlingCallback))
        .finally(() => handleFinally(finallyCallback, context));
}

export const getAll = (apiUrls, successCallback, context, finallyCallback, customErrorHandlingCallback) => {
    const promises = apiUrls.map(x => axios.get(prepareUrl(x, context), headers));
    const promisesResolved = promises.map(x => x.catch(error => (error)));

    return axios.all(promisesResolved)
        .then(checkFailed((response) => {
            handleSuccess(response, successCallback, context);
        }))
        .catch((error) => {
            handleError(error, context, customErrorHandlingCallback);
        })
        .finally(() => handleFinally(finallyCallback, context));
}

export const postWithCustomHeaders = (apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback, customHeaders) => {
    let headers = {};

    if (customHeaders != null) {
        headers = customHeaders;
    }

    headers[context.$AntiforgeryConstants.RequestHeader] = context.$cookies.get(context.$AntiforgeryConstants.RequestToken);
    var requestAntiForgeryVerification = document.querySelector('input[name="__RequestVerificationToken"]');
    if (requestAntiForgeryVerification)
        headers["RequestVerificationToken"] = requestAntiForgeryVerification.value;

    return axios
        .post(apiUrl, data, { headers: headers })
        .then(response => handlePostSuccess(apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback, response))
        .catch((error) => handleError(error, context, customErrorHandlingCallback))
        .finally(() => handleFinally(finallyCallback, context));
}

export const post = (apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback) => {
    return postWithCustomHeaders(apiUrl, data, successCallback, context, finallyCallback, customErrorHandlingCallback, null)
}

export const head = (apiUrl, successCallback, context, finallyCallback, customErrorHandlingCallback) => {
    return axios
        .head(apiUrl)
        .then(response => handleSuccess(response, successCallback, context))
        .catch((error) => handleError(error, context, customErrorHandlingCallback))
        .finally(() => handleFinally(finallyCallback, context));
}
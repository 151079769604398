<template>
    <div>
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.dashboard.title }}</h1>
        </div>

        <div class="portlets-wrapper">
            <Dashboard :dictionary="dictionary"
                       :toggles="toggles"
                       :orders-dictionary="ordersDictionary"
                       :security-chart-model="securityChartModel"
                       :dashboard-data="dashboardData">
            </Dashboard>
            <component-spinner :spin="isLoading"></component-spinner>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Dashboard from "./dashboard.component.vue";

    export default {
        components: {
            Dashboard
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                toggles: null,
                ordersDictionary: null,
                securityChartModel: null,
                dashboardData: null,
                isLoading: true,
            }
        },
        beforeCreate() {
            getAll([
                "/Dashboard/Dictionary",
                "/Dashboard/Toggles",
                "/Orders/Dictionary",
                "/Dashboard/GetDashboardData",
                "/Dashboard/GetPortfolioValueChartData"
            ], responses => {
                this.dictionary = responses[0].data,
                this.toggles = responses[1].data,
                this.ordersDictionary = responses[2].data,
                this.dashboardData = responses[3].data;
                this.securityChartModel = responses[4].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        },
        mounted() {
            if(this.pagesDictionary) {
                this.$setPageTitle(this.pagesDictionary.dashboard.title);
            }
        }
    };
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "listed-trading-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "listed-trading-details-expansion-panel" },
            [
              _c("v-expansion-panel-header", [
                _c(
                  "div",
                  {
                    staticClass:
                      "listed-trading-details-expansion-panel__header",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("PositionActionButtons", {
                      staticClass: "action-buttons-table-cell",
                      attrs: {
                        position: _vm.position,
                        dictionary: _vm.dictionary,
                        isMobile: _vm.isMobile,
                        type: "listed-trading"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-bold listed-trading-details-expansion-panel__text",
                        on: {
                          click: function($event) {
                            return _vm.togglePanel()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.position.positionName))]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile", attrs: { eager: "" } },
                [
                  _c("ListedTradingTableMobileRow", {
                    attrs: {
                      label: this.dictionary.table.positionName,
                      value: _vm.position.positionName
                    }
                  }),
                  _vm._v(" "),
                  _c("ListedTradingTableMobileRow", {
                    attrs: {
                      label: this.dictionary.table.bid,
                      value: _vm.replaceNullOrEmpty(
                        _vm.position.buyPriceFormatted
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("ListedTradingTableMobileRow", {
                    attrs: {
                      label: this.dictionary.table.ask,
                      value: _vm.replaceNullOrEmpty(
                        _vm.position.sellPriceFormatted
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("ListedTradingTableMobileRow", {
                    attrs: {
                      label: this.dictionary.table.last,
                      value: _vm.replaceNullOrEmpty(
                        _vm.position.securityPriceFormatted
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div v-if="!!exerciseScenario && !!exerciseScenario.exerciseScenarioType">
    <template  v-if="exerciseScenario.exerciseScenarioType.value === $ExerciseScenarioType.SALE_OF_SHARES">
        <label v-if="exerciseScenario.scenarioDescriptionText"> {{exerciseScenario.scenarioDescriptionText}} </label> 
        <div  v-if="exerciseScenario.shouldKeepProceeds">
            <p class="label">{{dictionary.form.proceedsAccount }}</p>
            <p>{{exerciseScenario.proceedsAccount}} </p>                      
        </div>
        <div  v-if="exerciseScenario.canSelectMoneyExternalAccount">
            <select-element v-model="model.moneyExternalAccountId" :label="dictionary.form.proceedsAccount" :options="moneyExternalAccounts"></select-element>
        </div>
    </template> 
    <template  v-if="exerciseScenario.exerciseScenarioType.value === $ExerciseScenarioType.DELIVERY_OF_SHARES">
        <p class="label" v-if="exerciseScenario.scenarioDescriptionText"> {{exerciseScenario.scenarioDescriptionText}} </p> 
        <div  v-if="exerciseScenario.shouldKeepProceeds">
            <p class="label">{{dictionary.form.securityExternalAccount }} </p>
            <p>{{exerciseScenario.deliveryAccount}}</p>                       
        </div>
        <div  v-if="exerciseScenario.canSelectSecurityExternalAccount">
            <select-element v-model="model.securityExternalAccountId" :label="dictionary.form.securityExternalAccount" :options="securityExternalAccounts" name="securityExternalAccounts"></select-element>
        </div>
    </template>
    <template  v-if="exerciseScenario.exerciseScenarioType.value === $ExerciseScenarioType.SALE_AND_DELIVERY_OF_SHARES">
        <p class="label" v-if="exerciseScenario.scenarioDescriptionText"> {{exerciseScenario.scenarioDescriptionText}} </p>
        <div  v-if="exerciseScenario.shouldKeepProceeds">
            <p class="label">{{dictionary.form.proceedsAccount }}</p>
            <p>{{exerciseScenario.proceedsAccount}}</p> 
            <p class="label">{{dictionary.form.securityExternalAccount }}</p>
            <p>{{exerciseScenario.deliveryAccount}}</p>                        
        </div>
        <div  v-if="exerciseScenario.canSelectSecurityExternalAccount">
            <select-element v-model="model.securityExternalAccountId" :label="dictionary.form.securityExternalAccount" :options="securityExternalAccounts" name="securityExternalAccounts"></select-element>
        </div>
        <div  v-if="exerciseScenario.canSelectMoneyExternalAccount">
            <select-element v-model="model.moneyExternalAccountId" :label="dictionary.form.proceedsAccount" :options="moneyExternalAccounts" name="moneyExternalAccounts"></select-element>
        </div>
    </template>
</div>
</template>

<script>

    const createDropDownOption = (disabled, group, selected, text, value) => ({
        disabled: disabled,
        group: group,
        selected: selected,
        text: text,
        value: value
    });

export default {

    props: {
        model:Object,
        exerciseScenario: Object,
        dictionary: Object
    },
    computed: {
        securityExternalAccounts(){ 
            return this.exerciseScenario.securityExternalAccounts.map( c=> createDropDownOption(false, null, false, c.text, c.value )); 
        },
        moneyExternalAccounts(){ 
            return this.exerciseScenario.moneyExternalAccounts.map( c=> createDropDownOption(false, null, false, c.text, c.value )); 
        }
    },

}
</script>
<template>
    <div class="tab-row">
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.orders.title }}</h1>
        </div>

        <div class="tab-bar">
            <component-spinner :spin="isLoading"></component-spinner>
            <Orders :dictionary="dictionary" :toggles="toggles" v-if="!isLoading" />
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Orders from "./orders.component.vue";

    export default {
        components: {
            Orders
        },
        props: {
            pagesDictionary: Object
        },        
        data() {
            return {
                dictionary: null,
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                "/Orders/Dictionary",
                "/Orders/Toggles",
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        },
        filters: {
            removeMinusSymbol: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.replace('-', '')
            }
        }
    };
</script>

<template>
    <div class="scenario-subscriptions-grid" role="row">
        <div class="underlying-usage-name">
            <p class="scenario-title">{{dictionary.table.securityName}}</p>
            <p role="cell" tabindex="0" >{{value.offer.underlyingUsageName}}</p>
        </div>

        <div v-if="isSubscriptions" class="grant-amount">
            <p class="scenario-title">{{dictionary.table.granted}}</p>
            <p role="cell" tabindex="0">{{grantAmount}}</p>
        </div>

        <div v-if="isSubscriptions" class="accepted-amount">
            <p class="scenario-title">{{dictionary.table.subscribed}}</p>
            <p role="cell" tabindex="0">{{value.offer.acceptedAmountFormatted}}</p>
        </div>

        <div v-if="!isSubscriptions &&  hasEmissionPrice" class="emission-price">
            <p class="scenario-title">{{dictionary.table.emissionPrice}}</p>
            <p class="text-center" role="cell" tabindex="0">{{value.offer.emissionPriceFormatted}}</p>
        </div>

        <div v-if="hasTaxableAmount" class="taxable-amount">
            <p class="scenario-title">{{dictionary.table.taxableAmount}}</p>
            <p role="cell" tabindex="0">{{value.offer.taxableAmountFormatted}}</p>
        </div>

        <div class="subscribe-amount">
            <p v-if="!isAcceptCollumn" class="scenario-title">{{dictionary.table.presentationModeSubscribe}}</p>
            <p v-else class="scenario-title">{{dictionary.table.presentationModeAccept}}</p>

            <input-element :id="value.offer.participationSubscriptionOfferId"
                    name="participationSubscriptionOfferId"
                    type="text"
                    v-model="value.amount"
                    :validationsList="amountValidation"
                    role="cell"
                    :readonly="isInputReadonly"
                    :isMandatory="true"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            hasTaxableAmount: Boolean,
            hasEmissionPrice: Boolean,
            value: Object,
            validationLists: Object,
            isSubscriptions: Boolean,
            isAcceptCollumn: Boolean
        },
        data() {
            return {
                isInputReadonly: false
            }
        },
        mounted() {
            if (this.value.amount) {
                this.isInputReadonly = true;
            }
        },
        computed: {
            minimumAndMaximumAmountDiffer() {
                return this.value.offer.minimumAwardedAmount != this.value.offer.maximumAwardedAmount;
            },
            grantAmount() {
                return this.minimumAndMaximumAmountDiffer ? `${this.value.offer.minimumAwardedAmountFormatted} - ${this.value.offer.maximumAwardedAmountFormatted}` : this.value.offer.maximumAwardedAmountFormatted;
            },
            amountValidation() {
                return this.validationLists.amount.filter(x=>x.participationSubscriptionOfferId == this.value.offer.participationSubscriptionOfferId);
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-bar" },
    [
      _c(
        "b-tabs",
        { ref: "tabs", staticClass: "tab-container" },
        [
          _c(
            "portlet-tab",
            {
              attrs: {
                tabTitle: _vm.dictionary.labels.tabActive,
                tabId: "activeTab"
              }
            },
            [
              _c("RequiredDocumentsTable", {
                attrs: {
                  dictionary: _vm.dictionary,
                  "datatables-dictionary": _vm.dictionary.table,
                  getUrl: "/RequiredDocuments/ActiveRequiredDocuments"
                },
                on: { action: _vm.viewActiveRequiredDocument }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "portlet-tab",
            {
              attrs: {
                tabTitle: _vm.dictionary.labels.tabHistory,
                tabId: "historyTab"
              }
            },
            [
              _c("RequiredDocumentsTable", {
                attrs: {
                  dictionary: _vm.dictionary,
                  "datatables-dictionary": _vm.dictionary.table,
                  getUrl: "/RequiredDocuments/HistoryRequiredDocuments"
                },
                on: { action: _vm.viewHistoryRequiredDocument }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("ReportsDetail", {
        attrs: {
          id: "AnnualReportDetails",
          dictionary: _vm.dictionary,
          toggles: _vm.toggles,
          reportName: _vm.dictionary.annual.name,
          reportDescription: _vm.dictionary.annual.description,
          "is-mobile": _vm.isMobile
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  _c("div", [
                    !_vm.isMobile
                      ? _c("div", { staticClass: "custom-report-form" }, [
                          _c(
                            "div",
                            { staticClass: "mr-4" },
                            [
                              _c("select-element", {
                                staticClass: "mb-0",
                                attrs: {
                                  validationsList:
                                    _vm.reportValidations.selectedYear,
                                  label: _vm.dictionary.annual.reportsLabelYear,
                                  options: _vm.listYears,
                                  "is-mandatory": true,
                                  name: "annualReportYears"
                                },
                                model: {
                                  value: _vm.selectedYear,
                                  callback: function($$v) {
                                    _vm.selectedYear = $$v
                                  },
                                  expression: "selectedYear"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "custom-report-form-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn primaryButton",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.getAnnualStatementReport()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.dictionary.annual.extractButton)
                                )
                              ]
                            )
                          ])
                        ])
                      : _c(
                          "div",
                          { staticClass: "custom-report-form-mobile" },
                          [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("select-element", {
                                  attrs: {
                                    validationsList:
                                      _vm.reportValidations.selectedYear,
                                    label:
                                      _vm.dictionary.annual.reportsLabelYear,
                                    options: _vm.listYears,
                                    "is-mandatory": true,
                                    name: "annualReportYears"
                                  },
                                  model: {
                                    value: _vm.selectedYear,
                                    callback: function($$v) {
                                      _vm.selectedYear = $$v
                                    },
                                    expression: "selectedYear"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "custom-report-form-btn col" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primaryButton",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getAnnualStatementReport()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.annual.extractButton
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                  ])
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2506487055
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <TransactionDetailsContentRow :label="dictionary.columns.transactionsTblColExecutionDate" :value="item.executionDate" />
        <TransactionDetailsContentRow :label="dictionary.columns.transactionsTblColType" :value="item.transactionType" />
        <TransactionDetailsContentRow :label="dictionary.columns.transactionsTblColAmount" :value="item.amountFormatted" />
        <TransactionDetailsContentRow :label="dictionary.columns.transactionsTblColReference" :value="item.reference" />
        <TransactionDetailsContentRow v-if="!toggles.hideTransactionOriginColumn" :label="dictionary.columns.transactionsTblColOrigin" :value="item.origin" />
        <TransactionDetailsContentRow :label="dictionary.columns.transactionsTblColComment" :value="item.comment" />
    </div>
</template>

<script>
import TransactionDetailsContentRow from './transaction-details-content-row.component.vue';

export default {
    components: {
        TransactionDetailsContentRow
    },
    props: {
        item: Object,
        dictionary: Object,
        toggles: Object
    }
}
</script>
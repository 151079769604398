<template>
    <div id="order-form-div">
        <form v-if="model" id="buy-instruct-form" @submit.prevent="submit">
            <alert-component v-if="error" variant="error">{{ error }}</alert-component>
            <order-types-link :type="$OrderTypes.Buy" :dictionary="dictionary.orderTypes" :positionViewModel="buyPositionModel" :title="dictionary.form.orderType" name="orderType"/>
            <select-element v-if="!model.moduleBounderies.length > 1" v-model="model.moduleId" :label="dictionary.form.moduleName" :options="moduleBounderies" name="moduleBounderies"></select-element>
            <select-element v-model="model.buyScenarioId" :label="dictionary.form.scenarioName" :options="scenarios" name="buyScenario"></select-element>
            <select-element v-model="model.executionMoment" :label="dictionary.form.executionMoment" :options="executionMoments" :validations="$v.model.executionMoment" name="executionMoments"></select-element>
            <BuyOrderQuantity :dictionary="dictionary" :model="model" :moduleBounderie="moduleBounderie" :validations="$v.model" @amountOrdered="model.amountOrdered = $event" @nrOrdered="model.nrOrdered = $event" />
            <input-element v-if="!hideLimitPrice" type="text" :label="dictionary.form.limitPrice" v-model="model.limitPrice" :validationsList="limitPriceValidations" name="limitPrice"></input-element>
            <datepicker-element v-if="!hideBuyDate" id="buy-order-buy-date" :label="dictionary.form.buyDate" v-model="model.buyDate" />
            <div class="flex-container-button-right mt-4">
                <button class="btn btn-primary" type="submit">{{ dictionary.form.placeOrderButton }}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import { buyOrderFormValidations } from "Shared/Components/Vue/Validations/order-form-validation.mixin.js";
    import BuyOrderQuantity from "./buy-order-quantity.component.vue";
    import { EventBus } from "Shared/event-bus";

    const createDropDownOption = (text, value) => ({
        text: text,
        value: value
    });

    export default {
        mixins: [buyOrderFormValidations],
        components: {
            BuyOrderQuantity
        },
        props: {
            dictionary: Object,
            buyPositionModel: Object,
            buyInstructionModel: Object,
            error: String
        },
        computed: {
            model() {
                return this.buyInstructionModel;
            },
            moduleBounderie() {
                if (!this.model.moduleId) {
                    this.model.moduleId = this.model.moduleBounderies[0]?.moduleId;
                }

                return this.model.moduleBounderies.filter(
                    (e) => e.moduleId === this.model.moduleId
                )[0];
            },
            moduleBounderies() {
                if (!this.model.moduleId) {
                    this.model.moduleId = this.model.moduleBounderies[0]?.moduleId;
                }

                return this.model.moduleBounderies.map((p) =>
                    createDropDownOption(p.moduleName, p.moduleId)
                );
            },
            scenarios() {
                const buyModuleBounderie = this.moduleBounderie;
                if (!buyModuleBounderie) {
                    return [];
                }
                if (!this.model.buyScenarioId) {
                    this.model.buyScenarioId =
                        buyModuleBounderie.scenarios[0]?.buyScenarioId;
                }

                return buyModuleBounderie.scenarios.map((p) =>
                    createDropDownOption(p.name, p.buyScenarioId)
                );
            },
            executionMoments() {
                const buyModuleBounderie = this.moduleBounderie;
                if (!buyModuleBounderie) {
                    return [];
                }
                if (!this.model.executionMoment || !buyModuleBounderie.executionMoments.filter((e) => e === this.model.executionMoment).length) {
                    this.model.executionMoment = buyModuleBounderie.executionMoments[0];
                }

                return buyModuleBounderie.executionMomentTypes.map((p) =>
                    createDropDownOption(p.description, p.value)
                );
            },
            hideLimitPrice() {
                if (this.model.executionMoment !== this.$ExecutionMomentType.AT_PRICE) {
                    this.model.limitPrice = '';
                    return true;
                }
                return false;
            },
            hideBuyDate() {
                if (this.model.executionMoment !== this.$ExecutionMomentType.AT_DATE) {
                    this.model.buyDate = null;
                    return true;
                }
                return false;
            },
            limitPriceValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.required,
                        message: this.dictionary.validation.limitPriceRequired
                    },
                    {
                        id: 2,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.mustBeDecimal,
                        message: this.dictionary.validation.limitPriceInvalid
                    },
                    {
                        id: 3,
                        error: this.$v.model.limitPrice.$error,
                        type: this.$v.model.limitPrice.mustBeModulo,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validation.limitPriceModulo,
                            {
                                LimitPriceModulo: this.$setDecimalSeparatorBySettings(this.moduleBounderie.limitPriceModulo),
                            }
                        )
                    }
                ];
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit("submit", this.model);
            }            
        }
    };
</script>
<template>
  <component-spinner v-if="isLoading" :spin="isLoading"></component-spinner>
  <div v-else-if="dictionary" class="required-info-box">
    <RequiredInfoCRSTimeline :dictionary="dictionary" />
  </div>
</template>

<script>
import { get } from "api";
import RequiredInfoCRSTimeline from "./required-info-crs-timeline.component.vue";

export default {
  components: {
    RequiredInfoCRSTimeline
  },
  data() {
    return {
      dictionary: null,
      isLoading: true
    };
  },
  beforeCreate() {
    this.$emit('set-header', {header: null, subHeader: null});

    get(
      "/RequiredInfo/CrsDictionary",
      response => {
        this.dictionary = response.data;
        this.$setPageTitle(this.dictionary.page.title);
        this.$emit('set-header', {header: this.dictionary.page.title});
      },
      this,
      () => (this.isLoading = false)
    );
  }
};
</script>
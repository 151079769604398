<template>
  <div id="order-form-div">
    <form v-if="model" id="ie-instruct-form" @submit.prevent="submit">
      <alert-component v-if="error" variant="error">{{ error }}</alert-component>

      <div class="form-group buy-sell-buttons" v-if="model.internalExchangeBoundaries.canInstructBuyOrder || model.internalExchangeBoundaries.canInstructSellOrder">
        <div>
          <template v-if="model.internalExchangeBoundaries.canInstructBuyOrder">
            <input class="radiobutton-buy" id="ieiotBuy" name="OrderType" type="radio" value="Buy" v-model="model.orderType" />
            <label for="ieiotBuy" class="radiobutton-buy form-input-label">{{ dictionary.form.orderTypeBuy }}</label>
          </template>
          <template v-if="model.internalExchangeBoundaries.canInstructSellOrder">
            <input checked="checked" class="radiobutton-sell" id="ieiotSell" name="OrderType" type="radio" value="Sell" v-model="model.orderType" />
            <label for="ieiotSell" class="radiobutton-sell form-input-label">{{ dictionary.form.orderTypeSell }}</label>
          </template>
        </div>
      </div>

      <input-element type="text" :label="dictionary.form.quantity" v-model="model.quantity" :validationsList="quantityValidations" name="quantity"></input-element>
      <input-element type="text" :label="dictionary.form.limitPrice" v-model="model.limitPrice" :validationsList="limitPriceValidations" name="limitPrice"></input-element>
      <datepicker-element v-if="isExpirationDateVisible" id="expiration-date" :label="dictionary.form.expirationDate" v-model="model.expirationDate" />
      <div class="flex-container-button-right mt-4">
        <button class="btn btn-primary" type="submit">{{ dictionary.form.placeOrder }}</button>
      </div>
    </form>
  </div>
</template>

<script>
    import { ieOrderFormValidations } from "Shared/Components/Vue/Validations/order-form-validation.mixin.js";
    import { EventBus } from "Shared/event-bus";

export default {
  mixins: [ieOrderFormValidations],
  components: {},
  props: {
    dictionary: Object,
    positionModel: Object,
    instructionModel: Object,
    error: String,
  },
  computed: {
    model() {
      return this.instructionModel;
    },
    quantityValidations() {
      return [
        {
          id: 1,
          error: this.$v.model.quantity.$error,
          type: this.$v.model.quantity.required,
          message: this.dictionary.validation.quantityRequired,
        },
        {
          id: 2,
          error: this.$v.model.quantity.$error,
          type: this.$v.model.quantity.mustBeDecimal,
          message: this.dictionary.validation.quantityInvalid,
        },
        {
          id: 3,
          error: this.$v.model.quantity.$error,
          type: this.$v.model.quantity.mustBeModuloMinimum,
          message: this.$replaceStringWithPlaceholders(
            this.dictionary.validation.transferModuloMinimum,
            {
              "TransferModulo": this.model.internalExchangeBoundaries.transferModulo,
              "TransferMinimum": this.model.internalExchangeBoundaries.transferMinimum,
            }
          ),
        },
        {
          id: 4,
          error: this.$v.model.quantity.$error,
          type: this.$v.model.quantity.mustBeValidMoneyAmount,
          message: this.$replaceStringWithPlaceholders(
            this.dictionary.validation.availableMoneyAmount,
            {
              "AvailableMoneyAmount": this.model.internalExchangeBoundaries.availableMoneyAmountFormatted,
            }
          ),
        },
        {
          id: 5,
          error: this.$v.model.quantity.$error,
          type: this.$v.model.quantity.mustBeValidSecurityAmount,
          message: this.$replaceStringWithPlaceholders(
            this.dictionary.validation.availableSecurityAmount,
            {
              "AvailableSecurityAmount": this.model.internalExchangeBoundaries.availableSecurityAmountFormatted,
            }
          ),
        },
        {
          id: 6,
          error: this.$v.model.internalExchangeBoundaries.hasMaxConcurrentOpenOrders.$error,
          type: this.$v.model.internalExchangeBoundaries.hasMaxConcurrentOpenOrders.mustBeFalse,
          message: this.dictionary.validation.internalExchangesErrorMaxOpenOrders
        }
      ];
    },
    limitPriceValidations() {
      return [
        {
          id: 1,
          error: this.$v.model.limitPrice.$error,
          type: this.$v.model.limitPrice.required,
          message: this.dictionary.validation.limitPriceRequired,
        },
        {
          id: 2,
          error: this.$v.model.limitPrice.$error,
          type: this.$v.model.limitPrice.mustBeGreatherThanZero,
          message: this.dictionary.validation.internalExchangesErrorInvalidLimitPrice
        },
        {
          id: 3,
          error: this.$v.model.limitPrice.$error,
          type: this.$v.model.limitPrice.mustBeDecimal,
          message: this.dictionary.validation.limitPriceInvalid,
        },
         {
          id: 4,
          error: this.$v.model.limitPrice.$error,
          type: this.$v.model.limitPrice.mustBeModulo,
          message: this.$replaceStringWithPlaceholders(
            this.dictionary.validation.limitPriceModulo,
            {
                "LimitPriceModulo": this.$setDecimalSeparatorBySettings(this.model.internalExchangeBoundaries.limitPriceModulo),
            }
          ),
        }
      ];
    },
    isExpirationDateVisible() {
      return this.model.internalExchangeBoundaries.isExpirationDateVisible ?? true;
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      EventBus.$emit("v-touch");
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("submit", this.model);
    },
  },
};
</script>
const createDropDownOption = (text, value) => ({
  text: text,
  value: value
});

export const reportPortfolioOverviewMixin = {
    props: {
      dictionary: Object,
      toggles: Object,
      model : Object,
      reportParameters: Object,
      reportOverview: Object,
      isSubmitting: Boolean,
      isMobile: Boolean,
      isTablet: Boolean,
      success: String,
      error :String
    },
    data() {
      return  {
        isLoading: false
      }
    },
    computed: {    
        reportCustomTitle(){
          return  this.$replaceStringWithPlaceholders( this.dictionary.portfolio.portfolioOverview.subHeadingReportTitle, { "REPORTDATE": this.$options.filters.toDate(this.model.reportDate) });
        },
        customCurrencies(){
          return this.reportParameters.selectableCurrencies.map(c => createDropDownOption(c.name, c.id));
        },
        portfolioOverviewDictionary(){
          return this.dictionary.portfolio.portfolioOverview
        },
        getSelectedCurrency(){
          return this.reportParameters.selectableCurrencies.find(c => c.id == this.model.selectedCurrency);
        }
    }
} 

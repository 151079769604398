import MoneyTransferContainer from "./money-transfer-container.component.vue";
import MoneyTransferPosition from './money-transfer-position.component.vue';
import MoneyTransferConfirmation from './money-transfer-confirmation.component.vue';


export const moneyTransferRoutes =
{
    path: '/moneyTransfer',
    component: MoneyTransferContainer,
    children: [
        {
            path: '',
            component: MoneyTransferPosition
        }, {
            path: 'confirmation',
            component: MoneyTransferConfirmation
        },
        {
            path: '*',
            redirect: '/moneyTransfer'
        }
    ]
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-wrapper",
      on: {
        click: function($event) {
          return _vm.removeBodyClass(
            "aside-toggled",
            "navigation-menu",
            "personal-menu"
          )
        }
      }
    },
    [
      _c(
        "aside",
        {
          staticClass: "aside-container sidebar-container navigation-menu",
          attrs: { id: "sidebar" }
        },
        [
          !_vm.isLoading
            ? _c(
                "nav",
                {
                  staticClass: "sidebar aside-inner",
                  attrs: {
                    "data-sidebar-anyclick-close": "",
                    "aria-label": "Site navigation"
                  }
                },
                [
                  _c("SidebarMenu", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      toggles: _vm.toggles,
                      features: _vm.features,
                      logoutEndpoint: _vm.logoutEndpoint
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "aside-container sidebar-container personal-menu" },
        [
          _c("div", { staticClass: "aside-inner" }, [
            !_vm.isLoading
              ? _c(
                  "nav",
                  {
                    staticClass: "sidebar",
                    attrs: {
                      "data-sidebar-anyclick-close": "",
                      "aria-label": "Site navigation"
                    }
                  },
                  [
                    _c("PersonalDataMenu", {
                      attrs: {
                        accountDataModel: _vm.accountDataModel,
                        beneficiaryBoundariesDataModel:
                          _vm.beneficiaryBoundariesDataModel,
                        dictionary: _vm.personalDataDictionary,
                        toggles: _vm.personalDataToggles,
                        userFullName: _vm.userFullName
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>

        <Mfa :isVisible="isVisible" :message="dictionary.pacPage.welcomeTextDescription" :skipConfirmation="true" 
         :mfa-boundaries-data-model="mfaBoundariesDataModel" :currentFactorType="currentFactorType"
         @mfa-get-state-error="onError" @mfa-success-login="$emit('mfa-success')" @cancel="logout"/>

        <button v-if="error" class="btn btn-block mt-5 primaryButton" type="button"  @click="logout">
                {{dictionary.pacPage.backToLoginText}}
        </button>
    </div>
</template>

<script>
    import { post, get } from "api";
    import Mfa from "Features/Mfa/Assets/Vue/mfa.component.vue";
    
    export default {
        components: {
            Mfa
        },
        props: {
            dictionary: Object,
            isVisible: Boolean
        },
        data() {
            return {
                error: null,
                mfaBoundariesDataModel:{},
                currentFactorType:''
            };
        },
        beforeCreate() {
            get('/Mfa/MfaBoundaries', (response) => {
                this.mfaBoundariesDataModel = response.data;
                this.currentFactorType = response.data.currentFactorType;
            }, this);
        },
        methods: {
            logout() {
                this.$emit("in-loading");
                
                post('/Login/Logout',
                    null,
                    () => {
                        
                    },
                    this,
                    () => {
                        window.location.replace('/');
                    });
            },
            onError(error) {
                this.error = error;
            }
        }
    };
</script>
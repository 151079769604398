<template>
    <li class="personal-nav-link" v-if="isVisible">
        <template>
            <div>
                <v-menu offset-y open-on-click>
                    <template v-slot:activator="{ attrs, on }">
                        <a class="nav-link" v-bind="attrs" v-on="on" href="javascript:;">
                            <span v-if="userFullName" class="mr-1">{{ userFullName }}</span>
                            <span class="nav-icon"><i class="far fa-user" aria-hidden="true"></i></span>
                            <span class="sr-only">{{ navPersonalDataLabel }}</span>
                        </a>
                    </template>
                    <v-list class="personal-data-dropdown-menu" v-if="isLoaded && showAvatarIcon && items.length > 0">
                        <v-list-item v-for="(item, index) in filteredItems" :key="`${item.name}-${index}`" link :to="item.relativeUrl">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </li>
</template>

<style>
    @import '../personal-data-dropdown.scss';
</style>

<script>

    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import { personalDataDropdownMixin } from "./mixins/personal-data-dropdown.mixins";
    import { EventBus } from "Shared/event-bus";

    export default {
        mixins: [ScreenSizeMixin, personalDataDropdownMixin],
        props: {
            dictionary: Object,
            toggles: Object,
            isHidden: {
                default: false,
                type: Boolean
            },
            beneficiaryBoundariesDataModel: Object,
            accountDataModel: Object,
            userFullName: String,
            navPersonalDataLabel: String
        },
        watch: {
            accountDataModel: {
                handler() {
                    EventBus.$emit('personal-data-user-full-name', this.userFullName);
                },
                immediate: true
            }
        },
        computed: {
            isVisible() {
                if (this.isDesktop && !this.isHidden) {
                    return true;
                }

                return false
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dictionary
      ? _c("div", [
          _c("div", { staticClass: "title-block" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.dictionary.page.titleConfirmSubscribe))
            ])
          ]),
          _vm._v(" "),
          _vm.confimationModel.participationSubscription
            ? _c("div", { staticClass: "portlets-wrapper" }, [
                _c("div", { staticClass: "portlet-container" }, [
                  _c(
                    "div",
                    { staticClass: "row m-0" },
                    [
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-lg-6 col-xl-6",
                            title:
                              _vm.confimationModel.participationSubscription
                                .name
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _vm.error
                                ? _c(
                                    "alert-component",
                                    { attrs: { variant: "error" } },
                                    [_vm._v(_vm._s(_vm.error))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("loading-spinner", {
                                attrs: { active: _vm.isSubmitting }
                              }),
                              _vm._v(" "),
                              _c("SubscriptionConfirmationDetails", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  participationSubscription:
                                    _vm.confimationModel
                                      .participationSubscription,
                                  isSubscriptions: _vm.isSubscriptions
                                }
                              }),
                              _vm._v(" "),
                              !_vm.confimationModel.disclaimers.length
                                ? _c("SubscriptionConfirmationButtons", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      disclaimers:
                                        _vm.confimationModel.disclaimers,
                                      presentationMode:
                                        _vm.confirmationData
                                          .participationSubscription
                                          .presentationMode
                                    },
                                    on: {
                                      accept: function($event) {
                                        return _vm.accept()
                                      },
                                      decline: function($event) {
                                        return _vm.decline()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.confimationModel.disclaimers.length
                        ? _c(
                            "portlet-layout",
                            {
                              attrs: {
                                "column-class": "col-lg-6 col-xl-6",
                                title: _vm.dictionary.widget.titleDisclaimers
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "body" },
                                [
                                  _c("loading-spinner", {
                                    attrs: { active: _vm.isSubmitting }
                                  }),
                                  _vm._v(" "),
                                  _c("SubscriptionConfirmationDisclaimers", {
                                    attrs: {
                                      dictionary: _vm.dictionary,
                                      confimationModel: _vm.confimationModel
                                    },
                                    on: {
                                      accept: function($event) {
                                        return _vm.accept()
                                      },
                                      decline: function($event) {
                                        return _vm.decline()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
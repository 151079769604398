<template>
    <div v-if="message.text && message.text.length > 0" :class="['email-message', legacyEmailCssClass]">
        <div class="rich-text-container" v-html="$sanitize(message.text)"></div>
    </div>
</template>

<script>
export default {
    props: {
        message: Object,
        model: Object
    },
    computed: {
        legacyEmailCssClass() {
            return !this.model.emailFormattingEnabled ? "legacy-email-message" : "";
        }
    }
}
</script>
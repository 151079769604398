<template>
    <LoginTemplate v-if="dictionary">  
        <template v-if="!isPostSuccessful">   
            <template slot="title" >
                <h1 class="sr-only welcome-text">{{dictionary.recovery.forgotPasswordTitle}}</h1>
                <p class="text-center py-2 welcome-text" aria-hidden="true">{{dictionary.recovery.forgotPasswordTitle}}</p>
                <hr>
            </template>
            <template slot="body">
                <div class="login-form">
                    <div class="forgot-password-message">
                        <p class="text-left pb-2">{{dictionary.recovery.forgotPasswordText}}</p>
                    </div>
                    <alert-component v-if="error" variant="error">{{ error }}</alert-component>

                    <form class="mb-lg-3" id="loginForm" @submit.prevent="recoverPassword">
                        <input-element type="text" 
                                        :is-mandatory="true" 
                                        :label="dictionary.page.userName" 
                                        v-model="forgottenPasswordViewModel.userName" 
                                        name="userName" 
                                        :validationsList="userNameValidations" >
                        </input-element>

                        <!-- year range shouldn't be hardcoded -->
                        <datepicker-element v-if="forgottenPasswordData.attributeType == 'pvaDateOfBirth'" id="personal-date-of-birth" class="password-recovery-form-date-picker"
                                        v-model="forgottenPasswordViewModel.value"
                                        :label="dictionary.labels.dateOfBirth"
                                        :validationsList="dateOfBirthValidations"
                                        :is-mandatory="true" 
                                        :yearRange="'-150:+0'">
                        </datepicker-element>

                        <input-element v-else type="text" id="text-input-forgotten-password" 
                                    v-model="forgottenPasswordViewModel.value"
                                    :label="forgottenPasswordData.attributeLabel"
                                    :is-mandatory="true" 
                                    :validationsList="textInputValidation">
                        </input-element>
                        <p>* {{dictionary.page.loginLabelMandatory}}</p>
                        <div class="d-md-flex justify-content-md-between button-container">
                            <button class="btn mt-3 secondaryButton login-button" type="button"  @click="back">
                                {{commonDictionary.btnGoBack}}
                            </button>

                            <button class="btn mt-3 primaryButton login-button" type="button"  @click="recoverPassword">
                                {{commonDictionary.btnContinue}}
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </template>
        <template v-else>
            <template slot="body">
                <PasswordRecoverySuccessful :dictionary="dictionary" :common-dictionary="commonDictionary"/>
            </template>
        </template>
    </LoginTemplate>
</template>

<script>
    import { getAll } from "api";
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import { required } from "vuelidate/lib/validators";
    import { post } from "api";
    import PasswordRecoverySuccessful from "../PasswordRecovery/password-recovery-successful.component.vue";
    import LoginTemplate from "../login-template.component.vue";
    import { EventBus } from "Shared/event-bus";

    export default {
         components:{
            PasswordRecoverySuccessful,
            LoginTemplate
        },
        mixins:[ScreenSizeMixin],
        data() {
            return {
                forgottenPasswordViewModel: {
                    userName:'',
                    value:''
                },
                forgottenPasswordData:null,
                dictionary: null,
                commonDictionary: null,
                toggles:null,
                isLoading: true,
                error:null,
                isPostSuccessful:false,
            };
        },
        validations() {
            return {
                forgottenPasswordViewModel: {
                    userName: { 
                        required
                    },
                    value: { 
                        required 
                    }                    
                }
            }
        },
        methods:{
            recoverPassword() {
                this.error = "";
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    return;
                }

                this.isSubmitting= true;
                
                post("/login/ForgottenPasswordData",
                    this.forgottenPasswordViewModel,
                    (response) => {
                        this.isPostSuccessful = true;
                    },
                    this,
                    () => { this.isSubmitting = false },
                    (err) => { 
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    }
                );
            },
            back() {
                this.$router.push({ path: '/login' });
            },
            loadDictionaries() {
                this.isLoading = true;

                getAll([
                    "/login/Dictionary",
                    "/login/CommonDictionary",
                ], (responses) => {
                    this.dictionary = responses[0].data;
                    this.commonDictionary = responses[1].data;
                    this.isLoading = false;
                }, this);
            }
        },
        beforeCreate() {
            getAll([
                "/login/Dictionary",
                '/login/Toggles',
                "/login/CommonDictionary",
                '/login/ForgottenPasswordData'
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.commonDictionary = responses[2].data;
                this.forgottenPasswordData = responses[3].data;
                this.isLoading = false;
            }, this);
        },
        mounted() {
            EventBus.$on("event-language-changed", () =>  {
                this.loadDictionaries();
            });
        },
        computed: {
            userNameValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.forgottenPasswordViewModel.userName.$error,
                        type: this.$v.forgottenPasswordViewModel.userName.required,
                        message: this.dictionary.errors.loginErrorUserNameRequired
                    }
                ];
            },
            dateOfBirthValidations() {
                return [
                    {
                        id: 2,
                        error: this.$v.forgottenPasswordViewModel.value.$error,
                        type: this.$v.forgottenPasswordViewModel.value.required,
                        message: this.dictionary.errors.loginErrorDateOfBirthRequired
                    }
                ];
            },
            textInputValidation(){
                  return [
                    {
                        id: 3,
                        error: this.$v.forgottenPasswordViewModel.value.$error,
                        type: this.$v.forgottenPasswordViewModel.value.required,
                        message: this.dictionary.errors.loginErrorsLoginRecoveryNotFilledIn
                    }
                ];
            }
        }
    }

</script>
<template>
<div id="exercises-vm">
    <router-view :dictionary="dictionary" :toggles="toggles" :orders-dictionary="ordersDictionary"></router-view>
</div>
</template>

<script>
    import { getAll } from 'api';

    export default {
        data() {
            return {
                dictionary: null,
                ordersDictionary: null,
                toggles: {},
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
            "/Exercises/Dictionary",
            "/Exercises/Toggles",
            "/Orders/Dictionary"
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.ordersDictionary = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    };
</script>

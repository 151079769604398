var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "questionnaire-container" }, [
    _vm.dictionary && !_vm.viewModel.isModal
      ? _c("div", { staticClass: "title-block" }, [
          _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.title))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "portlets-wrapper" },
      [
        _c("router-view", {
          key: "questionnaire-" + _vm.$route.fullPath,
          attrs: { dictionary: _vm.dictionary, "view-model": _vm.viewModel }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "table-striped",
            attrs: {
              "hide-default-header": _vm.isMobile,
              headers: _vm.headers,
              items: _vm.rows,
              options: _vm.options
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.startDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toDate")(item.startDate)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.endDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toDate")(item.endDate)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.custom-buttons",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "employment-details-button",
                          on: {
                            click: function($event) {
                              return _vm.openModal(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.dictionary.details) + " ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("employment-history-detail-modal", {
            attrs: {
              dictionary: _vm.dictionary,
              showModal: _vm.isModalOpened,
              selectedLocation: _vm.selectedLocation
            },
            on: {
              "close-modal": function($event) {
                return _vm.onModalClosed()
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
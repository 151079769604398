<template>
<div class="vesting-details-content">
    <VestingDetailsContentRow class="plan-name-row" :label="dictionary.labels.planName" :value="vesting.planName" />
    <VestingDetailsContentRow class="vesting-name-row" :label="dictionary.labels.vestingName" :value="vesting.vestingName" />
    <VestingDetailsContentRow class="vesting-date-row" :label="dictionary.labels.date" :value="vesting.vestingDate" />
    <VestingDetailsContentRow class="vesting-amount-row" :label="dictionary.labels.amount" :value="vesting.vestingAmount" />
    <VestingDetailsContentRow v-if="toggles.showVestingTaxDetails" class="share-price-row" :label="dictionary.labels.sharePrice" :value="replaceNullOrEmpty(vesting.sharePrice)" />
    <VestingDetailsContentRow v-if="toggles.showVestingTaxDetails" class="market-value-row" :label="dictionary.labels.marketValue" :value="replaceNullOrEmpty(vesting.marketValue)" />
    <VestingDetailsContentRow class="state-row" :label="dictionary.labels.state" :value="vesting.state" />
    
    <div class="vestings-row-flex">
        <span><p>* {{ dictionary.labels.atTimeOfVesting }}</p></span>
    </div>
</div>
</template>

<script>
import VestingDetailsContentRow from './vesting-details-content-row.component.vue';

export default {
    components: {
        VestingDetailsContentRow
    },
    props: {
        vesting: Object,
        dictionary: Object,
        toggles: Object
    }
}
</script>
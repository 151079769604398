var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-account-buttons button-container" },
    [
      _c("div", { staticClass: "default-account-buttons__cancel-button" }, [
        _c(
          "button",
          {
            staticClass: "btn secondaryButton",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("cancel-submit")
              }
            }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.dictionary.defaultAccountModal.btnCancel) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.maySetDefault
        ? _c("div", { staticClass: "default-account-buttons__submit-button" }, [
            _c(
              "button",
              {
                staticClass: "btn primaryButton",
                attrs: { type: "button", disabled: _vm.hasInvalidSettings },
                on: {
                  click: function($event) {
                    return _vm.$emit("submit-default-account")
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.dictionary.defaultAccountModal.btnSetAsDefault) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
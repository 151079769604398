<template>
    <div>
        <div class="title-block" style>
                <h1 v-if="dictionary">{{ dictionary.page.titleConfirmation }}</h1>
            </div>
            <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="model && dictionary" class="portlets-wrapper">
                <div class="row m-0" data-toggle="portlet">
                    <portlet-layout column-class="col-md-6" :title="dictionary.widgets.confirmation">
                        <template v-slot:body>
                            <loading-spinner :active="isFormProcessing"></loading-spinner>
                            <MoneyTransferConfirmationForm :dictionary="dictionary.confirmation" :commonDictionary="commonDictionary" :moneyTransferInstruction="model" :error="error" @accept-instruction="acceptInstruction" @reject-instruction="rejectInstruction" />
                        </template>
                    </portlet-layout>
                </div>
            </div>
    </div>
</template>

<script>
    import { getAll, post } from "api";
    import MoneyTransferConfirmationForm from "./money-transfer-confirmation-form.component.vue";

    export default {
        components: {
            MoneyTransferConfirmationForm
        },
        props: {
            toggles: Object,
            dictionary: Object
        },
        data() {
            return {
                moneyTranferInstruction: null,
                error: null,
                isLoading: true,
                isDecline: false,
                commonDictionary: null,
                isFormProcessing: false
            };
        },
        computed: {
            model() {
                return this.moneyTranferInstruction;
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to.name == 'money-transfer-position' && !this.isDecline) {
                this.isLoading = true;
                post(
                    "/MoneyTransfer/RejectInstruction",
                    this.prepareAcceptRejectModel(),
                   () => { },
                    this,
                    () => {
                        next();
                    },
                    () => { });
            } else {
                next();
            }
        },
        beforeCreate() {
            let query = this.$route.query;

            getAll([
                `/MoneyTransfer/Instruction?moneyInstructionId=${query.moneyInstructionId}&positionUsageId=${query.positionUsageId}`,
                "/Common/Dictionary"
                ],(response) => {
                    this.moneyTranferInstruction = response[0].data;
                    this.commonDictionary = response[1].data;
                },
                this,
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.moneyInstructionGoBack();
                }
            );
        },
        methods: {
            acceptInstruction() {
                this.isFormProcessing = true;

                post(
                    "/MoneyTransfer/AcceptInstruction",
                    this.prepareAcceptRejectModel(),
                    () => {
                        this.$router.push({ path: '/payments' });
                    },
                    this,
                    () => {
                        this.isFormProcessing = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            rejectInstruction() {
                this.isFormProcessing = true;
                post(
                    "/MoneyTransfer/RejectInstruction",
                    this.prepareAcceptRejectModel(),
                    () => {
                        this.isDecline = true;
                        this.$router.push({ path: '/portfolio' });
                    },
                    this,
                    () => {this.isFormProcessing = false;},
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            },
            prepareAcceptRejectModel() {
                return {
                    moneyInstructionId: this.moneyTranferInstruction.moneyInstructionId
                };
            },
            moneyInstructionGoBack() {
                let query = this.$route.query;

                switch (this.$route.query.returnPage) {
                    case "portfolio":
                        this.$router.push({ path: '/portfolio' });
                        break;
                    default:
                        this.$router.push({
                            path: "/moneyTransfer",
                            query: {
                                positionUsageId: query.positionUsageId,
                                sharePlanParticipationId: query.sharePlanParticipationId
                            },
                        });
                }
            }
        }
    };
</script>
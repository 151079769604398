import { head } from 'api';

const checkIntervalTime = 60 * 1000;

export const sessionCheck = {
    data() {
        return {
            checkInterval: null,
            isPageUnloading: false,
        }
    },
    mounted() {
        this.scheduleCheck();
        document.addEventListener('pagehide', this.onPageHide);
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        window.addEventListener('beforeunload', this.onBeforeUnload);
    },
    beforeDestroy() {
        document.removeEventListener('pagehide', this.onPageHide);
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('beforeunload', this.onBeforeUnload);
        this.cancelScheduledCheck();
    },
    methods: {
        async onPageHide() {
            await this.onVisibilityChangeInternal(false);
        },
        async onVisibilityChange() {
            await this.onVisibilityChangeInternal(document.visibilityState === 'visible');
        },
        async onVisibilityChangeInternal(isVisible) {
            this.cancelScheduledCheck();
            if (isVisible) {
                await this.processCheck();
            }
        },
        async processCheck() {
            const path = window.location.pathname;

            return head(
                '/api/session/heartbeat',
                () => {
                    if (path.startsWith("/login")) {
                        window.location.reload();
                    }
                },
                this,
                () => {
                    this.scheduleCheck();
                },
                (error) => {
                    if (error.response?.status === this.$HttpStatusCodes.UNAUTHORIZED
                        && !path.startsWith("/login") && !this.isPageUnloading) {
                        window.location.reload();
                    }
                });
        },
        cancelScheduledCheck() {
            if (this.checkInterval) {
                clearInterval(this.checkInterval);
                delete this.checkInterval
            }
        },
        scheduleCheck() {
            this.cancelScheduledCheck();
            this.checkInterval = setInterval(this.processCheck, checkIntervalTime);
        },
        onBeforeUnload() {
            this.isPageUnloading = true;
            this.cancelScheduledCheck();
        }
    }
}
<template>
  <nav class="navbar topnavbar" aria-label="Top navigation"  v-if="skin">
    <div class="navbar-header">
      <div id="navbar-logo-vm">
        <logo-image
          :logo-desktop="skin.images.logo"
          :logo-mobile="skin.images.logoMobile"
          :logo-favicon="skin.images.favicon"
          :logo-alignment="skin.layout.logoAlignment"
          :logo-overlap="skin.layout.logoOverlap"
        ></logo-image>
      </div>
    </div>
    <CurrentEnvironment v-if="currentEnvironment" :text="currentEnvironment"></CurrentEnvironment>
    <ul class="navbar-nav">
      <li class="nav-item pr-1" v-if="!isMobile && showHeaderLoginHelp">
          <HeaderLoginHelp v-if="dictionary" :label="dictionary.loginHelp"></HeaderLoginHelp>
      </li>
      <li class="nav-item pl-1 pr-lg-2" v-if="showHeaderLoginLanguage">
          <HeaderLoginLanguage :current-language="currentLanguage"></HeaderLoginLanguage>
      </li>
    </ul>
  </nav>
</template>

<script>
import HeaderLoginHelp from "./header-login-help.component.vue";
import HeaderLoginLanguage from "./header-login-language.component.vue";
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import { EventBus } from "Shared/event-bus";
import  CurrentEnvironment from './header-current-environment.component.vue';

export default {
  name: "header-login-container",
  mixins: [ScreenSizeMixin],
  props: {
    skin: Object,
    dictionary: Object,
    currentLanguage: String,
    currentEnvironment: String
  },
  components: {
    HeaderLoginHelp,
    HeaderLoginLanguage,
    CurrentEnvironment
  },
  data() {
    return {
      showHeaderLoginHelp: true,
      showHeaderLoginLanguage: true
    }
  },
  mounted() {
      EventBus.$on("event-header-login", (payLoad) => {
          this.showHeaderLoginHelp = payLoad.showHeaderLoginHelp
          this.showHeaderLoginLanguage = payLoad.showHeaderLoginLanguage
      });
  },  
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "primary-color h4" }, [
              _vm._v(
                _vm._s(_vm.dictionary.table.headingDetails) +
                  " : " +
                  _vm._s(_vm.choice.choiceStartDate)
              )
            ])
          ]),
          _vm._v(" "),
          _c("ChoiceDetails", {
            attrs: {
              dictionary: _vm.dictionary,
              choice: _vm.choice,
              isTablet: _vm.isTablet
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
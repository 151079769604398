var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "required-documents-details-content" },
    [
      _c("RequiredDocumentsDetailsRow", {
        attrs: {
          label: _vm.dictionary.table.documentName,
          value: _vm.requiredDocument.name
        }
      }),
      _vm._v(" "),
      _vm.requiredDocument.expiryDate
        ? _c("RequiredDocumentsDetailsRow", {
            attrs: {
              label: _vm.dictionary.table.expiryDate,
              value: _vm._f("toDate")(_vm.requiredDocument.expiryDate)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("RequiredDocumentsDetailsRow", {
        attrs: {
          label: _vm.dictionary.table.documentState,
          value: _vm.requiredDocument.stateDescription
        }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary w-100",
          attrs: { role: "button" },
          on: {
            click: function($event) {
              return _vm.$emit(
                "action",
                _vm.requiredDocument.requiredDocumentId,
                _vm.requiredDocument.requiredDocumentParticipationId
              )
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.getActionButtonText(_vm.requiredDocument, _vm.dictionary)
            )
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="tab-container tab-row">
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{pagesDictionary.beneficiary.title}}</h1>
        </div>
        <component-spinner :spin="isLoading"></component-spinner>
        <!-- Nav tabs -->
        <div v-if="!isLoading" class="tab-bar" id="beneficiary-vm">
            <ul class="nav nav-tabs" role="tablist">
                <li v-if="mayViewAccountData" class="nav-item" role="presentation">
                    <a class="nav-link" id="account-tab" data-hash="#account" role="tab" data-toggle="tab" @click="setCurrentTab($event)">{{accountDictionary.tabs.account}}</a>
                </li>
                <li v-if="mayViewFinancialData" class="nav-item" role="presentation">
                    <a class="nav-link" id="financial-tab" data-hash="#financial" role="tab" data-toggle="tab" @click="setCurrentTab($event)">{{accountDictionary.tabs.financial}}</a>
                </li>
                <li v-if="mayViewEvaluationData" class="nav-item" role="presentation">
                    <a class="nav-link" id="evaluation-tab" data-hash="#evaluation" role="tab" data-toggle="tab" @click="setCurrentTab($event)">{{accountDictionary.tabs.evaluation}}</a>
                </li>
                <li v-if="mayViewCompanyData" class="nav-item" role="presentation">
                    <a class="nav-link" id="company-tab" data-hash="#company" role="tab" data-toggle="tab" @click="setCurrentTab($event)">{{accountDictionary.tabs.company}}</a>
                </li>
            </ul>
            <div class="tab-content card-shadow" id="beneficiary-data">
                <div v-if="mayViewAccountData" role="tabpanel" class="tab-pane fade" id="account">
                    <div class="portlets-wrapper bg-white">
                        <AccountDetails :account-dictionary="accountDictionary" :common-dictionary="commonDictionary"
                                        :account-data-model="accountDataModel" :address-data-model="addressDataModel"
                                        :is-account-edited="isAccountEdited" :account-data-edit-model="accountDataEditModel"
                                        :is-address-edited="isAddressEdited" :address-data-edit-model="addressDataEditModel"
                                        :toggles="beneficiaryToggles" :loading-account-data="loadingAccountData"
                                        :beneficiary-boundaries-data-model="beneficiaryBoundariesDataModel"
                                        :mfa-boundaries-data-model="mfaBoundariesDataModel"
                                        v-on:account-edit-form-cancelled="accountEditFormCancelled"
                                        v-on:address-edit-form-cancelled="addressEditFormCancelled"
                                        v-on:edit-account="editAccount" v-on:edit-address="editAddress"
                                        v-on:reload-page="reloadPage" v-on:edit-mfa="editMfa" />
                    </div>
                </div>
                <div v-if="mayViewFinancialData" role="tabpanel" class="tab-pane fade" id="financial">
                    <FinancialDetails :financial-dictionary="financialDictionary" :financial-toggles="beneficiaryToggles"
                                      :money-account-data="moneyAccountsDataModel" :security-account-data="securityAccountDataModel"
                                      :beneficiary-boundaries-data="beneficiaryBoundariesDataModel"
                                      :loading-dictionaries-and-toggles="loadingDictionariesAndToggles"
                                      :datatables-dictionary="datatablesDictionary"
                                      v-on:reload-bank-account-data="reloadBankAccountData" />
                </div>
                <div v-if="mayViewEvaluationData" role="tabpanel" class="tab-pane fade" id="evaluation">
                    <div class="portlets-wrapper bg-white">
                        <EvaluationDetails :financial-dictionary="financialDictionary"
                                           :account-dictionary="accountDictionary"
                                           :datatables-dictionary="datatablesDictionary"
                                           :beneficiary-toggles="beneficiaryToggles" />
                    </div>
                </div>
                <div v-if="mayViewCompanyData" role="tabpanel" class="tab-pane fade" id="company">
                    <div class="portlets-wrapper bg-white">
                        <CompanyDetails
                                :account-dictionary="accountDictionary"
                                :employment-history="employmentHistory"
                                :current-employment="accountDataModel.currentEmployment"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get, getAll } from 'api';
    import BeneficiaryDataLabel from "../data-label.component.vue";
    import BeneficiaryActionLabel from "../action-label.component.vue";
    import BeneficiaryTrueFalseLabel from "../true-false-label.component.vue";
    import BeneficiaryForm from "../Account/form.component.vue";
    import AccountDetails from "../Account/account-details.component.vue";
    import FinancialDetails from "../Financial/financial-details.component.vue";
    import EvaluationDetails from "../Evaluation/evaluation-details.component.vue";
    import CompanyDetails from "../Company/company.component.vue";
    import { beneficiaryTabsViewRightsMixin } from "./mixins/beneficiary-tabs-view-rights.mixin.js";

    export default {
        mixins: [beneficiaryTabsViewRightsMixin],
        components: {
            BeneficiaryForm,
            BeneficiaryDataLabel,
            BeneficiaryActionLabel,
            BeneficiaryTrueFalseLabel,
            AccountDetails,
            FinancialDetails,
            EvaluationDetails,
            CompanyDetails
        },
        props: {
            pagesDictionary: Object
        }, 
        data() {
            return {
                addressDataModel: {},
                addressDataEditModel: {},
                accountDataModel: {},
                accountDataEditModel: {},
                employmentHistory: [],
                isAddressEdited: false,
                isAccountEdited: false,
                commonDictionary: null,
                accountDictionary: null,
                financialDictionary: null,
                datatablesDictionary: null,
                beneficiaryToggles: null,
                moneyAccountsDataModel: [],
                securityAccountDataModel: [],
                beneficiaryBoundariesDataModel: {},
                mfaBoundariesDataModel: {},
                loadingDictionariesAndToggles: true,
                loadingAccountData: true,
                currentActiveTab: null,
            };
        },

        watch: {
            $route(to, from) {
                this.updateTabs();
            }
        },
        computed: {
            isLoading() {
                return (this.loadingDictionariesAndToggles || this.loadingAccountData);
            }
        },
        beforeCreate() {
            get('/Beneficiaries/BeneficiaryBoundaries', (response) => {
                this.beneficiaryBoundariesDataModel = response.data;
                this.getData();
            }, this);

            get('/Mfa/MfaBoundaries', (response) => {
                this.mfaBoundariesDataModel = response.data;
            }, this);

            getAll([
                "/Common/Dictionary",
                "/Beneficiaries/AccountDictionary",
                "/Beneficiaries/FinancialDictionary",
                "/Beneficiaries/BeneficiaryToggles",
                '/Beneficiaries/DatatablesDictionary',
            ], (responses) => {
                this.commonDictionary = responses[0].data;
                this.accountDictionary = responses[1].data;
                this.financialDictionary = responses[2].data;
                this.beneficiaryToggles = responses[3].data;
                this.datatablesDictionary = responses[4].data;
                this.loadingDictionariesAndToggles = false;

                this.$setPageTitle(this.accountDictionary.page.title);
            }, this);
        },
        updated() {
            this.updateTabs();
        },
        methods: {
            updateTabs() {
                if(location.hash) {
                    this.currentActiveTab = document.querySelector('.nav-tabs a[data-hash="' + this.getSelectedTabNameFromHash(location.hash) + '"]');
                }

                document.title = this.accountDictionary ? this.accountDictionary.page.title : '';
                const personalDataTabs = document.querySelectorAll('[role="tab"]');
                if (personalDataTabs.length > 0) {
                    if (this.currentActiveTab == null) {
                        this.showFirstTabAsActive(personalDataTabs[0]);
                    }
                    else {
                        this.showFirstTabAsActive(this.currentActiveTab);
                    }
                }
            },
            getSelectedTabNameFromHash(hash){
                if(hash){
                    return hash.split('&')[0];
                }

                return null;
            },
            showFirstTabAsActive(tabToActivate) {
                let panelToActivate = null;
                if (tabToActivate) {
                    [...tabToActivate.parentElement.parentElement.children].forEach(t => t.firstChild.classList.remove('active'))
                    tabToActivate.classList.add("active");
                    this.currentActiveTab = tabToActivate;
                    const panelId = tabToActivate.id.replace('-tab', '');
                    panelToActivate = document.querySelector(`#${panelId}`);
                }
                if (panelToActivate) {
                    [...panelToActivate.parentElement.children].forEach(t => t.classList.remove('active'));
                    [...panelToActivate.parentElement.children].forEach(t => t.classList.remove('show'));
                    panelToActivate.classList.add("show");
                    panelToActivate.classList.add("active");
                }
            },
            setCurrentTab(tab) {
                this.currentActiveTab = tab.target;
                let hash = tab.target.getAttribute("data-hash");
                if (window.location.hash != hash) {
                    this.$router.replace({ hash: hash });
                }
                this.updateTabs();
            },
            getData() {
                let urlPaths = [
                    "/Beneficiaries/BeneficiaryAddressData",
                    "/Beneficiaries/BeneficiaryAccountData",
                ];

                if (this.beneficiaryBoundariesDataModel.mayViewMoneyBankAccount) {
                    urlPaths.push("/Beneficiaries/MoneyAccounts");
                }

                if (this.beneficiaryBoundariesDataModel.mayViewSecuritiesBankAccount) {
                    urlPaths.push("/Beneficiaries/SecurityAccounts");
                }

                if (this.beneficiaryBoundariesDataModel.mayViewPersonalData) {
                    urlPaths.push("Beneficiaries/BeneficiaryEmploymentHistory")
                }

                getAll(urlPaths, (responses) => {
                    responses.forEach(response => {
                        switch (this.$getUrlPathWithoutQueryString(response.config.url)) {
                            case "/Beneficiaries/BeneficiaryAddressData":
                                this.addressDataModel = response.data;
                                break;
                            case "/Beneficiaries/BeneficiaryAccountData":
                                this.accountDataModel = response.data;
                                break;
                            case "/Beneficiaries/MoneyAccounts":
                                this.moneyAccountsDataModel = response.data;
                                break;
                            case "/Beneficiaries/SecurityAccounts":
                                this.securityAccountDataModel = response.data;
                                break;
                            case "Beneficiaries/BeneficiaryEmploymentHistory":
                                this.employmentHistory = response.data?.employmentHistory;
                                break;
                            default:
                        }
                    });
                    this.loadingAccountData = false;
                }, this);
            },
            editAddress() {
                getAll([
                    "/Beneficiaries/BeneficiaryAddressEditData",
                    "/Common/GetCountries"
                ], (responses) => {
                    this.addressDataEditModel = responses[0].data;
                    this.addressDataEditModel.countries = responses[1].data;
                    this.isAddressEdited = true;
                }, this);
            },
            editAccount() {
                this.accountDataEditModel = Object.assign({}, this.accountDataModel);
                this.isAccountEdited = true;
            },
            editMfa(newMfaValue) {
                this.accountDataModel.mfaEnabled = newMfaValue;
                this.accountDataEditModel = Object.assign({}, this.accountDataModel);
            },
            addressEditFormCancelled() {
                this.isAddressEdited = false;
            },
            accountEditFormCancelled() {
                this.accountDataEditModel = Object.assign({}, this.accountDataModel);
                this.isAccountEdited = false;
            },
            reloadPage() {
                location.reload(true);
            },
            reloadBankAccountData(payLoad) {
                if (payLoad.accountType === this.$BankAccountType.MONEY) {
                    get("/Beneficiaries/MoneyAccounts", (responses) => {
                        this.moneyAccountsDataModel = responses.data;
                        this.loadingAccountData = false;
                    }, this);
                }
                if (payLoad.accountType === this.$BankAccountType.SECURITY) {
                    get("/Beneficiaries/SecurityAccounts", (responses) => {
                        this.securityAccountDataModel = responses.data;
                        this.loadingAccountData = false;
                    }, this);
                }
            }
        }
    }
</script>
<template>
    <footer :id="footerId" class="footer-container">
        <component-spinner secondary-color :spin="isLoading"></component-spinner>
        <div class="footer">


            <div id="footerImageContainer" class="footer-header">
                <div class="footer-sub-header">
                    <div v-if="!toggles.hideFooterLogo" class="footer-image-container pr-6">
                        <img :src="getAppropriateLogo" :alt="dictionary.footerLogoAltText" />
                    </div>
                    <div id="footerInfoContainer" class="align-self-center footer-info-container">
                        <FooterInfo :model="model" />
                    </div>
                </div>
               
                <div class="align-self-center footer-links">
                     <FooterLinks :dictionary="dictionary" :toggles="toggles" />
                </div>
            </div>
           
            <div v-if="!toggles.hideFooterCustomText" id="footerTextContainer" class="footer-text-container">
                <p class="rich-text-container" v-html="$sanitize(dictionary.footerCustomText)"></p>
            </div>
        </div>
    </footer>
</template>

<script>

import FooterInfo from "./footer-info.component.vue";
import FooterLinks from "./footer-links.component.vue";

export default {
  components: {
    FooterInfo,
    FooterLinks
  },
  data(){
        return{
            isMobile:false,
            isTablet:false,
        }
    },
  props: {
        model: Object,
        isLoading: Boolean,
        logoDesktop: String,
        logoMobile: String,
        isDefaultFooter: Boolean
    },
    created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
        handleResize() {
            this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
                && window.innerWidth > this.$windowSizes.MOBILE;
            this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
        },
    },
    computed:{
        dictionary() {
            return this.model.dictionary;
        },
        toggles() {
            return this.model.toggles;
        },
        getAppropriateLogo() {
            if ((this.isTablet || this.isMobile) && this.logoMobile)
            {
                return this.logoMobile;
            }
            return this.logoDesktop;
        },
        footerId() {
            return this.isDefaultFooter ? "footer" : "footer-login"
        }
    }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.dictionary.orderConfirmTitle))]),
      _vm._v(" "),
      _c(
        "dl",
        { staticClass: "order-details-list" },
        [
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.planName,
              value: _vm.buyInstructionModel.planName
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.positionName,
              value: _vm.buyInstructionModel.buyUnderlyingDescription
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.orderType,
              value: _vm.dictionary.orderTypeBuyName
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.scenarioName,
              value: _vm.buyInstructionModel.buyScenarioName
            }
          }),
          _vm._v(" "),
          _vm.buyInstructionModel.executionMoment
            ? _c("BuyConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.executionMoment,
                  value: _vm.buyInstructionModel.executionMoment.description
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.reference,
              value: _vm.buyInstructionModel.reference
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.instructionDate,
              value: _vm._f("toDateTime")(
                _vm.buyInstructionModel.instructionDate
              )
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.buyDate,
              value: _vm._f("toDate")(_vm.buyInstructionModel.buyDate)
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.tradeDate,
              value: _vm._f("toDate")(_vm.buyInstructionModel.tradeDate)
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.ordered,
              value: _vm.buyInstructionModel.isInstructionInMoney
                ? _vm.buyInstructionModel.amountOrderedFormatted
                : _vm.buyInstructionModel.nrOrdered
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.securityPrice,
              value: _vm.buyInstructionModel.securityPriceFormatted
            }
          }),
          _vm._v(" "),
          _vm.buyInstructionModel.limitPrice > 0
            ? _c("BuyConfirmationRow", {
                attrs: {
                  label: _vm.dictionary.limitPrice,
                  value: _vm.buyInstructionModel.limitPriceFormatted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("payments-result", {
            attrs: {
              payments: _vm.buyInstructionModel.feesPayments,
              noPaymentsLabel: _vm.dictionary.paymentFees
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            attrs: {
              label: _vm.dictionary.brokerFees,
              value: _vm.buyInstructionModel.brokerFeesFormatted
            }
          }),
          _vm._v(" "),
          _c("BuyConfirmationRow", {
            staticClass: "confirmation-total-row",
            attrs: {
              label: _vm.dictionary.netAmount,
              value: _vm.buyInstructionModel.netAmountFormatted
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.buyInstructionModel.disclaimers.length
        ? _c(
            "BuyConfirmationButtonsComponent",
            _vm._g(
              { attrs: { buyInstructionModel: _vm.buyInstructionModel } },
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
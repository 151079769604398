<template>
    <div>
        <disclaimers :disclaimers="exitInstructionViewModel.disclaimers" />
        <ExitConfirmationButtonsComponent :isQuoteExpires="isQuoteExpires" v-on="$listeners" :exitInstructionViewModel="exitInstructionViewModel" />
    </div>
</template>

<script>
    import ExitConfirmationButtonsComponent from "./exit-confirmation-buttons.component.vue";

    export default {
        components: {
            ExitConfirmationButtonsComponent
        },
        props: {
            exitInstructionViewModel: Object,
            isQuoteExpires: Boolean
        }
    }
</script>
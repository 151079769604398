<template>
<div>
    <div class="title-block">
        <h1 v-if="pagesDictionary">{{ pagesDictionary.reports.title }}</h1>
    </div>

    <div class="portlets-wrapper reports-container">
            <component-spinner :spin="isLoading"></component-spinner>
            <router-view v-if="!isLoading" :dictionary="dictionary" :toggles="toggles" :boundaries="boundaries"></router-view>
    </div>
</div>
</template>

<script>
    import { getAll } from 'api';

    export default {
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: {},
                toggles: {},
                boundaries: {},
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                '/Reports/Dictionary',
                '/Reports/Toggles',
                '/Reports/Boundaries'
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.boundaries = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    };
</script>

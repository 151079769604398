var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", [
    _c(
      "span",
      { staticClass: "primary-label" },
      [
        _vm.hasIcon
          ? [
              _c("i", {
                staticClass: "far fa-tag",
                attrs: { "aria-hidden": "true" }
              })
            ]
          : _vm._e(),
        _vm._v("        \n        " + _vm._s(_vm.label) + ":\n    ")
      ],
      2
    ),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.value))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        { staticClass: "cookie-policy-page" },
        [
          _c("div", { staticClass: "cookie-policy-page__header" }, [
            _c(
              "button",
              {
                staticClass: "cookie-policy-page__arrow",
                attrs: { "aria-label": _vm.dictionary.labels.btnGoBack },
                on: {
                  click: function($event) {
                    return _vm.goBack()
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "far fa-arrow-left fa",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cookie-policy-page__title" }, [
              _vm._v(" " + _vm._s(_vm.dictionary.page.headerCookiesPolicy))
            ])
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.dictionary.page.textCookiesPolicyLastUpdated))
          ]),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            staticClass: "mt-13",
            attrs: {
              richText: true,
              title: _vm.dictionary.page.titleCookiesPolicyCookiesExplain,
              text: _vm.dictionary.page.textCookiesPolicyConsent
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-4 mb-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-primary-white-background",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.openCookiePolicyModal()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.dictionary.page.btnManageCookies))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            staticClass: "mt-13",
            attrs: {
              title: _vm.dictionary.page.titleCookiesPolicyDetails,
              text: _vm.dictionary.page.textCookiesPolicyDetails
            }
          }),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            attrs: {
              listStyle: true,
              title: _vm.dictionary.page.titleStrictlyNecessary,
              text: _vm.dictionary.page.textCookiesStrictlyNecessary
            }
          }),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            attrs: {
              listStyle: true,
              title: _vm.dictionary.page.titleFunctional,
              text: _vm.dictionary.page.textFunctionalCookies
            }
          }),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            attrs: {
              listStyle: true,
              title: _vm.dictionary.page.titlePerformanceAnalytics,
              text: _vm.dictionary.page.textPerformanceAnalytics
            }
          }),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            staticClass: "mt-13",
            attrs: {
              richText: true,
              title: _vm.dictionary.page.titleManageBrowser,
              text: _vm.dictionary.page.textManageBrowser
            }
          }),
          _vm._v(" "),
          _c("CookiePolicyRow", {
            staticClass: "mt-13",
            attrs: {
              richText: true,
              title: _vm.dictionary.page.policyMoreInfoTitle,
              text: _vm.dictionary.page.policyMoreInfoText
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.condition
    ? _c(
        "portlet-layout",
        _vm._b(
          {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [_vm._t("title")]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [_vm._t("body")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          },
          "portlet-layout",
          Object.assign({}, _vm.$attrs, _vm.$props, {
            columnClass: _vm.portletClass
          }),
          false
        )
      )
    : _c("div", { class: _vm.divClass }, [_vm._t("body")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
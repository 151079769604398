export const positionMethods = {
    methods: {
        getValueFormatted(position, toggles) {
            if (toggles?.useAvailableValueDisplay) {

                return position.availableValueFormatted;
            }
            return position.valueFormatted;
        },
        getExpectedValue(position, toggles) {
            if (toggles?.useAvailableValueDisplay) {

                return position.expectedAvailableValueFormatted;
            }
            return position.expectedValueFormatted;
        }
    }
}
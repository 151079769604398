var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-bar" },
    [
      _c("component-spinner", {
        attrs: { spin: _vm.loadingDictionariesAndToggles }
      }),
      _vm._v(" "),
      !_vm.loadingDictionariesAndToggles
        ? _c(
            "b-tabs",
            { ref: "tabs", staticClass: "tab-container" },
            [
              _vm.beneficiaryBoundariesData && _vm.financialToggles
                ? [
                    _vm.showTabMoney
                      ? _c("FinancialAccountTab", {
                          attrs: {
                            financialDictionary: _vm.financialDictionary,
                            bankAccountData: _vm.moneyAccountData,
                            accountType: _vm.$BankAccountType.MONEY,
                            dictionary:
                              _vm.financialDictionary.bankAccount.money,
                            tabTitle: _vm.financialDictionary.moneyTabName,
                            tabId: "BankAccount",
                            widgetTitle:
                              _vm.financialDictionary.widget.bankAccount,
                            isActive: true,
                            "beneficiary-boundaries-data":
                              _vm.beneficiaryBoundariesData,
                            "financial-toggles": _vm.financialToggles
                          },
                          on: {
                            "reload-bank-account-data":
                              _vm.reloadBankAccountData
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTabSecurity
                      ? _c("FinancialAccountTab", {
                          attrs: {
                            financialDictionary: _vm.financialDictionary,
                            bankAccountData: _vm.securityAccountData,
                            accountType: _vm.$BankAccountType.SECURITY,
                            dictionary:
                              _vm.financialDictionary.bankAccount.security,
                            tabTitle: _vm.financialDictionary.securityTabName,
                            tabId: "SecurityBankAccount",
                            widgetTitle:
                              _vm.financialDictionary.widget
                                .securityBankAccount,
                            "beneficiary-boundaries-data":
                              _vm.beneficiaryBoundariesData,
                            "financial-toggles": _vm.financialToggles
                          },
                          on: {
                            "reload-bank-account-data":
                              _vm.reloadBankAccountData
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showTabTransferSettings
                ? _c("FinancialTransferSettingsTab", {
                    attrs: {
                      financialDictionary: _vm.financialDictionary,
                      "datatables-dictionary": _vm.datatablesDictionary
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <portlet-layout :hide-title="true" :hide-shadow="false">
        <template v-slot:body>
            <div id="inbox-container">
                <div class="form-row">
                    <div v-show="!isMobileRowClicked || !isMobileOrTablet" class="col-md-6 col-lg-5 col-xl-4 email-list">
                        <EmailsTable  :datatablesDictionary="datatablesDictionary" :dictionary="dictionary" @dataRefreshed="onDataRefresh" @rowClicked="onRowClicked" @loadedTable="onLoadTable" />
                    </div>
                    <div v-if="!isMobileOrTablet || (isMobileRowClicked && isMobileOrTablet)"  id="message-container" class="col-md-6 col-lg-7 col-xl-8 message-container">
                        <component-spinner :spin="isLoading"></component-spinner>
                        <EmailContent v-if="!isLoading" headerVisible :isMobileOrTablet="isMobileOrTablet" :message="message" :model="model" @back-click="backClick" />
                    </div>
                </div>
            </div>
        </template>
    </portlet-layout>
</template>

<script>
    import EmailsTable from './emails-table.component.vue';
    import EmailContent from './email-content.component.vue';
    import { get } from 'api';

    export default {
        components: {
            EmailsTable,
            EmailContent
        },
        props: {
            model: Object,
            datatablesDictionary: Object,
            dictionary: Object
        },
        data() {
            return {
                isLoading: true,
                isMobileOrTablet: false,
                isFirstTimeCreated: true,
                isMobileRowClicked: false,
                message: {},
                messageId: 0,
                tabletSize: 767
            }
        },
        computed: {
            modalTitle() {
                return this.isLoading ? "" : this.message.subject;
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.isMobileOrTablet = window.innerWidth <= this.tabletSize;
                if(!this.isMobileOrTablet)
                {
                    this.isMobileRowClicked = false;
                }
            },
            onRowClicked($event) {
                this.messageId = $event;
                this.isFirstTimeCreated = false;
                if(this.isMobileOrTablet === true)
                {
                    this.isMobileRowClicked = true;
                }
                this.isLoading = true;
                get(`/Inbox/Details?messageId=${$event}`, (response) => {
                    this.message = response.data;
                }, this, () => {
                    this.isLoading = false;
                });
            },
            onDataRefresh($event) {
                if (!this.messageId) {
                    this.messageId = $event;
                }

                if (this.messageId && (!this.isFirstTimeCreated && !this.isMobileOrTablet)) {
                    let elLink = document.getElementById(this.messageId);
                    if (elLink && !(document.querySelector('.email-list').classList.contains('hide-element') 
                    && document.querySelector('.email-content').classList.contains('hide-element'))) {
                        elLink.click();
                    }
                }
            },
            onLoadTable() {
                const firstItem = document.querySelector(".email-list-item");
                if (firstItem !== null) {
                    const firstItemId = firstItem.dataset.messageid;
                    if (!this.isMobileOrTablet) {
                        this.onRowClicked(firstItemId);
                    }
                }
                else {
                     this.isLoading = false;
                }
            },
            backClick(eventData) 
            {
                this.isMobileRowClicked = eventData;
            }
        }
    }
</script>
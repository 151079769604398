<template>
    <div>
        <div class="title-block single-line">
            <h1 v-if="positionModel">{{ positionModel.name }}</h1>
        </div>
        <div class="portlets-wrapper">
         <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="positionModel && dictionary.blocks" class="row m-0">
                <div class="col-md-8 col-xl-9 p-0">
                    <div class="row m-0">
                    <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalAvailable" :text="positionModel.availableFormatted" />
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalValue" :text="getValueFormatted(positionModel, toggles)"></total-block>
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalBalance" :text="positionModel.internalExchangeInstructionOrder.internalExchangeBoundaries.availableMoneyAmountFormatted" />
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <security-price-block :title="dictionary.blocks.lastPrice" :endpoint="lastPriceEndpoint"/>
                            </template>
                        </portlet-layout>
                    </div>
                    <div class="row m-0">
                        <div class="col-xl-5 p-0">
                            <portlet-layout v-if="positionModel.internalExchangeInstructionOrder.internalExchangeBoundaries.canInstructBuyOrder || positionModel.internalExchangeInstructionOrder.internalExchangeBoundaries.canInstructSellOrder" column-class="block" :title="dictionary.widgets.placeOrder">
                                <template v-slot:body>
                                    <InternalExchangesOrderForm :dictionary="dictionary" :positionModel="positionModel" :instructionModel="instructionModel" :error="error" @submit="submitBuyOrder" />
                                    <loading-spinner :active="isSubmitting"></loading-spinner>
                                </template>
                            </portlet-layout>

                            <alert-component v-else variant="warning">{{ dictionary.page.positionDetailTextNoInstructionAvailable }}</alert-component>

                            <portlet-layout v-if="toggles.showLastTrades" column-class="block" :title="dictionary.widgets.lastTrades">
                                <template slot="body">
                                    <OrderBookTrades :dictionary="dictionary" />
                                </template>
                            </portlet-layout>
                            <portlet-layout v-else-if="!toggles.hideOrderBookLastExecutions" column-class="block" :title="dictionary.widgets.lastExecutions">
                                <template slot="body">
                                    <OrderBookExecutions :dictionary="dictionary" />
                                </template>
                            </portlet-layout>
                        </div>
                        <div class="col-xl-7 p-0">
                            <portlet-layout v-if="!toggles.hideOrderBooks" column-class="block" :title="dictionary.widgets.orderBook">
                                <template slot="customCollapse">
                                    <OrderBookCustomCollapse v-if="instructionModel.internalExchangeBoundaries.hasCalculatedBestPrice" :dictionary="dictionary" :instructionModel="instructionModel"></OrderBookCustomCollapse>
                                </template>
                                <template slot="body">
                                    <OrderBook :dictionary="dictionary" />
                                </template>
                            </portlet-layout>
                            <portlet-layout v-if="!toggles.hideSecurityPricesChartBlock" column-class="block" :title="dictionary.widgets.securityPricesChart">
                                <template slot="body">
                                    <prices-chart :getSecurityPrices="true" :securityId="positionModel.securityId" :securityUnderlyingId="positionModel.securityUnderlyingId" />
                                </template>
                            </portlet-layout>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-3 p-0">
                    <portlet-layout v-if="!toggles.hideLastOrdersLinkBlock" column-class="block" :title="dictionary.widgets.lastOrders">
                        <template slot="body">
                            <last-orders :toggles="toggles" :positionUsageId="positionModel.positionUsageId" />
                            <order-detail v-if="ordersDictionary" :title="ordersDictionary.modal.headerTitle" :toggles="toggles"></order-detail>
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/orders" :title="dictionary.widgets.seeAllOrders">{{dictionary.widgets.seeAllOrders}}</router-link>
                        </template>
                    </portlet-layout>
                    <portlet-layout v-if="!toggles.hideLastTransactionsLinkBlock" column-class="block" :title="dictionary.widgets.lastTransactions">
                        <template slot="body">
                            <last-transactions :positionUsageId="positionModel.positionUsageId" />
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/transactions" :title="dictionary.widgets.seeAllTransactions">{{dictionary.widgets.seeAllTransactions}}</router-link>
                         </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { get, post } from "api";
    import OrderDetail from "/Features/Orders/Assets/Vue/order-detail.component.vue";
    import InternalExchangesOrderForm from "./internal-exchanges-order-form.component.vue";
    import OrderBook from "./internal-exchanges-orderbook.component.vue"
    import OrderBookExecutions from "./internal-exchanges-orderbook-executions.component.vue"
    import numeral from 'numeral'
    import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';
    import OrderBookTrades from "./internal-exchanges-orderbook-trades.component.vue"
    import OrderBookCustomCollapse from './internal-exchange-orderbook-custom-collapse.component.vue';

    export default {
        mixins: [ positionMethods ],
        components: {
            InternalExchangesOrderForm,
            OrderBook,
            OrderBookExecutions,
            OrderDetail,
            OrderBookTrades,
            OrderBookCustomCollapse
        },
        props: {
            toggles: Object,
            dictionary: Object,
            ordersDictionary: Object
        },
        data() {
            return {
                positionModel: null,
                instructionModel: null,
                error: null,
                isLoading: true,
                isSubmitting: false
            };
        },
        computed:{
            lastPriceEndpoint(){
                let query = this.$route.query;
                return `/InternalExchanges/GetLastPriceBlock?positionUsageId=${query.positionUsageId}`;
            }
        },
        beforeCreate() {
            let query = this.$route.query;
            get(
                `/InternalExchanges/InstructionOrder?positionUsageId=${query.positionUsageId}&orderType=${query.orderType}`,
                (response) => {
                    this.positionModel = response.data;
                    this.instructionModel = response.data.internalExchangeInstructionOrder;
                    if (query.orderType) {
                        this.instructionModel.orderType = query.orderType;
                    }
                },
                this,
                () => (this.isLoading = false)
            );
        },
        methods: {
            submitBuyOrder(instructionModel) {
                this.isSubmitting = true;
                let newInstructionModel = Object.assign({}, this.instructionModel);
                newInstructionModel.limitPrice = numeral(instructionModel.limitPrice).value();
                newInstructionModel.quantity = numeral(instructionModel.quantity).value();

                post(
                    "/InternalExchanges/InstructionConfirmation",
                    newInstructionModel,
                    (res) => {
                        const response = res.data;
                        this.$router.push({
                            path: "/internalExchanges/confirmation",
                            query: {
                                instructionId: response.internalExchangeInstructionId,
                                positionUsageId: response.positionUsageId,
                                sharePlanParticipationId: response.sharePlanParticipationId,
                                shouldShowDeviationWarningMessage: response.shouldShowDeviationWarningMessage,
                                priceDeviationWarningPercentage: response.priceDeviationWarningPercentage
                            }
                        });
                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                    }
                );
            }
        }
    };
</script>
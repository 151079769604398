<template>
    <div>
        <div class="row">
            <div class="col-8 cookie-policy-detail__title">
                <span>{{ title }}</span>
            </div>
            <div class="col-4 text-end">
                <slot name="switch" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <span>{{ text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            text: String
        }
    }
</script>
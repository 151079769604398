var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", { attrs: { id: "cancel-order-form" } }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn secondaryButton",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v(_vm._s(_vm.dictionary.modal.cancelOrderNo))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn primaryButton",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("submit-completed")
              }
            }
          },
          [_vm._v(_vm._s(_vm.dictionary.modal.cancelOrderYes))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
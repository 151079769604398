var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "transaction-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "transaction-details-expansion-panel" },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "transaction-details-expansion-panel__header" },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "row transaction-details-header-text" },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.dictionary.rows.transactionsTblRowMobileHeader
                            ) +
                              ": " +
                              _vm._s(_vm.item.executionDate)
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.toggles.hideTransactionOriginColumn
                      ? _c(
                          "div",
                          { staticClass: "row transaction-details-child-text" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.dictionary.columns
                                    .transactionsTblColOrigin
                                ) +
                                  ": " +
                                  _vm._s(_vm.item.origin)
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row transaction-details-child-text" },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.dictionary.columns.transactionsTblColAmount
                            ) +
                              ": " +
                              _vm._s(_vm.item.amountFormatted)
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile" },
                [
                  _c("TransactionDetailsContent", {
                    attrs: {
                      toggles: _vm.toggles,
                      dictionary: _vm.dictionary,
                      item: _vm.item
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
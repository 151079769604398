var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message.text && _vm.message.text.length > 0
    ? _c("div", { class: ["email-message", _vm.legacyEmailCssClass] }, [
        _c("div", {
          staticClass: "rich-text-container",
          domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.message.text)) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
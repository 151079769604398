import ExercisesContainer from "./exercises-container.component.vue";
import Exercises from "./exercises.component.vue";
import ExercisesConfirmation from "./exercises-confirmation.component.vue";


export const exercisesRoutes =
{
    path: '/exercises',
    component: ExercisesContainer,
    children: [
        {
            path: '',
            component: Exercises
        }, {
            path: 'confirmation',
            component: ExercisesConfirmation
        },
        {
            path: '*',
            redirect: '/exercises'
        }
    ]
}

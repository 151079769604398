var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-tab",
    {
      attrs: {
        tabTitle: _vm.tabTitle,
        isActive: _vm.isActive,
        tabId: _vm.tabId
      }
    },
    [
      _vm.action !== _vm.$AccountActionType.ADD &&
      _vm.action !== _vm.$AccountActionType.EDIT
        ? [
            _c("h2", [_vm._v(_vm._s(_vm.widgetTitle))]),
            _vm._v(" "),
            _vm.accountType == _vm.$BankAccountType.SECURITY
              ? _c("div", {
                  staticClass: "page-description rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.financialDictionary
                          .beneficiaryPageDescriptionSecurity
                      )
                    )
                  }
                })
              : _vm.accountType == _vm.$BankAccountType.MONEY
              ? _c("div", {
                  staticClass: "page-description rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.financialDictionary.beneficiaryPageDescriptionMoney
                      )
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("FinancialSucceessAlert", {
              attrs: {
                showSuccess: _vm.showSuccess,
                successMessage: _vm.successMessage
              },
              on: { "alert-visible": _vm.updateAlert }
            }),
            _vm._v(" "),
            _vm.bankAccountData.length
              ? _vm._l(_vm.bankAccountData, function(bankAccount) {
                  return _c(
                    "FinancialCard",
                    {
                      key: bankAccount.id,
                      attrs: {
                        "bank-account": bankAccount,
                        "financial-dictionary": _vm.dictionary,
                        "account-type": _vm.accountType,
                        "beneficiary-boundaries-data":
                          _vm.beneficiaryBoundariesData
                      },
                      on: {
                        setDefaultAccount: _vm.setDefaultAccount,
                        removeAccount: _vm.removeAccount,
                        editAccount: _vm.editAccount
                      }
                    },
                    [
                      _c("FinancialBankAccount", {
                        attrs: {
                          "financial-dictionary": _vm.dictionary,
                          "bank-account": bankAccount,
                          "financial-toggles": _vm.financialToggles,
                          "account-type": _vm.accountType
                        }
                      })
                    ],
                    1
                  )
                })
              : !_vm.showSuccess
              ? [
                  _c("alert-component", { attrs: { variant: "info" } }, [
                    _vm._v(_vm._s(_vm.dictionary.noRecord))
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("FinancialAddAccountButton", {
              attrs: {
                dictionary: _vm.dictionary,
                "account-type": _vm.accountType,
                "beneficiary-boundaries-data": _vm.beneficiaryBoundariesData
              },
              on: {
                "add-account": function($event) {
                  return _vm.addAccount()
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.action === _vm.$AccountActionType.ADD
        ? _c("FinancialAddAccount", {
            attrs: {
              dictionary: _vm.dictionary,
              "account-type": _vm.accountType,
              "financial-dictionary": _vm.financialDictionary,
              "beneficiary-boundaries-data": _vm.beneficiaryBoundariesData,
              "financial-toggles": _vm.financialToggles
            },
            on: {
              "cancel-add-account": function($event) {
                return _vm.cancelAddAccount()
              },
              "add-account-completed": _vm.addAccountSubmitCompleted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.action === _vm.$AccountActionType.EDIT
        ? _c("FinancialEditAccount", {
            attrs: {
              dictionary: _vm.dictionary,
              bankAccount: _vm.bankAccount,
              "account-type": _vm.accountType,
              "financial-toggles": _vm.financialToggles,
              "financial-dictionary": _vm.financialDictionary,
              "beneficiary-boundaries-data": _vm.beneficiaryBoundariesData
            },
            on: {
              "cancel-edit-account": function($event) {
                return _vm.cancelEditAccount()
              },
              "edit-account-completed": _vm.editAccountSubmitCompleted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.action === _vm.$AccountActionType.DEFAULT
        ? _c("FinancialDefaultAccount", {
            attrs: {
              dictionary: _vm.dictionary,
              "financial-dictionary": _vm.financialDictionary,
              bankAccount: _vm.bankAccount,
              accountType: _vm.accountType,
              isModalVisible: _vm.isModalVisible,
              "beneficiary-boundaries-data": _vm.beneficiaryBoundariesData,
              "financial-toggles": _vm.financialToggles
            },
            on: {
              "submit-completed": _vm.defaultAccountSubmitCompleted,
              "close-modal": _vm.closeModal,
              "open-modal": _vm.openModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.action === _vm.$AccountActionType.REMOVE
        ? _c("FinancialRemoveAccount", {
            attrs: {
              dictionary: _vm.dictionary,
              "financial-dictionary": _vm.financialDictionary,
              bankAccount: _vm.bankAccount,
              accountType: _vm.accountType,
              isModalVisible: _vm.isModalVisible,
              "beneficiary-boundaries-data": _vm.beneficiaryBoundariesData,
              "financial-toggles": _vm.financialToggles
            },
            on: {
              "submit-completed": _vm.removeAccountSubmitCompleted,
              "close-modal": _vm.closeModal,
              "open-modal": _vm.openModal
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { row: !_vm.isMobile } }, [
    _c("div", { staticClass: "col disclaimers-image-container" }),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c(
          "div",
          { staticClass: "disclaimers-content-container" },
          [
            _c("BodyModalHeader", {
              attrs: {
                layout: _vm.layout,
                isAuthenticated: _vm.isAuthenticated,
                header: _vm.header,
                subHeader: _vm.subHeader,
                loginButton: _vm.loginButton
              }
            }),
            _vm._v(" "),
            _c("router-view", {
              attrs: { isAuthenticated: _vm.isAuthenticated },
              on: { "set-header": _vm.setHeader }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
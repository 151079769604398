var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accountHolderModel.holderAddress
    ? _c(
        "div",
        {
          staticClass: "add-account-step",
          attrs: { id: "account-holder-container" }
        },
        [
          _vm.currentStep <= 2
            ? _c(
                "h3",
                {
                  staticClass: "timeline-step-header",
                  attrs: { id: "step-" + _vm.currentStep }
                },
                [
                  _vm._v(
                    _vm._s(_vm.dictionary.step) +
                      " 2: " +
                      _vm._s(_vm.dictionary.subHeadingAccountHolder)
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "account-holder-body" },
            [
              _vm.currentStep > 2
                ? [
                    _c(
                      "FinancialAddAccountStepAccordion",
                      {
                        attrs: {
                          heading: _vm.dictionary.subHeadingAccountHolder,
                          "current-step": _vm.currentStep,
                          dictionary: _vm.dictionary,
                          showStep3: _vm.showStep3
                        }
                      },
                      [
                        _c(
                          "dl",
                          {
                            staticClass: "details-list account-holder-details"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "details-list-left" },
                              [
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value: _vm.accountHolderModel.holderName,
                                    label: _vm.dictionary.accountHolder.name
                                  }
                                }),
                                _vm._v(" "),
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value:
                                      _vm.accountHolderModel.holderAddress
                                        .addressProperty,
                                    label:
                                      _vm.dictionary.accountHolder.addressLine1
                                  }
                                }),
                                _vm._v(" "),
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value:
                                      _vm.accountHolderModel.holderAddress
                                        .postalCode,
                                    label:
                                      _vm.dictionary.accountHolder.postalCode
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "details-list-right" },
                              [
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value:
                                      _vm.accountHolderModel.holderAddress.city,
                                    label: _vm.dictionary.accountHolder.city
                                  }
                                }),
                                _vm._v(" "),
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value:
                                      _vm.accountHolderModel.holderAddress
                                        .state,
                                    label: _vm.dictionary.accountHolder.state
                                  }
                                }),
                                _vm._v(" "),
                                _c("BeneficiaryDataLabelRow", {
                                  attrs: {
                                    value: _vm.countryName,
                                    label: _vm.dictionary.accountHolder.country
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ]
                : _vm.currentStep === 2
                ? _c(
                    "div",
                    { staticClass: "timeline-step-details" },
                    [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm.messageInSecurityAccountHolderStep
                            ? _c(
                                "alert-component",
                                { attrs: { variant: "info" } },
                                [
                                  _c("div", {
                                    staticClass: "rich-text-container",
                                    attrs: {
                                      id: "messageInSecurityAccountHolderStep"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(
                                          _vm.messageInSecurityAccountHolderStep
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm.missingAccountHolderMessage
                            ? _c(
                                "alert-component",
                                { attrs: { variant: "error" } },
                                [
                                  _c("div", {
                                    staticClass: "rich-text-container",
                                    attrs: {
                                      id: "missingAccountHolderMessage"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(
                                          _vm.missingAccountHolderMessage
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "holderName",
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                label: _vm.dictionary.accountHolder.name,
                                validationsList:
                                  _vm.accountHolderValidations.holderName,
                                "is-mandatory": _vm.isNameAndCityMandatory
                              },
                              model: {
                                value: _vm.accountHolderModel.holderName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel,
                                    "holderName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "accountHolderModel.holderName"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "addressProperty",
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                label:
                                  _vm.dictionary.accountHolder.addressLine1,
                                validationsList:
                                  _vm.accountHolderValidations.addressProperty
                              },
                              model: {
                                value:
                                  _vm.accountHolderModel.holderAddress
                                    .addressProperty,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel.holderAddress,
                                    "addressProperty",
                                    $$v
                                  )
                                },
                                expression:
                                  "accountHolderModel.holderAddress.addressProperty"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "postalCode",
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                validationsList:
                                  _vm.accountHolderValidations.postalCode,
                                label: _vm.dictionary.accountHolder.postalCode
                              },
                              model: {
                                value:
                                  _vm.accountHolderModel.holderAddress
                                    .postalCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel.holderAddress,
                                    "postalCode",
                                    $$v
                                  )
                                },
                                expression:
                                  "accountHolderModel.holderAddress.postalCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "city",
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                label: _vm.dictionary.accountHolder.city,
                                validationsList:
                                  _vm.accountHolderValidations.city,
                                "is-mandatory": _vm.isNameAndCityMandatory
                              },
                              model: {
                                value:
                                  _vm.accountHolderModel.holderAddress.city,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel.holderAddress,
                                    "city",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "accountHolderModel.holderAddress.city"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "state",
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                label: _vm.dictionary.accountHolder.state,
                                validationsList:
                                  _vm.accountHolderValidations.state
                              },
                              model: {
                                value:
                                  _vm.accountHolderModel.holderAddress.state,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel.holderAddress,
                                    "state",
                                    $$v
                                  )
                                },
                                expression:
                                  "accountHolderModel.holderAddress.state"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("select-element", {
                              attrs: {
                                disabled: _vm.securityBankAccountsInOwnNameOnly,
                                label: _vm.dictionary.accountHolder.country,
                                options: _vm.countries,
                                name: "accountHolderCountry",
                                "is-mandatory": true
                              },
                              model: {
                                value:
                                  _vm.accountHolderModel.holderAddress
                                    .countryId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accountHolderModel.holderAddress,
                                    "countryId",
                                    $$v
                                  )
                                },
                                expression:
                                  "accountHolderModel.holderAddress.countryId"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("FinancialAddAccountMandatoryTooltip", {
                        attrs: { tooltipText: _vm.dictionary.mandatory }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-md-flex justify-content-md-between button-container"
                        },
                        [
                          _c("FinancialCancelAddAccount", {
                            attrs: { dictionary: _vm.dictionary },
                            on: {
                              "cancel-add-account": function($event) {
                                return _vm.cancelAddAccount()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn secondaryButton",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.previousStep()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.dictionary.btnPrevious) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn primaryButton",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.disableNextButton
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.nextStep()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.dictionary.btnNext) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "timeline-step-details mt-0" }, [
                _vm.currentStep >= 3 && !_vm.showStep3
                  ? _c(
                      "div",
                      { staticClass: "d-md-flex justify-content-md-between" },
                      [
                        _c("FinancialCancelAddAccount", {
                          attrs: { dictionary: _vm.dictionary },
                          on: {
                            "cancel-add-account": function($event) {
                              return _vm.cancelAddAccount()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn secondaryButton",
                              attrs: {
                                type: "button",
                                "aria-describedby": _vm.previousStepAccessibilityLabel(
                                  _vm.currentStep
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.previousStep()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.dictionary.btnPrevious) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn primaryButton",
                              attrs: {
                                type: "button",
                                "aria-describedby": _vm.nextStepAccessibilityLabel(
                                  _vm.currentStep,
                                  _vm.maxNumberOfSteps
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitData()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.dictionary.btnSubmit) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
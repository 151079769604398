var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portfolio-plan-header-values-container" }, [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("div", [
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "ul",
          { staticClass: "portfolio-plan-header-values" },
          _vm._l(_vm.values, function(value, index) {
            return _c("li", { key: _vm.getKey(index) }, [
              _c(
                "span",
                { staticClass: "portfolio-plan-header-value font-weight-bold" },
                [_vm._v(_vm._s(value.valueFormatted))]
              )
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import CookiePolicyContainer from "./cookie-policy-container.component.vue";

export const cookiePolicyRoutes = {
    path: '/cookiePolicy',
    name: 'cookie-policy',
    component: CookiePolicyContainer,
    children: [
        {
            path: '*',
            redirect: '/cookiePolicy'
        }
    ]
};
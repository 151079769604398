var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { attrs: { id: "mainContent" } }, [
    _c("div", { staticClass: "login-image-box" }),
    _vm._v(" "),
    _c("div", { staticClass: "login-box" }, [
      _c("div", [
        _c("div", [
          _c("h1", { staticClass: "text-center py-2" }, [
            _vm._v(_vm._s(_vm.dictionary.errorPageTitle))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-left" },
          [
            _c("alert-component", { attrs: { variant: "error" } }, [
              _vm._v(_vm._s(_vm.dictionary.internalServerFriendlyError))
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-block btn-primary mt-5",
                attrs: { role: "button", to: "/login" }
              },
              [_vm._v(_vm._s(_vm.dictionary.btnOk))]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "order-form-div" } }, [
    _vm.model
      ? _c(
          "form",
          {
            attrs: { id: "exercises-instruct-form" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit($event)
              }
            }
          },
          [
            _vm.dictionary.form.widgetTextPlaceOrder
              ? _c("div", { staticClass: "form-group" }, [
                  _c("span", {
                    staticClass: "primary-color rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(_vm.dictionary.form.widgetTextPlaceOrder)
                      )
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("alert-component", { attrs: { variant: "error" } }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("order-types-link", {
              attrs: {
                type: _vm.$OrderTypes.Exercises,
                dictionary: _vm.dictionary.form.orderTypes,
                positionViewModel: _vm.exercisesPositionModel,
                title: _vm.dictionary.form.orderType,
                name: "orderTypes"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.dictionary.widgets.exerciseDescription))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("exercises-data-label", {
                  attrs: {
                    label: _vm.dictionary.widgets.labelOption,
                    value: _vm.exercisesPositionModel.name
                  }
                }),
                _vm._v(" "),
                _c("exercises-data-label", {
                  attrs: {
                    label: _vm.dictionary.widgets.labelExercisePrice,
                    value: _vm.exercisesPositionModel.strikePriceFormatted,
                    tooltip: _vm.dictionary.tooltips.exercisePriceTooltip
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasDerivativeSecurity
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("exercises-data-label", {
                      attrs: {
                        label: _vm.dictionary.widgets.labelUnderlying,
                        value: _vm.derivativeSecurity.name
                      }
                    }),
                    _vm._v(" "),
                    _c("exercises-data-label", {
                      attrs: {
                        label: _vm.dictionary.widgets.labelSecurityPrice,
                        value:
                          _vm.derivativeSecurity.lastSecurityPriceFormatted,
                        tooltip: _vm.derivativeSecurityPriceTooltip,
                        hideSecurityPriceInformationTooltip:
                          _vm.toggles.hideSecurityPriceInformationTooltip
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("select-element", {
              attrs: {
                label: _vm.dictionary.form.scenarioName,
                options: _vm.scenarioTypes,
                name: "scenarioTypes"
              },
              on: {
                changed: function($event) {
                  return _vm.scenarioChanged()
                }
              },
              model: {
                value: _vm.model.exerciseScenarioId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "exerciseScenarioId", $$v)
                },
                expression: "model.exerciseScenarioId"
              }
            }),
            _vm._v(" "),
            _vm.hasRestrictedSecurityAccountSettings
              ? _c("alert-component", { attrs: { variant: "warning" } }, [
                  _c("div", {
                    staticClass: "rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.dictionary.form
                            .errorExistingAccountSettingsInvalidDisplay
                        )
                      )
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("select-element", {
              attrs: {
                label: _vm.dictionary.form.executionMoment,
                options: _vm.executionMomentTypes,
                validations: _vm.$v.model.executionMoment,
                name: "executionMomentTypes"
              },
              model: {
                value: _vm.model.executionMoment,
                callback: function($$v) {
                  _vm.$set(_vm.model, "executionMoment", $$v)
                },
                expression: "model.executionMoment"
              }
            }),
            _vm._v(" "),
            !!_vm.selectedExerciseScenario
              ? _c("ExercisesOrderQuantity", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    model: _vm.model,
                    quantityType:
                      _vm.selectedExerciseScenario.quantityInputType,
                    validations: _vm.$v.model
                  },
                  on: {
                    nrOrderedDerivatives: function($event) {
                      _vm.model.nrOrderedDerivatives = $event
                    },
                    quantityAll: function($event) {
                      _vm.model.quantityAll = $event
                    },
                    quantityAllAndSelectSelect: function($event) {
                      _vm.model.quantityAllAndSelectSelect = $event
                    },
                    quantitySelection: function($event) {
                      _vm.model.quantityAllAndSelectSelection = $event
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.hideLimitPrice
              ? _c("input-element", {
                  attrs: {
                    type: "text",
                    label: _vm.dictionary.form.limitPrice,
                    validationsList: _vm.limitPriceValidations,
                    name: "limitPrice"
                  },
                  model: {
                    value: _vm.model.limitPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "limitPrice", $$v)
                    },
                    expression: "model.limitPrice"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("ExercisesOrderScenarioTypeDetail", {
              attrs: {
                dictionary: _vm.dictionary,
                model: _vm.model,
                exerciseScenario: _vm.selectedExerciseScenario,
                name: "selectedExerciseScenario"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex-container-button-right mt-4" }, [
              _vm.hasRestrictedSecurityAccountSettings
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary mr-2",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dictionary.form.btnCancel) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "submit",
                    disabled: _vm.hasRestrictedSecurityAccountSettings
                  }
                },
                [_vm._v(_vm._s(_vm.dictionary.form.paceOrder))]
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.positions, function(position, index) {
      return _c(
        "div",
        {
          key: position + "-" + index,
          staticClass: "orders-listitem-container"
        },
        [
          _c("dt", [_vm._v(_vm._s(!index ? _vm.label : ""))]),
          _vm._v(" "),
          _c("dd", { class: { "pb-2": !!index } }, [_vm._v(_vm._s(position))])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
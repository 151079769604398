<template>
  <div class="financial-card-container">
    <div class="financial-card-header">
      <div>
        <h3><small>{{financialCardHeaderLabel}}</small></h3>
      </div>
      <div>
          <div v-if="mayAddOrEditAccount && !isMobile" id="linksContainer">
            <a href="javascript:void(0)" v-if="!bankAccount.isDefault"  @click="setDefaultAccount()" role="button">{{financialDictionary.linkSetDefaultAccount}}</a>
            <a href="javascript:void(0)" @click="editAccount()"><i class="far fa-edit" aria-hidden="true"></i>{{financialDictionary.linkEditAccount}}</a>
            <a href="javascript:void(0)" v-if="!bankAccount.isInUse" @click="removeAccount()" ><i class="far fa-trash" aria-hidden="true" ></i>{{financialDictionary.removeAccount}}</a>
          </div>
      </div>
    </div>
    <div class="financial-card-body">
      <slot></slot>
      <div v-if="mayAddOrEditAccount && isMobile" class="d-md-flex mt-2" id="buttonsContainer">
          <button  v-if="!bankAccount.isDefault" @click="setDefaultAccount()" class="btn secondaryButton ml-auto mb-2" type="button"  >{{financialDictionary.linkSetDefaultAccount}}</button>
          <button  @click="editAccount()" class="btn secondaryButton ml-auto mb-2"  type="button"  ><i class="far fa-edit" aria-hidden="true"></i> {{financialDictionary.linkEditAccount}}</button>               
          <button v-if="!bankAccount.isInUse" @click="removeAccount()" class="btn secondaryButton ml-auto mb-0" type="button"  ><i class="far fa-trash" aria-hidden="true" ></i> {{financialDictionary.removeAccount}}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bankAccount: Object,
    financialDictionary: Object,
    accountType: String,
    beneficiaryBoundariesData: Object,  
  },
  data(){
    return {isMobile : false }
  },
  methods: {
    setDefaultAccount() {
      this.$emit("setDefaultAccount", { bankAccount: this.bankAccount, action: this.$AccountActionType.DEFAULT});
    },
    removeAccount() {
      this.$emit("removeAccount", { bankAccount: this.bankAccount, action: this.$AccountActionType.REMOVE});
    },
    editAccount() {
      this.$emit("editAccount", { bankAccount: this.bankAccount, action: this.$AccountActionType.EDIT });
    },
     handleResize() {
        this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
    },
  },
  computed: {
    mayAddOrEditAccount(){
      return (this.beneficiaryBoundariesData.mayEditSecuritiesBankAccount && this.accountType === this.$BankAccountType.SECURITY) ||
        (this.beneficiaryBoundariesData.mayEditMoneyBankAccount && this.accountType === this.$BankAccountType.MONEY)
    },
    financialCardHeaderLabel() {
        return this.bankAccount.isDefault ? this.financialDictionary.defaultAccount : this.financialDictionary.accountNotInUse
    }
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasItems && !_vm.isMobile
        ? _vm._l(_vm.exerciseDeliveries, function(exerciseDelivery, index) {
            return _c(
              "div",
              { key: index, staticClass: "orders-listitem-container" },
              [
                _c(
                  "dt",
                  [
                    index == 0
                      ? [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.label) +
                              "\n              "
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(
                    _vm._s(exerciseDelivery.NrSharesToDeliverFormatted) +
                      " X " +
                      _vm._s(exerciseDelivery.SecurityIdentificationDescription)
                  )
                ])
              ]
            )
          })
        : _vm.hasItems && _vm.isMobile
        ? _vm._l(_vm.exerciseDeliveries, function(exerciseDelivery, index) {
            return _c(
              "div",
              { key: index },
              [
                index == 0
                  ? _c("div", { staticClass: "orders-row-flex" }, [
                      _c("p", { staticClass: "orders-row__label" }, [
                        _vm._v(_vm._s(_vm.label))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "orders-row-flex" }, [
                  _c(
                    "p",
                    {
                      class: [
                        "orders-row__value",
                        { "text-right": _vm.textRight }
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(exerciseDelivery.NrSharesToDeliverFormatted) +
                          " X " +
                          _vm._s(
                            exerciseDelivery.SecurityIdentificationDescription
                          )
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("v-divider", { staticClass: "orders-row-divider" })
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
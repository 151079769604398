var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _c("div", { staticClass: "column-icon col-sm-auto d-none d-sm-block" }, [
      !!_vm.stackContent
        ? _c("div", { attrs: { "stack-content": _vm.stackContent } }, [
            _c("span", { staticClass: "fa-stack fa-1x" }, [
              _c("i", {
                class: "far " + _vm.stackFontName + " fa-stack-2x fa-shadow",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass:
                  "fa-stack-1x fa-inverse text-bold rich-text-container",
                domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.stackContent)) }
              })
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column-title col col-sm-3" }, [
      _c("p", { staticClass: "primary-label" }, [_vm._v(_vm._s(_vm.label))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column-expression col d-none d-sm-block text-right" },
      [
        _c("p", { staticClass: "primary-color" }, [
          _vm._v(_vm._s(_vm.expression))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "column-result col  text-right" }, [
      _c("p", { class: _vm.resultClass }, [_vm._v(_vm._s(_vm.result))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
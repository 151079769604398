export const formatAnswerType = (question, dictionary, QuestionType) => {
    if (question.questionType == QuestionType.StandardChoices) {
        if (!question.givenAnswer) {
            return '';
        }

        switch (question.givenAnswer.toString()) {
            case 'qqaYes':
                return dictionary.labels.yesAnswer;
            case 'qqaNo':
                return dictionary.labels.noAnswer;
            case 'qqaDontKnow':
                return dictionary.labels.dontKnowAnswer;
            default:
                return '';
        }
    }
    else if (question.questionType == QuestionType.DefinedChoices) {
        const choice = question.choices.find(c => c.questionChoiceId == question.givenChoiceId);
        return choice?.choiceText
    }
    else if (question.questionType == QuestionType.Open) {
        return question.givenOpenAnswer
    }
}

export const validateAnswer = (givenAnswer, question, correctAnswerMode, canEdit, dictionary, QuestionType) => {
    if (canEdit && correctAnswerMode) {
        if (question.questionType == QuestionType.StandardChoices) {
            if (question.correctAnswer !== null && givenAnswer !== question.correctAnswer) {
                return dictionary.labels.incorrectAnswer;
            }
        }

        if (question.questionType == QuestionType.DefinedChoices) {
            const correctChoice = question.choices.find(c => c.questionChoiceId == givenAnswer && c.isCorrectAnswer);
            if (correctChoice == null) {
                return dictionary.labels.incorrectAnswer;
            }
        }
    }

    return "";
}

export const questionnaireFilters = {
    filters: {
        formatAnswerType: formatAnswerType,
        validateAnswer: validateAnswer
    }
}
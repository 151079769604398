import SubscriptionsContainer from "./subscriptions-container.component.vue";
import Subscriptions from "./subscriptions.component.vue";
import SubscribePage from "./Shared/subscribe-page.component.vue";
import SubscriptionConfirmation from "./Shared/subscription-confirmation.component.vue";
import SubscriptionConfirmationSuccess from "./Shared/subscription-confirmation-success.component.vue"

export const subscriptionsRoutes = {
    path: '/subscriptions',
    component: SubscriptionsContainer,
    children: [
        {
            path: '',
            component: Subscriptions
        },
        {
            path: 'subscribe',
            component: SubscribePage
        },
        {
            path: 'confirmation',
            name: 'subscriptions-confirmation',
            component: SubscriptionConfirmation,
            props: true
        },
        {
            path: 'confirmation/success',
            name: 'subscriptions-confirmation-success',
            component: SubscriptionConfirmationSuccess,
            props: true
        },
        {
            path: '*',
            redirect: '/subscriptions'
        }
    ],
};
<template>
    <div>
        <div class="orders-row-flex">
            <p class="orders-row__label">{{ label }}</p>
        </div>
        <div class="orders-row-flex">
            <p :class="['orders-row__value', { 'text-right' : textRight }]">{{ replaceNullOrEmpty(value) }}</p>
        </div>
        <v-divider class="orders-row-divider" ></v-divider>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        value: String | Number,
        textRight: Boolean
    }
}
</script>
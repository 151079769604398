<template>
        <div v-if="dictionary">
            <div class="title-block">
                <h1>{{dictionary.page.titleExecuteContribution}}</h1>
            </div>
            <component-spinner v-if="isLoading" :spin="isLoading"></component-spinner>
            <div v-if="contributionsToExecute" class="portlets-wrapper">
                <div class="portlet-container">
                    <div class="row m-0">
                        <portlet-layout column-class="col-lg-6 col-xl-6" :title="dictionary.widget.titleExecuteContribution">
                            <template slot="body">
                                <alert-component v-if="!contributionsToExecute.canReserve" variant="error">{{ dictionary.table.labelInsufficientFund }}</alert-component>
                                <alert-component v-if="error" variant="error">{{ error }}</alert-component>
                                <loading-spinner :active="isSubmitting"></loading-spinner>
                                <SubscriptionConfirmationDetails :dictionary="dictionary" :participationSubscription="contributionsToExecute.participationSubscription" />
                                <div class="button-container d-md-flex justify-content-end mt-3">
                                    <div>
                                        <button class="btn secondaryButton" @click="back()">{{ dictionary.widget.btnGoBack }}</button>
                                    </div>
                                    <div>
                                        <button v-if="contributionsToExecute.canReserve" class="btn primaryButton" @click="confirm()">{{ dictionary.widget.btnConfirm }}</button>
                                    </div>
                                </div>
                            </template>
                        </portlet-layout>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

    import SubscriptionConfirmationDetails from "/Features/Subscriptions/Assets/Vue/Shared/subscription-confirmation-details.component.vue";
    import { get, post } from "api";

    export default {
        components: {
            SubscriptionConfirmationDetails,
        },
        props: {
            dictionary: Object
        },
        data() {
            return {
                contributionsToExecute: null,
                isLoading: true,
                isSubmitting: false,
                error: null
            }
        },
        beforeRouteEnter(to, from, next) {
            const previousPathName = from.path ?? "";
            if (!previousPathName.includes("offerings")) {
                location.replace(`/`);
            } else {
                next();
            } 
        },
        created() {

        },
        beforeCreate() {

            let query = this.$route.query;

            get(`/Offerings/GetContributionsToExecute?participationSubscriptionId=${query.participationSubscriptionId}`,
                (response) => {
                    this.contributionsToExecute = response.data;
                },
                this,
                () => { this.isLoading = false }
            );

        },
        methods: {
            confirm() {
                this.isSubmitting = true;
                this.error = null;

                post('/Offerings/ConfirmContribution',
                    this.contributionsToExecute,
                    (response) => {

                        this.$router.push({ path: '/offerings', hash: '#history' });

                    },
                    this,
                    () => {
                        this.isSubmitting = false;
                    },
                    (err) => {
                        this.error = err.response.data;
                        this.isSubmitting = false;
                    });
            },
            back() {
                this.$router.push({ path: '/offerings' });
            }
        }
    };
</script>
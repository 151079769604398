<template>
    <div v-if="value" class="form-subscriptions-scenario">

        <p class="font-weight-bold scenarioName-title" id="scenario-name-title" tabindex="0">
            {{subscriptionScenario.scenarioName}}
        </p>

        <p v-if="subscriptionScenario.noticeTitle" class="font-weight-bold" tabindex="0">
            {{subscriptionScenario.noticeTitle}}
        </p>

        <p v-if="subscriptionScenario.noticeText" tabindex="0">
            {{subscriptionScenario.noticeText}}
        </p>
        
        <div role="table" aria-describedby="scenario-name-title" >
            <div role="rowgroup">
                <div class="scenario-subscriptions-header hidden-xs-down" role="row">
                    <div class="underlying-usage-name">
                        <p role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.securityName}}</p>
                    </div>

                    <div v-if="isSubscriptions" class="grant-amount">
                        <p role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.granted}}</p>
                    </div>

                    <div v-if="isSubscriptions" class="accepted-amount">
                        <p role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.subscribed}}</p>
                    </div>

                    <div v-if="!isSubscriptions && hasEmissionPrice" class="emission-price">
                        <p class="text-center" role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.emissionPrice}}</p>
                    </div>

                    <div v-if="hasTaxableAmount" class="taxable-amount">
                        <p role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.taxableAmount}}</p>
                    </div>
                    
                    <div class="subscribe-amount">
                        <p v-if="!isAcceptCollumn" class="scenario-title" role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.presentationModeSubscribe}}</p>
                        <p v-else class="scenario-title" role="columnheader" aria-sort="none" tabindex="0">{{dictionary.table.presentationModeAccept}}</p>
                    </div>
                </div>
            </div>

            <div role="rowgroup">
                <div v-for="offer in value.offers" :key="offer.participationSubscriptionOfferId">
                    <SubscriptionScenarioOffer :dictionary="dictionary" v-model="offer.scenarioOffer" :hasTaxableAmount="hasTaxableAmount" :hasEmissionPrice="hasEmissionPrice" :isSubscriptions="isSubscriptions" :validationLists="validationLists"
                    :isAcceptCollumn="isAcceptCollumn" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import SubscriptionScenarioOffer from "./subscription-scenario-offer.component.vue";

    export default {
        components: {
            SubscriptionScenarioOffer
        },
        props: {
            dictionary: Object,
            value: Object,
            validationLists: Object,
            isSubscriptions: Boolean,
            isAcceptCollumn: Boolean
        },
        computed: {
            subscriptionScenario(){
                return this.value;
            },
            hasTaxableAmount() {
                return this.subscriptionScenario.offers.some(x => x.scenarioOffer.offer.taxableAmount > 0);
            },
            hasEmissionPrice() {
                return this.subscriptionScenario.offers.some(x => x.scenarioOffer.offer.hasEmissionPrice);
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bank-account-body" },
    [
      _vm.currentStep > 3
        ? [
            _c(
              "FinancialAddAccountStepAccordion",
              {
                attrs: {
                  heading: _vm.dictionary.subCustodianSubHeading,
                  isCustodian: true,
                  "current-step": _vm.currentStep,
                  dictionary: _vm.dictionary,
                  showStep3: true
                }
              },
              [
                _c("dl", { staticClass: "details-list bank-account-details" }, [
                  _c(
                    "div",
                    { staticClass: "details-list-left" },
                    [
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.subCustodianModel.subCustodianName,
                          label: _vm.dictionary.name
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.subCustodianModel.subCustodianAccountName,
                          label: _vm.dictionary.accountName
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value:
                            _vm.subCustodianModel.subCustodianAccountNumber,
                          label: _vm.dictionary.accountNumber
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details-list-right" },
                    [
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value:
                            _vm.subCustodianModel.subCustodianDepotIdentifier,
                          label: _vm.dictionary.depotIdentifier
                        }
                      }),
                      _vm._v(" "),
                      _c("BeneficiaryDataLabelRow", {
                        attrs: {
                          value: _vm.subCustodianModel.subCustodianReference,
                          label: _vm.dictionary.reference
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        : _vm.currentStep >= 3
        ? _c(
            "FinancialAddAccountStepAccordion",
            {
              attrs: {
                showStep3: true,
                heading: _vm.dictionary.subCustodianSubHeading,
                isCustodian: true
              }
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.dictionary.subCustodianHeaderDescription))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "sub-custodian-name",
                        label: _vm.dictionary.name
                      },
                      model: {
                        value: _vm.subCustodianModel.subCustodianName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.subCustodianModel,
                            "subCustodianName",
                            $$v
                          )
                        },
                        expression: "subCustodianModel.subCustodianName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "subcustodian-account-name",
                        label: _vm.dictionary.accountName
                      },
                      model: {
                        value: _vm.subCustodianModel.subCustodianAccountName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.subCustodianModel,
                            "subCustodianAccountName",
                            $$v
                          )
                        },
                        expression: "subCustodianModel.subCustodianAccountName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "sub-custodian-account-number",
                        label: _vm.dictionary.accountNumber
                      },
                      model: {
                        value: _vm.subCustodianModel.subCustodianAccountNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.subCustodianModel,
                            "subCustodianAccountNumber",
                            $$v
                          )
                        },
                        expression:
                          "subCustodianModel.subCustodianAccountNumber"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "sub-custodian-depot-identifier",
                        label: _vm.dictionary.depotIdentifier
                      },
                      model: {
                        value:
                          _vm.subCustodianModel.subCustodianDepotIdentifier,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.subCustodianModel,
                            "subCustodianDepotIdentifier",
                            $$v
                          )
                        },
                        expression:
                          "subCustodianModel.subCustodianDepotIdentifier"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-4" },
                  [
                    _c("input-element", {
                      attrs: {
                        name: "sub-custodian-reference",
                        label: _vm.dictionary.reference
                      },
                      model: {
                        value: _vm.subCustodianModel.subCustodianReference,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.subCustodianModel,
                            "subCustodianReference",
                            $$v
                          )
                        },
                        expression: "subCustodianModel.subCustodianReference"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("FinancialAddAccountMandatoryTooltip", {
                attrs: { tooltipText: _vm.dictionary.mandatory }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import TransactionsContainer from "./transactions-container.component.vue";

export const transactionsRoutes = {
    path: '/transactions',
    alias: '/transactions/index',
    name: 'transactions',
    component: TransactionsContainer,
    children: [
        {
            path: '*',
            redirect: '/transactions'
        }
    ]
}
<template>
    <portlet-layout v-if="!!dashboardData" class="w-100" :title="welcomeText">
        <template slot="body">
            <div v-html="$sanitize(dictionary.widgets.welcomeText)" class="rich-text-container welcome"></div>                    
        </template>
    </portlet-layout>
</template>

<script>
export default {
    props: {
        dashboardData: Object,
        dictionary: Object,
        toggles: Object
    },
    computed:{
        welcomeText() {
            const beneficiaryData = this.dashboardData.beneficiary;
            let fullName = "";
            if(beneficiaryData && (beneficiaryData.beneficiaryBoundaries.mayViewPersonalData == false) ||
               (beneficiaryData.beneficiaryBoundaries.mayViewPersonalData == true &&
               beneficiaryData.beneficiaryBoundaries.mayViewBeneficiaryDetails == false))
               {
                   fullName = "";
               }
               else
               {
                   fullName = beneficiaryData.fullName;
               }

            return this.dictionary.widgets.welcomeTitle.replace("{0}",  fullName);
        }
    }  
    
}
</script>
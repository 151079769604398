<template>
<div>
    <div class="title-block">
        <h1 v-if="pagesDictionary">{{ pagesDictionary.transactions.title }}</h1>
    </div>

    <div class="portlets-wrapper">
        <component-spinner :spin="isLoading"></component-spinner>
        <Transactions v-if="!isLoading" :dictionary="dictionary" :toggles="toggles" :transactions-options="transactionsOptions" :datatables-dictionary="datatablesDictionary" />
    </div>
</div>
</template>

<script>
import { getAll } from "api";
import Transactions from './transactions.component.vue';

export default {
    components: {
        Transactions
    },
    props: {
        pagesDictionary: Object
    },
    data() {
        return {
            dictionary: null,
            toggles: {},
            transactionsOptions: {},
            datatablesDictionary: {},
            isLoading: true
        }
    },
    beforeCreate() {
        getAll([
            "/Transactions/Dictionary",
            "/Transactions/Toggles",
            "/Transactions/Options",
            "/Transactions/DatatablesDictionary"
        ], (responses) => {
            this.dictionary = responses[0].data;
            this.toggles = responses[1].data;
            this.transactionsOptions = responses[2].data;
            this.datatablesDictionary = responses[3].data;

            this.$setPageTitle(this.dictionary.page.title);
            this.isLoading = false;
        }, this);
    }
}
</script>
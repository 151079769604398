var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mb-lg-3",
          attrs: { id: "loginForm" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login($event)
            }
          }
        },
        [
          _c(
            "fieldset",
            [
              _c("legend", { staticClass: "sr-only" }, [_vm._v("Login Form")]),
              _vm._v(" "),
              _c(
                "input-element",
                {
                  attrs: {
                    type: "text",
                    label: _vm.dictionary.page.userName,
                    "is-mandatory": true,
                    name: "userName",
                    validationsList: _vm.userNameValidations
                  },
                  model: {
                    value: _vm.model.userName,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "userName", $$v)
                    },
                    expression: "model.userName"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "link" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "float-right",
                          attrs: { to: "/login/forgottenUsername" }
                        },
                        [
                          _c("span", { staticClass: "underline" }, [
                            _vm._v(_vm._s(_vm.dictionary.page.forgotUserName))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "input-element",
                {
                  attrs: {
                    type: "password",
                    label: _vm.dictionary.page.password,
                    "is-mandatory": true,
                    name: "password",
                    validationsList: _vm.passwordValidations,
                    togglePassword: true
                  },
                  model: {
                    value: _vm.model.password,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "password", $$v)
                    },
                    expression: "model.password"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "link" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "float-right",
                          attrs: { to: "/login/forgottenPassword" }
                        },
                        [
                          _c("span", { staticClass: "underline" }, [
                            _vm._v(_vm._s(_vm.dictionary.page.forgotPassword))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v("* " + _vm._s(_vm.dictionary.page.loginLabelMandatory))
              ]),
              _vm._v(" "),
              _vm.toggles.loginUseCaptcha
                ? _c("LoginGoogleRecaptcha")
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-block mt-3 primaryButton login-button",
                  attrs: { type: "submit" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.dictionary.page.btnLogin) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.isMobile
                ? _c("div", { staticClass: "d-flex justify-content-around" }, [
                    _c(
                      "div",
                      { staticClass: "help-link-login" },
                      [
                        _c("HeaderLoginHelp", {
                          attrs: { label: _vm.dictionary.page.loginHelp }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
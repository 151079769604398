<template>
    <router-view :pagesDictionary="pagesDictionary"></router-view>
</template>

<script>

    export default {
        props: {
            pagesDictionary: Object
        } 
    };
</script>

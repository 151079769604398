var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "dtr-control p-2" }, [
      _c("div", { staticClass: "dtr-control-row" }, [
        _c("div", { staticClass: "d-flex mr-3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "document-details-container" }, [
            _c("p", [_c("strong", [_vm._v(_vm._s(_vm.item.documentName))])]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.item.documentDescription))])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { role: "button" },
              on: {
                click: function($event) {
                  return _vm.redirect(_vm.item)
                }
              }
            },
            [_vm._v(_vm._s(_vm.getLinkText(_vm.item)))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("div", { staticClass: "document-icon-container" }, [
        _c("i", {
          staticClass: "far fa-file fa-2x",
          attrs: { "aria-hidden": "true" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
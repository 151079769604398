var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "LoginTemplate",
        [
          !_vm.isPostSuccessful
            ? [
                _c("template", { slot: "title" }, [
                  _c("h1", { staticClass: "sr-only welcome-text" }, [
                    _vm._v(_vm._s(_vm.dictionary.recovery.forgotPasswordTitle))
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "text-center py-2 welcome-text",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.dictionary.recovery.forgotPasswordTitle)
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr")
                ]),
                _vm._v(" "),
                _c("template", { slot: "body" }, [
                  _c(
                    "div",
                    { staticClass: "login-form" },
                    [
                      _c("div", { staticClass: "forgot-password-message" }, [
                        _c("p", { staticClass: "text-left pb-2" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.recovery.forgotPasswordText)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.error
                        ? _c(
                            "alert-component",
                            { attrs: { variant: "error" } },
                            [_vm._v(_vm._s(_vm.error))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "mb-lg-3",
                          attrs: { id: "loginForm" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.recoverPassword($event)
                            }
                          }
                        },
                        [
                          _c("input-element", {
                            attrs: {
                              type: "text",
                              "is-mandatory": true,
                              label: _vm.dictionary.page.userName,
                              name: "userName",
                              validationsList: _vm.userNameValidations
                            },
                            model: {
                              value: _vm.forgottenPasswordViewModel.userName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.forgottenPasswordViewModel,
                                  "userName",
                                  $$v
                                )
                              },
                              expression: "forgottenPasswordViewModel.userName"
                            }
                          }),
                          _vm._v(" "),
                          _vm.forgottenPasswordData.attributeType ==
                          "pvaDateOfBirth"
                            ? _c("datepicker-element", {
                                staticClass:
                                  "password-recovery-form-date-picker",
                                attrs: {
                                  id: "personal-date-of-birth",
                                  label: _vm.dictionary.labels.dateOfBirth,
                                  validationsList: _vm.dateOfBirthValidations,
                                  "is-mandatory": true,
                                  yearRange: "-150:+0"
                                },
                                model: {
                                  value: _vm.forgottenPasswordViewModel.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forgottenPasswordViewModel,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "forgottenPasswordViewModel.value"
                                }
                              })
                            : _c("input-element", {
                                attrs: {
                                  type: "text",
                                  id: "text-input-forgotten-password",
                                  label:
                                    _vm.forgottenPasswordData.attributeLabel,
                                  "is-mandatory": true,
                                  validationsList: _vm.textInputValidation
                                },
                                model: {
                                  value: _vm.forgottenPasswordViewModel.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forgottenPasswordViewModel,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "forgottenPasswordViewModel.value"
                                }
                              }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "* " +
                                _vm._s(_vm.dictionary.page.loginLabelMandatory)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-md-flex justify-content-md-between button-container"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn mt-3 secondaryButton login-button",
                                  attrs: { type: "button" },
                                  on: { click: _vm.back }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.commonDictionary.btnGoBack) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn mt-3 primaryButton login-button",
                                  attrs: { type: "button" },
                                  on: { click: _vm.recoverPassword }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.commonDictionary.btnContinue) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            : [
                _c(
                  "template",
                  { slot: "body" },
                  [
                    _c("PasswordRecoverySuccessful", {
                      attrs: {
                        dictionary: _vm.dictionary,
                        "common-dictionary": _vm.commonDictionary
                      }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
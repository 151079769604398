var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-name-label" }, [
    _vm.isLink
      ? _c(
          "a",
          {
            attrs: { href: "javascript:void(0);" },
            on: {
              click: function($event) {
                return _vm.$emit("action")
              }
            }
          },
          [_vm._v(_vm._s(_vm.replaceNullOrEmpty(_vm.name)))]
        )
      : _c("div", [_vm._v(_vm._s(_vm.replaceNullOrEmpty(_vm.name)))]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.replaceNullOrEmpty(_vm.planName)))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
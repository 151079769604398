var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.header || _vm.subHeader || _vm.loginButton
    ? _c("div", { staticClass: "card-header-title w-100 flex flex-row" }, [
        _c("div", { staticClass: "flex flex-1" }, [
          _vm.header
            ? _c("h1", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.header))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subHeader
            ? _c("p", { staticClass: "card-subtitle" }, [
                _vm._v(_vm._s(_vm.subHeader))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.isAuthenticated && _vm.loginButton
          ? _c("div", { staticClass: "align-self-center pb-2" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-xl",
                  attrs: {
                    href: _vm.$sanitize(_vm.layout.loginEndpoint),
                    type: "submit",
                    title: _vm.layout.dictionary.login
                  }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.layout.dictionary.login))])
                ]
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "nav-icon" }, [
      _c("i", {
        staticClass: "fas fa-sign-in-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <fieldset v-if="currentQuestion" >
        <legend class="rich-text-container" v-html="$sanitize(currentQuestion.questionDescription)"></legend>
        <template v-if="currentQuestion.questionType == $QuestionType.StandardChoices">
            <label class="question-container__label">
                <input @change="changeAnswer" type="radio" id="qqaYesAnswer" v-model="currentQuestion.givenAnswer" value="qqaYes">
              <span class="question-container__text">{{dictionary.labels.yesAnswer}}</span>
          </label>
          <label class="question-container__label">
              <input @change="changeAnswer" type="radio" id="qqaNoAnswer" v-model="currentQuestion.givenAnswer" value="qqaNo">
              <span class="question-container__text">{{dictionary.labels.noAnswer}}</span>
          </label>
          <label class="question-container__label">
              <input @change="changeAnswer" type="radio" id="qqaDontKnow" v-model="currentQuestion.givenAnswer" value="qqaDontKnow">
              <span class="question-container__text">{{dictionary.labels.dontKnowAnswer}}</span>
          </label>
        </template>
        <template v-else-if="currentQuestion.questionType == $QuestionType.DefinedChoices">
            <template v-if="currentQuestion.choices.length == 0">
                <alert-component variant="error">{{dictionary.labels.noChoiceAvailable}}</alert-component>
            </template>
            <template v-else>
                <label class="question-container__label" v-for="choice in currentQuestion.choices" :key="choice.questionChoiceId">
                    <input @change="changeAnswer" type="radio" :name="currentQuestion.questionId" :id="choice.questionChoiceId" :value="choice.questionChoiceId" v-model="currentQuestion.givenChoiceId">
                    <span class="question-container__text">{{choice.choiceText}}</span>
                </label>
            </template>    
        </template>
        <template v-else>
          <div>            
              <textarea-element  name="givenOpenAnswer" id="givenOpenAnswer" :row="3" :cols="30" :maxlength="250" v-model.trim="currentQuestion.givenOpenAnswer" :validationsList="questionValidations(questionIndex)" />
          </div>
        </template>
    </fieldset>
</template>

<script>

export default {

  props: {
    currentQuestion: Object,
    questionIndex: Number,
    showCorrectAnswer: Boolean,
    dictionary: Object,
    v:Object
  },
  methods: {
    changeAnswer(){
        this.$emit("change-answer");
    },
    questionValidations(index) {
        this.$emit("change-answer");
        return [ 
            {
                id: 1,
                error: this.v.currentQuestionnaire.questions.$each[index].givenOpenAnswer.$error,
                type: this.v.currentQuestionnaire.questions.$each[index].givenOpenAnswer.required,
                message: this.dictionary.labels.answerRequired
            },
            {
                id: 2,
                error: this.v.currentQuestionnaire.questions.$each[index].givenOpenAnswer.$error,
                type: this.v.currentQuestionnaire.questions.$each[index].givenOpenAnswer.isValidAnswer,
                message: this.dictionary.labels.invalidAnswerFormat
            }                   
        ]
     }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "hidden" },
      domProps: { value: _vm.model.positionUsageId }
    }),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "hidden" },
      domProps: { value: _vm.model.sharePlanParticipationId }
    }),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "hidden" },
      domProps: { value: _vm.model.exitInstructionBoundaries.transferMinimum }
    }),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "hidden" },
      domProps: { value: _vm.model.exitInstructionBoundaries.transferModulo }
    }),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control",
      attrs: { type: "hidden" },
      domProps: { value: _vm.model.available }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
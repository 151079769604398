var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "employment-detail-container" }, [
    _c(
      "div",
      { staticClass: "employment-detail-category" },
      [
        _c("div", { staticClass: "employment-detail-category-header" }, [
          _c("i", { staticClass: "far fa-city" }),
          _vm._v(" "),
          _c("h4", [_vm._v(" " + _vm._s(_vm.dictionary.company))])
        ]),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.company,
            label: _vm.dictionary.company
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: { value: _vm.currentEmployment.hr, label: _vm.dictionary.hr }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.subsidiary,
            label: _vm.dictionary.subsidiary
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.registrationNumber,
            label: _vm.dictionary.registrationNumber
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "employment-detail-category" },
      [
        _c("div", { staticClass: "employment-detail-category-header" }, [
          _c("i", { staticClass: "far fa-user" }),
          _vm._v(" "),
          _c("h4", [_vm._v(" " + _vm._s(_vm.dictionary.role) + " ")])
        ]),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.position,
            label: _vm.dictionary.position
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.division,
            label: _vm.dictionary.division
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.appointment,
            label: _vm.dictionary.appointment
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "employment-detail-category" },
      [
        _c("div", { staticClass: "employment-detail-category-header" }, [
          _c("i", { staticClass: "far fa-map-marker-alt" }),
          _vm._v(" "),
          _c("h4", [_vm._v(" " + _vm._s(_vm.dictionary.location))])
        ]),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm.currentEmployment.location,
            label: _vm.dictionary.location
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm._f("toDate")(_vm.currentEmployment.startDate),
            label: _vm.dictionary.startDate
          }
        }),
        _vm._v(" "),
        _c("beneficiary-data-label", {
          attrs: {
            value: _vm._f("toDate")(_vm.currentEmployment.endDate),
            label: _vm.dictionary.endDate
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dividend-details-content" },
    [
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.distributionDate,
          value: _vm.dividend.distributionDate
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.securityName,
          value: _vm.dividend.securityName
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.dividendType,
          value: _vm.dividendTypeTitle
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.state,
          value: _vm.dividend.state.description
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.nrShares,
          value: _vm.dividend.nrSharesFormatted
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.grossAmount,
          value: _vm.dividend.grossAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.tax,
          value: _vm.dividend.taxAmountFormatted
        }
      }),
      _vm._v(" "),
      _c("DividendDataLabelRow", {
        attrs: {
          label: _vm.dictionary.table.netAmount,
          value: _vm.dividend.netAmountFormatted
        }
      }),
      _vm._v(" "),
      !_vm.toggles.hideCashDividendColumn
        ? _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.amountToReceive,
              value: _vm.dividend.amountToReceiveFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.toggles.hideStockDividendColumn
        ? _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.netQuantity,
              value: _vm.dividend.netQuantityFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showQuantityPerShare
        ? _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.quantityPerShare,
              value: _vm.quantityPerShare
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAmountPerShare
        ? _c("DividendDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.amountPerShare,
              value: _vm.amountPerShare
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "beneficiary-details" },
      [
        _vm.showToggleSMS
          ? _c("mfa-toggle", {
              attrs: {
                label: _vm.dictionary.beneficiaryLabelMFASMS,
                toggleValue: _vm.toggleValueSms,
                errors: _vm.smsErrors,
                isDisabled: _vm.isSmsDisabled
              },
              on: { "on-switch": _vm.switchToggleSms }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showToggleTotp
          ? _c("mfa-toggle", {
              attrs: {
                label: _vm.dictionary.beneficiaryLabelMFATOTP,
                toggleValue: _vm.toggleValueTotp,
                errors: _vm.totpErrors,
                isDisabled: _vm.isTotpDisabled
              },
              on: { "on-switch": _vm.switchToggleTotp }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.error
              ? _c("alert-component", { attrs: { variant: "info" } }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portlet-layout",
    {
      staticClass: "card-header-title w-100",
      attrs: { id: _vm.id, title: _vm.title }
    },
    [
      _c("template", { slot: "body" }, [
        _c("p", [_vm._v(_vm._s(_vm.description))]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c(
            "div",
            { staticClass: "dashboard-security-chart-container" },
            [
              _c("component-spinner", {
                attrs: { spin: _vm.transformedChartModel === null }
              }),
              _vm._v(" "),
              _c("PieChart", {
                attrs: {
                  "chart-model": _vm.transformedChartModel,
                  colours: _vm.coloursOverride,
                  pieChartType: "donut"
                },
                on: { "create-chart-legends": _vm.createChartLegends }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dashboard-security-chart-legends" },
          [
            _vm.chartContexts.length > 0
              ? _c("PieChartLegends", {
                  attrs: { chartContexts: _vm.chartContexts }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footerLink" },
        [
          _c(
            "router-link",
            {
              staticClass: "portlet-title-see-all-link",
              attrs: {
                to: "/portfolio",
                title: _vm.dictionary.portfolioLinkOpenPortfolio
              }
            },
            [_vm._v(" " + _vm._s(_vm.dictionary.portfolioLinkOpenPortfolio))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "form-input-label d-md-flex align-items-center" },
    [
      _c("span", [_c("strong", [_vm._v(_vm._s(_vm.label))])]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "px-2" },
        [
          _c("tooltip", {
            attrs: { "tooltip-text": _vm.tooltipText, hideTooltip: false }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "px-2" }, [_vm._t("reset-action")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
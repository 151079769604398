<template>
  <div class="portfolio-summary">
    <h4 class="force-nowrap">
        <span>
            {{portfolioSummary.blockTitle}}
            <i v-if="portfolioSummary.blockTooltip" :id="tooltipId" class="far fa-info-circle popover-info" aria-hidden="true"></i>
        </span>
        <b-popover :target="tooltipId" triggers="hover" placement="top">
            <span>{{ portfolioSummary.blockTooltip }}</span>
        </b-popover>
    </h4>
    <div v-if="portfolioSummary.walletValues.length > 1">
        <select v-if="portfolioSummary.walletValues.length > 1" class="form-control" :aria-label="portfolioSummary.blockTitle">
            <option v-for="item in portfolioSummary.walletValues" :key="item.currency" :value="item.currency">
                {{item.walletValue}}
            </option>
        </select>
    </div>
    <div v-else-if="portfolioSummary.walletValues.length">
        <h3>{{ portfolioSummary.walletValues[0] ? portfolioSummary.walletValues[0].walletValue : "" }}</h3>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
export default {
  props: {
    portfolioSummary: Object,
    title: String,
    hideTitle: {
      type: Boolean,
      default: true,
    },
    hideShadow: {
      type: Boolean,
      default: true,
    },
    },
    data() {
      return {
        selected: this.portfolioSummary.walletValues.length > 0 ? this.portfolioSummary.walletValues[0].currency : null
      }
    },
  computed: {
    blockId() {
      return `block-${uuid()}`;
    },
    tooltipId() {
      return `block-tooltip-${uuid()}`;
    }
  },
};
</script>
import Vue from 'vue';
import BodyContainer from './body-container.component.vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';

import { dashboardRoutes } from "Features/Dashboard/Assets/Vue/routes.js";
import { beneficiaryRoutes } from "Features/Beneficiaries/Assets/Vue/routes.js";
import { buyRoutes } from "Features/Buy/Assets/Vue/routes.js";
import { offeringsRoutes } from "Features/Offerings/Assets/Vue/routes.js";
import { subscriptionsRoutes } from "Features/Subscriptions/Assets/Vue/routes.js";
import { transactionsRoutes } from "Features/Transactions/Assets/Vue/routes.js";
import { vestingsRoutes } from "Features/Vestings/Assets/Vue/routes.js";
import { choicesRoutes } from "Features/Choices/Assets/Vue/routes.js";
import { exitsRoutes } from "Features/Exits/Assets/Vue/routes.js";
import { portfolioRoutes } from "Features/Portfolio/Assets/Vue/routes.js";
import { internalExchangesRoutes } from "Features/InternalExchanges/Assets/Vue/routes.js";
import { exercisesRoutes } from "Features/Exercises/Assets/Vue/routes.js";
import { reportsRoutes } from "Features/Reports/Assets/Vue/routes.js";
import { positionDetailRoutes } from "Features/PositionDetail/Assets/Vue/routes.js";
import { listedTradingRoutes } from "Features/ListedTrading/Assets/Vue/routes.js";
import { ordersRoutes } from "Features/Orders/Assets/Vue/routes.js";
import { paymentsRoutes } from "Features/Payments/Assets/Vue/routes.js";
import { dividendRoutes } from "Features/Dividend/Assets/Vue/routes.js";
import { documentRoutes } from "Features/Document/Assets/Vue/routes.js";
import { couponRoutes } from "Features/Coupon/Assets/Vue/routes.js";
import { inboxRoutes } from "Features/Inbox/Assets/Vue/routes.js";
import { moneyTransferRoutes } from "Features/MoneyTransfer/Assets/Vue/routes.js"

import { requiredDocumentsRoutes } from "Features/RequiredDocuments/Assets/Vue/routes.js";
import { requiredInfoRoutes } from "Features/RequiredInfo/Assets/Vue/routes.js";
import { questionnaireRoutes } from "Features/Questionnaire/Assets/Vue/routes.js";
import { loginDisclaimersRoutes } from "Features/LoginDisclaimers/Assets/Vue/routes.js";
import { disclaimerRoutes } from "Features/Disclaimer/Assets/Vue/routes.js";
import { cookiePolicyRoutes } from "Features/CookiePolicy/Assets/Vue/routes.js";
import { errorRoutes } from "Features/Error/Assets/Vue/routes.js";

import { loginRoutes, loginSsoRoutes } from "Features/Login/Assets/Vue/routes.js";

var router = new VueRouter({
  mode: 'history',
    routes: [
        dashboardRoutes,
        beneficiaryRoutes,
        buyRoutes,
        couponRoutes,
        choicesRoutes,
        dividendRoutes,
        documentRoutes,
        exercisesRoutes,
        exitsRoutes,
        inboxRoutes,
        internalExchangesRoutes,
        listedTradingRoutes,
        loginRoutes,
        loginSsoRoutes,
        moneyTransferRoutes,
        offeringsRoutes,
        ordersRoutes,
        paymentsRoutes,
        portfolioRoutes,
        positionDetailRoutes,
        reportsRoutes,
        subscriptionsRoutes,
        transactionsRoutes,
        vestingsRoutes,
        requiredDocumentsRoutes,
        requiredInfoRoutes,
        questionnaireRoutes,
        loginDisclaimersRoutes,
        disclaimerRoutes,
        cookiePolicyRoutes,
        errorRoutes,
    ]
});

new Vue({
    router,
    el: '#body-vm',
    vuetify: new Vuetify(),
    components: {
        BodyContainer
    }
});
<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>

        <p v-if="model.requiresInput">{{ dictionary.widgets.textConfirmation }}</p>
        <dl class="order-details-list">
            <ExitConfirmationRow v-if="model.state && !toggles.hideOrderState" :label="dictionary.confirmation.stateName" :value="model.state.description" />
            <ExitConfirmationRow :label="dictionary.confirmation.planName" :value="model.planName" />
            <ExitConfirmationPositionsRow :label="dictionary.confirmation.positionName" :positions="model.positions" />
            <ExitConfirmationRow :label="dictionary.form.orderType" :value="dictionary.form.orderTypeExitName" />
            <ExitConfirmationRow :label="dictionary.confirmation.scenarioName" :value="model.exitScenarioName" />
            <ExitConfirmationRow v-if="model.executionMoment" :label="dictionary.confirmation.executionMoment" :value="model.executionMoment.description" />
            <ExitConfirmationRow :label="dictionary.confirmation.reference" :value="model.reference" />
            <ExitConfirmationRow :label="dictionary.confirmation.instructionDate" :value="model.submissionDate | toDateTime" />
            <ExitConfirmationRow :label="dictionary.confirmation.ordered" :value="model.nrOrderedFormatted" />
            <ExitConfirmationRow :label="dictionary.confirmation.securityPrice" :value="model.securityPriceFormatted" />
            <ExitConfirmationRow :label="dictionary.confirmation.limitPrice" :value="model.limitPriceFormatted" />

            <payments-result :payments="model.taxPayments" :noPaymentsLabel="dictionary.confirmation.taxPayment" />
            <payments-result :payments="model.feesPayments" :noPaymentsLabel="dictionary.confirmation.paymentFees" />

            <ExitConfirmationRow :label="dictionary.confirmation.proceeds" :value="model.proceedsFormatted" class="confirmation-total-row"/>
        </dl>
        <div v-if="!model.requiresInput" class="float-md-right clearfix">
            <button class="btn primaryButton" :disabled="false" @click="$emit('exit-instruction-go-back')">{{ dictionary.form.goBack }}</button>
        </div>
        <ExitConfirmationButtonsComponent v-else-if="!model.disclaimers.length" :exitInstructionViewModel="model" v-on="$listeners" />

    </div>
</template>

<script>
    import ExitConfirmationRow from "./exit-confirmation-row.components.vue";
    import ExitConfirmationPositionsRow from "./exit-confirmation-positions-row.components.vue";
    import ExitConfirmationButtonsComponent from "./exit-confirmation-buttons.component.vue";

    export default {
        components: {
            ExitConfirmationRow,
            ExitConfirmationPositionsRow,
            ExitConfirmationButtonsComponent,
        },
        props: {
            exitInstructionViewModel: Object,
            toggles: Object,
            dictionary: Object,
            error: String
        },
        computed: {
            model() {
                return this.exitInstructionViewModel;
            }
        }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "modal-basic",
        {
          attrs: {
            title: _vm.dictionary.page.warningSkipHeader,
            "is-on": _vm.isModalVisible,
            centered: true,
            "ok-footer": false
          },
          on: {
            close: function($event) {
              return _vm.$emit("close-modal")
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "cancel-add-account-modal-container",
              attrs: { id: "cancel-add-account-modal-container" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "cancel-add-account-modal-description",
                  attrs: { id: "cancel-add-account-modal-description" }
                },
                [
                  _c("p", {
                    staticClass: "rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(_vm.dictionary.page.warningSkipText)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "mt-2 rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.dictionary.page.warningSkipTextConfirmation
                        )
                      )
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cancel-add-account-modal-buttons",
                  attrs: {
                    id: "cancel-add-account-modal-buttons button-container"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "cancel-add-account-modal__no-button" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn secondaryButton",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close-modal")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.dictionary.buttons.cancel) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cancel-add-account-modal__yes-button" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn primaryButton",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("submit-cancel")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.dictionary.buttons.confirm) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
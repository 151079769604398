<template>
    <div class="row employment-history-detail-row">
        <div class="col-6 employment-history-detail-row-label">
            <p>{{ label }}</p>
        </div>
        <div class="col-6 employment-history-detail-row-value">
            <p>{{ displayEligibleValue() }}</p>
        </div>
    </div>
</template>
  
<script>
export default {
  props: {
    label: String,
    value: String,
    displayValue: String,
    isEdit: Boolean,
  },
  methods: {
    isNotNullable: function () {
      return !!this.value;
    },
    displayEligibleValue: function () {
      if (!this.displayValue) {
        return this.replaceNullOrEmpty(this.value);
      }

      return this.displayValue;
    },
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c("ReportsDetail", {
        attrs: {
          id: "PortfolioReportDetails",
          dictionary: _vm.dictionary,
          toggles: _vm.toggles,
          reportName: _vm.dictionary.portfolio.name,
          reportDescription: _vm.dictionary.portfolio.description,
          "is-mobile": _vm.isMobile
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  !_vm.isMobile
                    ? _c("div", { staticClass: "custom-report-form" }, [
                        _c("div", {
                          staticClass: "custom-report-form-empty-height"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "custom-report-form-btn" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn primaryButton float-right",
                                attrs: { to: "/reports/portfolioOverview" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.dictionary.portfolio.extractButton)
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-content-start" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-report-form-btn col" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn primaryButton float-right",
                                  attrs: { to: "/reports/portfolioOverview" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.portfolio.extractButton
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1722620289
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
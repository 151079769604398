<template>
  <div class="questionnaire-card-container" ref="accessibilityMainFocus">
      <div class="questionnaire-description-block rich-text-container" v-html="$sanitize(dictionary.page.description)" />
      <component-spinner :spin="isLoading"></component-spinner>
      <alert-component v-if="error" variant="error">{{ error }}</alert-component>
      <div class="questionnaire-card-header" v-if="currentQuestionnaire">
        <div>
            <h3>{{currentQuestionnaire.questionnaireName}}</h3>
            <p class="rich-text-container" v-if="currentQuestionnaire.headingText" v-html="$sanitize(currentQuestionnaire.headingText)"></p>
        </div>
      </div>
      <div class="questionnaire-card-body">
          <template v-if="currentQuestionnaire.singleQuestionMode">
              <QuestionSingle :dictionary="dictionary" :v="$v" 
                  @change-answer="handleChangeAnswer" :current-questionnaire="currentQuestionnaire" 
                  :question-index="questionIndex" :show-correct-answer="showCorrectAnswer">
              </QuestionSingle>
          </template>
          <template v-else>
              <QuestionMultiple :dictionary="dictionary" :v="$v"
               @change-answer="handleChangeAnswer" :current-questionnaire="currentQuestionnaire" 
               :show-correct-answer="showCorrectAnswer">
              </QuestionMultiple>
          </template>
      </div>
      <div v-if="currentQuestionnaire" :class="[ 'questionnaire-card-footer d-md-flex', currentQuestionnaire.singleQuestionMode ? 'justify-content-md-between' : 'justify-content-md-end' ]">
          <div v-if="currentQuestionnaire.singleQuestionMode">
              <button class="btn secondaryButton" @click="prevQuestion" v-bind:disabled="!hasPrevQuestion">
                  {{dictionary.labels.btnPrevious}}
              </button>
              <button class="btn primaryButton" @click="nextQuestion" v-bind:disabled="!hasNextQuestion">
                  {{dictionary.labels.btnNext}}
              </button>
          </div>
          <div>
            <button  :disabled="!canSubmitQuestionnaire" class="btn primaryButton" @click="handleSubmitQuestionnaire">
                {{dictionary.labels.btnSubmit}}
            </button>
          </div>            
      </div>
  </div>
</template>

<script>
import QuestionMultiple from "./question-multiple.component.vue";
import QuestionSingle from "./question-single.component.vue";
import { questionnaireValidations } from "./questionnaire-validation.mixin.js";
import { EventBus } from "Shared/event-bus";
import { accessibility } from "Shared/Mixins/accessibility.mixin.js";

export default {
mixins:[questionnaireValidations, accessibility],
components: {
  QuestionMultiple,
  QuestionSingle,
},
props: {
  dictionary: Object,
  currentQuestionnaire: Object,
  questionnaireCount: Number,
  questionnaireIndex: Number,
  error: String,
  showCorrectAnswer: Boolean,
  returnUrl: { type: String, default: "/" },
  isLoading: Boolean,
  isModal: Boolean
},
data() {
  return {
    canSubmitQuestionnaire: true,
    questionIndex: -1
  };
},

mounted() {
  if (this.currentQuestionnaire && this.currentQuestionnaire.questions) {
    if (this.currentQuestionnaire.singleQuestionMode){
      this.nextQuestion();
    }
    this.canSubmitQuestionnaire = this.currentQuestionnaire.questions.filter(
        e => (e.givenAnswer == null && e.givenOpenAnswer == null && e.givenChoiceId == null)
      ).length === 0 &&  this.currentQuestionnaire.canEdit && !this.$v.$invalid
  }
  this.$v.$touch();
  EventBus.$emit("v-touch");
},
methods: {
  nextQuestionnaire() {
    this.questionnaireIndex++;
  },

  prevQuestionnaire() {
    this.questionnaireIndex--;
  },

  handleChangeAnswer() {
    this.canSubmitQuestionnaire = this.validQuestionnaire;
  },

  handleSubmitQuestionnaire() {
    if (this.$v.$invalid) {
      return;
    }

    const questionAnswers = this.currentQuestionnaire.questions.map((a) => {
      return { questionId: a.questionId, questionAnswer: a.givenAnswer, choiceId: a.givenChoiceId , openAnswer: a.givenOpenAnswer };
    });

    if (questionAnswers.length > 0) {
      this.canSubmitQuestionnaire = false;
      this.$emit("submit-questionnaire", {
        questionnaireId: this.currentQuestionnaire.questionnaireId,
        questions: questionAnswers,
      });
    }
  },
  nextQuestion() {
    this.questionIndex++;
    this.accessibilityMainContentFocus();
  },
  prevQuestion() {
    this.questionIndex--;
    this.accessibilityMainContentFocus();
  }
},

computed: {
  hasPrevQuestion() {
    return this.questionIndex > 0;
  },

  hasNextQuestion() {
    return (
      this.questionIndex < this.currentQuestionnaire.questions.length - 1
    );
  },

  validQuestionnaire() {
    /* get questions not yet answered or incorrect */
    return (
      this.currentQuestionnaire &&
      this.currentQuestionnaire.questions.filter(
        e => (e.givenAnswer == null && e.givenOpenAnswer == null && e.givenChoiceId == null)
      ).length === 0 &&
      this.currentQuestionnaire.canEdit && !this.$v.$invalid
    );
  }
}
};
</script>

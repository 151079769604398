var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.plan.positions.length
    ? _c("portlet-layout", {
        attrs: {
          "column-class": "col-12",
          title: _vm.plan.name,
          "is-custom-title": true,
          "is-custom-body": true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "customTitle",
              fn: function() {
                return [
                  _c("PortfolioPlanValueTitle", {
                    attrs: {
                      toggles: _vm.toggles,
                      dictionary: _vm.dictionary,
                      portfolioPlan: _vm.plan,
                      isMobile: _vm.isMobile
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "body",
              fn: function() {
                return [
                  _c("PortfolioPlanTable", {
                    attrs: {
                      portfolioPlan: _vm.plan,
                      dictionary: _vm.dictionary,
                      toggles: _vm.toggles,
                      currency: _vm.currency,
                      isMobile: _vm.isMobile
                    }
                  }),
                  _vm._v(" "),
                  _vm.plan.isMultiPositionExit
                    ? _c("MultiPositionExitButton", {
                        attrs: {
                          buttonText: _vm.dictionary.block.btnMultiPositionExit,
                          isModalVisible: _vm.isModalVisible,
                          positionName: _vm.plan.multiPositionExitPosition.name,
                          isLoading: _vm.isLoading,
                          isMobile: _vm.isMobile,
                          exitsDictionary: _vm.exitsDictionary,
                          exitOrderModel: _vm.exitInstructionOrderModel
                        },
                        on: {
                          clicked: function($event) {
                            return _vm.openExitInstructionOrderModal()
                          },
                          closed: function($event) {
                            return _vm.closeModal()
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2616233027
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
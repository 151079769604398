<template>
    <div>
        <div id="account-edit-form-body">
            <beneficiary-data-label v-if="isUserNameVisible"
                                    :value="dataModel.userName"
                                    :label="dictionary.account.loginName"
                                    is-edit></beneficiary-data-label>

            <beneficiary-edit-label v-model="editModel.emailAddress"
                                    :label="dictionary.account.emailAddress"
                                    name="EmailAddress"
                                    :is-visible="dataModel.beneficiaryBoundaries.mayViewEmailAddress"
                                    :is-editable="dataModel.beneficiaryBoundaries.mayEditEmailAddress"
                                    :error-keys="errorKeys"
                                    :validations="validations"
                                    type="email"></beneficiary-edit-label>

            <input-element v-if="dataModel.beneficiaryBoundaries.mayViewCCEmailAddress"
                           name="CcEmailAddress" type="text"
                           class="beneficiary-details"
                           :label="dictionary.account.ccEmailAddress"
                           :disabled="!dataModel.beneficiaryBoundaries.mayEditCCEmailAddress"
                           v-model="editModel.ccEmailAddress"
            ></input-element>

            <input-element v-if="dataModel.beneficiaryBoundaries.mayViewPersonalTelephone"
                           name="Telephone" type="text"
                           class="beneficiary-details"
                           :label="dictionary.account.mobileTelephone"
                           v-model="editModel.telephone"
                           :disabled="!dataModel.beneficiaryBoundaries.mayEditTelephone"
                           :validationsList="validationLists.mobileTelephoneValidation"></input-element>

            <div v-if="dataModel.beneficiaryBoundaries && dataModel.beneficiaryBoundaries.mayChangePassword">
                <BeneficiaryAccountChangePasswordLink :label="dictionary.account.password" :link="dictionary.account.changePassword"></BeneficiaryAccountChangePasswordLink>
            </div>

            <div v-if="dataModel.locale && dataModel.beneficiaryBoundaries.mayEditLocale">
                <div class="form-group beneficiary-details">
                    <custom-dropdown v-model="editModel.locale.id" 
                        id-name="LocaleId"
                        :use-inline-label="true"
                        :show-label="true"
                        :items="items"
                        :label="dictionary.account.language" 
                        :initial-item="dataModel.locale.name"
                        event-name="change-language"
                        aria-label="choose a language" />
                </div>

            </div>

            <div v-if="dataModel.beneficiaryBoundaries.mayEditLocale">
                <beneficiary-select-edit-label v-model="editModel.decimalSeparator.value"
                                               :label="dictionary.account.decimalSeparator"
                                               name="DecimalSeparator"
                                               :options="decimalSeparators"
                                               :is-editable="dataModel.beneficiaryBoundaries.mayEditLocale"
                                               :error-keys="errorKeys"
                                               :validations="validations"></beneficiary-select-edit-label>
            </div>
        </div>
        <FormButtons :commonDictionary="commonDictionary" @submit-form="submitForm()" @cancel-form="$emit('cancel-form')" />
    </div>
</template>

<script>
    import BeneficiaryDataLabel from "./../data-label.component.vue";
    import BeneficiaryEditLabel from "./../edit-label.component.vue";
    import BeneficiaryActionLabel from "./../action-label.component.vue";
    import BeneficiarySelectEditLabel from "./../select-edit-label.component.vue";
    import BeneficiaryLocaleSelect from "./beneficiary-locale-select.component.vue";
    import AccountEnableMfaToggle from "./account-enable-mfa-toggle.component.vue";
    import FormButtons from "./form-buttons.component.vue";
    import { accountEditValidation } from "./mixins/account-edit-validation.mixin.js";
    import { EventBus } from "Shared/event-bus";
    import BeneficiaryAccountChangePasswordLink from "./beneficiary-account-change-password-link.component.vue";

    const createDropDownOption = (text, value) => ({
        text: text,
        value: value,
    });

    export default {
        mixins: [accountEditValidation],
        components: {
            BeneficiaryDataLabel,
            BeneficiaryEditLabel,
            BeneficiaryActionLabel,
            BeneficiarySelectEditLabel,
            BeneficiaryLocaleSelect,
            AccountEnableMfaToggle,
            FormButtons,
            BeneficiaryAccountChangePasswordLink
        },
        props: {
            dataModel: Object,
            errorKeys: Array,
            validations: Array,
            dictionary: Object,
            commonDictionary: Object
        },
        data() {
            return {
                editModel: {
                    emailAddress: null,
                    ccEmailAddress: null,
                    locale: null,
                    telephone: null,
                    mfaEnabled: false,
                    decimalSeparator: null
                }
            }
        },
        watch: {
            dataModel: {
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.setEditModel(newVal);
                    }
                },
                immediate: true
            }
        },
        mounted() {
            this.$v.$touch();
            EventBus.$on('change-language', this.onChangeLanguage)
        },
        computed: {
            decimalSeparators() {
                return this.dataModel.beneficiaryBoundaries.decimalSeparators.map((c) => {
                    return createDropDownOption(c.description, c.value);
                });
            },
            isUserNameVisible() {
                return this.dataModel.beneficiaryBoundaries.mayViewUserName;
            },
            items() {
                return  this.dataModel.beneficiaryBoundaries.availableLocales &&  this.dataModel.beneficiaryBoundaries.availableLocales
                    .map((locale) => {  
                        return {
                            id: locale.id,
                            name: locale.name,
                            imageSrc:  `/images/flags/${locale.imageSrc}`,
                            isO3: locale.iso3,
                            displayName: locale.name
                        };
                    });
            }
        },
        methods: {
            setEditModel(newVal) {
                this.editModel.emailAddress = newVal.emailAddress;
                this.editModel.ccEmailAddress = newVal.ccEmailAddress;
                this.editModel.locale = newVal.locale;
                this.editModel.telephone = newVal.telephone;
                this.editModel.mfaEnabled = newVal.mfaEnabled;
                this.editModel.decimalSeparator = newVal.decimalSeparator;
            },
            getImageSrc: function (locale) {
                return `/images/flags/${locale.imageSrc}`;
            },
            submitForm() {
                this.$v.$touch();
                EventBus.$emit("v-touch");
                if (this.$v.$invalid) {
                    return;
                }

                this.$emit('submit-form', this.editModel);
            },
            onChangeLanguage(languageId){
                this.editModel.locale = this.dataModel.beneficiaryBoundaries.availableLocales.find(c => c.id == languageId);
            }
        }
    };
</script>
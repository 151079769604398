<template>
<div>
    <v-expansion-panels :class="['dividend-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="dividend-details-expansion-panel">
            <v-expansion-panel-header class="dividend-details-expansion-panel__header">
                <h3 class="h4">{{ dictionary.widgets.detail }}: {{ dividend.distributionDate }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <DividendDetailsContent :dictionary="dictionary" :dividend="dividend" :toggles="toggles" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</div>
</template>

<script>
import DividendDetailsContent from "./dividend-details-content.component.vue";

export default {
    components: {
        DividendDetailsContent
    },
    props: {
        dividend: Object,
        dictionary: Object,
        toggles: Object
    },
    data() {
        return {
            panel: null
        }
    },
    computed: {
        isPanelOpened() {
            return this.panel !== undefined && this.panel !== null;
        },
    }
}
</script>
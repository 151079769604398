<template>
    <div v-if="dictionary && dividendSubscription" class="portlet-container">
        <div class="row m-0">
            <DividendSubscriptionFormPortlet v-if="!showConfirmation" :dictionary="dictionary" :dividendSubscription="dividendSubscription" @subscribe="subscribe"/>
            <DividendSubscriptionConfirmationPortlet v-else :dictionary="dictionary" :dividendSubscription="dividendSubscription" :subscribedDividend="subscribedDividend" @confirm-subscription="confirm" @cancel="cancel"/>

            <portlet-layout column-class="col-lg-4 col-xl-3" :title="dictionary.subscriptionLabels.calendar" >
                <template slot="body">
                    <DividendSubscriptionInfo :dictionary="dictionary" :dividendSubscription="dividendSubscription" />
                </template>
            </portlet-layout>
        </div>
    </div>
</template>

<script>

import DividendSubscriptionFormPortlet from "./dividend-subscription-form-portlet.component.vue";
import DividendSubscriptionInfo from "./dividend-subscription-info.component.vue";
import DividendSubscriptionConfirmationPortlet from "./dividend-subscription-confirmation-portlet.component.vue";
import { get, post } from "api";

export default {
    components: {
        DividendSubscriptionFormPortlet,
        DividendSubscriptionInfo,
        DividendSubscriptionConfirmationPortlet,
    },
    props: {
        dictionary: Object,
        toggles: Object,
    },
    data() {
        return {
            dividendSubscription: null,
            isLoadingData: true,
            subscribedDividend: null,
            isSubmitting: false,
            error: null
        }
    },
    computed: {
        showConfirmation() {
            return this.subscribedDividend != null;
        },
    },
    methods: {
        subscribe(subscribedDividend) {
            this.subscribedDividend = subscribedDividend;
        },
        cancel() {
            this.subscribedDividend = null;
        },
        confirm(subscribedDividend) {
            this.isSubmitting = true;
            this.error = null;

            post('/Dividend/DividendAllocation',
                subscribedDividend,
                (response) => {
                    this.$router.push({ path: "/dividend" });
                },
                this,
                () => {
                    this.isSubmitting = false;
                },
               (err) => {
                    this.error = err.response.data;
                    this.isSubmitting = false;
                }
            );
        }
    },
    beforeCreate() {
        let query = this.$route.query;

        get(`/Dividend/DividendSubscription?dividendParticipationId=${encodeURIComponent(query.dividendParticipationId)}&dividendAllocationId=${encodeURIComponent(query.dividendAllocationId)}`,
            (response) => {
               this.dividendSubscription = response.data;
           },
           this,
           () => {this.isLoadingData = false}
        );
    }
};
</script>
export const ScreenSizeMixin = {
    data() {
        return {
            isTablet: false,
            isMobile: false,
            isDesktop: true,
            isInTabletAndDesktopRange: false
        }
    },
    methods: {
        handleResize() {
            this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
                && window.innerWidth > this.$windowSizes.MOBILE;
            this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            this.isDesktop = window.innerWidth >= this.$windowSizes.DESKTOP;
            this.isInTabletAndDesktopRange = window.innerWidth <= this.$windowSizes.DESKTOP_LG && window.innerWidth >= this.$windowSizes.TABLET;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    }
}
import LoginContainer from "./login-container.component.vue";
import Login from "./login.component.vue";
import UserNameRecovery from "./UserNameRecovery/user-name-recovery.component.vue";
import PasswordRecovery from "./PasswordRecovery/password-recovery.component.vue";
import PasswordExpired from "./PasswordExpired/password-expired.component.vue";
import PasswordChangedSuccessful from "./PasswordChangedSuccessful/password-changed-successful.component.vue";
import LoginSsoContainer from "./login-sso-container.component.vue";

export const loginRoutes =
{
    path: '/login',
    component: LoginContainer,
    children: [
        {
            path: '',
            component: Login
        },
        {
            path: 'forgottenUsername',
            component: UserNameRecovery
        },
        {
            path: 'forgottenPassword',
            component: PasswordRecovery
        },
        {
            path: 'passwordExpired',
            component: PasswordExpired
        },
        {
            path: 'passwordChangedSuccessful',
            component: PasswordChangedSuccessful
        },
        {
            path: '*',
            redirect: '/login'
        }
    ]
}

export const loginSsoRoutes =
{
    path: '/login-sso',
    component: LoginSsoContainer,
    children: [
        {
            path: '*',
            redirect: '/login-sso'
        }
    ]
}
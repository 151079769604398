<template>
  <div class="question-container">
      <QuestionDetail 
        :currentQuestion="currentQuestion" 
        :questionIndex="questionIndex" 
        :showCorrectAnswer="showCorrectAnswer" 
        :dictionary="dictionary" 
        :v="v"
        @change-answer="handleChangeAnswer" />
  </div>
</template>
<script>

import QuestionDetail from "./question-detail.component.vue";

export default {
  components: {
    QuestionDetail
  },
  props: {
    currentQuestionnaire: Object,
    questionIndex: Number,
    showCorrectAnswer: Boolean,
    dictionary: Object,
    v:Object
  },
  data() {
    return {
      currentQuestion: null,
    };
  },
  mounted() {
    if (this.questionIndex != -1) {
      this.currentQuestion =
        this.currentQuestionnaire.questions[this.questionIndex];
    }
  },
  watch: {
    questionIndex(newValue) {
      this.currentQuestion = null;
      if (newValue !== -1) {
        this.currentQuestion = this.currentQuestionnaire.questions[newValue];
      }
    },
  },
  methods: {
    handleChangeAnswer(){
      this.$emit('change-answer');
    }
  }  
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.derivativeSecurity
    ? _c(
        "div",
        { staticClass: "text-right force-nowrap", attrs: { id: _vm.spanId } },
        [
          _c("span", [_vm._v(_vm._s(_vm.lastDerivativeSecurityPrice) + " ")]),
          _vm._v(" "),
          !_vm.hideSecurityPriceInformationTooltip
            ? _c("i", {
                staticClass: "far fa-info-circle popover-info",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              !_vm.hideSecurityPriceInformationTooltip
                ? _c(
                    "b-popover",
                    {
                      attrs: {
                        target: _vm.spanId,
                        triggers: "hover",
                        placement: "right"
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.tooltipSecurityPrice) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-info-group", attrs: { id: "footer-info-group" } },
    [
      _c("span", [
        _vm._v(
          "© " +
            _vm._s(_vm.model.currentYear) +
            " " +
            _vm._s(_vm.model.companyName) +
            " -\n      "
        ),
        _c("span", { attrs: { id: "version" } }, [
          _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("V")]),
          _vm._v(" "),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Version")]),
          _vm._v("\n          " + _vm._s(_vm.getVersion()) + "\n      ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
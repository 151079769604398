import Timer from "Shared/Basics/timer.js";
export const MfaMixin = {

    data() {
        return {
            timer: new Timer({ duration: 0 }),
            minimumDelayInSeconds: 5,
            currentError: '',
            timeCountDownErrorMessageTemplate: ''
        }
    },
    methods: {      
        initializeTimer(errorMessageTemplate) {
            this.timeCountDownErrorMessageTemplate = errorMessageTemplate;
            this.timer.on("tick", (time) => {
                this.currentError = this.$replaceStringWithPlaceholders(this.timeCountDownErrorMessageTemplate,
                    {
                        DelayInSeconds: time
                    });
            });

            this.timer.on("end", () => {
                this.currentError = '';
            });
        },
        startDelayErrorMessage(factorDelay) {
            let delayInSeconds = Math.max(factorDelay, this.minimumDelayInSeconds);
            this.timer.start(delayInSeconds);
        }
    }
}
<template>
  <section class="report-panel" v-if="!isMobile">
    <div class="report-panel-inner custom-report-panel">
      <div>
        <div class="file-type-icon">
          <img src="/images/files/excel-icon.png" alt="export to excel" />
        </div>
        <div>
          <h3 class="h4">{{reportName}}</h3>
          <p class="rich-text-container" v-html="$sanitize(reportDescription)"></p>
        </div>
      </div>
      <div>
        <slot name="body"></slot>
      </div>
    </div>
  </section>

  <div v-else>
    <div class="report-accordion-header">
      <h3>
        <button v-b-toggle="id">
          <span class="accordion-header-text">
            <span class="h4">{{reportName}}</span>
            <span>{{reportDescription}}</span>
          </span>
          <span>
            <i :class="['far', isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up']" aria-hidden="true"></i>
          </span>
        </button>
      </h3>
    </div>
    <b-collapse :id="id" role="tabpanel">
      <div class="report-accordion-body">
        <slot name="body"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    props: {
      dictionary: Object,
      toggles: Object,
      reportName: String,
      reportDescription: String,
      id: String,
      isMobile: Boolean
    },
    data() {
      return {
        isCollapsed: null
      }
    },
    mounted() {
      this.$root.$on('bv::collapse::state', () => {
        this.isCollapsed = !this.isCollapsed
      })
    }
  };
</script>

import { required, requiredIf } from "vuelidate/lib/validators";

export const isValidUSTin = (input) => {
  const usTinRegEx = /^[0-9]{0,9}$/;
  return usTinRegEx.test(input || "");
};

export const notInFuture = function(value){
  return (!!value && (new Date(value) < new Date())) || false;
}

export const existingCountry = function(value){
  return !!value && value.length > 0 && this.countries.some(x=>x.id === value);
}

export const accountHoldingModelValidations = {
  validations() {
    return {
      requiredInfoModel: {
        firstNames: { required },
        lastName: { required },
        address: { required },
        complementaryAddress: {},
        countryId: { existingCountry },
        postalCode: { required },
        city: { required },
        dateOfBirth: { required, notInFuture },
        countryOfBirthId: { existingCountry }
      }
    };
  },
};

export const declarationModelValidations = {
  validations() {
    return {
      requiredInfoModel: {
        declarationAccepted: { isTrue: function(x){ return x === true } },
      },
    };
  },
};

export const emptyOrNotBeforeStartDate = function(value, {startDate}) {
  return (value ?? "").length === 0 || (new Date(startDate) <= new Date(value));
} 

export const atLeastOneCurrentFiscalNationality = function(fiscalNationalities, { isUSCitizen, usCitizenNationality }) {
  let nationalities = [ ...fiscalNationalities];
  
  if(isUSCitizen === true){
    nationalities.push(usCitizenNationality);
  }

  return nationalities.some(isFiscalNationalityCurrent);
}

export const isFiscalNationalityCurrent = ({startDate, endDate}) => {
  let start = startDate && new Date(startDate);
  let end = endDate && new Date(endDate);
  let now = new Date();
  if(start && start <= now){
    return !end || end > now;
  }
  if(end && end >= now){
    return !start || start < now;
  }
  return false;
}

export const taxResidencyModelValidations = {
  validations() {
    return {
      requiredInfoModel: {
        usCitizenNationality: {
          startDate: { required: requiredIf(function(){ return this.requiredInfoModel.isUSCitizen === true }) },
          endDate: { 
            emptyOrNotBeforeStartDate: function(input, siblings){
              if(this.requiredInfoModel.isUSCitizen === true){
                return emptyOrNotBeforeStartDate(input, siblings);
              }
              return true;
            }
          },
          fiscalNumber: { 
            validUSTin: function(input) { 
              if(this.requiredInfoModel.isUSCitizen === true){
                return isValidUSTin(input);
              }
              return true;
            }
          }
        },
        fiscalNationalities: {
          required: requiredIf(function(){ return this.requiredInfoModel.isUSCitizen === false }),
          atLeastOneCurrentFiscalNationality,
          $each: {
            startDate: { required },
            endDate: { emptyOrNotBeforeStartDate },
            countryId: { existingCountry }
          }
        }
      },
    };
  },
};
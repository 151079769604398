import VestingsContainer from "./vestings-container.component.vue";

export const vestingsRoutes = {
    path: '/vestings',
    alias: '/vestings/index',
    name: 'vestings',
    component: VestingsContainer,
    children: [
        {
            path: '*',
            redirect: '/vestings'
        }
    ]
}
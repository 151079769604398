<template>
    <div id="subscriptions-table-container" class="subscriptions-table-container">
        <h2 class="mb-2">{{ dictionary.widget.titleOpen }}</h2>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-if="!isLoading && tableHasNoRecords" class="empty-dataTable">
            <span>{{ dictionary.table.recordNotFoundOpen }}</span>
        </div>
        <v-data-table v-show="rows.length > 0 || isSearchDirty" class="table-striped"
                      :no-data-text="dictionary.table.recordNotFoundOpen"
                      item-key="participationSubscriptionId"
                      :hide-default-header="isMobile"
                      :headers="customHeaders"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer>
        
            <template v-slot:top>
                <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

            <template v-slot:[`item.custom-button`]="{ item }">
                <button class="btn btn-primary subscription-table-button" @click="subscribe(item.participationSubscriptionId)">{{ getButtonText(item.presentationMode.value) }}</button>
            </template>

            <template v-slot:[`item.name`]="{ item }">
                <SubscriptionNameLabel :name="item.name" :planName="item.planName" />
            </template>

            <template v-slot:[`item.subscribed`]="{ item }">
                <span>{{ replaceNullOrEmpty(item.subscribed) }}</span>
            </template>

            <template v-slot:[`item.subscriptionPeriodStartDate`]="{ item }">
                <span>{{ item.subscriptionPeriodStartDate | toDate }}</span>
            </template>

           <template v-slot:[`item.subscriptionPeriodEndDate`]="{ item }">
                <span>{{ item.subscriptionPeriodEndDate | toDate }}</span>
            </template>
            
            <template v-if="isMobile" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="customHeaders"
                                         v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
            </template>

            <template v-if="isMobile" v-slot:item="{ item }">
                <SubscriptionMobileContent :dictionary="dictionary">
                    <template v-slot:header>
                        <SubscriptionNameLabel :name="item.name" :planName="item.planName" />
                    </template>
                    <template v-slot:content>
                        <SubscriptionMobileRow :label="dictionary.table.securityName" :value="replaceNullOrEmpty(item.securityName)" />
                        <SubscriptionMobileRow :label="dictionary.table.subscribed" :value="replaceNullOrEmpty(item.subscribed)" />
                        <SubscriptionMobileRow :label="dictionary.table.startDate" :value="item.subscriptionPeriodStartDate | toDate" />
                        <SubscriptionMobileRow :label="dictionary.table.endDate" :value="item.subscriptionPeriodEndDate | toDate" />
                        <button class="btn primaryButton subscription-table-button" @click="subscribe(item.participationSubscriptionId)">{{ getButtonText(item.presentationMode.value) }}</button>
                    </template>
                </SubscriptionMobileContent>
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import SubscriptionMobileContent from "./Shared/subscription-mobile-content.component.vue"; 
    import SubscriptionNameLabel from "./Shared/subscription-name-label.component.vue"; 
    import SubscriptionMobileRow from "./Shared/subscription-mobile-row.component.vue"; 


    export default {
        mixins: [vueDataTableMixin, ScreenSizeMixin],
        components: {
            SubscriptionMobileContent, 
            SubscriptionNameLabel, 
            SubscriptionMobileRow 
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            toggles: Object
        },
        data() {
            return {
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                getUrl: '/subscriptions/subscriptions',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["name"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'custom-button', text: '', align: 'center', sortable: false, visible: true, width: '3rem' },
                    { value: 'name', text: this.dictionary.table.subscriptionName, align: 'left', sortable: true, visible: true },
                    { value: 'subscribed', text: this.dictionary.table.subscribed, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" },
                    { value: 'subscriptionPeriodStartDate', text: this.dictionary.table.startDate, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" },
                    { value: 'subscriptionPeriodEndDate', text: this.dictionary.table.endDate, align: 'right', sortable: true, visible: true, className: 'force-nowrap', cellClass: "force-nowrap" }
                ],
                rows: [],
                totalRecords: 0, 
                isSubscriptionQuery: false
            }
        },
        computed: {
            customHeaders() {
                return this.headers.filter(h => h.visible);
            },
            sortableColumns() {
                return this.customHeaders.filter(x => x.sortable);
            },
            tableHasNoRecords() {
                return this.rows.length == 0 && !this.isSearchDirty;
            }
        },
        methods: {
            subscribe(participationSubscriptionId) {
                this.$router.push({
                    path: "/subscriptions/subscribe",
                    query: {
                        participationSubscriptionId: participationSubscriptionId
                    }
                });
            },
            getButtonText(presentationMode) {
                if (presentationMode == 'spmAcceptation') {
                    return this.dictionary.table.presentationModeAccept;
                }
                else {
                    return this.dictionary.widget.buttonSubscribe;
                }
            }
        }
    }
</script>

<template>
  <div class="step-container" ref="stepRefScroll">
    <div class="step-body mb-4">
      <template v-if="currentStep > step">
        <required-info-step-accordion :heading="heading" :current-step="currentStep" :dictionary="dictionary" :step="step"
          :steps="steps" :summaryType="summaryType" @edit-step="editStep">
           <slot name="previous" />
        </required-info-step-accordion>
      </template>
      <div v-show="currentStep === step">
          <h2 class="timeline-step-header" :id="'step-' + step">{{heading}}</h2> 
          <p class="timeline-step-description rich-text-container" v-html="$sanitize(description)"></p>
          <slot name="current" />
      </div>
      <template v-if="currentStep < step">
        <h2 class="timeline-step-header" :id="'step-' + step">{{heading}}</h2> 
        <slot name="future" />
      </template>
    </div>
  </div>
</template>

<script>
import RequiredInfoStepAccordion from "./required-info-step-accordion.component.vue";

export default {
  components: { 
    RequiredInfoStepAccordion
  },
  props: {
    heading: String,
    description: String,
    dictionary: Object,
    currentStep: Number,
    step: Number,
    steps: Number,
    summaryType: String
  },
  methods:{
    editStep(val){
      this.$emit("edit-step", val);
    }
  },
  watch: {
    currentStep(newValue, oldValue) {
        if (newValue == this.step && newValue != oldValue) {
            this.$nextTick(() => {
              this.$refs.stepRefScroll.scrollIntoView(true)
            });
        }
    }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltip-container" },
    [
      !_vm.hideTooltip
        ? _c("i", {
            staticClass: "popover-info",
            class: _vm.icon,
            attrs: { id: _vm.uniqueId, "aria-hidden": "true" }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideTooltip
        ? _c(
            "b-popover",
            {
              staticClass: "vesting-column__popover",
              attrs: {
                target: _vm.uniqueId,
                triggers: "hover",
                placement: _vm.placement
              }
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.tooltipText))]),
              _vm._v(" "),
              _c("p", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.tooltipText))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
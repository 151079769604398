var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transactionsOptions && _vm.transactionsOptions.positions
    ? _c(
        "div",
        { attrs: { id: "transaction-overview" } },
        [
          _c(
            "portlet-row",
            [
              _c("TransactionsTable", {
                ref: "transactionsTable",
                attrs: {
                  toggles: _vm.toggles,
                  dictionary: _vm.dictionary,
                  transactionsOptions: _vm.transactionsOptions,
                  datatablesDictionary: _vm.datatablesDictionary
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("TransactionsForm", {
                            attrs: {
                              dictionary: _vm.dictionary,
                              transactionsOptions: _vm.transactionsOptions,
                              positions: _vm.positions,
                              isShowBtn: _vm.isShowBtn
                            },
                            on: {
                              onPositionChange: _vm.onPositionChange,
                              onShowBtn: _vm.updateTable,
                              onSendButton: _vm.sendTransactionReport
                            }
                          }),
                          _vm._v(" "),
                          _vm.sendtoInboxMessage
                            ? _c(
                                "alert-component",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    variant: _vm.sendtoInboxMessage.variant
                                  }
                                },
                                [_vm._v(_vm._s(_vm.sendtoInboxMessage.message))]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3479310137
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
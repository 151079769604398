<template>
<portlet-layout v-if="accountDictionary" :title="accountDictionary.widgets.contact" :hideShadow="true">
    <template v-slot:title>
        {{accountDictionary.widgets.contact}} <template v-if="beneficiaryBoundariesDataModel.mayEditCurrentAddress"> <i class='far fa-edit beneficiary-edit-button' aria-hidden="true" @click="editAddress"></i></template> 
    </template>
    <template v-slot:body>
        <div v-if="!isAddressEdited">
            <AddressView :addressDataModel="addressDataModel" :dictionary="accountDictionary.address" :isAddressEdited="isAddressEdited"></AddressView>
        </div>
        <div v-else>
            <BeneficiaryAddressEditForm :addressDataEditModel="addressDataEditModel" :accountDictionary="accountDictionary" :commonDictionary="commonDictionary" 
            :isAddressEdited="isAddressEdited" @address-edit-form-cancelled="addressEditFormCancelled" @reload-page="reloadPage"></BeneficiaryAddressEditForm>
        </div>
    </template>
</portlet-layout>
</template>

<script>
import AddressView from "./address-view.component.vue";
import AddressEdit from "./address-edit.component.vue";
import BeneficiaryAddressEditForm from "./beneficiary-address-edit-form.component.vue";

export default {
  components: {
    AddressView,
    AddressEdit,
    BeneficiaryAddressEditForm
  },
  props: {
    accountDictionary: Object,
    commonDictionary:Object,
    addressDataModel: Object,
    addressDataEditModel: Object,
    isAddressEdited: Boolean,
    beneficiaryBoundariesDataModel: Object,
  },
  methods: {
    editAddress: function () {
      this.$emit("edit-address")
    },
   addressEditFormCancelled() {
      this.$emit("address-edit-form-cancelled");
    },
    reloadPage() {
      this.$emit("reload-page");
    },
  }
};
</script>
<template id="sidebar-item-component">
    <li  v-if="!isHidden" :class="hiddenClass">
        <router-link :to="navUrlAction" :title="label" :class="[activeClass,linkClasses]">
            <i :class="[iconStyle, icon, 'fa']"></i>
            <span :data-localize="concatenateLocalization()" class="navigation-text">{{ label }}</span>
        </router-link>
    </li>
</template>

<script>

export default {
    props: {
        name: String,
        hiddenClass: String,
        urlAction: String,
        localization: String,
        isHidden: {
            type: Boolean,
            default: false
        },
        showSpinner: Boolean,
        icon: String,
        iconType: String,
        label: String,
        controllerMatch: Array
    },
    data() {
        return {
            iconStyle: this.iconType ? this.iconType : "fa",
            linkClasses: {
                "show-spinner": this.showSpinner
            },
            currentPath: window.location.pathname.toLowerCase(),
        }
    },
    watch: {
        $route(to, from) {
            this.currentPath = window.location.pathname.toLowerCase();
        }
    },
    computed: {
        activeClass() {
            return this.controllerMatch && this.controllerMatch.some(el => this.controllerName == el) ? "active-sidebar-item" : "";
        },
        controllerName() {
            var params = this.currentPath.split("/");

            if(params.length < 2) {
                return null;
            }
            
            return params[1];
        },
        navUrlAction() {
            return this.urlAction ? this.urlAction : this.name;
        }
    },
    methods: {
        concatenateLocalization: function () {
            return `sidebar.nav.${this.localization ? this.localization : this.label}`;
        }
    }
};
</script>
import { render, staticRenderFns } from "./financial-transfer-settings-table.component.vue?vue&type=template&id=5a3675d6&"
import script from "./financial-transfer-settings-table.component.vue?vue&type=script&lang=js&"
export * from "./financial-transfer-settings-table.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Monidee-Test\\A3\\_work\\1\\s\\Monidee.tOption\\Monidee.tOption.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a3675d6')) {
      api.createRecord('5a3675d6', component.options)
    } else {
      api.reload('5a3675d6', component.options)
    }
    module.hot.accept("./financial-transfer-settings-table.component.vue?vue&type=template&id=5a3675d6&", function () {
      api.rerender('5a3675d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Features/Beneficiaries/Assets/Vue/Financial/financial-transfer-settings-table.component.vue"
export default component.exports
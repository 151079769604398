var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { attrs: { id: "mainContent" } }, [
    _c("div", { staticClass: "login-image-box" }),
    _vm._v(" "),
    _c("div", { staticClass: "login-box" }, [_c("LoginSso")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message.attachments && _vm.message.attachments.length > 0
    ? _c("div", { staticClass: "email-attachment" }, [
        _c(
          "ul",
          { staticClass: "list-group" },
          _vm._l(_vm.message.attachments, function(attachement) {
            return _c(
              "li",
              { key: attachement.documentId, staticClass: "list-group-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "card-link",
                    attrs: {
                      target: "_blank",
                      rel: "noopener",
                      href: _vm.$sanitize(_vm.getAttachmentLink(attachement)),
                      download: ""
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Attachment: " + _vm._s(attachement.documentName) + " "
                      )
                    ]),
                    _vm._v("   \n                "),
                    _c("i", {
                      staticClass: "far fa-download",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.country,
          label: _vm.dictionary.country
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.nationalNumber,
          label: _vm.dictionary.residentNumber
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.legalDocumentType,
          label: _vm.dictionary.legalDocumentType
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.legalDocumentNumber,
          label: _vm.dictionary.legalDocumentNumber
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.startDate,
          label: _vm.dictionary.issueDate,
          "display-value": _vm._f("toDate")(
            _vm.legalNationalityDataModel.startDate
          )
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.endDate,
          label: _vm.dictionary.expiryDate,
          "display-value": _vm._f("toDate")(
            _vm.legalNationalityDataModel.endDate
          )
        }
      }),
      _vm._v(" "),
      _c("beneficiary-true-false-label", {
        attrs: {
          value: _vm.legalNationalityDataModel.isResident,
          label: _vm.dictionary.isResident
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
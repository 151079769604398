<template>
    <div id="recaptcha-field" ></div>
</template>

<script>
    import { get} from "api";
    
    export default {
        beforeCreate() {
            get(
                "/login/GetRecaptchaSiteKey"
            , (responses) => {
                grecaptcha.render('recaptcha-field', { 'sitekey': responses.data});
            }, this);
        }
    }
</script>
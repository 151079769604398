var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("beneficiary-data-label", {
        attrs: { value: _vm.companyDataModel.name, label: _vm.dictionary.name }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: {
          value: _vm.companyDataModel.registrationNumber,
          label: _vm.dictionary.registrationNumber
        }
      }),
      _vm._v(" "),
      _c("beneficiary-data-label", {
        attrs: { value: _vm.companyDataModel.rsin, label: _vm.dictionary.rsin }
      }),
      _vm._v(" "),
      _vm.isLEIVisible
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.companyDataModel.lei,
              label: _vm.dictionary.lei
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("BeneficiaryDataLabel", {
        staticClass: "col-md-6",
        attrs: {
          value: _vm.bankAccount.iban,
          "display-value": _vm.displayValueForAccountNumber,
          label: _vm.financialDictionary.accountNumber
        }
      }),
      _vm._v(" "),
      _c("BeneficiaryDataLabel", {
        staticClass: "col-md-6",
        attrs: {
          value: _vm.bankAccount.bankName,
          label: _vm.financialDictionary.bankName
        }
      }),
      _vm._v(" "),
      _c("BeneficiaryDataLabel", {
        staticClass: "col-md-6",
        attrs: {
          value: _vm.bankAccount.currency.name,
          label: _vm.financialDictionary.currency
        }
      }),
      _vm._v(" "),
      _c("BeneficiaryDataLabel", {
        staticClass: "col-md-6",
        attrs: {
          value: _vm.bankAccount.country.name,
          label: _vm.financialDictionary.country
        }
      }),
      _vm._v(" "),
      _vm.showAccountRelationship
        ? _c("BeneficiaryDataLabel", {
            staticClass: "col-md-6",
            attrs: {
              value: _vm.bankAccount.relationType,
              label: _vm.financialDictionary.relationType
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUsedInPlanAs
        ? _c("BeneficiaryDataLabel", {
            staticClass: "col-md-6",
            attrs: {
              value: _vm.bankAccount.usedInPlanAs,
              label: _vm.financialDictionary.accountType
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("BeneficiaryDataLabel", {
        staticClass: "col-md-6",
        attrs: {
          value: _vm.bankAccount.bic,
          label: _vm.financialDictionary.bic
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
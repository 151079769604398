<template>
    <div>
        <div :class="['orders-listitem-container', { 'd-none': isEdit }]">
            <dt>{{ dictionary.confirmation.ordered }}</dt>
            <dd>
                <span>{{ model.nrOrderedFormatted }}</span>
                <a href="javascript:void(0)" :class="['btn-link', 'ml-1', { 'd-none': !isActive }]" @click="editQuantity" role="button">
                    <i class="far fa-edit" aria-hidden="true"></i>
                    <span class="sr-only">{{ dictionary.confirmation.quantitySRText }}</span>
                </a>
            </dd>
        </div>
        <div class="mb-4">
            <div :class="[{ 'd-none': !isEdit }]">
               <label for="newQuantity" class="font-weight-bold form-input-label">{{ dictionary.confirmation.ordered }}</label>
               <div class="form-group form-inline">
                   <div class="form-group mr-3">
                       <input v-model="newQuantity" type="number" id="newQuantity" class="form-control" />
                   </div>
                   <button class="btn secondaryButton" @click="cancelQuantityEdit">{{ dictionary.form.cancel }}</button>
                   <button class="btn primaryButton" @click="submitNewQuantity">{{ dictionary.form.submit }}</button>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
            exitInstructionViewModel: Object,
            isActive: Boolean
        },
        watch: {
            isHide: function (newVal, oldVal) {
                if (newVal) {
                    this.cancelQuantityEdit();
                }
            }
        },
        data() {
            return {
                isEdit: false,
                newQuantity: 0
            };
        },
        computed: {
            model() {
                return this.exitInstructionViewModel;
            }
        },
        methods: {
            editQuantity() {
                this.newQuantity = this.model.nrOrdered;
                this.isEdit = true;
            },
            submitNewQuantity() {
                this.$emit('accept-new-quantity', this.newQuantity);
                this.isEdit = false;
            },
            cancelQuantityEdit() {
                this.isEdit = false;
            }
        }
    };
</script>
<template>
    <div class="d-md-flex justify-content-md-between button-container">
        <div>
            <button class="btn secondaryButton" @click="declineInstruction">{{ dictionary.btnDecline }}</button>
        </div>
        <div>
            <button class="btn primaryButton ml-0" :disabled="!isDisclaimersAccepted" @click="acceptInstruction" :title="confirmButtonTitle">{{ dictionary.btnConfirm }}</button>
        </div>
    </div>
</template>

<script>
    import { get } from "api";

    export default {
        props: {
            exercisesInstructionModel: Object
        },
        data() {
            return {
                dictionary: {}
            };
        },
        computed: {
            model() {
                return this.exercisesInstructionModel;
            },
            isDisclaimersAccepted() {
                return this.model.disclaimers.every(x => x.confirmed);
            },
            confirmButtonTitle() {
                return this.isDisclaimersAccepted
                    ? ""
                    : this.dictionary.btnConfirmTitleAcceptDisclaimer;
            }
        },
        methods: {
            acceptInstruction() {
                this.$emit("accept-instruction");
            },
            declineInstruction() {
                this.$emit("decline-instruction");
            }
        },
        beforeCreate() {
            get(
                "/Common/Dictionary",
                (response) => (this.dictionary = response.data),
                this
            );
        }
    };
</script>
<template>
    <div v-if="datatablesDictionary" id="required-documents-table-container">
        <v-data-table class="table-striped"
                      item-key="RequiredDocumentId"
                      :no-data-text="dictionary.labels.noRecordRequiredDocuments"
                      :hide-default-header="isMobile"
                      :headers="computedHeaders"
                      :items="rows"
                      :options.sync="options"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      hide-default-footer
                      :search="search"
                      >

            <template v-slot:[`item.expiryDate`]="{ item }">
                    {{ item.expiryDate | toDate }}
            </template>

            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <RequiredDocumentsDetailsMobileContent :dictionary="dictionary" :required-document="item" v-on="$listeners" />
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <button class="btn primaryButton" role="button" @click="$emit('action', item.requiredDocumentId, item.requiredDocumentParticipationId)">
                    {{ getActionButtonText(item, dictionary) }}
                </button>
            </template>

        </v-data-table>

    </div>
</template>

<script>
    import { vueDataTableMixin } from "Shared/Mixins/vue-data-table.mixin";
    import RequiredDocumentsDetailsMobileContent from "./required-documents-details-mobile-content.component.vue";
    import { requiredDocumentsMethods } from "./mixins/required-documents.mixin.js";

    export default {
        mixins: [ vueDataTableMixin, requiredDocumentsMethods ],
        components: {
            RequiredDocumentsDetailsMobileContent,
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            getUrl: String
        },
        data() {
            return {
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                options: {
                    sortBy: ["name"],
                    sortDesc: [true],
                },
                rows: [],
                totalRecords: 0,
            };
        },
        created() {
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        computed:{
            computedHeaders() {
                let headers = [
                    { value: "name", text: this.dictionary.table.documentName, align: "left", sortable: true },
                    { value: "expiryDate", text: this.dictionary.table.expiryDate, align: "left", sortable: true },
                    { value: "stateDescription", text: this.dictionary.table.documentState, align: "left", sortable: true },
                    { value: "action", text: "", align: "left", sortable: false, cellClass: "wd-xxs" }
                ];
                
                if (!this.rows.some(x => x.expiryDate))
                    return headers.filter(x => x.value != 'expiryDate');
                return headers;  
            }
        },
        methods: {
            handleResize() {
                this.isTablet =
                    window.innerWidth <= this.$windowSizes.TABLET &&
                    window.innerWidth > this.$windowSizes.MOBILE;
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            },
        },
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mfa-pac" }, [
    _c("div", { staticClass: "mfa-pac-body" }, [
      _c("p", [
        _c("span", {
          staticClass: "rich-text-container",
          domProps: {
            innerHTML: _vm._s(
              _vm.$sanitize(_vm.dictionary.beneficiaryAlertMFALostPhone)
            )
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-block mt-3 secondaryButton",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("cancel")
            }
          }
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.dictionary.buttonMfaCancel) +
              "\n        "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.position.showVestings
    ? _c(
        "div",
        { staticClass: "vesting-column text-right" },
        [
          _vm.toggles.hideVestingsTooltip
            ? _c("span", { staticClass: "vesting-column__hide-tooltip" }, [
                _vm._v(_vm._s(_vm.position.grantedFormatted))
              ])
            : _c(
                "span",
                {
                  staticClass: "vesting-column__show-tooltip",
                  attrs: { id: _vm.spanId }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.position.grantedFormatted) + " "
                  ),
                  _c("i", {
                    staticClass: "far fa-info-circle popover-info",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
          _vm._v(" "),
          !_vm.toggles.hideVestingsTooltip
            ? _c(
                "b-popover",
                {
                  staticClass: "vesting-column__popover",
                  attrs: {
                    target: _vm.spanId,
                    triggers: "hover",
                    placement: "right"
                  }
                },
                [
                  _c("VestingPopover", {
                    attrs: {
                      position: _vm.position,
                      dictionary: _vm.dictionary,
                      toggles: _vm.toggles
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exit-order-buttons button-container" }, [
    _vm.isModal || _vm.hasRestrictedSecurityAccountSettings
      ? _c("div", { staticClass: "exit-order-buttons__cancel-button" }, [
          _c(
            "button",
            {
              staticClass: "btn secondaryButton",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.hasRestrictedSecurityAccountSettings
                    ? _vm.$router.back()
                    : _vm.$emit("closed")
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.commonDictionary.btnCancel) +
                  "\n        "
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "exit-order-buttons__submit-button" }, [
      _c(
        "button",
        {
          staticClass: "btn primaryButton",
          attrs: {
            type: "button",
            disabled:
              _vm.isButtonDisabled ||
              _vm.isInvalid ||
              _vm.hasRestrictedSecurityAccountSettings
          },
          on: {
            click: function($event) {
              return _vm.$emit("order-submitted")
            }
          }
        },
        [_vm._v("\n            " + _vm._s(_vm.buttonText) + "\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
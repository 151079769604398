var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vestings" },
    [
      _c("div", {
        staticClass: "page-description rich-text-container",
        domProps: {
          innerHTML: _vm._s(
            _vm.$sanitize(_vm.dictionary.page.vestingsPageDescription)
          )
        }
      }),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "show-expand": _vm.showDetailsColumn,
          "single-expand": "",
          "item-key": "id",
          "no-data-text": _vm.dictionary.table.noRecordFound,
          "hide-default-header": _vm.isMobile,
          headers: _vm.filteredHeaders,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._v(" "),
                  _c("VestingsQueryForm", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      vestingsOptions: _vm.vestingsOptions
                    },
                    on: {
                      onShowButton: _vm.showButton,
                      onDownloadButton: _vm.downloadButton
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "header.sharePrice",
              fn: function(ref) {
                var header = ref.header
                return [
                  _vm._v("\n      " + _vm._s(header.text) + "\n      "),
                  _c("i", {
                    staticClass: "far fa-info-circle popover-info",
                    attrs: { id: "sharePriceInfo", "aria-hidden": "true" }
                  })
                ]
              }
            },
            {
              key: "header.marketValue",
              fn: function(ref) {
                var header = ref.header
                return [
                  _vm._v("\n      " + _vm._s(header.text) + "\n      "),
                  _c("i", {
                    staticClass: "far fa-info-circle popover-info",
                    attrs: { id: "marketValueInfo", "aria-hidden": "true" }
                  })
                ]
              }
            },
            {
              key: "item.sharePrice",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.replaceNullOrEmpty(item.sharePrice)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.marketValue",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.replaceNullOrEmpty(item.marketValue)) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticClass: "expanded-item-td",
                      attrs: { colspan: headers.length }
                    },
                    [
                      _c("VestingTableExpandedRows", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          vesting: item,
                          toggles: _vm.toggles
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.data-table-expand",
              fn: function(ref) {
                var item = ref.item
                var isExpanded = ref.isExpanded
                var expand = ref.expand
                return [
                  item.hasPurchase ||
                  _vm.hasAnyModifications(item) ||
                  _vm.toggles.showVestingTaxDetails
                    ? _c(
                        "v-icon",
                        {
                          class: [
                            "v-data-table__expand-icon",
                            { "v-data-table__expand-icon--active": isExpanded }
                          ],
                          attrs: {
                            "aria-label":
                              item.planName +
                              " " +
                              (isExpanded ? "collapse" : "expand") +
                              " more details"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return expand(!isExpanded)
                            }
                          }
                        },
                        [_vm._v("$expand")]
                      )
                    : _vm._e()
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.sortableColumns,
                          dictionary: _vm.datatablesDictionary
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      }),
                      _vm._v(" "),
                      _c("VestingsStateInfo", {
                        attrs: { dictionary: _vm.dictionary }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("VestingDetailsMobileContent", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          item: item,
                          toggles: _vm.toggles
                        }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "sharePriceInfo",
            triggers: "hover",
            placement: "bottom"
          }
        },
        [_c("span", [_vm._v(_vm._s(_vm.dictionary.table.popupSharePrice))])]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "marketValueInfo",
            triggers: "hover",
            placement: "bottom"
          }
        },
        [_c("span", [_vm._v(_vm._s(_vm.dictionary.table.popupMarketValue))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
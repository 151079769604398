<template>
    <div v-if="isEditable" class="beneficiary-details form-group">
        <label :for="name" class="form-input-label">{{ label }}</label>
        <div class="input-group">
          <select :id="name" :name="name" class="form-control" v-model="selectValue" @change="handleInput">
              <option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">
                  {{ option.text }}
              </option>
          </select>
        </div>
        <span class="beneficiary-edit-group__error-label" v-show="getErrorKey()">{{ getErrorMessage() }}</span>
    </div>
</template>

<script>
export default {
  props: {
    value: String | Number,
    label: String,
    name:String,
    isEditable: Boolean,
    errorKeys: Array,
    validations: Array,
    options: Array
  },
  methods: {
    getErrorKey: function () {
      return this.errorKeys
        ? this.errorKeys.filter(
            (x) => x.toLowerCase() === this.name.toLowerCase()
          )[0]
        : null;
    },
    getErrorMessage: function () {
      return this.validations
        ? this.validations
            .filter((x) => x.key.toLowerCase() === this.name.toLowerCase())
            .map((x) => x.errorMessage)[0]
        : null;
    },
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
  },
  computed:{
    selectValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading && !_vm.isModalVisible
        ? _c("component-spinner", { attrs: { spin: _vm.isLoading } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.isModalVisible
        ? _c(
            "div",
            [
              _vm.canBeCancelledByBeneficiary
                ? _c(
                    "button",
                    {
                      staticClass: "btn secondaryButton",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.openCancelDialog()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\r\n                                " +
                          _vm._s(_vm.dictionary.ordersPageBtnLabelCancelOrder) +
                          "\r\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("OrdersDetailsContentRowHeader", {
                attrs: { label: _vm.dictionary.exitsModalHeadingBasicData }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRow", {
                attrs: {
                  label: _vm.dictionary.instructionDate,
                  value: _vm.item.submissionDate
                }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRow", {
                attrs: {
                  label: _vm.dictionary.reference,
                  value: _vm.item.reference
                }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRow", {
                attrs: {
                  label: _vm.dictionary.instruction,
                  value: _vm.item.orderTypeDescription
                }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRow", {
                attrs: { label: _vm.dictionary.state, value: _vm.item.state }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRow", {
                attrs: {
                  label: _vm.dictionary.positionName,
                  value: _vm.item.productName
                }
              }),
              _vm._v(" "),
              _c("OrdersDetailsContentRowHeader", {
                attrs: { label: _vm.dictionary.exitsModalHeadingOrderDetails }
              }),
              _vm._v(" "),
              !_vm.isLoading
                ? _c(
                    _vm.transactionTypeModalElement,
                    _vm._b(
                      { tag: "component" },
                      "component",
                      {
                        "instruction-data": _vm.instructionData,
                        dictionary: _vm.detailsDictionary,
                        toggles: _vm.toggles
                      },
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ConfirmCancelOrderMobile", {
        attrs: {
          isModalVisible: _vm.isModalVisible,
          instructionData: _vm.instructionData,
          dictionary: _vm.dictionary,
          title: _vm.dictionary.ordersPageCancelConfirmation
        },
        on: { closeCancelDialog: _vm.closeCancelDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dictionary
      ? _c("div", [
          _c("div", { staticClass: "title-block" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.dictionary.page.titleConfirmSubscribe))
            ])
          ]),
          _vm._v(" "),
          _vm.participationSubscription
            ? _c("div", { staticClass: "portlets-wrapper" }, [
                _c("div", { staticClass: "portlet-container" }, [
                  _c(
                    "div",
                    { staticClass: "row m-0" },
                    [
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-lg-8 col-xl-9",
                            title:
                              _vm.dictionary.widget.headerSubscriptionCompleted
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c(
                                "alert-component",
                                { attrs: { variant: "warning" } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.widget
                                          .textSubscriptionCompletedDescription
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("SubscriptionConfirmationDetails", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  participationSubscription:
                                    _vm.participationSubscription,
                                  isSubscriptions: _vm.isSubscriptions
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "buttonContainer mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-content-end"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn primaryButton",
                                          on: {
                                            click: function($event) {
                                              return _vm.next()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dictionary.widget.btnContinue
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "portlet-layout",
                        {
                          attrs: {
                            "column-class": "col-lg-4 col-xl-3",
                            title: _vm.dictionary.widget.titleSubscriptionInfo
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c("SubscriptionInfo", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  participationSubscription:
                                    _vm.participationSubscription
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
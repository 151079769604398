<template>
  <div class="fiscal-nationality-item">
    <div class="row">
      <div class="col-md-6">
        <select-element
          v-model="fiscalNationality.countryId"
          :label="dictionary.labels.countryOfTaxResidency"
          :options="countries"
          :name="`countryId_${componentId}`"
          :validationsList="validations.countryId"
          :is-mandatory="true"
          :tool-tip-text="dictionary.tooltips.countryOfTaxResidency"
        >
        </select-element>
      </div>
      <div class="col-md-6">
        <input-element
          :name="`fiscalNumber_${componentId}`"
          v-model="fiscalNationality.fiscalNumber"
          :label="dictionary.labels.tiNorNI"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <datepicker-element
          :id="`startDate_${componentId}`"
          :name="`startDate_${componentId}`"
          v-model="fiscalNationality.startDate"
          :label="dictionary.labels.startDate"
          :validationsList="validations.startDate"
          :is-mandatory="true"
          :year-range="'-150:+0'"
        />
      </div>
      <div class="col-md-6">
        <datepicker-element
          :id="`endDate_${componentId}`"
          :name="`endDate_${componentId}`"
          v-model="fiscalNationality.endDate"
          :label="dictionary.labels.endDate"
          :validationsList="validations.endDate"
          :commonDictionary="dictionary.common"
          :show-button-panel="true"
          :year-range="'-150:+10'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <button @click="deleteItem" class="btn-link text-danger delete-tax-nationality"><i class="far fa-trash" aria-hidden="true"></i> <span class="sr-only">{{ dictionary.buttons.removeTaxResidency }}</span> {{ dictionary.buttons.removeTaxResidency }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  components: {
  },
  props: {
    dictionary: Object,
    value: Object,
    countries: Array,
    validations: Object
  },
  data(){
    return {
      fiscalNationality: this.value
    }
  },
  methods: {
    deleteItem(){
      this.$emit('delete-item');
    }
  },
  computed:{
    componentId() {
      return uuid();
    },
  },
  watch: {
    fiscalNationality: {
      handler: function (val, oldVal) {
        this.$emit('input', val);
      },
      deep: true
    }
  }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-container" },
    [
      _c("QuestionDetail", {
        attrs: {
          currentQuestion: _vm.currentQuestion,
          questionIndex: _vm.questionIndex,
          showCorrectAnswer: _vm.showCorrectAnswer,
          dictionary: _vm.dictionary,
          v: _vm.v
        },
        on: { "change-answer": _vm.handleChangeAnswer }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
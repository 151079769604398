var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-row" }, [
    _c("div", { staticClass: "title-block" }, [
      _vm.pagesDictionary
        ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.document.title))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-bar", attrs: { id: "documents-vm" } },
      [
        _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
        _vm._v(" "),
        _vm.dictionary && _vm.dictionaryPersonalDocuments
          ? _c("Documents", {
              attrs: {
                "dictionary-documents": _vm.dictionary,
                "dictionary-personal-documents":
                  _vm.dictionaryPersonalDocuments,
                toggles: _vm.toggles,
                plans: _vm.plans
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
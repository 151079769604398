<template>
    <router-view :dictionary="dictionary" :toggles="toggles" :orders-dictionary="ordersDictionary"></router-view>
</template>

<script>
    import { getAll } from 'api';

    export default {
        data() {
            return {
                dictionary: null,
                ordersDictionary: null,
                toggles: {},
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                "/Buy/Dictionary",
                "/Buy/Toggles",
                "/Orders/Dictionary"
            ], (responses) => {
                this.dictionary = responses[0].data;
                this.toggles = responses[1].data;
                this.ordersDictionary = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false;
            }, this);
        }
    };
</script>

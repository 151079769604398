import Offerings from "./offerings.component.vue";
import OfferingsContainer from "./offerings-container.component.vue";
import SubscribePage from "/Features/Subscriptions/Assets/Vue/Shared/subscribe-page.component.vue";
import SubscriptionConfirmation from "/Features/Subscriptions/Assets/Vue/Shared/subscription-confirmation.component.vue";
import SubscriptionConfirmationSuccess from "/Features/Subscriptions/Assets/Vue/Shared/subscription-confirmation-success.component.vue"
import ExecuteContribution from "./execute-contribution.component.vue"

export const offeringsRoutes = {
    path: '/offerings',
    component: OfferingsContainer,
    children: [
        {
            path: '',
            component: Offerings
        },
        {
            path: 'subscribe',
            component: SubscribePage
        },
        {
            path: 'confirmation',
            name: 'offerings-confirmation',
            component: SubscriptionConfirmation,
            props: true
        },
        {
            path: 'confirmation/success',
            name: 'offerings-confirmation-success',
            component: SubscriptionConfirmationSuccess,
            props: true
        },
        {
            path: 'execute/contribution',
            component: ExecuteContribution
        },
        {
            path: '*',
            redirect: '/offerings'
        }
    ]
}
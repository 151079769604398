var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c(
        "router-link",
        {
          attrs: {
            id: "portfolio-overview",
            to: "/Reports/PortfolioOverview",
            role: "button"
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "title-action-icon",
              attrs: { "aria-hidden": "true" }
            },
            [_c("i", { staticClass: "far fa-calculator" })]
          ),
          _vm._v(_vm._s(_vm.dictionary.page.portfolioBtnPORCalculatorDesktop))
        ]
      )
    : _vm.isMobile
    ? _c(
        "router-link",
        {
          attrs: {
            id: "portfolio-overview",
            to: "/Reports/PortfolioOverview",
            role: "button"
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "title-action-icon",
              attrs: { "aria-hidden": "true" }
            },
            [_c("i", { staticClass: "far fa-calculator" })]
          ),
          _vm._v(_vm._s(_vm.dictionary.page.portfolioBtnPORCalculatorMobile))
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
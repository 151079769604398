var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("disclaimers", {
          attrs: { disclaimers: _vm.choiceInstructionModel.disclaimers }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-2" },
      [
        _c("ChoiceInstructionButtonsComponent", {
          attrs: {
            validation: _vm.v,
            choiceInstructionModel: _vm.choiceInstructionModel
          },
          on: {
            "accept-instruction": _vm.acceptInstruction,
            "decline-instruction": _vm.declineInstruction
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
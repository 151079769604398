var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { id: _vm.formName, method: "post" } },
    [
      _c(
        _vm.childComponent,
        _vm._b(
          {
            tag: "component",
            on: {
              "cancel-form": function($event) {
                return _vm.cancelForm()
              },
              "submit-form": function($event) {
                return _vm.submitForm()
              }
            }
          },
          "component",
          {
            "edit-model": _vm.editModel,
            dictionary: _vm.accountDictionary,
            commonDictionary: _vm.commonDictionary
          },
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
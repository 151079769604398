<template>
    <component-spinner v-if="isLoadingData" :spin="isLoadingData"></component-spinner>
    <div v-else>
        <div class="title-block">
            <h1>{{dictionary.page.titleSubscribe}}</h1>
        </div>
        <div v-if="participationSubscription" class="portlets-wrapper">
            <div class="portlet-container">
                <div class="row m-0">
                    <portlet-layout column-class="col-lg-8 col-xl-9" :title="participationSubscription.name" >
                        <template slot="body">
                            <div v-for="scenario in participationSubscription.availableScenarios" :key="scenario.participationSubscriptionScenarioId">
                                <SubscriptionForm
                                    :dictionary="dictionary"
                                    :subscriptionScenario="scenario"
                                    :isSubscriptions="isSubscriptions"
                                    :participationSubscription="participationSubscription" />
                            </div>
                        </template> 
                    </portlet-layout>
                    <portlet-layout column-class="col-lg-4 col-xl-3" :title="dictionary.widget.titleSubscriptionInfo" >
                        <template slot="body">
                            <SubscriptionInfo :dictionary="dictionary" :participationSubscription="participationSubscription" />
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SubscriptionForm from "./subscription-form.component.vue";
import SubscriptionInfo from "./subscription-info.component.vue";
import { get, post } from "api";
import numeral from 'numeral';

export default {
    components: {
        SubscriptionForm,
        SubscriptionInfo
    },
    props: {
        dictionary: Object,
        toggles: Object,
        isSubscriptions: Boolean,
    },
    data() {
        return {
            participationSubscription: null,
            isLoadingData: true
        }
    },
    beforeCreate() {
        let query = this.$route.query;

        get(`/Subscriptions/ParticipationSubscription?participationSubscriptionId=${encodeURIComponent(query.participationSubscriptionId)}`,
            (response) => {
                const shouldSkipSubscriptionForm = this.handleRedirecting(response.data);
                if (shouldSkipSubscriptionForm) {
                    return;
                }
                this.isLoadingData = false;
                this.participationSubscription = response.data;
            },
            this,
            () => {},
            () => {
                this.isLoadingData = false;
            }
        );
    },
    methods: {
        handleRedirecting(subscription) {
            this.isLoadingData = true;
            const singleScenario = 
                subscription.availableScenarios.length == 1 ?
                subscription.availableScenarios[0].offers : null;
            if (!singleScenario) {
                return;
            };
            var isMaximumAmountEqualToMinimumForAllOffers = true;
            subscription.availableScenarios.forEach((scenario) => {
                scenario.offers.forEach((offer) => {
                    if (!(offer.minimumAwardedAmount === offer.maximumAwardedAmount)) 
                        isMaximumAmountEqualToMinimumForAllOffers = false
                })
            })
            const shouldSkipConfirmationStep = 
                this.isSubscriptions &&
                subscription.presentationMode.value == 'spmAcceptation' &&
                isMaximumAmountEqualToMinimumForAllOffers
            if (!shouldSkipConfirmationStep) {
                return;
            }
            const subscribeSubscribtion = {
                participationSubscriptionScenarioId: subscription.availableScenarios[0].participationSubscriptionScenarioId,
                offers: 
                    singleScenario.map((offer) => {
                        return {
                            participationSubscriptionOfferId: offer.participationSubscriptionOfferId,
                            amount: numeral(this.$setDecimalSeparatorBySettings(offer.maximumAwardedAmount)).value()
                        }
                    })
                
            };
            post('/Subscriptions/Subscribe',
                subscribeSubscribtion,
                (response) => {
                    this.$router.push({
                        name: 'subscriptions-confirmation',
                        params: {
                            confirmationData: response.data,
                            isFormStepSkipped: true
                        }
                    });
                },
                this
            );
            return true;
        }
    }
};
</script>
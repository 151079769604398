var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredInfoModel
    ? _c("required-info-step", {
        attrs: {
          id: "personal-info-container",
          currentStep: _vm.currentStep,
          step: _vm.step,
          steps: _vm.steps,
          summaryType: _vm.summaryType,
          heading: _vm.dictionary.labels.step1,
          description: _vm.dictionary.labels.step1Description,
          dictionary: _vm.dictionary
        },
        on: {
          "edit-step": function(val) {
            return _vm.$emit("edit-step", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "previous",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("RequiredInfoDataLabelRow", {
                        staticClass: "col-md-6",
                        attrs: {
                          value: _vm.requiredInfoModel.firstNames,
                          label: _vm.dictionary.labels.firstNames,
                          isMandatory: true
                        }
                      }),
                      _vm._v(" "),
                      _c("RequiredInfoDataLabelRow", {
                        staticClass: "col-md-6",
                        attrs: {
                          value: _vm.requiredInfoModel.lastName,
                          label: _vm.dictionary.labels.lastName,
                          isMandatory: true
                        }
                      }),
                      _vm._v(" "),
                      _c("RequiredInfoDataLabelRow", {
                        staticClass: "col-md-6",
                        attrs: {
                          value: _vm._f("toDate")(
                            _vm.requiredInfoModel.dateOfBirth
                          ),
                          label: _vm.dictionary.labels.dateOfBirth,
                          isMandatory: true
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "current",
              fn: function() {
                return [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "firstNames",
                            label: _vm.dictionary.labels.firstNames,
                            validationsList:
                              _vm.accountholderValidations.firstNames,
                            readonly: !_vm.boundaries.mayEditFirstNames,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.firstNames,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "firstNames", $$v)
                            },
                            expression: "requiredInfoModel.firstNames"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "lastName",
                            label: _vm.dictionary.labels.lastName,
                            validationsList:
                              _vm.accountholderValidations.lastName,
                            readonly: !_vm.boundaries.mayEditLastName,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.lastName,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "lastName", $$v)
                            },
                            expression: "requiredInfoModel.lastName"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("datepicker-element", {
                          attrs: {
                            id: "personal-date-of-birth",
                            label: _vm.dictionary.labels.dateOfBirth,
                            validationsList:
                              _vm.accountholderValidations.dateOfBirth,
                            readonly: !_vm.boundaries.mayEditDateOfBirth,
                            "is-mandatory": true,
                            yearRange: "-150:+0",
                            readonlyLabel:
                              _vm.dictionary.accessibility.readonlyLabel
                          },
                          model: {
                            value: _vm.requiredInfoModel.dateOfBirth,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "dateOfBirth",
                                $$v
                              )
                            },
                            expression: "requiredInfoModel.dateOfBirth"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-md-flex justify-content-md-between button-container"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn primaryButton",
                          attrs: {
                            type: "button",
                            "aria-describedby": _vm.nextStepAccessibilityLabel(
                              _vm.step,
                              _vm.steps
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.nextStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dictionary.buttons.next) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2271696149
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
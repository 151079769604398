<template>
    <div>
        <div class="title-block single-line">
            <h1 v-if="moneyTransferPosition">{{ moneyTransferPosition.name }}</h1>
        </div>
        <div class="portlets-wrapper">
            <component-spinner :spin="isLoading"></component-spinner>
            <div v-if="moneyTransferPosition && dictionary" class="row m-0">
                <div class="portlet-container col-md-8 col-xl-9 p-0">
                    <div class="row m-0">
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalAvailable" :text="moneyTransferPosition.availableFormatted" />
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <total-block :title="dictionary.blocks.totalValue" :text="getValueFormatted(moneyTransferPosition, toggles)"></total-block>
                            </template>
                        </portlet-layout>
                        <portlet-layout column-class="col-xl col-lg-12" :hideTitle="true">
                            <template slot="body">
                                <security-price-block :title="dictionary.blocks.lastPrice" :endpoint="lastPriceEndpoint" />
                            </template>
                        </portlet-layout>
                    </div>
                    <div class="row m-0">
                        <MoneyTransferPlaceOrder :moneyTransferPosition="moneyTransferPosition" :dictionary="dictionary" />
                    </div>
                </div>
                <div class="col-md-4 col-xl-3 p-0">
                    <portlet-layout v-if="!toggles.hideLastTransactionsLinkBlock" column-class="block" :title="dictionary.widgets.lastTransactions">
                        <template slot="body">
                            <last-transactions :positionUsageId="moneyTransferPosition.positionUsageId" />
                        </template>
                        <template slot="footerLink">
                            <router-link class="portlet-title-see-all-link" to="/transactions" :title="dictionary.widgets.seeAllTransactions">{{dictionary.widgets.seeAllTransactions}}</router-link>
                        </template>
                    </portlet-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'api';
import MoneyTransferPlaceOrder from "./money-transfer-place-order.component.vue";
import { positionMethods } from 'Shared/Mixins/position-methods.mixin.js';

    export default {
  mixins: [ positionMethods ],
  components: {
    MoneyTransferPlaceOrder,
  },
  props: {
    dictionary: Object,
    toggles: Object,
  },
  data() {
      return {
          moneyTransferPosition: null,
          error: null,
          isLoading: true,
          isSubmitting: false
      };
  },
  computed: {
      lastPriceEndpoint(){
          let query = this.$route.query;
          return `/InternalExchanges/GetLastPriceBlock?positionUsageId=${query.positionUsageId}`;
      }
  },
  beforeCreate() {
    let query = this.$route.query;
    get(
      `/MoneyTransfer/Position?sharePlanParticipationId=${query.sharePlanParticipationId}&positionUsageId=${query.positionUsageId}`,
        (response) => {
            this.moneyTransferPosition = response.data;
        },
        this,
        () => (this.isLoading = false)
    );
  }
};
</script>
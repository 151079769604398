<template>
    <div id="questionnaire-table-container">
        <v-data-table class="table-striped" 
                      item-key="questionnaireId"
                      :no-data-text="dictionary.labels.recordNotFound"
                      :hide-default-header="isMobile"
                      :headers="headers"
                      :items="rows"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer
                      >

            <!-- Search input at top --->
            <template v-slot:top>
                <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
            </template>

           <template v-slot:[`item.action`]="{ item }">
                <router-link class="btn primaryButton"  role="button" :to="{ name: 'questionnaire-detail', params: { questionnaireId: item.questionnaireId, returnUrl: '/Beneficiaries#evaluation' }}">{{item.canEdit ? dictionary.labels.modify : dictionary.labels.view }}</router-link>                
            </template>
            <template v-slot:[`item.isEligible`]="{ item }">
                <span>{{item.isEligible == null ? '-' : item.isEligible ? dictionary.labels.eligible :  dictionary.labels.notEligible }}</span>
             </template>

            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile" v-slot:item="{ item }">
                <QuestionnairesDetailsMobileContent :dictionary="dictionary" :questionnaire="item" :toggles="toggles" />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>

    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
    import QuestionnairesDetailsMobileContent from "./questionnaires-details-mobile-content.component.vue"
    export default {
        mixins: [vueDataTableMixin, ScreenSizeMixin],
        components: {
            QuestionnairesDetailsMobileContent
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            toggles: Object
        },
        data() {
            return {
                nonCriticalHeaders: [],
                getUrl: '/Questionnaire/GetQuestionnaires',
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["questionnaireName"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'action', text: this.dictionary.table.detailsColumn, align: 'left', sortable: false, cellClass: 'wd-xxs' },
                    { value: 'questionnaireName', text: this.dictionary.table.questionnaireName, align: 'left', sortable: true },
                    { value: 'isEligible', text: this.dictionary.table.result, align: 'center', sortable: true },
                    { value: 'nrQuestions', text: this.dictionary.table.nrOfQuestions, align: 'right', sortable: true },
                    { value: 'unansweredQuestions', text: this.dictionary.table.nrOfUnansweredQuestions, align: 'right', sortable: true },
                ],
                rows: [],
                totalRecords: 0
            }
        }
    }
</script>
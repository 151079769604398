<template>
<portlet-layout v-if="!!accountDataModel.beneficiaryCompany" :title="accountDictionary.widgets.companyInformation" :hideShadow="true">
    <template v-slot:body>
        <div>
            <CompanyView :company-data-model="accountDataModel.beneficiaryCompany" 
            :dictionary="accountDictionary.company" :beneficiary-boundaries="accountDataModel.beneficiaryBoundaries"></CompanyView>
        </div>
    </template>
</portlet-layout>
</template>

<script>
import CompanyView from "./company-view.component.vue";

export default {
  components: {
    CompanyView,
  },
  props: {
    accountDictionary: Object,
    accountDataModel: Object,
  },
};
</script>
<template>
    <Error v-if="isAuthenticated && !isLoading" :dictionary="dictionary" />
    <ErrorForUnauthenticatedUser v-else-if="!isLoading" :dictionary="dictionary" />
</template>

<script>
import { get } from 'api';
import Error from "./error.component.vue";
import ErrorForUnauthenticatedUser from "./error-for-unauthenticated-user.component.vue";

    export default {
    components: {
        Error,
        ErrorForUnauthenticatedUser
    },
    props: {
        isAuthenticated: Boolean,
    },
    data() {
        return{
            dictionary: null,
            isLoading: true
        }
    },
    beforeCreate() {
        get([
            "/Error/Dictionary",
        ], response => {
                this.dictionary = response.data;

                this.$setPageTitle(this.dictionary.errorPageTitle);
                this.isLoading = false;
        }, this);
    }
    }
</script>
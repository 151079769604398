<template>
    <div class="coupon-expanded-content">
      <div class="row">
          <div class="col-md-6 col-lg-4">
            <CouponDataLabelRow  :label="dictionary.table.deliveryInstructionReference" :value="coupon.deliveryInstructionReference" />  
            <CouponDataLabelRow  :label="dictionary.table.securityName" :value="coupon.securityName" />
          </div>
          <div class="col-md-6 col-lg-4">
            <CouponDataLabelRow  :label="dictionary.table.deliveryInstructionDate" :value="coupon.deliveryInstructionDate" />
          </div>
      </div>
    </div>
</template>

<script>

import CouponDataLabelRow from "./coupon-data-label-row.component.vue";

export default {

  components: {
    CouponDataLabelRow,
  },
  props: {
    coupon: Object,
    dictionary: Object,
    isTablet:Boolean
  },
};
</script>
import BuyContainer from "./buy-container.component.vue";
import Buy from "./buy.component.vue";
import BuyConfirmation from "./buy-confirmation.component.vue";


export const buyRoutes =
{
    path: '/buy',
    component: BuyContainer,
    children: [
        {
            path: '',
            component: Buy
        }, {
            path: 'confirmation',
            component: BuyConfirmation
        },
        {
            path: '*',
            redirect: '/buy'
        }
    ]
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-view", {
    attrs: {
      dictionary: _vm.dictionary,
      toggles: _vm.toggles,
      "orders-dictionary": _vm.ordersDictionary
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="payment-details payment-expanded-content">
    <div class="row">
      <div class="col-md-6">
        <PaymentDetailsExpandedRowDataLabel :label="dictionary.table.accountNumber" :value="replaceNullOrEmpty(payment.debitAccountNumber)" />
        <PaymentDetailsExpandedRowDataLabel :label="dictionary.table.bankName" :value="replaceNullOrEmpty(payment.debitAccountBankName)" />
        <PaymentDetailsExpandedRowDataLabel :label="dictionary.table.unconfirmedAmount" :value="replaceNullOrEmpty(payment.unconfirmedAmountFormatted)" />    
      </div>
      <div class="col-md-6">
        <PaymentDetailsExpandedRowDataLabel :label="dictionary.table.bankCountry" :value="replaceNullOrEmpty(payment.debitAccountBankCountryName)" />
        <PaymentDetailsExpandedRowDataLabel :label="dictionary.table.bic" :value="replaceNullOrEmpty(payment.debitAccountBIC)" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentDetailsExpandedRowDataLabel from "./payment-details-expanded-row-data-label.component.vue";
export default {
  components: {
    PaymentDetailsExpandedRowDataLabel
  },
  props: {
    payment: Object,
    dictionary: Object
  }
};
</script>
<template>
<div class="dividend-details-content">
    <DividendDataLabelRow :label="dictionary.table.distributionDate" :value="dividend.distributionDate" />
    <DividendDataLabelRow :label="dictionary.table.securityName" :value="dividend.securityName" />
    <DividendDataLabelRow :label="dictionary.table.dividendType" :value="dividendTypeTitle" />    
    <DividendDataLabelRow :label="dictionary.table.state" :value="dividend.state.description" />
    <DividendDataLabelRow :label="dictionary.table.nrShares" :value="dividend.nrSharesFormatted" />
    <DividendDataLabelRow :label="dictionary.table.grossAmount" :value="dividend.grossAmountFormatted" />
    <DividendDataLabelRow :label="dictionary.table.tax" :value="dividend.taxAmountFormatted" />
    <DividendDataLabelRow :label="dictionary.table.netAmount" :value="dividend.netAmountFormatted" />
    <DividendDataLabelRow v-if="!toggles.hideCashDividendColumn" :label="dictionary.table.amountToReceive" :value="dividend.amountToReceiveFormatted" />
    <DividendDataLabelRow v-if="!toggles.hideStockDividendColumn" :label="dictionary.table.netQuantity" :value="dividend.netQuantityFormatted" />
    <DividendDataLabelRow v-if="showQuantityPerShare" :label="dictionary.table.quantityPerShare" :value="quantityPerShare" />
    <DividendDataLabelRow v-if="showAmountPerShare" :label="dictionary.table.amountPerShare" :value="amountPerShare" />  
</div>
</template>

<script>
import DividendDataLabelRow from "./dividend-data-label-row.component.vue";
import { dividendMixin } from "./dividend.mixin";

export default {
  mixins: [dividendMixin],
  components: {
    DividendDataLabelRow,
  },
  props: {
    dividend: Object,
    dictionary: Object,
    toggles: Object,
  },
};
</script>
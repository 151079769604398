<template>
    <b-form-group :label="label" label-for="sortbyDate" >
        <b-select id="sortbyDate" class="form-control form-control-sm" :options="options" v-model="selected"  
            text-field="text" value-field="value" @change="sortByPlanDocumentDate($event)"/>  
    </b-form-group>
</template>

<script>
export default {
  props: {
    dictionary: Object,
  },
  data() {
    return {
      options: [
        {
          value: "1",
          text: this.dictionary.labels.sortByNewest,
        },
        {
          value: "2",
          text:  this.dictionary.labels.sortByOldest,
        },
      ],
      label: this.dictionary.labels.sortBy,
      selected: "1",
    };
  },
  methods: {
    sortByPlanDocumentDate: function (value) {
      this.$emit("sort-by-plan-document-date", value);
    },
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("component-spinner", { attrs: { spin: _vm.isLoading } })
    : _vm.dictionary && _vm.changePasswordBoundaries
    ? _c(
        "LoginTemplate",
        [
          [
            _c("template", { slot: "title" }, [
              _c("h1", { staticClass: "sr-only welcome-text" }, [
                _vm._v(
                  _vm._s(_vm.dictionary.accountPageDictionary.passwordExpired)
                )
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-center py-2 welcome-text",
                  attrs: { "aria-hidden": "true" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.dictionary.accountPageDictionary.passwordExpired)
                  )
                ]
              ),
              _vm._v(" "),
              _c("hr")
            ]),
            _vm._v(" "),
            _c("template", { slot: "body" }, [
              _c(
                "div",
                { staticClass: "login-form" },
                [
                  _c("div", { staticClass: "forgot-password-message" }, [
                    _c("p", { staticClass: "text-left pb-2" }, [
                      _vm._v(_vm._s(_vm.dictionary.labels.passwordExpiredTitle))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.error
                    ? _c("alert-component", { attrs: { variant: "error" } }, [
                        _vm._v(_vm._s(_vm.error))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("loading-spinner", {
                    attrs: { active: _vm.isSubmitting }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-lg-3", attrs: { id: "loginForm" } },
                    [
                      _c("ChangePasswordInputs", {
                        attrs: {
                          dictionary: _vm.accountDictionary,
                          model: _vm.model,
                          showPasswordValidationNote: true,
                          boundaries: _vm.changePasswordBoundaries
                        },
                        on: { back: _vm.back, submit: _vm.submit },
                        scopedSlots: _vm._u([
                          {
                            key: "buttons",
                            fn: function(ref) {
                              var back = ref.back
                              var submit = ref.submit
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group d-md-flex justify-content-md-between button-container"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn secondaryButton",
                                        on: { click: back }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.commonDictionary.btnGoBack
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primaryButton",
                                        on: { click: submit }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.commonDictionary.btnContinue
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="tab-row">
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{pagesDictionary.subscriptions.title}}</h1>
        </div>
        <component-spinner :spin="isLoading"></component-spinner>
        <div v-show="dictionary">
            <b-tabs content-class="mt-0" class="tab-container tab-bar" ref="tabs">
                <portlet-tab v-if="dictionary" :tabTitle="dictionary.widget.tabOpen" :isActive="true" tabId="tabOpenSubscriptions">
                    <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.subscriptionsPageDescriptionOpen)"></div>  
                    <SubscriptionTable :dictionary="dictionary" :datatablesDictionary="dictionary.datatables" id="openSubscriptions" />
                </portlet-tab>
                <portlet-tab v-if="dictionary" :tabTitle="dictionary.widget.tabHistory" tabId="tabHistorySubscriptions">
                    <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.subscriptionsPageDescriptionHistory)"></div>  
                    <SubscriptionHistoricalTable :dictionary="dictionary" :datatablesDictionary="dictionary.datatables" id="historicalSubscriptions" />
                </portlet-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import SubscriptionTable from "./subscriptions-table.component.vue";
    import SubscriptionHistoricalTable from "./subscriptions-historical-table.component.vue";

    export default {
        components: {
            SubscriptionTable,
            SubscriptionHistoricalTable
        },
        props: {
            dictionary: Object,
            isLoading: Boolean,
            pagesDictionary: Object
        },
        beforeMount() {
           this.$nextTick(() => {
                if (this.$route.hash && this.$refs.tabs) {
                    var params = this.$route.hash.split("&");
                    this.$refs.tabs.currentTab = params.includes("#history") ? 1 : 0;
                    this.$router.replace({hash: ''});
                }
            });
        }
    };
</script>

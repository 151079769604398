<template>
    <portlet-layout v-if="condition" v-bind="{ ...$attrs, ...$props, columnClass: portletClass }">
        <template v-slot:title>
            <slot name="title"></slot>
        </template>
        <template v-slot:body>
            <slot name="body"></slot>
        </template>
    </portlet-layout>
    <div v-else :class="divClass">
        <slot name="body"></slot>
    </div>
</template>

<script>
export default {
    props: {
        condition: Boolean,
        portletClass: String,
        divClass: String
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("Mfa", {
        attrs: {
          isVisible: _vm.isVisible,
          message: _vm.dictionary.pacPage.welcomeTextDescription,
          skipConfirmation: true,
          "mfa-boundaries-data-model": _vm.mfaBoundariesDataModel,
          currentFactorType: _vm.currentFactorType
        },
        on: {
          "mfa-get-state-error": _vm.onError,
          "mfa-success-login": function($event) {
            return _vm.$emit("mfa-success")
          },
          cancel: _vm.logout
        }
      }),
      _vm._v(" "),
      _vm.error
        ? _c(
            "button",
            {
              staticClass: "btn btn-block mt-5 primaryButton",
              attrs: { type: "button" },
              on: { click: _vm.logout }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.dictionary.pacPage.backToLoginText) +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <required-info-step
    v-if="requiredInfoModel"
    id="accountholder-container"
    :currentStep="currentStep"
    :step="step"
    :heading="dictionary.labels.step1"
    :description="dictionary.labels.step1Description"
    :dictionary="dictionary"
    @edit-step="(val) => $emit('edit-step', val)"
  >
    <template v-slot:previous>
      <dl class="details-list accountholder-details">
        <div class="details-list-left">
          <DataLabelRow
            v-if="hasCompany"
            :value="requiredInfoModel.companyName"
            :label="dictionary.labels.company"
          />
          <DataLabelRow
            :value="requiredInfoModel.firstNames"
            :label="dictionary.labels.firstName"
          />
          <DataLabelRow
            :value="requiredInfoModel.lastName"
            :label="dictionary.labels.lastName"
          />
          <DataLabelRow
            :value="requiredInfoModel.address"
            :label="dictionary.labels.address"
          />
          <DataLabelRow
            v-if="requiredInfoModel.complementaryAddress && requiredInfoModel.complementaryAddress.length > 0"
            :value="requiredInfoModel.complementaryAddress"
            :label="''"
          />
          <DataLabelRow
            :value="requiredInfoModel.postalCode"
            :label="dictionary.labels.postalCode"
          />
          <DataLabelRow
            :value="requiredInfoModel.city"
            :label="dictionary.labels.city"
          />
          <DataLabelRow
            :value="getCountryName(requiredInfoModel.countryId)"
            :label="dictionary.labels.countryOfResidence"
          />
          <DataLabelRow
            :value="requiredInfoModel.dateOfBirth | toDate"
            :label="dictionary.labels.dob"
          />
          <DataLabelRow
            :value="getCountryName(requiredInfoModel.countryOfBirthId)"
            :label="dictionary.labels.birthCountry"
          />
        </div>
      </dl>
    </template>
    <template v-slot:current>
      <div class="row" v-if="hasCompany">
        <div class="col-12 col-md-6">
          <input-element
            name="companyName"
            v-model="requiredInfoModel.companyName"
            :label="dictionary.labels.company"
            :readonly="true"
          >
          </input-element>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input-element
            name="firstName"
            v-model="requiredInfoModel.firstNames"
            :label="dictionary.labels.firstName"
            :validationsList="accountholderValidations.firstNames"
            :is-mandatory="true"
          >
          </input-element>
        </div>
        <div class="col-md-6">
          <input-element
            name="lastName"
            v-model="requiredInfoModel.lastName"
            :label="dictionary.labels.lastName"
            :validationsList="accountholderValidations.lastName"
            :is-mandatory="true"
          >
          </input-element>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <input-element
            name="address"
            v-model="requiredInfoModel.address"
            :label="dictionary.labels.address"
            :validationsList="accountholderValidations.address"
            :is-mandatory="true"
          >
          </input-element>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <input-element
            name="complementaryAddress"
            v-model="requiredInfoModel.complementaryAddress"
            :label="dictionary.labels.complementaryAddress"
          >
          </input-element>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <input-element
            name="postalCode"
            v-model="requiredInfoModel.postalCode"
            :label="dictionary.labels.postalCode"
            :validationsList="accountholderValidations.postalCode"
            :is-mandatory="true"
          >
          </input-element>
        </div>
        <div class="col-12 col-md-6">
          <input-element
            name="city"
            v-model="requiredInfoModel.city"
            :label="dictionary.labels.city"
            :validationsList="accountholderValidations.city"
            :is-mandatory="true"
          >
          </input-element>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <select-element
            v-model="requiredInfoModel.countryId"
            :label="dictionary.labels.countryOfResidence"
            :validationsList="accountholderValidations.countryId"
            :options="listCountries"
            name="countryId"
            :is-mandatory="true"
          >
          </select-element>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-md-6">
          <datepicker-element
            name="dateOfBirth"
            id="dateOfBirth"
            v-model="requiredInfoModel.dateOfBirth"
            :label="dictionary.labels.dob"
            :validationsList="accountholderValidations.dateOfBirth"
            :is-mandatory="true"
            :yearRange="'-150:+0'"
          >
          </datepicker-element>
        </div>
        <div class="col-12 col-md-6">
          <select-element
            v-model="requiredInfoModel.countryOfBirthId"
            :label="dictionary.labels.birthCountry"
            :validationsList="accountholderValidations.countryOfBirthId"
            :options="listCountries"
            name="countryOfBirthId"
            :is-mandatory="true"
          >
          </select-element>
        </div>
      </div>
      <div class="d-md-flex justify-content-md-between button-container">
        <div>
          <button
              class="btn primaryButton"
              type="button"
              @click="nextStep()"
              :aria-describedby="nextStepAccessibilityLabel(step, steps)">
            {{ dictionary.buttons.next }}
          </button>
        </div>
      </div>
    </template>
  </required-info-step>
</template>

<script>
import { accountHoldingModelValidations } from "./mixins/required-info-form-validation.mixin";
import DataLabelRow from "../../../Beneficiaries/Assets/Vue/data-label-row.component.vue";
import RequiredInfoStep from "./required-info-step.component.vue";
import { createDropDownOption } from "./required-info-crs-timeline.component.vue";
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  mixins: [accountHoldingModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  components: {
    DataLabelRow,
    RequiredInfoStep,
  },
  props: {
    dictionary: Object,
    currentStep: Number,
    step: Number,
    requiredInfoData: Object,
    countries: Array,
  },
  computed: {
    requiredInfoModel() {
      return this.requiredInfoData;
    },
    hasCompany(){
      return this.requiredInfoModel.companyName && this.requiredInfoModel.companyName.length > 0;
    },
    listCountries() {
      return this.countries.map((c) =>
        createDropDownOption(false, false, false, c.name, c.id)
      );
    },
    accountholderValidations() {
      return {
        firstNames: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.firstNames,
            error: this.$v.requiredInfoModel.firstNames.$error,
            type: this.$v.requiredInfoModel.firstNames.required,
            message: this.dictionary.validation.firstNameBlank,
          },
        ],
        lastName: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.lastName,
            error: this.$v.requiredInfoModel.lastName.$error,
            type: this.$v.requiredInfoModel.lastName.required,
            message: this.dictionary.validation.lastNameBlank,
          },
        ],
        address: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.address,
            error: this.$v.requiredInfoModel.address.$error,
            type: this.$v.requiredInfoModel.address.required,
            message: this.dictionary.validation.addressBlank,
          },
        ],
        postalCode: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.postalCode,
            error: this.$v.requiredInfoModel.postalCode.$error,
            type: this.$v.requiredInfoModel.postalCode.required,
            message: this.dictionary.validation.postalCodeBlank,
          },
        ],
        city: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.city,
            error: this.$v.requiredInfoModel.city.$error,
            type: this.$v.requiredInfoModel.city.required,
            message: this.dictionary.validation.cityBlank,
          },
        ],
        countryId: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.countryId,
            error: this.$v.requiredInfoModel.countryId.$error,
            type: this.$v.requiredInfoModel.countryId.requiredAndNotEmpty,
            message: this.dictionary.validation.countryOfResidenceBlank,
          },
        ],
        dateOfBirth: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.dateOfBirth,
            error: this.$v.requiredInfoModel.dateOfBirth.$error,
            type: this.$v.requiredInfoModel.dateOfBirth.required,
            message: this.dictionary.validation.dobBlank,
          },
          {
            id: 2,
            field: this.$v.requiredInfoModel.dateOfBirth,
            error: this.$v.requiredInfoModel.dateOfBirth.$error,
            type: this.$v.requiredInfoModel.dateOfBirth.notInFuture,
            message: this.dictionary.validation.dobInFuture,
          }
        ],
        countryOfBirthId: [
          {
            id: 1,
            field: this.$v.requiredInfoModel.countryOfBirthId,
            error: this.$v.requiredInfoModel.countryOfBirthId.$error,
            type: this.$v.requiredInfoModel.countryOfBirthId.requiredAndNotEmpty,
            message: this.dictionary.validation.birthCountryBlank,
          },
        ],
      };
    },
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.focusOnFirstInvalidInput();
      }
      this.$emit("next-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    },
    previousStep() {
      this.$emit("previous-step", {
        step: this.currentStep,
        data: this.requiredInfoModel,
      });
    },
    getCountryName(id){
      return this.countries.find(x=>x.id === id)?.name;
    },
  },
};
</script>
<template>

<div id="order-form-div"  aria-live="polite">
    <alert-component v-if="error" variant="error">{{ error }}</alert-component>
    <loading-spinner :active="isSubmitting"></loading-spinner>
    <div class="mb-4 rich-text-container" aria-hidden="true" v-html="$sanitize(formatText(choiceInstructionModel.text))"></div>
    <form id="choice-form" @submit.prevent.stop>
        <fieldset>
            <legend class="sr-only rich-text-container"  v-html="$sanitize(formatText(choiceInstructionModel.text))"></legend>
            <div class="choiceContainer" v-for="(scenario, index) in  choiceInstructionModel.scenarios" :key="scenario.participationChoiceScenarioId" >
                <template v-if="scenario.answerMode.value == 'csamSelect'">
                    <ChoiceScenarioAnswerModeSelect :itemIndex="index" 
                        :dictionary="dictionary"
                        :choiceInstructionModel="choiceInstructionModel" 
                        :validation="v"
                        :scenario="scenario"
                        @scenario-selected="onScenarioSelected"
                        @reset="onResetValidation"  /> 
                </template>
                <template v-else>
                    <ChoiceScenarioAnswerModeValue :itemIndex="index" 
                        :dictionary="dictionary"
                        :choiceInstructionModel="choiceInstructionModel" 
                        :scenario="scenario"
                        :validation="v"
                        @scenario-selected="onScenarioSelected" 
                        @reset="onResetValidation" /> 
                </template> 
            </div> 
            <div>
                <ChoiceInstructionButtonsComponent v-if="!choiceInstructionModel.disclaimers.length"
                    :validation="v"
                    :choiceInstructionModel="choiceInstructionModel" 
                    @accept-instruction="acceptInstruction"  
                    @decline-instruction="declineInstruction" />
            </div>            
        </fieldset>
    </form>
</div>
</template>

<script>
import ChoiceScenarioAnswerModeValue from "./choice-scenario-answer-mode-value.component.vue";
import ChoiceScenarioAnswerModeSelect from "./choice-scenario-answer-mode-select.component.vue";
import ChoiceInstructionButtonsComponent from "./choice-instruction-buttons.component.vue";

export default {
  components: {
    ChoiceScenarioAnswerModeValue,
    ChoiceScenarioAnswerModeSelect,
    ChoiceInstructionButtonsComponent,
  },
  props: {
    dictionary: Object,
    choiceInstructionModel: Object,
    error: String,
    v: Object,
    isSubmitting: Boolean,
  },
  methods: {
    onScenarioSelected(participationChoiceScenarioId) {
      this.$emit("scenario-selected", participationChoiceScenarioId);
    },
    acceptInstruction(payload) {
      this.v.$touch();
      if (this.v.$invalid) return;
      this.$emit("accept-instruction", payload);
    },
    declineInstruction() {
      this.$emit("decline-instruction");
    },
    formatText(text) {
      let result = text ?  text : '';
      return result.replace(/(\\r)*\\n/g, '<br>');           
    },
    onResetValidation(){
      this.$emit("reset");
    }    
  }  
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-mobile-details-content" },
    [
      _vm.showVestingDateColumnForControlTags
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.vestingDate,
              value: _vm.position.lastVestingDate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLastDayExercisePeriodColumnForControlTags
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.lastDayExPeriod,
              value: _vm.position.expiryDate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.anyVestings && _vm.showVestingsColumn
        ? [
            _c("PortfolioDataLabelRow", {
              attrs: {
                label: _vm.dictionary.table.granted,
                value: _vm.position.grantedFormatted
              }
            }),
            _vm._v(" "),
            _vm.toggles.showAcceptedAmount
              ? _c("PortfolioDataLabelRow", {
                  attrs: {
                    label: _vm.dictionary.table.accepted,
                    value: _vm.position.acceptedFormatted
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("PortfolioDataLabelRow", {
              attrs: {
                label: _vm.dictionary.table.vested,
                value: _vm.position.vestedFormatted
              }
            }),
            _vm._v(" "),
            _c("PortfolioDataLabelRow", {
              attrs: {
                label: _vm.dictionary.table.unvested,
                value: _vm.position.unvestedFormatted
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showBalanceColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.balance,
              value: _vm.position.balanceFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAvailableColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.available,
              value: _vm.position.availableFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUnvestedColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.unvested,
              value: _vm.position.unvestedFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUnvestedValueColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.unvestedValue,
              value: _vm.position.unvestedValueFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLastPriceColumn && _vm.position.lastSecurityPriceFormatted
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.lastPrice,
              value: _vm.position.lastSecurityPriceFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDerivativeSecurityPriceColumn && _vm.derivativeSecurity
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.underlyingSharePrice,
              value: _vm.lastDerivativeSecurityPrice
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showReferencePriceColumn &&
      _vm.position.lastVestingReferencePriceFormatted
        ? _c("PortfolioDataLabelRowWithTooltip", {
            attrs: {
              label: this.dictionary.table.referencePrice,
              value: _vm.position.lastVestingReferencePriceFormatted,
              tooltipText:
                _vm.dictionary.block.portfolioReferencePriceColumnTooltip,
              tooltipShow: !_vm.toggles.hideReferencePriceTooltipColumn
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExercisePriceColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: this.dictionary.table.exercisePrice,
              value: _vm.position.strikePriceFormatted
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showVestingDateColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: this.dictionary.table.vestingDate,
              value: _vm.position.lastVestingDate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLastDayExercisePeriodColumn
        ? _c("PortfolioDataLabelRow", {
            attrs: {
              label: this.dictionary.table.lastDayExPeriod,
              value: _vm.position.expiryDate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExpectedValueColumn
        ? _c("PortfolioDataLabelRowWithTooltip", {
            attrs: {
              label: this.dictionary.table.expectedValue,
              value: _vm.getExpectedValue(_vm.position, _vm.toggles),
              tooltipText:
                _vm.dictionary.block.portfolioExpectedValueColumnTooltip,
              tooltipShow: !_vm.toggles.hideExpectedValueTooltipColumn
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPlanActionButtons
        ? _c("PortfolioPlanActionButtons", {
            attrs: {
              position: _vm.position,
              dictionary: _vm.dictionary,
              isMobile: _vm.isMobile
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMultiPositionExit
        ? _c("PortfolioPlanActionLinks", {
            staticClass: "mt-2",
            attrs: {
              position: _vm.position,
              dictionary: _vm.dictionary,
              isMobile: _vm.isMobile
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <header class="topnavbar-wrapper noprint">
        <nav aria-labelledby="mainContentLink">
            <button type="button" id="mainContentLink" @click="scrollToMainContent()">
                {{ header.dictionary.sidebar.skipToMainContent }}
            </button>
        </nav>
        <HeaderLoginContainer v-if="header && (!isAuthenticated || isPasswordExpired)" :skin="skin" :dictionary="header.dictionary.header" :current-language="header.currentLanguageName" :current-environment="header.currentEnvironment" />
        <HeaderAuthenticatedContainer v-else-if="header" :isDisclaimer="isDisclaimer" :isImpersonated="isImpersonated" :skin="skin" :dictionary="header.dictionary" :logoutEndpoint="logoutEndpoint" />
    </header>
</template>

<script>
    import HeaderLoginContainer from './header-login-container.component.vue';
    import HeaderAuthenticatedContainer from './header-authenticated-container.component.vue';
    import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";

    export default {
        mixins: [ScreenSizeMixin],
        props: {
            header: Object,
            skin: Object,
            logoutEndpoint: String,
            isAuthenticated: Boolean,
            isDisclaimer: Boolean,
            isPasswordExpired: Boolean,
            isImpersonated: Boolean
        },
        components: {
            HeaderLoginContainer,
            HeaderAuthenticatedContainer
        },
        data() {
            return {
                mainContentId: 'mainContent',
                loginContainerId: 'container-login',
                modalContainerId: 'container-modal'
            }
        },
        mounted() {
            if (this.$route.hash === '#' + this.mainContentId) {
                this.scrollToMainContent();
            }
        },
        methods: {
            scrollToMainContent() {
                let element = document.getElementById(this.mainContentId) || document.getElementById(this.loginContainerId) ||
                    document.getElementById(this.modalContainerId)
                element?.focus();
                element?.scrollIntoView({ behavior: "smooth" });

                if (this.$route.hash !== '#' + this.mainContentId) {
                    this.$router.replace({ hash: this.mainContentId });
                }
            }
        }
    };
</script>

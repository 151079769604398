var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", { attrs: { id: "login-disclaimers-box" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("LoginDisclaimers", {
                    attrs: {
                      disclaimers: _vm.disclaimers,
                      dictionary: _vm.dictionary,
                      boundaries: _vm.boundaries
                    },
                    on: { reloadDisclaimers: _vm.reload }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
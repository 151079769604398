var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.dictionary && !!_vm.ordersDictionary
    ? _c("div", { staticClass: "dashboard row m-0" }, [
        _c(
          "div",
          {
            staticClass: "col-xl-4 col-sm-12 col-md-6 p-0",
            attrs: { id: "dashboard-main" }
          },
          [
            _vm.dashboardData
              ? _c("dashboard-welcome", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    "dashboard-data": _vm.dashboardData,
                    toggles: _vm.toggles
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.dashboardData
              ? _c("dashboard-announcements", {
                  attrs: {
                    dictionary: _vm.dictionary,
                    "dashboard-data": _vm.dashboardData
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.showSecurityAvailabilityChart || _vm.isInTabletAndDesktopRange
          ? _c("div", { staticClass: "col-xl-4 col-sm-12 col-md-6 p-0" }, [
              _c(
                "div",
                { staticClass: "dashboard-chart-column" },
                [
                  _vm.showSecurityAvailabilityChart
                    ? _c("security-chart", {
                        attrs: {
                          id: "securityChart",
                          dictionary: _vm.dictionary.widgets,
                          title:
                            _vm.dictionary.widgets
                              .securityAvailabilityChartTitle,
                          description:
                            _vm.dictionary.widgets
                              .securityAvailabilityChartDescription,
                          "chart-model": _vm.securityChartModelData,
                          "colours-override":
                            _vm.getDerivativeSharePriceSeriesColor
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.toggles.hideOrders && _vm.isInTabletAndDesktopRange
                ? _c(
                    "div",
                    {
                      staticClass: "portlet-container",
                      attrs: { id: "dashboard-side" }
                    },
                    [
                      _c("dashboard-last-orders", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          toggles: _vm.toggles,
                          ordersDictionary: _vm.ordersDictionary
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isInTabletAndDesktopRange
          ? _c("div", { staticClass: "col-xl-4 col-sm-12 p-0" }, [
              !_vm.toggles.hideOrders
                ? _c(
                    "div",
                    {
                      staticClass: "portlet-container",
                      attrs: { id: "dashboard-side" }
                    },
                    [
                      _c("dashboard-last-orders", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          toggles: _vm.toggles,
                          ordersDictionary: _vm.ordersDictionary
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
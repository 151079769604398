var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: { title: _vm.dictionary.widgets.participations },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", {
              staticClass: "page-description rich-text-container",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$sanitize(_vm.dictionary.page.couponsPageDescription)
                )
              }
            }),
            _vm._v(" "),
            _c("CouponTable", {
              attrs: {
                dictionary: _vm.dictionary,
                datatablesDictionary: _vm.datatablesDictionary
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <beneficiary-data-label v-if="isUserNameVisible" :value="accountDataModel.userName" :label="dictionary.loginName"></beneficiary-data-label>
        <beneficiary-data-label v-if="isEmailAddressVisible" :value="accountDataModel.emailAddress" :label="dictionary.emailAddress"></beneficiary-data-label>
        <beneficiary-data-label v-if="isCCEmailAddressVisible" :value="accountDataModel.ccEmailAddress" :label="dictionary.ccEmailAddress"></beneficiary-data-label>
        <beneficiary-data-label v-if="isPersonalTelephoneVisible" :value="accountDataModel.telephone" :label="dictionary.mobileTelephone"></beneficiary-data-label>
        <beneficiary-data-label v-if="isBusinessTelephoneVisible" :value="accountDataModel.businessTelephone" :label="dictionary.businessTelephone"></beneficiary-data-label>
        <template v-if="accountDataModel.beneficiaryBoundaries && accountDataModel.beneficiaryBoundaries.mayChangePassword">
                <BeneficiaryAccountChangePasswordLink :label="dictionary.password" :link="dictionary.changePassword"></BeneficiaryAccountChangePasswordLink>
        </template>
        <beneficiary-data-label :value="accountDataModel.locale ? accountDataModel.locale.name : null" :label="dictionary.language"></beneficiary-data-label>
        <beneficiary-data-label :value="accountDataModel.decimalSeparator.description" :label="dictionary.decimalSeparator"></beneficiary-data-label>
    </div>
</template>

<script>

import BeneficiaryDataLabel from "./../data-label.component.vue";
import BeneficiaryActionLabel from "./../action-label.component.vue";
import BeneficiaryAccountChangePasswordLink from "./beneficiary-account-change-password-link.component.vue";


export default {
    components: {
        BeneficiaryDataLabel,
        BeneficiaryActionLabel,
        BeneficiaryAccountChangePasswordLink
    },
    props: { 
        accountDataModel : Object, 
        dictionary: Object 
    },
    computed: {
        isEmailAddressVisible() {
            return this.accountDataModel.beneficiaryBoundaries.mayViewEmailAddress;
        },
        isCCEmailAddressVisible() {
            return this.accountDataModel.beneficiaryBoundaries.mayViewCCEmailAddress;
        },
        isPersonalTelephoneVisible()
        {
            return this.accountDataModel.beneficiaryBoundaries.mayViewPersonalTelephone;
        },
        isBusinessTelephoneVisible()
        {
            return this.accountDataModel.beneficiaryBoundaries.mayViewBusinessTelephone;
        },
         isUserNameVisible() {
            return this.accountDataModel.beneficiaryBoundaries.mayViewUserName;
        },
    }
}
</script>
<template>
  <OrderDataLabel v-if="shouldDisplay" :label="label" :value="displayValue"></OrderDataLabel>   
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";
export default {
  components: {
      OrderDataLabel,
  },
  props: {
    brokerFeesFormated: String,
    brokerFees:Number,
    label:String,
  },
  computed: {
    shouldDisplay() {
      return this.brokerFees > 0
    },
    displayValue() {
      return this.brokerFees > 0 ? this.brokerFeesFormated : '-'
    },
  },
};
</script>
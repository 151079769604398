var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "stepRefScroll", staticClass: "step-container" }, [
    _c(
      "div",
      { staticClass: "step-body mb-4" },
      [
        _vm.currentStep > _vm.step
          ? [
              _c(
                "required-info-step-accordion",
                {
                  attrs: {
                    heading: _vm.heading,
                    "current-step": _vm.currentStep,
                    dictionary: _vm.dictionary,
                    step: _vm.step,
                    steps: _vm.steps,
                    summaryType: _vm.summaryType
                  },
                  on: { "edit-step": _vm.editStep }
                },
                [_vm._t("previous")],
                2
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === _vm.step,
                expression: "currentStep === step"
              }
            ]
          },
          [
            _c(
              "h2",
              {
                staticClass: "timeline-step-header",
                attrs: { id: "step-" + _vm.step }
              },
              [_vm._v(_vm._s(_vm.heading))]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "timeline-step-description rich-text-container",
              domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.description)) }
            }),
            _vm._v(" "),
            _vm._t("current")
          ],
          2
        ),
        _vm._v(" "),
        _vm.currentStep < _vm.step
          ? [
              _c(
                "h2",
                {
                  staticClass: "timeline-step-header",
                  attrs: { id: "step-" + _vm.step }
                },
                [_vm._v(_vm._s(_vm.heading))]
              ),
              _vm._v(" "),
              _vm._t("future")
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
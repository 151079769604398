<template>
    <div>
        <alert-component v-if="error" variant="error">{{ error }}</alert-component>
        <p>{{ dictionary.orderConfirmTitle }}</p>
        <dl class="order-details-list">
            <BuyConfirmationRow :label="dictionary.planName" :value="buyInstructionModel.planName" />
            <BuyConfirmationRow :label="dictionary.positionName" :value="buyInstructionModel.buyUnderlyingDescription" />
            <BuyConfirmationRow :label="dictionary.orderType" :value="dictionary.orderTypeBuyName" />
            <BuyConfirmationRow :label="dictionary.scenarioName" :value="buyInstructionModel.buyScenarioName" />
            <BuyConfirmationRow v-if="buyInstructionModel.executionMoment" :label="dictionary.executionMoment" :value="buyInstructionModel.executionMoment.description" />
            <BuyConfirmationRow :label="dictionary.reference" :value="buyInstructionModel.reference" />
            <BuyConfirmationRow :label="dictionary.instructionDate" :value="buyInstructionModel.instructionDate | toDateTime" />
            <BuyConfirmationRow :label="dictionary.buyDate" :value="buyInstructionModel.buyDate | toDate" />
            <BuyConfirmationRow :label="dictionary.tradeDate" :value="buyInstructionModel.tradeDate | toDate" />
            <BuyConfirmationRow :label="dictionary.ordered" :value="buyInstructionModel.isInstructionInMoney ? buyInstructionModel.amountOrderedFormatted : buyInstructionModel.nrOrdered" />
            <BuyConfirmationRow :label="dictionary.securityPrice" :value="buyInstructionModel.securityPriceFormatted" />
            <BuyConfirmationRow v-if="buyInstructionModel.limitPrice > 0" :label="dictionary.limitPrice" :value="buyInstructionModel.limitPriceFormatted" />
            <payments-result :payments="buyInstructionModel.feesPayments" :noPaymentsLabel="dictionary.paymentFees" />
            <BuyConfirmationRow :label="dictionary.brokerFees" :value="buyInstructionModel.brokerFeesFormatted" />
            <BuyConfirmationRow :label="dictionary.netAmount" :value="buyInstructionModel.netAmountFormatted" class="confirmation-total-row" />
        </dl>
        <BuyConfirmationButtonsComponent v-if="!buyInstructionModel.disclaimers.length" :buyInstructionModel="buyInstructionModel" v-on="$listeners" />
    </div>
</template>

<script>
    import BuyConfirmationRow from "./buy-confirmation-row.component.vue";
    import BuyConfirmationButtonsComponent from "./buy-confirmation-buttons.component.vue";

    export default {
        components: {
            BuyConfirmationRow,
            BuyConfirmationButtonsComponent
        },
        props: {
            buyInstructionModel: Object,
            toggles: Object,
            dictionary: Object,
            error: String
        }
    };
</script>
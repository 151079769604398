var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "address-edit-form-body" } },
        [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "hidden", name: "AddressId" },
            domProps: { value: _vm.editModel.addressId }
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "hidden", name: "EmailAddress" },
            domProps: { value: _vm.editModel.emailAddress }
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "hidden", name: "CcEmailAddress" },
            domProps: { value: _vm.editModel.ccEmailAddress }
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "hidden", name: "LocaleId" },
            domProps: { value: _vm.editModel.localeId }
          }),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.address.address,
              name: "AddressProperty",
              "is-visible": _vm.editModel.mayEditCurrentAddress,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.addressProperty,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "addressProperty", $$v)
              },
              expression: "editModel.addressProperty"
            }
          }),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.address.complementaryAddress,
              name: "ComplementaryAddress",
              "is-visible": _vm.editModel.mayEditCurrentAddress,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.complementaryAddress,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "complementaryAddress", $$v)
              },
              expression: "editModel.complementaryAddress"
            }
          }),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.address.postalCode,
              name: "PostalCode",
              "is-visible": _vm.editModel.mayEditCurrentAddress,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.postalCode,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "postalCode", $$v)
              },
              expression: "editModel.postalCode"
            }
          }),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.address.city,
              name: "City",
              "is-visible": _vm.editModel.mayEditCurrentAddress,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.city,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "city", $$v)
              },
              expression: "editModel.city"
            }
          }),
          _vm._v(" "),
          _c("beneficiary-select-edit-label", {
            attrs: {
              label: _vm.dictionary.address.country,
              name: "CountryId",
              options: _vm.countries,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.countryId,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "countryId", $$v)
              },
              expression: "editModel.countryId"
            }
          }),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.address.telephone,
              name: "Telephone",
              "is-visible": _vm.editModel.mayEditCurrentAddress,
              "is-editable": _vm.editModel.mayEditCurrentAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations
            },
            model: {
              value: _vm.editModel.telephone,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "telephone", $$v)
              },
              expression: "editModel.telephone"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("FormButtons", {
        attrs: { commonDictionary: _vm.commonDictionary },
        on: {
          "submit-form": function($event) {
            return _vm.submitForm()
          },
          "cancel-form": function($event) {
            return _vm.$emit("cancel-form")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="exit-order-buttons button-container">
        <div v-if="isModal || hasRestrictedSecurityAccountSettings" class="exit-order-buttons__cancel-button">
            <button class="btn secondaryButton" type="button" @click="hasRestrictedSecurityAccountSettings ? $router.back() : $emit('closed')">
                {{ commonDictionary.btnCancel }}
            </button>
        </div>
        <div class="exit-order-buttons__submit-button">
            <button class="btn primaryButton" type="button" :disabled="isButtonDisabled || isInvalid || hasRestrictedSecurityAccountSettings" @click="$emit('order-submitted')">
                {{ buttonText }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isEnabled: Boolean,
        isRealTimeDealing: Boolean,
        hasAuhtorityToDeal: Boolean,
        dictionary: Object,
        commonDictionary: Object, 
        isInvalid: Boolean,
        isModal: Boolean,
        hasRestrictedSecurityAccountSettings: Boolean
    },
    computed: {
        isButtonDisabled() {
            return this.hasAuhtorityToDeal ? !this.isEnabled : false;
        },
        buttonText() {
            return !this.isRealTimeDealing ? this.dictionary.placeOrder : this.dictionary.getQuote;
        }
    }
}
</script>
<template>
    <div class="form-group">
        <p class="primary-color label font-weight-bold">{{ dictionary.form.orderLabelAuthorityToDeal }}</p>
        <div class="rich-text-container" v-html="$sanitize(dictionary.form.orderTextAuthorityToDeal)"></div>        
        <label class="mb-0 form-check-inline form-input-label">
            <input v-model="checkboxValue" class="form-check-input" type="checkbox" />
            <span class="pl-2">{{ dictionary.form.orderCheckboxAuthorityToDealLabel }}</span>
        </label>
        <div class="error" v-if="validations.authorityCheckbox.$error && !validations.authorityCheckbox.required">{{ dictionary.validation.authorityCheckboxRequired }}</div>
    </div>
</template>

<script>
export default {
    props: {
        validations: Object,
        value: Boolean,
        dictionary: Object
    },
    computed: {
        checkboxValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
}
</script>
<template>
<portlet-layout v-if="!!accountDataModel.personalData" :title="accountDictionary.widgets.personal" :hideShadow="true">
    <template v-slot:body>
        <div>
            <PersonalView :personalDataModel="accountDataModel.personalData" 
            :dictionary="accountDictionary.personalData"
            :beneficiary-boundaries-personal-info="beneficiaryBoundariesPersonalInfo">></PersonalView>
        </div>
    </template>
</portlet-layout>
</template>

<script>

import PersonalView from './personal-view.component.vue';

export default {
    components: {
        PersonalView,
    },
    props: {
        accountDictionary: Object,
        accountDataModel:Object,
        beneficiaryBoundariesPersonalInfo:Object
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        label: _vm.dictionary.labels.filterByPlanName,
        "label-for": "plans"
      }
    },
    [
      _c("b-form-select", {
        staticClass: "form-control form-control-sm",
        attrs: {
          id: "plans",
          options: _vm.plans,
          "text-field": "plan",
          "value-field": "plan"
        },
        on: {
          change: function($event) {
            return _vm.filterPlanDocuments($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "first",
            fn: function() {
              return [
                _c("b-form-select-option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.dictionary.labels.allPlans))
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <template v-if="instructionData.ExerciseScenarioType == 'detSale'">
        <ExerciseScenarioTypeSale 
            :valueAmountToDeliver="instructionData.AmountToDeliverFormatted" 
            :labelAmountToDeliver="dictionary.modal.amountToDeliver" 
            :valueProceedsAccount="instructionData.MoneyProceedsAccountDescription" 
            :labelProceedsAccount="dictionary.modal.proceedsAccount">        
        </ExerciseScenarioTypeSale>
    </template>
    <template v-if="instructionData.ExerciseScenarioType == 'detDelivery'">
        <ExerciseScenarioTypeDelivery :dictionary="dictionary" :instructionData="instructionData"></ExerciseScenarioTypeDelivery>
    </template>
    <template v-if="instructionData.ExerciseScenarioType == 'detSaleAndDelivery'">
        <ExerciseScenarioTypeSaleAndDelivery :dictionary="dictionary" :instructionData="instructionData"></ExerciseScenarioTypeSaleAndDelivery>       
    </template>
  </div>      
</template>

<script>
import ExerciseScenarioTypeSaleAndDelivery from "./order-exercise-scenario-type-sale-and-delivery.component.vue";
import ExerciseScenarioTypeDelivery from "./order-exercise-scenario-type-delivery.component.vue";
import ExerciseScenarioTypeSale from "./order-exercise-scenario-type-sale.component.vue";

export default {
  components: {
    ExerciseScenarioTypeSale,
    ExerciseScenarioTypeDelivery,
    ExerciseScenarioTypeSaleAndDelivery,
  },
  props: {
    instructionData: Object,
    dictionary: Object,
  }
};
</script>
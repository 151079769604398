var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: {
      "column-class": "col-lg col-sm-6 col-12",
      title: _vm.title,
      "hide-title": _vm.hideTitle,
      "hide-shadow": _vm.hideShadow
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "block-portlet value-block" },
              [
                _c(
                  "h2",
                  { staticClass: "force-nowrap" },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.portfolioSummary.blockTitle) +
                          "\n                  "
                      ),
                      _vm.portfolioSummary.blockTooltip
                        ? _c("i", {
                            staticClass: "far fa-info-circle popover-info",
                            attrs: { id: _vm.tooltipId, "aria-hidden": "true" }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: _vm.tooltipId,
                          triggers: "hover",
                          placement: "top"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.portfolioSummary.blockTooltip))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.portfolioSummary.walletValues.length > 1
                  ? _c("b-select", {
                      staticClass: "value-block-select",
                      attrs: {
                        options: _vm.portfolioSummary.walletValues,
                        "text-field": "walletValue",
                        "value-field": "currency",
                        id: _vm.blockId,
                        "aria-label": _vm.portfolioSummary.blockTitle
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    })
                  : _vm.portfolioSummary.walletValues.length
                  ? _c("h3", { staticClass: "force-nowrap h4" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.portfolioSummary.walletValues[0]
                              ? _vm.portfolioSummary.walletValues[0].walletValue
                              : ""
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-plan table-responsive mt-0" },
    [
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1",
          attrs: {
            "item-key": "name",
            headers: _vm.customHeaders,
            items: _vm.items,
            "disable-pagination": true,
            "footer-props": {
              disablePagination: true,
              disableItemsPerPage: true
            },
            "disable-sort": true,
            "items-per-page": -1,
            "hide-default-footer": true,
            "mobile-breakpoint": 0,
            "show-expand": _vm.isMobile
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header.expectedValueFormatted",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("ExpectedValueColumn", {
                      attrs: {
                        header: header,
                        toggles: _vm.toggles,
                        dictionary: _vm.dictionary
                      }
                    })
                  ]
                }
              },
              {
                key: "header.expectedAvailableValueFormatted",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("ExpectedValueColumn", {
                      attrs: {
                        header: header,
                        toggles: _vm.toggles,
                        dictionary: _vm.dictionary
                      }
                    })
                  ]
                }
              },
              _vm.showReferencePriceColumn
                ? {
                    key: "header.lastVestingReferencePriceFormatted",
                    fn: function(ref) {
                      var header = ref.header
                      return [
                        _c("VestingReferencePriceColumn", {
                          attrs: {
                            header: header,
                            toggles: _vm.toggles,
                            dictionary: _vm.dictionary
                          }
                        })
                      ]
                    }
                  }
                : null,
              {
                key: "item.custom-buttons",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("PortfolioPlanActionButtons", {
                      staticClass: "hideInMobile action-buttons-table-cell",
                      attrs: { position: item, dictionary: _vm.dictionary }
                    })
                  ]
                }
              },
              {
                key: "item.custom-name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "font-weight-bold" },
                      [
                        !_vm.isMobile
                          ? _c("PortfolioPlanActionLinks", {
                              attrs: {
                                dictionary: _vm.dictionary,
                                isMultiPositionExit:
                                  _vm.portfolioPlan.isMultiPositionExit,
                                position: item
                              }
                            })
                          : _c("PortfolioPlanPositionName", {
                              attrs: { position: item }
                            })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.custom-vesting",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("VestingColumn", {
                      attrs: {
                        anyVestings: _vm.portfolioPlan.hasAnyVestings,
                        position: item,
                        toggles: _vm.toggles,
                        dictionary: _vm.dictionary
                      }
                    })
                  ]
                }
              },
              {
                key: "item.custom-last-price",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("LastPriceColumn", {
                      attrs: {
                        position: item,
                        dictionary: _vm.dictionary,
                        hideSecurityPriceInformationTooltip:
                          _vm.toggles.hideSecurityPriceInformationTooltip
                      }
                    })
                  ]
                }
              },
              {
                key: "item.custom-derivative",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("DerivativeSecurityPriceColumn", {
                      attrs: {
                        derivativeSecurity: _vm.derivativeSecurity(item),
                        dictionary: _vm.dictionary,
                        hideSecurityPriceInformationTooltip:
                          _vm.toggles.hideSecurityPriceInformationTooltip
                      }
                    })
                  ]
                }
              },
              {
                key: "item.custom-value",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "text-nowrap",
                        class: { "font-weight-bold": _vm.isMobile }
                      },
                      [_vm._v(_vm._s(_vm.portfolioValue(item)))]
                    )
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _vm.isMobile
                      ? _c(
                          "td",
                          {
                            staticClass: "expanded-item-td",
                            attrs: { colspan: headers.length }
                          },
                          [
                            _c("PortfolioPlanMobileDetails", {
                              attrs: {
                                position: item,
                                dictionary: _vm.dictionary,
                                toggles: _vm.toggles,
                                isMultiPositionExit:
                                  _vm.portfolioPlan.isMultiPositionExit,
                                anyVestings: _vm.portfolioPlan.hasAnyVestings,
                                derivativeSecurity: _vm.derivativeSecurity(
                                  item
                                ),
                                controlTags: _vm.portfolioPlan.controlTags,
                                isMobile: _vm.isMobile
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.data-table-expand",
                fn: function(ref) {
                  var item = ref.item
                  var isExpanded = ref.isExpanded
                  var expand = ref.expand
                  return [
                    _c(
                      "v-icon",
                      {
                        class: [
                          "v-data-table__expand-icon",
                          { "v-data-table__expand-icon--active": isExpanded }
                        ],
                        attrs: {
                          "aria-label":
                            item.type +
                            " " +
                            item.reference +
                            " " +
                            (isExpanded ? "collapse" : "expand") +
                            " more details"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return expand(!isExpanded)
                          }
                        }
                      },
                      [_vm._v("$expand")]
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm.toggles.showShareTotalsRow &&
          _vm.portfolioPlan.security &&
          !_vm.isMobile
            ? _c(
                "template",
                { slot: "body.append" },
                [
                  _c("PortfolioPlanTotalsRow", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      security: _vm.portfolioPlan.security,
                      isMultiPositionExit:
                        _vm.portfolioPlan.isMultiPositionExit,
                      toggles: _vm.toggles,
                      portfolioPlan: _vm.portfolioPlan,
                      controlTags: _vm.portfolioPlan.controlTags
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
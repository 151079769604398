var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.exerciseScenario && !!_vm.exerciseScenario.exerciseScenarioType
    ? _c(
        "div",
        [
          _vm.exerciseScenario.exerciseScenarioType.value ===
          _vm.$ExerciseScenarioType.SALE_OF_SHARES
            ? [
                _vm.exerciseScenario.scenarioDescriptionText
                  ? _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.exerciseScenario.scenarioDescriptionText) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.shouldKeepProceeds
                  ? _c("div", [
                      _c("p", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.dictionary.form.proceedsAccount))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.exerciseScenario.proceedsAccount) + " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.canSelectMoneyExternalAccount
                  ? _c(
                      "div",
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.form.proceedsAccount,
                            options: _vm.moneyExternalAccounts
                          },
                          model: {
                            value: _vm.model.moneyExternalAccountId,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "moneyExternalAccountId", $$v)
                            },
                            expression: "model.moneyExternalAccountId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.exerciseScenario.exerciseScenarioType.value ===
          _vm.$ExerciseScenarioType.DELIVERY_OF_SHARES
            ? [
                _vm.exerciseScenario.scenarioDescriptionText
                  ? _c("p", { staticClass: "label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.exerciseScenario.scenarioDescriptionText) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.shouldKeepProceeds
                  ? _c("div", [
                      _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.dictionary.form.securityExternalAccount) +
                            " "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.exerciseScenario.deliveryAccount))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.canSelectSecurityExternalAccount
                  ? _c(
                      "div",
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.form.securityExternalAccount,
                            options: _vm.securityExternalAccounts,
                            name: "securityExternalAccounts"
                          },
                          model: {
                            value: _vm.model.securityExternalAccountId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "securityExternalAccountId",
                                $$v
                              )
                            },
                            expression: "model.securityExternalAccountId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.exerciseScenario.exerciseScenarioType.value ===
          _vm.$ExerciseScenarioType.SALE_AND_DELIVERY_OF_SHARES
            ? [
                _vm.exerciseScenario.scenarioDescriptionText
                  ? _c("p", { staticClass: "label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.exerciseScenario.scenarioDescriptionText) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.shouldKeepProceeds
                  ? _c("div", [
                      _c("p", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.dictionary.form.proceedsAccount))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.exerciseScenario.proceedsAccount))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.dictionary.form.securityExternalAccount)
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.exerciseScenario.deliveryAccount))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.canSelectSecurityExternalAccount
                  ? _c(
                      "div",
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.form.securityExternalAccount,
                            options: _vm.securityExternalAccounts,
                            name: "securityExternalAccounts"
                          },
                          model: {
                            value: _vm.model.securityExternalAccountId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "securityExternalAccountId",
                                $$v
                              )
                            },
                            expression: "model.securityExternalAccountId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.exerciseScenario.canSelectMoneyExternalAccount
                  ? _c(
                      "div",
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.form.proceedsAccount,
                            options: _vm.moneyExternalAccounts,
                            name: "moneyExternalAccounts"
                          },
                          model: {
                            value: _vm.model.moneyExternalAccountId,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "moneyExternalAccountId", $$v)
                            },
                            expression: "model.moneyExternalAccountId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-component", { attrs: { variant: "error" } }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.dictionary.orderConfirmTitle))]),
      _vm._v(" "),
      !!_vm.exerciseScenarioType
        ? [
            _vm.exerciseScenarioType.value ===
            _vm.$ExerciseScenarioType.SALE_OF_SHARES
              ? _c("ExercisesConfirmationSaleOfShares", {
                  attrs: {
                    model: _vm.exercisesInstructionModel,
                    dictionary: _vm.dictionary
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.exerciseScenarioType.value ===
            _vm.$ExerciseScenarioType.DELIVERY_OF_SHARES
              ? _c("ExercisesConfirmationDeliveryOfShares", {
                  attrs: {
                    model: _vm.exercisesInstructionModel,
                    dictionary: _vm.dictionary
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.exerciseScenarioType.value ===
            _vm.$ExerciseScenarioType.SALE_AND_DELIVERY_OF_SHARES
              ? _c("ExercisesConfirmationSaleAndDeliveryOfShares", {
                  attrs: {
                    model: _vm.exercisesInstructionModel,
                    dictionary: _vm.dictionary
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.exercisesInstructionModel.disclaimers.length
              ? _c(
                  "ExercisesConfirmationButtons",
                  _vm._g(
                    {
                      attrs: {
                        exercisesInstructionModel: _vm.exercisesInstructionModel
                      }
                    },
                    _vm.$listeners
                  )
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <portlet-layout v-if="containsAnnouncements" class="w-100" :title="dictionary.widgets.announcements">
        <template slot="body">
            <div class="announcement" v-for="(announcement, index) in dashboardData.announcements" :key="`${announcement.announcementId}-${index}`">
                <div class="announcement__date">{{announcement.startDate | toDate }}</div>
                <h6>{{announcement.title}}</h6>
                <p>{{announcement.text }}</p>
                <div v-if="announcement.document" class="announcement__document">
                    <a @click="redirectToDocument(announcement.document)"> {{ getDocumentLinkText(announcement) }}</a>
                </div>
                <div v-if="announcement.url" class="announcement__url">
                    <a @click="redirect(announcement.url)"> {{ announcement.urlDescription }}</a>
                </div>
            </div>
        </template>
    </portlet-layout>

</template>

<script>
    import DOMPurify from 'dompurify';

    export default {
        props: {
            dashboardData: Object,
            dictionary: Object,
        },
        computed:{ 
            containsAnnouncements() {
                return !!this.dashboardData.announcements?.length
            }
        },
        methods: {
            redirectToDocument(document) {
                return window.location.href = `Document/GetDocument?documentId=${document.documentId}&documentType=${document.documentType}`;
            },
            getDocumentLinkText(announcement) {
                if (announcement.urlDescription) {
                    return announcement.urlDescription
                } else {
                    return announcement.document.documentName;
                }
            },
            redirect: function (url) {
                let sanitizedUrl = DOMPurify.sanitize(url)

                if (sanitizedUrl.startsWith("/")) {
                    if (this.$router.getMatchedComponents(url)?.length) {
                        this.$router.push({ path: sanitizedUrl });
                    } else {
                        return window.location.href = sanitizedUrl
                    }
                } else {
                    return window.location.href = 'http://' + sanitizedUrl;
                }
            }
        }
    }
</script>
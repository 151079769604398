<template>
    <div>
        <VueApexCharts v-if="apexChartData !== null"
                       :type="pieChartType"
                       :options="apexChartData.options"
                       :series="apexChartData.series"
                       aria-hidden="true">
        </VueApexCharts>
    </div>
</template>

<script>

    import VueApexCharts from 'vue-apexcharts';

    export default {
        props: {
            chartModel: Object,
            colours: Array,
            pieChartType: String,
        },
        components: {
            VueApexCharts
        },
        data() {
            return {
                defaultColours: ["#FFC700",
                    "#FF6B00",
                    "#2368F6",
                    "#00AD30",
                    "#9969FF"
                ],
                chartContexts: [],
                isDonutChart: false,
                chartItem: this.chartModel.data.filter((x, i, a) => i !== a.length - 1),
                chartTotal: this.chartModel.data.filter((x, i, a) => i === a.length - 1)
            }
        },
        computed: {
            apexChartData() {

                let data = null;
                if (this.chartModel !== null) {

                    data = {};

                    data.options = {
                        colors: this.colours || this.defaultColours,
                        chart: {
                            type: this.pieChartType,
                            height: 200,
                            width: 200,
                            events: {
                                mounted: (chartContext, config)  => {
                                    this.createLegends(config);
                                }
                            },
                        },
                        dataLabels: {
                            enabled: false
                            },
                        labels: this.chartItem.map(e => e.label + ": " + e.valueFormatted),
                        legend: {
                            show: false
                        },
                        plotOptions: {
                            pie: {
                                expandOnClick: false,
                                donut: {
                                    size: '85%',
                                    labels: {
                                        show: this.isDonutChart,
                                        value: {
                                            show: true,
                                            offsetY: 0,
                                            fontSize: '18px',
                                            fontWeight: 600
                                        },
                                        name: {
                                            fontSize: '14px'
                                        },
                                        total: {
                                            show: this.isDonutChart,
                                            showAlways: this.isDonutChart,
                                            label: this.chartTotal.map(e => e.label),
                                            fontSize: '14px',
                                            color: '#484848',
                                            formatter: () => {
                                                return this.chartTotal.map(e => e.valueFormatted)
                                            }
                                        }
                                    }
                                }
                            },
                        },
                        states: {
                            active: {
                                allowMultipleDataPointsSelection: false,
                                filter: {
                                    type: 'none',
                                }
                            }
                        },
                        tooltip: {
                            fillSeriesColor: false,
                            marker: {
                                show: false
                            },
                            custom({ series, seriesIndex, w }) {
                                return `<div class="popover-chart"><span>${w.config.toolTips[seriesIndex]}</span></div>`
                            }
                        },
                        toolTips: this.chartModel.data.map(e => e.tooltip)
                    };

                    data.series = this.chartItem.map(e => e.value);
                };

                return data;
            }
        },
        created() {
            this.isDonutChart = this.pieChartType === "donut"
        },
        methods: {
            createLegends(config) {
                for(let i=0; i<config.config.labels.length; i++) {
                    this.chartContexts[i] =
                        { 
                            labels:  config.config.labels[i].split(":").map(x => x.trim()),
                            percents : config.globals.seriesPercent[i][0],
                            colors: config.config.colors[i]
                        }
                }
                this.$emit('create-chart-legends', this.chartContexts);
            }
        }
    }
</script>
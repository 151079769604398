var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "topnavbar-wrapper noprint" },
    [
      _c("nav", { attrs: { "aria-labelledby": "mainContentLink" } }, [
        _c(
          "button",
          {
            attrs: { type: "button", id: "mainContentLink" },
            on: {
              click: function($event) {
                return _vm.scrollToMainContent()
              }
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.header.dictionary.sidebar.skipToMainContent) +
                "\n        "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.header && (!_vm.isAuthenticated || _vm.isPasswordExpired)
        ? _c("HeaderLoginContainer", {
            attrs: {
              skin: _vm.skin,
              dictionary: _vm.header.dictionary.header,
              "current-language": _vm.header.currentLanguageName,
              "current-environment": _vm.header.currentEnvironment
            }
          })
        : _vm.header
        ? _c("HeaderAuthenticatedContainer", {
            attrs: {
              isDisclaimer: _vm.isDisclaimer,
              isImpersonated: _vm.isImpersonated,
              skin: _vm.skin,
              dictionary: _vm.header.dictionary,
              logoutEndpoint: _vm.logoutEndpoint
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
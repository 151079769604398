var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldDisplay
    ? _c("OrderDataLabel", {
        attrs: { label: _vm.label, value: _vm.displayValue }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <loading-spinner :active="isLoading"></loading-spinner>
        <div v-if="!isLoading">
        <div class="portlets-wrapper">
                <div class="row m-0" data-toggle="portlet">
                    <portlet-layout column-class="col-md-6" :title="choiceInstructionModel.title" >
                        <template v-slot:body>
                            <ChoiceInstructionForm :dictionary="dictionary" 
                                :choiceInstructionModel="choiceInstructionModel" 
                                :isSubmitting="isSubmitting" 
                                :error="error" 
                                :v="$v"
                                @accept-instruction="acceptInstruction"
                                @decline-instruction="declineInstruction" 
                                @scenario-selected="onScenarioSelected" 
                                @reset="resetValidation" />
                        </template>
                    </portlet-layout>
                    <portlet-layout id="disclaimers" v-if="showDisclaimersPortlet" column-class="col-md-6" :title="dictionary.widgets.disclaimers" >
                        <template v-slot:body>
                            <ChoiceInstructionDisclaimers :dictionary="dictionary" 
                                :choiceInstructionModel="choiceInstructionModel" 
                                :v="$v"
                                @accept-instruction="acceptInstruction" 
                                @decline-instruction="declineInstruction"/>
                        </template>
                    </portlet-layout>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, post } from "api";
import ChoiceInstructionForm from "./choice-instruction-form.component.vue";
import ChoiceInstructionDisclaimers from "./choice-instruction-disclaimers.component.vue";
import { scenarioValidations } from "./choice-instruction-form-validation.mixin";

export default {
  mixins: [scenarioValidations],
  components: {
    ChoiceInstructionForm,
    ChoiceInstructionDisclaimers,
  },
  props: {
    dictionary: Object,
  },
  data() {
    return {
      choiceInstructionModel: null,
      error: null,
      isLoading: true,
      isDecline: false,
      isSubmitting: false,
    };
  },
  beforeCreate() {
    let query = this.$route.query;
    get(
      `/choices/ParticipationChoice?ParticipationChoiceId=${query.participationChoiceId}`,
      (response) => {
        this.choiceInstructionModel = response.data;
        this.choiceInstructionModel.scenarios.forEach((scenario) => {
          scenario.value = this.$setDecimalSeparatorBySettings(scenario.value);
        });
      },
      this,
      () => (this.isLoading = false)
    );
  },
  computed: {
    showDisclaimersPortlet() {
      return (
        this.choiceInstructionModel.disclaimers &&
        this.choiceInstructionModel.disclaimers.length
      );
    },
    validScenariosSelected() {
      if ((this.choiceInstructionModel.minimalNrEnteredScenarios == 0 && this.choiceInstructionModel.maximumNrEnteredScenarios == 0)
           ||  this.hasSingleScenario ) {
        return true;
      } else {
        const selectedScenarios = this.selectedScenarios;
        return (
          selectedScenarios >=
            this.choiceInstructionModel.minimalNrEnteredScenarios &&
          selectedScenarios <=
            this.choiceInstructionModel.maximumNrEnteredScenarios
        );
      }
    },
    selectedScenarios() {
      return this.choiceInstructionModel.scenarios.filter((c) => c.isSelected).length;
    },
    hasSingleScenario() {
        return (
          this.choiceInstructionModel.scenarios.length  == 1 || 
          (this.choiceInstructionModel.maximumNrEnteredScenarios == 1 && 
            this.choiceInstructionModel.minimalNrEnteredScenarios == 1)
        );
    },
  },
  methods: {
    acceptInstruction(model) {
      this.error = "";

      if (this.validateMinimalNrEnteredScenarios()) return;
      if (this.validateMaximumNrEnteredScenarios()) return;
      if (this.validateScenariosPercentag()) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.submitInstruction(model);
    },
    declineInstruction() {
      this.$router.replace(`/choices${this.$route.hash}`);
    },
    onScenarioSelected(payload) {
      this.choiceInstructionModel.scenarios.forEach((scenario) => {
        if (this.hasSingleScenario){
          if (scenario.participationChoiceScenarioId === payload.participationChoiceScenarioId) {
            scenario.isSelected = true;
          } else {
            scenario.isSelected = false;
          }
        }
        else {
          if (scenario.participationChoiceScenarioId === payload.participationChoiceScenarioId) {
            scenario.isSelected = !payload.isSelected;
          }
        }        
      });
    },
    prepareSubmitData(model) {
      let newInstructionModel = {};
      newInstructionModel.participationChoiceId = model.participationChoiceId;
      newInstructionModel.acceptDisclaimers = model.disclaimers;
      newInstructionModel.scenarios = structuredClone(model.scenarios);
      newInstructionModel.scenarios.forEach((scenario) => {

        if (scenario?.answerMode?.value == 'csamValue')
          scenario.isSelected = true;
        scenario.value = this.$convertToDecimal(scenario.value);
      });
      return newInstructionModel;
    },
    validateMinimalNrEnteredScenarios() {
      if (!this.validScenariosSelected && this.selectedScenarios < this.choiceInstructionModel.minimalNrEnteredScenarios ) {
        this.error = this.$replaceStringWithPlaceholders(
          this.dictionary.validation.minSelections,
          {
            MinimumSelections: this.choiceInstructionModel.minimalNrEnteredScenarios,
          }
        );
        return true;
      }
      return false;
    },
    validateMaximumNrEnteredScenarios() {
      if (
        !this.validScenariosSelected &&
        this.choiceInstructionModel.maximumNrEnteredScenarios != 0 &&
        this.selectedScenarios > this.choiceInstructionModel.maximumNrEnteredScenarios
      ) {
        this.error = this.$replaceStringWithPlaceholders(
          this.dictionary.validation.maxSelections,
          {
            MaximumSelections: this.choiceInstructionModel.maximumNrEnteredScenarios,
          }
        );
        return true;
      }
      return false;
    },
    validateScenariosPercentag() {
      if (this.choiceInstructionModel.isStrictPercentage) {
        let total = 0;
        this.choiceInstructionModel.scenarios.forEach((scenario) => {
          if (scenario.isSelected) {
            total += this.$convertToDecimal(scenario.value);
          }
        });

        if (total != 100) {
          this.error = this.dictionary.validation.mustBe100Percent;
          return true;
        }
      }
      return false;
    },
    submitInstruction(model) {
      this.error = "";
      this.isSubmitting = true;
      let newInstructionModel = this.prepareSubmitData(model);
      post(
        "/choices/ChoiceInstruction",
        newInstructionModel,
        (res) => {
          const response = res.data;
          this.$router.push({
            path: "/choices",
          });
        },
        this,
        () => {
          this.isSubmitting = false;
        },
        (err) => {
          this.error = err.response.data;
        }
      );
    },
    resetValidation(){
      // reset form validation errors
      this.$v.$reset();                
      // reset form data
      const initialData = this.$options.data.call(this);
      let query = this.$route.query;
      get(
        `/choices/ParticipationChoice?ParticipationChoiceId=${query.participationChoiceId}`,
        (response) => {
          initialData.choiceInstructionModel = response.data;
          initialData.choiceInstructionModel.scenarios.forEach((scenario) => {
            scenario.value = this.$setDecimalSeparatorBySettings(scenario.value);
          });           
          Object.assign(this.$data, initialData);
        },
        this,
        () => (this.isLoading = false)
      );     
    }
  },
};
</script>
<template>
    <div class="pt-2 form-group float-right">
        <button type="button" class="btn btn-secondary" @click="$emit('cancel-form')">
            {{ commonDictionary.btnCancel }}
        </button>
        <button type="button" class="btn btn-primary" @click="$emit('submit-form')">
            {{ commonDictionary.btnSubmit }}
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            commonDictionary: Object
        }
    }
</script>
<template>
  <div class="beneficiary-details form-group">
    <p>{{ label }}</p>
    <p class="change-password-link" @click="$router.push({ path: '/beneficiaries/password/change' })">{{ link }}</p>
  </div>
</template>

<script>
export default {
    props: {
        label: String,
        link: String
    }
}
</script>
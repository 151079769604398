var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "choice-expanded-content" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-lg-4" },
        [
          _c("ChoiceDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.choiceName,
              value: _vm.choice.title
            }
          }),
          _vm._v(" "),
          _c("ChoiceDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.startDate,
              value: _vm.choice.choiceStartDate
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6 col-lg-4" },
        [
          _c("ChoiceDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.endDate,
              value: _vm.choice.choiceEndDate
            }
          }),
          _vm._v(" "),
          _c("ChoiceDataLabelRow", {
            attrs: {
              label: _vm.dictionary.table.state,
              value: _vm.choice.stateDescription
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
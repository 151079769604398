<template>
    <div id="account-type-container" v-if="accountTypeModel" class="add-account-step">

      <template v-if="currentStep > 1">
        <FinancialAddAccountStepAccordion :heading="dictionary.subHeadingAccountType" :current-step="currentStep" :dictionary="dictionary" :showStep3="showStep3">

          <dl class="details-list">
            <BeneficiaryDataLabelRow :value="relationship" :label="dictionary.accountType.relationType" />
            <BeneficiaryDataLabelRow :value="accountTypeName" :label="dictionary.accountType.accountType" />
            <BeneficiaryDataLabelRow :value="bankCountryName" :label="dictionary.accountType.bankCountry" />
            <BeneficiaryDataLabelRow :value="currencyName" :label="dictionary.accountType.currency" />
          </dl>
          
        </FinancialAddAccountStepAccordion>

      </template>
      <template v-else>
      
        <h3 class="timeline-step-header" :id="'step-' + currentStep">{{dictionary.step}} 1: {{dictionary.subHeadingAccountType}}</h3>

        <div class="account-type-body timeline-step-details">    
          <div class="row">
            <template v-if="accountType == $BankAccountType.MONEY">
              <select-element class="col-md-6 col-lg-4" v-model="accountTypeModel.externalAccountType" :label="dictionary.accountType.accountType" :options="externalAccountTypes" :is-mandatory="true" name="accountTypeExternalAccountType" />              
            </template>
            <template v-else>
              <select-element class="col-md-6 col-lg-4" v-model="accountTypeModel.externalAccountType" :label="dictionary.accountType.accountType" :options="externalAccountTypes" :is-mandatory="true" @changed="changedExternalAccountType()" name="accountTypeExternalAccountType" />
            </template>
              <select-element class="col-md-6 col-lg-4" :disabled="securityAccountDisplaySettings.securityBankAccountsInOwnNameOnly" v-model="accountTypeModel.relationshipType" :label="dictionary.accountType.relationType" :options="relationshipTypes" :is-mandatory="true" name="accountTypeRelationType" />              
              <select-element class="col-md-6 col-lg-4" v-model="accountTypeModel.bankCountryId" :label="dictionary.accountType.bankCountry" :options="countries" :is-mandatory="true" @changed="updateBoundaries()" name="accountTypeBankCountry" />
              <select-element class="col-md-6 col-lg-4" :disabled="securityAccountDisplaySettings.allowUKShareTransferSettingsOnly" v-model="accountTypeModel.currencyId" :label="dictionary.accountType.currency" :options="currencies" :is-mandatory="true" @changed="updateBoundaries()" name="accountTypeCurrency" />             
           </div>
          <FinancialAddAccountMandatoryTooltip :tooltipText="dictionary.mandatory" />

          <div class="d-md-flex justify-content-md-between">
              <FinancialCancelAddAccount :dictionary="dictionary" @cancel-add-account="cancelAddAccount()"/>              
              <div>
                <button
                    class="btn primaryButton"
                    type="button"
                    @click="nextStep()"
                    :aria-describedby="nextStepAccessibilityLabel(currentStep, maxNumberOfSteps)">          
                  {{dictionary.btnNext}}
                </button>  
              </div>
          </div>
        </div>

      </template>
    </div>
</template>

<script>
import { accountTypeModelValidations } from "./financial-add-account-form-validation.mixin";
import FinancialAddAccountMandatoryTooltip from "./financial-add-account-mandatory-tool-tip.component.vue";
import BeneficiaryDataLabelRow from "./../data-label-row.component.vue";
import FinancialAddAccountStepAccordion from "./finanacial-add-account-step-accordion.component.vue";
import FinancialCancelAddAccount from "./financial-cancel-add-account.component.vue";
import { accessibility } from "Shared/Mixins/accessibility.mixin";
import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";

export default {
  components: {
    FinancialAddAccountMandatoryTooltip, 
    BeneficiaryDataLabelRow, 
    FinancialAddAccountStepAccordion, 
    FinancialCancelAddAccount,
  },
  mixins: [accountTypeModelValidations, accessibility, stepperAccessibilityPreviousNextLabel],
  props: {
    dictionary: Object,
    validationDictionary:Object,
    accountType: String,
    relationshipTypes: Array,
    externalAccountTypes: Array,
    countries: Array,
    currencies: Array,
    complianceExternalAccount: Object,
    accountTypeData: Object,
    currentStep: Number,
    maxNumberOfSteps: Number,
    action: String,
    toggles:Object,
    securityAccountDisplaySettings:Object,
    showStep3: Boolean,
    externalAccountTypeDefaultsToSecurity: Boolean
  },
  data() {
    return { isPersonalAccount : true }
  },
  methods: {
    nextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.focusOnFirstInvalidInput();
      }
      this.$emit("next-step", { step: this.currentStep, data: this.accountTypeModel } );
    },
    cancelAddAccount() {
      this.$emit("cancel-add-account");
    },
    updateBoundaries(){
      this.$emit('update-boundaries', this.accountTypeModel )
    },
    changedExternalAccountType(){
      this.$emit("changed-external-account-type");
    }
  },
  mounted() {
      if (this.securityAccountDisplaySettings && this.securityAccountDisplaySettings.allowUKShareTransferSettingsOnly) {
        this.accountTypeData.currencyId = this.currencies.filter(x => x.externalIdentifier == "GBP")[0]?.value;
      } else if(this.action == this.$AccountActionType.ADD && !this.toggles.hideCustodianFieldsForSecurityAccounts){
        this.accountTypeData.currencyId = this.currencies.filter(x => x.externalIdentifier == "EUR")[0]?.value;
      }

      if(this.externalAccountTypeDefaultsToSecurity) {
        this.accountTypeData.externalAccountType = 'beatSecurity';
      }
  },
  computed: {
    accountTypeModel() {
      if (this.securityAccountDisplaySettings) {
        if(this.securityAccountDisplaySettings.securityBankAccountsInOwnNameOnly == true)
          this.accountTypeData.relationshipType = "brtPersonal";
        
        if (this.externalAccountTypes.filter(c => c.value === this.accountTypeData.externalAccountType).length == 0)
          this.accountTypeData.externalAccountType = '';
        
        return this.accountTypeData;
      }
    },
    relationship() {
        return this.relationshipTypes.filter(c => c.value === this.accountTypeData.relationshipType)[0].text;
    },
    accountTypeName() {
      return this.externalAccountTypes.filter(c => c.value === this.accountTypeData.externalAccountType)[0].text;
    },
    bankCountryName() {
      return this.countries.filter(c => c.value === this.accountTypeData.bankCountryId)[0].text;
    },
    currencyName() {
      return this.currencies.filter(c => c.value === this.accountTypeData.currencyId)[0].text;
    }
  }
};

</script>

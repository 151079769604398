var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-md-flex justify-content-md-between button-container" },
    [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn secondaryButton",
            on: { click: _vm.declineInstruction }
          },
          [_vm._v(_vm._s(_vm.dictionary.btnDecline))]
        )
      ]),
      _vm._v(" "),
      _vm.model.hasRealTimeDealingQuote
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "btn secondaryButton",
                attrs: { disabled: !_vm.isQuoteExpires },
                on: { click: _vm.requoteInstruction }
              },
              [_vm._v(_vm._s(_vm.dictionary.btnRequote))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn primaryButton ml-0",
            attrs: {
              disabled: _vm.isQuoteExpires || !_vm.isDisclaimersAccepted,
              title: _vm.confirmButtonTitle
            },
            on: { click: _vm.acceptInstruction }
          },
          [_vm._v(_vm._s(_vm.dictionary.btnConfirm))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "title-block" }, [
        _vm.dictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.dictionary.page.confirmation))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "portlets-wrapper exercise-confirmation" },
        [
          _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
          _vm._v(" "),
          _vm.model && _vm.dictionary
            ? _c(
                "div",
                { staticClass: "row m-0", attrs: { "data-toggle": "portlet" } },
                [
                  _c("portlet-layout", {
                    attrs: {
                      "column-class": "col-md-6",
                      title: _vm.dictionary.page.confirmation
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function() {
                            return [
                              _c("loading-spinner", {
                                attrs: { active: _vm.isFormProcessing }
                              }),
                              _vm._v(" "),
                              _c("ExercisesConfirmationForm", {
                                attrs: {
                                  dictionary: _vm.dictionary.confirmation,
                                  toggles: _vm.toggles,
                                  exercisesInstructionModel: _vm.model,
                                  error: _vm.error
                                },
                                on: {
                                  "accept-instruction": _vm.acceptInstruction,
                                  "decline-instruction": _vm.declineInstruction
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2760127355
                    )
                  }),
                  _vm._v(" "),
                  _vm.showDisclaimersPortlet
                    ? _c("portlet-layout", {
                        attrs: {
                          "column-class": "col-md-6",
                          title: _vm.dictionary.widgets.disclaimers
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function() {
                                return [
                                  _c("ExercisesConfirmationDisclaimers", {
                                    attrs: {
                                      exercisesInstructionModel: _vm.model
                                    },
                                    on: {
                                      "accept-instruction":
                                        _vm.acceptInstruction,
                                      "decline-instruction":
                                        _vm.declineInstruction
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2543128750
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
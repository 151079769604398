<template>
  <div>
    <h3 class="h4 primary-color">{{dictionary.modal.cancelOrderConfirmText}}</h3>
    <dl class="order-details-list">
        <OrderDataLabel :label="dictionary.modal.positionName" :value="instructionData.SecurityName"></OrderDataLabel>
        <OrderDataLabel v-if="instructionData.OrderType === 'Sell'" :label="dictionary.modal.orderType" :value="dictionary.modal.orderTypeSell"></OrderDataLabel>
        <OrderDataLabel v-if="instructionData.OrderType === 'Buy'" :label="dictionary.modal.orderType" :value="dictionary.modal.orderTypeBuy"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.instructionDate" :value="instructionData.SubmissionDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.expirationDate" :value="instructionData.ExpirationDate"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.quantity" :value="instructionData.QuantityFormatted"></OrderDataLabel>
        <OrderDataLabel :label="dictionary.modal.limitPrice" :value="instructionData.LimitPriceFormatted"></OrderDataLabel>
    </dl>
  </div>
</template>

<script>
import OrderDataLabel from "./order-data-label.component.vue";

export default {
  components: {
    OrderDataLabel
  },
  props: {
    instructionData: Object,
    dictionary: Object,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portfolio-row-flex form-row" }, [
    _c("p", { staticClass: "col portfolio-row__label font-weight-bold" }, [
      _vm._v(_vm._s(_vm.label) + " "),
      _vm.tooltipShow
        ? _c("i", {
            staticClass: "far fa-info-circle popover-info",
            attrs: { id: _vm.spanId, "aria-hidden": "true" }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "p",
      { class: ["col portfolio-row__value", { "text-right": _vm.textRight }] },
      [_vm._v(_vm._s(_vm.value) + " ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "b-popover",
          {
            attrs: { target: _vm.spanId, triggers: "hover", placement: "top" }
          },
          [
            _c("span", {
              staticClass: "rich-text-container",
              domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.tooltipText)) }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="default-account-buttons button-container">
    <div class="default-account-buttons__cancel-button">
      <button class="btn secondaryButton" type="button" @click="$emit('cancel-submit')">
          {{ dictionary.removeAccountModal.btnCancel }}
      </button>
    </div>
    <div class="default-account-buttons__submit-button">
          <button class="btn primaryButton" type="button"  @click="$emit('submit-remove-account')">
              {{ dictionary.removeAccountModal.btnRemove }}
          </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dictionary: Object,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { ref: "PortfolioOverview", attrs: { id: "PortfolioOverview" } },
        [
          _vm.dictionary
            ? _c(
                "portlet-row",
                {
                  attrs: { title: _vm.portfolioOverviewDictionary.subHeading }
                },
                [
                  _c("div", { staticClass: "p-2" }, [
                    _c("div", {
                      staticClass: "rich-text-container",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(
                            _vm.portfolioOverviewDictionary.description
                          )
                        )
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("PortfolioReportParametersForm", {
            ref: "PortfolioOverviewForm",
            attrs: {
              isSubmitting: _vm.isSubmitting,
              portfolioOverviewDictionary: _vm.portfolioOverviewDictionary,
              model: _vm.model,
              customCurrencies: _vm.customCurrencies,
              isMobile: _vm.isMobile,
              isTablet: _vm.isTablet,
              getSelectedCurrency: _vm.getSelectedCurrency
            }
          }),
          _vm._v(" "),
          _vm.portfolioOverviewDictionary && _vm.reportOverview
            ? _c("PortfolioReportOverviewDetail", {
                ref: "PortfolioOverviewDetail",
                attrs: {
                  model: _vm.model,
                  portfolioOverviewDictionary: _vm.portfolioOverviewDictionary,
                  reportCustomTitle: _vm.reportCustomTitle,
                  reportOverview: _vm.reportOverview,
                  success: _vm.success,
                  error: _vm.error,
                  isSubmitting: _vm.isSubmitting,
                  isMobile: _vm.isMobile,
                  isTablet: _vm.isTablet
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-if="isNotNullable()">
      <div v-if="!isMobile" class="orders-listitem-container" >
          <dt>{{ label }}</dt>
          <dd>{{ value}}</dd>
      </div>
      <OrdersDetailsContentRow v-else-if="isNotNullabledLabelAndValue()" :label="label" :value="value" />
  </div>
</template>

<script>
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import OrdersDetailsContentRow from './orders-details-content-row.component.vue';

export default {
  props: {
    label: String,
    value: String | Number       
  },
  components:{
    OrdersDetailsContentRow
  },
  mixins:[ScreenSizeMixin],
  methods: {
    isNotNullable() {
      return this.value !== null && this.value !== undefined;
    },
    isNotNullabledLabelAndValue() {
      return this.value !== null && this.value !== undefined && this.label !== null && this.label !== undefined;
    }
  },
};
</script>
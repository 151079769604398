var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transfer-settings-row-flex form-row" },
    [
      _c(
        "p",
        { staticClass: "transfer-settings-row__label col-6 font-weight-bold" },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c(
        "TransferSettingsDetailActionButton",
        _vm._g({ attrs: { item: _vm.item } }, _vm.$listeners)
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="footer-info-group" class="footer-info-group">

      <span >&copy; {{ model.currentYear }} {{ model.companyName }} &#45;
        <span id="version">
            <span aria-hidden="true">V</span>
            <span class="sr-only">Version</span>
            {{ getVersion() }}
        </span>
      </span>

  </div>
</template>

<script>
export default {
  props: {
    model: Object
  },
  methods: {
    getVersion: function () {
      return this.model.releaseVersion ?? this.model.assemblyVersion;
    },
  },
};
</script>
<template>
    <modal-basic v-if="dictionary" :title="dictionary.page.warningSkipHeader" :is-on="isModalVisible" :centered="true" :ok-footer="false" @close="$emit('close-modal')">
        <div id="cancel-add-account-modal-container" class="cancel-add-account-modal-container">
            <div id="cancel-add-account-modal-description" class="cancel-add-account-modal-description">
                <p class="rich-text-container" v-html="$sanitize(dictionary.page.warningSkipText)"></p>
                <p class="mt-2 rich-text-container" v-html="$sanitize(dictionary.page.warningSkipTextConfirmation)"></p>
            </div>
            <div id="cancel-add-account-modal-buttons button-container" class="cancel-add-account-modal-buttons" >
                <div class="cancel-add-account-modal__no-button">
                    <button class="btn secondaryButton" type="button" @click="$emit('close-modal')">
                        {{dictionary.buttons.cancel}}
                    </button>
                </div>
                <div class="cancel-add-account-modal__yes-button">
                    <button class="btn primaryButton" type="button"  @click="$emit('submit-cancel')">
                        {{dictionary.buttons.confirm}}
                    </button>
                </div>
            </div> 
        </div>
    </modal-basic>
</template>

<script>
export default {
  props: {
    dictionary: Object,
    isModalVisible: Boolean
  }
};
</script>
<template>
    <portlet-layout v-if="accountDictionary" :title="accountDictionary.widgets.account" :hideShadow="true">
        <template v-slot:title>
            {{accountDictionary.widgets.account}}
            <button v-if="mayEditAccount" type="button" class='far fa-edit beneficiary-edit-button' @click="editAccount"
                    :aria-label="accountDictionary.widgets.account + ' ' + accountDictionary.accessibility.edit"></button>
        </template>
        <template v-slot:body>
            <div v-if="!isAccountEdited">
                <AccountView :accountDataModel="accountDataModel" :dictionary="accountDictionary.account" :isAccountEdited="isAccountEdited"></AccountView>
            </div>
            <div v-else>
                <BeneficiaryAccountEditForm :accountDataEditModel="accountDataEditModel" :accountDictionary="accountDictionary" :commonDictionary="commonDictionary"
                                            :isAccountEdited="isAccountEdited" @account-edit-form-cancelled="accountEditFormCancelled" @reload-page="reloadPage"></BeneficiaryAccountEditForm>
            </div>
        </template>
    </portlet-layout>
</template>

<script>
    import AccountView from "./account-view.component.vue";
    import AccountEdit from "./account-edit.component.vue";
    import BeneficiaryAccountEditForm from "./beneficiary-account-edit-form.component.vue";

    export default {
        components: {
            AccountView,
            AccountEdit,
            BeneficiaryAccountEditForm
        },
        props: {
            accountDictionary: Object,
            commonDictionary: Object,
            accountDataModel: Object,
            accountDataEditModel: Object,
            isAccountEdited: Boolean
        },
        methods: {
            editAccount: function () {
                this.$emit("edit-account");
            },
            accountEditFormCancelled() {
                this.$emit("account-edit-form-cancelled");
            },
            reloadPage() {
                this.$emit("reload-page");
            },
        },
        computed: {
            mayEditAccount() {
                let { mayEditLocale, mayEditEmailAddress, mayEditCCEmailAddress, mayChangePassword, mayEditTelephone } = this.accountDataModel.beneficiaryBoundaries;

                return [
                    mayEditLocale,
                    mayEditEmailAddress,
                    mayEditCCEmailAddress,
                    mayChangePassword,
                    mayEditTelephone
                ].some(x => x === true);
            }
        }
    };
</script>
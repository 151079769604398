var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["internal-exchange-orderbook-table-" + _vm.type] },
    [
      _c("v-data-table", {
        class: ["table-striped", { "vertical-scroll": _vm.hasScroll }],
        attrs: {
          "no-data-text": _vm.dictionary.datatables.noRecordFound,
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.isLoading || _vm.isUpdating,
          "hide-default-footer": true,
          "items-per-page": -1,
          "fixed-header": _vm.hasScroll,
          "mobile-breakpoint": 0
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.nrOrdersFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v("\n      " + _vm._s(item.nrOrdersFormatted) + "\n    ")
                ]
              }
            },
            {
              key: "item.totalQuantityFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " + _vm._s(item.totalQuantityFormatted) + "\n    "
                  )
                ]
              }
            },
            {
              key: "item.priceFormatted",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v("\n      " + _vm._s(item.priceFormatted) + "\n    ")
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
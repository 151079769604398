<template>
    <div class="help-link-login">
        <button @click="isModalVisible = true">
            <i class="far fa-question-circle fa-2x" aria-hidden="true"></i><span class="sr-only">{{label}}</span><span class="text-center"> {{label}}</span> 
        </button>

        <modal-basic v-if="dictionary" :title="dictionary.page.supportTitle" :is-on="isModalVisible" :ok-footer="false"  @close="isModalVisible = false">
            <div>
                <div>
                    <div class="rich-text-container"  v-html="$sanitize(dictionary.page.supportText)"></div>
                </div>
            </div>
        </modal-basic> 
    </div>
</template>

<script>

import { get } from 'api';

export default {
    name: 'header-login-help',
    props: {
        label: String
    },
    data() {
        return {
            isModalVisible : false,
            dictionary: null
        }
    },
    beforeCreate() {
      get([
          "/Login/Dictionary"
      ], (responses) => {
        this.dictionary = responses.data;
        this.isLoading = false;
      }, this);
    }
}
</script>
<template>
<div class="bank-account-body">
    <template v-if="currentStep > 3">
        <FinancialAddAccountStepAccordion :heading="dictionary.subCustodianSubHeading" :isCustodian="true" :current-step="currentStep" :dictionary="dictionary" :showStep3=true>
        <dl class="details-list bank-account-details">
            <div class="details-list-left">
            <BeneficiaryDataLabelRow :value="subCustodianModel.subCustodianName" :label="dictionary.name" />
            <BeneficiaryDataLabelRow :value="subCustodianModel.subCustodianAccountName" :label="dictionary.accountName" />
            <BeneficiaryDataLabelRow :value="subCustodianModel.subCustodianAccountNumber" :label="dictionary.accountNumber" />
            </div>
            <div class="details-list-right">
            <BeneficiaryDataLabelRow :value="subCustodianModel.subCustodianDepotIdentifier" :label="dictionary.depotIdentifier" />
            <BeneficiaryDataLabelRow :value="subCustodianModel.subCustodianReference" :label="dictionary.reference" />
            </div>
        </dl>
        </FinancialAddAccountStepAccordion>
    </template>
    <FinancialAddAccountStepAccordion v-else-if="currentStep >= 3" 
        :showStep3="true"
        :heading="dictionary.subCustodianSubHeading" 
        :isCustodian="true">
        <p>{{dictionary.subCustodianHeaderDescription}}</p>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model="subCustodianModel.subCustodianName" name="sub-custodian-name" :label="dictionary.name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="subCustodianModel.subCustodianAccountName" name="subcustodian-account-name" :label="dictionary.accountName" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="subCustodianModel.subCustodianAccountNumber" name="sub-custodian-account-number" :label="dictionary.accountNumber" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <input-element v-model="subCustodianModel.subCustodianDepotIdentifier" name="sub-custodian-depot-identifier" :label="dictionary.depotIdentifier" />
            </div>
            <div class="col-md-6 col-lg-4">
                <input-element v-model="subCustodianModel.subCustodianReference" name="sub-custodian-reference" :label="dictionary.reference" />
            </div>
        </div>
        <FinancialAddAccountMandatoryTooltip :tooltipText="dictionary.mandatory" />
    </FinancialAddAccountStepAccordion>
</div>
</template>
<script>
    import FinancialAddAccountStepAccordion from "./finanacial-add-account-step-accordion.component.vue";
    import BeneficiaryDataLabelRow from "./../data-label-row.component.vue";
    import FinancialAddAccountMandatoryTooltip from "./financial-add-account-mandatory-tool-tip.component.vue";
    export default {
        components: {
            FinancialAddAccountStepAccordion,
            BeneficiaryDataLabelRow,
            FinancialAddAccountMandatoryTooltip,
        },
        props: {
            dictionary: Object,
            currentStep: Number,
            custodianData: Object,
        },
        computed: {
            subCustodianModel() {
                return this.custodianData;
            },
        }
    }
</script>
<template>
    <div>
        <div class="title-block title-actions">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.coupon.title }}</h1>
            <div v-if="!isLoading" class="title-actions-container">
                <router-link v-if="boundaries.canRequestCouponPaymentsReport" to="/reports"><span class="title-action-icon" aria-hidden="true"><i class="fas fa-download"></i></span> {{ dictionary.labels.downloadExtract }}</router-link>
            </div>
        </div>

        <div class="portlets-wrapper">
                <component-spinner :spin="isLoading"></component-spinner>
                <Coupon v-if="!isLoading" :dictionary="dictionary" :datatables-dictionary="datatablesDictionary"></Coupon>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Coupon from "./coupon.component.vue";

    export default {
        components: {
            Coupon
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                datatablesDictionary: {},
                boundaries: null,
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
            '/Coupon/Dictionary',
            '/Coupon/DatatablesDictionary',
            '/Coupon/Boundaries'
            ], responses => {
                this.dictionary = responses[0].data;
                this.datatablesDictionary = responses[1].data;
                this.boundaries = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false
            }, this);
        }
    };
</script>

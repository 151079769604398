var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "account-edit-form-body" } },
        [
          _vm.isUserNameVisible
            ? _c("beneficiary-data-label", {
                attrs: {
                  value: _vm.dataModel.userName,
                  label: _vm.dictionary.account.loginName,
                  "is-edit": ""
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("beneficiary-edit-label", {
            attrs: {
              label: _vm.dictionary.account.emailAddress,
              name: "EmailAddress",
              "is-visible":
                _vm.dataModel.beneficiaryBoundaries.mayViewEmailAddress,
              "is-editable":
                _vm.dataModel.beneficiaryBoundaries.mayEditEmailAddress,
              "error-keys": _vm.errorKeys,
              validations: _vm.validations,
              type: "email"
            },
            model: {
              value: _vm.editModel.emailAddress,
              callback: function($$v) {
                _vm.$set(_vm.editModel, "emailAddress", $$v)
              },
              expression: "editModel.emailAddress"
            }
          }),
          _vm._v(" "),
          _vm.dataModel.beneficiaryBoundaries.mayViewCCEmailAddress
            ? _c("input-element", {
                staticClass: "beneficiary-details",
                attrs: {
                  name: "CcEmailAddress",
                  type: "text",
                  label: _vm.dictionary.account.ccEmailAddress,
                  disabled: !_vm.dataModel.beneficiaryBoundaries
                    .mayEditCCEmailAddress
                },
                model: {
                  value: _vm.editModel.ccEmailAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.editModel, "ccEmailAddress", $$v)
                  },
                  expression: "editModel.ccEmailAddress"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.beneficiaryBoundaries.mayViewPersonalTelephone
            ? _c("input-element", {
                staticClass: "beneficiary-details",
                attrs: {
                  name: "Telephone",
                  type: "text",
                  label: _vm.dictionary.account.mobileTelephone,
                  disabled: !_vm.dataModel.beneficiaryBoundaries
                    .mayEditTelephone,
                  validationsList: _vm.validationLists.mobileTelephoneValidation
                },
                model: {
                  value: _vm.editModel.telephone,
                  callback: function($$v) {
                    _vm.$set(_vm.editModel, "telephone", $$v)
                  },
                  expression: "editModel.telephone"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.beneficiaryBoundaries &&
          _vm.dataModel.beneficiaryBoundaries.mayChangePassword
            ? _c(
                "div",
                [
                  _c("BeneficiaryAccountChangePasswordLink", {
                    attrs: {
                      label: _vm.dictionary.account.password,
                      link: _vm.dictionary.account.changePassword
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.locale &&
          _vm.dataModel.beneficiaryBoundaries.mayEditLocale
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "form-group beneficiary-details" },
                  [
                    _c("custom-dropdown", {
                      attrs: {
                        "id-name": "LocaleId",
                        "use-inline-label": true,
                        "show-label": true,
                        items: _vm.items,
                        label: _vm.dictionary.account.language,
                        "initial-item": _vm.dataModel.locale.name,
                        "event-name": "change-language",
                        "aria-label": "choose a language"
                      },
                      model: {
                        value: _vm.editModel.locale.id,
                        callback: function($$v) {
                          _vm.$set(_vm.editModel.locale, "id", $$v)
                        },
                        expression: "editModel.locale.id"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.beneficiaryBoundaries.mayEditLocale
            ? _c(
                "div",
                [
                  _c("beneficiary-select-edit-label", {
                    attrs: {
                      label: _vm.dictionary.account.decimalSeparator,
                      name: "DecimalSeparator",
                      options: _vm.decimalSeparators,
                      "is-editable":
                        _vm.dataModel.beneficiaryBoundaries.mayEditLocale,
                      "error-keys": _vm.errorKeys,
                      validations: _vm.validations
                    },
                    model: {
                      value: _vm.editModel.decimalSeparator.value,
                      callback: function($$v) {
                        _vm.$set(_vm.editModel.decimalSeparator, "value", $$v)
                      },
                      expression: "editModel.decimalSeparator.value"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("FormButtons", {
        attrs: { commonDictionary: _vm.commonDictionary },
        on: {
          "submit-form": function($event) {
            return _vm.submitForm()
          },
          "cancel-form": function($event) {
            return _vm.$emit("cancel-form")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
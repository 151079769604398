var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    ref: "portfolioOverview",
    attrs: {
      title: _vm.reportCustomTitle,
      "is-custom-title": true,
      "is-custom-body": true
    },
    scopedSlots: _vm._u([
      {
        key: "customTitle",
        fn: function() {
          return [
            !_vm.isMobile
              ? _c(
                  "a",
                  {
                    attrs: {
                      id: "printPage",
                      href: "javascript:void(0)",
                      role: "button",
                      title: _vm.portfolioOverviewDictionary.labels.linkPrint
                    },
                    on: { click: _vm.printPage }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "title-action-icon",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_c("i", { staticClass: "far fa-print p-1" })]
                    ),
                    _vm._v(
                      _vm._s(_vm.portfolioOverviewDictionary.labels.linkPrint)
                    )
                  ]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("loading-spinner", { attrs: { active: _vm.isSubmitting } }),
            _vm._v(" "),
            _vm.success
              ? _c("alert-component", { attrs: { variant: "success" } }, [
                  _vm._v(_vm._s(_vm.success))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("alert-component", { attrs: { variant: "error" } }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "portfolio-values-container" } },
              [
                _vm.reportOverview.values.length
                  ? _c("PortfolioPlanValues", {
                      attrs: {
                        title:
                          _vm.portfolioOverviewDictionary.labels.portfolioTotal,
                        values: _vm.reportOverview.values
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.mostOfSecurityPricesValuesAreNotZero()
              ? _c(
                  "div",
                  { attrs: { id: "portfolio-plans-container" } },
                  [
                    _c("div", { staticClass: "p-3" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioOverviewDictionary.labels.breakdown
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.reportOverview.plans, function(plan, index) {
                      return _c(
                        "div",
                        { key: plan.planName + "-" + index },
                        [
                          _c(
                            "portlet-row",
                            {
                              staticClass: "plan-portlet-row",
                              attrs: { title: plan.planName }
                            },
                            [
                              plan.values.length
                                ? _c("PortfolioPlanValues", {
                                    attrs: {
                                      title:
                                        _vm.portfolioOverviewDictionary.labels
                                          .planValue,
                                      values: plan.values
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("PortfolioOverviewTable", {
                                attrs: {
                                  dictionary: _vm.portfolioOverviewDictionary,
                                  records: plan.moneyPositions.concat(
                                    plan.securityPositions
                                  ),
                                  isMobile: _vm.isMobile,
                                  isTablet: _vm.isTablet
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _c("div", [
                  _c("div", { staticClass: "p-3" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.portfolioOverviewDictionary.labels
                            .errorNoResultsReturned
                        )
                      )
                    ])
                  ])
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-md-flex justify-content-end" }, [
              _c("div", [
                _vm.isMobile
                  ? _c(
                      "button",
                      {
                        staticClass: "btn secondaryButton pl-2",
                        attrs: { type: "button" },
                        on: { click: _vm.printPage }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioOverviewDictionary.labels.linkPrint
                          )
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn primaryButton",
                    attrs: {
                      type: "button",
                      disabled: !_vm.mostOfSecurityPricesValuesAreNotZero()
                    },
                    on: { click: _vm.sendToInboxPortfolio }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.portfolioOverviewDictionary.labels.btnSendToInbox
                      )
                    )
                  ]
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="footer-links-collection">
        <router-link to="/cookiePolicy" :title="dictionary.linkCookiePolicy"  >
          {{dictionary.linkCookiePolicy}}
        </router-link>
        
        <router-link to="/disclaimer" :title="dictionary.linkDisclaimer" >
          {{dictionary.linkDisclaimer}}
        </router-link>

        <a v-if="toggles.showTermsAndConditionsLink" :href="$sanitize(dictionary.footerLinkTermsAndConditionsUrl)" :title="dictionary.footerLinkTermsAndConditions" target="_blank" rel="noopener">
          {{ dictionary.footerLinkTermsAndConditions }}
        </a>

        <a v-if="toggles.showPrivacyPolicyLink" :href="$sanitize(dictionary.footerLinkPrivacyPolicyUrl)" :title="dictionary.linkPrivacyPolicy" target="_blank" rel="noopener">
            {{ dictionary.linkPrivacyPolicy }}
        </a>
    </div>
</template>

<script>

export default {
  props: {
    dictionary: Object,
    toggles: Object
  }
};
</script>
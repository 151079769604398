<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-lg-4">
          <TablePlanDocumentsFilterByPlanName :dictionary="dictionary" :plans="plans" @filter-plan-documents="filterPlanDocuments" />
      </div>
      <div class="col-md-3 col-lg-2">
           <TablePlanDocumentsSortByDate :dictionary="dictionary" @sort-by-plan-document-date="sortByPlanDocumentDate" />
      </div>
    </div>
  </div>
</template>

<script>
import TablePlanDocumentsSortByDate from "./table-plan-documents-sort-by-date.component.vue";
import TablePlanDocumentsFilterByPlanName from "./table-plan-documents-filter-by-plan-name.component.vue";

export default {
  components: {
    TablePlanDocumentsSortByDate,
    TablePlanDocumentsFilterByPlanName,
  },
  props: {
    dictionary: Object,
    plans: Array,
  },
  data() {
    return {
      selectedPlans: [],
    };
  },
  methods: {
    filterPlanDocuments: function (planName) {
      this.$emit("filter-plan-documents", planName);
    },
    sortByPlanDocumentDate: function (type) {
      this.$emit("sort-by-plan-document-date", type);
    },
  },
};
</script>
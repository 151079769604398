var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "table-striped",
        attrs: {
          "no-data-text": _vm.dictionary.table.noRecordFound,
          "item-key": "dividendParticipationId",
          "hide-default-header": _vm.isMobile,
          headers: _vm.computedHeaders,
          items: _vm.rows,
          loading: _vm.isLoading,
          "server-items-length": _vm.totalRecords,
          search: _vm.search,
          "items-per-page": _vm.options.itemsPerPage,
          options: _vm.options,
          "hide-default-footer": ""
        },
        on: {
          "page-count": function($event) {
            _vm.options.pageCount = $event
          },
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("datatable-search", {
                    attrs: { label: _vm.datatablesDictionary.searchByKeywords },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.subscribeButton",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.subscribe(
                            item.dividendParticipationId,
                            item.dividendAllocationId
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.dictionary.subscriptionLabels.btnSubscribe)
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "item.startDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\r\n            " +
                      _vm._s(_vm._f("toDateTime")(item.startDate)) +
                      "\r\n        "
                  )
                ]
              }
            },
            {
              key: "item.endDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\r\n            " +
                      _vm._s(_vm._f("toDateTime")(item.endDate)) +
                      "\r\n        "
                  )
                ]
              }
            },
            {
              key: "item.sharesDividendAmountPercentage",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\r\n            " +
                      _vm._s(
                        _vm.formatAllocationPercentage(
                          item.sharesDividendAmountPercentage
                        )
                      ) +
                      "\r\n        "
                  )
                ]
              }
            },
            {
              key: "item.moneyDividendAmountPercentage",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\r\n            " +
                      _vm._s(
                        _vm.formatAllocationPercentage(
                          item.moneyDividendAmountPercentage
                        )
                      ) +
                      "\r\n        "
                  )
                ]
              }
            },
            _vm.isMobile
              ? {
                  key: "body.prepend",
                  fn: function() {
                    return [
                      _c("datatable-mobile-header", {
                        attrs: {
                          options: _vm.options,
                          sortableColumns: _vm.sortableColumns,
                          dictionary: _vm.datatablesDictionary
                        },
                        on: {
                          onSortChange: function($event) {
                            return _vm.onSortChange()
                          }
                        },
                        model: {
                          value: _vm.sortBy,
                          callback: function($$v) {
                            _vm.sortBy = $$v
                          },
                          expression: "sortBy"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobile
              ? {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("DividendSubscriptionDetailsMobile", {
                        attrs: {
                          dictionary: _vm.dictionary,
                          dividendSubscription: item,
                          isMobile: _vm.isMobile
                        },
                        on: { subscribe: _vm.subscribe }
                      })
                    ]
                  }
                }
              : null,
            {
              key: "footer",
              fn: function() {
                return [
                  _c("datatable-pagination", {
                    attrs: { datatablesDictionary: _vm.datatablesDictionary },
                    model: {
                      value: _vm.options,
                      callback: function($$v) {
                        _vm.options = $$v
                      },
                      expression: "options"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export const stepperAccessibilityPreviousNextLabel = {
    methods: {
        nextStepAccessibilityLabel(currentStep, maxNumberOfSteps) {
            if (currentStep === maxNumberOfSteps) 
                return '';
            return `step-${currentStep + 1}`;
        },
        previousStepAccessibilityLabel(currentStep) {
            return `step-${currentStep - 1}`;
        }
    }
}
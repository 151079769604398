<template>
<div id="expansion-panel-choice">
    <v-expansion-panels :class="['choice-details-expansion-block', 'mobile', 
        { 'expansion-block-margin-bottom' : !isPanelOpened }]" v-model="panel" accordion
    >
        <v-expansion-panel class="choice-details-expansion-panel">
            <v-expansion-panel-header class="choice-details-expansion-panel__header">
                <h3 class="h4 text-left">{{ dictionary.table.headingDetails }}: {{ choice.title }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mobile">
                <ChoiceDetailsContent :dictionary="dictionary" :choice="choice"  :actionLinkText="actionLinkText" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</div>
</template>

<script>
import ChoiceDetailsContent from "./choice-details-content.component.vue";

export default {
  components: {
    ChoiceDetailsContent,
  },
  props: {
    choice: Object,
    dictionary: Object,
    actionLinkText: String,
  },
  data() {
    return {
      panel: null,
    };
  },
  computed: {
    isPanelOpened() {
      return this.panel !== undefined && this.panel !== null;
    },
  },
};
</script>
<template>
    <div class="tab-row">
        <b-tabs content-class="mt-0" class="tab-bar tab-container" ref="tabs">
            <portlet-tab :tabTitle="dictionary.tabs.active" :isActive="true" tabId="tabActiveChoices">
                <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.choicesPageDescriptionActive)"></div>
                <ChoiceTable tableId="TableActiveChoices" :dictionary="dictionary" :noRecordFoundText="dictionary.table.noActiveChoices" getUrl="/choices/ActiveChoices" :datatablesDictionary="datatablesDictionary" />
            </portlet-tab>
            <portlet-tab :tabTitle="dictionary.tabs.historical" tabId="tabHistoricalChoices">
                <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.choicesPageDescriptionHistorical)"></div>
                <ChoiceTable tableId="TableHistoricalChoices" :dictionary="dictionary" :noRecordFoundText="dictionary.table.noHistoricalChoices" getUrl="/choices/HistoricalChoices" :datatablesDictionary="datatablesDictionary" />
            </portlet-tab>
        </b-tabs>
    </div>
</template>

<script>
    import ChoiceTable from './choice-table.component.vue';

    export default {
        components: {
            ChoiceTable
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object
        },
        data() {
            return {
                tabs: ['#tabActiveChoices', '#tabHistoricalChoices']
            }
        },
        mounted() {
            this.$refs.tabs.currentTab = this.tabs.findIndex(tab => tab === this.$route.hash)
        }
    }
</script>
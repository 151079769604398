var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.datatablesDictionary
    ? _c(
        "div",
        { attrs: { id: "required-documents-table-container" } },
        [
          _c("v-data-table", {
            staticClass: "table-striped",
            attrs: {
              "item-key": "RequiredDocumentId",
              "no-data-text": _vm.dictionary.labels.noRecordRequiredDocuments,
              "hide-default-header": _vm.isMobile,
              headers: _vm.computedHeaders,
              items: _vm.rows,
              options: _vm.options,
              loading: _vm.isLoading,
              "server-items-length": _vm.totalRecords,
              "hide-default-footer": "",
              search: _vm.search
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.expiryDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("toDate")(item.expiryDate)) +
                          "\n        "
                      )
                    ]
                  }
                },
                _vm.isMobile
                  ? {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "RequiredDocumentsDetailsMobileContent",
                            _vm._g(
                              {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  "required-document": item
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ]
                      }
                    }
                  : null,
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn primaryButton",
                          attrs: { role: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "action",
                                item.requiredDocumentId,
                                item.requiredDocumentParticipationId
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.getActionButtonText(item, _vm.dictionary)
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
      <div v-if="currentQuestionnaire">
        <div v-for="(currentQuestion, index) in currentQuestionnaire.questions" :key="index" class="question-container">
            <QuestionDetail 
                :currentQuestion="currentQuestion" 
                :questionIndex="index" 
                :showCorrectAnswer="showCorrectAnswer" 
                :dictionary="dictionary" 
                :v="v"
                @change-answer="handleChangeAnswer" />
        </div>
    </div>
</template>

<script>
import QuestionDetail from "./question-detail.component.vue";

export default {
  components: {
    QuestionDetail
  },
  props: {
    currentQuestionnaire: Object,
    showCorrectAnswer: Boolean,
    dictionary: Object,
    v:Object
  },
  methods: {
    handleChangeAnswer(){
      this.$emit('change-answer');
    }
  }
};
</script>
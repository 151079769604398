import { render, staticRenderFns } from "./required-documents-details-form.component.vue?vue&type=template&id=dc597614&"
import script from "./required-documents-details-form.component.vue?vue&type=script&lang=js&"
export * from "./required-documents-details-form.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Monidee-Test\\A3\\_work\\1\\s\\Monidee.tOption\\Monidee.tOption.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc597614')) {
      api.createRecord('dc597614', component.options)
    } else {
      api.reload('dc597614', component.options)
    }
    module.hot.accept("./required-documents-details-form.component.vue?vue&type=template&id=dc597614&", function () {
      api.rerender('dc597614', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Features/RequiredDocuments/Assets/Vue/required-documents-details-form.component.vue"
export default component.exports
<template>
  <div v-if="dictionary">
    <div>
      <p>{{ headerDescription }}</p>
    </div>
    <alert-component v-if="error" variant="error">{{ error }}</alert-component>
    <loading-spinner :active="isLoading || isSkipping"></loading-spinner>
    <div v-if="disclaimers && disclaimers.length" class="disclaimers mb-4">
      <div v-for="(disclaimer, index) in disclaimers" :key="index" class="disclaimer-block mb-2">
        <div class="disclaimer-header mb-1">
          <input :id="`disclaimerCheckbox-${index}`" type="checkbox" v-model="disclaimer.isAccepted" />
          <label :for="`disclaimerCheckbox-${index}`" class="font-weight-bold mb-0 form-input-label" >
            {{ disclaimer.title }}
          </label>
          <div class="disclaimer-required ml-auto" v-if="disclaimer.isMandatory">
              {{dictionary.labels.required}}
          </div>
        </div>
        <div class="disclaimer-body ml-1 rich-text-container" v-html="$sanitize(disclaimer.text)"></div>
        <div class="disclaimer-download" v-if="!!disclaimer.documentId">
          <a :href="$sanitize(`/LoginDisclaimers/DownloadDisclaimer?documentType=${disclaimer.documentType}&documentId=${disclaimer.documentId}`)" :title="dictionary.labels.btnDownload">
            <i class="far fa-download p-1" aria-hidden="true"></i> {{disclaimer.documentName}}
          </a>
        </div>
      </div>
    </div>
    <div class="d-md-flex justify-content-end disclaimer-buttons-container">
      <button v-if="canSkipDisclaimers" class="btn secondaryButton" @click="skip">{{ dictionary.labels.btnSkip }}</button>
      <button class="btn primaryButton" :disabled="disableSubmitButton" @click="submitDisclaimers">{{ acceptButtonTitle }}</button>
    </div>
  </div>
</template>

<script>
import { get, post } from "api";
import { EventBus } from "Shared/event-bus";

export default {
  props: {
    dictionary: Object,
    disclaimers: Array,
    boundaries: Object
  },
  data() {
    return {
        isLoading:false,
        isSkipping:false,
        error:''
    }
  },
  computed: {
    disableSubmitButton() {
        return !this.disclaimers.some(disclaimer => disclaimer.isAccepted);
    },
    canSkipDisclaimers() {
        return !this.boundaries.hasUnsignedImmediateOrExpiredOnOrBeforeDisclaimers
    },
    hasOptionalDisclaimers() {
        return this.boundaries.hasUnsignedActiveOnOrBeforeDisclaimers
    },
    headerDescription() {
      return this.hasOptionalDisclaimers
        ? this.dictionary.labels.descriptionImmediateAndLater  
        : this.dictionary.labels.descriptionImmediateOnly 
    },
    acceptButtonTitle() {
      return this.hasOptionalDisclaimers
        ? this.dictionary.labels.btnAcceptImmediateAndLater  
        : this.dictionary.labels.btnAcceptImmediateOnly 
    }
  },
  methods: {
    skip() {
      this.isSkipping = true;
      get(
          "/LoginDisclaimers/SkipDisclaimers",
          (response) => {
            EventBus.$emit("event-reload-layout", '/dashboard');
          },
          this,
          () => {
            this.isSkipping = false;
          },
          (error) => {
            this.isSkipping = false;
            this.error = error.response.data;
          }
        );
    },
    submitDisclaimers() {
      this.error = '';
      this.isLoading = true;
      const acceptedDisclaimers = this.disclaimers.filter(disclaimer => disclaimer.isAccepted);
      post(
          "/LoginDisclaimers/SubmitDisclaimers",
          acceptedDisclaimers,
          (response) => {
            EventBus.$emit("event-reload-layout", '/dashboard');
            this.$emit("reloadDisclaimers");
          },
          this,
          () => {
            this.isLoading = false;
          },
          (error) => {
            this.error = error.response.data;
            this.isLoading = false;
          }
        );
    }
  }
};
</script>

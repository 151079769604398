var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { staticClass: "order-detail", on: { click: _vm.onClick } }, [
    _vm._v(_vm._s(_vm.productName))
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <label class="form-input-label d-md-flex align-items-center">
        <span>
            <strong>{{label}}</strong> 
        </span>
        <span class="px-2">
            <tooltip  :tooltip-text="tooltipText"  :hideTooltip="false" ></tooltip>
        </span>
        <span class="px-2">
            <slot name="reset-action"></slot>        
        </span>
    </label>
</template>

<script>
export default {
    props: {
        label: String,
        tooltipText: String,        
    }
}
</script>

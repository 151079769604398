<template>
<div>
    <v-card class="dividend-purchase">        
        <v-card-title class="dividend-purchase-title">
            <h3 class="primary-color h4">{{ dictionary.widgets.detail }} : {{ dividend.distributionDate }}</h3>
        </v-card-title>
        <DividendDetails class="flex-row" :dictionary="dictionary" :dividend="dividend" :toggles="toggles" :isTablet="isTablet" />
    </v-card>    
</div>
</template>

<script>
import DividendDetails from "./dividend-details.component.vue";

export default {
  components: {
    DividendDetails,
  },
  props: {
    dividend: Object,
    dictionary: Object,
    toggles: Object,
    isTablet:Boolean
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "orders-row-flex" }, [
        _c("p", { staticClass: "orders-row__label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "orders-row-flex" }, [
        _c(
          "p",
          { class: ["orders-row__value", { "text-right": _vm.textRight }] },
          [_vm._v(_vm._s(_vm.replaceNullOrEmpty(_vm.value)))]
        )
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "orders-row-divider" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
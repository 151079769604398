<template>
    <div class="mfa-pac">
        <div class="mfa-pac-body">
            <p>
                <span class="rich-text-container" v-html="$sanitize(dictionary.beneficiaryAlertMFALostPhone)"></span>
            </p>
            <button class="btn btn-block mt-3 secondaryButton" type="button" @click="$emit('cancel')">
                {{ dictionary.buttonMfaCancel }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
            props: {
                dictionary: Object,
            }
    };
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("component-spinner", { attrs: { spin: _vm.isLoading } })
    : _vm.dictionary
    ? _c(
        "div",
        { staticClass: "required-info-box" },
        [
          _c("RequiredInfoCRSTimeline", {
            attrs: { dictionary: _vm.dictionary }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
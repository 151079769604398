var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.beneficiaryBoundariesSpouseInfo.mayViewSpouseFirstName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.spouseDataModel.spouseFirstName,
              label: _vm.dictionary.spouseFirstName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesSpouseInfo.mayViewSpouseLastName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.spouseDataModel.spouseLastName,
              label: _vm.dictionary.spouseLastName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesSpouseInfo.mayViewSpouseMaidenName
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.spouseDataModel.spouseMaidenName,
              label: _vm.dictionary.spouseMaidenName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesSpouseInfo.mayViewSpouseInitials
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.spouseDataModel.spouseInitials,
              label: _vm.dictionary.spouseInitials
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.beneficiaryBoundariesSpouseInfo.mayViewSpouseGender
        ? _c("beneficiary-data-label", {
            attrs: {
              value: _vm.spouseDataModel.spouseGender,
              label: _vm.dictionary.spouseGender
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
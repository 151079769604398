var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "dividend-purchase" },
        [
          _c("v-card-title", { staticClass: "dividend-purchase-title" }, [
            _c("h3", { staticClass: "primary-color h4" }, [
              _vm._v(
                _vm._s(_vm.dictionary.widgets.detail) +
                  " : " +
                  _vm._s(_vm.dividend.distributionDate)
              )
            ])
          ]),
          _vm._v(" "),
          _c("DividendDetails", {
            staticClass: "flex-row",
            attrs: {
              dictionary: _vm.dictionary,
              dividend: _vm.dividend,
              toggles: _vm.toggles,
              isTablet: _vm.isTablet
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
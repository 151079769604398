<template>
  <section>
    <modal-basic :title="title" :is-on="isModalVisible" :ok-footer="!hasModalFooter" @close="isModalVisible = false">
      <component :is="transactionTypeModalElement" v-bind="{ 'instruction-data': instructionData, 'dictionary': dictionary, 'toggles': toggles }"></component>
      <template v-slot:footer>
        <InternalExchangeCancelOrderModalWrapper v-if="hasModalFooter" :instructionData="instructionData" :dictionary="dictionary" @close="isModalVisible = false" />
      </template>
    </modal-basic>
  </section>
</template>

<script>
import BuyDetail from "./buy-detail.component.vue";
import ExitDetail from "./exit-detail.component.vue";
import ExerciseDetail from "./exercise-detail.component.vue";
import InternalExchangeDetail from "./internal-exchange-detail.component.vue";
import InternalExchangeCancelOrder from "./internal-exchange-cancel-order.component.vue";
import InternalExchangeCancelOrderModalWrapper from './internal-exchange-cancel-order-modal-wrapper.component.vue';
import { EventBus } from "Shared/event-bus";
import { getAll } from "api";

export default {
  components: {
    BuyDetail,
    ExitDetail,
    ExerciseDetail,
    InternalExchangeDetail,
    InternalExchangeCancelOrder,
    InternalExchangeCancelOrderModalWrapper
  },
  props: {
    title: String,
    toggles: Object
  },
  data() {
    return {
      dictionary: {},
      isModalVisible: false,
      instructionData: {},
      transactionType: "",
      cancelOrder: false,
      transactionTypeRequestDictionary: {
        [this.$TransactionType.EXIT]: "Exits",
        [this.$TransactionType.INTERNAL_EXCHANGE]: "InternalExchanges",
        [this.$TransactionType.EXERCISE]: "Exercises",
        [this.$TransactionType.BUY]: "Buy"
      }
    };
  },
  computed: {
    transactionTypeModalElement() {
      return this.transactionTypeModalDictionary[this.transactionType];
    },
    hasModalFooter() {
      return this.transactionType === this.$TransactionType.INTERNAL_EXCHANGE && this.cancelOrder;
    },
    transactionTypeModalDictionary(){
      return {
        [this.$TransactionType.EXIT]: "exit-detail",
        [this.$TransactionType.INTERNAL_EXCHANGE]: this.cancelOrder ? "internal-exchange-cancel-order" : "internal-exchange-detail",
        [this.$TransactionType.BUY]: "buy-detail",
        [this.$TransactionType.EXERCISE]: "exercise-detail"
      }
    }
  },
  methods: {
    loadModalData(instructionId, transactionType) {
      this.transactionType = transactionType;
      this.instructionData = {};

      const requestUrl = `/Orders/GetInstructionDetails?instructionId=${instructionId}&transactionType=${transactionType}`;
      const dictionaryUrl = `/${this.transactionTypeRequestDictionary[this.transactionType]}/Dictionary`

      getAll([requestUrl, dictionaryUrl], (response) => {
          this.transactionType = transactionType;
          this.instructionData = response[0].data;
          this.dictionary = response[1].data;

          this.isModalVisible = true;
        }, this);
    },
  },
  mounted() {
    EventBus.$on("event-get-order-detail", (payLoad) =>  {
      this.cancelOrder = payLoad.cancelOrder || false;
      this.loadModalData(payLoad.instructionId, payLoad.instructionType);
    });
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dictionary
    ? _c(
        "div",
        {
          staticClass: "orders-table-container",
          attrs: { id: "orders-table-container" }
        },
        [
          _vm.hasExitSecondaryPositions
            ? _c("div", [
                _c("small", [
                  _vm._v("*" + _vm._s(_vm.dictionary.multiplePositions))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "table-striped",
            attrs: {
              id: _vm.id,
              "item-key": "reference",
              "no-data-text": _vm.dictionary.noRecordOrders,
              "hide-default-header": _vm.isMobile,
              headers: _vm.headers,
              items: _vm.rows,
              loading: _vm.isLoading,
              "server-items-length": _vm.totalRecords,
              search: _vm.search,
              "mobile-breakpoint": 0,
              "items-per-page": _vm.options.itemsPerPage,
              options: _vm.options,
              "hide-default-footer": ""
            },
            on: {
              "page-count": function($event) {
                _vm.options.pageCount = $event
              },
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c("datatable-search", {
                        attrs: {
                          isMobile: _vm.isMobile,
                          label: _vm.datatablesDictionary.searchByKeywords
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.submissionDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toDateTime")(item.submissionDate)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.expirationDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toDateTime")(item.expirationDate)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.limitPrice",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toMinusIfNull")(item.limitPrice)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.averagePrice",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("toMinusIfNull")(item.averagePrice)) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.totalTransactionValue",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("toMinusIfNull")(item.totalTransactionValue)
                          ) +
                          "\n        "
                      )
                    ]
                  }
                },
                {
                  key: "item.productName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.canBeCancelledByBeneficiary
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mobile-column tablet-column primary-color"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDateTime")(item.submissionDate)
                                ) +
                                  " | " +
                                  _vm._s(item.ordered) +
                                  " | " +
                                  _vm._s(item.state) +
                                  " | \n                "
                              ),
                              item.canBeCancelledByBeneficiary
                                ? _c("OrderCancelLink", {
                                    attrs: {
                                      order: item,
                                      "cancel-link-text":
                                        _vm.dictionary.btnCancelOrder
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !item.canBeCancelledByBeneficiary
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mobile-column tablet-column primary-color"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDateTime")(item.submissionDate)
                                ) +
                                  " | " +
                                  _vm._s(item.ordered) +
                                  " | " +
                                  _vm._s(item.state)
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [_c("OrderDetailsLink", { attrs: { order: item } })],
                        1
                      )
                    ]
                  }
                },
                _vm.hasCanBeCancelledByBeneficiarys
                  ? {
                      key: "item.canBeCancelledByBeneficiary",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.canBeCancelledByBeneficiary
                            ? _c("OrderCancelLink", {
                                attrs: {
                                  order: item,
                                  "cancel-link-text":
                                    _vm.dictionary.btnCancelOrder
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  : null,
                _vm.isMobile
                  ? {
                      key: "body.prepend",
                      fn: function() {
                        return [
                          _c("datatable-mobile-header", {
                            attrs: {
                              options: _vm.options,
                              sortableColumns: _vm.headers,
                              dictionary: _vm.datatablesDictionary
                            },
                            on: {
                              onSortChange: function($event) {
                                return _vm.onSortChange()
                              }
                            },
                            model: {
                              value: _vm.sortBy,
                              callback: function($$v) {
                                _vm.sortBy = $$v
                              },
                              expression: "sortBy"
                            }
                          }),
                          _vm._v(" "),
                          _c("OrdersDetailsMobileHeader", {
                            attrs: { title: _vm.dictionary.positionName }
                          })
                        ]
                      },
                      proxy: true
                    }
                  : null,
                _vm.isMobile
                  ? {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("OrdersDetailsMobileRow", {
                            attrs: {
                              dictionary: _vm.dictionary,
                              toggles: _vm.toggles,
                              item: item
                            }
                          })
                        ]
                      }
                    }
                  : null,
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("datatable-pagination", {
                        attrs: {
                          datatablesDictionary: _vm.datatablesDictionary
                        },
                        model: {
                          value: _vm.options,
                          callback: function($$v) {
                            _vm.options = $$v
                          },
                          expression: "options"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
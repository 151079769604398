var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inactivity-prompt-buttons button-container" },
    [
      _c("div", { staticClass: "inactivity-prompt-buttons__signout-button" }, [
        _c(
          "button",
          {
            staticClass: "btn secondaryButton",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("signout")
              }
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.dictionary.signOutButton) +
                "\n        "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inactivity-prompt-buttons_continue-button" }, [
        _c(
          "button",
          {
            staticClass: "btn primaryButton",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("continue")
              }
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.dictionary.continueButton) +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div>
            <disclaimers :disclaimers="intExchangeInstructionModel.disclaimers" />
        </div>
        <InternalExchangeConfirmationButtonsComponent :intExchangeInstructionModel="intExchangeInstructionModel" v-on="$listeners" />
    </div>
</template>

<script>
import InternalExchangeConfirmationButtonsComponent from "./internal-exchanges-confirmation-buttons.component.vue";

export default {
  components: {
    InternalExchangeConfirmationButtonsComponent,
  },
  props: {
    intExchangeInstructionModel: Object,
  },
};
</script>
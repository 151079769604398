<template>
<portlet-layout v-if="!!accountDataModel.fiscalData && accountDataModel.beneficiaryCompany === null" :title="accountDictionary.widgets.fiscal" :hideShadow="true">
    <template v-slot:body>
        <div>
            <FiscalView :fiscalDataModel="accountDataModel.fiscalData" :dictionary="accountDictionary.fiscal"></FiscalView>
        </div>
    </template>
</portlet-layout>
</template>

<script>
import FiscalView from "./fiscal-view.component.vue";

export default {
  components: {
    FiscalView,
  },
  props: {
    accountDictionary: Object,
    accountDataModel: Object,
  },
};
</script>
<template>
  <div class="portfolio-plan-header-values" :class="{ 'mt-2': isMobile }" v-if="showPlanHeader">
    <ul>
      <li v-if="showSecurityPrice" class="plan-value-container" :class="{ 'mobile': isMobile }">
        <span>{{ dictionary.block.planHeaderSecurityPriceLabel }}</span>
        <span class="portfolio-plan-header-value">{{ portfolioPlan.securityPriceFormatted }}</span>
        <PortfolioTooltip :hideTooltip="toggles.hideSecurityPriceInformationTooltip" class="hideInMobile plan-value-tooltip" placement="top" icon="far fa-info-circle" :tooltip-text="tooltipSecurityPrice" />
      </li>

      <li v-if="showPlanValue" class="plan-value-container" :class="{ 'mobile': isMobile }" >
        <template v-if="portfolioPlan.values.length > 1">

          <label class="form-input-label" :for="uniqueId" >{{ dictionary.table.value }}</label>
          <b-select :id="uniqueId" 
                    :options="portfolioPlan.values"  
                    text-field="valueFormatted" 
                    value-field="value" 
                    v-model="selected"
                    class="value-block-select">
          </b-select>
        </template>
        <template v-else>
          <span>{{ dictionary.table.value }}:</span>
          <span class="portfolio-plan-header-value">{{ planValue }}</span>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
  import { v4 as uuid } from 'uuid';

  import 'bootstrap-filestyle2';
  import PortfolioTooltip from './portfolio-tooltip.component.vue';

  export default {
    components: {
      PortfolioTooltip
    },
    data() {
      return {
        selected: this.portfolioPlan.values.length > 1 ? this.portfolioPlan.values[0].value : null
      }
    },
    props: {
      dictionary: Object,
      toggles: Object,
      portfolioPlan: Object,
      isMobile: Boolean
    },
    computed: {
      uniqueId() {
        return uuid();
      },
      showSecurityPrice() {
        return this.portfolioPlan.containsSingleSecurity === true && this.toggles.hidePortfolioPlanSubTitleSecurityPrice === false;
      },
      showPlanValue() {
        return this.toggles.hidePortfolioPlanSubTitleValue === false;
      },
      planValue() {
        return this.portfolioPlan.values.length ? this.portfolioPlan.values[0].valueFormatted : "-";
      },
      showPlanHeader() {
        return !this.portfolioPlan.hideSecurityPrice || !this.portfolioPlan.hidePlanValue;
      },
      tooltipSecurityPrice() {
        const securityPriceDate = this.$options.filters.toDateTime(this.portfolioPlan.lastSecurityPriceDate).replace('00:00:00', '')
        return `${this.dictionary.block.tooltipSharePrice} ${securityPriceDate}`;
      },
    },
  }
</script>
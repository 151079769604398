<template>
  <component-spinner v-if="showSpinner" :spin="showSpinner"></component-spinner>
  <div v-else>
    <template v-if="errors && errors.length > 0">
      <form-error-alert-component :errors="errors"></form-error-alert-component>
    </template>
    <template v-if="hasHeaders">
      <div class="mb-2 rich-text-container" v-for="(header, index) in this.boundaries.headers" :key="index" v-html="$sanitize(header)"></div>
    </template>
    <timeline-container v-if="!!requiredInfo">
      <timeline-step :current-step="currentStep" :step="1">
        <required-info-crs-step-account-holder
          :current-step="currentStep"
          :step="1"
          :steps="steps"
          :dictionary="dictionary"
          :countries="knownCountries"
          :required-info-data="requiredInfo"
          @next-step="nextStep"
          @previous-step="previousStep"
          @edit-step="editStep"
          class="timeline-step"
        />
      </timeline-step>
      <timeline-step :current-step="currentStep" :step="2">
        <required-info-crs-step-tax-residency
          :current-step="currentStep"
          :step="2"
          :steps="steps"
          :dictionary="dictionary"
          :required-info-data="requiredInfo"
          :countries="knownCountries"
          @next-step="nextStep"
          @previous-step="previousStep"
          @edit-step="editStep"
          class="timeline-step"
        />
      </timeline-step>
      <timeline-step :current-step="currentStep" :step="3">
        <required-info-crs-step-declaration
          :current-step="currentStep"
          :step="3"
          :steps="steps"
          :dictionary="dictionary"
          :required-info-data="requiredInfo"
          @next-step="nextStep"
          @previous-step="previousStep"
          class="timeline-step"
        />
      </timeline-step>
    </timeline-container>
    <loading-spinner :active="submitingData"></loading-spinner>
  </div>
</template>

<script>
import { get, post } from "api";
import { v4 as uuid } from "uuid";
import { timelineStepsLogic } from "./mixins/required-info-timeline-steps-logic.mixin.js";
import RequiredInfoCrsStepAccountHolder from "./required-info-crs-step-account-holder.component.vue";
import RequiredInfoCrsStepTaxResidency from "./required-info-crs-step-tax-residency.component.vue";
import RequiredInfoCrsStepDeclaration from "./required-info-crs-step-declaration.component.vue";
import FormErrorAlertComponent from "Shared/Components/Vue/Alert/form-error-alert.component.vue";
import { EventBus } from "Shared/event-bus";

export const createDropDownOption = (
  disabled,
  group,
  selected,
  text,
  value
) => ({
  disabled: disabled,
  group: group,
  selected: selected,
  text: text,
  value: value,
});

export default {
  mixins: [timelineStepsLogic],
  components: {
    RequiredInfoCrsStepAccountHolder,
    RequiredInfoCrsStepTaxResidency,
    RequiredInfoCrsStepDeclaration,
    FormErrorAlertComponent,
  },
  props: {
    dictionary: Object,
  },
  data() {
    return {
      steps: 3,
      requiredInfo: null,
      boundaries: null,
      countries: null,
      errors: []
    };
  },
  created() {
    get(
      "/RequiredInfo/CRSInfoBoundaries",
      (response) => {
        this.boundaries = response.data;
        if (this.boundaries.shouldCompleteCRSInfo === false) {
          this.redirectToRefreshCRSInfoRequired();
        } else {
          get(
            "/Common/GetCountries",
            (response) => {
              this.countries = response.data;
              this.requiredInfo = this.parseBoundaries(this.boundaries);
              this.isLoading = false;
            },
            this
          );
        }
      },
      this
    );
  },
  computed: {
    USACountry() {
      if (this.countries) {
        return this.countries.find(
          (country) => country.externalIdentifier === "US"
        );
      }
    },
    knownCountries() {
      if (this.countries) {
        return this.countries.filter(
          (country) => country.externalIdentifier !== "ZZ"
        );
      }
    },
    hasHeaders() {
      return !!this.boundaries && !!this.boundaries.headers;
    },
    showSpinner() {
      return this.isLoading;
    },
  },
  methods: {
    parseBoundaries({ defaultValues, companyName }) {
      let requiredInfoData = { ...defaultValues, companyName };
      let usCitizenNationality = { countryId: this.USACountry.id };
      requiredInfoData.fiscalNationalities ??= [];

      let index = requiredInfoData.fiscalNationalities.findIndex(
        (x) => x.countryId === this.USACountry.id
      );
      if (index >= 0) {
        requiredInfoData.isUSCitizen ??= true; //if USA fiscal nationality is found and isUSCitizen is null, default to true;
        if (requiredInfoData.isUSCitizen === true) {
          usCitizenNationality = requiredInfoData.fiscalNationalities.splice(
            index,
            1
          )[0]; //split USA nationality from other fiscal nationalities because we display it separate (we join them right before submitting)
        }
      }

      requiredInfoData.fiscalNationalities.forEach((x) => (x._id = uuid())); //needed for vue's :key property in v-for loop

      if (requiredInfoData.isUSCitizen == null) //this is for making the default selection false
        requiredInfoData.isUSCitizen = false;

      return {
        ...requiredInfoData,
        usCitizenNationality,
        companyName,
        declarationAccepted: false, //isn't saved, just used in the form validation
      };
    },
    prepareRequiredInfoForSubmit(requiredInfo) {
      let data = { ...requiredInfo };

      if (data.isUSCitizen === true && data.usCitizenNationality) {
        data.fiscalNationalities = [
          data.usCitizenNationality,
          ...data.fiscalNationalities,
        ]; //add us nationality to list of fiscal nationalities
      }

      return data;
    },
    submitStep(payLoad) {
      this.requiredInfo = payLoad.data;
      if (payLoad.finished === true) {
        this.submitCRSInfo();
      }
    },
    editStep(payLoad) {
      this.currentStep = payLoad.step;
    },
    logOut() {
      window.location.replace(
        this.boundaries ? this.boundaries.logoutEndpoint : "logout"
      );
    },
    redirectToRefreshCRSInfoRequired() {
      get("/RequiredInfo/RefreshRequiredInfo",
            (response) => {
             EventBus.$emit("event-reload-layout", '/dashboard');
            },
            this
          );
    },
    submitCRSInfo() {
      const context = this;
      const data = this.prepareRequiredInfoForSubmit(this.requiredInfo);
      context.submitingData = true;
      context.errors = [];

      post(
        "/RequiredInfo/SubmitCRSInfo",
        data,
        (response) => {
          context.redirectToRefreshCRSInfoRequired();
        },
        context,
        () => {},
        (err) => {
          context.submitingData = false;
          if (err.response.status === this.$HttpStatusCodes.BAD_REQUEST) {
            //errors from the api will be merged into one string with \r\n
            context.errors = err.response.data
              .split("\r\n")
              .filter((x) => x.length > 0);
          } else {
            context.errors = [context.dictionary.common.errorUnknownException];
          }
          if (window) {
            //move to error list
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }
      );
    },
  },
};
</script>
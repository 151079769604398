<template>
    <div>
        <input type="hidden" class="form-control" :value="model.positionUsageId" />
        <input type="hidden" class="form-control" :value="model.sharePlanParticipationId" />
        <input type="hidden" class="form-control" :value="model.exitInstructionBoundaries.transferMinimum" />
        <input type="hidden" class="form-control" :value="model.exitInstructionBoundaries.transferModulo" />
        <input type="hidden" class="form-control" :value="model.available" />
    </div>
</template>

<script>
export default {
    props: {
        model: Object
    }
}
</script>
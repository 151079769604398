<template>
    <modal-basic class="leavers-prompt-modal" size="md" :title="dictionary.page.settingsModalHeaderPersonalDetails"
        :is-on="showDialog" :staticMode="true" :lazy="false" :centered="true" :close-button="true"
        @close="closePromptModal()"
        @hook:mounted="openPromptModal()">
                <div>
                    <div>
                        <div class="leavers-prompt-content">
                            <div class="row">
                                <div class="leavers-prompt-modal-alert">
                                    <alert-component variant="warning" iconClass="fa fa-lightbulb-on"> {{ dictionary.page.settingsModalAlertTextPersonalEmail }}</alert-component>
                                </div>
                                <div class="leavers-prompt-modal-content">
                                    <p class="font-weight-bold">{{ dictionary.page.settingsModalTitleDescriptionPersonalEmail }}</p>
                                    <p>{{ dictionary.page.settingsModalTextDescriptionPersonalEmail }}</p>
                                </div>
                            </div>
                            <div class="row leavers-prompt__buttons">
                                <div class="col-12 col-md-6 mt-2">
                                    <button class="btn btn-primary btn-primary-white-background w-100" type="button"
                                        @click.prevent="closePromptModal()">{{ dictionary.page.settingsModalBtnSkip  }}</button>
                                </div>
                                <div class="col-12 col-md-6 mt-2">
                                    <button class="btn btn-secondary w-100" type="button"
                                        @click="redirectToBeneficiariesAccount()"> {{ dictionary.page.settingsModalBtnAddPersonalEmail }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </modal-basic>
</template>

<script>

export default {
    props: {
        dictionary: Object,
        isModalVisible: Boolean,
        showModal: Boolean
    },
    data() {
        return {
            showDialog: false
        }
    },
    methods: {
        openPromptModal() {
            this.$nextTick(function () {
                this.showDialog = this.isModalVisible;
            });
        },
        closePromptModal() {
            if (this.showDialog) {
                this.showDialog = false;
                this.$emit("leavers-prompt-close-modal");
            }
        },
        redirectToBeneficiariesAccount() {
            this.showDialog = false;
            this.$emit("leavers-prompt-close-modal");
            this.$router.push({ path: '/beneficiaries', hash: '#account' });
        }
    }
}
</script>
<template>
    <div>
        <input-element type="password"
                       :is-mandatory="true"
                       :label="dictionary.labels.currentPassword"
                       v-model="model.currentPassword"
                       :togglePassword="true"
                       name="currentPassword"
                       :validationsList="currentPasswordValidations" />

        <div class="pr-2 pl-2 password-expired-validation-note" v-if="showPasswordValidationNote">
            <hr />
            <p class="rich-text-container" v-html="$sanitize(dictionary.labels.passwordExpiredDescription)" />
            <PasswordValidationNote :dictionary="dictionary.labels" :boundaries="boundaries" />
        </div>

        <input-element type="password"
                       :is-mandatory="true"
                       :label="dictionary.labels.newPassword"
                       v-model="model.newPassword"
                       :togglePassword="true"
                       name="newPassword"
                       :validationsList="newPasswordValidations" />

        <input-element type="password"
                       :is-mandatory="true"
                       :label="dictionary.labels.confirmPassword "
                       v-model="model.newPasswordConfirmed"
                       :togglePassword="true"
                       name="newPasswordConfirmed"
                       :validationsList="newPasswordConfirmedValidations" />

        <slot name="buttons" :back="back" :submit="submit"></slot>
    </div>
</template>

<script>
    import PasswordValidationNote from './password-validation-note.component.vue'
    import { required, sameAs, minLength } from "vuelidate/lib/validators";
    import { EventBus } from "Shared/event-bus";
    import { containsUppercase, containsLowercase, containsNumber, containsSpecial } from 'Shared/Components/Vue/Validations/custom-validator'

    export default {
        components: {
            PasswordValidationNote
        },
        props: {
            dictionary: Object,
            model: Object,
            boundaries: Object,
            showPasswordValidationNote: {
                type: Boolean,
                default: false
            }
        },
        validations() {
            return {
                model: {
                    currentPassword: {
                        required
                    },
                    newPassword: {
                        required,
                        containsUppercase: (value) => {
                            return !this.boundaries.useStrongPassword || containsUppercase(value);
                        },
                        containsLowercase: (value) => {
                            return !this.boundaries.useStrongPassword || containsLowercase(value);
                        },
                        containsNumber: (value) => {
                            return !this.boundaries.useStrongPassword || containsNumber(value);
                        },
                        containsSpecial: (value) => {
                            return !this.boundaries.useStrongPassword || containsSpecial(value);
                        },
                        minLength: minLength(this.boundaries.minimumLength),
                        notSameAsPassword: (value) => { return this.model.currentPassword != value }
                    },
                    newPasswordConfirmed: {
                        required,
                        containsUppercase: (value) => {
                            return !this.boundaries.useStrongPassword || containsUppercase(value);
                        },
                        containsLowercase: (value) => {
                            return !this.boundaries.useStrongPassword || containsLowercase(value);
                        },
                        containsNumber: (value) => {
                            return !this.boundaries.useStrongPassword || containsNumber(value);
                        },
                        containsSpecial: (value) => {
                            return !this.boundaries.useStrongPassword || containsSpecial(value);
                        },
                        minLength: minLength(this.boundaries.minimumLength),
                        sameAsPassword: sameAs('newPassword')
                    }
                }
            }
        },
        computed: {
            currentPasswordValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.currentPassword.$error,
                        type: this.$v.model.currentPassword.required,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.required,
                            {
                                "Password": this.dictionary.labels.currentPassword
                            }
                        )
                    }
                ];
            },
            newPasswordValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.required,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.required,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    },
                    {
                        id: 2,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.containsUppercase,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.capitalLetter,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    },
                    {
                        id: 3,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.containsLowercase,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.lowercaseLetter,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    },
                    {
                        id: 4,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.containsNumber,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.number,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    },
                    {
                        id: 5,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.containsSpecial,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.specialCharacter,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    },
                    {
                        id: 6,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.minLength,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.minimumLength,
                            {
                                "Password": this.dictionary.labels.newPassword,
                                "MinimumLength": this.boundaries.minimumLength
                            }
                        )
                    },
                    {
                        id: 7,
                        error: this.$v.model.newPassword.$error,
                        type: this.$v.model.newPassword.notSameAsPassword,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.notSamePassword,
                            {
                                "Password": this.dictionary.labels.newPassword
                            }
                        )
                    }
                ];
            },
            newPasswordConfirmedValidations() {
                return [
                    {
                        id: 1,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.required,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.required,
                            {
                                "Password": this.dictionary.labels.confirmPassword
                            }
                        )
                    },
                    {
                        id: 2,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.containsUppercase,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.capitalLetter,
                            {
                                "Password": this.dictionary.labels.confirmPassword
                            }
                        )
                    },
                    {
                        id: 3,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.containsLowercase,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.lowercaseLetter,
                            {
                                "Password": this.dictionary.labels.confirmPassword
                            }
                        )
                    },
                    {
                        id: 4,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.containsNumber,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.number,
                            {
                                "Password": this.dictionary.labels.confirmPassword
                            }
                        )
                    },
                    {
                        id: 5,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.containsSpecial,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.specialCharacter,
                            {
                                "Password": this.dictionary.labels.confirmPassword
                            }
                        )
                    },
                    {
                        id: 6,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.minLength,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.minimumLength,
                            {
                                "Password": this.dictionary.labels.confirmPassword,
                                "MinimumLength": this.boundaries.minimumLength
                            }
                        )
                    },
                    {
                        id: 7,
                        error: this.$v.model.newPasswordConfirmed.$error,
                        type: this.$v.model.newPasswordConfirmed.sameAsPassword,
                        message: this.$replaceStringWithPlaceholders(
                            this.dictionary.validations.samePassword,
                            {
                                "NewPassword": this.dictionary.labels.newPassword,
                                "ConfirmPassword": this.dictionary.labels.confirmPassword
                            }
                        )
                    }
                ];
            }
        },
        methods: {
            back() {
                this.$emit('back');
            },
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    return;
                }

                this.$emit('submit');
            }
        }
    }
</script>
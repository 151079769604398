var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-basic",
    {
      attrs: {
        size: "md",
        title: _vm.dictionary.employmentModalTitle,
        staticMode: true,
        lazy: false,
        centered: true,
        "close-button": true,
        isOn: _vm.showModal
      },
      on: {
        close: function($event) {
          return _vm.$emit("close-modal")
        }
      }
    },
    [
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.company,
          value: _vm.selectedLocation.company
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: { label: _vm.dictionary.hr, value: _vm.selectedLocation.hr }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.subsidiary,
          value: _vm.selectedLocation.subsidiary
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.registrationNumber,
          value: _vm.selectedLocation.registrationNumber
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.division,
          value: _vm.selectedLocation.division
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.position,
          value: _vm.selectedLocation.position
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.location,
          value: _vm.selectedLocation.location
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.startDate,
          value: _vm.selectedLocation.startDate,
          displayValue: _vm._f("toDate")(_vm.selectedLocation.startDate)
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.endDate,
          value: _vm.selectedLocation.endDate,
          displayValue: _vm._f("toDate")(_vm.selectedLocation.endDate)
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.appointment,
          value: _vm.selectedLocation.appointment
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.businessArea,
          value: _vm.selectedLocation.businessArea
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.businessUnit,
          value: _vm.selectedLocation.businessUnit
        }
      }),
      _vm._v(" "),
      _c("EmploymentHistoryDetailRow", {
        attrs: {
          label: _vm.dictionary.costCenter,
          value: _vm.selectedLocation.costCenter
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
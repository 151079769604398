var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { label: _vm.label, "label-for": "sortbyDate" } },
    [
      _c("b-select", {
        staticClass: "form-control form-control-sm",
        attrs: {
          id: "sortbyDate",
          options: _vm.options,
          "text-field": "text",
          "value-field": "value"
        },
        on: {
          change: function($event) {
            return _vm.sortByPlanDocumentDate($event)
          }
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
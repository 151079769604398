var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal-basic",
        {
          attrs: {
            centered: "",
            id: "modal-inactivity",
            title: _vm.dictionary.header,
            "is-on": _vm.isModalVisible,
            closeButton: false,
            size: "lg"
          },
          on: {
            close: function($event) {
              _vm.isModalVisible = false
            }
          }
        },
        [
          [
            _c(
              "div",
              { staticClass: "inactivity-prompt" },
              [
                _c("hr"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "rich-text-container",
                  domProps: {
                    innerHTML: _vm._s(_vm.$sanitize(_vm.dictionary.description))
                  }
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _c("h3", { staticClass: "mb-4" }, [
                    _c("i", {
                      staticClass: "far fa-clock",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" " + _vm._s(_vm.dictionary.countdownDescription))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "inactivity-prompt__counter" }, [
                    _c("span", [_vm._v(_vm._s(_vm.minutes))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.dictionary.countdownMinutes))
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.seconds))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.dictionary.countdownSeconds))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("InactivityPromptButtons", {
                  attrs: { dictionary: _vm.dictionary },
                  on: { signout: _vm.signout, continue: _vm.continueSession }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <template v-if="errors && errors.length > 0">
            <form-error-alert-component :errors="errors"></form-error-alert-component>
        </template>
        <template v-if="hasHeaders">
            <div class="mb-2 rich-text-container" v-for="(header, index) in this.boundaries.headers" :key="index" v-html="$sanitize(header)"></div>
        </template>
        <RequiredInfoLegalEntitylInformation class="ml-3"
                                             :dictionary="dictionary"
                                             :errors="errors"
                                             :boundaries="boundaries"
                                             :required-info-data="requiredInfo"
                                             @skip="$emit('skip')"
                                             @submit="$emit('submit')" />
    </div>
</template>

<script>
    import RequiredInfoLegalEntitylInformation from "./required-info-mifid-legal-entity-information.component.vue";
    import FormErrorAlertComponent from "Shared/Components/Vue/Alert/form-error-alert.component.vue";

    export default {
        components: {
            RequiredInfoLegalEntitylInformation,
            FormErrorAlertComponent
        },
        props: {
            dictionary: Object,
            boundaries: Object,
            requiredInfo: Object,
            errors: Array
        },
        computed: {
            hasHeaders() {
                return !!this.boundaries && !!this.boundaries.headers;
            }
        }
    };
</script>
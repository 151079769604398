<template>
<div>
   <v-data-table
        class="table-striped"
        :no-data-text="dictionary.table.noRecordFound"
        item-key="dividendParticipationId"
        :hide-default-header="isMobile"
        :headers="computedHeaders"
        :items="rows"
        :loading="isLoading"
        :server-items-length="totalRecords"
        :search="search"
        :items-per-page="options.itemsPerPage"
        @page-count="options.pageCount = $event;"
        :options.sync="options"
        hide-default-footer
    >       
        <template v-slot:top>
            <datatable-search :label="datatablesDictionary.searchByKeywords" v-model="search" />
        </template>

        <template v-slot:[`item.subscribeButton`]="{ item }">
            <button class="btn btn-primary" @click="subscribe(item.dividendParticipationId, item.dividendAllocationId)">{{ dictionary.subscriptionLabels.btnSubscribe }}</button>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
            {{item.startDate | toDateTime}}
        </template>

        <template v-slot:[`item.endDate`]="{ item }">
            {{item.endDate | toDateTime}}
        </template>

        <template v-slot:[`item.sharesDividendAmountPercentage`]="{ item }">
            {{formatAllocationPercentage(item.sharesDividendAmountPercentage)}}
        </template>

        <template v-slot:[`item.moneyDividendAmountPercentage`]="{ item }">
            {{formatAllocationPercentage(item.moneyDividendAmountPercentage)}}
        </template>

        <template v-if="isMobile" v-slot:[`body.prepend`]>
            <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                    v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
        </template>

        <template v-if="isMobile" v-slot:item="{ item }">
            <DividendSubscriptionDetailsMobile :dictionary="dictionary" :dividendSubscription="item" :isMobile="isMobile" @subscribe="subscribe" />
        </template>

        <template v-slot:footer>
            <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
        </template>
    
    </v-data-table>
</div>
</template>

<script>
import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
import { ScreenSizeMixin } from "Shared/Mixins/screen-size.mixin";
import DividendTableExpandedItem from "./dividend-table-expanded-item.component.vue";
import DividendDetailsMobileContent from "./dividend-details-mobile-content.component.vue";
import DividendSubscriptionDetailsMobile from "./dividend-subscription-details-mobile.component.vue";
import numeral from 'numeral';

export default {
    mixins: [vueDataTableMixin, ScreenSizeMixin],
    components: {
        DividendTableExpandedItem,
        DividendDetailsMobileContent,
        DividendSubscriptionDetailsMobile,
    },
    props: {
        dictionary: Object,
        datatablesDictionary: Object
    },
    data() {
        return {
            isTablet: false,
            isMobile: false,            
            nonCriticalHeaders: [],
            getUrl: '/Dividend/GetAllDividendSubscriptions',
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ["securityName"],
                sortDesc: [true],
                pageCount: 0,
                itemsPerPageOptions: [
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: this.datatablesDictionary.showAll, value: -1 }
                ]
            },
            headers: [
                { value: 'subscribeButton', text: '', align: 'left', sortable: false, width: '3rem' },
                { value: 'securityName', text: this.dictionary.subscriptionTable.securityName, align: 'left', sortable: true },
                { value: 'sharesDividendAmountPercentage', text: this.dictionary.subscriptionTable.shares, align: 'left', sortable: true },
                { value: 'moneyDividendAmountPercentage', text: this.dictionary.subscriptionTable.money, align: 'left', sortable: true },
                { value: 'startDate', text: this.dictionary.subscriptionTable.startDate, align: 'right', sortable: true },
                { value: 'endDate', text: this.dictionary.subscriptionTable.endDate, align: 'right', sortable: true },
            ],
            rows: [],
            totalRecords: 0
        }
    },
    computed: {
        computedHeaders() { 
            return this.headers;           
        },
        sortableColumns() {
            return this.computedHeaders.filter(x => x.sortable);
        }
    },
    methods: {
        subscribe(dividendParticipationId, dividendAllocationId) {
            this.$router.push({
                name: "dividend-subscribe-page",
                query: {
                    dividendParticipationId: dividendParticipationId,
                    dividendAllocationId: dividendAllocationId
                }
            });
        },
        formatAllocationPercentage(percentage) {
            return `${numeral(percentage).format('0.[000000000]')} %`;
        }
    }
}
</script>
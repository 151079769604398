<template>
    <div ref="accessibilityMainFocus" class="questionnaire-card-container">
        <div class="questionnaire-card-header">
            <h3 class="primary-color">{{ dictionary.labels.overviewTitle + " - " + questionnaireOverview.questionnaireName }}</h3>
            <p class="card-text">{{overviewText}}</p>
        </div>
        <div class="questionnaire-card-body">
            <div class="question-container" v-for="(currentQuestion, index) in questionnaireOverview.questions" :key="index">
                <p>
                    <span class="font-weight-bold primary-color">{{dictionary.labels.question}}: </span>
                    <span>({{index + 1 }}/{{questionnaireOverview.questions.length}})</span>
                </p>
                <div class="card-text rich-text-container" v-html="$sanitize(currentQuestion.questionDescription)"></div>
                <p>
                    <span class="font-weight-bold primary-color">{{dictionary.labels.answer}}: </span>
                    <span class="font-weight-bold">{{currentQuestion | formatAnswerType(dictionary, $QuestionType)}}</span>
                </p>
                <p v-if="invalidAnswer(currentQuestion)">
                    <template v-if="currentQuestion.questionType == $QuestionType.StandardChoices">
                        <span class="text-danger font-weight-bold">{{currentQuestion.givenAnswer | validateAnswer(currentQuestion, correctAnswerMode, canEdit, dictionary, $QuestionType)}}</span>
                    </template>
                    <template v-else-if="currentQuestion.questionType == $QuestionType.DefinedChoices">
                        <span class="text-danger font-weight-bold">{{currentQuestion.givenChoiceId | validateAnswer(currentQuestion, correctAnswerMode, canEdit, dictionary, $QuestionType)}}</span>
                    </template>
                </p>
            </div>
        </div>
        <div class="d-md-flex justify-content-md-end">
            <button class="btn primaryButton" v-on:click="handleCloseQuestionnaireOverview">
                {{dictionary.labels.btnOk}}
            </button>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "Shared/event-bus";
    import { questionnaireFilters } from "./questionnaire-filters.mixin";
    import { questionnaireMethods } from "./questionnaire-methods.mixin";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";

    export default {
        mixins: [questionnaireFilters, questionnaireMethods, accessibility],
        props: {
            dictionary: Object,
            questionnaireOverview: Object,
            questionnaireIndex: Number,
            questionnaireCount: Number,
            returnUrl: { type: String, default: '/' },
            overviewText: String,
            correctAnswerMode: Boolean,
            canEdit: Boolean,
            isModal: Boolean,
            questionnaireWithInvalidAnswer: Array
        },
        mounted() {
            this.accessibilityMainContentFocus();
        },
        methods: {
            handleCloseQuestionnaireOverview() {

                if (this.questionnaireIndex < this.questionnaireCount - 1) {
                    this.$emit('next-questionnaire')
                }
                else {
                    if (this.isModal) {
                        this.$emit('update-data')
                    } else {
                        EventBus.$emit("event-reload-layout", this.returnUrl);
                    }
                }
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portlet-layout", {
    attrs: { title: _vm.dictionary.page.subHeading },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("Reports", {
              attrs: {
                dictionary: _vm.dictionary,
                toggles: _vm.toggles,
                boundaries: _vm.boundaries
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="portfolio-container">
        <PortfolioPrintHeader :dictionary="dictionary" :skin="skin"></PortfolioPrintHeader>

        <div class="title-block title-actions">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.portfolio.title }}</h1>
            <template v-if="!isLoadingSummaryData">
                <div class="title-actions-container noprint">
                    <a id="printPage" href="javascript:void(0)" role="button" v-on:click="printPage"><span class="title-action-icon" aria-hidden="true"><i class="far fa-print" aria-hidden="true"></i></span> {{ dictionary.page.btnPrint }}</a>
                    <portfolio-overview-link v-if="canRequestPortfolioOverviewReport && !toggles.hidePortfolioCalculatorLink" :dictionary="dictionary"></portfolio-overview-link>
                </div>
            </template>
        </div>

        <div class="portlets-wrapper print-wrapper">

            <div id="portlet-1" class="portlet-flex-container">

                <component-spinner :spin="isLoadingSummaryData || isLoadingCurrenciesData"></component-spinner>

                <div v-if="!isLoadingCurrenciesData && !isLoadingSummaryData" class="clearfix portlet-portfolio-summary">
                    <portfolio-summary :toggles="toggles" :portfolio-summary="portfolioSummary" class="row m-0" />
                </div>

                <template v-if="!isLoadingCurrenciesData && !isLoadingSummaryData">
                    <div v-if="toggles.showCurrencyConversionDropdown && availableCurrencies.length > 1"
                         class="portlet-portfolio-panel portlet-currency-panel">
                        <portfolio-tooltip placement="top" icon="far fa-info-circle" :tooltip-text="dictionary.block.currencySelectBoxTooltip">
                        </portfolio-tooltip>
                        <custom-dropdown id-name="currencySelection"
                                         :items="currencies"
                                         :label="dictionary.block.currencySelectBoxLabel"
                                         :initial-item="selectedCurrency"
                                         :show-label="true"
                                         event-name="currency-changed" />
                    </div>

                    <div v-if="dictionary && portfolioPlans" class="portlet-portfolio-plan">
                        <div :key="plan.name" v-for="plan in portfolioPlans" class="row m-0">
                            <portfolio-plan :plan="plan" :dictionary="dictionary" :toggles="toggles" :currency="selectedCurrency"></portfolio-plan>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { getAll, get } from 'api';
    import { getSessionRepository } from 'Shared/factory'
    import { EventBus } from "Shared/event-bus";

    import PortfolioPlan from "./portfolio-plan.component.vue";
    import PortfolioSummary from './portfolio-summary.component.vue';
    import PortfolioTooltip from './portfolio-tooltip.component.vue';
    import PortfolioOverviewLink from './portfolio-overview-link.component.vue'
    import PortfolioPrintHeader from './portfolio-print-header.component.vue'
    const currencySelectionSessionKey = "portfolioCurrencySelection";
    const sessionRepo = getSessionRepository();

    export default {
        components: {
            PortfolioPlan,
            PortfolioTooltip,
            PortfolioSummary,
            PortfolioOverviewLink,
            PortfolioPrintHeader
        },
        props: {
            pagesDictionary: Object,
            skin: Object
        },
        data() {
            return {
                portfolioPlans: [],
                toggles: {},
                dictionary: null,
                canRequestPortfolioOverviewReport: false,
                availableCurrencies: [],
                selectedCurrency: '',
                portfolioSummary: {},
                loadingSummary: true,
                loadingDictionaryAndToggles: true,
                loadingCurrencies: true
            }
        },
        created() {

            const isoCode = sessionRepo.get(currencySelectionSessionKey);

            getAll([
                "/Portfolio/Dictionary",
                "/Portfolio/Toggles"
            ], response => {
                this.dictionary = response[0].data;
                this.toggles = response[1].data;
                this.loadingDictionaryAndToggles = false;

                this.$setPageTitle(this.dictionary.page.title);
            }, this);

            get(this.getPortfolioSummaryApiUrl(isoCode), response => {
                this.portfolioSummary = response.data;
                this.portfolioPlans = response.data.plans;
                this.loadingSummary = false;
            }, this);

            get("/Portfolio/Currencies", response => {
                this.availableCurrencies = response.data;
                this.selectedCurrency = response.data.find(c => c.defaultCurrency).externalIdentifier;
                this.loadingCurrencies = false;
            }, this);

            get('/Reports/Boundaries', response => {
                this.canRequestPortfolioOverviewReport = response.data?.canRequestPortfolioOverviewReport;
            }, this);
        },
        computed: {
            isLoadingSummaryData() {
                return this.loadingSummary || this.loadingDictionaryAndToggles;
            },
            isLoadingCurrenciesData() {
                return this.loadingCurrencies || this.loadingDictionaryAndToggles;
            },
            currencies() {
                return this.availableCurrencies
                    .map((currency) => {
                        return {
                            id: currency.externalIdentifier,
                            iso3: currency.externalIdentifier,
                            imageSrc: !!currency.imageSrc ? `/images/flags/${currency.imageSrc}` : null,
                            name: currency.externalIdentifier,
                            displayName: `${currency.symbol} ${currency.externalIdentifier}`,
                            highlight: true
                        };
                    });
            },
        },
        methods: {
            onCurrencyChange(isoCode) {
                if (isoCode) {
                    get(this.getPlansApiUrl(isoCode), (responses) => {
                        this.portfolioPlans = responses.data;
                        this.selectedCurrency = isoCode;
                    }, this); 

                    sessionRepo.set(currencySelectionSessionKey, isoCode);
                }
            },
            getPlansApiUrl: (isoCode) => `/Portfolio/Plans${isoCode ? `?isoCode=${isoCode}` : ""}`,
            getPortfolioSummaryApiUrl: (isoCode) => `/Portfolio/PortfolioSummary${isoCode ? `?isoCode=${isoCode}` : ""}`,
            printPage: () => window.print(),
        },
        watch: {
            availableCurrencies(newvalue) {
                this.selectedCurrency = sessionRepo.get(currencySelectionSessionKey);
                // If we don't have a value stored in session then set it to the default
                if (this.selectedCurrency === null) {
                    this.selectedCurrency = newvalue.filter(c => c.defaultCurrency === true)[0]?.externalIdentifier;
                }
            }
        },
        mounted() {
            EventBus.$on('currency-changed', this.onCurrencyChange);
        }
    };
</script>
<template>
  <div class="choice-details-content">
        <ChoiceDataLabelRow  :label="dictionary.table.choiceName" :value="choice.title" />  
        <ChoiceDataLabelRow  :label="dictionary.table.startDate" :value="choice.choiceStartDate" />  
        <ChoiceDataLabelRow  :label="dictionary.table.endDate" :value="choice.choiceEndDate" />  
        <ChoiceDataLabelRow  :label="dictionary.table.state" :value="choice.stateDescription" />  
        <router-link class="btn btn-primary w-100"  role="button" :to="`/choices/instruction?participationChoiceId=${choice.participationChoiceId}`" >{{actionLinkText}}</router-link>
  </div>
</template>

<script>
import ChoiceDataLabelRow from "./choice-data-label-row.component.vue";

export default {
  components: {
    ChoiceDataLabelRow,
  },
  props: {
    choice: Object,
    dictionary: Object,
    actionLinkText : String
  },
};
</script>
<template>
    <div>
        <div class="title-block">
            <h1 v-if="pagesDictionary">{{ pagesDictionary.payments.title }}</h1>
        </div>

        <div class="portlets-wrapper">
                <component-spinner :spin="isLoading"></component-spinner>
                <Payments v-if="!isLoading" :dictionary="dictionary" :toggles="toggles" :datatables-dictionary="datatablesDictionary"></Payments>
        </div>
    </div>
</template>

<script>
    import { getAll } from 'api';
    import Payments from "./payments.component.vue";

    export default {
        components: {
            Payments
        },
        props: {
            pagesDictionary: Object
        },
        data() {
            return {
                dictionary: null,
                datatablesDictionary: {},
                toggles: {},
                isLoading: true
            }
        },
        beforeCreate() {
            getAll([
                '/Payments/Dictionary',
                '/Payments/DatatablesDictionary',
                '/Payments/Toggles'
            ], responses => {
                this.dictionary = responses[0].data;
                this.datatablesDictionary = responses[1].data;
                this.toggles = responses[2].data;

                this.$setPageTitle(this.dictionary.page.title);
                this.isLoading = false
            }, this);
        }
    };
</script>

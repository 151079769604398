<template>
    <portlet-layout column-class="col-lg col-sm-6 col-12" :title="title" :hide-title="hideTitle" :hide-shadow="hideShadow">
        <template v-slot:body>
            <div class="block-portlet value-block">
              <h2 class="force-nowrap">
                  <span>
                      {{portfolioSummary.blockTitle}}
                      <i v-if="portfolioSummary.blockTooltip" :id="tooltipId" class="far fa-info-circle popover-info" aria-hidden="true"></i>
                  </span>
                  <b-popover :target="tooltipId" triggers="hover" placement="top">
                      <span>{{ portfolioSummary.blockTooltip }}</span>
                  </b-popover>
              </h2>
              <b-select v-if="portfolioSummary.walletValues.length > 1" :options="portfolioSummary.walletValues"  text-field="walletValue" value-field="currency" 
                  :id="blockId" class="value-block-select" v-model="selected" :aria-label="portfolioSummary.blockTitle">
              </b-select>
              <h3 v-else-if="portfolioSummary.walletValues.length" class="force-nowrap h4">
                  {{ portfolioSummary.walletValues[0] ? portfolioSummary.walletValues[0].walletValue : "" }}
              </h3>
            </div>
        </template>
    </portlet-layout>
</template>

<script>
import { v4 as uuid } from "uuid";
export default {
  props: {
    portfolioSummary: Object,
    title: String,
    hideTitle: {
      type: Boolean,
      default: true,
    },
    hideShadow: {
      type: Boolean,
      default: true,
    },
    },
    data() {
      return {
        selected: this.portfolioSummary.walletValues.length > 0 ? this.portfolioSummary.walletValues[0].currency : null
      }
    },
  computed: {
    blockId() {
      return `block-${uuid()}`;
    },
    tooltipId() {
      return `block-tooltip-${uuid()}`;
    }
  },
};
</script>
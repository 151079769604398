<template>
    <div>
        <div class="portlets-wrapper">
            <div class="title-block">
                <h1>{{dictionary.errorPageTitle}}</h1>
            </div>
        </div>
        <div class="portlets-wrapper">
            <div class="row m-0" id="portlet-1">
                <div class="col-12 p-0">
                    <section>
                        <div class="card card-shadow">
                            <div class="card-wrapper collapse show">
                                <div class="card-body">
                                    <div>
                                        <p class="text-center py-2">{{dictionary.errorPageTitle}}</p>
                                        <alert-component variant="error">{{ dictionary.internalServerFriendlyError }}</alert-component>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object,
        },
    }
</script>
<template>
    <div class="employment-detail-container">
        <div class="employment-detail-category">
            <div class="employment-detail-category-header">
                <i class="far fa-city"></i>
                <h4> {{ dictionary.company }}</h4>
            </div>
            <beneficiary-data-label :value="currentEmployment.company" :label="dictionary.company"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.hr" :label="dictionary.hr"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.subsidiary" :label="dictionary.subsidiary"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.registrationNumber" :label="dictionary.registrationNumber"></beneficiary-data-label>
        </div>
        <div class="employment-detail-category">
            <div class="employment-detail-category-header">
                <i class="far fa-user"></i>
                <h4> {{  dictionary.role }} </h4>
            </div>
            <beneficiary-data-label :value="currentEmployment.position" :label="dictionary.position"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.division" :label="dictionary.division"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.appointment" :label="dictionary.appointment"></beneficiary-data-label>
        </div>
        <div class="employment-detail-category">
            <div class="employment-detail-category-header">
                <i class="far fa-map-marker-alt"></i>
                <h4> {{ dictionary.location }}</h4>
            </div>
            <beneficiary-data-label :value="currentEmployment.location" :label="dictionary.location"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.startDate | toDate" :label="dictionary.startDate"></beneficiary-data-label>
            <beneficiary-data-label :value="currentEmployment.endDate | toDate" :label="dictionary.endDate"></beneficiary-data-label>
        </div>
    </div>
</template>

<script>

import BeneficiaryDataLabel from "../data-label.component.vue";

export default {
    components: {
        BeneficiaryDataLabel,
    },
    props: { 
        currentEmployment: Object,
        dictionary: Object 
    }
}
</script>

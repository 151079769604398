var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading && _vm.dictionary && _vm.viewModel
        ? _c(
            "div",
            { staticClass: "row m-0" },
            [
              !_vm.showOverview
                ? _c(
                    "conditional-portlet-layout",
                    {
                      attrs: {
                        "portlet-class": _vm.portletLayoutClasses,
                        "div-class": "col",
                        condition: !_vm.viewModel.isModal,
                        hideTitle: true,
                        "allow-collapse": false
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _vm.currentQuestionnaire
                            ? _c("QuestionnaireDetail", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  currentQuestionnaire:
                                    _vm.currentQuestionnaire,
                                  questionnaireCount: _vm.questionnaireCount,
                                  questionnaireIndex: _vm.questionnaireIndex,
                                  showCorrectAnswer: _vm.showCorrectAnswer,
                                  error: _vm.error,
                                  isLoading: _vm.isLoading,
                                  returnUrl: _vm.returnUrl,
                                  isModal: _vm.viewModel.isModal
                                },
                                on: {
                                  "submit-questionnaire":
                                    _vm.handleSubmitQuestionnaire
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loading-spinner", {
                            attrs: { active: _vm.isSubmitting }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _c(
                    "conditional-portlet-layout",
                    {
                      attrs: {
                        "portlet-class": _vm.portletLayoutClasses,
                        "div-class": "col",
                        condition: !_vm.viewModel.isModal,
                        hideTitle: true,
                        "allow-collapse": false
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _vm.questionnaireOverview
                            ? _c("QuestionnaireOverview", {
                                attrs: {
                                  dictionary: _vm.dictionary,
                                  questionnaireCount: _vm.questionnaireCount,
                                  questionnaireIndex: _vm.questionnaireIndex,
                                  questionnaireOverview:
                                    _vm.questionnaireOverview,
                                  overviewText: _vm.overviewText,
                                  returnUrl: _vm.returnUrl,
                                  isModal: _vm.viewModel.isModal,
                                  correctAnswerMode: _vm.correctAnswerMode,
                                  canEdit: _vm.currentQuestionnaire.canEdit
                                },
                                on: {
                                  "next-questionnaire": _vm.nextQuestionnaire,
                                  "update-data": function($event) {
                                    return _vm.getData(true)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("loading-spinner", {
                            attrs: { active: _vm.isSubmitting }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
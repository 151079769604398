<template>
    <div v-if="dictionary">
        <div class="documents-container">
            <div class="upload-document-block">
                <div class="document-header">
                    <h2 class="card-header-title">
                        {{ !inEdit ? dictionary.labels.documentsUpload : dictionary.labels.documentsUploaded }}
                    </h2>
                </div>

                <div id="upload-document-form" class="document-body">
                    <div v-if="requiredDocumentDetails.isPersonalIdentificationDocument">
                        <select-element :label="dictionary.labels.documentType" :options="requiredDocumentDetails.availableDocumentIdentificationTypes" :isMandatory="inEdit" :readonly="!inEdit" :readonlyLabel="dictionary.accessibility.readonlyLabel" name="documentType" v-model="documentUploadDetails.documentType"></select-element>

                        <input-element id="document-number" name="DocumentNumber" type="text" :isMandatory="inEdit" :label="dictionary.labels.documentNumber" v-model="documentUploadDetails.documentNumber" :readonly="!inEdit" :validationsList="validationLists.documentNumberValidation"></input-element>

                        <datepicker-element id="document-expiration-date" :label="dictionary.labels.documentExpiryDate" yearRange="-25:+25" :isMandatory="inEdit" v-model="documentUploadDetails.documentExpiryDate" :readonly="!inEdit" :readonlyLabel="dictionary.accessibility.readonlyLabel" :validationsList="validationLists.documentExpiryDateValidation" />

                        <datepicker-element id="document-issue-date" :label="dictionary.labels.documentIssueDate" :isMandatory="inEdit" v-model="documentUploadDetails.documentIssueDate" :readonly="!inEdit" :readonlyLabel="dictionary.accessibility.readonlyLabel" :validationsList="validationLists.documentIssueDateValidation" />

                        <select-element :label="dictionary.labels.documentCountry" :options="requiredUploadDocument.countries" name="documentCountry" v-model="documentUploadDetails.documentCountry" :isMandatory="inEdit" :readonly="!inEdit" :readonlyLabel="dictionary.accessibility.readonlyLabel"></select-element>
                    </div>

                    <div class="form-group">
                        <template v-if="inEdit">
                            <upload-element id="document-data" name="document-data" :label="dictionary.labels.filesUpload" :isMandatory="inEdit" :placeholder="dictionary.labels.chooseFile" :browseLabel="dictionary.labels.btnBrowse" extensionFilter=".png,.jpg,.jpeg,.pdf" v-model="documentUploadDetails.uploadFiles" :validationsList="validationLists.uploadFilesValidation" />
                        </template>

                        <template v-else>
                            <p class="label form-input-label input-container font-weight-bold">
                                {{dictionary.labels.filesUploaded}}
                            </p>
                            <div class="input-group mb-3">
                                <div v-if="requiredDocumentDetails.requiredDocumentParticipation.documentData.length" class="list-group">
                                    <div v-for="documentData in requiredDocumentDetails.requiredDocumentParticipation.documentData" :key="documentData.documentId">
                                        <template v-if="!toBeApproved">
                                            <a :href="$sanitize(getDocumentUrl(documentData.documentId, documentData.documentType))" class="list-group-item list-group-item-action" download>{{documentData.documentName}}</a>
                                        </template>
                                        <template v-else>
                                            {{documentData.documentName}}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <template v-if="inEdit">
                        <p>* {{dictionary.labels.mandatory}}</p>
                    </template>

                    <div class="document-button">
                        <template v-if="inEdit">
                            <button class="btn btn-secondary pull-right" @click="goBack(false)" :aria-label="dictionary.accessibility.backButton">{{dictionary.labels.btnCancel}}</button>
                            <button class="btn btn-primary pull-right" type="submit" @click="submit" :aria-label="dictionary.accessibility.submitButton">{{dictionary.labels.btnSubmit}} </button>
                        </template>
                        <template v-else>
                            <button class="btn btn-primary pull-right" @click="goBack(false)" :aria-label="dictionary.accessibility.backButton">{{dictionary.labels.btnGoBack}}</button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner :active="isLoading"></loading-spinner>
    </div>
</template>

<script>
    import { postWithCustomHeaders } from "api";
    import { documentsUploadValidation } from "./mixins/documents-upload-validation.mixin.js";
    import { EventBus } from "Shared/event-bus";

    export default {
        mixins: [documentsUploadValidation],
        data() {
            return {
                documentUploadDetails: {
                    documentNumber: null,
                    documentType: null,
                    documentIssueDate: null,
                    documentExpiryDate: null,
                    documentCountry: null,
                    uploadFiles: null
                },
                isLoading: false
            }
        },
        props: {
            dictionary: Object,
            requiredUploadDocument: Object,
            inEdit: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            requiredDocumentDetails() {
                return this.requiredUploadDocument.requiredDocumentDetails;
            },
            toBeApproved() {
                return this.requiredDocumentDetails.requiredDocumentParticipation.approvalState.value == "rdasToBeApproved";
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                EventBus.$emit("v-touch");

                if (this.$v.$invalid) {
                    return;
                }

                this.isLoading = true;
                postWithCustomHeaders("/RequiredDocuments/SubmitRequiredDocument",
                    this.buildFormData(),
                    () => {
                        this.goBack(true);
                    },
                    this,
                    () => {
                        this.isLoading = false;
                    },
                    () => { },
                    { 'Content-Type': 'multipart/form-data' });
            },
            goToRequiredDocuments() {
                this.$router.push({
                    name: "required-documents"
                });
            },
            goBack(isFileSubmitted) {
                const route = this.$route;
                if (route.query.returnUrl) {

                    let returnPath = route.query.returnUrl;
                    returnPath += route.hash ? route.hash : '';
                    this.$router.push({ path: returnPath });
                }
                else {
                    if (isFileSubmitted) {
                        this.goToRequiredDocuments();
                    } else {
                        this.$router.go(-1)
                    }
                }
            },
            getDocumentUrl(documentId, documentType) {
                return `/Document/GetDocument?documentId=${documentId}&documentType=${documentType}`;
            },
            buildFormData() {
                const formData = new FormData();

                formData.append("RequiredDocumentId", this.requiredUploadDocument.requiredDocumentDetails.requiredDocumentId);
                formData.append("DocumentType", this.documentUploadDetails.documentType);
                formData.append("DocumentNumber", this.documentUploadDetails.documentNumber);
                formData.append("DocumentExpiryDate", this.documentUploadDetails.documentExpiryDate);
                formData.append("DocumentIssueDate", this.documentUploadDetails.documentIssueDate);
                formData.append("DocumentCountry", this.documentUploadDetails.documentCountry);

                Array.from(this.documentUploadDetails.uploadFiles).forEach(x => formData.append("UploadFiles", x, x.name));

                return formData;
            }
        },
        created() {
            this.documentUploadDetails.documentNumber =
                this.requiredDocumentDetails.requiredDocumentParticipation.documentNumber;

            this.documentUploadDetails.documentType =
                this.requiredDocumentDetails.requiredDocumentParticipation.documentIdentificationType == null
                    ? this.requiredDocumentDetails.availableDocumentIdentificationTypes[0].value
                    : this.requiredDocumentDetails.requiredDocumentParticipation.documentIdentificationType.value;

            this.documentUploadDetails.documentIssueDate =
                this.requiredDocumentDetails.requiredDocumentParticipation.documentIssueDate;

            this.documentUploadDetails.documentExpiryDate =
                this.requiredDocumentDetails.requiredDocumentParticipation.documentExpiryDate;

            this.documentUploadDetails.documentCountry =
                this.requiredDocumentDetails.requiredDocumentParticipation.documentCountry == null
                    ? this.requiredUploadDocument.countries[0].value
                    : this.requiredDocumentDetails.requiredDocumentParticipation.documentCountry;
        }
    };
</script>
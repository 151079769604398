var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredInfoModel
    ? _c("required-info-step", {
        attrs: {
          id: "accountholder-container",
          currentStep: _vm.currentStep,
          step: _vm.step,
          heading: _vm.dictionary.labels.step1,
          description: _vm.dictionary.labels.step1Description,
          dictionary: _vm.dictionary
        },
        on: {
          "edit-step": function(val) {
            return _vm.$emit("edit-step", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "previous",
              fn: function() {
                return [
                  _c(
                    "dl",
                    { staticClass: "details-list accountholder-details" },
                    [
                      _c(
                        "div",
                        { staticClass: "details-list-left" },
                        [
                          _vm.hasCompany
                            ? _c("DataLabelRow", {
                                attrs: {
                                  value: _vm.requiredInfoModel.companyName,
                                  label: _vm.dictionary.labels.company
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.firstNames,
                              label: _vm.dictionary.labels.firstName
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.lastName,
                              label: _vm.dictionary.labels.lastName
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.address,
                              label: _vm.dictionary.labels.address
                            }
                          }),
                          _vm._v(" "),
                          _vm.requiredInfoModel.complementaryAddress &&
                          _vm.requiredInfoModel.complementaryAddress.length > 0
                            ? _c("DataLabelRow", {
                                attrs: {
                                  value:
                                    _vm.requiredInfoModel.complementaryAddress,
                                  label: ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.postalCode,
                              label: _vm.dictionary.labels.postalCode
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.requiredInfoModel.city,
                              label: _vm.dictionary.labels.city
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.getCountryName(
                                _vm.requiredInfoModel.countryId
                              ),
                              label: _vm.dictionary.labels.countryOfResidence
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm._f("toDate")(
                                _vm.requiredInfoModel.dateOfBirth
                              ),
                              label: _vm.dictionary.labels.dob
                            }
                          }),
                          _vm._v(" "),
                          _c("DataLabelRow", {
                            attrs: {
                              value: _vm.getCountryName(
                                _vm.requiredInfoModel.countryOfBirthId
                              ),
                              label: _vm.dictionary.labels.birthCountry
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "current",
              fn: function() {
                return [
                  _vm.hasCompany
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("input-element", {
                              attrs: {
                                name: "companyName",
                                label: _vm.dictionary.labels.company,
                                readonly: true
                              },
                              model: {
                                value: _vm.requiredInfoModel.companyName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.requiredInfoModel,
                                    "companyName",
                                    $$v
                                  )
                                },
                                expression: "requiredInfoModel.companyName"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "firstName",
                            label: _vm.dictionary.labels.firstName,
                            validationsList:
                              _vm.accountholderValidations.firstNames,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.firstNames,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "firstNames", $$v)
                            },
                            expression: "requiredInfoModel.firstNames"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "lastName",
                            label: _vm.dictionary.labels.lastName,
                            validationsList:
                              _vm.accountholderValidations.lastName,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.lastName,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "lastName", $$v)
                            },
                            expression: "requiredInfoModel.lastName"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "address",
                            label: _vm.dictionary.labels.address,
                            validationsList:
                              _vm.accountholderValidations.address,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.address,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "address", $$v)
                            },
                            expression: "requiredInfoModel.address"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "complementaryAddress",
                            label: _vm.dictionary.labels.complementaryAddress
                          },
                          model: {
                            value: _vm.requiredInfoModel.complementaryAddress,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "complementaryAddress",
                                $$v
                              )
                            },
                            expression: "requiredInfoModel.complementaryAddress"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "postalCode",
                            label: _vm.dictionary.labels.postalCode,
                            validationsList:
                              _vm.accountholderValidations.postalCode,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.postalCode,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "postalCode", $$v)
                            },
                            expression: "requiredInfoModel.postalCode"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            name: "city",
                            label: _vm.dictionary.labels.city,
                            validationsList: _vm.accountholderValidations.city,
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.city,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "city", $$v)
                            },
                            expression: "requiredInfoModel.city"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.labels.countryOfResidence,
                            validationsList:
                              _vm.accountholderValidations.countryId,
                            options: _vm.listCountries,
                            name: "countryId",
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.countryId,
                            callback: function($$v) {
                              _vm.$set(_vm.requiredInfoModel, "countryId", $$v)
                            },
                            expression: "requiredInfoModel.countryId"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("datepicker-element", {
                          attrs: {
                            name: "dateOfBirth",
                            id: "dateOfBirth",
                            label: _vm.dictionary.labels.dob,
                            validationsList:
                              _vm.accountholderValidations.dateOfBirth,
                            "is-mandatory": true,
                            yearRange: "-150:+0"
                          },
                          model: {
                            value: _vm.requiredInfoModel.dateOfBirth,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "dateOfBirth",
                                $$v
                              )
                            },
                            expression: "requiredInfoModel.dateOfBirth"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("select-element", {
                          attrs: {
                            label: _vm.dictionary.labels.birthCountry,
                            validationsList:
                              _vm.accountholderValidations.countryOfBirthId,
                            options: _vm.listCountries,
                            name: "countryOfBirthId",
                            "is-mandatory": true
                          },
                          model: {
                            value: _vm.requiredInfoModel.countryOfBirthId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoModel,
                                "countryOfBirthId",
                                $$v
                              )
                            },
                            expression: "requiredInfoModel.countryOfBirthId"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-md-flex justify-content-md-between button-container"
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn primaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.nextStepAccessibilityLabel(
                                _vm.step,
                                _vm.steps
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.nextStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.dictionary.buttons.next) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          4229683747
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <h1 class="sr-only welcome-text">{{dictionary.recovery.forgotPasswordTitle}}</h1>
        <p class="text-center py-2 welcome-text" aria-hidden="true">{{dictionary.recovery.forgotPasswordTitle}}</p>
        <hr>
        <div class="text-left py-2 rich-text-container" v-html="$sanitize(dictionary.recovery.passwordRecoverySuccessText)"></div>
        <button type="button" @click="back" class="btn float-right btn-primary mt-5 secondary-background-color">
            {{commonDictionary.btnGoBack}}
        </button>
    </div>
</template>

<script>
export default {
    props:{
        dictionary: Object,
        commonDictionary: Object
    },
    methods: {
         back() {
                this.$router.push({ path: '/login' });
        }
    }
}
</script>
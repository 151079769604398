var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("disclaimers", {
        attrs: { disclaimers: _vm.confimationModel.disclaimers }
      }),
      _vm._v(" "),
      _c(
        "SubscriptionConfirmationButtons",
        _vm._g(
          {
            attrs: {
              dictionary: _vm.dictionary,
              disclaimers: _vm.confimationModel.disclaimers,
              presentationMode:
                _vm.confimationModel.participationSubscription.presentationMode
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
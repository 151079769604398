<template>
    <div>
        <v-data-table class="table-striped"
                      :no-data-text="dictionary.rows.transactionsTblRecordNotFoundTransactions"
                      :hide-default-header="isMobile || isTablet"
                      :headers="computedHeaders"
                      :items="rows"
                      item-key="reference"
                      :loading="isLoading"
                      :server-items-length="totalRecords"
                      :search="search"
                      :items-per-page="options.itemsPerPage"
                      @page-count="options.pageCount = $event;"
                      :options.sync="options"
                      hide-default-footer
                      >

            <!-- Search input at top -->
            <template v-slot:top>
                <datatable-search-header class="mb-3" :label="datatablesDictionary.searchByKeywords" :description="dictionary.page.transactionsWidgetText" v-model="search" />
                <slot name="header" />
                <TransactionOverviewHeader :dictionary="dictionary" :transaction="transaction" :isMobile="isMobile"/>
            </template>
            <!-- Datatable template for mobile view before data rows --->
            <template v-if="isMobile || isTablet" v-slot:[`body.prepend`]>
                <datatable-mobile-header @onSortChange="onSortChange()" :options="options" :sortableColumns="sortableColumns"
                                         v-model="sortBy" :dictionary="datatablesDictionary"></datatable-mobile-header>
            </template>
            <!-- Datatable template for mobile view of data rows --->
            <template v-if="isMobile || isTablet" v-slot:item="{ item }">
                <TransactionDetailsMobileContent :toggles="toggles" :dictionary="dictionary" :item="item" />
            </template>

            <template v-slot:footer>
                <datatable-pagination :datatablesDictionary="datatablesDictionary" v-model="options" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { vueDataTableMixin } from 'Shared/Mixins/vue-data-table.mixin';
    import { post } from 'api';
    import TransactionDetailsMobileContent from './transaction-details-mobile-content.component.vue';
    import TransactionOverviewHeader from './transactions-overview-header.component.vue';

    export default {
        mixins: [vueDataTableMixin],
        components: {
            TransactionDetailsMobileContent,
            TransactionOverviewHeader
        },
        props: {
            dictionary: Object,
            datatablesDictionary: Object,
            transactionsOptions: Object,
            toggles: Object
        },
        data() {
            return {
                transaction: {},
                isTablet: false,
                isMobile: false,
                nonCriticalHeaders: [],
                postUrl: '/Transactions/Data',
                requestBody: this.transactionsOptions,
                requestType: "post",
                options: {
                    itemsPerPage: 10,
                    page: 1,
                    sortBy: ["executionDate"],
                    sortDesc: [true],
                    pageCount: 0,
                    itemsPerPageOptions: [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: this.datatablesDictionary.showAll, value: -1 }
                    ]
                },
                headers: [
                    { value: 'executionDate', text: this.dictionary.columns.transactionsTblColExecutionDate, align: 'left', sortable: true, visible: true },
                    { value: 'transactionType', text: this.dictionary.columns.transactionsTblColType, align: 'left', sortable: true, visible: true },
                    { value: 'amountFormatted', text: this.dictionary.columns.transactionsTblColAmount, align: 'left', sortable: true, visible: true },
                    { value: 'reference', text: this.dictionary.columns.transactionsTblColReference, align: 'left', sortable: false, visible: true },
                    { value: 'origin', text: this.dictionary.columns.transactionsTblColOrigin, align: 'left', sortable: true, visible: !this.toggles.hideTransactionOriginColumn },
                    { value: 'comment', text: this.dictionary.columns.transactionsTblColComment, align: 'left', sortable: false, visible: true }
                ],
                rows: [],
                totalRecords: 0
            }
        },
        computed: {
            computedHeaders() {
                return this.headers.filter(h => h.visible);
            },
            sortableColumns() {
                return this.headers.filter(x => x.sortable);
            },
            getAllPositionsText() {
                if (this.transactionsOptions && this.transactionsOptions.positions) {
                    const allPositions = this.transactionsOptions.positions.filter(t => t.positionName === this.dictionary.reports.transactionsAllPositions);
                    if (allPositions.length > 0) {
                        return allPositions[0].positionName;
                    }
                    return "-";
                }
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.isTablet = window.innerWidth <= this.$windowSizes.TABLET
                    && window.innerWidth > this.$windowSizes.MOBILE;
                this.isMobile = window.innerWidth <= this.$windowSizes.MOBILE;
            },
            prepareForAllPositions() {
                this.rows = [];
                this.totalRecords = 0;
                this.transaction = {};
                this.transaction.positionName = this.getAllPositionsText;
                this.transaction.startPosition = "-";
                this.transaction.endPosition = "-";
            },
            updateTable() {
                this.loadItems();
            },
            post(postUrl, sortBy, sortDirection, search, page, itemsPerPage) {
                if (this.requestBody.positionUsageId) {
                    post(`${postUrl}` +
                        `?sortBy=${sortBy ?? ""}` +
                        `&sortDirection=${sortDirection}` +
                        `&searchValue=${search}` +
                        `&page=${page}` +
                        `&perPage=${itemsPerPage}`, this.requestBody,
                        (response) => {
                            this.transaction = response.data;
                            this.rows = this.transaction.vueTableServerResponse.rows;
                            this.totalRecords = this.transaction.vueTableServerResponse.totalRecords;
                        }, this, () => {
                            this.isLoading = false;
                        },
                        (err) => {
                            this.isLoading = false;
                            this.error = err.response;
                            this.transaction = {};
                            this.rows = [];
                            this.totalRecords = 0;
                        });
                }
                this.transaction.positionName = this.getAllPositionsText;
            }
        }
    }
</script>
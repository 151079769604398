var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-info" }, [
    _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
      _c("p", { staticClass: "subscription-info-title" }, [
        _vm._v(_vm._s(_vm.dictionary.table.subscriptionOpenTitle))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-sm-right" }, [
        _vm._v(
          _vm._s(
            _vm._f("toDateTime")(
              _vm.participationSubscription.subscriptionPeriodStartDate
            )
          )
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
      _c("p", { staticClass: "subscription-info-title" }, [
        _vm._v(_vm._s(_vm.dictionary.table.subscriptionClosedTitle))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-sm-right" }, [
        _vm._v(
          _vm._s(
            _vm._f("toDateTime")(
              _vm.participationSubscription.subscriptionPeriodEndDate
            )
          )
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
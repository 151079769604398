var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredInfoModel
    ? _c("required-info-step", {
        attrs: {
          id: "tax-residency-container",
          currentStep: _vm.currentStep,
          step: _vm.step,
          heading: _vm.dictionary.labels.step3,
          dictionary: _vm.dictionary
        },
        on: {
          "edit-step": function(val) {
            return _vm.$emit("edit-step", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "current",
              fn: function() {
                return [
                  _vm.hasEmptyTins
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "alert-component",
                              {
                                attrs: {
                                  variant: "warning",
                                  iconClass: "fa-info-circle"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.dictionary.validation.missingTIN)
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row declaration-text-row mb-2" }, [
                    _c("div", {
                      staticClass: "col-12 rich-text-container",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(_vm.dictionary.labels.declarationText)
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.declarationError
                          ? _c(
                              "alert-component",
                              { attrs: { variant: "error" } },
                              [_vm._v(_vm._s(_vm.declarationError.message))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "input-group mb-2" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "mb-0 form-check-inline form-input-label"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.requiredInfoData
                                          .declarationAccepted,
                                      expression:
                                        "requiredInfoData.declarationAccepted"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.requiredInfoData.declarationAccepted
                                    )
                                      ? _vm._i(
                                          _vm.requiredInfoData
                                            .declarationAccepted,
                                          null
                                        ) > -1
                                      : _vm.requiredInfoData.declarationAccepted
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.requiredInfoData
                                            .declarationAccepted,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.requiredInfoData,
                                              "declarationAccepted",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.requiredInfoData,
                                              "declarationAccepted",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.requiredInfoData,
                                          "declarationAccepted",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.labels.declarationCheckbox
                                    )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-md-flex justify-content-md-between button-container"
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn secondaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.previousStepAccessibilityLabel(
                                _vm.step
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.previousStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dictionary.buttons.previous) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn primaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.nextStepAccessibilityLabel(
                                _vm.step,
                                _vm.steps
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.nextStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dictionary.buttons.submit) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1437705756
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <tr>
        <td class="dtr-control p-2">
            <div class="dtr-control-row">
                <div class="d-flex mr-3">
                    <div class="align-self-center">
                        <div class="document-icon-container">
                            <i class="far fa-file fa-2x" aria-hidden="true"></i>
                        </div>
                    </div>   
                    <div class="document-details-container">                          
                        <p><strong>{{item.documentName}}</strong></p>
                        <p>{{item.documentDescription}}</p>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                  <a class="btn btn-primary" @click="redirect(item)" role="button">{{getLinkText(item)}}</a>                
                </div>
            </div>            
        </td>        
    </tr>    
</template>

<script>
export default {
  props: {
    dictionary: Object,
    item: Object,
  },
  methods: {
    getLinkText(row) {
      if (row.redirectUrl) {
        return this.dictionary.labels.actionLinkView;
      } else if (row.documentType) {
        return this.dictionary.labels.linkDownload;
      } else {
        return this.dictionary.labels.actionLinkView;
      }
    },
    redirect(row) {
      if (row.redirectUrl) {
        return window.location.href = row.redirectUrl;
      } else if (row.documentType) {
        return window.location.href = `Document/GetDocument?documentId=${row.documentId}&documentType=${row.documentType}`;
      } else {
        this.$router.push({ path: `requiredDocuments/details?documentId=${row.documentId}&documentParticipationId=${row.documentParticipationId}&returnUrl=/Document#personal` });
      }
    },
  },
};
</script>
<template>
    <div v-if="derivativeSecurity" :id="spanId" class="text-right force-nowrap">
        <span>{{ lastDerivativeSecurityPrice }} </span>
        <i v-if="!hideSecurityPriceInformationTooltip" class="far fa-info-circle popover-info" aria-hidden="true"></i>
        <div>
            <b-popover :target="spanId" triggers="hover" placement="right" v-if="!hideSecurityPriceInformationTooltip">
                {{ tooltipSecurityPrice }}
            </b-popover>
        </div>    
    </div>   
</template>

<script>

import { v4 as uuid } from "uuid";
    
export default {
  props: {
    derivativeSecurity: Object,
    dictionary: Object,
    hideSecurityPriceInformationTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spanId() {
      return `portfolio-last-derivative-security-price-popover-target_${uuid()}`;
    },
    lastDerivativeSecurityPrice() {
        return this.derivativeSecurity != null ? this.derivativeSecurity.lastSecurityPriceFormatted : ''
    },
    tooltipSecurityPrice() {        
        const securityPriceDate = this.derivativeSecurity != null ? this.$options.filters.toDateTime(this.derivativeSecurity.lastSecurityPriceDate).replace('00:00:00', '') : ''
        return `${this.dictionary.block.tooltipSharePrice} ${securityPriceDate}`;
    },
  },
};
</script>
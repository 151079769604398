var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-container tab-row" },
    [
      _c("div", { staticClass: "title-block" }, [
        _vm.pagesDictionary
          ? _c("h1", [_vm._v(_vm._s(_vm.pagesDictionary.beneficiary.title))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("component-spinner", { attrs: { spin: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "tab-bar", attrs: { id: "beneficiary-vm" } },
            [
              _c(
                "ul",
                { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
                [
                  _vm.mayViewAccountData
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                id: "account-tab",
                                "data-hash": "#account",
                                role: "tab",
                                "data-toggle": "tab"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setCurrentTab($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.accountDictionary.tabs.account))]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewFinancialData
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                id: "financial-tab",
                                "data-hash": "#financial",
                                role: "tab",
                                "data-toggle": "tab"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setCurrentTab($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.accountDictionary.tabs.financial)
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewEvaluationData
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                id: "evaluation-tab",
                                "data-hash": "#evaluation",
                                role: "tab",
                                "data-toggle": "tab"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setCurrentTab($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.accountDictionary.tabs.evaluation)
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewCompanyData
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                id: "company-tab",
                                "data-hash": "#company",
                                role: "tab",
                                "data-toggle": "tab"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setCurrentTab($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.accountDictionary.tabs.company))]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content card-shadow",
                  attrs: { id: "beneficiary-data" }
                },
                [
                  _vm.mayViewAccountData
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: { role: "tabpanel", id: "account" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "portlets-wrapper bg-white" },
                            [
                              _c("AccountDetails", {
                                attrs: {
                                  "account-dictionary": _vm.accountDictionary,
                                  "common-dictionary": _vm.commonDictionary,
                                  "account-data-model": _vm.accountDataModel,
                                  "address-data-model": _vm.addressDataModel,
                                  "is-account-edited": _vm.isAccountEdited,
                                  "account-data-edit-model":
                                    _vm.accountDataEditModel,
                                  "is-address-edited": _vm.isAddressEdited,
                                  "address-data-edit-model":
                                    _vm.addressDataEditModel,
                                  toggles: _vm.beneficiaryToggles,
                                  "loading-account-data":
                                    _vm.loadingAccountData,
                                  "beneficiary-boundaries-data-model":
                                    _vm.beneficiaryBoundariesDataModel,
                                  "mfa-boundaries-data-model":
                                    _vm.mfaBoundariesDataModel
                                },
                                on: {
                                  "account-edit-form-cancelled":
                                    _vm.accountEditFormCancelled,
                                  "address-edit-form-cancelled":
                                    _vm.addressEditFormCancelled,
                                  "edit-account": _vm.editAccount,
                                  "edit-address": _vm.editAddress,
                                  "reload-page": _vm.reloadPage,
                                  "edit-mfa": _vm.editMfa
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewFinancialData
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: { role: "tabpanel", id: "financial" }
                        },
                        [
                          _c("FinancialDetails", {
                            attrs: {
                              "financial-dictionary": _vm.financialDictionary,
                              "financial-toggles": _vm.beneficiaryToggles,
                              "money-account-data": _vm.moneyAccountsDataModel,
                              "security-account-data":
                                _vm.securityAccountDataModel,
                              "beneficiary-boundaries-data":
                                _vm.beneficiaryBoundariesDataModel,
                              "loading-dictionaries-and-toggles":
                                _vm.loadingDictionariesAndToggles,
                              "datatables-dictionary": _vm.datatablesDictionary
                            },
                            on: {
                              "reload-bank-account-data":
                                _vm.reloadBankAccountData
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewEvaluationData
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: { role: "tabpanel", id: "evaluation" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "portlets-wrapper bg-white" },
                            [
                              _c("EvaluationDetails", {
                                attrs: {
                                  "financial-dictionary":
                                    _vm.financialDictionary,
                                  "account-dictionary": _vm.accountDictionary,
                                  "datatables-dictionary":
                                    _vm.datatablesDictionary,
                                  "beneficiary-toggles": _vm.beneficiaryToggles
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mayViewCompanyData
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: { role: "tabpanel", id: "company" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "portlets-wrapper bg-white" },
                            [
                              _c("CompanyDetails", {
                                attrs: {
                                  "account-dictionary": _vm.accountDictionary,
                                  "employment-history": _vm.employmentHistory,
                                  "current-employment":
                                    _vm.accountDataModel.currentEmployment
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
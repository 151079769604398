var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("loading-spinner", { attrs: { active: _vm.isLoading } })
    : _c(
        "LoginTemplate",
        [
          _c("template", { slot: "title" }, [
            _c("div", [
              _c("h1", { staticClass: "sr-only welcome-text" }, [
                _vm._v(_vm._s(_vm.header))
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-center py-2 welcome-text",
                  attrs: { "aria-hidden": "true" }
                },
                [_vm._v(_vm._s(_vm.header))]
              ),
              _vm._v(" "),
              _c("hr")
            ])
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "body" },
            [
              !_vm.mfaRequired
                ? _c("LoginForm", {
                    attrs: { dictionary: _vm.dictionary, toggles: _vm.toggles },
                    on: { "login-success": _vm.onLoginSuccess }
                  })
                : _c("LoginMfa", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      isVisible: _vm.mfaRequired
                    },
                    on: {
                      "mfa-success": _vm.redirect,
                      "in-loading": _vm.inLoading
                    }
                  })
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dictionary && _vm.canSkipRequiredDocuments
        ? _c(
            "div",
            [
              _c("div", {
                staticClass:
                  "required-documents-page-description rich-text-container",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.dictionary.labels.requiredDocumentsPageDescription
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("RequiredDocumentsTable", {
                attrs: {
                  dictionary: _vm.dictionary,
                  datatablesDictionary: _vm.dictionary.dataTables,
                  getUrl: "/RequiredDocuments/IncompleteRequiredDocuments"
                },
                on: { action: _vm.upload }
              }),
              _vm._v(" "),
              _vm.canSkipRequiredDocuments.canSkip
                ? _c("div", { staticClass: "float-right" }, [
                    _c("div", { staticClass: "dt-buttons btn-group pt-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn primaryButton canSkipButton",
                          on: { click: _vm.skip }
                        },
                        [_vm._v(_vm._s(_vm.dictionary.labels.btnSkip))]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("component-spinner", {
        attrs: { spin: _vm.isLoading || _vm.canSkipIsLoading }
      }),
      _vm._v(" "),
      _c("loading-spinner", { attrs: { active: _vm.isSkipping } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredInfoModel
    ? _c("required-info-step", {
        attrs: {
          id: "accountholder-container",
          currentStep: _vm.currentStep,
          step: _vm.step,
          steps: _vm.steps,
          summaryType: _vm.summaryType,
          heading: _vm.dictionary.labels.step3,
          description: _vm.dictionary.labels.step3Description,
          dictionary: _vm.dictionary
        },
        on: {
          "edit-step": function(val) {
            return _vm.$emit("edit-step", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "previous",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("RequiredInfoDataLabelRow", {
                        staticClass: "col-md-6",
                        attrs: {
                          value: _vm.requiredInfoModel.fiscalNumber,
                          label: _vm.dictionary.labels.ssnFiscalNumber,
                          isMandatory: true
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "current",
              fn: function() {
                return [
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("input-element", {
                          attrs: {
                            type: "text",
                            name: "fiscalNumber",
                            isMandatory: true,
                            readonly: !_vm.boundaries.mayEditFiscalNumber,
                            label: _vm.dictionary.labels.ssnFiscalNumber,
                            validationsList: _vm.fiscalValidations.fiscalNumber
                          },
                          model: {
                            value: _vm.requiredInfoData.fiscalNumber,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.requiredInfoData,
                                "fiscalNumber",
                                $$v
                              )
                            },
                            expression: "requiredInfoData.fiscalNumber"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-md-flex justify-content-md-between button-container"
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn secondaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.previousStepAccessibilityLabel(
                                _vm.step
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.previousStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.dictionary.buttons.previous) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn primaryButton",
                            attrs: {
                              type: "button",
                              "aria-describedby": _vm.nextStepAccessibilityLabel(
                                _vm.step,
                                _vm.steps
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.nextStep()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.dictionary.buttons.next) +
                                "\n                "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          3884958710
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    { attrs: { accordion: "" } },
    [
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", [
            _c("h3", { staticClass: "h4" }, [
              _vm._v(_vm._s(_vm.item.positionName))
            ])
          ]),
          _vm._v(" "),
          _c("v-expansion-panel-content", [
            _c(
              "div",
              [
                _c("TransferSettingsDetailContentRow", {
                  attrs: {
                    label: _vm.dictionary.positionName,
                    value: _vm.item.positionName
                  }
                }),
                _vm._v(" "),
                _c("TransferSettingsDetailContentRow", {
                  attrs: {
                    label: _vm.dictionary.transactionType,
                    value: _vm.item.transactionType.description
                  }
                }),
                _vm._v(" "),
                _c("TransferSettingsDetailContentRow", {
                  attrs: {
                    label: _vm.dictionary.planProcess,
                    value: _vm.item.planProcess.description
                  }
                }),
                _vm._v(" "),
                _c(
                  "TransferSettingsDetailContentActionRow",
                  _vm._g(
                    {
                      attrs: {
                        label: _vm.dictionary.automaticPaymentSettings,
                        item: _vm.item
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _vm._v(" "),
                _c("div", { staticClass: "transfer-settings-row-flex" }, [
                  _c("p", [_vm._v("* ")]),
                  _c("span", {
                    staticClass: "rich-text-container",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.dictionary.automaticPaymentSettingsPopover
                        )
                      )
                    }
                  })
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
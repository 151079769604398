var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mfa-buttons button-container" }, [
    _c("div", { staticClass: "mfa-buttons__cancel-button" }, [
      _c(
        "button",
        {
          class: [
            { hasOnlyCancelButton: _vm.hasOnlyCancelButton },
            "btn",
            "secondaryButton"
          ],
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("cancel")
            }
          }
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.dictionary.buttonMfaCancel) +
              "\n        "
          )
        ]
      )
    ]),
    _vm._v(" "),
    !_vm.hasContinueButton && !_vm.hasOnlyCancelButton
      ? _c("div", { staticClass: "mfa-buttons__submit-button" }, [
          _c(
            "button",
            {
              class: [
                { isDisabled: _vm.disableSubmit },
                "btn",
                "primaryButton"
              ],
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.dictionary.buttonMfaConfirm) +
                  "\n        "
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasContinueButton && !_vm.hasOnlyCancelButton
      ? _c("div", { staticClass: "mfa-buttons__submit-button" }, [
          _c(
            "button",
            {
              class: ["btn", "primaryButton"],
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("continueMfa")
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.dictionary.mfaBtnMFAPromptContinue) +
                  "\n        "
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "order-form-div" } }, [
    _vm.model
      ? _c(
          "form",
          {
            attrs: { id: "buy-instruct-form" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit($event)
              }
            }
          },
          [
            _vm.error
              ? _c("alert-component", { attrs: { variant: "error" } }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("order-types-link", {
              attrs: {
                type: _vm.$OrderTypes.Buy,
                dictionary: _vm.dictionary.orderTypes,
                positionViewModel: _vm.buyPositionModel,
                title: _vm.dictionary.form.orderType,
                name: "orderType"
              }
            }),
            _vm._v(" "),
            !_vm.model.moduleBounderies.length > 1
              ? _c("select-element", {
                  attrs: {
                    label: _vm.dictionary.form.moduleName,
                    options: _vm.moduleBounderies,
                    name: "moduleBounderies"
                  },
                  model: {
                    value: _vm.model.moduleId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "moduleId", $$v)
                    },
                    expression: "model.moduleId"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("select-element", {
              attrs: {
                label: _vm.dictionary.form.scenarioName,
                options: _vm.scenarios,
                name: "buyScenario"
              },
              model: {
                value: _vm.model.buyScenarioId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "buyScenarioId", $$v)
                },
                expression: "model.buyScenarioId"
              }
            }),
            _vm._v(" "),
            _c("select-element", {
              attrs: {
                label: _vm.dictionary.form.executionMoment,
                options: _vm.executionMoments,
                validations: _vm.$v.model.executionMoment,
                name: "executionMoments"
              },
              model: {
                value: _vm.model.executionMoment,
                callback: function($$v) {
                  _vm.$set(_vm.model, "executionMoment", $$v)
                },
                expression: "model.executionMoment"
              }
            }),
            _vm._v(" "),
            _c("BuyOrderQuantity", {
              attrs: {
                dictionary: _vm.dictionary,
                model: _vm.model,
                moduleBounderie: _vm.moduleBounderie,
                validations: _vm.$v.model
              },
              on: {
                amountOrdered: function($event) {
                  _vm.model.amountOrdered = $event
                },
                nrOrdered: function($event) {
                  _vm.model.nrOrdered = $event
                }
              }
            }),
            _vm._v(" "),
            !_vm.hideLimitPrice
              ? _c("input-element", {
                  attrs: {
                    type: "text",
                    label: _vm.dictionary.form.limitPrice,
                    validationsList: _vm.limitPriceValidations,
                    name: "limitPrice"
                  },
                  model: {
                    value: _vm.model.limitPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "limitPrice", $$v)
                    },
                    expression: "model.limitPrice"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.hideBuyDate
              ? _c("datepicker-element", {
                  attrs: {
                    id: "buy-order-buy-date",
                    label: _vm.dictionary.form.buyDate
                  },
                  model: {
                    value: _vm.model.buyDate,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "buyDate", $$v)
                    },
                    expression: "model.buyDate"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex-container-button-right mt-4" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v(_vm._s(_vm.dictionary.form.placeOrderButton))]
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
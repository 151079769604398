<template>
    <portlet-layout v-if="accountDictionary && showMfaPortlet" :title="accountDictionary.widgets.account" :hideShadow="true">
        <template v-slot:title>
            {{accountDictionary.widgets.mfa}}
        </template>
        <template v-slot:body>
            <AccountEnableMfaToggle :dictionary="accountDictionary.mfa" :boundaries="accountDataModel.beneficiaryBoundaries"
                                    :mfa-boundaries-data-model="mfaBoundariesDataModel"
                                    :telephone="accountDataModel.telephone" :toggleValue="accountDataModel.mfaEnabled" @on-switch="onSwitch($event)"></AccountEnableMfaToggle>
            <MfaModal :dictionary="accountDictionary" :header="modalHeader" :message="modalConfirmationMessage"
                      :currentFactorType="switchFactor" 
                      :mfa-boundaries-data-model="mfaBoundariesDataModel"
                      :mfa-enable-disable-data-model="mfaEnableDisableDataModel"
                      :phone="modalPacPhone" :isModalVisible="isMfaModalVisable"
                      :showFirstScreen="isMfaEnabling"
                      @confirm-code="confirmCode"
                      @cancel="closeMfaModal()" @submit-confirmation="submitConfirmation" @mfa-success-login="pacCodeConfirmed()" />
        </template>
    </portlet-layout>
</template>

<script>
    import { post, get } from "api";
    import AccountEnableMfaToggle from "./account-enable-mfa-toggle.component.vue";
    import MfaModal from "Features/Mfa/Assets/Vue/mfa-modal.component.vue";

    export default {
        components: {
            AccountEnableMfaToggle,
            MfaModal
        },
        props: {
            accountDictionary: Object,
            accountDataModel: Object,
            mfaBoundariesDataModel:Object
        },
        data() {
            return {
                isMfaModalVisable: false,
                modalHeader: '',
                modalConfirmationMessage: '',
                modalPacPhone: '',
                mfaEnableDisableDataModel:{},
                switchFactor:''
            }
        },
        computed: {
            showMfaPortlet() {
                if (!(this.accountDataModel.beneficiaryBoundaries && this.mfaBoundariesDataModel))
                    return false;

                return (this.accountDataModel.beneficiaryBoundaries.mayEditMfaEnabled && this.mfaBoundariesDataModel.mayEnableAndDisable && this.mfaBoundariesDataModel.availableFactorTypes.length > 0)
            },
            isMfaEnabling(){
                return !this.accountDataModel.mfaEnabled;
            },
            currentFactorType() {
                if (this.switchFactor)
                    return this.switchFactor;
                if (this.mfaBoundariesDataModel.currentFactorType == 'mftNone')
                    if (this.mfaBoundariesDataModel.availableFactorTypes.length > 0)
                        return this.mfaBoundariesDataModel.availableFactorTypes[0];
                else
                    return this.mfaBoundariesDataModel.currentFactorType;
            }
        },
        methods: {
            onSwitch(data) {
                this.switchFactor = data.toggleFactor;
                this.showMfaModal();

                if (this.currentFactorType == 'mftTOTP') 
                    this.enableOrDisableMfa();
                
            },
            submitConfirmation() {
                this.enableOrDisableMfa();
            },
            pacCodeConfirmed() {
                this.$emit('edit-mfa', !this.accountDataModel.mfaEnabled);
                this.closeMfaModal();
            },
            enableOrDisableMfa() {
                let url = '/Mfa/Disable';

                if (this.isMfaEnabling) 
                    url = '/Mfa/Enable';

                post(url,
                    {
                        factorType: this.currentFactorType
                    },
                    (res) => {
                        if (res.data) {
                            this.mfaEnableDisableDataModel = res.data;
                        } 
                        else 
                            this.$emit('reload-page');
                    },
                    this,
                    () => { this.$emit("in-loading", false); },
                    (err) => {
                        this.error = err.response.data;
                        this.$emit("in-loading", false);
                    }
                );
            },
            confirmCode(mfaResponse) {
                if (mfaResponse.isSuccess) {
                    let url = '/Mfa/ConfirmDisable';

                    if (this.isMfaEnabling) 
                        url = '/Mfa/ConfirmEnable';

                    post(url,
                        {
                            identifier:mfaResponse.identifier,
                            factorType: mfaResponse?.factorType,
                            code:mfaResponse.code
                        },
                        (res) => {
                            if (res.data.factor.isSuccessful) {
                                this.secretKey = res.data.secret;
                                this.$emit('reload-page');
                            }
                        },
                        this,
                        () => { this.$emit("in-loading", false); },
                        (err) => {
                            this.error = err.response.data;
                            this.$emit("in-loading", false);
                        }
                    );
                }
            },
            updateBeneficiary() {
                post("/Beneficiaries/EditBeneficiary",
                    {
                        emailAddress: this.accountDataModel.emailAddress,
                        ccEmailAddress: this.accountDataModel.ccEmailAddress,
                        localeId: this.accountDataModel.locale.id,
                        telephone: this.accountDataModel.telephone,
                        mfaEnabled: !this.accountDataModel.mfaEnabled,
                        decimalSeparator: this.accountDataModel.decimalSeparator.value
                    },
                    (response) => {
                        this.$emit('reload-page');
                    },
                    this,
                    () => { },
                    (err) => { }
                );
            },
            showMfaModal() {
                this.modalPacPhone = this.accountDataModel.telephone;
                this.modalHeader = this.accountDictionary.mfa.mfaSettingModalHeader;

                if (this.accountDataModel.mfaEnabled) {
                    this.modalConfirmationMessage = this.accountDictionary.mfa.alertMfaDisable
                } else {
                    this.modalConfirmationMessage = this.accountDictionary.mfa.alertMfaEnable
                }

                this.isMfaModalVisable = true;
            },
            closeMfaModal() {
                this.isMfaModalVisable = false;
            }
        }
    };
</script>
<template>
    <required-info-step id="citizenship-info-container"
                        :currentStep="currentStep"
                        :step="step"
                        :steps="steps"
                        :summaryType="summaryType"
                        :heading="dictionary.labels.step2"
                        :description="dictionary.labels.step2Description"
                        :dictionary="dictionary"
                        @edit-step="(val) => $emit('edit-step', val)">
        <template v-slot:previous>
            <div class="row">
                <RequiredInfoDataLabelRow class="col-md-6" :value="getCountryName(requiredInfoModel.legalDocumentCountryId)" :label="dictionary.labels.legalDocumentCountry" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" :value="getDocumentTypeName(requiredInfoModel.legalDocumentType.value)" :label="dictionary.labels.legalDocumentType" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" v-if="boundaries.canViewLegalDocumentIssueDate"
                                          :value="requiredInfoModel.legalDocumentIssueDate | toDate" :label="dictionary.labels.issueDate" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" v-if="boundaries.canViewLegalDocumentExpiryDate"
                                          :value="requiredInfoModel.legalDocumentExpiryDate | toDate" :label="dictionary.labels.expiryDate" :isMandatory="true" />
                <RequiredInfoDataLabelRow class="col-md-6" :value="requiredInfoModel.legalDocumentNumber" :label="dictionary.labels.legalDocumentNumber" :isMandatory="true" />
            </div>
        </template>
        <template v-slot:current>
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <select-element v-model="requiredInfoModel.legalDocumentCountryId"
                                        :label="dictionary.labels.legalDocumentCountry"
                                        :validationsList="citizenshipValidations.legalDocumentCountryId"
                                        :options="listCountries"
                                        name="legalDocumentCountryId"
                                        :readonly="!boundaries.mayEditLegalDocumentCountryId"
                                        :is-mandatory="true" />
                    </div>
                    <div v-if="boundaries.legalDocumentTypes" class="col-md-6">
                        <select-element v-model="requiredInfoModel.legalDocumentType.value"
                                        :label="dictionary.labels.legalDocumentType"
                                        :options="legalDocumentTypes"
                                        name="legalDocumentType"
                                        :readonly="!boundaries.mayEditLegalDocumentType"
                                        :validationsList="citizenshipValidations.legalDocumentType"
                                        :is-mandatory="true" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <datepicker-element id="document-issue-date"
                                            :label="dictionary.labels.issueDate"
                                            :isMandatory="true"
                                            v-if="boundaries.canViewLegalDocumentIssueDate"
                                            :readonly="!boundaries.mayEditLegalDocumentIssueDate"
                                            v-model="requiredInfoModel.legalDocumentIssueDate"
                                            :validationsList="citizenshipValidations.legalDocumentIssueDate"
                                            :readonlyLabel="dictionary.accessibility.readonlyLabel" />
                    </div>
                    <div class="col-md-6">
                        <datepicker-element id="document-expiration-date"
                                            :label="dictionary.labels.expiryDate"
                                            yearRange="-25:+25"
                                            :isMandatory="true"
                                            v-if="boundaries.canViewLegalDocumentExpiryDate"
                                            :readonly="!boundaries.mayEditLegalDocumentExpiryDate"
                                            v-model="requiredInfoModel.legalDocumentExpiryDate"
                                            :validationsList="citizenshipValidations.legalDocumentExpiryDate"
                                            :readonlyLabel="dictionary.accessibility.readonlyLabel" />
                    </div>

                </div>

                <div class="row mb-2">
                    <div class="col-12 col-md-6">
                        <input-element id="document-number"
                                       name="DocumentNumber"
                                       type="text"
                                       :isMandatory="true"
                                       :readonly="!boundaries.mayEditLegalDocumentNumber"
                                       :label="dictionary.labels.legalDocumentNumber"
                                       v-model="requiredInfoModel.legalDocumentNumber"
                                       :validationsList="citizenshipValidations.legalDocumentNumber"></input-element>
                    </div>
                </div>
                <div class="d-md-flex justify-content-md-between button-container">
                    <div>
                        <button class="btn secondaryButton"
                                type="button"
                                @click="previousStep()"
                                :aria-describedby="previousStepAccessibilityLabel(step)">
                            {{ dictionary.buttons.previous }}
                        </button>
                        <button
                                class="btn primaryButton"
                                type="button"
                                @click="nextStep()"
                                :aria-describedby="nextStepAccessibilityLabel(step, steps)">
                            {{ dictionary.buttons.next }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </required-info-step>
</template>

<script>
    import { createDropDownOption } from "./required-info-crs-timeline.component.vue";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import { notInFuture } from "./mixins/required-info-form-validation.mixin";
    import DataLabelRow from "../../../Beneficiaries/Assets/Vue/data-label-row.component.vue";
    import RequiredInfoStep from "./required-info-step.component.vue";
    import RequiredInfoDataLabelRow from "./required-info-data-label-row.component.vue";
    import { EventBus } from "Shared/event-bus";
    import { stepperAccessibilityPreviousNextLabel } from "Shared/Mixins/stepper-accessibility-previous-next-label.mixin";
    import { accessibility } from "Shared/Mixins/accessibility.mixin";

    export default {
        mixins: [accessibility, stepperAccessibilityPreviousNextLabel],
        components: {
            DataLabelRow,
            RequiredInfoStep,
            RequiredInfoDataLabelRow
        },
        props: {
            dictionary: Object,
            currentStep: Number,
            step: Number,
            steps: Number,
            summaryType: String,
            requiredInfoData: Object,
            countries: Array,
            boundaries: Object
        },
        validations() {
            return {
                requiredInfoModel: {
                    legalDocumentCountryId: { 
                        required: requiredIf(function () {
                            return this.boundaries.mayEditLegalDocumentCountryId;
                        })
                     },
                    legalDocumentType: { 
                        required: requiredIf(function () {
                            return this.boundaries.mayEditLegalDocumentType;
                        })
                     },
                    legalDocumentNumber: { 
                        required: requiredIf(function () {
                            return this.boundaries.mayEditLegalDocumentNumber;
                        })
                    },
                    legalDocumentIssueDate: {
                        required: requiredIf(function () {
                            return this.boundaries.canViewLegalDocumentIssueDate && this.boundaries.mayEditLegalDocumentIssueDate;
                        }),
                        notInFuture: function (value) {
                            return !this.boundaries.canViewLegalDocumentIssueDate || notInFuture(value);
                        }
                    },
                    legalDocumentExpiryDate: {
                        required: requiredIf(function () {
                            return this.boundaries.canViewLegalDocumentExpiryDate && this.boundaries.mayEditLegalDocumentExpiryDate;
                        }),
                        inFuture: function (value) {
                            return !this.boundaries.canViewLegalDocumentIssueDate || !notInFuture(value);
                        }
                    },
                }
            };
        },
        computed: {
            requiredInfoModel() {
                return this.requiredInfoData;
            },
            listCountries() {
                return this.countries.map(c =>
                    createDropDownOption(false, false, false, c.name, c.id)
                );
            },
            legalDocumentTypes() {
                return this.boundaries.legalDocumentTypes.map(c =>
                    createDropDownOption(false, false, false, c.text, c.value)
                );
            },
            citizenshipValidations() {
                return {
                    legalDocumentCountryId: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalDocumentCountryId,
                            error: this.$v.requiredInfoModel.legalDocumentCountryId.$error,
                            type: this.$v.requiredInfoModel.legalDocumentCountryId.required,
                            message: this.dictionary.validation.legalDocumentCountryInvalid
                        }
                    ],
                    legalDocumentType: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalDocumentType,
                            error: this.$v.requiredInfoModel.legalDocumentType.$error,
                            type: this.$v.requiredInfoModel.legalDocumentType.required,
                            message: this.dictionary.validation.legalDocumentTypeInvalid
                        }
                    ],
                    legalDocumentNumber: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalDocumentNumber,
                            error: this.$v.requiredInfoModel.legalDocumentNumber.$error,
                            type: this.$v.requiredInfoModel.legalDocumentNumber.required,
                            message: this.dictionary.validation.legalDocumentNumberInvalid
                        }
                    ],
                    legalDocumentIssueDate: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalDocumentIssueDate,
                            error: this.$v.requiredInfoModel.legalDocumentIssueDate.$error,
                            type: this.$v.requiredInfoModel.legalDocumentIssueDate.required,
                            message: this.dictionary.validation.issueDateInvalid
                        },
                        {
                            id: 2,
                            field: this.$v.requiredInfoModel.legalDocumentIssueDate,
                            error: this.$v.requiredInfoModel.legalDocumentIssueDate.$error,
                            type: this.$v.requiredInfoModel.legalDocumentIssueDate.notInFuture,
                            message: this.dictionary.validation.issueDateInvalid
                        }
                    ],
                    legalDocumentExpiryDate: [
                        {
                            id: 1,
                            field: this.$v.requiredInfoModel.legalDocumentExpiryDate,
                            error: this.$v.requiredInfoModel.legalDocumentExpiryDate.$error,
                            type: this.$v.requiredInfoModel.legalDocumentExpiryDate.inFuture,
                            message: this.dictionary.validation.expiryDateInvalid
                        },
                        {
                            id: 2,
                            field: this.$v.requiredInfoModel.legalDocumentExpiryDate,
                            error: this.$v.requiredInfoModel.legalDocumentExpiryDate.$error,
                            type: this.$v.requiredInfoModel.legalDocumentExpiryDate.required,
                            message: this.dictionary.validation.expiryDateInvalid
                        }
                    ]
                };
            }
        },
        methods: {
            nextStep() {
                EventBus.$emit("v-touch");
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return this.focusOnFirstInvalidInput();
                }
                this.$emit("next-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            },
            previousStep() {
                this.$emit("previous-step", {
                    step: this.currentStep,
                    data: this.requiredInfoModel
                });
            },
            getCountryName(id) {
                return this.countries.find(x => x.id === id)?.name;
            },
            getDocumentTypeName(value) {
                return this.boundaries.legalDocumentTypes.find(x => x.value === value)?.text;
            }
        }
    };
</script>
<template>
    <div>
        <beneficiary-data-label :value="fiscalDataModel.fiscalNumber" :label="dictionary.fiscalNumber"></beneficiary-data-label>
        <beneficiary-data-label :value="fiscalDataModel.country" :label="dictionary.country"></beneficiary-data-label>
        <beneficiary-data-label :value="fiscalDataModel.startDate" :label="dictionary.startDate" :display-value="fiscalDataModel.startDateFormatted"></beneficiary-data-label>
        <beneficiary-data-label :value="fiscalDataModel.endDate" :label="dictionary.endDate" :display-value="fiscalDataModel.endDateFormatted"></beneficiary-data-label>
    </div>
</template>

<script>
import BeneficiaryDataLabel from "./../data-label.component.vue";

export default {
  components: {
    BeneficiaryDataLabel,
  },
  props: {
    fiscalDataModel: Object,
    dictionary: Object,
  },
};
</script>
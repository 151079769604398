<template>
  <div v-if="dictionary" class="orders">
    <b-tabs content-class="mt-0" class="tab-container"> 
        <portlet-tab :tabTitle="dictionary.tabs.open" :isActive="true" tabId="tabOpenOrders">
            <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.ordersPageDescriptionOpen)"></div>  
            <OrdersTable :dictionary="dictionary.openTable" :datatablesDictionary="dictionary.dataTables" :toggles="toggles" id="OpenOrders" getUrl="/Orders/OpenOrders"/>
        </portlet-tab>
        <portlet-tab :tabTitle="dictionary.tabs.history" tabId="tabHistoryOrders">
            <div class="page-description rich-text-container" v-html="$sanitize(dictionary.page.ordersPageDescriptionHistory)"></div>  
            <OrdersTable :dictionary="dictionary.historyTable" :datatablesDictionary="dictionary.dataTables" :toggles="toggles" :isHistoryOrders="true" id="ClosedOrders" getUrl="/Orders/HistoryOrders"/>
        </portlet-tab>   
    </b-tabs>
    <OrderDetail :toggles="toggles" :title="dictionary.modal.headerTitle" /> 
  </div>
</template>

<script>
import OrderDetail from "./order-detail.component.vue";
import OrdersTable from "./orders-table.component.vue";

export default {
  components: {
    OrderDetail,
    OrdersTable
  },
  props: {
    dictionary: Object,
    toggles: Object
  },
  data() {
    return {
        isTablet: true,
    }
  }
};
</script>
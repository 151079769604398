<template>
  <div class="timeline-step-accordion">
    <h2 class="timeline-step-header">
      <button v-b-toggle="accordionId">
        <span class="timeline-accordion-header">
          {{heading}}
        </span>
        <span v-if="!isSummary" class="timeline-accordion-icon">
          <i :class="['accordion-toggle-icon far', isVisible ? 'fa-chevron-up' : 'fa-chevron-down']" aria-hidden="true"></i>
        </span>
      </button>
    </h2>
    <b-collapse :id="accordionId" role="tabpanel" v-model="isVisible">
      <div class="timeline-step-static-details">
        <slot></slot>
        <button v-if="!isSummary" class="primaryButton btn edit-step-button" @click="editStep()">{{dictionary.buttons.edit}}<span class="sr-only">{{heading}}</span></button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    props: {
      heading: String,
      currentStep: Number,
      step: Number,
      dictionary: Object,
      steps: Number,
      summaryType: String
    },
    data() {
      return {
        isVisible: false,
        accordionId: 'accordion-' + this.step
      }
    },
    computed: {
        isSummary() {
          if(this.currentStep == this.finishStep) {
            this.isVisible = true;
            return true;
          }
          this.isVisible = false;
          return false;
        },
        finishStep() {
            return this.summaryType == 'afterLastStep' ? this.steps + 1 : this.steps;
        }
    },
    methods: {
      editStep() {
        this.$emit("edit-step", { step: this.step });
      }
    }
  }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          class: [
            "required-documents-details-expansion-block",
            "mobile",
            { "expansion-block-margin-bottom": !_vm.isPanelOpened }
          ],
          attrs: { accordion: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "required-documents-details-expansion-panel" },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass:
                    "required-documents-details-expansion-panel__header"
                },
                [
                  _c("h3", { staticClass: "h4" }, [
                    _vm._v(
                      _vm._s(_vm.dictionary.mobileRowTitle) +
                        " " +
                        _vm._s(_vm.requiredDocument.name)
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "mobile" },
                [
                  _c(
                    "RequiredDocumentsDetailsContent",
                    _vm._g(
                      {
                        attrs: {
                          dictionary: _vm.dictionary,
                          requiredDocument: _vm.requiredDocument
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
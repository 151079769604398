<template>
    <div class="localhr-container" >
            <div class="localhr__icon">
                <i class="far fa-glasses fa-2x" aria-hidden="true"></i>
                <span class="sr-only">{{ dictionary.impersonationMode }}</span>
            </div>
             <div class="localhr__text">
                <span>{{ dictionary.impersonationMode }}</span>
            </div>
    </div>
</template>

<script>
    export default {
        props: {
            dictionary: Object
        }
    };
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-label-row-details" }, [
    _c("p", [
      _vm._v(_vm._s(_vm.isMandatory ? _vm.label + " *" : "" + _vm.label))
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.displayEligibleValue()))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
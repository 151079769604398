import { mustBeDecimal, mustBeMinimum, mustBeMaximum } from 'Shared/Components/Vue/Validations/custom-validator'

export const scenarioValidations = {

    validations() {
        return {
            choiceInstructionModel: {
                scenarios:
                {
                    $each: {
                        value: {
                            mustBeDecimal: function (value) {
                                return mustBeDecimal(value);
                            },
                            mustBeMinimumValue: function (value, param) {
                                return mustBeMinimum(value, param.minimumValue);
                            },
                            mustBeMaximumValue: function (value, param) {
                                return mustBeMaximum(value, param.maximumValue);
                            },
                        }
                    }
                }
            }
        }
    },
} 